import React, { useEffect } from 'react';
import styled from 'styled-components';

import LinkAccountFlow from 'components/accounts/LinkAccountFlow';
import TopInstitutionsGrid from 'components/institutions/TopInstitutionsGrid';
import FlexContainer from 'components/lib/ui/FlexContainer';
import SearchBar from 'components/lib/ui/SearchBar';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import OnboardingFooterNav from 'components/onboarding/OnboardingFooterNav';
import OnboardingSubtitle from 'components/onboarding/OnboardingSubtitle';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';

import { spacing } from 'common/lib/theme/dynamic';
import useGetInstitutionGroup from 'lib/hooks/institutions/useGetInstitutionGroup';
import useIsFeatureFlagOff from 'lib/hooks/useIsFeatureFlagOff';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import useModal from 'lib/hooks/useModal';

import * as COPY from 'common/constants/copy';

import type {
  InstitutionRowFieldsFragment,
  Web_GetTopInstitutionGroupsQuery,
} from 'common/generated/graphql';
import { TopInstitutionGroupType } from 'common/generated/graphql';
import type { ElementOf } from 'common/types/utility';

const PageSubtitle = styled(OnboardingSubtitle)`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const PageSubtitleMobileOnly = styled(PageSubtitle)`
  margin: ${({ theme }) => theme.spacing.default} 0 0 0;
  display: none;

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    display: block;
  }
`;

const PageSubtitleHighlight = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const IntroContainer = styled(FlexContainer).attrs({ column: true })`
  width: 100%;
  max-width: 558px;
  margin: auto;
`;

const PageContainer = styled.div`
  width: 100%;
`;

const TopInstitutionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.large};
  padding-top: 0;
`;

const StyledSearchBar = styled(SearchBar)`
  margin: ${spacing.xxlarge} ${spacing.xlarge};
`;

const SkipButton = styled(DefaultButton).attrs({
  size: 'medium',
})<{
  $isCreditCardFirstOn: boolean;
}>`
  margin-top: ${({ theme }) => theme.spacing.xxxlarge};
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    margin-top: ${({ $isCreditCardFirstOn, theme }) =>
      $isCreditCardFirstOn ? theme.spacing.default : theme.spacing.xxxlarge};
  }
`;

type Props = {
  next: () => void;
  skipToComplete: () => void;
};

const DEFAULT_SELECTED_GROUP_TYPE = TopInstitutionGroupType.BANKS_CREDIT_CARDS;

const CREDIT_CARD_FIRST_EXPERIMENT = {
  TITLE: "Connect your credit card or debit card and we'll automatically categorize your spending.",
  SUBTITLE:
    "Securely give Monarch read-only access to automatically keep track of your accounts. We don't sell or share your data. You can add others later.",
  MOBILE_TIP: () => (
    <>
      Tip: Many customers find setup easier on a larger screen. If possible,{' '}
      <PageSubtitleHighlight>
        visit our website and log in to the desktop version.
      </PageSubtitleHighlight>
    </>
  ),
};
const OnboardingConnectSpendingAccount = ({ next, skipToComplete }: Props) => {
  const isCreditCardFirstOn = useIsFeatureFlagOn('ab-test-credit-card-first', {
    trackImpression: true,
  });

  const EXPERIMENT = 'ab-test-connect-single-account-before-paywall';

  /*
    I am checking if it is on and off because I want to avoid control.
    If it is off it will skip the page and I don't want to blink any content.
  */
  const isAbTestConnectSingleAccountBeforePaywallOn = useIsFeatureFlagOn(EXPERIMENT, {
    trackImpression: true,
  });

  const isAbTestConnectSingleAccountBeforePaywallOff = useIsFeatureFlagOff(EXPERIMENT);

  /*
    skips the page when the ab-test-connect-accounts-before-paywall feature flag is on.
    This feature flag is used to let users connect accounts before the paywall. When that happens, we should skip this page.
  */
  useEffect(() => {
    if (isAbTestConnectSingleAccountBeforePaywallOn) {
      skipToComplete();
    }
  }, [isAbTestConnectSingleAccountBeforePaywallOn]);

  const { credentials, topInstitutionGroupByType } =
    useGetInstitutionGroup(DEFAULT_SELECTED_GROUP_TYPE) ?? {};

  const [LinkModal, { open: openLink, close: closeLink, context }] = useModal<{
    institution?: InstitutionRowFieldsFragment;
    selectedTopGroup?: ElementOf<Web_GetTopInstitutionGroupsQuery, 'topInstitutionGroups'>;
  }>();

  return (
    <PageContainer>
      {isAbTestConnectSingleAccountBeforePaywallOff && (
        <>
          <IntroContainer>
            {isCreditCardFirstOn ? (
              // ab-test-credit-card-first experiment
              <>
                <OnboardingTitle>{CREDIT_CARD_FIRST_EXPERIMENT.TITLE}</OnboardingTitle>
                <PageSubtitle>{CREDIT_CARD_FIRST_EXPERIMENT.SUBTITLE}</PageSubtitle>
              </>
            ) : (
              // default experience
              <>
                <OnboardingTitle>
                  {COPY.ONBOARDING.SPENDING_INSIGHTS_CONNECT_ACCOUNT.TITLE}
                </OnboardingTitle>
                <PageSubtitle>
                  {COPY.ONBOARDING.SPENDING_INSIGHTS_CONNECT_ACCOUNT.SUBTITLE}
                </PageSubtitle>
              </>
            )}

            <StyledSearchBar
              name="search-institutions"
              placeholder="Search 13,000+ institutions"
              autoComplete="off"
              focusOnRender={false}
              onFocus={() => {
                openLink({ selectedTopGroup: topInstitutionGroupByType ?? undefined });
              }}
            />

            <TopInstitutionsContainer>
              <TopInstitutionsGrid
                institutions={topInstitutionGroupByType?.institutions ?? []}
                credentialCount={credentials?.length ?? 0}
                onSelectInstitution={(institution) => openLink({ institution })}
              />
              {isCreditCardFirstOn && (
                <PageSubtitleMobileOnly>
                  {CREDIT_CARD_FIRST_EXPERIMENT.MOBILE_TIP()}
                </PageSubtitleMobileOnly>
              )}

              <OnboardingFooterNav>
                <SkipButton onClick={next} $isCreditCardFirstOn={isCreditCardFirstOn}>
                  {COPY.ONBOARDING.SPENDING_INSIGHTS_CONNECT_ACCOUNT.SKIP_BUTTON}
                </SkipButton>
              </OnboardingFooterNav>
            </TopInstitutionsContainer>
          </IntroContainer>

          <LinkModal>
            <LinkAccountFlow
              allowManualAccount={false}
              selectedTopGroup={context?.selectedTopGroup}
              institution={context?.institution}
              onClose={closeLink}
              onComplete={() => next()}
            />
          </LinkModal>
        </>
      )}
    </PageContainer>
  );
};

export default OnboardingConnectSpendingAccount;
