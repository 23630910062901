import React from 'react';
import styled, { css } from 'styled-components';

import type { BlockRenderer, TextBlock } from 'common/lib/posts/types';
import { fontSize, fontWeight, spacing, variables } from 'common/lib/theme/dynamic';

const h1 = css`
  font-size: ${fontSize.xlarge};
  font-weight: ${fontWeight.medium};
`;

const h2 = css`
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.medium};
  margin-top: ${spacing.xlarge};
`;

const p = css`
  font-size: ${fontSize.base};
  color: ${variables.color.content.secondary};
  margin-top: ${spacing.xsmall};
`;

const Text = styled.div<{ type: TextBlock['type'] }>`
  ${({ type }) =>
    ({
      h1,
      h2,
      p,
    })[type]}
`;

const TextBlockRenderer: BlockRenderer<TextBlock> = ({ data: { type, text } }) => (
  <Text type={type}>{text}</Text>
);

export default TextBlockRenderer;
