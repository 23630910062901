import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import type { Variable } from 'common/lib/theme/dynamic/types';

type StaticVariables<T> = {
  [K in keyof T]: T[K] extends Variable<infer R>
    ? R
    : T[K] extends Record<string, unknown>
      ? StaticVariables<T[K]>
      : never;
};

/**
 * Recursively converts dynamic variables (where values are functions) back to a static object.
 * This reverses the operation performed by makedynamicVariables.
 */
export const makeStaticVariables = <T extends Record<string, any>>(
  dynamicVariables: T,
  theme: Record<string, any>,
): StaticVariables<T> =>
  R.mapObjIndexed((value) => {
    // If it's an object (but not a function), recurse
    if (RA.isObject(value) && !RA.isFunction(value)) {
      return makeStaticVariables(value, theme);
    }

    // If it's a function, execute it with the theme
    if (typeof value === 'function') {
      return value({ theme });
    }

    // Otherwise return the value as-is
    return value;
  }, dynamicVariables) as StaticVariables<T>;
