import classNames from 'classnames';
import React, { useRef, useState, useEffect } from 'react';

interface ToggleProps {
  checked?: boolean;
  disabled?: boolean;
  defaultChecked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  className?: string;
  name?: string;
  value?: string;
  id?: string;
  'aria-labelledby'?: string;
  'aria-label'?: string;
  icons?:
    | boolean
    | {
        checked?: React.ReactNode;
        unchecked?: React.ReactNode;
      };
}

export function pointerCoord(event: React.TouchEvent | React.MouseEvent) {
  // get coordinates for either a mouse click
  // or a touch depending on the given event
  if (event) {
    if (typeof event === 'object' && 'changedTouches' in event) {
      const { changedTouches } = event;
      if (changedTouches && changedTouches.length > 0) {
        const touch = changedTouches.item(0);
        return { x: touch.clientX, y: touch.clientY };
      }
    } else if (typeof event === 'object' && 'pageX' in event) {
      const { pageX } = event;
      if (pageX !== undefined) {
        return { x: pageX, y: event.pageY };
      }
    }
  }
  return { x: 0, y: 0 };
}

const X = () => (
  <svg width="10" height="10" viewBox="0 0 10 10">
    <path
      d="M9.9 2.12L7.78 0 4.95 2.828 2.12 0 0 2.12l2.83 2.83L0 7.776 2.123 9.9 4.95 7.07 7.78 9.9 9.9 7.776 7.072 4.95 9.9 2.12"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

const Check = () => (
  <svg width="14" height="11" viewBox="0 0 14 11">
    <path
      d="M11.264 0L5.26 6.004 2.103 2.847 0 4.95l5.26 5.26 8.108-8.107L11.264 0"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

const defaultIcons = {
  checked: <Check />,
  unchecked: <X />,
};

const Toggle: React.FC<ToggleProps> = ({
  checked: controlledChecked,
  disabled,
  defaultChecked,
  className,
  icons: customIcons,
  onChange,
  onFocus,
  onBlur,
  ...inputProps
}) => {
  const [checked, setChecked] = useState(() => !!(controlledChecked || defaultChecked));
  const [activated, setActivated] = useState(false);
  const [moved, setMoved] = useState(false);
  const [startX, setStartX] = useState<number | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const previouslyCheckedRef = useRef(checked);

  useEffect(() => {
    if (controlledChecked !== undefined) {
      setChecked(!!controlledChecked);
    }
  }, [controlledChecked]);

  const getIcon = (type: 'checked' | 'unchecked') => {
    if (!customIcons) {
      return null;
    }
    return typeof customIcons === 'object' && customIcons[type] !== undefined
      ? customIcons[type]
      : defaultIcons[type];
  };

  const handleClick = (event: React.MouseEvent | React.KeyboardEvent) => {
    if (disabled) {
      return;
    }

    const checkbox = inputRef.current;
    if (!checkbox) {
      return;
    }

    if (event.target !== checkbox && !moved) {
      previouslyCheckedRef.current = checkbox.checked;
      event.preventDefault();
      checkbox.click();
      return;
    }

    const newChecked = controlledChecked !== undefined ? controlledChecked : checkbox.checked;
    setChecked(newChecked);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick(event);
    }
  };

  const handleTouchStart = (event: React.TouchEvent) => {
    if (disabled) {
      return;
    }
    setStartX(pointerCoord(event).x);
    setActivated(true);
  };

  const handleTouchMove = (event: React.TouchEvent) => {
    if (!activated) {
      return;
    }
    setMoved(true);

    if (startX !== null) {
      const currentX = pointerCoord(event).x;
      if (checked && currentX + 15 < startX) {
        setChecked(false);
        setStartX(currentX);
        setActivated(true);
      } else if (currentX - 15 > startX) {
        setChecked(true);
        setStartX(currentX);
        setActivated(currentX < startX + 5);
      }
    }
  };

  const handleTouchEnd = (event: React.TouchEvent) => {
    if (!moved) {
      return;
    }
    const checkbox = inputRef.current;
    if (!checkbox) {
      return;
    }
    event.preventDefault();

    if (startX !== null) {
      const endX = pointerCoord(event).x;
      if (previouslyCheckedRef.current === true && startX + 4 > endX) {
        if (previouslyCheckedRef.current !== checked) {
          setChecked(false);
          previouslyCheckedRef.current = false;
          checkbox.click();
        }
      } else if (startX - 4 < endX) {
        if (previouslyCheckedRef.current !== checked) {
          setChecked(true);
          previouslyCheckedRef.current = true;
          checkbox.click();
        }
      }

      setActivated(false);
      setStartX(null);
      setMoved(false);
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    onFocus?.(event);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.(event);
  };

  const classes = classNames(
    'react-toggle',
    {
      'react-toggle--checked': checked,
      'react-toggle--disabled': disabled,
    },
    className,
  );

  return (
    <div
      className={classes}
      onClick={handleClick}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      <div className="react-toggle-track">
        <div className="react-toggle-track-check">{getIcon('checked')}</div>
        <div className="react-toggle-track-x">{getIcon('unchecked')}</div>
      </div>
      <div className="react-toggle-thumb" />

      <input
        {...inputProps}
        ref={inputRef}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className="react-toggle-screenreader-only"
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
    </div>
  );
};

Toggle.displayName = 'Toggle';

export default Toggle;
