import pluralize from 'pluralize';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import CopyBalancePreviewGraph from 'components/accounts/CopyBalancePreviewGraph';
import { DatePicker } from 'components/accounts/transferAccountData/DatePicker';
import RadioGroup from 'components/lib/form/RadioGroup';
import Card from 'components/lib/ui/Card';
import FlexContainer from 'components/lib/ui/FlexContainer';
import InformationList, { InformationItem } from 'components/lib/ui/InformationList';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import type { StepProps } from 'components/routes/accounts/transferAccountData/TransferAccountData';
import TransferAccountDataStep from 'components/routes/accounts/transferAccountData/TransferAccountDataStep';

import { spacing, variables } from 'common/lib/theme/dynamic';
import { isoDateToAbbreviatedMonthDayAndYear, parseISODate } from 'common/utils/date';
import { snapshotsToPreviewBalanceDataPoints } from 'lib/accounts/snapshots';
import { getProgressPercent } from 'lib/accounts/transferAccountData/utils/progress';
import { track } from 'lib/analytics/segment';
import usePreviewAccountDataTransferSnapshots from 'lib/hooks/accounts/usePreviewAccountDataTransferSnapshots';
import useRecommendedDataTransferDates from 'lib/hooks/accounts/useRecommendedDataTransferDates';

import { TransferAccountDataEventNames } from 'common/constants/analytics';

const GraphRoot = styled(Card)`
  width: 100%;
  border: 1px solid ${variables.color.divider.secondary};
  padding: ${spacing.default} ${spacing.xxlarge} 0;
`;

const StyledInformationList = styled(InformationList)`
  background-color: ${variables.color.background.secondary};
`;

const Snapshots: React.FC<StepProps> = ({ params, updateParams, next }) => {
  const { fromAccountId, toAccountId, transferBalanceSnapshots, endDateBalanceSnapshots } = params;

  const { data: recommendedDataTransferDates, loading: loadingRecommendedDataTransferDates } =
    useRecommendedDataTransferDates(fromAccountId, toAccountId);

  const hasBalanceSnapshotsToTransfer =
    recommendedDataTransferDates && recommendedDataTransferDates.snapshotsEndDate !== null;
  useEffect(() => {
    // Only run this effect once the data has finished loading
    if (loadingRecommendedDataTransferDates) {
      return;
    }

    // If we have a recommended end date, and we don't yet have an end date, set the end date to the
    // recommended end date
    if (hasBalanceSnapshotsToTransfer && !endDateBalanceSnapshots) {
      updateParams({
        endDateBalanceSnapshots: recommendedDataTransferDates.snapshotsEndDate,
      });
    } else if (!hasBalanceSnapshotsToTransfer && transferBalanceSnapshots) {
      // Otherwise, if we don't have a recommended end date, that means we don't have any
      // balance snapshots to transfer.
      updateParams({
        transferBalanceSnapshots: false,
        endDateBalanceSnapshots: null,
        startDateBalanceSnapshots: null,
      });
    }
  }, [
    hasBalanceSnapshotsToTransfer,
    recommendedDataTransferDates,
    endDateBalanceSnapshots,
    transferBalanceSnapshots,
    updateParams,
    loadingRecommendedDataTransferDates,
  ]);

  const startDate = recommendedDataTransferDates?.snapshotsStartDate;
  const { data: previewData, loading: isLoadingPreview } = usePreviewAccountDataTransferSnapshots(
    fromAccountId,
    toAccountId,
    startDate,
    endDateBalanceSnapshots,
  );

  // We don't currently let users select a start date, so we just pass the end date here.
  // If we start letting users select a start date, we'll need to update this.
  const previewSnapshots = previewData?.previewSnapshots ?? [];
  const balanceDataPoints = endDateBalanceSnapshots
    ? snapshotsToPreviewBalanceDataPoints(previewSnapshots, endDateBalanceSnapshots)
    : [];

  const fromAccountExpectedAffectedBalanceSnapshotCount =
    previewData?.fromAccountSummary.numSnapshots ?? 0;
  const toAccountExpectedAffectedBalanceSnapshotCount =
    previewData?.toAccountSummary.numSnapshots ?? 0;
  const toAccountNewerBalanceSnapshotCount = previewData?.toAccountSummary.numNewerSnapshots ?? 0;

  const handleNext = () => {
    const isRecommendedDate =
      endDateBalanceSnapshots === recommendedDataTransferDates?.snapshotsEndDate;

    track(TransferAccountDataEventNames.TransferAccountDataBalancesDateSelected, {
      transferBalanceSnapshots,
      selectedDate: endDateBalanceSnapshots,
      recommendedDate: recommendedDataTransferDates?.snapshotsEndDate,
      isRecommendedDate,
    });

    next({
      fromAccountExpectedAffectedBalanceSnapshotCount,
      toAccountExpectedAffectedBalanceSnapshotCount,
      toAccountNewerBalanceSnapshotCount,
      startDateBalanceSnapshots: recommendedDataTransferDates?.snapshotsStartDate,
    });
  };

  return (
    <TransferAccountDataStep
      exitAccountId={fromAccountId}
      progress={getProgressPercent(3)}
      overrideTitle="Transfer balances"
      isLoading={loadingRecommendedDataTransferDates}
      footer={
        <PrimaryButton size="large" onClick={handleNext}>
          Next up: Data backup
        </PrimaryButton>
      }
    >
      <FlexContainer column marginTop="xxlarge" marginBottom="xxlarge" gap="xxlarge">
        <FlexContainer column gap="small">
          <Text size="large" weight="medium">
            Balances
          </Text>
          <RadioGroup
            value={transferBalanceSnapshots ? 'transferBalanceSnapshots' : 'skipBalanceSnapshots'}
            onChange={(value) =>
              updateParams({
                transferBalanceSnapshots: value === 'transferBalanceSnapshots',
              })
            }
            options={[
              {
                value: 'transferBalanceSnapshots',
                label: 'Move balance history to your new account',
                description:
                  'You can choose which day you want your balance history to move from your old account to your new account.',
                disabled: !hasBalanceSnapshotsToTransfer,
              },
              {
                value: 'skipBalanceSnapshots',
                label: 'Don’t move balance history',
                description: hasBalanceSnapshotsToTransfer
                  ? 'If your old account had inaccurate or missing balances and you don’t want those to be carried over to your new account'
                  : 'There’s no balance history on your old account to move. Continue to the next step to backup your data.',
              },
            ]}
          />
        </FlexContainer>
        {transferBalanceSnapshots && (
          <>
            <DatePicker
              title="Transition date"
              subtitle={`We recommend the day before your new account was created: ${isoDateToAbbreviatedMonthDayAndYear(
                recommendedDataTransferDates?.snapshotsEndDate ?? '',
              )}`}
              tooltip="Balance history on and before this date will be copied from your old account to your new account."
              tooltipMaxWidth={308} // Sized to keep the text at two lines
              recommendedDate={recommendedDataTransferDates?.snapshotsEndDate}
              endDate={endDateBalanceSnapshots}
              setEndDate={(date) => updateParams({ endDateBalanceSnapshots: date })}
              minDate={
                recommendedDataTransferDates?.snapshotsStartDate
                  ? parseISODate(recommendedDataTransferDates?.snapshotsStartDate)
                  : null
              }
            />
            <GraphRoot hideHeader>
              <CopyBalancePreviewGraph isLoading={isLoadingPreview} data={balanceDataPoints} />
            </GraphRoot>
            <StyledInformationList title="What will happen:">
              <InformationItem iconName="trash">
                <Text>
                  {toAccountExpectedAffectedBalanceSnapshotCount}{' '}
                  {pluralize('day', toAccountExpectedAffectedBalanceSnapshotCount)} of balances on
                  and before{' '}
                  <Text weight="medium">
                    {isoDateToAbbreviatedMonthDayAndYear(endDateBalanceSnapshots ?? '')}
                  </Text>{' '}
                  will be deleted on your new account
                </Text>
              </InformationItem>
              <InformationItem iconName="merge">
                <Text>
                  {fromAccountExpectedAffectedBalanceSnapshotCount}{' '}
                  {pluralize('day', fromAccountExpectedAffectedBalanceSnapshotCount)} of balances on
                  and before{' '}
                  <Text weight="medium">
                    {isoDateToAbbreviatedMonthDayAndYear(endDateBalanceSnapshots ?? '')}
                  </Text>{' '}
                  will be copied from your old account to your new account
                </Text>
              </InformationItem>
              <InformationItem iconName="check">
                <Text>
                  {toAccountNewerBalanceSnapshotCount}{' '}
                  {pluralize('day', toAccountNewerBalanceSnapshotCount)} of balances after{' '}
                  <Text weight="medium">
                    {isoDateToAbbreviatedMonthDayAndYear(endDateBalanceSnapshots ?? '')}
                  </Text>{' '}
                  will remain on your new account
                </Text>
              </InformationItem>
            </StyledInformationList>
          </>
        )}
      </FlexContainer>
    </TransferAccountDataStep>
  );
};

export default Snapshots;
