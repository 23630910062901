import React from 'react';
import styled from 'styled-components';

import {
  EmptyTransactionRow,
  TransactionRow,
} from 'components/accounts/transferAccountData/TransactionRow';

import type { Transaction, TransactionStatus } from 'lib/accounts/transferAccountData/types';

const List = styled.ul`
  padding: 0;
  margin: 0;
`;

type Props = {
  transactions: Transaction[];
  emptyRowCount: number;
  type: TransactionStatus;
};

export const SingleDayTransactions: React.FC<Props> = ({ transactions, emptyRowCount, type }) => (
  <List>
    {transactions.map((transaction) => (
      <TransactionRow key={transaction.id} transaction={transaction} type={type} />
    ))}
    {emptyRowCount > 0 &&
      Array.from({ length: emptyRowCount }).map((_, i) => (
        <EmptyTransactionRow key={`empty-${i}`} type={type} />
      ))}
  </List>
);
