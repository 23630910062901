import Cookies from 'js-cookie';
import uuid from 'uuid/v4';

import { getDeviceUUID as getDeviceUUIDSelector } from 'selectors';
import { store } from 'state/store';

import { onWarning } from './errors/onError';

const DEVICE_UUID_STORAGE_KEY = 'monarchDeviceUUID';

let inMemoryDeviceUUID: string | null = null;

const getDeviceUUID = (): string => {
  if (inMemoryDeviceUUID) {
    return inMemoryDeviceUUID;
  }

  inMemoryDeviceUUID = loadDeviceUUID();

  return inMemoryDeviceUUID;
};

const loadDeviceUUID = (): string => {
  const localStorageDeviceUUID = localStorage?.getItem(DEVICE_UUID_STORAGE_KEY);
  const cookieDeviceUUID = Cookies.get(DEVICE_UUID_STORAGE_KEY) as string | null;
  // deprecated store, use only if all others are empty
  const reduxPersistDeviceUUID = getDeviceUUIDSelector(store.getState());

  const deviceUUID = localStorageDeviceUUID || cookieDeviceUUID || reduxPersistDeviceUUID || uuid();

  if (deviceUUID !== localStorageDeviceUUID) {
    localStorage.setItem(DEVICE_UUID_STORAGE_KEY, deviceUUID);
    if (deviceUUID === cookieDeviceUUID) {
      onWarning('Device UUID was found in cookies but not localStorage');
    }
  }
  if (deviceUUID !== cookieDeviceUUID) {
    // keep in mind the frontend domain is different from the backend domain.
    // this cookie will not be added in API requests, this is purely for redundant storage.
    Cookies.set(DEVICE_UUID_STORAGE_KEY, deviceUUID, { sameSite: 'strict', expires: 365 });
    if (deviceUUID === localStorageDeviceUUID) {
      onWarning('Device UUID was found in localStorage but not cookies');
    }
  }
  // skip setting deprecated redux-persist store

  return deviceUUID;
};

export default getDeviceUUID;
