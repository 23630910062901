// eslint-disable-next-line @typescript-eslint/ban-types
import type { Maybe } from 'common/generated/graphql';

export type ArrayType<T extends Array<any>> = T extends Array<infer ItemT> ? ItemT : never;

type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T;

export const truthy = <T>(value: T): value is Truthy<T> => !!value;

/**
 * Given an object type T and a key K that exists on T,
 * this type will extract the element type of the array located
 * at T[K], after ensuring T[K] is non-nullable.
 *
 * This is useful when you want to infer the type of a single
 * element inside an array property of an object (i.e. GraphQL types).
 */
export type ElementOf<T, K extends keyof T> = NonNullable<T[K]> extends (infer E)[] ? E : never;

export type ConditionalReturnType<
  TrueT,
  FalseT,
  ConditionT extends boolean | undefined,
> = ConditionT extends true ? TrueT : FalseT;

export type OmitTypename<T> = Omit<T, '__typename'>;

/**
 * Represents a list of items that may be null or undefined (including the list itself).
 */
export type MaybeList<T> = Maybe<Maybe<T>[]>;

/**
 * Represents an object that has an `id` property.
 */
export type Identifiable = { id: GraphQlUUID | string };

export type HasTypename<T> = T extends { __typename: string } | { __typename: string }[]
  ? true
  : false;

// Given an object type T, extract all objects that have a `__typename` property.
export type ExtractTypedObjects<T> = Pick<
  T,
  {
    [K in keyof T]: HasTypename<NonNullable<T[K]>> extends true ? K : never;
  }[keyof T]
>;

/**
 * Given an union type T that contains objects with a `__typename` property,
 * extract the `__typename` property and return it as a union type.
 */
export type ExtractTypename<T> = T extends { __typename: infer U } ? U : never;

/**
 * Given an object type T, make a union type of all possible values of T.
 */
export type ValueOf<T> = T[keyof T];

/**
 * Extracts properties from type `T` whose keys start with 'include'.
 *
 * This type iterates over the keys of type `T` and selects those
 * keys which begin with the lowercase word 'include'. The resulting
 * type includes only these selected properties.
 * */
export type PickByIncludePrefix<T> = {
  [K in keyof T as K extends `include${infer Rest}` ? K : never]: T[K];
};

export type MarkOptional<T, K extends keyof T> = Omit<T, K> & {
  [P in K]?: T[P];
};

/**
 * Extracts keys from type T where the corresponding value is a Record (object) or undefined.
 *
 * This type utility iterates over all keys of T and creates a union type of those keys
 * whose values are either a Record<string, any> or undefined. It's useful for identifying
 * which properties of an object are themselves objects or potentially objects.
 *
 * @example
 * interface User {
 *   id: number;
 *   name: string;
 *   address: { street: string; city: string };
 *   settings?: { theme: string; notifications: boolean };
 *   friends: string[];
 * }
 *
 * type UserObjectValuedKeys = ObjectValuedKeys<User>;
 * // Result: "address" | "settings"
 * // Note: "friends" is not included because it's an array, not a Record
 */
export type ObjectValuedKeys<T> = NonNullable<
  {
    [K in keyof T]: T[K] extends Record<string, any> | undefined ? K : never;
  }[keyof T]
>;

/**
 * Extracts specific props from a React component type so you don't have to export the props manually.
 *
 * This type utility allows you to pick specific prop keys from a React component's props type.
 * It's useful when you want to reuse a subset of another component's props without
 * manually redefining their types.
 *
 * @example
 * interface ButtonProps {
 *   onClick: () => void;
 *   disabled: boolean;
 *   children: React.ReactNode;
 * }
 * const Button: React.FC<ButtonProps> = ...
 *
 * type PickedProps = PickPropsFromComponent<typeof Button, 'onClick' | 'disabled'>;
 * // Result: { onClick: () => void; disabled: boolean; }
 */
export type PickPropsFromComponent<
  T extends React.ComponentType<any>,
  K extends keyof React.ComponentProps<T>,
> = Pick<React.ComponentProps<T>, K>;

/**
 * Utility type that joins two strings with a dot separator.
 * If the first string is empty, no dot is added.
 *
 * type Result1 = DotPrefix<'parent', 'child'> // 'parent.child'
 * type Result2 = DotPrefix<'', 'child'> // 'child'
 */
type DotPrefix<T extends string, K extends string> = `${T}${'' extends T ? '' : '.'}${K}`;

/**
 * Recursively extracts all possible property paths from a type as dot-notation strings.
 *
 * Features:
 * - Handles nested objects by creating dot-separated paths
 * - Includes function properties as-is without their parameters
 * - Skips non-string keys and non-object types
 *
 * @example
 * interface User {
 *   name: string;
 *   age: number;
 *   address: {
 *     street: string;
 *     city: {
 *       name: string;
 *       country: string;
 *     };
 *   };
 *   save(): void;
 * }
 *
 * type UserPaths = StringObjectPaths<User>;
 * // Result:
 * // 'name' |
 * // 'age' |
 * // 'save' |
 * // 'address.street' |
 * // 'address.city.name' |
 * // 'address.city.country'
 */
export type StringObjectPaths<T> =
  T extends Record<string, any>
    ? {
        [K in keyof T]: K extends string
          ? T[K] extends (...args: any[]) => any
            ? K
            : T[K] extends Record<string, any>
              ? DotPrefix<K, StringObjectPaths<T[K]>>
              : K
          : never;
      }[keyof T]
    : '';
