import color from 'common/lib/theme/dynamic/color';

/**
 * Dynamic theme variables for the Monarch design system.
 *
 * Semantic and abstracted variables that can be used to style components.
 */
export const dynamicVariables = {
  color,
};
