import { useMutation } from '@apollo/client';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import AbstractField from 'components/lib/form/AbstractField';
import Form from 'components/lib/form/Form';
import SelectField from 'components/lib/form/SelectField';
import TextField from 'components/lib/form/TextField';
import OnboardingAbstractField from 'components/onboarding/OnboardingAbstractField';
import type { AbstractFieldChildrenRenderProps } from 'components/onboarding/OnboardingAbstractField';
import OnboardingFormContainer from 'components/onboarding/OnboardingFormContainer';
import OnboardingFormSubmitButton from 'components/onboarding/OnboardingFormSubmitButton';
import OnboardingSubtitle from 'components/onboarding/OnboardingSubtitle';
import OnboardingSurveyRadio from 'components/onboarding/OnboardingSurveyRadio';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';

import { setManageFinancesWithPartner, setTemporaryPromoCode } from 'actions';
import { throwIfHasMutationErrors } from 'common/lib/form/errors';
import { GET_ME, UPDATE_USER_PROFILE_MUTATION } from 'common/lib/graphQl/user';
import useQuery from 'common/lib/hooks/useQuery';
import { getCollaboratesOnFinancesValueFromAnswers } from 'common/lib/onboarding/Adapters';
import { demographicsValidationSchema } from 'common/lib/signup/SignupValidation';
import { variables } from 'common/lib/theme/dynamic';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import { getTemporaryPromoCode } from 'selectors';

import { ONBOARDING } from 'common/constants/copy';
import { COUNTRY_OPTIONS, DEFAULT_COUNTRY } from 'common/constants/onboarding/user';

import type {
  UpdateMeInput,
  UpdateMyHouseholdInput,
  UpdateUserProfileInput,
} from 'common/generated/graphql';

const SELECT_OPTIONS = [
  { name: 'yes', displayName: 'Yes' },
  { name: 'no', displayName: 'No' },
];

const QuestionContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const QuestionLabel = styled.label`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${variables.color.content.primary};
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

type Props = {
  next: () => void;
};
const WELCOME30_PROMO_CODE = 'WELCOME30';

const OnboardingDemographics = ({ next }: Props) => {
  const dispatch = useDispatch();

  const temporaryPromoCode = useSelector(getTemporaryPromoCode);
  // Checking for ab-test-goal-oriented-signup so it applies the WELCOME30 coupon before
  // the user sees the paywall
  const isGoalOrientedSignUpTestOn = useIsFeatureFlagOn('ab-test-goal-oriented-signup', {
    trackImpression: true,
  });

  if (isGoalOrientedSignUpTestOn && !temporaryPromoCode) {
    // sets the WELCOME30 promo code if the user has not applied another promo code
    // and the goal-oriented signup test is not off
    dispatch(setTemporaryPromoCode(WELCOME30_PROMO_CODE));
  }

  const { data, isLoadingInitialData } = useQuery(GET_ME);
  const { name } = data?.me ?? {};

  const [mutate] = useMutation(UPDATE_USER_PROFILE_MUTATION);

  /**
   *  Autofocus doesn't work with fields that have a "disabled" property.
   *
   *  To focus on the field when the page loads, we need to use a ref and useEffect based on what disable/enables the field.
   */
  const nameInputElementRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (nameInputElementRef.current) {
      nameInputElementRef.current.focus();
    }
  }, [isLoadingInitialData]);

  return (
    <>
      <OnboardingTitle>{ONBOARDING.DEMOGRAPHICS.TITLE}</OnboardingTitle>
      <OnboardingSubtitle>{ONBOARDING.DEMOGRAPHICS.SUBTITLE}</OnboardingSubtitle>
      <OnboardingFormContainer>
        <Form
          onSubmit={async ({
            name,
            manageWithPartner,
            manageWithProfessional,
            country,
          }: {
            name: string;
            manageWithPartner: Record<string, boolean>;
            manageWithProfessional: Record<string, boolean>;
            country: string;
          }) => {
            const manageWithPartnerValue = manageWithPartner.yes === true;
            const manageWithProfessionalValue = manageWithProfessional.yes === true;

            const { data } = await mutate({
              variables: {
                updateProfileInput: {
                  collaboratesOnFinancesDetailed: getCollaboratesOnFinancesValueFromAnswers(
                    manageWithPartnerValue,
                    manageWithProfessionalValue,
                  ),
                } as UpdateUserProfileInput,
                updateMeInput: { name } as UpdateMeInput,
                updateMyHouseholdInput: {
                  country,
                } as UpdateMyHouseholdInput,
              },
            });

            dispatch(setManageFinancesWithPartner(manageWithPartnerValue));

            throwIfHasMutationErrors(data);
            next();
          }}
          overrideValidationSchema={demographicsValidationSchema}
          initialValues={{
            name,
            country: DEFAULT_COUNTRY,
            manageWithPartner: {
              yes: false,
              no: false,
            },
            manageWithProfessional: {
              yes: false,
              no: false,
            },
          }}
        >
          <TextField
            name="name"
            label={ONBOARDING.DEMOGRAPHICS.FIELDS.NAME.LABEL}
            placeholder={ONBOARDING.DEMOGRAPHICS.FIELDS.NAME.PLACEHOLDER}
            disabled={isLoadingInitialData}
            innerRef={nameInputElementRef}
            autoFocus
            autoFocusOnMobile={false}
            required
          />
          <SelectField
            name="country"
            label={ONBOARDING.DEMOGRAPHICS.FIELDS.COUNTRY.LABEL}
            options={COUNTRY_OPTIONS}
          />
          <QuestionContainer>
            <QuestionLabel>
              {ONBOARDING.DEMOGRAPHICS.FIELDS.MANAGE_WITH_PARTNER.LABEL}
            </QuestionLabel>
            {SELECT_OPTIONS.map(({ name, displayName }) => (
              <OnboardingAbstractField name={`manageWithPartner.${name}`} hideLabel key={name}>
                {({ value, setFieldValue }: AbstractFieldChildrenRenderProps) => (
                  <OnboardingSurveyRadio
                    value={value}
                    onChange={(value) => {
                      // unselect all options so only one is selected at a time
                      SELECT_OPTIONS.forEach((option) => {
                        setFieldValue(`manageWithPartner.${option.name}`, false);
                      });
                      setFieldValue(`manageWithPartner.${name}`, value);
                    }}
                  >
                    {displayName}
                  </OnboardingSurveyRadio>
                )}
              </OnboardingAbstractField>
            ))}
          </QuestionContainer>
          <QuestionContainer>
            <QuestionLabel>
              {ONBOARDING.DEMOGRAPHICS.FIELDS.MANAGE_WITH_PROFESSIONAL.LABEL}
            </QuestionLabel>
            {SELECT_OPTIONS.map(({ name, displayName }) => (
              <AbstractField name={`manageWithProfessional.${name}`} hideLabel key={name}>
                {({ value, setFieldValue }) => (
                  <OnboardingSurveyRadio
                    value={value}
                    onChange={(value) => {
                      // unselect all options so only one is selected at a time
                      SELECT_OPTIONS.forEach((option) => {
                        setFieldValue(`manageWithProfessional.${option.name}`, false);
                      });
                      setFieldValue(`manageWithProfessional.${name}`, value);
                    }}
                  >
                    {displayName}
                  </OnboardingSurveyRadio>
                )}
              </AbstractField>
            ))}
          </QuestionContainer>
          <OnboardingFormSubmitButton>{ONBOARDING.DEMOGRAPHICS.BUTTON}</OnboardingFormSubmitButton>
        </Form>
      </OnboardingFormContainer>
    </>
  );
};

export default OnboardingDemographics;
