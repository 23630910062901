import React from 'react';
import styled from 'styled-components';

import Card from 'components/lib/ui/Card';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import { spacing, variables } from 'common/lib/theme/dynamic';

const StyledCard = styled(Card)`
  border: 1px solid ${variables.color.divider.secondary};
  overflow: hidden;
  margin-bottom: ${spacing.xxsmall};
`;

const ReviewCardHeaderWrapper = styled(FlexContainer).attrs({ column: true, gap: 'xsmall' })`
  padding: ${spacing.default};
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${variables.color.divider.secondary};
`;

type ReviewCardProps = {
  children: React.ReactNode;
};

const ReviewCard: React.FC<ReviewCardProps> = ({ children }) => <StyledCard>{children}</StyledCard>;

type ReviewCardHeaderProps = {
  title: string;
  children: React.ReactNode;
};

const ReviewCardHeader: React.FC<ReviewCardHeaderProps> = ({ title, children }) => (
  <ReviewCardHeaderWrapper>
    <Text weight="medium" size="large">
      {title}
    </Text>
    {children}
  </ReviewCardHeaderWrapper>
);

export default ReviewCard;
export { ReviewCardHeader };
