import type { Operation } from '@apollo/client';
import { ApolloClient, ApolloLink, from, split } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import type { ErrorResponse } from '@apollo/client/link/error';
import { onError as onApolloError } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { SentryLink } from 'apollo-link-sentry';
import { GraphQlError, containsErrorCode } from 'common/errors';
import { sort } from 'ramda';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { logout } from 'common/state/user/actions';
import clientPlatform from 'lib/analytics/clientPlatform';
import onError, { onWarning } from 'lib/errors/onError';
import getApiUrl, { getWsUrl } from 'lib/getApiUrl';
import getDeviceUUID from 'lib/getDeviceUUID';
import history from 'lib/history';
import isEnvDevelopment from 'lib/isEnvDevelopment';
import { errorToast } from 'lib/ui/toast';
import { getActAsUser } from 'state/multiHousehold/selectors';
import { store } from 'state/store';
import { getUser, getUserToken } from 'state/user/selectors';

import routes from 'constants/routes';

import fragments from 'common/generated/fragments.json';
import type { BudgetMonthlyAmounts } from 'common/generated/graphql';
import { ErrorCode } from 'common/generated/graphql';

const mergeMonthlyAmounts = (
  existing: Maybe<BudgetMonthlyAmounts[]>,
  incoming: Maybe<BudgetMonthlyAmounts[]>,
) => {
  // Convert arrays to objects, preferring incoming values over existing ones
  const merged: Record<string, BudgetMonthlyAmounts> = Object.fromEntries([
    ...(existing ?? []).map((item) => [item.month, item]),
    ...(incoming ?? []).map((item) => [item.month, item]),
  ]);

  return sort((a, b) => a.month.localeCompare(b.month), Object.values(merged));
};

const cache = new InMemoryCache({
  possibleTypes: fragments.possibleTypes,
  typePolicies: {
    BudgetCategoryMonthlyAmounts: {
      keyFields: ['category', ['id']],
      fields: {
        monthlyAmounts: {
          merge: mergeMonthlyAmounts,
        },
      },
    },
    BudgetCategoryGroupMonthlyAmounts: {
      keyFields: ['categoryGroup', ['id']],
      fields: {
        monthlyAmounts: {
          merge: mergeMonthlyAmounts,
        },
      },
    },
    BudgetMonthTotals: {
      keyFields: ['month'],
      fields: {
        monthlyAmounts: {
          merge: mergeMonthlyAmounts,
        },
      },
    },
    BudgetFlexMonthlyAmounts: {
      keyFields: ['budgetVariability'],
      fields: {
        monthlyAmounts: {
          merge: mergeMonthlyAmounts,
        },
      },
    },
  },
});

const errorLink = onApolloError((errorResponse: ErrorResponse) => {
  // We are verbosely sending all graphql errors to Sentry. This might result in some duplication
  // so we can peel this back as needed.
  const {
    graphQLErrors,
    networkError,
    operation: { operationName },
  } = errorResponse;

  if (networkError && 'statusCode' in networkError) {
    if (networkError.statusCode === 401) {
      store.dispatch(logout());
      return;
    }
  }

  if (graphQLErrors) {
    if (containsErrorCode(graphQLErrors, ErrorCode.TOO_MANY_REQUESTS)) {
      errorToast('Too many requests. Please try again later.');
      return;
    } else if (graphQLErrors && containsErrorCode(graphQLErrors, ErrorCode.SUBSCRIPTION_ENDED)) {
      history.push(routes.settings.billing.subscriptionEnded());
      return;
    } else if (graphQLErrors && containsErrorCode(graphQLErrors, ErrorCode.USER_IS_ONBOARDING)) {
      history.push(
        routes.signup.premiumUpsell({
          queryParams: { back: false, skippable: true },
        }),
      );
      return;
    }

    onError(new GraphQlError(operationName, { graphQLErrors, networkError }), operationName);
  } else if (networkError) {
    onWarning(
      `[GraphQl network error]: '${networkError}' on operation: ${operationName}`,
      operationName,
    );
  }
});

const request = (operation: Operation) => {
  const state = store.getState();
  const user = getUser(state);
  const token = getUserToken(state);
  const actAsUser = getActAsUser(state);
  const deviceUUID = getDeviceUUID();

  operation.setContext({
    headers: {
      authorization: `Token ${user ? token : ''}`,
      'Client-Platform': clientPlatform,
      'Device-UUID': deviceUUID,
      ...(actAsUser ? { 'Act-As-User': actAsUser } : {}),
    },
  });
};

const httpLink = new HttpLink({ uri: (_) => `${getApiUrl()}/graphql` });

const wsLink = new WebSocketLink(
  new SubscriptionClient(`${getWsUrl()}/subscriptions`, {
    reconnect: true,
    lazy: true,
    connectionParams: () => {
      const state = store.getState();
      const token = getUserToken(state);

      if (token) {
        return { token };
      }

      return {};
    },
  }),
);

/**
 * Sends query/mutation requests to the httpLink and subscription requests to the wsLink.
 */
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const requestLink = new ApolloLink((operation, forward) => {
  if (isEnvDevelopment()) {
    // eslint-disable-next-line no-console
    console.debug('GraphQL:', operation.operationName, operation.variables);
  }

  request(operation);
  return forward(operation);
});

const sentryLink = new SentryLink({
  // we disable this library's management of transaction and fingerprint assignment
  // due to undesirable side effects. we implement these ourselves elsewhere.
  setFingerprint: false,
  setTransaction: false,
  uri: `${getApiUrl()}/graphql`,
  attachBreadcrumbs: {
    includeQuery: true,
    includeError: true,
  },
});

const client = new ApolloClient({
  link: from([sentryLink, errorLink, requestLink, splitLink]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

if (isEnvDevelopment()) {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

export default client;
