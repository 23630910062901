import { useMutation, gql } from '@apollo/client';
import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import OnboardingAbstractField from 'components/onboarding/OnboardingAbstractField';
import type { AbstractFieldChildrenRenderProps } from 'components/onboarding/OnboardingAbstractField';
import OnboardingFormContainer from 'components/onboarding/OnboardingFormContainer';
import OnboardingFormSubmitButton from 'components/onboarding/OnboardingFormSubmitButton';
import OnboardingSurveyRadio from 'components/onboarding/OnboardingSurveyRadio';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';

import useQuery from 'common/lib/hooks/useQuery';
import { moveItemToLastByIndex } from 'common/utils/Array';
import { shuffleArray } from 'common/utils/Random';

import { ONBOARDING } from 'common/constants/copy';

import type {
  Web_GetUserReportedOnboardingAttributionChannels,
  Web_GetUserReportedOnboardingAttributionChannels_userReportedOnboardingAttributionChannels,
} from 'common/generated/graphQlTypes/Web_GetUserReportedOnboardingAttributionChannels';
import type {
  Web_SetUserReportedAttributionChannels,
  Web_SetUserReportedAttributionChannelsVariables,
} from 'common/generated/graphQlTypes/Web_SetUserReportedAttributionChannels';

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-top: ${({ theme }) => theme.spacing.xxxlarge};
`;

type Props = {
  next: () => void;
};

const OnboardingAttribution = ({ next }: Props) => {
  const OTHER_OPTION_NAME = 'other';

  const { data: { userReportedOnboardingAttributionChannels = [] } = {}, isLoadingInitialData } =
    useQuery<Web_GetUserReportedOnboardingAttributionChannels>(QUERY);
  const [setUserOnboardingAttributionChannels] = useMutation<
    Web_SetUserReportedAttributionChannels,
    Web_SetUserReportedAttributionChannelsVariables
  >(MUTATION);

  const randomizedAttributionChannels = useMemo(() => {
    const shuffledArray = shuffleArray(userReportedOnboardingAttributionChannels);

    // find the "other" option and set it as the last item in the array
    const otherOptionIndex = shuffledArray.findIndex(
      (
        attributionChannel: Web_GetUserReportedOnboardingAttributionChannels_userReportedOnboardingAttributionChannels,
      ) => attributionChannel.name === OTHER_OPTION_NAME,
    );

    return moveItemToLastByIndex(shuffledArray, otherOptionIndex);
  }, [userReportedOnboardingAttributionChannels]);

  return isLoadingInitialData ? (
    <StyledLoadingSpinner />
  ) : (
    <>
      <OnboardingTitle>{ONBOARDING.ATTRIBUTION.TITLE}</OnboardingTitle>
      <OnboardingFormContainer>
        <Form
          onSubmit={async (values: Record<string, boolean>) => {
            const priorities = R.toPairs(values)
              .filter(([, value]) => value)
              .map(([key]) => key);

            await setUserOnboardingAttributionChannels({
              variables: {
                priorities,
              },
            });
          }}
          onSubmitSuccess={() => next()}
        >
          {randomizedAttributionChannels.map(({ name, displayName }) => (
            <OnboardingAbstractField name={name} hideLabel key={name}>
              {({ value, setFieldValue }: AbstractFieldChildrenRenderProps) => (
                <OnboardingSurveyRadio
                  value={value}
                  onChange={(value) => {
                    // unselect all attribution channels so only one is selected at a time
                    randomizedAttributionChannels.forEach((attributionChannel) => {
                      setFieldValue(attributionChannel.name, false);
                    });
                    setFieldValue(name, value);
                  }}
                >
                  {displayName}
                </OnboardingSurveyRadio>
              )}
            </OnboardingAbstractField>
          ))}
          <OnboardingFormSubmitButton disableWhenValuesUnchanged={false}>
            {ONBOARDING.ATTRIBUTION.BUTTON}
          </OnboardingFormSubmitButton>
        </Form>
      </OnboardingFormContainer>
    </>
  );
};

const QUERY = gql`
  query Web_GetUserReportedOnboardingAttributionChannels {
    userReportedOnboardingAttributionChannels {
      name
      displayName
    }
  }
`;

const MUTATION = gql`
  mutation Web_SetUserReportedAttributionChannels($priorities: [String]!) {
    updateUserProfile(input: { userReportedAttributionChannels: $priorities }) {
      userProfile {
        id
        userReportedAttributionChannels
      }
    }
  }
`;

export default OnboardingAttribution;
