import { transparentize } from 'polished';
import styled from 'styled-components';

import ReactToggle from 'components/lib/ui/ReactToggle';

const Toggle = styled(ReactToggle).attrs({ icons: false })<{
  large?: boolean;
}>`
  &.react-toggle--disabled {
    opacity: 1;
  }

  .react-toggle-track {
    ${({ theme, large }) =>
      `
    width: ${large ? '40px' : theme.spacing.xxlarge};
    height: ${large ? theme.spacing.large : theme.spacing.default};
  `}

    border-radius: ${({ theme }) => theme.radius.pill};
    background-color: ${({ theme }) => theme.color.gray};
  }

  .react-toggle-thumb {
    ${({ large }) =>
      `
    width: ${large ? '14px' : '10px'};
    height: ${large ? '14px' : '10px'};
    left: ${large ? '14px' : '10px'};
  `}

    left: 3px;
    top: 3px;
    background: ${({ theme }) => theme.color.white};
    border: none;
  }

  .react-toggle-thumb:focus {
    outline: none;
  }

  &.react-toggle--checked {
    &:not(.react-toggle--disabled) .react-toggle-track {
      background-color: ${({ theme }) => theme.color.orange};
    }

    &.react-toggle--disabled .react-toggle-track {
      background-color: ${({ theme }) => transparentize(0.5, theme.color.orange)};
    }

    .react-toggle-thumb {
      left: ${({ large }) => (large ? '23px' : '19px')};
    }
  }

  &.react-toggle--focus .react-toggle-thumb {
    box-shadow: none;
  }

  &.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: none;
  }

  .react-toggle.react-toggle--disabled {
    .react-toggle-track {
      background-color: ${({ theme }) => theme.color.grayLight};
    }
  }

  &.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${({ theme }) => theme.color.grayDark};
  }

  &.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${({ theme }) => theme.color.orangeDark};
  }
`;
export default Toggle;
