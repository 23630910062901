import styled from 'styled-components';

import AbstractField from 'components/lib/form/AbstractField';
import type { ChildrenRenderProps } from 'components/lib/form/AbstractField';

import { spacing } from 'common/lib/theme/dynamic';

export { ChildrenRenderProps as AbstractFieldChildrenRenderProps };

const OnboardingAbstractField = styled(AbstractField)`
  /* Complex selector only to override specificity of selector in FormItemContainer */
  &:not(:last-child) {
    margin-bottom: ${spacing.xsmall};
  }

  /* The fixed footer is adjacent to the form items, but has a fixed position.
  There should not be a margin after the last form item if the next one is a footer.
  Footer uses a footer html element, :last-of-type works here to get the last form item
  that is not the footer. */
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export default OnboardingAbstractField;
