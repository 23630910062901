import * as React from 'react';
import styled from 'styled-components';

import Text from 'components/lib/ui/Text';

import { radius, spacing, variables } from 'common/lib/theme/dynamic';

import type { ColorVariablePaths } from 'common/types/Styles';

const Root = styled.div`
  background-color: ${variables.color.background.secondary};
  border-radius: ${radius.medium};
  padding: ${spacing.xlarge};
  text-align: center;
  flex-grow: 1;
  width: 100%;
`;

const Label = styled(Text).attrs({
  weight: 'medium',
  size: 'xsmall',
  colorVariable: 'content.secondary',
})`
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  margin-top: ${spacing.xsmall};
`;

type Props = {
  colorVariable?: ColorVariablePaths;
  label: string;
  value: string;
};

export const Box = ({ colorVariable = 'content.primary', label, value }: Props) => (
  <Root>
    <Text size="xxlarge" weight="medium" colorVariable={colorVariable}>
      {value}
    </Text>
    <Label>{label}</Label>
  </Root>
);
