import * as Sentry from '@sentry/browser';

import { identifyUserToAppsFlyer } from 'lib/analytics/appsFlyer';
import { identifyUserToCustomerIO } from 'lib/analytics/customerio';
import { identifyUserToSegment } from 'lib/analytics/segment';
import { identifyUserToUserLeap } from 'lib/analytics/userLeap';
import { store } from 'state/store';
import { getIsDemo } from 'state/user/selectors';

import type { UserHouseholdRole } from 'common/generated/graphQlTypes/globalTypes';

const identifyUser = (id: string, email: string, householdRole?: UserHouseholdRole) => {
  const state = store.getState();
  const isDemo = getIsDemo(state);

  if (isDemo) {
    return;
  }

  Sentry.setUser({ id, email });
  identifyUserToSegment(id, email, householdRole);
  identifyUserToUserLeap(id, email);
  identifyUserToAppsFlyer(id);
  identifyUserToCustomerIO(id, email);
};

export default identifyUser;
