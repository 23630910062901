import type { Common_GetOnboardingFinancialGoalsQuery } from 'common/generated/graphql';

export const getInitialGoalValues = (
  queryData: Common_GetOnboardingFinancialGoalsQuery | undefined,
) => {
  const userGoals = queryData?.userOnboardingFinancialGoals ?? [];
  return userGoals.reduce(
    (acc, { onboardingFinancialGoal: { id }, otherText }) => ({
      ...acc,
      [id]: true,
      ...(otherText ? { otherText } : {}),
    }),
    {},
  );
};

export const getOtherGoalId = (queryData: Common_GetOnboardingFinancialGoalsQuery | undefined) => {
  if (!queryData) {
    return undefined;
  }

  const otherGoal = queryData.onboardingFinancialGoals.find(({ name }) => name === 'Other');

  return otherGoal?.id;
};
