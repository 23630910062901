import React from 'react';
import styled from 'styled-components';

import Icon from 'components/lib/ui/Icon';

import { color, radius, transition } from 'common/lib/theme/dynamic';

import { TRANSACTION_ROW_ICON_SIZE_PX } from 'constants/transactions';

const SplitIcon = styled(Icon).attrs({ name: 'split', size: 14 })``;

const Root = styled.div<{ $clickable?: boolean }>`
  width: ${TRANSACTION_ROW_ICON_SIZE_PX}px;
  height: ${TRANSACTION_ROW_ICON_SIZE_PX}px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};
  border-radius: ${radius.round};
  transition: ${transition.fast};

  :hover {
    background: ${color.grayBackground};

    ${SplitIcon} {
      color: ${color.text};
    }
  }
`;

type Props = {
  className?: string;
  onClick?: () => void;
};

const TransactionSplitIcon: React.FC<Props> = ({ onClick, className }) => (
  <Root className={className} onClick={onClick} role="button" $clickable={!!onClick}>
    <SplitIcon />
  </Root>
);

export default TransactionSplitIcon;
