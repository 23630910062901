import { isNotNil } from 'ramda-adjunct';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import Banner from 'components/lib/ui/Banner';
import CardBody from 'components/lib/ui/CardBody';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import DangerButton from 'components/lib/ui/button/DangerButton';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import ReportViewOverview from 'components/reports/ReportViewOverview';
import TransactionFiltersOverview from 'components/transactions/TransactionFiltersOverview';

import { spacing } from 'common/lib/theme/dynamic';
import { useFlowContext } from 'lib/contexts/FlowContext';
import { areFiltersEquivalent } from 'lib/filters/utils';
import useTrack from 'lib/hooks/useTrack';
import { areReportViewsEquivalent, getReportFiltersForTransactionFilterSet } from 'lib/reports';
import { convertFiltersToInput } from 'lib/transactions/Filters';

import { ReportsEventNames } from 'common/constants/analytics';

import type { ReportConfiguration, ReportView } from 'common/generated/graphql';
import type { TransactionFilters } from 'types/filters';

const StyledCardBody = styled(CardBody)`
  gap: ${spacing.xlarge};
`;

const StyledTextField = styled(TextField)`
  :not(:last-child) {
    margin-bottom: 0;
  }
`;

const BannerText = styled(Text)`
  display: block;
  overflow-wrap: break-word;

  :not(:last-child) {
    margin-bottom: ${spacing.xsmall};
  }
`;

const SameReportConfigurationBanner = ({ reportName }: { reportName: string }) => (
  <Banner type="error">
    <BannerText>
      This report has the same configuration as your existing saved report{' '}
      <strong>{reportName}</strong>.
    </BannerText>
    <BannerText>Change the date range, filters, or chart view to save.</BannerText>
  </Banner>
);

const ExistingReportNameBanner = () => (
  <Banner type="error">
    <BannerText>This report uses the same name as an existing saved report.</BannerText>
    <BannerText>Use a different name to save.</BannerText>
  </Banner>
);

type BaseProps = {
  reportView: ReportView;
  filters: Partial<TransactionFilters>;
  reportConfigurations: ReportConfiguration[];
  onDeleteReportConfiguration: (reportConfiguration: ReportConfiguration) => void;
};

type SaveReportConfigurationInnerFormProps = BaseProps & {
  editingReportConfiguration: ReportConfiguration | undefined;
};

export type FormValues = {
  displayName: string;
  includeReportView: boolean;
};

const SaveReportConfigurationInnerForm = ({
  reportView,
  filters,
  reportConfigurations,
  editingReportConfiguration,
  onDeleteReportConfiguration,
}: SaveReportConfigurationInnerFormProps) => {
  const { next, skipToComplete } = useFlowContext();
  const { initialValues, values, isSubmitting } = useFormContext<FormValues>();

  // We only care about comparing report configurations internals when creating a new one
  const sameReportConfiguration = useMemo(
    () =>
      !editingReportConfiguration
        ? reportConfigurations.find(
            (reportConfiguration: ReportConfiguration) =>
              areFiltersEquivalent(
                convertFiltersToInput(filters),
                reportConfiguration.transactionFilterSet,
              ) &&
              areReportViewsEquivalent(
                values.includeReportView ? reportView : undefined,
                reportConfiguration.reportView,
              ),
          )
        : undefined,
    [
      editingReportConfiguration,
      reportConfigurations,
      filters,
      values.includeReportView,
      reportView,
    ],
  );

  const cleanDisplayName = values.displayName?.trim() ?? '';

  const usesExistingReportName = useMemo(
    () =>
      reportConfigurations.some(
        (reportConfiguration: ReportConfiguration) =>
          reportConfiguration.displayName === cleanDisplayName &&
          reportConfiguration.displayName !== initialValues.displayName,
      ),
    [reportConfigurations, cleanDisplayName, initialValues],
  );

  const displayedFilters = useMemo(
    () =>
      editingReportConfiguration
        ? getReportFiltersForTransactionFilterSet(editingReportConfiguration.transactionFilterSet)
        : filters,
    [editingReportConfiguration, filters],
  );

  const handleDelete = () => {
    next({
      reportName: editingReportConfiguration?.displayName,
      onDelete: () => {
        if (editingReportConfiguration) {
          onDeleteReportConfiguration(editingReportConfiguration);
        }
      },
    });
  };

  return (
    <>
      <StyledCardBody>
        {!!sameReportConfiguration && (
          <SameReportConfigurationBanner reportName={sameReportConfiguration.displayName} />
        )}
        {usesExistingReportName && <ExistingReportNameBanner />}
        <StyledTextField name="displayName" label="Report name" placeholder="Enter report name" />
        <TransactionFiltersOverview filters={displayedFilters} />
        <ReportViewOverview
          reportView={reportView}
          editingReportConfiguration={editingReportConfiguration}
        />
      </StyledCardBody>
      <CardFooter justifyEnd>
        {editingReportConfiguration && <DangerButton onClick={handleDelete}>Delete</DangerButton>}
        <DefaultButton onClick={skipToComplete}>Cancel</DefaultButton>
        <FormSubmitButton
          size="small"
          pending={isSubmitting}
          disabled={
            isNotNil(sameReportConfiguration) || usesExistingReportName || cleanDisplayName === ''
          }
        >
          Save
        </FormSubmitButton>
      </CardFooter>
    </>
  );
};

export type SaveReportConfigurationModalProps = BaseProps & {
  editingReportConfigurationId?: Maybe<string>;
  reportConfigurations: ReportConfiguration[];
  onSubmit: (values: FormValues) => void;
};

const SaveReportConfigurationModal = ({
  reportView,
  filters,
  editingReportConfigurationId,
  reportConfigurations,
  onDeleteReportConfiguration,
  onSubmit,
}: SaveReportConfigurationModalProps) => {
  useTrack(
    editingReportConfigurationId
      ? ReportsEventNames.ReportConfigurationUpdateStarted
      : ReportsEventNames.ReportConfigurationCreationStarted,
  );

  const { skipToComplete } = useFlowContext();

  const editingReportConfiguration = useMemo(
    () =>
      reportConfigurations.find(
        (reportConfiguration: ReportConfiguration) =>
          reportConfiguration.id === editingReportConfigurationId,
      ),
    [reportConfigurations, editingReportConfigurationId],
  );

  return (
    <ModalCard title={`${editingReportConfigurationId ? 'Edit' : 'Create'} saved report`}>
      <Form
        initialValues={{
          displayName: editingReportConfiguration?.displayName,
          includeReportView: true,
        }}
        onSubmit={onSubmit}
        onSubmitSuccess={skipToComplete}
      >
        <SaveReportConfigurationInnerForm
          reportView={reportView}
          filters={filters}
          reportConfigurations={reportConfigurations}
          editingReportConfiguration={editingReportConfiguration}
          onDeleteReportConfiguration={onDeleteReportConfiguration}
        />
      </Form>
    </ModalCard>
  );
};

export default SaveReportConfigurationModal;
