import { ellipsis } from 'polished';
import React from 'react';
import type { Place } from 'react-tooltip';
import styled from 'styled-components';

import { ClickTriggeredTooltip } from 'components/lib/ui/ClickTriggeredTooltip';
import FlexContainer from 'components/lib/ui/FlexContainer';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { copyToClipboard } from 'utils/clipboard';

const Root = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })<{
  $hideBorder?: boolean;
}>`
  border: 1px solid
    ${({ $hideBorder, theme }) => ($hideBorder ? 'transparent' : theme.color.grayFocus)};
  border-radius: ${({ theme }) => theme.radius.small};
  padding: ${({ theme }) => theme.spacing.xsmall};
  padding-left: ${({ theme }) => theme.spacing.small};
  min-width: 0;
`;

const Content = styled.div`
  text-align: left;
  margin-right: ${({ theme }) => theme.spacing.xsmall};
  ${ellipsis()}
`;

const DisabledTransitionTooltip = styled(ClickTriggeredTooltip)`
  transition: unset;
`;

const ClickToCopy = ({
  children,
  className,
  hideBorder,
  hideButton,
  afterCopy,
  tooltipPlace = 'top',
}: {
  children: string;
  className?: string;
  hideBorder?: boolean;
  hideButton?: boolean;
  afterCopy?: () => void;
  tooltipPlace?: Place;
}) => (
  <Root className={className} $hideBorder={hideBorder}>
    <Content>{children}</Content>
    {!hideButton && (
      <DisabledTransitionTooltip
        content="Copied to clipboard"
        place={tooltipPlace}
        afterShow={() => {
          copyToClipboard(children);
          afterCopy?.();
        }}
      >
        <DefaultButton>Copy link</DefaultButton>
      </DisabledTransitionTooltip>
    )}
  </Root>
);

export default ClickToCopy;
