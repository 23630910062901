import _ from 'lodash';
import type { DateTime } from 'luxon';
import * as R from 'ramda';

import type { ExtendedCategoryGroupType } from 'common/lib/budget/Amounts';
import {
  BUDGET_VARIABILITY_TO_TITLE_MAPPING,
  EXCESS_BUDGET_TYPE,
  SAVINGS_PLAN_SECTION_TYPE,
} from 'common/lib/budget/constants';
import { filterOutArchivedGoals } from 'common/lib/goalsV2/adapters';

import type { BudgetMonthlyAmounts, Common_BudgetDataQuery, Maybe } from 'common/generated/graphql';
import { BudgetSystem, BudgetVariability, CategoryGroupType } from 'common/generated/graphql';
import type { ElementOf, MarkOptional } from 'common/types/utility';

type CategoryGroupAmounts = Omit<BudgetMonthlyAmounts, 'plannedAmount'>;

type SummaryBudgetVariability = SummaryDisplayRow & {
  name: string;
  type: BudgetVariability;
};

export type SummaryDisplayBudgetType = {
  name: string | undefined;
  budgeted: Maybe<number>;
  actual: Maybe<number>;
  rollover?: Maybe<number>;
  remaining?: Maybe<number>;
  // income, expenses, excess, goals(maybe)
  type: string | undefined;
  budgetVariabilities?: SummaryBudgetVariability[];
  // Only needed for the Income and Expenses tabs
  categoryGroups?: Record<string, { name: string } & MarkOptional<SummaryDisplayRow, 'remaining'>>;
};

export type SummaryDisplayRow = {
  budgeted: Maybe<number>;
  actual: Maybe<number>;
  remaining: Maybe<number>;
  rollover?: Maybe<number>;
};

/**
 * This is the type of the amounts returned from the query.
 */
type QueryAmounts = ElementOf<
  Common_BudgetDataQuery['budgetData'],
  'totalsByMonth'
>['totalExpenses'];

// This is the starting point for the budget summary data
export const budgetSummaryAdapter = (
  data: Common_BudgetDataQuery,
  date: DateTime,
): SummaryDisplayBudgetType[] => {
  const { budgetSystem, budgetData } = data;

  const isUsingFixedFlexBudgeting = budgetSystem === BudgetSystem.FIXED_AND_FLEX;
  const { totalsByMonth } = budgetData;

  const isoDate = date.toISODate();

  const totals = totalsByMonth.find(R.propEq('month', isoDate));

  const goalAggregateAmounts = getGoalV2AggregateAmounts(data, date);
  const includeGoals = !!goalAggregateAmounts?.budgeted;

  const getCategoryGroupTotalsForSection = createCategoryGroupTotalsGetter(data, isoDate);

  return [
    {
      name: 'Income',
      type: CategoryGroupType.INCOME,
      ...mapBudgetTotals(totals?.totalIncome),
      categoryGroups: getCategoryGroupTotalsForSection(CategoryGroupType.INCOME),
    },
    {
      name: 'Expenses',
      type: CategoryGroupType.EXPENSE,
      ...mapBudgetTotals(totals?.totalExpenses),
      budgetVariabilities: isUsingFixedFlexBudgeting
        ? [
            {
              name: BUDGET_VARIABILITY_TO_TITLE_MAPPING[BudgetVariability.FIXED],
              type: BudgetVariability.FIXED,
              ...mapBudgetTotals(totals?.totalFixedExpenses),
            },
            {
              name: BUDGET_VARIABILITY_TO_TITLE_MAPPING[BudgetVariability.FLEXIBLE],
              type: BudgetVariability.FLEXIBLE,
              ...mapBudgetTotals(totals?.totalFlexibleExpenses),
            },
            {
              name: BUDGET_VARIABILITY_TO_TITLE_MAPPING[BudgetVariability.NON_MONTHLY],
              type: BudgetVariability.NON_MONTHLY,
              ...mapBudgetTotals(totals?.totalNonMonthlyExpenses),
            },
          ]
        : undefined,
      categoryGroups: getCategoryGroupTotalsForSection(CategoryGroupType.EXPENSE),
    },
    ...(includeGoals
      ? [
          {
            name: 'Goals',
            type: SAVINGS_PLAN_SECTION_TYPE,
            ...goalAggregateAmounts,
          },
        ]
      : []),
    {
      name: '',
      type: EXCESS_BUDGET_TYPE,
      // Excess amount is: planned income - planned expenses - planned goals
      budgeted:
        (totals?.totalIncome?.plannedAmount ?? 0) -
        (totals?.totalExpenses?.plannedAmount ?? 0) -
        (goalAggregateAmounts?.budgeted ?? 0),
      actual: null, // this is only needed for TS, it's not actually used
    },
  ];
};

/**
 * Creates a function that retrieves category group totals for a specific section type and date.
 *
 * The returned function finds relevant groups for the given section type,
 * then aggregates their totals for the specified date.
 */
const createCategoryGroupTotalsGetter =
  (data: Common_BudgetDataQuery, isoDate?: string) => (sectionType: ExtendedCategoryGroupType) => {
    // First get the category groups for the section type
    const relatedGroups = data.categoryGroups.filter(({ type }) => type === sectionType);

    // Get the monthly amounts for each category group
    const amountsByCategoryGroupId = data.budgetData.monthlyAmountsByCategoryGroup.reduce(
      (acc, { categoryGroup, monthlyAmounts }) => {
        // If we have a date, we want to get the amounts for that date.
        // (used in web since we fetch data for multiple months)
        if (isoDate) {
          const amounts = monthlyAmounts.find(R.propEq('month', isoDate));
          if (amounts) {
            acc[categoryGroup.id] = amounts;
          }
        } else {
          // If we don't have a date, we want to get the first month's amounts. (used in mobile)
          const [firstMonthAmount] = monthlyAmounts;
          acc[categoryGroup.id] = firstMonthAmount;
        }
        return acc;
      },
      {} as Record<string, CategoryGroupAmounts>,
    );

    // Now that we have the groups, get the totals for each group and date
    return relatedGroups?.reduce(
      (acc, { id, name, groupLevelBudgetingEnabled, categories }) => {
        if (!acc) {
          acc = {};
        }

        const amounts = amountsByCategoryGroupId[id] as Maybe<CategoryGroupAmounts>;

        acc[id] = {
          name,
          budgeted: amounts?.plannedCashFlowAmount,
          actual: amounts?.actualAmount,
          remaining: amounts?.remainingAmount,
          rollover: amounts?.previousMonthRolloverAmount ?? 0,
        };

        return acc;
      },
      {} as SummaryDisplayBudgetType['categoryGroups'],
    );
  };

export const mapBudgetTotals = (amounts?: QueryAmounts) => ({
  budgeted: amounts?.plannedAmount ?? null,
  actual: amounts?.actualAmount ?? null,
  remaining: amounts?.remainingAmount,
  rollover: amounts?.previousMonthRolloverAmount,
});

const getGoalV2AggregateAmounts = (data: Common_BudgetDataQuery, date: DateTime) => {
  const { goalsV2 = [] } = data;
  const byDate = R.propEq('month', date.toISODate());
  const initialAmounts = { budgeted: 0, actual: 0, remaining: 0 };

  return filterOutArchivedGoals(goalsV2).reduce((acc, goal) => {
    const goalBudgeted = goal.plannedContributions.find(byDate)?.amount ?? 0;
    const goalActual = goal.monthlyContributionSummaries.find(byDate)?.sum ?? 0;
    const budgeted = acc.budgeted + goalBudgeted;
    const actual = acc.actual + goalActual;
    const remaining = budgeted - actual;

    return { budgeted, actual, remaining };
  }, initialAmounts);
};
