import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  AccountColumn,
  ColumnWrapper as AccountColumnWrapper,
} from 'components/accounts/transferAccountData/AccountColumn';
import CheckCircle from 'components/lib/ui/CheckCircle';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import RouteLink from 'components/lib/ui/link/RouteLink';
import type { StepProps } from 'components/routes/accounts/transferAccountData/TransferAccountData';
import TransferAccountDataStep from 'components/routes/accounts/transferAccountData/TransferAccountDataStep';

import { spacing, variables } from 'common/lib/theme/dynamic';
import { getProgressPercent } from 'lib/accounts/transferAccountData/utils/progress';
import useDataTransferAccounts from 'lib/hooks/accounts/useDataTransferAccounts';

import routes from 'constants/routes';

const StyledCheckCircle = styled(CheckCircle).attrs({
  iconSizePx: 30,
})`
  background-color: ${variables.color.content.success};
  width: 60px;
  height: 60px;
  margin-bottom: ${spacing.small};
`;

const TitleText = styled(Text)`
  margin-bottom: ${spacing.xxsmall};
`;

const ResultsAccountWrapper = styled(FlexContainer)`
  width: 100%;
  margin-top: ${spacing.xlarge};

  ${AccountColumnWrapper} {
    width: calc(50% - ${spacing.gutter} / 2);
    min-width: 0;
    flex: 0 0 calc(50% - ${spacing.gutter} / 2);
  }
`;

const Results: React.FC<StepProps> = ({ params }) => {
  const { fromAccountId, toAccountId } = params;
  const { push } = useHistory();
  const { data: accountsForTransfer, loading: loadingAccountsForTransfer } =
    useDataTransferAccounts(fromAccountId, toAccountId);

  return (
    <TransferAccountDataStep
      exitAccountId={fromAccountId}
      overrideTitle="Results"
      progress={getProgressPercent(6)}
      isLoading={loadingAccountsForTransfer || !fromAccountId || !toAccountId}
      showBackButton={false}
      footer={
        <PrimaryButton
          size="large"
          onClick={() => push(routes.accounts.accountDetails({ id: toAccountId! }))}
        >
          View new account
        </PrimaryButton>
      }
    >
      <FlexContainer center column marginTop="xxlarge" marginBottom="xxlarge">
        <StyledCheckCircle />
        <TitleText size="xlarge" weight="bold">
          Your data has been transferred!
        </TitleText>
        <FlexContainer column gap="small">
          <Text align="center">
            Check out your{' '}
            <RouteLink to={routes.accounts.accountDetails({ id: toAccountId! })}>
              new account
            </RouteLink>{' '}
            to ensure everything looks correct. If it does, you can delete your old account.
          </Text>
          <Text align="center">
            Reminder: if anything doesn&rsquo;t look right, you can reference the CSVs you
            downloaded before the transfer.
          </Text>
        </FlexContainer>
        <ResultsAccountWrapper gap="gutter">
          <AccountColumn
            title="Old"
            subtitle="(We moved data from this account)"
            account={accountsForTransfer?.fromAccount}
            titleSpacing="small"
            shadow="small"
          />
          <AccountColumn
            title="New"
            subtitle="(We moved data to this account)"
            account={accountsForTransfer?.toAccount}
            titleSpacing="small"
            shadow="small"
          />
        </ResultsAccountWrapper>
      </FlexContainer>
    </TransferAccountDataStep>
  );
};

export default Results;
