import downloadFile from 'js-file-download';
import { useCallback } from 'react';

import { track } from 'lib/analytics/segment';
import api from 'lib/api';
import useToast from 'lib/hooks/useToast';

import { AccountEventNames } from 'common/constants/analytics';

type Options = {
  onSuccess?: (csv: string) => void;
  onError?: () => void;
};

type DownloadBalancesOptions = {
  showToast?: boolean;
  fileName?: string;
};

const useDownloadBalances = ({ onSuccess, onError }: Options = {}) => {
  const { openToast } = useToast();

  const getBalancesCsv = useCallback(async (accounts?: [string]) => {
    const response = await api.post('/download-balances/', {
      data: { account_ids: accounts },
    });

    return response;
  }, []);

  const downloadBalances = useCallback(
    async (accounts?: [string], options: DownloadBalancesOptions = {}) => {
      const { showToast = true, fileName = 'balances.csv' } = options;

      track(AccountEventNames.DownloadBalancesCsvButtonClicked);

      const response = await api.post('/download-balances/', {
        data: { account_ids: accounts },
      });

      if (response) {
        downloadFile(response, fileName);

        if (showToast) {
          openToast({
            title: 'Success',
            description: 'Your download is starting now...',
          });
        }

        onSuccess?.(response);
      } else {
        onError?.();
      }
    },
    [onSuccess, onError],
  );

  return { getBalancesCsv, downloadBalances };
};

export default useDownloadBalances;
