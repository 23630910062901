import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { Suspense, useCallback, useMemo, useRef, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import TabsContext from 'common/components/tabs/Tabs';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Grid, { GridItem } from 'components/lib/ui/Grid';
import PageWithNoAccountsEmptyState from 'components/lib/ui/PageWithNoAccountsEmptyState';
import StatisticCard from 'components/lib/ui/StatisticCard';
import Text from 'components/lib/ui/Text';
import ReportsCashFlowValue from 'components/reports/ReportsCashFlowValue';
import ReportsChartCard from 'components/reports/ReportsChartCard';
import ReportsChartCardEmpty from 'components/reports/ReportsChartCardEmpty';
import ReportsHeaderControls from 'components/reports/ReportsHeaderControls';
import ReportsHeaderTabs from 'components/reports/ReportsHeaderTabs';
import SaveReportConfigurationFlow from 'components/reports/SaveReportConfigurationFlow';
import type { FormValues } from 'components/reports/SaveReportConfigurationModal';
import TransactionsListContainer from 'components/transactions/TransactionsListContainer';
import TransactionsSummaryCard from 'components/transactions/TransactionsSummaryCard';

import {
  setGroupByEntity,
  setChartTypeForTab,
  setReportsSankeyGroupMode,
  setGroupByTimeframe,
  setReportsFilters,
  setReportsTransactionsSortBy,
  setViewModeForTab,
} from 'actions';
import type { GroupDetail } from 'common/lib/reports';
import { ensureGroupBy } from 'common/lib/reports';
import boxShadow from 'common/lib/styles/boxShadow';
import { breakPoints, color, radius, spacing } from 'common/lib/theme/dynamic';
import { formatCurrency } from 'common/utils/Currency';
import { formatPercent } from 'common/utils/Number';
import { track } from 'lib/analytics/segment';
import typewriter from 'lib/analytics/typewriter';
import { areFiltersEquivalent } from 'lib/filters/utils';
import { useDispatch } from 'lib/hooks';
import useAdaptedReportsData from 'lib/hooks/reports/useAdaptedReportsData';
import useReportConfigurations from 'lib/hooks/reports/useReportConfigurations';
import useReportsCurrentTab from 'lib/hooks/reports/useReportsCurrentTab';
import { useReportsParameters } from 'lib/hooks/reports/useReportsParameters';
import useReportsSavedViewsWalkthrough from 'lib/hooks/reports/useReportsSavedViewsWalkthrough';
import { getQueryParamsFromFilters } from 'lib/hooks/transactions/useFilters';
import useModal from 'lib/hooks/useModal';
import useToast from 'lib/hooks/useToast';
import {
  formatReportsValue,
  getReportFiltersForTransactionFilterSet,
  makeChartTitle,
  decodeReportView,
  convertReportViewToInput,
  areReportViewsEquivalent,
} from 'lib/reports';
import { convertFiltersToInput } from 'lib/transactions/Filters';
import type { ReportsTab } from 'state/reports/types';
import { downloadTransactions } from 'state/transactions/thunks';
import { isElementBottomBelowViewport } from 'utils/viewport';

import { ReportsEventNames } from 'common/constants/analytics';
import { REPORTS } from 'common/constants/copy';
import routes from 'constants/routes';

import type { ReportConfiguration, TransactionOrdering } from 'common/generated/graphql';

const DEFAULT_ROUTE = routes.reports.cashFlow.path;

const TransactionsSummaryRefContainer = styled.div`
  scroll-margin-bottom: ${spacing.gutter};
`;

const WithShadow = styled.div`
  ${boxShadow.medium}
  background-color: ${color.white};
  border-radius: ${radius.medium};
`;

const StatisticCardGrow = styled(StatisticCard)`
  flex: 1;
`;

const SummaryCardsContainer = styled(FlexContainer).attrs({
  justifyBetween: true,
  alignCenter: true,
  gap: 'gutter',
})`
  margin-top: 0;
  margin-bottom: ${spacing.gutter};

  @media (max-width: ${({ theme }) => theme.breakPoints.md}px) {
    flex-flow: row wrap;
    gap: ${({ theme }) => theme.spacing.small};
    margin: ${spacing.gutter} 0 ${spacing.small};

    > ${StatisticCardGrow} {
      flex-basis: 33%;
    }
  }

  @media (max-width: ${breakPoints.xs}px) {
    > ${StatisticCardGrow} {
      flex-basis: 50%;
    }
  }
`;

const StyledGrid = styled(Grid)`
  padding-top: 0;
`;

const LazyLoadedSavedViewsWalkthrough = React.lazy(
  () => import('components/reports/SavedViewsWalkthrough'),
);

const Reports = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentTab = useReportsCurrentTab();
  const transactionsSummaryRef = useRef<HTMLDivElement>(null);

  const [ReportConfigurationModal, { open: openReportConfigurationModal }] = useModal();

  const {
    showAllEntities,
    setShowAllEntities,
    reportView,
    filters,
    groupBy,
    groupByTimeframe,
    sortTransactionsBy,
    quickViewFilters,
    setQuickViewFilters,
    selectedDatumId,
    setSelectedDatumId,
    selectedDateRange,
    allFilters,
    setSelectedDateRange,
    hasQuickViewFilters,
    removeQuickViewFilters,
    analyticsMetadata,
  } = useReportsParameters({ currentTab });

  const [shouldShowSavedViewsWalkthrough, dismissSavedViewsWalkthrough] =
    useReportsSavedViewsWalkthrough();

  const {
    reportConfigurations,
    createReportConfiguration,
    updateReportConfiguration,
    deleteReportConfiguration,
  } = useReportConfigurations();
  const [editingReportConfigurationId, setEditingReportConfigurationId] =
    useState<Maybe<string>>(null);

  const selectedReportConfigurationId = useMemo(() => {
    if (R.isNil(filters)) {
      return undefined;
    }

    // First, filter by matching transaction filters. If there are no configurations with
    // matching filters, we always bail early.
    const configsWithMatchingFilters = reportConfigurations.filter((reportConfiguration) =>
      areFiltersEquivalent(
        convertFiltersToInput(filters),
        reportConfiguration.transactionFilterSet,
      ),
    );
    if (configsWithMatchingFilters.length === 0) {
      return undefined;
    }

    // Then, try to find a configuration exactly matching the current report view, which is
    // technically always "present." We always prefer an exact match to a view agnostic match.
    const exactViewMatch = configsWithMatchingFilters.find((reportConfiguration) =>
      areReportViewsEquivalent(reportView, reportConfiguration.reportView),
    );
    if (exactViewMatch) {
      return exactViewMatch.id;
    }

    // When there is no exact match, look for a "view agnostic" match.
    const viewAgnosticMatch = configsWithMatchingFilters.find((reportConfiguration) =>
      areReportViewsEquivalent(undefined, reportConfiguration.reportView),
    );
    if (viewAgnosticMatch) {
      return viewAgnosticMatch.id;
    }

    return undefined;
  }, [reportView, reportConfigurations, filters]);

  const supportsSingleGroupBy = ['spending', 'income'].includes(currentTab);

  // This set of data is used for the main chart and the top level summary. We do not want to
  // include quick view filters here because we want the chart and summary to include all the
  // transactions that apply based on the top level filters.
  const { result, summary, isLoading, queryVariables } = useAdaptedReportsData({
    groupBy: supportsSingleGroupBy ? ensureGroupBy(groupBy) : undefined,
    groupByTimeframe,
    currentTab,
    filters,
    showAllEntities,
  });

  // This set of data is used for the transactions summary. We _do_ want to include quick view
  // filters here because we want the transactions summary to sync with the transactions list based
  // on the quick view filters. However, if we don't have any quick view filters, we don't need to
  // run this query, so we set `skipQuery` to true.
  const { summary: quickViewFilteredSummary, isLoading: isLoadingQuickViewFilteredSummary } =
    useAdaptedReportsData({
      groupBy: supportsSingleGroupBy ? ensureGroupBy(groupBy) : undefined,
      currentTab,
      filters: allFilters,
      showAllEntities,
      skipQuery: !hasQuickViewFilters,
      onCompletedQuerySuccessfully: () => {
        if (
          transactionsSummaryRef.current &&
          hasQuickViewFilters &&
          isElementBottomBelowViewport(transactionsSummaryRef.current)
        ) {
          transactionsSummaryRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      },
    });

  const onChangeSortBy = useCallback(
    (sortBy: TransactionOrdering) => dispatch(setReportsTransactionsSortBy(sortBy)),
    [dispatch],
  );

  const chartTitle = useMemo(() => makeChartTitle(groupBy, currentTab), [currentTab, groupBy]);

  const dataMap = useMemo(
    () =>
      result[0].reduce(
        (acc, datum) => {
          if (datum.entities) {
            Object.values(datum.entities).forEach((entity) => {
              if (entity?.id) {
                acc[entity.id] = datum;
              }
            });
          }
          return acc;
        },
        {} as Record<string, GroupDetail>,
      ),
    [result],
  );

  const onSelectDatum = useCallback(
    (id: string) => {
      if (id === selectedDatumId) {
        setSelectedDatumId(id);
        removeQuickViewFilters();
        return;
      }

      const datum = dataMap[id];
      if (R.isNil(datum)) {
        return;
      }
      setSelectedDatumId(id);

      const { category, categoryGroup, merchant } = datum.original.groupBy;
      if (RA.isNotNil(category) && category.id === id) {
        setQuickViewFilters({ categories: [category.id] });
      } else if (RA.isNotNil(categoryGroup) && categoryGroup.id === id) {
        setQuickViewFilters({ categoryGroups: [categoryGroup.id] });
      } else if (RA.isNotNil(merchant) && merchant.id === id) {
        setQuickViewFilters({ merchants: [merchant.id] });
      }

      track(ReportsEventNames.ReportsChartDataSelected, analyticsMetadata);
    },
    [selectedDatumId, dataMap, analyticsMetadata, removeQuickViewFilters],
  );

  const onSelectDateRange = useCallback(
    (startDate: string, endDate: string) => {
      if (selectedDateRange?.startDate === startDate && selectedDateRange?.endDate === endDate) {
        setSelectedDateRange(null);
        removeQuickViewFilters();
        return;
      }
      setSelectedDateRange({ startDate, endDate });
      setQuickViewFilters({ startDate, endDate });

      track(ReportsEventNames.ReportsChartDataSelected, analyticsMetadata);
    },
    [
      selectedDateRange?.startDate,
      selectedDateRange?.endDate,
      analyticsMetadata,
      removeQuickViewFilters,
    ],
  );

  const onSelectReportConfiguration = useCallback(
    (reportConfiguration: ReportConfiguration) => {
      track(ReportsEventNames.ReportConfigurationSelected, {
        name: reportConfiguration.displayName,
      });

      const newFilters = getReportFiltersForTransactionFilterSet(
        reportConfiguration.transactionFilterSet,
      );

      dispatch(setReportsFilters({ filters: newFilters }));

      if (R.isNil(reportConfiguration.reportView)) {
        return;
      }

      const {
        tab,
        chartType,
        calculationViewMode,
        sankeyGroupMode,
        groupByEntity,
        groupByTimeframe,
      } = decodeReportView(reportConfiguration.reportView);

      // Dispatch the view mode change before dispatching the chart type change,
      // since setting the view mode has some side effects on the chart type.
      if (calculationViewMode) {
        dispatch(setViewModeForTab({ view: calculationViewMode, tab }));
      }

      dispatch(setChartTypeForTab({ chartType, tab }));

      if (sankeyGroupMode) {
        dispatch(setReportsSankeyGroupMode(sankeyGroupMode));
      }

      if (groupByEntity) {
        dispatch(setGroupByEntity(groupByEntity));
      }

      if (groupByTimeframe) {
        dispatch(setGroupByTimeframe(groupByTimeframe));
      }

      if (tab !== currentTab) {
        // Passing the new filters already ensures a single history entry, whereas it might
        // otherwise create one for just the tab change and another for when the url synchronizes.
        history.push(
          routes.reports[tab as ReportsTab]({
            queryParams: getQueryParamsFromFilters(newFilters),
          }),
        );
      }
    },
    [currentTab, dispatch, history],
  );

  const onSaveReportConfiguration = useCallback(
    async ({ displayName, includeReportView }: FormValues) => {
      const cleanedDisplayName = displayName.trim();

      if (editingReportConfigurationId) {
        updateReportConfiguration(editingReportConfigurationId, cleanedDisplayName);
        return;
      }

      const cleanedFilters = convertFiltersToInput(filters);

      const cleanedReportView = includeReportView
        ? convertReportViewToInput(reportView)
        : undefined;

      await createReportConfiguration(cleanedDisplayName, cleanedFilters, cleanedReportView);
    },
    [
      editingReportConfigurationId,
      filters,
      reportView,
      createReportConfiguration,
      updateReportConfiguration,
    ],
  );

  const renderChartCard = useCallback(
    (props: Partial<React.ComponentProps<typeof ReportsChartCard>>) => (
      <ReportsChartCard
        data={result}
        isLoading={isLoading}
        title={chartTitle}
        currentTab={currentTab}
        groupByTimeframe={groupByTimeframe}
        summary={summary}
        {...R.omit(['total'], props)}
        total={formatReportsValue(props.total)}
        showAllEntities={showAllEntities}
        onChangeShowAllEntities={setShowAllEntities}
        selectedDatumId={selectedDatumId}
        onSelectDatum={onSelectDatum}
        onSelectDateRange={onSelectDateRange}
        selectedDateRange={selectedDateRange}
      />
    ),
    [
      result,
      isLoading,
      chartTitle,
      currentTab,
      groupByTimeframe,
      summary,
      showAllEntities,
      selectedDatumId,
      selectedDateRange,
    ],
  );

  const { openErrorToast, openToast } = useToast();
  const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);
  const onClickDownloadCsv = useCallback(async () => {
    setIsDownloadingCsv(true);

    const { startDate, endDate, ...rest } = allFilters;
    const filtersWithCounts = Object.keys(rest).reduce((acc, key) => {
      const count = R.pathOr(0, [key, 'length'], allFilters);
      return count > 0 ? { ...acc, [key]: count } : acc;
    }, {});

    typewriter.transactionsDownloadedToCsv({
      filtersWithCounts: R.mergeRight(filtersWithCounts, { startDate, endDate }),
      origin: 'reports',
    });

    try {
      await dispatch(downloadTransactions(allFilters));
      openToast({
        title: 'Transactions downloaded',
        description: 'Your transactions have been downloaded to your computer.',
      });
    } catch (error) {
      openErrorToast({
        title: 'Error downloading your transactions',
      });
    } finally {
      setIsDownloadingCsv(false);
    }
  }, [allFilters, dispatch]);

  return (
    <TabsContext>
      <PageWithNoAccountsEmptyState
        name="Reports"
        tabs={<ReportsHeaderTabs />}
        controls={
          <ReportsHeaderControls
            reportConfigurations={reportConfigurations}
            selectedReportConfigurationId={selectedReportConfigurationId}
            onSelectReportConfiguration={onSelectReportConfiguration}
            onSaveReportConfiguration={openReportConfigurationModal}
            onEditReportConfiguration={({ id }) => {
              setEditingReportConfigurationId(id);
              openReportConfigurationModal();
            }}
          />
        }
        emptyIcon="pie-chart"
        emptyTitle={REPORTS.EMPTY_STATE_TITLE}
        emptyButtonText={REPORTS.EMPTY_STATE_BUTTON}
        scrollKey={currentTab}
      >
        <StyledGrid
          template={`"chart chart" "transactions summary" / 1fr 30%`}
          md={`"chart" "summary" "transactions"`}
        >
          <GridItem area="chart">
            <Switch>
              {/* This redirects the user to the default route if they try to access the page by
               the direct URL (/reports). Without this we'd render a blank page. */}
              <Route
                exact
                path={routes.reports.path}
                render={() => <Redirect to={DEFAULT_ROUTE} />}
              />
              <Route path={routes.reports.cashFlow.path}>
                <SummaryCardsContainer>
                  <StatisticCardGrow
                    label="Total Income"
                    value={
                      <ReportsCashFlowValue
                        formatter={formatCurrency}
                        value={summary?.sumIncome ?? 0}
                        color="greenText"
                      />
                    }
                    isLoading={isLoading}
                  />
                  <StatisticCardGrow
                    label="Total Expenses"
                    value={
                      <ReportsCashFlowValue
                        formatter={formatCurrency}
                        value={(summary?.sumExpense ?? 0) * -1}
                        color="redText"
                      />
                    }
                    isLoading={isLoading}
                  />
                  <StatisticCardGrow
                    label="Total Net Income"
                    value={
                      <ReportsCashFlowValue
                        formatter={formatCurrency}
                        value={summary?.savings ?? 0}
                      />
                    }
                    isLoading={isLoading}
                  />
                  <StatisticCardGrow
                    label="Savings Rate"
                    value={
                      <ReportsCashFlowValue
                        formatter={formatPercent}
                        value={Math.max(0, summary?.savingsRate ?? 0)}
                      />
                    }
                    isLoading={isLoading}
                  />
                </SummaryCardsContainer>
                {renderChartCard({ title: 'Cash Flow' })}
              </Route>
              <Route path={routes.reports.spending.path}>
                {renderChartCard({ total: summary?.sumExpense })}
              </Route>
              <Route path={routes.reports.income.path}>
                {renderChartCard({ total: summary?.sumIncome })}
              </Route>
            </Switch>
          </GridItem>
          <GridItem area="transactions">
            <WithShadow>
              <TransactionsListContainer
                transactionFilters={queryVariables.filters ?? {}}
                quickViewFilters={quickViewFilters ?? {}}
                onRemoveQuickViewFilters={removeQuickViewFilters}
                emptyComponent={<ReportsChartCardEmpty />}
                sortBy={sortTransactionsBy}
                onChangeSortBy={onChangeSortBy}
                overrideTitle={
                  <Text size="large" weight="medium">
                    Transactions
                  </Text>
                }
              />
            </WithShadow>
          </GridItem>
          <GridItem area="summary" sticky>
            <TransactionsSummaryRefContainer ref={transactionsSummaryRef}>
              <TransactionsSummaryCard
                transactionsSummary={hasQuickViewFilters ? quickViewFilteredSummary : summary}
                empty={<ReportsChartCardEmpty />}
                filters={allFilters}
                onChangeFilters={RA.noop}
                onClickDownloadCsv={onClickDownloadCsv}
                isDownloadingCsv={isDownloadingCsv}
                isLoading={hasQuickViewFilters ? isLoadingQuickViewFilteredSummary : isLoading}
              />
            </TransactionsSummaryRefContainer>
          </GridItem>
          <ReportConfigurationModal onClose={() => setEditingReportConfigurationId(null)}>
            <SaveReportConfigurationFlow
              reportView={reportView}
              filters={filters}
              editingReportConfigurationId={editingReportConfigurationId}
              reportConfigurations={reportConfigurations}
              onDeleteReportConfiguration={async (reportConfiguration: ReportConfiguration) => {
                await deleteReportConfiguration(reportConfiguration.id);
              }}
              onSubmit={onSaveReportConfiguration}
            />
          </ReportConfigurationModal>
        </StyledGrid>
        {shouldShowSavedViewsWalkthrough && (
          <Suspense fallback={null}>
            <LazyLoadedSavedViewsWalkthrough onFinish={dismissSavedViewsWalkthrough} />
          </Suspense>
        )}
      </PageWithNoAccountsEmptyState>
    </TabsContext>
  );
};

export default Reports;
