import * as RA from 'ramda-adjunct';

import { getAmountWithSign, getInitialType, RecurringStatusFormValues } from 'common/lib/recurring';

import type { RecurringTransactionStreamType } from 'common/constants/recurringTransactions';

import type { UpdateMerchantInput } from 'common/generated/graphQlTypes/globalTypes';
import type { Mobile_GetEditMerchantQuery } from 'common/generated/graphql';

type Stream = NonNullable<Mobile_GetEditMerchantQuery['merchant']>['recurringTransactionStream'];

export type FormValues = Omit<NonNullable<UpdateMerchantInput['recurrence']>, 'isActive'> & {
  name: string;
  merchantId: string;
  type?: RecurringTransactionStreamType;
  isActive: RecurringStatusFormValues;
};

export const getEditMerchantInitialValues = (
  merchantId: string,
  name: string | undefined | null,
  stream: Stream | undefined | null,
): FormValues => ({
  merchantId,
  name: name ?? '',
  isRecurring: RA.isNotNil(stream),
  frequency: stream?.frequency,
  amount: stream?.amount,
  baseDate: stream?.baseDate,
  type: getInitialType(stream?.amount),
  isActive:
    stream?.isActive !== false
      ? RecurringStatusFormValues.Active
      : RecurringStatusFormValues.Canceled,
});

export const transformFormValuesToInput = (values: FormValues): UpdateMerchantInput => {
  const { isRecurring, frequency, baseDate, amount, type, isActive, ...rest } = values;
  return {
    ...rest,
    recurrence: {
      isRecurring,
      frequency,
      baseDate,
      amount: getAmountWithSign(amount, type!),
      isActive: isActive === RecurringStatusFormValues.Active,
    },
  };
};
