import React from 'react';

import { IconCircle } from 'components/lib/ui/IconCircle';

type Props = {
  iconSizePx?: number;
  className?: string;
};

export const CheckCircle: React.FC<Props> = ({ iconSizePx, className }) => (
  <IconCircle iconName="check" iconSizePx={iconSizePx} className={className} />
);

export default CheckCircle;
