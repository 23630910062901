import { useEffect } from 'react';
import { isIOS, isAndroid } from 'react-device-detect';

/**
 * Hook to handle redirecting users from problematic in-app browsers to their native browser.
 * Some in-app browsers (like Instagram and LinkedIn) have issues with authentication flows,
 * particularly with Google Auth, due to their embedded browser security policies.
 *
 * This hook detects if the user is in such an in-app browser and redirects them to their
 * device's native browser:
 * - For iOS: Uses x-safari-{url} scheme to open Safari
 * - For Android: Uses Android intent system to open the default browser
 */
export const useRedirectFromInAppBrowser = () => {
  useEffect(() => {
    const ua = navigator.userAgent;
    const currentUrl = new URL(window.location.href);

    if (ua.includes('Instagram') || ua.includes('LinkedIn')) {
      if (isIOS) {
        window.location.href = `x-safari-${currentUrl.toString()}`;
      } else if (isAndroid) {
        const intentUrl = `intent:${currentUrl.toString()}#Intent;end`;
        window.location.href = intentUrl;
      }
    }
  }, []);
};
