import { useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

import { gql } from 'common/generated/gql';
import type {
  ReportConfiguration,
  ReportViewInput,
  TransactionFilterInput,
} from 'common/generated/graphql';

/**
 * Performs operations related to report configurations, such as fetching, creating, and deleting them.
 */
const useReportConfigurations = () => {
  const { data, refetch } = useQuery(GET_REPORT_CONFIGURATIONS);
  const reportConfigurations = (data?.reportConfigurations ?? []) as ReportConfiguration[];

  const [createReportConfigurationMutation] = useMutation(CREATE_REPORT_CONFIGURATION);
  const [updateReportConfigurationMutation] = useMutation(UPDATE_REPORT_CONFIGURATION);
  const [deleteReportConfigurationMutation] = useMutation(DELETE_REPORT_CONFIGURATION);

  const createReportConfiguration = useCallback(
    async (
      displayName: string,
      transactionFilters: TransactionFilterInput,
      reportView: Maybe<ReportViewInput>,
    ) => {
      await createReportConfigurationMutation({
        variables: {
          input: {
            displayName,
            transactionFilters,
            reportView,
          },
        },
      });

      refetch();
    },
    [createReportConfigurationMutation, refetch],
  );

  const updateReportConfiguration = useCallback(
    async (id: string, displayName: string) => {
      await updateReportConfigurationMutation({
        variables: { input: { id, displayName } },
      });

      refetch();
    },
    [updateReportConfigurationMutation, refetch],
  );

  const deleteReportConfiguration = useCallback(
    async (id: string) => {
      await deleteReportConfigurationMutation({
        variables: { id },
      });

      refetch();
    },
    [deleteReportConfigurationMutation, refetch],
  );

  return {
    reportConfigurations,
    createReportConfiguration,
    updateReportConfiguration,
    deleteReportConfiguration,
  };
};

const GET_REPORT_CONFIGURATIONS = gql(/* GraphQL */ `
  query Web_GetReportConfigurations {
    reportConfigurations {
      ...ReportConfigurationFields
    }
  }
`);

const CREATE_REPORT_CONFIGURATION = gql(/* GraphQL */ `
  mutation Web_CreateReportConfiguration($input: CreateReportConfigurationInput!) {
    createReportConfiguration(input: $input) {
      reportConfiguration {
        ...ReportConfigurationFields
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

const UPDATE_REPORT_CONFIGURATION = gql(/* GraphQL */ `
  mutation Web_UpdateReportConfiguration($input: UpdateReportConfigurationInput!) {
    updateReportConfiguration(input: $input) {
      reportConfiguration {
        ...ReportConfigurationFields
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

const DELETE_REPORT_CONFIGURATION = gql(/* GraphQL */ `
  mutation Web_DeleteReportConfiguration($id: ID!) {
    deleteReportConfiguration(id: $id) {
      deleted
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export const REPORT_CONFIGURATION_FIELDS = gql(/* GraphQL */ `
  fragment ReportConfigurationFields on ReportConfiguration {
    id
    displayName
    transactionFilterSet {
      ...TransactionFilterSetFields
    }
    reportView {
      analysisScope
      chartType
      chartCalculation
      chartLayout
      chartDensity
      dimensions
      timeframe
    }
  }
`);

export const TRANSACTION_FILTER_SET_FIELDS = gql(/* GraphQL */ `
  fragment TransactionFilterSetFields on TransactionFilterSet {
    id
    displayName
    categories {
      id
      name
      icon
    }
    categoryGroups {
      id
      name
      type
    }
    accounts {
      id
      displayName
      logoUrl
      icon
    }
    merchants {
      id
      name
      logoUrl
    }
    tags {
      id
      name
      color
    }
    isUntagged
    goal {
      id
      name
      imageStorageProvider
      imageStorageProviderId
    }
    searchQuery
    systemCategories
    systemCategoryGroups
    categoryType
    isUncategorized
    budgetVariability
    isFlexSpending
    startDate
    endDate
    timeframePeriod {
      unit
      value
      includeCurrent
    }
    createdBefore
    createdAfter
    absAmountGte
    absAmountLte
    isSplit
    isRecurring
    isInvestmentAccount
    isPending
    creditsOnly
    debitsOnly
    hasNotes
    hasAttachments
    hiddenFromReports
    importedFromMint
    syncedFromInstitution
    needsReview
    needsReviewUnassigned
    needsReviewByUser {
      id
      name
    }
  }
`);

export default useReportConfigurations;
