import * as R from 'ramda';

import type {
  EntitlementConstantKey,
  LimitByEntitlement,
  ProductFeatureLimit,
} from 'common/constants/premium';
import { MONTHS_PER_YEAR } from 'common/constants/time';

import type { EntitlementConstantItem } from 'common/generated/graphql';

/**
 * Converts backend response to the format we want on the frontend.
 *
 * FROM:
 * [
    {
      "entitlement": "lite",
      "maxCredentials": 2,
      "maxTransactionRules": 10
    },
    {
      "entitlement": "premium",
      "maxCredentials": null,
      "maxTransactionRules": null
    }
  ]
 * TO:
 * {
    maxCredentials: {
      lite: 2,
      premium: null,
    },
    maxTransactionRules: {
      lite: 10,
      premium: null,
    },
  }
 */
export const indexConstants = (
  constants: EntitlementConstantItem[],
): Partial<Record<ProductFeatureLimit, LimitByEntitlement>> => {
  const indexedByEntitlement = R.indexBy(R.prop('entitlement'), constants);
  const entitlements = R.keys(indexedByEntitlement);

  const keys = R.pipe(
    R.omit(['entitlement', '__typename']), // we want all keys except these
    R.keys,
  )(R.head(constants) ?? {}) as EntitlementConstantKey[];

  const getEntitlementConfigForFeature = (feature: EntitlementConstantKey) => {
    const values = entitlements.map((entitlement) => indexedByEntitlement[entitlement]?.[feature]);
    return R.fromPairs(R.zip(entitlements, values));
  };

  const configs = keys.map(getEntitlementConfigForFeature);
  return R.fromPairs(R.zip(keys, configs));
};

/** i.e. $7.50/mo */
export const getMonthlyPriceDisplayStringForAnnualPrice = (
  annualPrice: number | undefined,
  currencyCode = 'USD',
) => {
  if (!annualPrice) {
    return '-';
  }

  const monthlyPrice = annualPrice / MONTHS_PER_YEAR;
  const formattedPrice = monthlyPrice.toLocaleString(undefined, {
    style: 'currency',
    currency: currencyCode,
  });

  return `${formattedPrice}/mo`;
};
