import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import type { Props as PageProps } from 'components/lib/ui/Page';
import Page from 'components/lib/ui/Page';
import { FOOTER_NAV_HEIGHT } from 'components/onboarding/OnboardingFooterNav';
import OnboardingGlobalStyle from 'components/onboarding/OnboardingGlobalStyles';

import { spacing } from 'common/lib/theme/dynamic';
import useTheme from 'lib/hooks/useTheme';

const StyledPage = styled(Page)`
  background-color: ${({ theme }) => theme.color.white};
  text-align: center;
`;

const Container = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  flex: 1;
  padding: ${spacing.xxlarge} ${spacing.gutter};
`;

type Props = PageProps & {
  quote?: { text: string; author: string };
  children: React.ReactNode;
  excludePageFooterNavSpacing?: boolean;
};

const OnboardingPage = ({
  children,
  quote,
  excludePageFooterNavSpacing = true,
  ...pageProps
}: Props) => {
  const theme = useTheme();

  return (
    <>
      <OnboardingGlobalStyle />

      <Helmet>
        <meta name="theme-color" content={theme.color.white} />
      </Helmet>

      <StyledPage
        scrollMarginBottom={excludePageFooterNavSpacing ? 0 : FOOTER_NAV_HEIGHT}
        {...pageProps}
      >
        <Container>{children}</Container>
      </StyledPage>
    </>
  );
};

export default OnboardingPage;
