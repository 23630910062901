import { DateTime } from 'luxon';
import * as R from 'ramda';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import Tabs from 'common/components/tabs/Tabs';
import CashFlowControls from 'components/cashFlows/CashFlowControls';
import FilteredCashFlowBarChart from 'components/cashFlows/FilteredCashFlowBarChart';
import FilteredCashFlowSummaryCard from 'components/cashFlows/FilteredCashFlowSummaryCard';
import Card from 'components/lib/ui/Card';
import Empty from 'components/lib/ui/Empty';
import Grid, { GridItem } from 'components/lib/ui/Grid';
import type { Props as PageProps } from 'components/lib/ui/Page';
import Page from 'components/lib/ui/Page';
import TransactionsListContainer from 'components/transactions/TransactionsListContainer';

import { inferTransactionTypeFromSummaryData } from 'common/lib/cashFlow';
import {
  aggregatesDataAdapter,
  formatFilteredChartDataPoint,
  getDefaultDateForTimeframe,
} from 'common/lib/cashFlow/adapters';
import useQuery from 'common/lib/hooks/useQuery';
import { getMostRecentDateIfCurrentDateNonAvailable } from 'lib/cashFlow/filter';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import usePersistentFilter from 'lib/hooks/usePersistentFilter';
import { useUpdatableQueryParam } from 'lib/hooks/useQueryParams';
import { convertFiltersToInput } from 'lib/transactions/Filters';
import { formatFullDateRange, getDateShorthand } from 'utils/dateRange';

import type { Timeframe } from 'common/constants/timeframes';
import { DEFAULT_TIMEFRAME, TIMEFRAME_OPTIONS } from 'common/constants/timeframes';

import { gql as newGql } from 'common/generated/gql';
import type { Web_GetFilteredCashFlowPageQueryVariables } from 'common/generated/graphql';
import type { TransactionFilters } from 'types/filters';

// Only way to enforce that all keys of GetFilteredCashFlowPageVariables are listed below
// (https://stackoverflow.com/a/54308812/4526204)
const VALID_INPUT_VARIABLES: Record<
  keyof Required<Web_GetFilteredCashFlowPageQueryVariables>,
  true
> = {
  accounts: true,
  categories: true,
  categoryGroups: true,
  startDate: true,
  endDate: true,
  tags: true,
  merchants: true,
};

// This are the filters that are visible
// in the filtered cash flow filters
// and using only this avoid have uneditable filters
// leaking from the transaction filters set in other pages
const EDITABLE_ACTIVE_FILTERS: Record<string, true> = {
  accounts: true,
  tags: true,
};

const DateLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xlarge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const TransactionsEmpty = styled(Empty)`
  padding: ${({ theme }) => theme.spacing.xxxlarge};
`;

const StyledGrid = styled(Grid)`
  padding-top: 0;
`;

type Props = Pick<PageProps, 'overlayEmptyComponent' | 'icon'> & {
  title: string;

  // startDate and endDate are set from the dateRange
  transactionFilters: Omit<Partial<TransactionFilters>, 'startDate' | 'endDate'>;
  displayAsExpense?: boolean;
  /** Additional controls to display to the right of the date range picker. */
  additionalControls?: React.ReactNode;
  /** Additional element to display above the summary card on the right. */
  additionalSummaryElement?: React.ReactNode;
};

const FilteredCashFlowPage = ({
  title,
  transactionFilters,
  displayAsExpense,
  additionalControls,
  additionalSummaryElement,
  ...pageProps
}: Props) => {
  const isDefaultToSankeyEnabled = useIsFeatureFlagOn('ab-test-cash-flow-sankey-default', {
    trackImpression: true,
  });
  const filtersKey = isDefaultToSankeyEnabled ? 'cashFlowSankey' : 'cashFlow';
  const { activeFilters, updateFilter } = usePersistentFilter(filtersKey, { useParams: false });
  const activeTimeframe = (activeFilters.timeframe ?? DEFAULT_TIMEFRAME) as Timeframe;
  const initialTimeframeIndex = useMemo(
    () => TIMEFRAME_OPTIONS.findIndex(({ value }) => value === activeTimeframe),
    [],
  );
  const maxChartTitleLength = 80;
  const chartTitle =
    title.substring(0, maxChartTitleLength) === title
      ? title
      : `${title.substring(0, maxChartTitleLength)}...`;

  const [date, setDate] = useUpdatableQueryParam('date');
  const activeDate = date
    ? DateTime.fromISO(date).startOf(activeTimeframe).toISODate()
    : getDefaultDateForTimeframe(activeTimeframe);

  const dateRange = useMemo(
    () => ({
      startDate: activeDate,
      endDate: DateTime.fromISO(activeDate).endOf(activeTimeframe).toISODate(),
    }),
    [activeDate, activeTimeframe],
  );

  const transactionFiltersWithDates = useMemo(() => {
    const validActiveFilters = R.pick(R.keys(EDITABLE_ACTIVE_FILTERS), activeFilters);
    const input = convertFiltersToInput({
      ...validActiveFilters,
      ...transactionFilters,
      ...dateRange,
    });
    return R.pick(R.keys(VALID_INPUT_VARIABLES), input);
  }, [transactionFilters, dateRange, activeFilters]);

  const { data, isLoadingInitialData } = useQuery(QUERY, {
    variables: transactionFiltersWithDates,
  });
  const { byMonth = [] } = data ?? {};

  const transactionType = inferTransactionTypeFromSummaryData(byMonth);
  const isExpense = transactionType === 'expense';

  const timeframeData = aggregatesDataAdapter(data, activeTimeframe, (timeframe, data) =>
    formatFilteredChartDataPoint(timeframe, data, !!displayAsExpense || isExpense),
  );

  const dateShorthand = useMemo(() => getDateShorthand(dateRange), [dateRange]);
  const summary = data?.summary[0].summary;

  useEffect(() => {
    const newDate = getMostRecentDateIfCurrentDateNonAvailable(timeframeData, activeDate);
    if (newDate !== activeDate) {
      updateFilter({ date: newDate });
    }
  }, [timeframeData, activeDate]);

  return (
    <Tabs initialIndex={initialTimeframeIndex}>
      <Page
        name="Filtered Cash Flow"
        parentRoute="Cash Flow"
        overrideTitle={title}
        controls={<CashFlowControls controls={additionalControls} filtersKey={filtersKey} />}
        {...pageProps}
      >
        <StyledGrid
          template={`"chart chart" "date date" "transactions summary" / 1fr 30%`}
          md={`"chart" "date" "transactions" "summary"`}
        >
          <GridItem area="chart">
            <FilteredCashFlowBarChart
              activeDateRange={dateRange}
              data={timeframeData}
              onBarClick={setDate}
              dataLabel={chartTitle}
              showLoader={isLoadingInitialData}
              displayAsExpense={!!displayAsExpense || isExpense}
              timeframe={activeTimeframe}
              activeDate={activeDate}
            />
          </GridItem>

          <GridItem area="date">
            <DateLabel>{dateShorthand || formatFullDateRange(dateRange)}</DateLabel>
          </GridItem>

          <GridItem area="transactions" sticky>
            <Card title="Transactions">
              <TransactionsListContainer
                transactionFilters={transactionFiltersWithDates}
                emptyComponent={
                  <TransactionsEmpty title={`No transactions in this period for ${title}`} />
                }
                hideHeader
              />
            </Card>
          </GridItem>

          <GridItem area="summary">
            {additionalSummaryElement}
            {summary && <FilteredCashFlowSummaryCard transactionsSummary={summary} />}
          </GridItem>
        </StyledGrid>
      </Page>
    </Tabs>
  );
};

// If you change this query name, change in constants/graphql.ts as well
const QUERY = newGql(/* GraphQL */ `
  query Web_GetFilteredCashFlowPage(
    $categories: [UUID!]
    $categoryGroups: [UUID!]
    $accounts: [UUID!]
    $merchants: [UUID!]
    $startDate: Date
    $endDate: Date
    $tags: [ID!]
  ) {
    summary: aggregates(
      fillEmptyValues: true
      filters: {
        startDate: $startDate
        endDate: $endDate
        categories: $categories
        categoryGroups: $categoryGroups
        merchants: $merchants
        accounts: $accounts
        tags: $tags
      }
    ) {
      summary {
        ...FilteredCashFlowSummaryFields
      }
    }

    byYear: aggregates(
      groupBy: ["year"]
      fillEmptyValues: true
      filters: {
        categories: $categories
        categoryGroups: $categoryGroups
        merchants: $merchants
        accounts: $accounts
        tags: $tags
      }
    ) {
      groupBy {
        year
      }

      summary {
        sum
        count
      }
    }

    byMonth: aggregates(
      groupBy: ["month"]
      fillEmptyValues: true
      filters: {
        categories: $categories
        categoryGroups: $categoryGroups
        merchants: $merchants
        accounts: $accounts
        tags: $tags
      }
    ) {
      groupBy {
        month
      }

      summary {
        sum
        count
      }
    }

    byQuarter: aggregates(
      groupBy: ["quarter"]
      fillEmptyValues: true
      filters: {
        categories: $categories
        categoryGroups: $categoryGroups
        merchants: $merchants
        accounts: $accounts
        tags: $tags
      }
    ) {
      groupBy {
        quarter
      }

      summary {
        sum
        count
      }
    }
  }
`);

export default FilteredCashFlowPage;
