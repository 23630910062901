import React from 'react';
import styled, { css } from 'styled-components';

import Icon from 'components/lib/ui/Icon';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.radius.round};
  background-color: ${({ theme }) => theme.color.green};
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.color.textWhite};

  /* Apply any className styles with higher specificity */
  && {
    ${({ className }) => className && ''}
  }
`;

const StyledIcon = styled(Icon)<{ sizePx?: number }>`
  ${({ sizePx }) =>
    sizePx &&
    css`
      width: ${sizePx}px;
      height: ${sizePx}px;
    `}
`;

export type IconCircleProps = {
  iconName: string;
  iconSizePx?: number;
  className?: string;
};

export const IconCircle: React.FC<IconCircleProps> = ({ iconName, iconSizePx, className }) => (
  <Root className={className}>
    <StyledIcon name={iconName} sizePx={iconSizePx} />
  </Root>
);
