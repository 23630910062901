import type { MutationUpdaterFn } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import * as R from 'ramda';
import { useCallback, useMemo } from 'react';

import {
  CLEAR_ALL_ACTIVITIES_MUTATION,
  DISMISS_ACTIVITY_MUTATION,
  MARK_ACTIVITY_AS_READ_MUTATION,
  MARK_ALL_AS_READ_MUTATION,
} from 'common/lib/graphQl/activity';
import { mergeNextRelayPage } from 'common/utils/pagination';

import { gql } from 'common/generated/gql';
import type { Common_DismissActivityMutation } from 'common/generated/graphql';
import type { ExtractVariablesFromDocument } from 'common/types/graphql';

const QUERY_PAGE_SIZE = 10;

type QuerySettings = {
  query: typeof QUERY;
  variables: ExtractVariablesFromDocument<typeof QUERY>;
};

const updateActivitiesWhenDismissed =
  (querySettings: QuerySettings): MutationUpdaterFn<Common_DismissActivityMutation> =>
  (cache, { data }) => {
    const activityId = data?.dismissActivity?.node?.id;

    if (!activityId) {
      return;
    }

    const { query, variables } = querySettings;

    const cachedData = cache.readQuery({
      query,
      variables,
    });

    if (!cachedData) {
      return;
    }

    const { activityEvents } = cachedData;

    if (!activityEvents) {
      return;
    }

    const updatedPinnedActivities = activityEvents.edges.filter(
      (edge) => edge?.node?.dismissedAt === null,
    );

    cache.writeQuery({
      data: {
        ...cachedData,
        activityEvents: {
          ...activityEvents,
          edges: updatedPinnedActivities,
        },
      },
      query,
      variables,
    });
  };

export const useNotifications = () => {
  const { data, loading, fetchMore, refetch } = useQuery(QUERY, {
    variables: {
      first: QUERY_PAGE_SIZE,
      afterCursor: undefined,
    },
  });

  const notifications = useMemo(
    () => data?.activityEvents?.edges.flatMap((edge) => (edge?.node ? [edge.node] : [])) ?? [],
    [data],
  );

  const hasUnreadNotifications = notifications.some(({ readAt, isPinned }) => !readAt && !isPinned);
  const hasNextPage = data?.activityEvents?.pageInfo.hasNextPage ?? false;
  const endCursor = data?.activityEvents?.pageInfo.endCursor;
  const notificationsCount = notifications.length;

  const [markAllAsRead, { loading: isLoadingMarkAllAsRead }] = useMutation(
    MARK_ALL_AS_READ_MUTATION,
    {
      variables: {
        first: notificationsCount,
      },
    },
  );

  const [clearAll, { loading: isLoadingClearAll }] = useMutation(CLEAR_ALL_ACTIVITIES_MUTATION, {
    onCompleted: () => {
      refetch();
    },
  });

  const [markAsRead] = useMutation(MARK_ACTIVITY_AS_READ_MUTATION);

  const [dismissActivity] = useMutation(DISMISS_ACTIVITY_MUTATION, {
    update: updateActivitiesWhenDismissed({
      query: QUERY,
      variables: { first: QUERY_PAGE_SIZE, afterCursor: undefined },
    }),
  });

  const requestNextPage = useCallback(() => {
    fetchMore({
      variables: {
        first: QUERY_PAGE_SIZE,
        afterCursor: endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) =>
        R.evolve(
          {
            activityEvents: mergeNextRelayPage(fetchMoreResult?.activityEvents),
          },
          prev,
        ),
    });
  }, [fetchMore, endCursor]);

  const computedData = useMemo(() => {
    const pinnedNotifications = notifications.filter(({ isPinned }) => isPinned);
    const nonPinnedNotifications = notifications.filter(({ isPinned }) => !isPinned);
    const readNotifications = nonPinnedNotifications.filter(({ readAt }) => !!readAt);
    const unreadNotifications = nonPinnedNotifications.filter(({ readAt }) => !readAt);

    return {
      pinnedNotifications,
      readNotifications,
      unreadNotifications,
    };
  }, [notifications]);

  return {
    notifications,
    loading,
    hasNextPage,
    hasUnreadNotifications,
    markAllAsRead,
    isLoadingMarkAllAsRead,
    clearAll,
    isLoadingClearAll,
    markAsRead,
    dismissActivity,
    requestNextPage,
    notificationsCount,
    ...computedData,
  };
};

const QUERY = gql(/* GraphQL */ `
  query Common_GetNotificationCenter($first: Int!, $afterCursor: String) {
    activityEvents(first: $first, after: $afterCursor) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          createdAt
          readAt
          title
          body
          action
          actionLabel
          eventPriority
          isPinned
          logo
          dismissedAt
          eventType
          secondaryUrl
        }
      }
    }
  }
`);
