import * as Sentry from '@sentry/browser';

import getUserApi from 'common/lib/api/user';
import useEventCallback from 'common/lib/hooks/useEventCallback';
import api from 'lib/api';
import getStringEnvVar from 'lib/getStringEnvVar';
import useToast from 'lib/hooks/useToast';

export const useRetailSyncMessaging = () => {
  const { openErrorToast } = useToast();

  const checkRuntimeAndNotify = ({ notify = false }: { notify?: boolean } = {}) => {
    try {
      if (!chrome?.runtime) {
        if (notify) {
          openErrorToast({
            title: 'Error',
            description:
              'Trying to send message to extension but no runtime found. Please contact support.',
          });
        }
        return false;
      }
    } catch (e) {
      if (e instanceof ReferenceError) {
        return false;
      }

      // Only capture exceptions that are not ReferenceError
      // because reference error are expected in cases where
      // the extension is not installed
      Sentry.captureException(e);
      return false;
    }

    return true;
  };

  const sendLoggedInMessage = useEventCallback(async () => {
    if (!checkRuntimeAndNotify({ notify: true })) {
      return;
    }

    const response = await getUserApi(api).getScopedAuthToken('retail-sync');

    await chrome.runtime.sendMessage(
      getStringEnvVar('REACT_APP_RETAIL_SYNC_EXTENSION_ID'),
      { type: 'logged-in', data: { user: response } },
      { includeTlsChannelId: true },
    );
  });

  const sendLoggedOutMessage = useEventCallback(async () => {
    if (!checkRuntimeAndNotify({ notify: false })) {
      return;
    }

    await chrome.runtime.sendMessage(
      getStringEnvVar('REACT_APP_RETAIL_SYNC_EXTENSION_ID'),
      { type: 'logged-out' },
      { includeTlsChannelId: true },
    );
  });

  return { sendLoggedInMessage, sendLoggedOutMessage };
};
