import { useMutation } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import IconButton from 'components/lib/ui/button/IconButton';
import { OverlayTrigger, Popover } from 'components/lib/ui/popover';
import NotificationCenter from 'components/notifications/NotificationCenter';

import { CHECK_ACTIVITY_MUTATION } from 'common/lib/graphQl/user';
import useHasUnreadActivity from 'common/lib/hooks/notifications/useHasUnreadActivity';
import { spacing, variables } from 'common/lib/theme/dynamic';

import { SIDEBAR_COLLAPSED_CLASS_NAME } from 'constants/classNames';

const Button = styled(IconButton)<{ isOpen: boolean }>`
  position: relative;
  color: ${variables.color.content.secondary};

  .${SIDEBAR_COLLAPSED_CLASS_NAME} & {
    display: none;
  }
`;

const UnreadIndicator = styled.div`
  width: ${({ theme }) => theme.spacing.small};
  height: ${({ theme }) => theme.spacing.small};
  background: ${({ theme }) => theme.color.red};
  border-radius: ${({ theme }) => theme.radius.round};
  position: absolute;
  top: 6px;
  right: 6px;
  border: 2px solid ${variables.color.background.page};
`;

const UnreadIndicatorSidebarCollapsed = styled(UnreadIndicator)`
  top: ${spacing.xsmall};
  right: -2px;
  display: none;

  .${SIDEBAR_COLLAPSED_CLASS_NAME} & {
    display: block;
  }
`;

const NotificationCenterButton = () => {
  const [hasUnread] = useHasUnreadActivity();
  const [checkActivity] = useMutation(CHECK_ACTIVITY_MUTATION);

  return (
    <OverlayTrigger
      placement="bottom-start"
      overlay={
        <Popover>
          <NotificationCenter />
        </Popover>
      }
    >
      {({ toggleOpen, isOpen }) => (
        <>
          <Button
            onClick={() => {
              toggleOpen();
              if (hasUnread) {
                checkActivity();
              }
            }}
            isOpen={isOpen}
            icon="bell"
            size="small"
            darkBg={false}
            active={isOpen}
          >
            {hasUnread && <UnreadIndicator />}
          </Button>
          {hasUnread && <UnreadIndicatorSidebarCollapsed />}
        </>
      )}
    </OverlayTrigger>
  );
};

export default NotificationCenterButton;
