import { forEach, isNil, omit, path } from 'ramda';
import { isNotNil } from 'ramda-adjunct';

import type { GroupDetail } from 'common/lib/reports';
import type { SankeyGroupMode } from 'lib/cashFlow/sankey';
import { INCOME_NODE_ID, SAVINGS_NODE_ID, NodeType } from 'lib/cashFlow/sankey';
import type useTheme from 'lib/hooks/useTheme';

import type { ReportsSummaryFieldsFragment, CategoryGroupType } from 'common/generated/graphql';

type GroupDetailEntity<TKey extends keyof NonNullable<GroupDetail['entities']>> = NonNullable<
  NonNullable<GroupDetail['entities']>[TKey]
>;

type SankeyDiagramGeneratorConfig = {
  groupMode: SankeyGroupMode;
  theme: ReturnType<typeof useTheme>;
};

export const SANKEY_GROUP_MODE_TO_LABEL: Record<NonNullable<SankeyGroupMode>, string> = {
  category: 'By category',
  group: 'By group',
  both: 'By category & group',
};

export const SANKEY_NODE_IDENTIFIER_SUFFIX = {
  inflow: '_INFLOW',
  outflow: '_OUTFLOW',
};

const sankeyNodeIdentifierSuffixRegex = new RegExp(
  `(${SANKEY_NODE_IDENTIFIER_SUFFIX.inflow}|${SANKEY_NODE_IDENTIFIER_SUFFIX.outflow})$`,
);

export const normalizeSankeyNodeIdentifier = (id: string) =>
  id.replace(sankeyNodeIdentifierSuffixRegex, '');

export class SankeyDiagramGenerator {
  private nodes: Record<string, ReportsNode> = {};
  private pendingLinks: Record<string, ReportsPendingLink[]> = {};
  private links: ReportsLink[] = [];

  private incomeNode: ReportsNode;
  private savingsNode: ReportsNode | undefined;

  constructor(
    private data: GroupDetail[],
    private summary: ReportsSummaryFieldsFragment,
    private config: SankeyDiagramGeneratorConfig,
  ) {
    this.incomeNode = this.addNode(
      INCOME_NODE_ID,
      'Income',
      // Make sure income is at least 1 to avoid omitting it from the diagram.
      // This can happen when we're only looking at expenses.
      Math.max(1, summary.sumIncome),
      NodeType.Generic,
    );
  }

  public generate() {
    this.data.forEach((detail) => this.processGroupDetails(detail));

    if (this.config.groupMode === 'both') {
      this.addMultiDimensionLinks();
    } else {
      this.addSingleDimensionLinks();
    }

    if (this.summary.savings > 0) {
      this.savingsNode = this.addNode(
        SAVINGS_NODE_ID,
        'Savings',
        this.summary.savings,
        NodeType.Generic,
      );
      this.addLink(this.incomeNode, this.savingsNode, this.summary.savings);
    }

    return {
      nodes: Object.values(this.nodes)
        .filter((node) => isNotNil(node) && node.value !== 0)
        .map((node) => node.toJson()),
      links: this.links.map((link) => link.toJson()),
    };
  }

  private addNode(
    id: string,
    label: string,
    value: number,
    type: NodeType,
    categoryGroupType?: CategoryGroupType,
    metadata?: GroupDetailEntity<'category'> | GroupDetailEntity<'categoryGroup'>,
  ): ReportsNode {
    const existingNode = this.nodes[id];

    if (existingNode) {
      // If the node already exists, modify its existing value so that we can
      // filter out nodes with no money movement later.
      existingNode.value += value;
      this.nodes[id] = existingNode;
      return existingNode;
    }

    const newNode = new ReportsNode(id, label, value, type, categoryGroupType, metadata);
    this.nodes[id] = newNode;
    return newNode;
  }

  private addLink(source: ReportsNode, target: ReportsNode, value: number) {
    if (value === 0) {
      return;
    }

    const link = new ReportsLink(source, target, value);
    this.links = [...this.links, link];
    return link;
  }

  private addPendingLink(sourceId: string, targetId: string, sum: number) {
    const key = `${sourceId}-${targetId}`;
    const existing = this.pendingLinks[key] || [];

    this.pendingLinks[key] = [
      ...existing,
      {
        sourceId,
        targetId,
        sum,
      },
    ];
  }

  private processGroupDetails(detail: GroupDetail) {
    const category = path<GroupDetailEntity<'category'>>(['entities', 'category'], detail);
    const categoryGroup = path<GroupDetailEntity<'categoryGroup'>>(
      ['entities', 'categoryGroup'],
      detail,
    );

    let categoryNode;
    let categoryGroupNode;

    if (category) {
      categoryNode = this.addNode(
        category.id,
        `${category.icon} ${category.name}`,
        detail.summary.sum,
        NodeType.Category,
        category.group.type,
        category,
      );
    }

    if (categoryGroup) {
      categoryGroupNode = this.addNode(
        categoryGroup.id,
        categoryGroup.name,
        detail.summary.sum,
        NodeType.CategoryGroup,
        categoryGroup.type,
        categoryGroup,
      );
    }

    if (categoryNode && this.config.groupMode === 'category') {
      this.addPendingLink(categoryNode.id, INCOME_NODE_ID, detail.summary.sum);
    } else if (categoryGroupNode && this.config.groupMode === 'group') {
      this.addPendingLink(categoryGroupNode.id, INCOME_NODE_ID, detail.summary.sum);
    }
  }

  private addSingleDimensionLinks() {
    forEach((links) => {
      if (links.length === 0) {
        return;
      }

      // Determine whether links add up to an inflow or outflow
      const [{ sourceId, targetId }, _] = links;
      const sourceNode = this.nodes[sourceId];
      const targetNode = this.nodes[targetId];

      if (isNil(sourceNode) || isNil(targetNode)) {
        return;
      }

      const totalSum = links.reduce((sum, link) => sum + link.sum, 0);

      if (totalSum < 0) {
        this.addLink(targetNode, sourceNode, Math.abs(totalSum));
      } else if (totalSum > 0) {
        this.addLink(sourceNode, targetNode, totalSum);
      }
    }, Object.values(this.pendingLinks));
  }

  private addMultiDimensionLinks() {
    forEach((node) => {
      if (node.type !== NodeType.Category) {
        return;
      }

      const isCategoryInflow = node.value > 0;
      const categoryValue = Math.abs(node.value);
      const categoryMetadata = node.metadata as GroupDetailEntity<'category'>;
      const categoryGroup = categoryMetadata.group;
      const categoryGroupSuffixedId = `${categoryGroup.id}${
        isCategoryInflow
          ? SANKEY_NODE_IDENTIFIER_SUFFIX.inflow
          : SANKEY_NODE_IDENTIFIER_SUFFIX.outflow
      }`;

      // Remove pre-existing category group node, which would not have inflow/outflow suffix
      // This may have already been removed by previous iterations, in which case this is a no-op
      if (isNotNil(this.nodes[categoryGroup.id])) {
        this.nodes = omit([categoryGroup.id], this.nodes);
      }

      // Add or update the category group node (with the correct suffix), adding the necessary
      // sub-category's value to it over time. In the end, the category group node will have the
      // total value of all sub-categories.
      const groupNode = this.addNode(
        categoryGroupSuffixedId,
        categoryGroup.name,
        categoryValue,
        NodeType.CategoryGroup,
        categoryGroup.type,
        categoryGroup,
      );

      if (isCategoryInflow) {
        // Visually appears as `Category ==> Category Group ==> Income`
        this.addPendingLink(node.id, groupNode.id, categoryValue);
        this.addPendingLink(groupNode.id, this.incomeNode.id, categoryValue);
      } else {
        // Visually appears as `Income ==> Category Group ==> Category`
        this.addPendingLink(this.incomeNode.id, groupNode.id, categoryValue);
        this.addPendingLink(groupNode.id, node.id, categoryValue);
      }
    }, Object.values(this.nodes));

    this.addSingleDimensionLinks();
  }
}

class ReportsNode {
  constructor(
    public id: string,
    public label: string,
    public value: number,
    public type: NodeType,
    public categoryGroupType?: CategoryGroupType,
    public metadata?: GroupDetailEntity<'category'> | GroupDetailEntity<'categoryGroup'>,
  ) {
    this.id = id;
    this.label = label;
    this.value = value;
    this.type = type;
    this.categoryGroupType = categoryGroupType;
    this.metadata = metadata;
  }

  public get isClickable() {
    return !!this.categoryGroupType;
  }

  public toJson() {
    return {
      id: this.id,
      label: this.label,
      value: this.value,
      type: this.type,
      isClickable: this.isClickable,
      categoryGroupType: this.categoryGroupType,
    };
  }
}

type ReportsPendingLink = {
  sourceId: string;
  targetId: string;
  sum: number;
};

class ReportsLink {
  constructor(
    public source: ReportsNode,
    public target: ReportsNode,
    public value: number,
  ) {
    this.source = source;
    this.target = target;
    this.value = Math.abs(value);
  }

  public toJson() {
    return {
      source: this.source.id,
      target: this.target.id,
      value: this.value,
    };
  }
}
