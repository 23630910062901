import useEventCallback from 'common/lib/hooks/useEventCallback';

type Args = {
  value: boolean;
  onChange: (value: boolean) => void;
};

export const useOnboardingSurveyCheckbox = ({ value, onChange }: Args) => {
  const onInteract = useEventCallback(() => {
    onChange(!value);
  });

  const onKeyDown = useEventCallback((event: React.KeyboardEvent) => {
    if (event.key === ' ') {
      event.preventDefault();
      onChange(!value);
    }
  });

  return { onInteract, onKeyDown };
};
