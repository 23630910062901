import useQuery from 'common/lib/hooks/useQuery';

import { gql } from 'common/generated/gql';

const usePreviewAccountDataTransferSnapshots = (
  fromAccountId?: string | null,
  toAccountId?: string | null,
  startDate?: string | null,
  endDate?: string | null,
) => {
  const {
    data,
    isNetworkRequestInFlight: loading,
    error,
  } = useQuery(PREVIEW_ACCOUNT_DATA_TRANSFER_SNAPSHOTS_QUERY, {
    skip: !fromAccountId || !toAccountId || !startDate || !endDate,
    variables: {
      input: {
        fromAccountId: fromAccountId!,
        toAccountId: toAccountId!,
        cutoverDate: null,
        startDate: startDate!,
        endDate: endDate!,
      },
    },
  });
  return { data: data?.previewAccountDataTransferSnapshots, loading, error };
};

const PREVIEW_ACCOUNT_DATA_TRANSFER_SNAPSHOTS_QUERY = gql(/* GraphQL */ `
  query Web_PreviewAccountDataTransferSnapshots($input: PreviewCopySnapshotsInput!) {
    previewAccountDataTransferSnapshots(input: $input) {
      previewSnapshots {
        date
        signedBalance
      }
      fromAccountSummary {
        numSnapshots
      }
      toAccountSummary {
        numSnapshots
        numNewerSnapshots
      }
    }
  }
`);

export default usePreviewAccountDataTransferSnapshots;
