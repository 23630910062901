import React from 'react';
import styled from 'styled-components';

import Card from 'components/lib/ui/Card';
import Drawer from 'components/lib/ui/Drawer';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

import { spacing, color, fontWeight, fontSize, radius, variables } from 'common/lib/theme/dynamic';

export const RecurringList = styled(Card)`
  margin-bottom: ${spacing.default};
  background: ${color.white};
  transform: scale(
    1
  ); /* create stacking context to prevent z index from header to affect modals and pop ups */
`;

export const RecurringListRows = styled(Drawer).attrs({ shouldAnimateOnMount: false })`
  padding: 0;
`;

const RecurringListHeaderWrapper = styled.div`
  background: ${variables.color.background.page};
  position: sticky;
  top: 0;
  border-bottom-left-radius: ${radius.medium};
  border-bottom-right-radius: ${radius.medium};
  overflow: hidden;

  /* This makes sure the header is above the icon button */
  z-index: 1; /* stylelint-disable-line plugin/no-z-index */
`;

type HeaderProps = {
  children: React.ReactNode;
};

const InnerRecurringListHeader = styled(FlexContainer).attrs({ alignCenter: true })<HeaderProps>`
  background: ${color.white};
  border-top-left-radius: ${radius.medium};
  border-top-right-radius: ${radius.medium};
  padding: ${spacing.small} ${spacing.default};
  gap: ${spacing.default};
  transition: ${({ theme }) => theme.transition.default};
`;

export const RecurringListHeader = ({ children, ...props }: HeaderProps) => (
  <RecurringListHeaderWrapper>
    <InnerRecurringListHeader {...props}>{children}</InnerRecurringListHeader>
  </RecurringListHeaderWrapper>
);

export const RecurringListCell = styled.div<{
  $width?: number;
  $align?: 'left' | 'right';
  $marginRight?: boolean;
}>`
  flex-grow: 1;
  width: ${({ $width = 260 }) => `${$width}px`};
  text-align: ${({ $align = 'left' }) => $align};
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  background: ${color.white};
  margin-right: ${({ $marginRight }) => ($marginRight ? spacing.xsmall : 0)};
`;

export const RecurringListRow = styled(FlexContainer).attrs({ alignCenter: true })`
  padding: ${spacing.small} ${spacing.default} ${spacing.small} ${spacing.xlarge};
  gap: ${spacing.default};
  border-top: 1px solid ${variables.color.divider.primary};
  transform: scale(1); /* create stacking context to prevent it from being on top of header */
`;

export const RecurringHeaderTitle = styled(Text)`
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.large};
`;

export const RecurringHeaderText = styled(Text)<{ $marginRight?: number }>`
  font-size: ${fontSize.small};
  color: ${color.textLight};
  font-weight: ${fontWeight.medium};
  margin-right: ${({ $marginRight = 0 }) => $marginRight}px;

  &:hover {
    cursor: pointer;
    color: ${color.blue};
  }
`;

export const PaymentDateLabel = styled(Text)<{ $late?: boolean }>`
  color: ${({ $late }) => ($late ? color.orange : color.text)};
  font-weight: ${({ $late }) => ($late ? fontWeight.medium : fontWeight.book)};
`;

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export const DirectionIcon = <T extends { property: string; direction: string }>({
  property,
  sortedBy,
}: {
  property: T['property'];
  sortedBy: T | null;
}) => {
  if (sortedBy?.property !== property) {
    return null;
  }

  return sortedBy?.direction === SortDirection.ASC ? (
    <Icon name="arrow-up" />
  ) : (
    <Icon name="arrow-down" />
  );
};

export const SummaryBox = styled(FlexContainer).attrs({ justifyBetween: true, alignCenter: true })`
  background: ${color.gray4};
  padding: ${spacing.small} 68px ${spacing.small} ${spacing.xlarge};
  border-radius: ${radius.medium};
  margin-bottom: ${spacing.default};
`;

export const SummaryColumn = styled(FlexContainer).attrs({ column: true })<{ $align?: string }>`
  text-align: ${({ $align = 'right' }) => $align};
`;

export const SummaryLabel = styled(Text)`
  color: ${color.textLight};
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.medium};
`;

export const SummaryText = styled(Text)<{ $isIncome?: boolean }>`
  color: ${({ $isIncome }) => ($isIncome ? color.greenText : color.text)};
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.medium};
`;

export const SummaryAmounts = styled(FlexContainer).attrs({ justifyBetween: true })`
  gap: 56px;
`;

export const DateText = styled(Text)`
  margin-right: ${spacing.xsmall};
`;
