import React from 'react';
import styled from 'styled-components';

import ManageCategoryRow from 'components/categories/ManageCategoryRow';
import Draggable from 'components/lib/dnd/Draggable';
import { useDraggableContext } from 'components/lib/dnd/DraggableContext';
import Droppable from 'components/lib/dnd/Droppable';
import DroppableContext from 'components/lib/dnd/DroppableContext';
import DroppableOutline from 'components/lib/dnd/DroppableOutline';
import DragDots from 'components/lib/ui/DragDots';
import FlexContainer from 'components/lib/ui/FlexContainer';
import TextButton from 'components/lib/ui/TextButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import PremiumBadge from 'components/premium/PremiumBadge';
import PremiumFeatureOverlayTrigger from 'components/premium/PremiumFeatureOverlayTrigger';

import noop from 'common/utils/noop';
import { getDroppableTypeForGroup } from 'lib/categories/DragAndDrop';

import { ProductFeature } from 'common/constants/premium';

import type { ManageGetCategoryGroupsQuery } from 'common/generated/graphql';
import type { ElementOf } from 'common/types/utility';

const StyledDraggable = styled(Draggable)`
  margin-top: ${({ theme }) => theme.spacing.default};
  background: ${({ theme }) => theme.color.grayBackground};
  border-radius: ${({ theme }) => theme.radius.medium};
`;

const HeaderDots = styled(DragDots)`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
  opacity: 0;
`;

const Header = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.default};
  padding-left: ${({ theme }) => theme.spacing.xsmall};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayFocus};
  display: flex;
  align-items: center;

  :hover {
    ${HeaderDots} {
      opacity: 1;
    }
  }
`;

const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.default};
`;

const ContentFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.default};
`;

const EmptyContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.xlarge} ${({ theme }) => theme.spacing.xlarge}
    ${({ theme }) => theme.spacing.xxlarge};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
`;

const EmptyTitle = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const EmptySubtitle = styled.span`
  color: ${({ theme }) => theme.color.textLight};
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const GrayButton = styled(TextButton)`
  color: ${({ theme }) => theme.color.textLight};
  padding: 0 ${({ theme }) => theme.spacing.small};

  :first-child {
    padding-left: 0;
  }
`;

const DragHeader = ({ children }: { children: React.ReactNode }) => {
  const { dragHandleProps } = useDraggableContext();
  return <Header {...dragHandleProps}>{children}</Header>;
};

type Props = {
  group: ElementOf<ManageGetCategoryGroupsQuery, 'categoryGroups'>;
  onClickEditGroup: () => void;
  onClickCreateCategory: () => void;
  onClickEditCategory: (categoryId: ElementOf<ManageGetCategoryGroupsQuery, 'categories'>) => void;
  children?: React.ReactNode;
  categories?: ManageGetCategoryGroupsQuery['categories'];
};

const ManageCategoryGroupCard = ({
  group,
  onClickEditGroup,
  onClickCreateCategory,
  onClickEditCategory,
  categories = [],
}: Props) => {
  const disabledCategories = categories.filter((category) => category.isDisabled);
  const activeCategories = categories.filter((category) => !category.isDisabled);
  return (
    <StyledDraggable draggableId={group.id} index={group.order} customDragHandle>
      <Droppable
        type={getDroppableTypeForGroup(group)}
        droppableId={group.id}
        styledComponent={DroppableOutline}
        customPlaceholder
      >
        <DragHeader>
          <HeaderDots />
          {group.name}
          <GrayButton onClick={onClickEditGroup}>Edit</GrayButton>
        </DragHeader>
        {disabledCategories.length + activeCategories.length === 0 ? (
          <EmptyContent>
            <EmptyTitle>No categories in this group</EmptyTitle>
            <EmptySubtitle>Drag categories into this group or create new ones</EmptySubtitle>
            <PrimaryButton onClick={onClickCreateCategory}>Create category</PrimaryButton>
          </EmptyContent>
        ) : (
          <Content>
            {activeCategories.map((category) => (
              <ManageCategoryRow
                key={category.id}
                category={category}
                onClick={() => onClickEditCategory(category)}
              />
            ))}
            <DroppableContext.Consumer>
              {({ placeholder }) => placeholder}
            </DroppableContext.Consumer>

            {disabledCategories.map((category) => (
              <ManageCategoryRow
                key={category.id}
                category={category}
                onClick={() => onClickEditCategory(category)}
              />
            ))}
            <ContentFooter>
              <PremiumFeatureOverlayTrigger
                feature={ProductFeature.CUSTOM_CATEGORIES}
                placement="right"
              >
                {({ hasAccess }) => (
                  <FlexContainer alignCenter>
                    <GrayButton onClick={hasAccess ? onClickCreateCategory : noop}>
                      Create Category
                    </GrayButton>
                    {!hasAccess && <PremiumBadge />}
                  </FlexContainer>
                )}
              </PremiumFeatureOverlayTrigger>
            </ContentFooter>
          </Content>
        )}
      </Droppable>
    </StyledDraggable>
  );
};

export default ManageCategoryGroupCard;
