import queryString from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';

import AccountSelection from 'components/routes/accounts/transferAccountData/steps/AccountSelection';
import Backup from 'components/routes/accounts/transferAccountData/steps/Backup';
import Results from 'components/routes/accounts/transferAccountData/steps/Results';
import Review from 'components/routes/accounts/transferAccountData/steps/Review';
import Snapshots from 'components/routes/accounts/transferAccountData/steps/Snapshots';
import Transactions from 'components/routes/accounts/transferAccountData/steps/Transactions';

import { useUpdatableQueryParams } from 'lib/hooks/useQueryParams';

type AllQueryParams = {
  step: number;
  fromAccountId: string | null;
  toAccountId: string | null;
  transferTransactions: boolean;
  startDateTransactions: string | null;
  endDateTransactions: string | null;
  fromAccountExpectedAffectedTransactionCount: number;
  toAccountExpectedAffectedTransactionCount: number;
  toAccountNewerTransactionCount: number;
  transferBalanceSnapshots: boolean;
  startDateBalanceSnapshots: string | null;
  endDateBalanceSnapshots: string | null;
  fromAccountExpectedAffectedBalanceSnapshotCount: number;
  toAccountExpectedAffectedBalanceSnapshotCount: number;
  toAccountNewerBalanceSnapshotCount: number;
};

const QUERY_PARAM_DEFAULTS: AllQueryParams = {
  step: 0,
  fromAccountId: null,
  toAccountId: null,
  transferTransactions: true,
  startDateTransactions: null,
  endDateTransactions: null,
  fromAccountExpectedAffectedTransactionCount: 0,
  toAccountExpectedAffectedTransactionCount: 0,
  toAccountNewerTransactionCount: 0,
  transferBalanceSnapshots: true,
  startDateBalanceSnapshots: null,
  endDateBalanceSnapshots: null,
  fromAccountExpectedAffectedBalanceSnapshotCount: 0,
  toAccountExpectedAffectedBalanceSnapshotCount: 0,
  toAccountNewerBalanceSnapshotCount: 0,
};

const steps = [AccountSelection, Transactions, Snapshots, Backup, Review, Results];

export type StepProps = {
  params: AllQueryParams;
  updateParams: (newParams?: Partial<AllQueryParams> | null | undefined, replace?: boolean) => void;
  next: (newParams?: Partial<AllQueryParams> | null | undefined, replace?: boolean) => void;
};

const TransferAccountData = () => {
  const [rawParams, updateParams] = useUpdatableQueryParams<AllQueryParams>({ cleanParams: false });
  const location = useLocation();

  // Parse query parameters with explicit type definitions
  const parsedParams = React.useMemo(
    () =>
      queryString.parse(location.search, {
        arrayFormat: 'comma',
        parseBooleans: true,
        parseNumbers: true,
        types: {
          fromAccountId: 'string',
          toAccountId: 'string',
        },
      }),
    [location.search],
  );

  const typedParams = React.useMemo(
    () => ({ ...QUERY_PARAM_DEFAULTS, ...parsedParams }),
    [parsedParams],
  );

  // Apply defaults to URL only on first load
  React.useEffect(() => {
    // Add default values for all missing parameters
    const missingParams = Object.entries(QUERY_PARAM_DEFAULTS)
      .filter(([key]) => rawParams[key] === undefined)
      .reduce(
        (acc, [key, value]) => {
          acc[key] = value;
          return acc;
        },
        {} as Record<string, unknown>,
      );

    // Only update if there are missing parameters to add
    if (Object.keys(missingParams).length > 0) {
      updateParams(missingParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If the current steps isn't in range, set it to the first step
  const currentStep =
    typedParams.step < 0 || typedParams.step >= steps.length ? 0 : typedParams.step;

  // Get the component for the current step
  const CurrentStep = steps[currentStep];

  return (
    <CurrentStep
      params={typedParams}
      updateParams={updateParams}
      next={(newParams, replace = false) => {
        updateParams(
          {
            ...newParams,
            step: currentStep + 1,
          },
          replace,
        );
      }}
    />
  );
};

export default TransferAccountData;
