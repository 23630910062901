import { gql } from '@apollo/client';
import React from 'react';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AddClientsModal from 'components/advisorPortal/AddClientsModal';
import AdvisorPortalClientsHeaderControls from 'components/advisorPortal/AdvisorPortalClientsHeaderControls';
import ClientsList from 'components/advisorPortal/ClientsList';
import GetAccessGrantModal from 'components/advisorPortal/GetAccessGrantModal';
import SubscriptionSponsorAccessGrantsList from 'components/advisorPortal/SubscriptionSponsorAccessGrantsList';
import RouteModal from 'components/lib/routing/RouteModal';
import Card from 'components/lib/ui/Card';
import CardHeader from 'components/lib/ui/CardHeader';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Page from 'components/lib/ui/Page';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import useQuery from 'common/lib/hooks/useQuery';

import routes from 'constants/routes';

import type { Web_GetAdvisorClientsPage } from 'common/generated/graphQlTypes/Web_GetAdvisorClientsPage';

const Container = styled(Flex)`
  flex-direction: column;
  padding: ${(props) => props.theme.spacing.gutter};
  padding-bottom: 0;
  padding-top: 0;
`;

const StyledCard = styled(Card)`
  margin-bottom: ${(props) => props.theme.spacing.gutter};
`;

const ButtonText = styled.span`
  white-space: nowrap;
`;

const AdvisorClients = () => {
  const history = useHistory();

  const { data, isLoadingInitialData } = useQuery<Web_GetAdvisorClientsPage>(
    GET_ADVISOR_CLIENTS_PAGE_QUERY,
  );

  const sponsorshipInvites = data?.subscriptionSponsorshipInvites ?? [];
  const accessGrants = data?.subscriptionSponsorAccessGrants ?? [];
  const sponsor = data?.subscriptionSponsor;
  const demoUserId = data?.subscriptionSponsorshipDemoAccount?.userId;

  const isLoadingStateVisible = isLoadingInitialData;

  return (
    <Page name="Clients" parentRoute={null}>
      <Helmet>
        <title>Professional Portal | Clients</title>
      </Helmet>
      <Container>
        <StyledCard>
          <CardHeader
            title="Sponsored Clients"
            description="A sponsored client is a client you pay for. You will be charged a fixed amount for each active client."
            controls={
              <AdvisorPortalClientsHeaderControls
                onAddClients={() => history.push(routes.advisorPortal.clients.addClients.path)}
              />
            }
            hideBottomBorder
          />
          <ClientsList
            invites={sponsorshipInvites}
            isLoading={isLoadingStateVisible}
            sponsor={sponsor}
            demoUserId={demoUserId}
          />
        </StyledCard>
        <StyledCard>
          <CardHeader
            title="Access-Only Clients"
            description="You have temporary access to these clients' households. They will need their own Monarch subscription, and you will not be billed for the access. "
            controls={
              <FlexContainer alignCenter justifyEnd marginLeft="default">
                <PrimaryButton
                  onClick={() => history.push(routes.advisorPortal.clients.getAccessGrant.path)}
                >
                  <ButtonIcon name="plus" />
                  <ButtonText>Get access</ButtonText>
                </PrimaryButton>
              </FlexContainer>
            }
          />
          <SubscriptionSponsorAccessGrantsList
            isLoading={isLoadingStateVisible}
            accessGrants={accessGrants}
          />
        </StyledCard>
      </Container>
      <RouteModal path={routes.advisorPortal.clients.addClients.path}>
        {({ close }) => (
          <AddClientsModal
            onDone={() => {
              close();
            }}
          />
        )}
      </RouteModal>
      <RouteModal path={routes.advisorPortal.clients.getAccessGrant.path}>
        <GetAccessGrantModal email={data?.me?.email || ''} />
      </RouteModal>
    </Page>
  );
};

const GET_ADVISOR_CLIENTS_PAGE_QUERY = gql`
  query Web_GetAdvisorClientsPage {
    subscriptionSponsor {
      id
      name
    }
    me {
      email
    }
    subscriptionSponsorshipInvites {
      id
      code
      label
      sentToEmail
      status
      createdAt
      sponsorship {
        id
        status
        household {
          id
          name
          profilePictureUrl
          owner {
            id
            name
            email
          }
          lastActivityAt
        }
      }
    }
    subscriptionSponsorAccessGrants {
      id
      toEmail
      householdGivingAccessName
      userGivingAccessId
      expiresAt
    }
    subscriptionSponsorshipDemoAccount {
      userId
    }
  }
`;

export default AdvisorClients;
