import React from 'react';
import type { DefaultTheme } from 'styled-components';
import styled from 'styled-components';

import AccountSummary from 'components/accounts/transferAccountData/AccountSummary';
import Card from 'components/lib/ui/Card';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import boxShadow from 'common/lib/styles/boxShadow';
import { spacing, variables } from 'common/lib/theme/dynamic';

import type { Web_GetAccountsForTransferQuery } from 'common/generated/graphql';

const ColumnWrapper = styled(FlexContainer).attrs({ column: true })``;

const StyledAccountSummary = styled(AccountSummary)`
  margin: ${spacing.small} ${spacing.default};
`;

const AccountCard = styled(Card)<{ $shadow: keyof typeof boxShadow }>`
  border: 1px solid ${variables.color.divider.secondary};
  overflow: hidden;
  ${({ $shadow }) => boxShadow[$shadow]}
`;

const SecondaryText = styled(Text)`
  color: ${variables.color.content.secondary};
`;

const SectionTitle = styled(Text)`
  margin-right: ${spacing.xxsmall};
`;

type Props = {
  title: string;
  subtitle: string;
  account:
    | Web_GetAccountsForTransferQuery['fromAccount']
    | Web_GetAccountsForTransferQuery['toAccount'];
  children?: React.ReactNode;
  titleSpacing?: keyof DefaultTheme['spacing'];
  shadow?: keyof typeof boxShadow;
};

export const AccountColumn: React.FC<Props> = ({
  title,
  subtitle,
  account,
  titleSpacing = 'gutter',
  shadow = 'medium',
  children,
}) => (
  <ColumnWrapper gap={titleSpacing}>
    <span>
      <SectionTitle weight="medium">{title}</SectionTitle>
      <SecondaryText size="small">{subtitle}</SecondaryText>
    </span>
    <AccountCard $shadow={shadow}>
      <StyledAccountSummary
        icon={account?.icon}
        logoUrl={account?.logoUrl}
        label={account?.displayName}
        lastUpdatedAt={account?.displayLastUpdatedAt}
        dataProvider={account?.dataProvider}
        institution={account?.institution}
        credential={account?.credential}
        syncDisabled={account?.syncDisabled}
      />
      {children}
    </AccountCard>
  </ColumnWrapper>
);
export { ColumnWrapper };
