import { assocPath, isNil, omit } from 'ramda';
import { isNotNil } from 'ramda-adjunct';
import { createMigrate } from 'redux-persist';
import type { MigrationManifest, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createReducer } from 'typesafe-actions';

import {
  setChartTypeForTab,
  setGroupByEntity,
  setGroupByTimeframe,
  setReportsFilters,
  setReportsSankeyGroupMode,
  setReportsTransactionsSortBy,
  setViewModeForTab,
} from 'actions';
import type { ReportsTab, TabDisplayProperties } from 'state/reports/types';
import { ReportsChart } from 'state/reports/types';

import {
  ReportsGroupByEntity,
  ReportsGroupByTimeframe,
  TransactionOrdering,
} from 'common/generated/graphql';
import type { TransactionFilters } from 'types/filters';

const migrations = {
  1: (state: ReportsState) => ({
    ...state,
    filters: {
      ...INITIAL_STATE.filters,
    },
    cashFlow: {
      ...INITIAL_STATE.cashFlow,
    },
  }),
};

export const LATEST_VERSION = Math.max(...Object.keys(migrations).map(parseInt));

export const persistConfig: PersistConfig<ReportsState> = {
  key: 'reports',
  version: LATEST_VERSION,
  storage,
  migrate: createMigrate(
    migrations as any as MigrationManifest, // have to cast because the type in redux-persist is wrong
    { debug: process.env.NODE_ENV !== 'production' },
  ),
};

const getDefaultChartTypeForView = (view: string) =>
  view === 'totalAmounts' ? ReportsChart.PieChart : ReportsChart.BarChart;

export type ReportsState = {
  filters: Partial<TransactionFilters>;
  groupBy?: ReportsGroupByEntity;
  groupByTimeframe: ReportsGroupByTimeframe;
  sortBy: Maybe<TransactionOrdering>;
} & {
  [tab in ReportsTab]: TabDisplayProperties;
};

export const INITIAL_STATE: ReportsState = {
  groupBy: ReportsGroupByEntity.CATEGORY,
  // Default to monthly for performance, even though daily makes more sense for the default filters.
  groupByTimeframe: ReportsGroupByTimeframe.MONTH,
  sortBy: TransactionOrdering.DATE,
  filters: {},
  spending: {
    viewMode: 'totalAmounts',
    chartType: ReportsChart.PieChart,
  },
  income: {
    viewMode: 'totalAmounts',
    chartType: ReportsChart.PieChart,
  },
  cashFlow: {
    chartType: ReportsChart.SankeyCashFlowChart,
    groupMode: 'both',
  },
};

const reportsReducer = createReducer<ReportsState>(INITIAL_STATE)
  .handleAction(setChartTypeForTab, (state, { payload: { chartType, tab } }) =>
    assocPath([tab, 'chartType'], chartType, state),
  )
  .handleAction(setGroupByEntity, (state, { payload: groupBy }) =>
    assocPath(['groupBy'], groupBy, state),
  )
  .handleAction(setGroupByTimeframe, (state, { payload: groupByTimeframe }) =>
    assocPath(['groupByTimeframe'], groupByTimeframe, state),
  )
  .handleAction(setReportsSankeyGroupMode, (state, { payload: view }) =>
    assocPath(['cashFlow', 'groupMode'], view, state),
  )
  .handleAction(setReportsTransactionsSortBy, (state, { payload: sortBy }) =>
    assocPath(['sortBy'], sortBy, state),
  )
  .handleAction(setViewModeForTab, (state, { payload: { view, tab } }) => {
    let result = assocPath([tab, 'viewMode'], view, state);

    // Reset the chart type if the view mode is changed
    if (state[tab]?.viewMode !== view) {
      result = assocPath([tab, 'chartType'], getDefaultChartTypeForView(view), result);
    }

    return result;
  })
  .handleAction(
    setReportsFilters,
    (state, { payload: { filters, maintainDateFilters, maintainNonDateFilters } }) => {
      // Extract date-related and non-date filters
      const { startDate, endDate, timeframePeriod, ...remainingCurrentFilters } = state.filters;
      const currentDateRangeFilters = { startDate, endDate, timeframePeriod };
      const {
        startDate: newStartDate,
        endDate: newEndDate,
        timeframePeriod: newTimeframePeriod,
        ...remainingNewFilters
      } = filters ?? {};

      // Reset to initial state, but respect only the maintainDateFilters flag. There's no reason to
      // maintain the non-date filters when asking for a reset.
      if (isNil(filters)) {
        return assocPath(
          ['filters'],
          maintainDateFilters
            ? { ...INITIAL_STATE.filters, ...currentDateRangeFilters }
            : INITIAL_STATE.filters,
          state,
        );
      }

      let updatedFilters: Partial<TransactionFilters> = {};
      if (maintainDateFilters) {
        updatedFilters = { ...updatedFilters, ...currentDateRangeFilters };
      }
      if (maintainNonDateFilters) {
        updatedFilters = { ...updatedFilters, ...remainingCurrentFilters };
      }
      updatedFilters = { ...updatedFilters, ...remainingNewFilters };

      // Prioritize explicit start/end dates over timeframe period.
      if (isNotNil(newStartDate) || isNotNil(newEndDate)) {
        updatedFilters = { ...updatedFilters, startDate: newStartDate, endDate: newEndDate };
        updatedFilters = omit(['timeframePeriod'], updatedFilters);
      } else if (isNotNil(newTimeframePeriod)) {
        updatedFilters = { ...updatedFilters, timeframePeriod: newTimeframePeriod };
        updatedFilters = omit(['startDate', 'endDate'], updatedFilters);
      }

      // Last ditch effort, in case the prior state was already in a broken scenario.
      if (isNotNil(updatedFilters.startDate) || isNotNil(updatedFilters.endDate)) {
        updatedFilters = omit(['timeframePeriod'], updatedFilters);
      }

      return assocPath(['filters'], updatedFilters, state);
    },
  );

export default reportsReducer;
