import { sentenceCase, snakeCase } from 'change-case';
import { omit, equals, sort, isNil } from 'ramda';
import { isNilOrEmpty, isNotNil } from 'ramda-adjunct';

import { ENTITY_TO_LABEL } from 'common/lib/reports';
import type { SankeyGroupMode } from 'lib/cashFlow/sankey';
import { generateAmountFilterFromRange } from 'lib/filters/utils';
import type { ReportsState } from 'state/reports/reducer';
import type { ReportsTab, TabDisplayProperties } from 'state/reports/types';
import { ReportsChart } from 'state/reports/types';

import type {
  TransactionFilterSet,
  ReportsGroupByTimeframe,
  ReportViewInput,
  ReportView,
} from 'common/generated/graphql';
import { Timeframe, ReportsGroupByEntity } from 'common/generated/graphql';
import type { TransactionFilters } from 'types/filters';

export const VIEW_MODE_TO_LABEL: Record<NonNullable<TabDisplayProperties['viewMode']>, string> = {
  totalAmounts: 'Totals',
  changeOverTime: 'Change',
};

export const TIMEFRAME_TO_LABEL: Record<
  NonNullable<Timeframe | ReportsGroupByTimeframe>,
  string
> = {
  [Timeframe.DAY]: 'Daily',
  [Timeframe.WEEK]: 'Weekly',
  [Timeframe.MONTH]: 'Monthly',
  [Timeframe.QUARTER]: 'Quarterly',
  [Timeframe.YEAR]: 'Yearly',
};

export const makeChartTitle = (groupBy: Maybe<ReportsGroupByEntity>, tab: ReportsTab) => {
  if (!groupBy) {
    return undefined;
  } else if (tab === 'cashFlow') {
    return 'Cash Flow';
  } else {
    const groupLabel = ENTITY_TO_LABEL[groupBy];
    return sentenceCase(`${tab} by ${groupLabel}`);
  }
};

export const formatReportsValue = (value: Maybe<number>) => (value ? Math.abs(value) : 0);

export const isTimeBasedReport = (
  tab?: ReportsTab,
  chartType?: ReportsChart,
  viewMode?: TabDisplayProperties['viewMode'],
) => {
  const isCashFlowBarChart = tab === 'cashFlow' && chartType !== ReportsChart.SankeyCashFlowChart;
  const supportsTimeBased = tab ? ['spending', 'income'].includes(tab) : false;
  const isChangeOverTime = viewMode === 'changeOverTime';
  return isCashFlowBarChart || (supportsTimeBased && isChangeOverTime);
};

export const convertReportViewToInput = (reportView: ReportView): ReportViewInput =>
  omit(['__typename'])(reportView);

export const getReportFiltersForTransactionFilterSet = (
  transactionFilterSet: TransactionFilterSet,
): Partial<TransactionFilters> => {
  const amountFilter = generateAmountFilterFromRange(
    transactionFilterSet.absAmountLte,
    transactionFilterSet.absAmountGte,
  );

  return {
    accounts: transactionFilterSet.accounts?.map((account) => account.id),
    categories: transactionFilterSet.categories?.map((category) => category.id),
    categoryGroups: transactionFilterSet.categoryGroups?.map((group) => group.id),
    merchants: transactionFilterSet.merchants?.map((merchant) => merchant.id),
    tags: transactionFilterSet.tags?.map((tag) => tag.id),
    isUntagged: transactionFilterSet.isUntagged,
    goalId: transactionFilterSet.goal?.id,
    uploadedStatement: transactionFilterSet.uploadedStatement?.id,
    amountFilter,
    absAmountGte: transactionFilterSet.absAmountGte,
    absAmountLte: transactionFilterSet.absAmountLte,
    categoryType: transactionFilterSet.categoryType,
    creditsOnly: transactionFilterSet.creditsOnly,
    debitsOnly: transactionFilterSet.debitsOnly,
    hasAttachments: transactionFilterSet.hasAttachments,
    hasNotes: transactionFilterSet.hasNotes,
    hideFromReports: transactionFilterSet.hiddenFromReports,
    importedFromMint: transactionFilterSet.importedFromMint,
    isFlexSpending: transactionFilterSet.isFlexSpending,
    isInvestmentAccount: transactionFilterSet.isInvestmentAccount,
    isPending: transactionFilterSet.isPending,
    isRecurring: transactionFilterSet.isRecurring,
    isSplit: transactionFilterSet.isSplit,
    isUncategorized: transactionFilterSet.isUncategorized,
    needsReview: transactionFilterSet.needsReview,
    needsReviewByUser: transactionFilterSet.needsReviewByUser?.id,
    needsReviewUnassigned: transactionFilterSet.needsReviewUnassigned,
    search: transactionFilterSet.searchQuery,
    syncedFromInstitution: transactionFilterSet.syncedFromInstitution,
    startDate: transactionFilterSet.startDate,
    endDate: transactionFilterSet.endDate,
    timeframePeriod: transactionFilterSet.timeframePeriod,
  };
};

export type DecodedReportView = {
  tab: ReportsTab;
  chartType: ReportsChart;
  calculationViewMode?: Maybe<'totalAmounts' | 'changeOverTime'>;
  sankeyGroupMode?: Maybe<SankeyGroupMode>;
  groupByEntity?: Maybe<ReportsGroupByEntity>;
  groupByTimeframe?: Maybe<ReportsGroupByTimeframe>;
};

export function decodeReportView(reportView: ReportView): DecodedReportView {
  // Determine which tab to show based on analysis scope
  let tab: ReportsTab = 'cashFlow';
  if (reportView.analysisScope === 'spending') {
    tab = 'spending';
  } else if (reportView.analysisScope === 'income') {
    tab = 'income';
  }

  // Determine chart type based on combination of chartType and layout
  let chartType: ReportsChart;
  switch (reportView.chartType) {
    case 'pie':
      chartType = ReportsChart.PieChart;
      break;
    case 'bar':
      if (reportView.chartLayout === 'horizontal') {
        chartType = ReportsChart.HorizontalBarChart;
      } else if (reportView.chartDensity === 'stacked') {
        chartType =
          tab === 'cashFlow' ? ReportsChart.StackedCashFlowChart : ReportsChart.StackedBarChart;
      } else {
        chartType = tab === 'cashFlow' ? ReportsChart.CashFlowChart : ReportsChart.BarChart;
      }
      break;
    case 'sankey':
      chartType = ReportsChart.SankeyCashFlowChart;
      break;
    default:
      chartType = tab === 'cashFlow' ? ReportsChart.SankeyCashFlowChart : ReportsChart.PieChart;
  }

  // Set view mode based on chart calculation
  let calculationViewMode: Maybe<'totalAmounts' | 'changeOverTime'>;
  if (isNotNil(reportView.chartCalculation) && ['spending', 'income'].includes(tab)) {
    const chartCalculationToViewModeMap: Record<
      string,
      Maybe<'totalAmounts' | 'changeOverTime'>
    > = {
      difference: 'changeOverTime',
      sum: 'totalAmounts',
    };
    calculationViewMode = chartCalculationToViewModeMap[reportView.chartCalculation];
  }

  // Set group by and sankey group mode if dimensions are specified
  let sankeyGroupMode: Maybe<SankeyGroupMode>;
  let groupByEntity: Maybe<ReportsGroupByEntity>;
  if (reportView.dimensions?.length) {
    const hasCategory = reportView.dimensions?.includes(ReportsGroupByEntity.CATEGORY);
    const hasCategoryGroup = reportView.dimensions?.includes(ReportsGroupByEntity.CATEGORY_GROUP);

    // Handle Sankey group mode for cash flow tab
    if (chartType === ReportsChart.SankeyCashFlowChart) {
      if (hasCategory && hasCategoryGroup) {
        sankeyGroupMode = 'both';
      } else if (hasCategoryGroup) {
        sankeyGroupMode = 'group';
      } else {
        sankeyGroupMode = 'category';
      }
    } else {
      const dimension = reportView.dimensions[0] as ReportsGroupByEntity;
      groupByEntity = Object.values(ReportsGroupByEntity).includes(dimension)
        ? dimension
        : undefined;
    }
  }

  // Set timeframe if specified
  let groupByTimeframe: Maybe<ReportsGroupByTimeframe>;
  if (isNotNil(reportView.timeframe) && isTimeBasedReport(tab, chartType, calculationViewMode)) {
    groupByTimeframe = reportView.timeframe as unknown as ReportsGroupByTimeframe;
  }

  return {
    tab,
    chartType,
    calculationViewMode,
    sankeyGroupMode,
    groupByEntity,
    groupByTimeframe,
  };
}

export function encodeReportView(state: ReportsState, tab: ReportsTab): ReportView {
  const displayProperties = state[tab];

  // Map analysis scope from tab
  const analysisScope = snakeCase(tab);

  let chartCalculation: string | undefined;
  if (isNotNil(displayProperties.viewMode)) {
    chartCalculation = displayProperties.viewMode === 'changeOverTime' ? 'difference' : 'sum';
  }

  // Map chart type / layout / density
  let chartType = 'bar';
  let chartLayout: string | undefined;
  let chartDensity: string | undefined;
  switch (displayProperties.chartType) {
    case ReportsChart.PieChart:
      chartType = 'pie';
      break;
    case ReportsChart.SankeyCashFlowChart:
      chartType = 'sankey';
      break;
    case ReportsChart.BarChart:
    case ReportsChart.CashFlowChart:
      chartLayout = 'vertical';
      chartDensity = 'split';
      break;
    case ReportsChart.StackedBarChart:
    case ReportsChart.StackedCashFlowChart:
      chartLayout = 'vertical';
      chartDensity = 'stacked';
      break;
    case ReportsChart.HorizontalBarChart:
      chartLayout = 'horizontal';
      break;
  }

  // Map dimensions based on group by and sankey mode; for sankey in 'both' mode, add both dimensions
  let dimensions: Maybe<ReportsGroupByEntity[]> = isNotNil(state.groupBy)
    ? [state.groupBy]
    : undefined;
  if (tab === 'cashFlow' && chartType === 'sankey') {
    if (displayProperties.groupMode === 'both') {
      dimensions = [ReportsGroupByEntity.CATEGORY, ReportsGroupByEntity.CATEGORY_GROUP];
    } else if (displayProperties.groupMode === 'group') {
      dimensions = [ReportsGroupByEntity.CATEGORY_GROUP];
    } else {
      dimensions = [ReportsGroupByEntity.CATEGORY];
    }
  }

  let timeframe: Maybe<Timeframe>;
  if (isTimeBasedReport(tab, displayProperties.chartType, displayProperties.viewMode)) {
    timeframe = state.groupByTimeframe as unknown as Maybe<Timeframe>;
  }

  return {
    __typename: 'ReportView',
    analysisScope,
    chartType,
    chartLayout,
    chartDensity,
    chartCalculation,
    dimensions,
    timeframe,
  };
}

/**
 * Compares two report views and returns true if they are equivalent on all fields.
 *
 * @param currentView - The current report view.
 * @param potentialView - The potential report view to compare against.
 * @returns true if the two report views are equivalent, false otherwise.
 */
export const areReportViewsEquivalent = (
  currentView: Maybe<ReportView>,
  potentialView: Maybe<ReportView>,
) => {
  if (isNil(currentView) && isNil(potentialView)) {
    return true;
  }

  if (isNil(currentView) || isNil(potentialView)) {
    return false;
  }

  const equivalency_map = {
    analysisScope: _areReportViewScalarsEquivalent(
      currentView?.analysisScope,
      potentialView?.analysisScope,
    ),
    chartType: _areReportViewScalarsEquivalent(currentView?.chartType, potentialView?.chartType),
    chartLayout: _areReportViewScalarsEquivalent(
      currentView?.chartLayout,
      potentialView?.chartLayout,
    ),
    chartDensity: _areReportViewScalarsEquivalent(
      currentView?.chartDensity,
      potentialView?.chartDensity,
    ),
    chartCalculation: _areReportViewScalarsEquivalent(
      currentView?.chartCalculation,
      potentialView?.chartCalculation,
    ),
    dimensions: _areReportViewDimensionsEquivalent(
      currentView?.dimensions,
      potentialView?.dimensions,
    ),
    timeframe: _areReportViewScalarsEquivalent(currentView?.timeframe, potentialView?.timeframe),
  };

  return Object.values(equivalency_map).every(Boolean);
};

const _areReportViewDimensionsEquivalent = (
  dimensions1: string[] | null | undefined,
  dimensions2: string[] | null | undefined,
) => {
  if (isNilOrEmpty(dimensions1) && isNilOrEmpty(dimensions2)) {
    return true;
  }

  if (isNilOrEmpty(dimensions1) || isNilOrEmpty(dimensions2)) {
    return false;
  }

  if (dimensions1!.length !== dimensions2!.length) {
    return false;
  }

  // At this point we know both arrays exist and are non-empty
  return equals(
    sort((a, b) => a.localeCompare(b), dimensions1!),
    sort((a, b) => a.localeCompare(b), dimensions2!),
  );
};

const _areReportViewScalarsEquivalent = (value1: any, value2: any) =>
  (isNilOrEmpty(value1) && isNilOrEmpty(value2)) || value1 === value2;
