import { getType } from 'typesafe-actions';

import {
  appLoaded,
  logPlaidEvent,
  logErrorSurfaced,
  viewedMobileBrowserWarning,
  logFinicityConnectErrored,
  logFinicityConnectEvent,
  logFinicityConnectOpened,
  logFinicityConnectComplete,
} from 'actions';
import { login } from 'common/state/user/actions';
import { initializeCustomerIO } from 'lib/analytics/customerio';
import identifyUser from 'lib/analytics/identifyUser';
import {
  loadSegment,
  trackCurrentPage,
  logLoadedWebApp,
  trackDedupedFacebookPixelEvent,
} from 'lib/analytics/segment';
import typewriter from 'lib/analytics/typewriter';
import { getDedupEventId } from 'lib/analytics/util';
import { getUser } from 'selectors';
import type { Middleware } from 'state/types';
import { routeChanged } from 'state/ui/actions';

import { AnalyticsEventNames, PLAID_EVENT_NAMES_RAW } from 'common/constants/analytics';

// TODO middleware type doesn't parameterize action
const analyticsMiddleware: Middleware = (store) => (next) => (action) => {
  let payload;
  const { type } = action;
  const user = getUser(store.getState());

  switch (type) {
    case getType(appLoaded):
      loadSegment();
      initializeCustomerIO();
      if (user?.external_id) {
        logLoadedWebApp();
        identifyUser(user.external_id, user.email, user.household_role || undefined);
      }
      break;
    case getType(login):
      ({ payload } = action);
      identifyUser(payload.external_id, payload.email, payload.household_role);
      if (payload.signedUpAndVerified) {
        typewriter.userSignedUpAndVerifiedWeb({
          userId: payload.external_id,
          email: payload.email,
        });
        const eventId = payload?.external_id ? getDedupEventId(payload.external_id) : undefined;
        trackDedupedFacebookPixelEvent(AnalyticsEventNames.CompleteRegistration, {}, eventId);
      }
      break;
    case getType(routeChanged):
      ({ payload } = action);
      trackCurrentPage(payload.path, payload.search, payload.pageTitle, payload.pageName);
      break;
    case getType(logPlaidEvent):
      ({ payload } = action);
      switch (payload.eventName) {
        case PLAID_EVENT_NAMES_RAW.OPEN:
          typewriter.plaidLinkOpened(payload.metadata);
          break;
        case PLAID_EVENT_NAMES_RAW.EXIT:
          typewriter.plaidLinkExited(payload.metadata);
          break;
        case PLAID_EVENT_NAMES_RAW.HANDOFF:
          typewriter.plaidLinkHandedOff(payload.metadata);
          break;
        case PLAID_EVENT_NAMES_RAW.SEARCH_INSTITUTION:
          typewriter.plaidLinkInstitutionsSearched(payload.metadata);
          break;
        case PLAID_EVENT_NAMES_RAW.SELECT_INSTITUTION:
          typewriter.plaidLinkInstitutionSelected(payload.metadata);
          break;
        case PLAID_EVENT_NAMES_RAW.SUBMIT_CREDENTIALS:
          typewriter.plaidLinkCredentialsSubmitted(payload.metadata);
          break;
        case PLAID_EVENT_NAMES_RAW.SUBMIT_MFA:
          typewriter.plaidLinkMfaSubmitted(payload.metadata);
          break;
        case PLAID_EVENT_NAMES_RAW.TRANSITION_VIEW:
          typewriter.plaidLinkViewTransitioned(payload.metadata);
          break;
        case PLAID_EVENT_NAMES_RAW.ERROR:
          typewriter.plaidLinkErrored(payload.metadata);
          break;
        default:
          break;
      }
      break;
    case getType(logErrorSurfaced):
      ({ payload } = action);
      typewriter.errorSurfaced({
        error_string: payload.error.message,
        from_route: payload.fromRoute,
      });
      break;
    case getType(viewedMobileBrowserWarning):
      ({ payload } = action);
      typewriter.viewedMobileBrowserWarning();
      break;
    case getType(logFinicityConnectErrored):
      ({ payload } = action);
      typewriter.finicityConnectErrored(payload);
      break;
    case getType(logFinicityConnectOpened):
      typewriter.finicityConnectOpened();
      break;
    case getType(logFinicityConnectEvent):
      ({ payload } = action);
      typewriter.finicityConnectEvent(payload);
      break;
    case getType(logFinicityConnectComplete):
      typewriter.finicityConnectComplete();
      break;
    default:
      break;
  }

  const response = next(action);

  // This needs to run _after_ next, to ensure the user object has been processed
  // and we've stored their token, and API calls will be authorized.
  if (type === getType(login)) {
    logLoadedWebApp();
  }

  return response;
};

export default analyticsMiddleware;
