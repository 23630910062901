import React from 'react';
import styled from 'styled-components';

import Drawer from 'components/lib/ui/Drawer';
import Toggle from 'components/lib/ui/Toggle';

const Root = styled.div`
  border-radius: ${({ theme }) => theme.radius.medium};
  background: ${({ theme }) => theme.color.grayBackground};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  cursor: pointer;
  user-select: none;
`;

const Body = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.grayFocus};
  padding: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  title: string;
  enabled: boolean;
  onToggle: () => void;
  children?: React.ReactNode;
  className?: string;
};

const ToggleCard = ({ title, enabled, onToggle, children, className }: Props) => (
  <Root className={className}>
    <Header onClick={onToggle}>
      <span>{title}</span>
      <Toggle checked={enabled} />
    </Header>
    {!!children && (
      <Drawer open={enabled} shouldAnimateOnMount={false}>
        <Body>{children}</Body>
      </Drawer>
    )}
  </Root>
);

export default ToggleCard;
