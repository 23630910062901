import React, { useMemo } from 'react';
import styled from 'styled-components';

import AccountSummary from 'components/accounts/transferAccountData/AccountSummary';
import Select from 'components/lib/form/Select';
import Banner from 'components/lib/ui/Banner';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import { MenuItem } from 'components/lib/ui/menu/Menu';
import type { StepProps } from 'components/routes/accounts/transferAccountData/TransferAccountData';
import TransferAccountDataStep from 'components/routes/accounts/transferAccountData/TransferAccountDataStep';

import { spacing, variables } from 'common/lib/theme/dynamic';
import { getProgressPercent } from 'lib/accounts/transferAccountData/utils/progress';
import type { AccountOption, RecommendedAccountGroup } from 'lib/accounts/transferRecommendations';
import {
  getRecommendedAccountGroups,
  isUnsupportedAccount,
} from 'lib/accounts/transferRecommendations';
import { track } from 'lib/analytics/segment';
import useRecommendedAccounts from 'lib/hooks/accounts/useRecommendedAccounts';
import type { AccountSelectOption } from 'lib/hooks/useAccountSelectOptions';
import useAccountSelectOptions from 'lib/hooks/useAccountSelectOptions';

import { AccountTypeName } from 'common/constants/accounts';
import { TransferAccountDataEventNames } from 'common/constants/analytics';

const FullsizeSelect = styled(Select)`
  && .react-select {
    &__control {
      padding: 5px 8px;
      min-height: ${spacing.xxxlarge};
    }

    &__single-value {
      position: relative;
      width: 100%;
      transform: none;
      margin: 0;
    }

    &__menu-list {
      padding: 0;
    }

    &__group {
      padding: 0;
    }

    &__group-heading {
      background-color: unset;
      padding: 0;
    }
  }

  ${MenuItem} {
    margin-top: ${spacing.xsmall};
    margin-bottom: ${spacing.xsmall};
    padding: 7px 4px;

    &[disabled] {
      color: ${variables.color.content.primary};
      opacity: 0.5;
    }
  }
`;

const StyledAccountSummary = styled(AccountSummary)`
  margin: 9px 0;
  width: 100%;
  height: 100%;
  flex-basis: 100%;
  min-width: 0;

  .react-select__menu-list & {
    padding: 0 4px;
    margin-right: 32px;
  }
`;

const SectionTitle = styled(Text)`
  margin-right: ${spacing.xxsmall};
`;

const SectionSubtitle = styled(Text)`
  color: ${variables.color.content.secondary};
`;

const GroupHeader = styled.div`
  background: ${({ theme }) => theme.color.grayBackground};
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  padding: 6px 10px;
  padding-left: ${({ theme }) => theme.spacing.large};
  text-transform: none;
`;

const GroupHeaderWrapper = styled.div`
  background: ${variables.color.background.primary};
`;

const StyledBanner = styled(Banner)`
  margin: ${spacing.xsmall} ${spacing.xsmall} 0 ${spacing.xsmall};
  color: ${variables.color.content.info};
`;

const formatGroupLabel = (data: RecommendedAccountGroup) => {
  if (data.type === 'unsupported') {
    return (
      <GroupHeaderWrapper>
        <GroupHeader>{data.label}</GroupHeader>
        <StyledBanner type="info">
          <FlexContainer>
            <FlexContainer marginRight="small" marginTop="xxsmall">
              <Icon name="info" size={16} />
            </FlexContainer>
            To transfer data, accounts must be the same type (e.g. both cash or both investment),
            and data cannot be transferred to a manual account.
          </FlexContainer>
        </StyledBanner>
      </GroupHeaderWrapper>
    );
  }
  return <GroupHeader>{data.label}</GroupHeader>;
};

const AccountSelection: React.FC<StepProps> = ({
  params: { fromAccountId, toAccountId },
  updateParams,
  next,
}) => {
  const [isLoadingAccounts, accountOptions] = useAccountSelectOptions({
    shouldGroupByType: false,
  });
  const { data: recommendedAccountData, isLoading: loadingRecommendedAccounts } =
    useRecommendedAccounts(fromAccountId);

  const eitherAccountIsBrokerage = useMemo(
    () =>
      accountOptions.some(
        (option) =>
          (option.accountData?.id === fromAccountId || option.accountData?.id === toAccountId) &&
          option.accountData?.type.name === AccountTypeName.BROKERAGE,
      ),
    [accountOptions, fromAccountId, toAccountId],
  );

  const handleNext = () => {
    if (!toAccountId) {
      return;
    }

    // Check which type of account is selected
    const isRecommendedAccount = recommendedAccountData?.recommendedAccounts.some(
      (account) => account.id === toAccountId,
    );
    const isSameSubtypeAccount = recommendedAccountData?.sameSubtypeAccounts.some(
      (account) => account.id === toAccountId,
    );
    const isOtherAccount = recommendedAccountData?.otherAccounts.some(
      (account) => account.id === toAccountId,
    );

    const hasRecommendedAccounts = (recommendedAccountData?.recommendedAccounts ?? []).length > 0;

    track(TransferAccountDataEventNames.TransferAccountDataAccountSelected, {
      fromAccountId,
      toAccountId,
      isRecommendedAccount,
      isSameSubtypeAccount,
      isOtherAccount,
      hasRecommendedAccounts,
    });

    next();
  };

  return (
    <TransferAccountDataStep
      progress={getProgressPercent(1)}
      exitAccountId={fromAccountId}
      isLoading={isLoadingAccounts}
      overrideTitle="Select accounts"
      footer={
        <PrimaryButton size="large" disabled={!toAccountId} onClick={handleNext}>
          Next up: Transactions
        </PrimaryButton>
      }
    >
      <FlexContainer column marginTop="xxlarge" gap="gutter">
        <FlexContainer column gap="xxsmall">
          <Text size="large" weight="medium">
            Accounts
          </Text>
          <Text>
            Choose the accounts you want to move data from/to. Note: You&rsquo;ll be able to
            download CSV backups of transactions and balances before the transfer occurs.
          </Text>
        </FlexContainer>
        <FlexContainer column gap="xsmall">
          <span>
            <SectionTitle weight="medium">Old</SectionTitle>
            <SectionSubtitle size="small">
              (We&rsquo;ll move data from this account)
            </SectionSubtitle>
          </span>
          <FullsizeSelect
            name="from_accounts"
            options={accountOptions}
            isCreatable={false}
            isSearchable={false}
            placeholder="Select an account"
            renderOption={(option: AccountSelectOption) => (
              <StyledAccountSummary
                icon={option.accountData?.icon}
                logoUrl={option.accountData?.logoUrl}
                label={option.label}
                balance={option.accountData?.displayBalance}
                createdAt={option.accountData?.createdAt}
                lastUpdatedAt={option.accountData?.displayLastUpdatedAt}
                dataProvider={option.accountData?.dataProvider}
                institution={option.accountData?.institution}
                credential={option.accountData?.credential}
                syncDisabled={option.accountData?.syncDisabled}
              />
            )}
            value={fromAccountId}
            onChange={(value: AccountSelectOption) => {
              updateParams({ fromAccountId: value.value, toAccountId: null });
            }}
          />
        </FlexContainer>
        <FlexContainer column gap="xsmall">
          <span>
            <SectionTitle weight="medium">New</SectionTitle>
            <SectionSubtitle size="small">(We&rsquo;ll move data to this account)</SectionSubtitle>
          </span>
          <FullsizeSelect
            name="to_accounts"
            options={getRecommendedAccountGroups(recommendedAccountData)}
            isOptionDisabled={(option: AccountOption) => isUnsupportedAccount(option.accountData)}
            isLoading={loadingRecommendedAccounts}
            isCreatable={false}
            isSearchable={false}
            placeholder="Select an account"
            formatGroupLabel={formatGroupLabel}
            renderOption={(option: AccountOption) => {
              const accountData = isUnsupportedAccount(option.accountData)
                ? option.accountData.account
                : option.accountData;
              return (
                <StyledAccountSummary
                  icon={accountData.icon}
                  logoUrl={accountData.logoUrl}
                  label={accountData.displayName}
                  balance={accountData.displayBalance}
                  createdAt={accountData.createdAt}
                  lastUpdatedAt={accountData.displayLastUpdatedAt}
                  dataProvider={accountData.dataProvider}
                  institution={accountData.institution}
                  syncDisabled={accountData.syncDisabled}
                />
              );
            }}
            value={toAccountId}
            onChange={(value: AccountOption) => {
              updateParams({ toAccountId: value.value });
            }}
          />
        </FlexContainer>
        {eitherAccountIsBrokerage && (
          <Banner type="info">
            <FlexContainer>
              <FlexContainer marginRight="small" marginTop="xxsmall">
                <Icon name="info" size={16} />
              </FlexContainer>
              Note: Holdings data for investment accounts cannot be transferred. At this time, you
              can only choose to transfer transactions and/or balances.
            </FlexContainer>
          </Banner>
        )}
      </FlexContainer>
    </TransferAccountDataStep>
  );
};

export default AccountSelection;
