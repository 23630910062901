import React from 'react';
import { FiFacebook, FiTwitter, FiLinkedin, FiMail } from 'react-icons/fi';
import styled, { css } from 'styled-components';

import Badge from 'components/lib/ui/Badge';
import ClickToCopy from 'components/lib/ui/ClickToCopy';
import { ClickTriggeredTooltip } from 'components/lib/ui/ClickTriggeredTooltip';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import ShareLinkItem from 'components/settings/referrals/ShareLinkItem';

import {
  getShareXUrl,
  getShareFacebookUrl,
  getShareLinkedInUrl,
  getShareMailUrl,
  getShareTextUrl,
} from 'common/lib/referral';
import { color, radius, spacing } from 'common/lib/theme/dynamic';
import typewriter from 'lib/analytics/typewriter';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import useTheme from 'lib/hooks/useTheme';
import { copyToClipboard } from 'utils/clipboard';

import { REFERRALS } from 'common/constants/copy';
import { FACEBOOK_COLOR, TWITTER_COLOR, LINKEDIN_COLOR } from 'common/constants/social';

const LegacyLinkContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: ${({ theme }) => theme.color.grayBackground};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  gap: 1px;

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    grid-template-columns: 1fr;
  }
`;

const LegacyCopyLinkContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const LegacyCopyLinkText = styled(Text).attrs({ weight: 'medium' })`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const Messaging = styled(FlexContainer).attrs({
  column: true,
  gap: 'xlarge',
  justifyCenter: true,
})<{ isCentered?: boolean; isSidebarExpanded?: boolean }>`
  flex: 1;
  padding-left: ${spacing.xlarge};
  align-items: ${({ isCentered }) => (isCentered ? 'center' : 'flex-start')};

  @media (max-width: ${({ isSidebarExpanded, theme }) =>
      isSidebarExpanded ? theme.breakPoints.lg : theme.breakPoints.sm}px) {
    padding-left: 0;
  }
`;

const LimitedTimeBadge = styled(Badge).attrs({ color: 'orange' })`
  margin: 0;
`;

const Headline = styled(Text).attrs({ weight: 'medium', size: 'xlarge' })`
  display: block;
`;

const CUTOFF_WIDTH = 370;

const SubHeadline = styled(Text).attrs({ size: 'base', colorVariable: 'content.secondary' })`
  max-width: ${CUTOFF_WIDTH}px;
`;

const ShareButtons = styled(FlexContainer).attrs({
  column: true,
  gap: 'xsmall',
  justifyCenter: true,
})<{
  isCentered?: boolean;
}>`
  flex: 1;
  ${({ isCentered }) =>
    isCentered &&
    css`
      width: 100%;
      max-width: ${CUTOFF_WIDTH}px;
      align-self: center;
    `}
`;

const StyledBoxIcon = styled(Icon).attrs({ size: 16 })`
  color: ${color.white};
  transform: translateX(0.5px);
`;

const StyledLogoIcon = styled(Icon).attrs({ size: 24 })`
  border-radius: ${radius.small};
`;

const V2Container = styled(FlexContainer)<{ isCentered?: boolean }>`
  text-align: ${({ isCentered }) => (isCentered ? 'center' : 'left')};
`;

const DisclaimerText = styled(Text).attrs({ size: 'xsmall', colorVariable: 'input.placeholder' })<{
  isCentered?: boolean;
}>`
  ${({ isCentered }) => isCentered && 'max-width: 266px;'}
`;

type Props = {
  className?: string;
  referralUrl: string;
  isCentered?: boolean;
  isReferralsV2Active: boolean;
  isSidebarExpanded?: boolean;
};

const ShareLinkSection = ({
  className,
  isCentered,
  isReferralsV2Active,
  isSidebarExpanded,
  referralUrl,
}: Props) => {
  const isReferralPromoCopyChangesActive = useIsFeatureFlagOn('referral-promo-test-copy-changes');
  const theme = useTheme();

  return isReferralsV2Active ? (
    <V2Container
      className={className}
      isCentered={isCentered}
      column={isCentered}
      justifyCenter={isCentered}
    >
      <Messaging isCentered={isCentered} isSidebarExpanded={isSidebarExpanded}>
        <FlexContainer column gap="small">
          <LimitedTimeBadge>Limited time</LimitedTimeBadge>

          <FlexContainer column gap="xsmall">
            <Headline>{REFERRALS.SHARE_LINK.HEADLINE}</Headline>
            <SubHeadline>{REFERRALS.SHARE_LINK.SUB_HEADLINE}</SubHeadline>
          </FlexContainer>
        </FlexContainer>

        <div>
          <ClickTriggeredTooltip
            content="Copied"
            afterShow={() => copyToClipboard(referralUrl)}
            place="top"
          >
            <PrimaryButton
              size="medium"
              onClick={() => {
                typewriter.referralCodeShared({ method: 'copy' });
              }}
            >
              <ButtonIcon name="copy" />
              <span>{REFERRALS.SHARE_LINK.COPY_BUTTON_TEXT}</span>
            </PrimaryButton>
          </ClickTriggeredTooltip>
        </div>

        <DisclaimerText isCentered={isCentered}>{REFERRALS.SHARE_LINK.DISCLAIMER}</DisclaimerText>
      </Messaging>

      <ShareButtons isCentered={isCentered}>
        <ShareLinkItem
          isReferralsV2Active={isReferralsV2Active}
          icon={<StyledBoxIcon name="message-circle" />}
          title="Share via text"
          color={theme.color.green9}
          href={getShareTextUrl(referralUrl, { isReferralPromoCopyChangesActive })}
          platform="text"
        />

        <ShareLinkItem
          isReferralsV2Active={isReferralsV2Active}
          icon={<StyledBoxIcon name="mail" />}
          title="Share via email"
          color={theme.color.orange}
          href={getShareMailUrl(referralUrl, { isReferralPromoCopyChangesActive })}
          platform="email"
        />

        <FlexContainer gap="xsmall">
          <ShareLinkItem
            isReferralsV2Active={isReferralsV2Active}
            icon={<StyledLogoIcon name="monarch-facebook-logo" />}
            href={getShareFacebookUrl(referralUrl, { isReferralPromoCopyChangesActive })}
            platform="facebook"
          />
          <ShareLinkItem
            isReferralsV2Active={isReferralsV2Active}
            icon={<StyledLogoIcon name="monarch-linkedin-logo" />}
            href={getShareLinkedInUrl(referralUrl)}
            platform="linkedin"
          />
          <ShareLinkItem
            isReferralsV2Active={isReferralsV2Active}
            icon={<StyledLogoIcon name="monarch-x-logo" />}
            href={getShareXUrl(referralUrl, { isReferralPromoCopyChangesActive })}
            platform="x"
          />
        </FlexContainer>
      </ShareButtons>
    </V2Container>
  ) : (
    <div className={className}>
      <LegacyLinkContainer>
        <ShareLinkItem
          isReferralsV2Active={isReferralsV2Active}
          icon={<FiFacebook fill={theme.color.textWhite} color="transparent" />}
          title="Share on Facebook"
          color={FACEBOOK_COLOR}
          href={getShareFacebookUrl(referralUrl, { isReferralPromoCopyChangesActive })}
          platform="facebook"
        />
        <ShareLinkItem
          isReferralsV2Active={isReferralsV2Active}
          icon={<FiLinkedin fill={theme.color.textWhite} color="transparent" />}
          title="Share on LinkedIn"
          color={LINKEDIN_COLOR}
          href={getShareLinkedInUrl(referralUrl)}
          platform="linkedin"
        />
        <ShareLinkItem
          isReferralsV2Active={isReferralsV2Active}
          icon={<FiTwitter fill={theme.color.textWhite} color="transparent" />}
          title="Share on Twitter"
          color={TWITTER_COLOR}
          href={getShareXUrl(referralUrl, { isReferralPromoCopyChangesActive })}
          platform="twitter"
        />
        <ShareLinkItem
          isReferralsV2Active={isReferralsV2Active}
          icon={<FiMail color={theme.color.textWhite} />}
          title="Share via Email"
          color={theme.color.orange}
          href={getShareMailUrl(referralUrl, { isReferralPromoCopyChangesActive })}
          platform="email"
        />
      </LegacyLinkContainer>
      <LegacyCopyLinkContainer>
        <LegacyCopyLinkText>Copy your referral link and share it anywhere</LegacyCopyLinkText>
        <ClickToCopy
          tooltipPlace="left"
          afterCopy={() => typewriter.referralCodeShared({ method: 'copy' })}
        >
          {referralUrl}
        </ClickToCopy>
      </LegacyCopyLinkContainer>
    </div>
  );
};

export default ShareLinkSection;
