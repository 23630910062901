import { css } from 'styled-components';

import { radius, spacing, variables } from 'common/lib/theme/dynamic';

import type { ThemeType } from 'common/types/Styles';

export const onboardingSurveyAnswerContainerStyles = css`
  border-radius: ${radius.medium};
  background: ${variables.color.background.secondary};
  overflow: hidden;
`;

export const onboardingSurveyAnswerPaddingStyles = css`
  padding: ${spacing.small} ${spacing.default};
`;

export const onboardingSurveyTextColor = {
  active: ({ theme }: { theme: ThemeType }) => theme.color.textWhite,
  inactive: ({ theme }: { theme: ThemeType }) => theme.color.text,
};
