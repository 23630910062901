import { gql } from 'common/generated/gql';

export const GET_ONBOARDING_FINANCIAL_GOALS_QUERY = gql(/* GraphQL */ `
  query Common_GetOnboardingFinancialGoals {
    onboardingFinancialGoals {
      id
      name
    }
    userOnboardingFinancialGoals {
      id
      onboardingFinancialGoal {
        id
      }
      otherText
    }
  }
`);

export const SET_ONBOARDING_FINANCIAL_GOALS_MUTATION = gql(/* GraphQL */ `
  mutation Common_SetUserOnboardingFinancialGoals($input: [SetUserOnboardingFinancialGoalInput!]!) {
    setUserOnboardingFinancialGoals(input: $input) {
      userOnboardingFinancialGoals {
        id
        onboardingFinancialGoal {
          id
        }
      }
    }
  }
`);
