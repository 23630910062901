import * as RA from 'ramda-adjunct';
import * as React from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import SettingsCard from 'components/lib/layouts/SettingsCard';
import Banner from 'components/lib/ui/Banner';
import Column from 'components/lib/ui/Column';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Link from 'components/lib/ui/Link';
import Text from 'components/lib/ui/Text';
import { ProcessSection } from 'components/settings/referrals/ProcessSection';
import { ReferralEarnings } from 'components/settings/referrals/ReferralEarnings';
import ReferralTracker from 'components/settings/referrals/ReferralTracker';
import ShareLinkSection from 'components/settings/referrals/ShareLinkSection';

import useSubscriptionDetails from 'common/lib/hooks/billing/useSubscriptionDetails';
import useQuery from 'common/lib/hooks/useQuery';
import useSharedConstants from 'common/lib/hooks/useSharedConstants';
import { formatReferralCode } from 'common/lib/referral';
import { spacing, variables } from 'common/lib/theme/dynamic';
import { formatCurrency } from 'common/utils/Currency';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import { getIsSidebarExpanded } from 'selectors';

import { REFERRALS } from 'common/constants/copy';
import { HELP_CENTER_SWITCH_BILLING_ARTICLE_URL } from 'common/constants/externalUrls';

import { gql } from 'common/generated/gql';
import { SubscriptionDetailsPaymentSource } from 'common/generated/graphql';
import type { Web_GetReferralSettingsQuery } from 'common/generated/graphql';

const PAYMENT_SOURCE_TO_LABEL: Record<SubscriptionDetailsPaymentSource, string> = {
  STRIPE: 'Stripe',
  PLAY_STORE: 'Play Store',
  APP_STORE: 'App Store',
  PROMOTIONAL: 'Promotional',
};

const Card = styled(SettingsCard)`
  margin-bottom: ${spacing.gutter};
`;

const CardInnerContent = styled.div`
  margin: ${spacing.default} 0;
`;

const List = styled.ul`
  line-height: 180%;
  margin-right: ${spacing.xsmall};
  list-style-position: outside;
  padding: 0 1.5em;

  li {
    &:not(:last-child) {
      margin-bottom: ${spacing.xsmall};
    }
  }
`;

const PaymentSourceMessage = styled(Banner).attrs({ type: 'error' })`
  margin-bottom: ${spacing.default};
`;

const Line = styled.span`
  display: block;
`;

const Content = styled(FlexContainer).attrs({ column: true })`
  padding: 0 ${spacing.xlarge};
`;

const ShareLinkSectionV2 = styled(ShareLinkSection)<{ isSidebarExpanded?: boolean }>`
  padding: ${spacing.xlarge};
  gap: ${spacing.gutter};

  @media (max-width: ${({ isSidebarExpanded, theme }) =>
      isSidebarExpanded ? theme.breakPoints.lg : theme.breakPoints.sm}px) {
    flex-direction: column;
    gap: ${spacing.xxlarge};
  }
`;

const StyledReferralEarnings = styled(ReferralEarnings)`
  padding: ${spacing.xlarge};
`;

const StyledProcessSection = styled(ProcessSection)`
  padding: ${spacing.xlarge};
`;

const DividerContainer = styled.div`
  padding: ${spacing.xsmall} 0;
  margin-bottom: ${spacing.gutter};
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${variables.color.divider.secondary};
`;

const withStatisticsValues = ({
  isLoadingStatistics,
  statistics,
}: {
  isLoadingStatistics: boolean;
  statistics?: Web_GetReferralSettingsQuery['referralStatistics'];
}) => {
  if (isLoadingStatistics || !statistics) {
    return false;
  }

  return statistics.creditsEarned > 0 || statistics.signedUp > 0 || statistics.subscribed > 0;
};

const ReferralSettings = () => {
  const isReferralPromoCopyChangesActive = useIsFeatureFlagOn('referral-promo-test-copy-changes');
  const isReferralsV2Active = useIsFeatureFlagOn('referrals-v2');
  const isSidebarExpanded = useSelector(getIsSidebarExpanded);

  const { data, isLoadingInitialData: isLoadingStatistics } = useQuery(REFERRAL_SETTINGS_QUERY);
  const statistics = data?.referralStatistics;

  const { paymentSource, referralCode, isLoading } = useSubscriptionDetails();
  const [
    { monthlyPriceDollars, referralsTrialDurationDays, activeReferralCampaign },
    { isLoading: isLoadingConstants },
  ] = useSharedConstants();

  const referralUrl = React.useMemo(() => formatReferralCode(referralCode ?? ''), [referralCode]);

  const withLegacyReferralsSection =
    !isReferralsV2Active ||
    (isReferralsV2Active && withStatisticsValues({ isLoadingStatistics, statistics }));

  return (
    <React.Fragment>
      <Helmet>
        <title>Share Monarch</title>
      </Helmet>
      <Column md={9}>
        {!isReferralsV2Active &&
          RA.isNotNil(paymentSource) &&
          paymentSource !== SubscriptionDetailsPaymentSource.STRIPE && (
            <PaymentSourceMessage>
              <Line>
                You&apos;re being billed through the{' '}
                <Text weight="medium">{PAYMENT_SOURCE_TO_LABEL[paymentSource]}</Text>, which
                doesn&apos;t allow for referral rewards.
              </Line>
              <Line>
                Please switch to our online billing by following the instructions{' '}
                <Link
                  href={HELP_CENTER_SWITCH_BILLING_ARTICLE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </Link>{' '}
                to earn referral rewards.
              </Line>
            </PaymentSourceMessage>
          )}
        {!isReferralsV2Active && (
          <Card
            title={
              isReferralPromoCopyChangesActive
                ? 'Extra rewards for sharing Monarch'
                : 'Earn rewards for sharing Monarch'
            }
            loading={isLoadingConstants}
          >
            <Content>
              {isReferralPromoCopyChangesActive ? (
                <CardInnerContent>
                  <Text>
                    Have friends or family who would love Monarch? Now through July 10th, we&apos;re
                    sweetening the deal for both you and them.{' '}
                  </Text>
                  <List>
                    {activeReferralCampaign?.creditsEarned && (
                      <li>
                        You&apos;ll get ${activeReferralCampaign.creditsEarned.toFixed()} in credit
                        for every friend you refer to Monarch, once they become an annual
                        subscriber.{' '}
                        <Text weight="medium">That&apos;s a year free for every 3 friends.</Text>
                      </li>
                    )}
                    <li>
                      The people you invite get an{' '}
                      <Text weight="medium">
                        extended 30-day trial, plus 50% off an annual subscription.
                      </Text>
                    </li>
                  </List>
                  <Text>
                    Visit this{' '}
                    <Link
                      href={REFERRALS.JUNE_2024_DISCOUNT.HELP_CENTER_ARTICLE_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      help center article
                    </Link>{' '}
                    for more detail.
                  </Text>
                </CardInnerContent>
              ) : (
                <List>
                  <li>
                    Get{' '}
                    <Text weight="medium">{formatCurrency(monthlyPriceDollars)} in credits</Text>{' '}
                    for every subscriber you refer to Monarch, after they pay for their first month
                  </li>
                  <li>
                    The people you invite get an{' '}
                    <Text weight="medium">extended {referralsTrialDurationDays}-day trial</Text>
                  </li>
                  <li>Share your unique link anywhere!</li>
                </List>
              )}
            </Content>
          </Card>
        )}
        <Card
          title={isReferralsV2Active ? undefined : 'Share your referral link'}
          loading={isLoading}
        >
          {isReferralsV2Active ? (
            <ShareLinkSectionV2
              referralUrl={referralUrl}
              isSidebarExpanded={isSidebarExpanded}
              isReferralsV2Active={isReferralsV2Active}
            />
          ) : (
            <ShareLinkSection
              referralUrl={referralUrl}
              isSidebarExpanded={isSidebarExpanded}
              isReferralsV2Active={isReferralsV2Active}
            />
          )}
        </Card>
        {isReferralsV2Active && (
          <Card title="Referrals" loading={isLoading}>
            <StyledReferralEarnings />
          </Card>
        )}
        {isReferralsV2Active && (
          <Card title="How it works" loading={isLoading}>
            <StyledProcessSection referralUrl={referralUrl} isSidebarExpanded={isSidebarExpanded} />
          </Card>
        )}
        {withLegacyReferralsSection && (
          <>
            {isReferralsV2Active && (
              <DividerContainer>
                <Divider />
              </DividerContainer>
            )}
            <Card
              title={isReferralsV2Active ? 'Referral credits' : 'Referrals'}
              loading={isLoadingStatistics}
            >
              {RA.isNotNil(statistics) && (
                <ReferralTracker
                  statistics={statistics}
                  isReferralsV2Active={isReferralsV2Active}
                />
              )}
            </Card>
          </>
        )}
      </Column>
    </React.Fragment>
  );
};

const REFERRAL_SETTINGS_QUERY = gql(/* GraphQL */ `
  query Web_GetReferralSettings {
    referralStatistics {
      creditsEarned
      subscribed
      signedUp
    }
  }
`);

export default ReferralSettings;
