import React from 'react';
import Helmet from 'react-helmet';

// Items to go in the <head> that can potentially be overridden by react-helmet
// somewhere else in the app. Example: onboarding layout which uses a white theme.
// Include theme here rather than index.html so that react-helmet can swap between
// them at the appropriate times.
export const DynamicHead = () => (
  <Helmet>
    <meta name="theme-color" content="#F6F5F3" media="(prefers-color-scheme: light)" />
    <meta name="theme-color" content="#191918" media="(prefers-color-scheme: dark)" />
  </Helmet>
);

export default DynamicHead;
