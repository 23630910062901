import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';
import { ACTIVE_CLASS_NAME } from 'components/lib/ui/link/NavLink';
import RouteLink from 'components/lib/ui/link/RouteLink';
import ShareLinkSection from 'components/settings/referrals/ShareLinkSection';
import NavBarLink from 'components/sidebar/NavBarLink';

import useSubscriptionDetails from 'common/lib/hooks/billing/useSubscriptionDetails';
import { formatReferralCode } from 'common/lib/referral';
import { color, fontWeight, spacing } from 'common/lib/theme/dynamic';
import { track } from 'lib/analytics/segment';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import useModal from 'lib/hooks/useModal';

import { ReferralsEventNames } from 'common/constants/analytics';
import { REFERRALS } from 'common/constants/copy';
import routes from 'constants/routes';

const YellowLink = styled(NavBarLink)`
  color: ${color.orange10};
  font-weight: ${fontWeight.medium};

  &.${ACTIVE_CLASS_NAME}, &:hover {
    color: ${color.orange10};
  }
`;

const ModalContent = styled(FlexContainer).attrs({ column: true })`
  padding: 0 ${spacing.xlarge} ${spacing.xxxlarge};
  gap: ${spacing.xxlarge};
  text-align: center;
`;

const StyledShareLinkSection = styled(ShareLinkSection)`
  gap: ${spacing.xxlarge};
`;

/**
 * This is separated out into its own component to ensure we only log an
 * impression for the feature flag when the user actually sees the button.
 */
const ReferralLinkButton = () => {
  const isReferralPromoCopyChangesActive = useIsFeatureFlagOn('referral-promo-test-copy-changes');
  const isReferralsV2Active = useIsFeatureFlagOn('referrals-v2');
  const location = useLocation();
  const { referralCode } = useSubscriptionDetails();
  const referralUrl = useMemo(() => formatReferralCode(referralCode ?? ''), [referralCode]);
  const [ReferralModal, { open: openReferralModal, close: closeReferralModal }] = useModal();
  const onClickReferralButton = useCallback(() => {
    openReferralModal();
    track(ReferralsEventNames.ButtonClicked);
  }, [openReferralModal]);
  const referralsRoute = routes.settings.referrals();

  if (isReferralsV2Active) {
    const linkActionProps =
      location.pathname === routes.settings.referrals()
        ? { to: referralsRoute }
        : { onClick: onClickReferralButton };

    return (
      <>
        <YellowLink iconName="gift" {...linkActionProps}>
          {REFERRALS.NAVIGATION_CALLOUT}
        </YellowLink>

        <ReferralModal>
          <ModalCard hideBottomBorder>
            <ModalContent>
              <StyledShareLinkSection
                referralUrl={referralUrl}
                isCentered
                isReferralsV2Active={isReferralsV2Active}
              />
              <FlexContainer column gap="default" alignCenter>
                <RouteLink onClick={closeReferralModal} to={referralsRoute}>
                  Learn more
                </RouteLink>
                <RouteLink onClick={closeReferralModal} to={referralsRoute}>
                  Track your referrals
                </RouteLink>
              </FlexContainer>
            </ModalContent>
          </ModalCard>
        </ReferralModal>
      </>
    );
  }

  return (
    <YellowLink
      to={referralsRoute}
      onClick={() => track(ReferralsEventNames.ButtonClicked)}
      iconName="gift"
    >
      {isReferralPromoCopyChangesActive ? 'Summer Referral Bonus' : 'Get 1 Month Free'}
    </YellowLink>
  );
};

export default ReferralLinkButton;
