import { equals, filter, isEmpty, mergeRight, omit } from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { setReportsFilters } from 'actions';
import { track } from 'lib/analytics/segment';
import { getQueryParamsFromFilters, isActiveFilter } from 'lib/hooks/transactions/useFilters';
import useQueryParamFiltersWithFalsyValues from 'lib/hooks/transactions/useQueryParamFiltersWithFalsyValues';
import useDispatch from 'lib/hooks/useDispatch';
import useSelectors from 'lib/hooks/useSelectors';
import { correctDatesForFilters } from 'lib/transactions/Filters';
import {
  selectReportsFilters,
  selectReportsGroupBy,
  selectReportsGroupByTimeframe,
  selectReportsSortBy,
  selectDisplayPropertiesForTab,
  selectReportView,
} from 'state/reports/selectors';
import type { ReportsTab } from 'state/reports/types';
import { ReportsChart } from 'state/reports/types';
import type { RootState } from 'state/types';

import { ReportsEventNames } from 'common/constants/analytics';
import routes from 'constants/routes';

import type { TransactionFilters } from 'types/filters';

export type Props = {
  currentTab: ReportsTab;
};

export const useReportsParameters = ({ currentTab }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [handledInitialUrlChange, setHandledInitialUrlChange] = useState<boolean>(false);
  const [showAllEntities, setShowAllEntities] = useState(false);

  const queryParamFilters = useQueryParamFiltersWithFalsyValues();

  const [filters, groupBy, groupByTimeframe, sortTransactionsBy] = useSelectors([
    selectReportsFilters,
    selectReportsGroupBy,
    selectReportsGroupByTimeframe,
    selectReportsSortBy,
  ]);
  const [{ chartType, groupMode, viewMode }, reportView] = useSelectors([
    (state: RootState) => selectDisplayPropertiesForTab(state, currentTab),
    (state: RootState) => selectReportView(state, currentTab),
  ]);

  const [quickViewFilters, setQuickViewFilters] = useState<Partial<TransactionFilters>>({});
  const [selectedDatumId, setSelectedDatumId] = useState<Maybe<string>>(null);
  const [selectedDateRange, setSelectedDateRange] =
    useState<Maybe<{ startDate: string; endDate: string }>>(null);

  const allFilters = useMemo(() => {
    const mergedFilters = mergeRight(filters, quickViewFilters);
    return correctDatesForFilters(mergedFilters);
  }, [filters, quickViewFilters]);

  const hasQuickViewFilters = useMemo(
    () => Object.keys(quickViewFilters).length > 0,
    [quickViewFilters],
  );

  const analyticsMetadata = useMemo(
    () => ({
      chartType,
      viewMode: ['spending', 'income'].includes(currentTab) ? viewMode : undefined,
      grouping: groupMode ?? groupBy,
      timeframe: [
        ReportsChart.CashFlowChart,
        ReportsChart.StackedCashFlowChart,
        ReportsChart.BarChart,
        ReportsChart.StackedBarChart,
      ].includes(chartType)
        ? groupByTimeframe
        : undefined,
    }),
    [chartType, currentTab, viewMode, groupMode, groupBy, groupByTimeframe],
  );

  const removeQuickViewFilters = useCallback(
    (filtersToRemove?: Partial<TransactionFilters>, userInitiatedAction = true) => {
      if (filtersToRemove) {
        // This logic currently clobbers the entire filter provided; i.e., if a single category is
        // requested for removal (and is passed as ['sample-id'] to the categories sub-field), all
        // categories are actually removed, not just the one requested. This is acceptable for now
        // because, technically, only a single quick view filter should ever be applied at a time,
        // even though there is support for multiple. If a use case arises where multiple quick
        // view filters can actually be applied at once, this logic will need to be revisited.
        setQuickViewFilters(omit(Object.keys(filtersToRemove), quickViewFilters));
      } else {
        setQuickViewFilters({});
      }

      if (userInitiatedAction) {
        track(ReportsEventNames.ReportsChartDataSelectionReset, analyticsMetadata);
      }
    },
    [analyticsMetadata, quickViewFilters],
  );

  useEffect(() => {
    // Reset every time the tab or filters change
    setShowAllEntities(false);
  }, [currentTab, filters]);

  useEffect(() => {
    // Only track this event for changes to the reports configuration (except the tab). Tab changes
    // are already tracked as a page level event, and filters changes are captured elsewhere.
    track(ReportsEventNames.ReportsViewChanged, analyticsMetadata);
  }, [chartType, viewMode, groupMode, groupBy, groupByTimeframe, analyticsMetadata]);

  useEffect(() => {
    // Reset the quick-view filters every time top-level changes occur. Changes to the quick-view
    // filters themselves should not trigger this, as it would mean *any change is destructive.
    removeQuickViewFilters(undefined, false);
  }, [currentTab, filters, chartType, viewMode, groupMode, groupBy, groupByTimeframe]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!hasQuickViewFilters) {
      setSelectedDatumId(null);
      setSelectedDateRange(null);
    }
  }, [hasQuickViewFilters]);

  // First time loading the page we use the query params provided by the URL and also if the history
  // location changes, then we check if filters need to be updated.
  useEffect(() => {
    // These are the current filters according to the store.
    const filtersQueryParams = getQueryParamsFromFilters(filters);

    // These are the new filters we'd like to potentially change to.
    const queryParamsFromQueryParamFilters = getQueryParamsFromFilters(queryParamFilters);

    // If the query params are equal, we don't need to update the filters.
    if (equals(filtersQueryParams, queryParamsFromQueryParamFilters)) {
      return;
    }

    // When the new filters are empty, we default to what's in the store. For example, if the user
    // navigates away from the Reports page and then returns, we want to maintain the filters they
    // had previously selected.
    if (isEmpty(queryParamsFromQueryParamFilters)) {
      dispatch(setReportsFilters({ filters }));
      return;
    }

    const filteredQueryParams = filter((value) => isActiveFilter(value), queryParamFilters);
    dispatch(setReportsFilters({ filters: filteredQueryParams }));
  }, [history.location]);

  // This will update the query params to match the filters state.
  useEffect(() => {
    // The initial url change should be handled by the effect above, but it can lead to inconsistent
    // state on first load if this effect is also run (since the filters "change" then, too). As a
    // result, we only run this effect when the filters change afterwards (i.e., a manual user
    // action like selecting a saved report or applying new filters).
    if (!handledInitialUrlChange) {
      setHandledInitialUrlChange(true);
      return;
    }

    // These are the current filters according to the url.
    const queryParamsFromQueryParamFilters = getQueryParamsFromFilters(queryParamFilters);

    // These are the new filters we'd like to potentially change the url to.
    const filtersQueryParams = getQueryParamsFromFilters(filters);

    // We don't need to change the url if the filters are the same.
    if (equals(filtersQueryParams, queryParamsFromQueryParamFilters)) {
      return;
    }

    // If there are any filters, we want to update the url to match the upcoming filters state.
    const url = routes.reports[currentTab](
      !isEmpty(filtersQueryParams) ? { queryParams: filtersQueryParams } : {},
    );

    // This pushState call only updates the URL without actually reloading the page.
    // This is needed to prevent infinite loop from syncing filters/query params
    window.history.pushState(
      {
        path: url,
      },
      '',
      url,
    );
  }, [filters]);

  return {
    showAllEntities,
    setShowAllEntities,
    reportView,
    filters,
    allFilters,
    groupBy,
    groupByTimeframe,
    sortTransactionsBy,
    chartType,
    groupMode,
    viewMode,
    quickViewFilters,
    setQuickViewFilters,
    selectedDatumId,
    setSelectedDatumId,
    selectedDateRange,
    setSelectedDateRange,
    hasQuickViewFilters,
    removeQuickViewFilters,
    analyticsMetadata,
  };
};
