import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Page from 'components/lib/ui/Page';
import SetupHeader from 'components/lib/ui/SetupHeader';

import boxShadow from 'common/lib/styles/boxShadow';
import { spacing, variables } from 'common/lib/theme/dynamic';

import routes from 'constants/routes';

const StyledPage = styled(Page)`
  height: 100%;
  background-color: ${variables.color.background.fullscreenHeader};
`;

export const TransferAccountDataContentContainer = styled(FlexContainer).attrs({ column: true })`
  max-width: 600px;
  width: 100%;
  flex-grow: 1;
`;

const FooterContainer = styled(FlexContainer).attrs({ column: true })`
  width: 100%;
  padding: ${spacing.large};
  position: sticky;
  bottom: 0;
  background-color: ${variables.color.background.primary};

  ${boxShadow.mediumTop}
`;

type Props = {
  children: React.ReactNode;
  footer?: React.ReactNode;
  progress: number;
  onClickBack?: () => void;
  isLoading?: boolean;
  showBackButton?: boolean;
  overrideTitle?: string;
  exitAccountId?: string | null;
  useContainer?: boolean;
};

const TransferAccountDataStep = ({
  children,
  footer,
  isLoading,
  useContainer = true,
  ...props
}: Props) => {
  const { push, goBack } = useHistory();

  return (
    <StyledPage
      name="Transfer Account Data"
      overrideTitle={props.overrideTitle}
      header={
        <SetupHeader
          title="Transfer Account Data"
          cancelButtonText="Exit"
          progress={props.progress}
          onClickBack={props.onClickBack ?? goBack}
          onClickCancel={
            props.exitAccountId
              ? () => push(routes.accounts.accountDetails({ id: props.exitAccountId as string }))
              : () => push(routes.accounts())
          }
          showBackButton={props.showBackButton}
        />
      }
    >
      {isLoading ? (
        <FlexContainer center column full>
          <LoadingSpinner />
        </FlexContainer>
      ) : (
        <FlexContainer alignCenter column full>
          {useContainer ? (
            <TransferAccountDataContentContainer>{children}</TransferAccountDataContentContainer>
          ) : (
            children
          )}
          {footer && <TransferAccountDataFooter>{footer}</TransferAccountDataFooter>}
        </FlexContainer>
      )}
    </StyledPage>
  );
};

export const TransferAccountDataFooter = ({ children }: { children: React.ReactNode }) => (
  <FooterContainer alignCenter>
    <TransferAccountDataContentContainer>{children}</TransferAccountDataContentContainer>
  </FooterContainer>
);

export default TransferAccountDataStep;
