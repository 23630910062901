import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import CategorySelect from 'components/categories/CategorySelect';
import CurrencyField from 'components/lib/form/CurrencyField';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import SelectField from 'components/lib/form/SelectField';
import CashFlowCurrency from 'components/lib/ui/currency/CashFlowCurrency';

import { getAvailableDisplayAmount } from 'common/lib/budget/Amounts';
import { getAmountsFromCalculableBudget, getAvailableAmount } from 'common/lib/budget/MoveMoney';
import type { FormValues, CalculableBudget } from 'common/lib/budget/MoveMoney';

import { CategoryGroupType } from 'common/generated/graphql';

const CategoryOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const CategoryLabel = styled.div`
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CategoryCurrency = styled(CashFlowCurrency)`
  flex-shrink: 0;
  margin-left: ${({ theme }) => theme.spacing.xsmall};
  margin-right: ${({ theme }) => theme.spacing.xxxsmall};
  min-width: 60px;

  .react-select__option--is-selected & {
    color: ${({ theme }) => theme.color.textWhite};
  }
`;

const CategoryAmount = styled.div`
  color: ${({ theme }) => theme.color.textLight};
`;

const SubmitButton = styled(FormSubmitButton)`
  width: auto;
  margin-left: auto;
  margin-top: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  categoryType: CategoryGroupType;
  budget: CalculableBudget | undefined;
  isPending: boolean;
  excludeCategories?: string[];
  isFlexBudgetSystem?: boolean;
};

type ItemProps = {
  value: string;
  label: string;
  isGroup: boolean;
};

const MoveMoneyInnerForm = ({
  budget,
  categoryType,
  isPending,
  excludeCategories,
  isFlexBudgetSystem,
}: Props) => {
  const { getFieldMeta, setFieldValue, values } = useFormContext<FormValues>();
  const getBudgetAmounts = useCallback(
    (id: string, isGroup: boolean) => budget && getAmountsFromCalculableBudget(budget, id, isGroup),
    [budget],
  );

  const renderOption = ({ value, label, isGroup }: ItemProps, isSelectedOption = false) => {
    const { available } = getBudgetAmounts(value, isGroup ?? false) ?? {};
    const categoryLabel = isGroup && !isSelectedOption ? `All categories` : label;

    return (
      <CategoryOption>
        <CategoryLabel>{categoryLabel}</CategoryLabel>
        <CategoryAmount>
          <CategoryCurrency
            value={getAvailableDisplayAmount(available, categoryType)}
            type="income"
            round
            emphasis
          >
            {' '}
            left
          </CategoryCurrency>
        </CategoryAmount>
      </CategoryOption>
    );
  };

  const handleCategoryChange = useCallback(
    (id: string) => {
      if (!budget) {
        return;
      }

      const fromCategoryId = getFieldMeta('fromCategoryId').value as string;
      const amount = getAvailableAmount(budget, categoryType, fromCategoryId, id);
      setFieldValue('amount', amount);
    },
    [budget, categoryType, setFieldValue, getFieldMeta],
  );

  const fromCategoryIdExcludedCategories = useMemo(() => {
    if (excludeCategories) {
      return [...excludeCategories, getFieldMeta('toCategoryId').value];
    }

    return [getFieldMeta('toCategoryId').value];
  }, [excludeCategories, getFieldMeta]);

  const toCategoryIdExcludedCategories = useMemo(() => {
    if (excludeCategories) {
      return [...excludeCategories, getFieldMeta('fromCategoryId').value];
    }

    return [getFieldMeta('fromCategoryId').value];
  }, [excludeCategories, getFieldMeta]);

  const extraOptions = useMemo(() => {
    if (categoryType === CategoryGroupType.EXPENSE && isFlexBudgetSystem) {
      return [
        {
          id: 'flexible',
          label: 'Flexible',
          options: [
            {
              value: 'flexible',
              label: 'Flexible',
              isGroup: true,
            },
          ],
        },
      ];
    }

    return [];
  }, [categoryType, isFlexBudgetSystem]);

  return (
    <>
      <SelectField
        name="fromCategoryId"
        label="From"
        InputComponent={CategorySelect}
        isCreatable={false}
        filters={{
          type: categoryType,
          excludeCategories: fromCategoryIdExcludedCategories,
        }}
        extraOptions={extraOptions}
        renderOption={renderOption}
        showPlaceholderWhenMenuOpen={false}
        onChange={handleCategoryChange}
        required
        small
        placeholder="Select a category or group"
        collapseGroupBasedOnBudgeting
      />
      <SelectField
        name="toCategoryId"
        label="To"
        InputComponent={CategorySelect}
        isCreatable={false}
        filters={{
          type: categoryType,
          excludeCategories: toCategoryIdExcludedCategories,
        }}
        extraOptions={extraOptions}
        renderOption={renderOption}
        showPlaceholderWhenMenuOpen={false}
        onChange={handleCategoryChange}
        required
        small
        placeholder="Select a category or group"
        collapseGroupBasedOnBudgeting
      />
      <CurrencyField name="amount" placeholder="$0" small required />
      <SubmitButton
        size="small"
        disabled={values.amount <= 0}
        disableWhenValuesUnchanged={false}
        pending={isPending}
      >
        Save
      </SubmitButton>
    </>
  );
};

export default MoveMoneyInnerForm;
