import { capitalCase } from 'change-case';
import { isNotNil } from 'ramda-adjunct';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import ToggleFieldWithText from 'components/lib/form/ToggleFieldWithText';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import { ToggleFieldWithTextRoot, ToggleFieldSubText } from 'components/lib/ui/ToggleWithText';

import { ENTITY_TO_LABEL } from 'common/lib/reports';
import { color, fontWeight, spacing } from 'common/lib/theme/dynamic';
import { decodeReportView, TIMEFRAME_TO_LABEL, VIEW_MODE_TO_LABEL } from 'lib/reports';
import { SANKEY_GROUP_MODE_TO_LABEL } from 'lib/reports/sankey';
import { ReportsChart } from 'state/reports/types';

import type { ReportConfiguration, ReportView } from 'common/generated/graphql';

const CHART_TYPE_TO_LABEL = {
  [ReportsChart.CashFlowChart]: 'Bar Chart',
  [ReportsChart.StackedCashFlowChart]: 'Bar Chart (Stacked)',
  [ReportsChart.SankeyCashFlowChart]: 'Sankey',
  [ReportsChart.PieChart]: 'Donut Chart',
  [ReportsChart.HorizontalBarChart]: 'Bar Chart',
  [ReportsChart.BarChart]: 'Bar Chart',
  [ReportsChart.StackedBarChart]: 'Bar Chart (Stacked)',
};

const ReportViewSectionContainer = styled(FlexContainer).attrs({
  gap: 'xsmall',
  column: true,
})`
  ${ToggleFieldWithTextRoot} {
    margin-bottom: 0;
  }

  ${ToggleFieldSubText} {
    max-width: unset;
  }
`;

const ReportViewSectionTitle = styled(Text).attrs({
  size: 'small',
})`
  font-weight: ${fontWeight.bold};
`;

const ReportViewSectionItemsContainer = styled(FlexContainer).attrs({
  justifyStart: true,
  gap: 'xsmall',
  wrap: true,
})``;

const ReportViewSectionItemContainer = styled(FlexContainer).attrs({
  justifyBetween: true,
  alignCenter: true,
  gap: 'xsmall',
})`
  background-color: ${color.grayBackground};
  padding: ${spacing.xxsmall} ${spacing.xsmall};
  border-radius: ${spacing.xxsmall};
`;

const ReportViewSectionItemText = styled(Text).attrs({
  size: 'small',
})``;

const ReportViewOverview = ({
  reportView,
  editingReportConfiguration,
}: {
  reportView: ReportView;
  editingReportConfiguration?: ReportConfiguration;
}) => {
  const resolvedReportView = useMemo(() => {
    if (editingReportConfiguration) {
      if (editingReportConfiguration.reportView) {
        return decodeReportView(editingReportConfiguration.reportView);
      }

      return undefined;
    }

    return decodeReportView(reportView);
  }, [editingReportConfiguration, reportView]);

  const displayedReportInformation = useMemo(() => {
    if (!resolvedReportView) {
      return undefined;
    }

    const {
      tab,
      chartType,
      calculationViewMode,
      sankeyGroupMode,
      groupByEntity,
      groupByTimeframe,
    } = resolvedReportView;

    const chartTypeLabel = chartType ? CHART_TYPE_TO_LABEL[chartType] : undefined;
    const sankeyGroupLabel = sankeyGroupMode
      ? SANKEY_GROUP_MODE_TO_LABEL[sankeyGroupMode]
      : undefined;
    const entityGroupLabel = groupByEntity ? `By ${ENTITY_TO_LABEL[groupByEntity]}` : undefined;
    const groupLabel =
      sankeyGroupLabel ?? (['spending', 'income'].includes(tab) ? entityGroupLabel : undefined);
    const timeframeLabel = groupByTimeframe ? TIMEFRAME_TO_LABEL[groupByTimeframe] : undefined;
    const viewModeLabel = calculationViewMode ? VIEW_MODE_TO_LABEL[calculationViewMode] : undefined;

    return [capitalCase(tab), chartTypeLabel, timeframeLabel, viewModeLabel, groupLabel].filter(
      Boolean,
    );
  }, [resolvedReportView]);

  const chartViewSection = (
    <ReportViewSectionItemsContainer>
      {displayedReportInformation?.map((information) => (
        <ReportViewSectionItemContainer key={information}>
          <ReportViewSectionItemText>{information}</ReportViewSectionItemText>
        </ReportViewSectionItemContainer>
      ))}
    </ReportViewSectionItemsContainer>
  );

  return displayedReportInformation ? (
    <ReportViewSectionContainer>
      {isNotNil(editingReportConfiguration) ? (
        <>
          <ReportViewSectionTitle>Chart view</ReportViewSectionTitle>
          {chartViewSection}
        </>
      ) : (
        <ToggleFieldWithText
          name="includeReportView"
          label="Include chart view"
          subtext={chartViewSection}
        />
      )}
    </ReportViewSectionContainer>
  ) : null;
};

export default ReportViewOverview;
