import { SplitClient, SplitFactory, SplitFactoryProvider } from '@splitsoftware/splitio-react';
import type { ISplitFactoryProviderProps } from '@splitsoftware/splitio-react/types/types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import uuid from 'uuid/v4';

import {
  getSegmentAnonymousIdFromQueryParamOrCookie,
  setSegmentAnonymousId,
} from 'lib/analytics/segment';
import getStringEnvVar from 'lib/getStringEnvVar';
import { getUser } from 'selectors';

const IS_DEVELOPMENT = getStringEnvVar('NODE_ENV') === 'development';
const SPLIT_FACTORY_CONFIG: ISplitFactoryProviderProps['config'] = {
  core: {
    authorizationKey: getStringEnvVar('REACT_APP_SPLIT_API_KEY'),
    // We need to pass a dummy key value just to initialize the factory.
    // https://help.split.io/hc/en-us/articles/360046771911-React-SDK-Lazy-initialization-of-Split-client
    key: 'UNKNOWN',
  },
  scheduler: {
    featuresRefreshRate: IS_DEVELOPMENT ? 5 : 30,
    impressionsRefreshRate: IS_DEVELOPMENT ? 5 : 30,
  },
  // @ts-ignore this works but isn't in the types...
  urls: {
    sdk: 'https://features.monarchmoney.com/sdk/api',
    auth: 'https://features.monarchmoney.com/auth/api',
  },
};

const splitFactory = SplitFactory(SPLIT_FACTORY_CONFIG);

type Props = { children: JSX.Element };

const MonarchSplitFactoryProvider = ({ children }: Props) => (
  <SplitFactoryProvider factory={splitFactory}>
    <SplitCustomClient>{children}</SplitCustomClient>
  </SplitFactoryProvider>
);

const SplitCustomClient = ({ children }: Props) => {
  const user = useSelector(getUser);
  const anonymousUuid = uuid();

  // loading the segment anonymous id from the cookie it doesn't have to wait until Segment loads.
  // Segment Anonymous ID is usually set when the user visits the website. Users visiting the signup/signin page is an exception
  const segmentAnonymousId = getSegmentAnonymousIdFromQueryParamOrCookie();

  // sets the Segment Anonymous ID if it's not set yet. The idea is to prevent the user gets multiple UUIDs and different treatments.
  if (!segmentAnonymousId) {
    setSegmentAnonymousId(anonymousUuid);
  }

  const userId = user.external_id ?? segmentAnonymousId ?? anonymousUuid;

  // This prevents the children from being re-rendered when the context value changes
  // (it was causing some issues with our GraphQL queries being re-executed on every render)
  const memoizedChildren = useMemo(() => children, [children]);

  return userId ? <SplitClient splitKey={userId}>{memoizedChildren}</SplitClient> : null;
};

export default MonarchSplitFactoryProvider;
