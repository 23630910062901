import { useSplitTreatments } from '@splitsoftware/splitio-react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { parseFlagsFromSplit } from 'common/lib/external/splitio';
import type { FeatureFlags } from 'common/lib/external/splitio';
import { GET_ANONYMOUS_ID } from 'common/lib/graphQl/user';
import useQuery from 'common/lib/hooks/useQuery';
import { getSegmentAnonymousIdFromQueryParamOrCookie, track } from 'lib/analytics/segment';
import { getSplitAttributes } from 'selectors';
import type { FeatureFlagName } from 'state/features/types';

import { AnalyticsEventNames } from 'common/constants/analytics';

type FeatureFlagsWithStatus<TConfig> = {
  flags: FeatureFlags<TConfig>;
  isReady: boolean;
  isReadyFromCache: boolean;
};

export const useFeatureFlagsWithStatus = <TConfig>(
  names: FeatureFlagName[],
  key: string | undefined = undefined,
): FeatureFlagsWithStatus<TConfig> => {
  const attrs = useSelector(getSplitAttributes);
  const { treatments, isReady, isReadyFromCache } = useSplitTreatments({
    names,
    attributes: attrs,
    splitKey: key,
  });

  return {
    flags: parseFlagsFromSplit(treatments),
    isReady,
    isReadyFromCache,
  };
};

// DEPRECATED: use useFeatureFlagsWithStatus instead
// This has been used in the useSupportedDataProviders hook only.
// We should refactor it in a short future.
export const useFeatureFlags = <TConfig>(
  names: FeatureFlagName[],
  key: string | undefined = undefined,
): FeatureFlags<TConfig> => {
  const attrs = useSelector(getSplitAttributes);

  const { treatments } = useSplitTreatments({
    names,
    attributes: attrs,
    splitKey: key,
  });

  return parseFlagsFromSplit(treatments);
};

export type Options = {
  trackImpression?: boolean;
  /** It uses the anonymousId as the key for the feature flag if it is an anonymous experiment */
  isAnonymous?: boolean;
};

const useFeatureFlag = <TConfig>(
  flagName: FeatureFlagName,
  { trackImpression, isAnonymous }: Options = {},
) => {
  const cookieAnonymousId = getSegmentAnonymousIdFromQueryParamOrCookie();

  const { data, isNetworkRequestInFlight } = useQuery(GET_ANONYMOUS_ID, {
    fetchPolicy: 'cache-first',
    skip: !isAnonymous || !!cookieAnonymousId, // request anonymous id only if it's an anonymous experiment
  });

  // use the anonymous id from the cookie if it exists, otherwise use the anonymous id from the query
  const anonymousId = cookieAnonymousId ?? data?.me?.attributionData?.anonymousId;

  // override the key used to retrieve the feature flag treatment with the anonymous id if the experiment is anonymous
  const key = isAnonymous && anonymousId ? anonymousId : undefined;

  const result = useFeatureFlagsWithStatus<TConfig>([flagName], key);
  const { flags, isReadyFromCache, isReady } = result;
  const { treatment } = flags[flagName];

  const isSplitReady = isReadyFromCache || isReady;

  useEffect(() => {
    if (isSplitReady && trackImpression && treatment && (anonymousId || !isAnonymous)) {
      track(AnalyticsEventNames.FeatureFlagImpression, { split: flagName, treatment });
    }
  }, [
    treatment,
    flagName,
    trackImpression,
    anonymousId,
    isAnonymous,
    isNetworkRequestInFlight,
    isSplitReady,
  ]);

  return flags[flagName];
};

export default useFeatureFlag;
