import { DateTime } from 'luxon';
import { useState } from 'react';

import useEventCallback from 'common/lib/hooks/useEventCallback';
import useProfileFlag from 'common/lib/hooks/users/useProfileFlag';

export const useTransferAccountDataWalkthrough = () => {
  const [isWalkthroughOpen, setIsWalkthroughOpen] = useState(false);
  const [dismissedWalkthroughAt, setDismissedWalkthroughAt, isLoadingDismissedWalkthroughAt] =
    useProfileFlag('dismissedTransferAccountDataWalkthroughAt');

  const shouldShowWalkthrough = !isLoadingDismissedWalkthroughAt && !dismissedWalkthroughAt;
  const dismissWalkthrough = useEventCallback(() => {
    setDismissedWalkthroughAt(DateTime.now().toISO());
  });

  return [
    isWalkthroughOpen,
    setIsWalkthroughOpen,
    shouldShowWalkthrough,
    dismissWalkthrough,
  ] as const;
};
