import type {
  Web_GetAccountsForTransferQuery,
  Web_PreviewAccountDataTransferTransactionsQuery,
} from 'common/generated/graphql';
import type { ElementOf } from 'common/types/utility';

export type Transaction = ElementOf<
  Web_PreviewAccountDataTransferTransactionsQuery['previewAccountDataTransferTransactions']['fromAccountSummary'],
  'transactionsInWindow'
>;

export type DateWithTransactions = {
  date: string;
  transactions: Transaction[];
  numTransactionsForDate: number;
};

export type TransactionListProps = {
  startDate: string;
  transitionDate: string;
  datesWithTransactions: DateWithTransactions[];
  includeItemsAfterTransitionDate?: boolean;
  transitionDateElementRef?: React.RefObject<HTMLElement>;
  numNewerTransactions?: number;
  numOlderTransactions?: number;
  lastTransactionDate?: string | null;
};

export type TransactionsPreviewProps = {
  isLoading: boolean;
  startDate: string;
  transitionDate: string;
  fromAccount: Web_GetAccountsForTransferQuery['fromAccount'];
  toAccount: Web_GetAccountsForTransferQuery['toAccount'];
  fromAccountSummary?: Web_PreviewAccountDataTransferTransactionsQuery['previewAccountDataTransferTransactions']['fromAccountSummary'];
  toAccountSummary?: Web_PreviewAccountDataTransferTransactionsQuery['previewAccountDataTransferTransactions']['toAccountSummary'];
  previewDays: number;
  lastTransactionDate?: string | null;
};

export enum TransactionStatus {
  Included = 'included',
  Excluded = 'excluded',
  Default = 'default',
}
