import type { CardElementProps } from '@stripe/react-stripe-js';
import { CardElement } from '@stripe/react-stripe-js';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { color } from 'common/lib/theme/dynamic';
import useTheme from 'lib/hooks/useTheme';
import fieldErrorBorderMixin from 'lib/styles/fieldErrorBorderMixin';
import fieldStyleMixin, { fieldStyleFocus, fieldStyleHover } from 'lib/styles/fieldStyleMixin';

const StripeInput = styled(CardElement)`
  ${fieldStyleMixin};

  &.StripeElement--focus {
    ${({ theme }) => fieldStyleFocus({ theme })};
  }
  &.StripeElement--invalid {
    ${({ theme }) => fieldErrorBorderMixin({ theme, error: true })};
  }

  &:hover:not(:disabled) {
    ${({ theme }) => fieldStyleHover({ theme })};
  }
  &.StripeElement--focus:hover:not(:disabled) {
    border-color: ${color.blue};
  }
`;

type Props = Omit<CardElementProps, 'options'>;

/**
 * Wrapper around stripe CardElement to provide custom styling
 */
const StripeCardInput = (props: Props) => {
  const theme = useTheme();

  const options = useMemo(
    () => ({
      style: {
        base: {
          color: theme.color.text,
          fontSize: theme.fontSize.base,
          fontFamily: theme.fontFamily.primaryStyle,
          borderRadius: theme.radius.small,
          '::placeholder': {
            color: theme.color.textLight,
          },
          fontSmoothing: 'antialiased',
        },
        invalid: {
          color: theme.color.red,
        },
      },
    }),
    [theme],
  );

  return <StripeInput {...props} options={options} />;
};

export default StripeCardInput;
