import { DateTime } from 'luxon';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { DateSection } from 'components/accounts/transferAccountData/DateSection';
import { SingleDayTransactions } from 'components/accounts/transferAccountData/SingleDayTransactions';

import { spacing, variables } from 'common/lib/theme/dynamic';
import staticTheme from 'common/lib/theme/staticTheme';
import { isoDateToAbbreviatedMonthDayAndYear } from 'common/utils/date';
import { TRANSACTION_ROW_HEIGHT_PX } from 'lib/accounts/transferAccountData/constants';
import type { TransactionListProps } from 'lib/accounts/transferAccountData/types';
import { TransactionStatus } from 'lib/accounts/transferAccountData/types';

const DescriptionList = styled.dl`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

const TransactionSummaryRow = styled.div<{ $type: TransactionStatus; $rowCount?: number }>`
  list-style: none;
  padding: ${spacing.xsmall} ${spacing.default};
  font-size: ${staticTheme.fontSize.small};
  height: ${({ $rowCount = 1 }) => $rowCount * TRANSACTION_ROW_HEIGHT_PX}px;
  min-height: ${TRANSACTION_ROW_HEIGHT_PX}px;
  display: flex;
  align-items: center;
  background-color: ${({ $type, theme }) => {
    switch ($type) {
      case TransactionStatus.Included:
        return theme.color.green1;
      case TransactionStatus.Excluded:
        return theme.color.red1;
      default:
        return 'transparent';
    }
  }};
  color: ${variables.color.content.primary};
  opacity: ${({ $type }) => ($type === TransactionStatus.Default ? 0.5 : 1)};
`;

export const GroupedTransactionTimeline: React.FC<TransactionListProps> = ({
  startDate,
  transitionDate,
  datesWithTransactions,
  includeItemsAfterTransitionDate = true,
  transitionDateElementRef,
  numNewerTransactions,
  numOlderTransactions,
  lastTransactionDate,
}) => {
  const getType = useCallback(
    (date: string) => {
      const afterTransitionDate = DateTime.fromISO(date) > DateTime.fromISO(transitionDate);

      if (includeItemsAfterTransitionDate) {
        return afterTransitionDate ? TransactionStatus.Included : TransactionStatus.Excluded;
      }
      return afterTransitionDate ? TransactionStatus.Default : TransactionStatus.Included;
    },
    [includeItemsAfterTransitionDate, transitionDate],
  );

  const nextDay = useMemo(() => {
    if (!datesWithTransactions.length) {
      return '';
    }
    return DateTime.fromISO(datesWithTransactions[0].date).plus({ days: 1 }).toISODate();
  }, [datesWithTransactions]);

  const previousDay = useMemo(() => {
    if (!datesWithTransactions.length) {
      return '';
    }
    return DateTime.fromISO(datesWithTransactions[datesWithTransactions.length - 1].date)
      .minus({ days: 1 })
      .toISODate();
  }, [datesWithTransactions]);

  const today = DateTime.local().startOf('day');
  const showNextRange = DateTime.fromISO(nextDay) < today;
  const showPreviousRange = DateTime.fromISO(previousDay) > DateTime.fromISO(startDate);

  const getSubtitle = (isToday: boolean, isLastTransactionDate: boolean) => {
    if (isToday) {
      return '(Today)';
    }
    if (isLastTransactionDate) {
      return '(Last transaction)';
    }
    return undefined;
  };

  return (
    <DescriptionList>
      <DateSection
        key={nextDay}
        title={
          showNextRange
            ? `Today–${isoDateToAbbreviatedMonthDayAndYear(nextDay)}`
            : isoDateToAbbreviatedMonthDayAndYear(nextDay)
        }
        type={
          includeItemsAfterTransitionDate ? TransactionStatus.Included : TransactionStatus.Default
        }
      >
        <TransactionSummaryRow
          $type={
            includeItemsAfterTransitionDate ? TransactionStatus.Included : TransactionStatus.Default
          }
        >
          {(numNewerTransactions ?? 0) > 0
            ? `${numNewerTransactions} newer transactions`
            : '0 transactions'}
        </TransactionSummaryRow>
      </DateSection>
      {datesWithTransactions.map(({ date, transactions, numTransactionsForDate }) => {
        const type = getType(date);
        const isToday = DateTime.fromISO(date).hasSame(today, 'day');
        const isLastTransactionDate = Boolean(lastTransactionDate && date === lastTransactionDate);
        return (
          <DateSection
            key={date}
            ref={date === transitionDate ? transitionDateElementRef : undefined}
            title={isoDateToAbbreviatedMonthDayAndYear(date)}
            subtitle={getSubtitle(isToday, isLastTransactionDate)}
            type={type}
          >
            {transactions.length === 0 ? (
              <TransactionSummaryRow $type={type} $rowCount={numTransactionsForDate}>
                0 transactions
              </TransactionSummaryRow>
            ) : (
              <SingleDayTransactions
                transactions={transactions}
                emptyRowCount={numTransactionsForDate - transactions.length}
                type={type}
              />
            )}
          </DateSection>
        );
      })}
      <DateSection
        key={previousDay}
        title={
          showPreviousRange
            ? `${isoDateToAbbreviatedMonthDayAndYear(previousDay)}–${isoDateToAbbreviatedMonthDayAndYear(
                startDate,
              )}`
            : isoDateToAbbreviatedMonthDayAndYear(previousDay)
        }
        type={
          includeItemsAfterTransitionDate ? TransactionStatus.Excluded : TransactionStatus.Included
        }
      >
        <TransactionSummaryRow
          $type={
            includeItemsAfterTransitionDate
              ? TransactionStatus.Excluded
              : TransactionStatus.Included
          }
        >
          {(numOlderTransactions ?? 0) > 0
            ? `${numOlderTransactions} older transactions`
            : '0 transactions'}
        </TransactionSummaryRow>
      </DateSection>
    </DescriptionList>
  );
};
