import * as R from 'ramda';

import type { Maybe } from 'common/generated/graphql';
import type { MaybeList, Identifiable } from 'common/types/utility';

/**
 * Merges the next page of a relay-style edges/node response
 */
export const mergeNextRelayPage =
  <T extends { node: Maybe<{ id: string }> }, P extends { edges: MaybeList<T> }>(
    next: P | null | undefined,
  ) =>
  (previous: P | null) =>
    ({
      ...(previous ?? {}),
      ...(next ?? {}),
      edges: R.uniqBy(
        (edge) => edge?.node?.id,
        [...(previous?.edges ?? []), ...(next?.edges ?? [])],
      ),
    }) as P;

/** Merge next page of items, removing duplicates by ID */
export const mergeNextPage =
  <ItemT extends Identifiable>(
    next: Maybe<ItemT[]>,
    idGetter?: (item: ItemT) => Identifiable['id'],
  ) =>
  (previous: Maybe<ItemT[]>): ItemT[] => {
    const nextItems = next ?? [];
    const previousItems = previous ?? [];
    const mergedItems = [...previousItems, ...nextItems];
    return R.uniqBy(idGetter ?? R.prop('id'), mergedItems);
  };

/** Use a custom ID getter to merge next pages, removing duplicates */
export const customMergeNextPage =
  <ItemT>(next: Maybe<ItemT[]>, idGetter: (item: ItemT) => string) =>
  (previous: Maybe<ItemT[]>): ItemT[] => {
    const nextItems = next ?? [];
    const previousItems = previous ?? [];
    const mergedItems = [...previousItems, ...nextItems];
    return R.uniqBy(idGetter, mergedItems);
  };
