import styled from 'styled-components';

import { onboardingContentContainerMixin } from 'components/onboarding/style';

import { spacing } from 'common/lib/theme/dynamic';

const OnboardingFormContainer = styled.div`
  ${onboardingContentContainerMixin}
  margin-top: ${spacing.xxlarge};
  text-align: left;
  width: 100%;
`;

export default OnboardingFormContainer;
