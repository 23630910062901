import React from 'react';
import styled from 'styled-components';

import Icon from 'components/lib/ui/Icon';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import OnboardingFooterNav from 'components/onboarding/OnboardingFooterNav';
import OnboardingSubtitle from 'components/onboarding/OnboardingSubtitle';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';
import { usePreRegistration } from 'components/onboarding/preRegistration/PreRegistrationContext';
import { BetterUnderstandOption } from 'components/onboarding/preRegistration/constants';

import { color, fontFamily, fontWeight, spacing } from 'common/lib/theme/dynamic';

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
`;

const StyledIcon = styled(Icon).attrs({
  size: 60,
})`
  color: ${({ theme }) => theme.color.grayLight};
`;

const StarContainer = styled.div`
  display: flex;
  gap: ${spacing.xxsmall};
`;

const StarIcon = styled(Icon).attrs({
  name: 'star-filled',
  size: 20,
})`
  color: ${({ theme }) => theme.color.yellow9};
`;

const AwardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xlarge};
  margin-top: ${spacing.xxlarge};
  width: 100%;
`;

const Awards = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  padding: 0 16px;
`;

const AwardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  text-align: center;
`;

const AwardTitle = styled.div`
  font-family: ${fontFamily.secondaryStyle};
  font-weight: ${fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.base};
  line-height: 167%;
`;

const AwardSubtitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  line-height: 142%;
  margin-top: ${spacing.xxxsmall};
`;

const StarAwardSubtitle = styled(AwardSubtitle)`
  margin-top: ${spacing.xsmall};
`;

const JoinOneMillion = styled(OnboardingSubtitle)`
  margin-top: ${({ theme }) => theme.spacing.small};
`;

const OneMillionHighlight = styled.span`
  font-weight: ${fontWeight.bold};
  color: ${color.gray12};
`;

type Props = {
  next: () => void;
};

const PreRegistrationAwards = ({ next }: Props) => {
  const { data } = usePreRegistration();

  const title =
    data.betterUnderstand === BetterUnderstandOption.YES
      ? "We're glad you found us"
      : 'You clearly have a great foundation.';

  const subtitle =
    data.betterUnderstand === BetterUnderstandOption.YES
      ? 'Monarch was designed to make the complexity of finances feel simple again.'
      : 'Monarch can help you optimize even further, saving you time and effort.';

  return (
    <>
      <OnboardingTitle>{title}</OnboardingTitle>
      <OnboardingSubtitle>{subtitle}</OnboardingSubtitle>
      <JoinOneMillion>
        Join over <OneMillionHighlight>1 million</OneMillionHighlight> households.
      </JoinOneMillion>
      <AwardsContainer>
        <Awards>
          <StyledIcon name="laurel-left" />
          <AwardContent>
            <AwardTitle>
              <StarContainer>
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </StarContainer>
            </AwardTitle>
            <StarAwardSubtitle>4.9 stars, 40,000+ reviews</StarAwardSubtitle>
          </AwardContent>
          <StyledIcon name="laurel-right" />
        </Awards>
        <Awards>
          <StyledIcon name="laurel-left" />
          <AwardContent>
            <AwardTitle>Best Budgeting App</AwardTitle>
            <AwardSubtitle>Wall Street Journal, 2025</AwardSubtitle>
          </AwardContent>
          <StyledIcon name="laurel-right" />
        </Awards>
        <Awards>
          <StyledIcon name="laurel-left" />
          <AwardContent>
            <AwardTitle>Best Mint Replacement</AwardTitle>
            <AwardSubtitle>Forbes, 2025</AwardSubtitle>
          </AwardContent>
          <StyledIcon name="laurel-right" />
        </Awards>
        <Awards>
          <StyledIcon name="laurel-left" />
          <AwardContent>
            <AwardTitle>Best App for Couples</AwardTitle>
            <AwardSubtitle>Motley Fool, 2024</AwardSubtitle>
          </AwardContent>
          <StyledIcon name="laurel-right" />
        </Awards>
      </AwardsContainer>
      <OnboardingFooterNav>
        <StyledPrimaryButton size="large" onClick={next}>
          Continue
        </StyledPrimaryButton>
      </OnboardingFooterNav>
    </>
  );
};

export default PreRegistrationAwards;
