import { transparentize } from 'polished';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import OnboardingFooterNav from 'components/onboarding/OnboardingFooterNav';
import OnboardingSubtitle from 'components/onboarding/OnboardingSubtitle';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';

import { spacing } from 'common/lib/theme/dynamic';
import { useFeatureFlag } from 'lib/hooks';

import * as COPY from 'common/constants/copy';

const SCREENSHOT_DESKTOP_DEVICE_URL =
  'https://monarch-static-assets.s3.amazonaws.com/onboarding/connect-account/flat-desktop-cash-flow.png';

const SCREENSHOT_MOBILE_DEVICE_URL =
  'https://monarch-static-assets.s3.amazonaws.com/onboarding/connect-account/flat-phone-cash-flow.png';

const Device = styled(FlexContainer)`
  position: relative;
  width: 100%;
  max-width: 672px;
  margin: auto;
  margin-top: ${spacing.xxlarge};
`;

const DeviceGradientOverlay = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 180px;
  background: linear-gradient(
    0deg,
    ${({ theme }) => theme.color.white} 80%,
    ${({ theme }) => transparentize(1, theme.color.white)} 100%
  );

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    background: linear-gradient(
      0deg,
      ${({ theme }) => theme.color.white} 50%,
      ${({ theme }) => transparentize(1, theme.color.white)} 100%
    );

    height: 120px;
  }
`;

const ContinueButton = styled(PrimaryButton)`
  width: 100%;
`;

const DeviceDesktopScreen = styled.img.attrs({
  src: SCREENSHOT_DESKTOP_DEVICE_URL,
})`
  width: 100%;
  height: auto;

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    display: none;
  }
`;

const DeviceMobileScreen = styled.img.attrs({
  src: SCREENSHOT_MOBILE_DEVICE_URL,
})`
  width: 100%;
  height: auto;
  display: none;

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    display: block;
  }
`;

type Props = {
  next: () => void;
  skipToComplete: () => void;
};

const OnboardingConnectAccountHypeScreen = ({ next, skipToComplete }: Props) => {
  const { treatment: abTestConnectSingleAccountBeforePaywall } = useFeatureFlag(
    'ab-test-connect-single-account-before-paywall',
    {
      trackImpression: true,
    },
  );

  /*
    skips the page when the ab-test-connect-accounts-before-paywall feature flag is on.
    This feature flag is used to let users connect accounts before the paywall. When that happens, we should skip this page.
  */
  useEffect(() => {
    if (abTestConnectSingleAccountBeforePaywall === 'on') {
      skipToComplete();
    }
  }, [abTestConnectSingleAccountBeforePaywall]);

  return (
    <>
      {abTestConnectSingleAccountBeforePaywall !== 'on' && (
        <>
          <OnboardingTitle>{COPY.ONBOARDING.CONNECT_ACCOUNT_HYPE.TITLE}</OnboardingTitle>
          <OnboardingSubtitle>{COPY.ONBOARDING.CONNECT_ACCOUNT_HYPE.SUBTITLE}</OnboardingSubtitle>

          <Device>
            <DeviceDesktopScreen />
            <DeviceMobileScreen />
            <DeviceGradientOverlay>
              <OnboardingFooterNav>
                <ContinueButton size="large" type="button" onClick={next}>
                  {COPY.ONBOARDING.SPENDING_INSIGHTS.BUTTON}
                </ContinueButton>
              </OnboardingFooterNav>
            </DeviceGradientOverlay>
          </Device>
        </>
      )}
    </>
  );
};

export default OnboardingConnectAccountHypeScreen;
