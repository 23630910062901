import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import ManualLink from 'components/lib/ui/link/ManualLink';
import { GiftCard } from 'components/settings/referrals/GiftCard';

import { breakPoints, radius, spacing, variables } from 'common/lib/theme/dynamic';

import { REFERRALS } from 'common/constants/copy';
import { MONARCH_REFERRAL_TERMS_URL } from 'common/constants/externalUrls';

type ProcessKeys = keyof typeof REFERRALS.PROCESS;
const processKeys: ProcessKeys[] = ['SHARE_STEP', 'DISCOUNT_STEP', 'EARNINGS_STEP'];
const processSteps = processKeys.map((key) => {
  const step = REFERRALS.PROCESS[key];
  return {
    title: step.TITLE,
    description: step.DESCRIPTION,
    descriptionLine2: 'DESCRIPTION_LINE_2' in step ? step.DESCRIPTION_LINE_2 : undefined,
    disclaimer: 'DISCLAIMER' in step ? step.DISCLAIMER : undefined,
  };
});

const Root = styled.div<{ isSidebarExpanded?: boolean }>`
  display: flex;
  gap: ${spacing.xxlarge};

  @media (max-width: ${({ isSidebarExpanded, theme }) =>
      isSidebarExpanded ? theme.breakPoints.lg : theme.breakPoints.sm}px) {
    flex-direction: column;
  }
`;

const Block = styled(FlexContainer)<{ isSidebarExpanded?: boolean }>`
  width: 50%;

  @media (max-width: ${({ isSidebarExpanded, theme }) =>
      isSidebarExpanded ? theme.breakPoints.lg : theme.breakPoints.sm}px) {
    width: 100%;
  }
`;

const ProcessBlock = styled(Block).attrs({ column: true })`
  gap: ${spacing.xxlarge};
`;

const ProcessStep = styled.div`
  display: flex;
  gap: ${spacing.default};
`;

const ProcessNumberDot = styled(FlexContainer).attrs({ alignCenter: true, justifyCenter: true })`
  width: 32px;
  height: 32px;
  background: ${variables.color.background.secondary};
  border-radius: ${radius.xlarge};
`;

const ProcessItem = styled(FlexContainer).attrs({ column: true })`
  flex: 1;
  /* Make sure the referralUrl can break lines when the container gets small */
  overflow: hidden;
`;

const ReferralUrl = styled(Text).attrs({
  size: 'xsmall',
  colorVariable: 'content.secondary',
})`
  margin-top: ${spacing.xsmall};
  overflow-wrap: break-word;
`;

const ProcessDescription = styled(Text).attrs({
  size: 'small',
  colorVariable: 'content.secondary',
})`
  margin-top: ${spacing.xxsmall};
`;

const ProcessDisclaimer = styled(Text).attrs({
  size: 'xsmall',
  colorVariable: 'content.secondary',
})`
  margin-top: ${spacing.xlarge};
`;

const ProcessTermsLink = styled(ManualLink)`
  color: ${variables.color.content.primary};

  &:hover {
    color: ${variables.color.content.secondary};
  }
`;

const GiftCardsBlock = styled(Block).attrs({ justifyCenter: true })`
  border-radius: ${radius.medium};
  background: ${variables.color.background.secondary};
  padding: ${spacing.xxlarge};
  min-height: 322px;
`;

const GiftCardsContainer = styled(FlexContainer).attrs({ alignCenter: true })`
  flex: 1;
  position: relative;
  max-width: 440px;
`;

const GiftCardContainer = styled(FlexContainer).attrs({ alignCenter: true })`
  position: absolute;
  width: 100%;
  max-width: 56%;
`;

const GiftCardLeftContainer = styled(GiftCardContainer)`
  left: 0;
  transform: translateY(-22%);

  @media (max-width: ${breakPoints.lg}px) {
    transform: translateY(-24%);
  }
`;

const GiftCardRightContainer = styled(GiftCardContainer)`
  right: 0;
  transform: translateY(22%);

  @media (max-width: ${breakPoints.lg}px) {
    transform: translateY(24%);
  }
`;

type Props = {
  className?: string;
  isSidebarExpanded?: boolean;
  referralUrl: string;
};

export const ProcessSection = ({ className, isSidebarExpanded, referralUrl }: Props) => (
  <Root className={className} isSidebarExpanded={isSidebarExpanded}>
    <ProcessBlock isSidebarExpanded={isSidebarExpanded}>
      {processSteps.map((step, index) => (
        <ProcessStep key={index}>
          <ProcessNumberDot>
            <Text weight="medium" colorVariable="content.secondary" size="small">
              {index + 1}
            </Text>
          </ProcessNumberDot>

          <ProcessItem>
            <Text weight="medium" colorVariable="content.primary">
              {step.title}
            </Text>

            <ProcessDescription>{step.description}</ProcessDescription>

            {index === 0 && <ReferralUrl>{referralUrl}</ReferralUrl>}

            {step.descriptionLine2 && (
              <ProcessDescription>{step.descriptionLine2}</ProcessDescription>
            )}

            {step.disclaimer && (
              <>
                <ProcessDisclaimer>{step.disclaimer}</ProcessDisclaimer>
                <Text size="xsmall" colorVariable="content.secondary">
                  View the full{' '}
                  <ProcessTermsLink
                    href={MONARCH_REFERRAL_TERMS_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms & conditions.
                  </ProcessTermsLink>
                </Text>
              </>
            )}
          </ProcessItem>
        </ProcessStep>
      ))}
    </ProcessBlock>

    <GiftCardsBlock isSidebarExpanded={isSidebarExpanded}>
      <GiftCardsContainer>
        <GiftCardRightContainer>
          <GiftCard title="$50.00" description="Gift card" isSidebarExpanded={isSidebarExpanded} />
        </GiftCardRightContainer>

        <GiftCardLeftContainer>
          <GiftCard
            title="50% off"
            description="Your first year"
            isSidebarExpanded={isSidebarExpanded}
          />
        </GiftCardLeftContainer>
      </GiftCardsContainer>
    </GiftCardsBlock>
  </Root>
);
