import * as R from 'ramda';
import React, { useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import styled from 'styled-components';

import ClientsListHeader from 'components/advisorPortal/ClientsListHeader';
import ClientsListLoading from 'components/advisorPortal/ClientsListLoading';
import ClientsListRow from 'components/advisorPortal/ClientsListRow';
import Card from 'components/lib/ui/Card';
import Empty, { Theme as EmptyTheme } from 'components/lib/ui/Empty';
import Link from 'components/lib/ui/Link';
import SearchBar from 'components/lib/ui/SearchBar';

import { SubscriptionSponsorshipInviteStatus } from 'common/lib/sponsorship';
import { spacing } from 'common/lib/theme/dynamic';

import {
  ADVISOR_FAQ_URL,
  ADVISOR_ONBOARDING_CALENDLY_URL,
  ADVISOR_ONBOARDING_VIDEO_URL,
} from 'common/constants/externalUrls';

import type { Web_GetAdvisorClientsPage_subscriptionSponsorshipInvites } from 'common/generated/graphQlTypes/Web_GetAdvisorClientsPage';
import { truthy } from 'common/types/utility';

const VirtuosoContainer = styled.div`
  height: calc(100vh - 580px);
  min-height: 300px;
`;

const StyledSearchBar = styled(SearchBar)`
  margin: ${spacing.xlarge};
  margin-top: 0;
`;

type Props = {
  sponsor?: { name?: string | null } | null;
  invites?: Web_GetAdvisorClientsPage_subscriptionSponsorshipInvites[];
  isLoading: boolean;
  demoUserId?: string;
};

const ClientsList = ({ sponsor, invites = [], isLoading, demoUserId }: Props) => {
  const [search, setSearch] = useState('');

  // TODO: @edufschmidt move filtering to backend
  const filteredInvites = useMemo(() => {
    let filteredInvites = invites.filter(
      ({ status }) =>
        status === SubscriptionSponsorshipInviteStatus.Pending ||
        status === SubscriptionSponsorshipInviteStatus.Redeemed,
    );

    if (search) {
      filteredInvites = filteredInvites.filter(({ sentToEmail, sponsorship }) => {
        const { household } = sponsorship ?? {};
        const { owner } = household ?? {};

        const searchFields = [sentToEmail, household?.name, owner?.name, owner?.email].filter(
          truthy,
        );

        // super basic case insensitive search. TODO: improve with fuzzy matching
        return searchFields.some((field) => field.toLowerCase().includes(search.toLowerCase()));
      });
    }

    return filteredInvites;
  }, [invites, search]);

  const isEmpty = !isLoading && R.isEmpty(filteredInvites) && !demoUserId;

  const redeemedInvites = R.filter(
    ({ status }) => status === SubscriptionSponsorshipInviteStatus.Redeemed,
    invites,
  );

  const showOnboarding = !isLoading && redeemedInvites?.length <= 1;

  const row = useMemo(
    () =>
      (
        i: number,
        {
          id,
          code,
          label,
          status,
          createdAt,
          sponsorship,
          sentToEmail,
        }: Web_GetAdvisorClientsPage_subscriptionSponsorshipInvites,
      ) => (
        <ClientsListRow
          key={id}
          id={id}
          code={code}
          label={label}
          sponsor={sponsor}
          createdAt={createdAt}
          inviteStatus={status}
          sponsorship={sponsorship}
          userId={sponsorship?.household?.owner?.id}
          sentToEmail={sentToEmail}
        />
      ),
    [],
  );

  return (
    <Card>
      {isLoading && <ClientsListLoading />}
      {isEmpty && (
        <Empty
          emptyTheme={EmptyTheme.NEW}
          title="No clients yet"
          subtitle="Invite clients to get started"
        />
      )}
      {!isLoading && !isEmpty && (
        <>
          <StyledSearchBar
            placeholder="Search by name or email"
            name="search-clients"
            value={search}
            onChange={setSearch}
          />
          <ClientsListHeader />
          <VirtuosoContainer>
            <Virtuoso
              data={filteredInvites}
              itemContent={row}
              components={{
                Footer: () =>
                  demoUserId ? (
                    <ClientsListRow
                      code="DEMO"
                      key="demo"
                      id="demo"
                      label="Demo User"
                      sponsor={sponsor}
                      inviteStatus="demo"
                      userId={demoUserId}
                    />
                  ) : null,
              }}
            />
          </VirtuosoContainer>
        </>
      )}

      {showOnboarding && (
        <Empty
          emptyTheme={EmptyTheme.NEW}
          title="Need help getting started?"
          subtitle={
            <>
              You can book an{' '}
              <Link target="_blank" href={ADVISOR_ONBOARDING_CALENDLY_URL}>
                onboarding session
              </Link>{' '}
              with our Customer Success team, watch our{' '}
              <Link target="_blank" href={ADVISOR_ONBOARDING_VIDEO_URL}>
                onboarding video
              </Link>
              , or read our{' '}
              <Link target="_blank" href={ADVISOR_FAQ_URL}>
                FAQ
              </Link>
              .
            </>
          }
        />
      )}
    </Card>
  );
};

export default ClientsList;
