import React from 'react';

import useTheme from 'lib/hooks/useTheme';

type Props = {
  color?: string;
  x?: string;
  y?: string;
};

export const ArrowUpExtension = ({ color, ...props }: Props) => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="90"
      viewBox="0 0 66 90"
      stroke={color ?? theme.color.white}
      fill="none"
      {...props}
    >
      <path
        d="M1.28204 86.1333C0.251097 86.5298 -0.263209 87.687 0.133309 88.718C0.529826 89.7489 1.68701 90.2632 2.71796 89.8667L1.28204 86.1333ZM54.6046 0.806171C53.9453 -0.0800307 52.6924 -0.263943 51.8062 0.395391L37.3647 11.1399C36.4785 11.7992 36.2946 13.0521 36.9539 13.9383C37.6132 14.8245 38.8661 15.0084 39.7523 14.3491L52.5892 4.79844L62.1399 17.6353C62.7992 18.5215 64.0521 18.7054 64.9383 18.0461C65.8245 17.3868 66.0084 16.1339 65.3491 15.2477L54.6046 0.806171ZM2.71796 89.8667C9.69156 87.1845 20.7264 78.9973 30.9981 64.7311C41.3105 50.4082 50.9323 29.857 54.9788 2.29047L51.0212 1.70953C47.0677 28.643 37.6895 48.5918 27.7519 62.3939C17.7736 76.2527 7.30844 83.8155 1.28204 86.1333L2.71796 89.8667Z"
        fill={color ?? theme.color.white}
      />
    </svg>
  );
};
