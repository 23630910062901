import React from 'react';

import Form from 'components/lib/form/Form';
import OnboardingAbstractField from 'components/onboarding/OnboardingAbstractField';
import type { AbstractFieldChildrenRenderProps } from 'components/onboarding/OnboardingAbstractField';
import OnboardingFormContainer from 'components/onboarding/OnboardingFormContainer';
import OnboardingFormSubmitButton from 'components/onboarding/OnboardingFormSubmitButton';
import OnboardingSurveyRadio from 'components/onboarding/OnboardingSurveyRadio';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';
import { usePreRegistration } from 'components/onboarding/preRegistration/PreRegistrationContext';
import {
  PRE_REGISTRATION_BETTER_UNDERSTAND,
  FinancialSystemOption,
} from 'components/onboarding/preRegistration/constants';

import { track } from 'lib/analytics/segment';

import { OnboardingEventNames } from 'common/constants/analytics';

type Props = {
  next: () => void;
};

const PreRegistrationBetterUnderstand = ({ next }: Props) => {
  const { data, setBetterUnderstand } = usePreRegistration();

  const encouragingContent = {
    [FinancialSystemOption.YES]: "Great! You're ahead of the game.",
    [FinancialSystemOption.KIND_OF]: "Great! Let's build on that.",
    [FinancialSystemOption.NOT_RIGHT_NOW]: 'Monarch can help with that.',
  };

  return (
    <>
      {data.financialSystem && (
        <OnboardingTitle>{encouragingContent[data.financialSystem]}</OnboardingTitle>
      )}

      <OnboardingTitle isSecondary={!!data.financialSystem}>
        {PRE_REGISTRATION_BETTER_UNDERSTAND.question}
      </OnboardingTitle>
      <OnboardingFormContainer>
        <Form
          onSubmit={async (values: Record<string, boolean>) => {
            const selectedOption = PRE_REGISTRATION_BETTER_UNDERSTAND.options.find(
              ({ name }) => values[name],
            );
            if (selectedOption) {
              setBetterUnderstand(selectedOption.name);
            }

            track(OnboardingEventNames.PreSurveyRegistrationAnswered, {
              question_id: PRE_REGISTRATION_BETTER_UNDERSTAND.id,
              question: PRE_REGISTRATION_BETTER_UNDERSTAND.question,
              answer: selectedOption?.name,
            });
            next();
          }}
        >
          {PRE_REGISTRATION_BETTER_UNDERSTAND.options.map(({ name, displayName }) => (
            <OnboardingAbstractField name={name} hideLabel key={name}>
              {({ value, setFieldValue }: AbstractFieldChildrenRenderProps) => (
                <OnboardingSurveyRadio
                  value={value}
                  onChange={(value) => {
                    // unselect all options so only one is selected at a time
                    PRE_REGISTRATION_BETTER_UNDERSTAND.options.forEach((option) => {
                      setFieldValue(option.name, false);
                    });
                    setFieldValue(name, value);
                  }}
                >
                  {displayName}
                </OnboardingSurveyRadio>
              )}
            </OnboardingAbstractField>
          ))}
          <OnboardingFormSubmitButton>Continue</OnboardingFormSubmitButton>
        </Form>
      </OnboardingFormContainer>
    </>
  );
};

export default PreRegistrationBetterUnderstand;
