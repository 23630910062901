import { useEffect } from 'react';
import { useLocalstorageState } from 'rooks';

import { useQueryParam } from 'lib/hooks/useQueryParams';

export const useIsWalmartUser = () => {
  const srcQueryParam = useQueryParam('src');
  const [refSrc, setRefSrc] = useLocalstorageState('ref_src');

  useEffect(() => {
    if (srcQueryParam) {
      setRefSrc(srcQueryParam);
    }
  }, [srcQueryParam, setRefSrc]);

  return srcQueryParam === 'walmart' || refSrc === 'walmart';
};
