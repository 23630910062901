import { useMutation } from '@apollo/client';

import useEventCallback from 'common/lib/hooks/useEventCallback';
import useQuery from 'common/lib/hooks/useQuery';

import { gql } from 'common/generated/gql';
import type { Common_UserProfileFlagsQuery } from 'common/generated/graphql';
import type {
  MutationHookOptionsFromDocument,
  QueryHookOptionsFromDocument,
} from 'common/types/graphql';

type UserProfileFlags = NonNullable<Common_UserProfileFlagsQuery['userProfile']>;
type Flags = Omit<UserProfileFlags, '__typename' | 'id'>;

/**
 * Manages a specific user profile attribute ("flag") by providing its current value and a
 * function to update it.
 *
 * Returns a tuple with the attribute value (or `undefined` during initial load) and the update
 * function.
 *
 * Note: The update function triggers server side update and hence can be asynchronous. The new value
 * may not immediately reflect in the attribute value returned by the hook.
 */
const useProfileFlag = <T extends keyof Flags>(
  flag: T,
  mutationOptions?: MutationHookOptionsFromDocument<typeof USER_PROFILE_FLAGS_MUTATION>,
  queryOptions?: QueryHookOptionsFromDocument<typeof USER_PROFILE_FLAGS_QUERY>,
) => {
  const { data, isLoadingInitialData } = useQuery(USER_PROFILE_FLAGS_QUERY, queryOptions);
  const [updateFlag] = useMutation(USER_PROFILE_FLAGS_MUTATION, mutationOptions);

  const userProfile = (data?.userProfile ?? {}) as UserProfileFlags;
  const flagValue = isLoadingInitialData ? undefined : (userProfile[flag] as Flags[T]);

  const setFlagValue = useEventCallback((value: Flags[T]) =>
    updateFlag({
      variables: {
        // @ts-expect-error: TODO: change graphql codegen to use optionals for nullable fields
        updateProfileInput: { [flag]: value },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateUserProfile: {
          __typename: 'UpdateUserProfile',
          userProfile: {
            ...userProfile,
            [flag]: value,
          },
        },
      },
    }),
  );

  return [flagValue, setFlagValue, isLoadingInitialData] as const;
};

export const USER_PROFILE_FLAGS_FIELDS_FRAGMENT = gql(/* GraphQL */ `
  fragment UserProfileFlagsFields on UserProfile {
    id
    aiAssistantOptedInAt
    dismissedFlexBudgetingWalkthroughAt
    dismissedRecurringWalkthroughAt
    dismissedReportsSavedViewsWalkthroughAt
    dismissedTransferAccountDataWalkthroughAt
    dismissedSpendingInsightsBanner
    dismissedTransactionsListUpdatesTourAt
    dismissedYearlyReviewAt
    hasDismissedWhatsNewAt
    hasSeenCategoriesManagementTour
    hasSeenWeeklyReviewTour
    viewedMarkAsReviewedUpdatesCalloutAt
  }
`);

export const USER_PROFILE_FLAGS_MUTATION = gql(/* GraphQL */ `
  mutation Common_UpdateUserProfileFlags($updateProfileInput: UpdateUserProfileInput!) {
    updateUserProfile(input: $updateProfileInput) {
      userProfile {
        ...UserProfileFlagsFields
      }
    }
  }
`);

export const USER_PROFILE_FLAGS_QUERY = gql(/* GraphQL */ `
  query Common_UserProfileFlags {
    userProfile {
      ...UserProfileFlagsFields
    }
  }
`);

export default useProfileFlag;
