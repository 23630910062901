import { useMutation } from '@apollo/client';
import pluralize from 'pluralize';
import * as RA from 'ramda-adjunct';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import CategorySelect from 'components/categories/FullHeightCategorySelect';
import GoalSelect from 'components/goalsV2/GoalSelect';
import ClearFieldButton from 'components/lib/form/ClearFieldButton';
import DateField from 'components/lib/form/Deprecated_DateField';
import Form from 'components/lib/form/Form';
import SelectField from 'components/lib/form/SelectField';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import DangerButton from 'components/lib/ui/button/DangerButton';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import PremiumBadge from 'components/premium/PremiumBadge';
import PremiumFeatureOverlayTrigger from 'components/premium/PremiumFeatureOverlayTrigger';
import MerchantSearchSelect from 'components/transactions/MerchantSearchSelect';
import TransactionBulkDeleteConfirmationModal from 'components/transactions/TransactionBulkDeleteConfirmationModal';
import TransactionBulkUpdateConfirmationModalContainer from 'components/transactions/TransactionBulkUpdateConfirmationModalContainer';
import TransactionBulkUpdateNotesInput from 'components/transactions/TransactionBulkUpdateNotesInput';
import TransactionDrawerFieldRow from 'components/transactions/drawer/TransactionDrawerFieldRow';
import TransactionTagSelect from 'components/transactions/tags/TransactionTagSelect';

import {
  BULK_DELETE_TRANSACTIONS_MUTATION,
  BULK_UPDATE_TRANSACTIONS_MUTATION,
} from 'common/lib/graphQl/transactions';
import useHouseholdUsers, {
  HOUSEHOLD_MEMBERS_QUERY_NAME,
} from 'common/lib/hooks/household/useHouseholdUsers';
import type { FormValues } from 'common/lib/transactions/bulkUpdate';
import {
  getValidValues,
  HIDE_SELECT_OPTIONS,
  validateForm,
  RECURRING_SELECT_OPTIONS,
} from 'common/lib/transactions/bulkUpdate';
import { REVIEW_STATUS_OPTIONS, ReviewStatus } from 'common/lib/transactions/review';
import { formatThousands } from 'common/utils/Number';
import { useSideDrawerContext } from 'lib/contexts/SideDrawerContext';
import useModal from 'lib/hooks/useModal';
import useToast from 'lib/hooks/useToast';

import * as COPY from 'common/constants/copy';
import { ProductFeature } from 'common/constants/premium';

import type {
  BulkDeleteTransactionsMutation,
  BulkDeleteTransactionsMutationVariables,
} from 'common/generated/graphQlTypes/BulkDeleteTransactionsMutation';
import type { TransactionUpdateParams } from 'common/generated/graphql';
import type { TransactionFilters } from 'types/filters';

const Root = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.xlarge} ${({ theme }) => theme.spacing.default};
`;

const StyledSelectField = styled(SelectField)`
  flex-grow: 1;
`;

const FormButtonContainer = styled(FlexContainer).attrs({ alignEnd: true })`
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing.default};
  & > *:first-child {
    margin-right: auto;
  }
`;

const StyledDateField = styled(DateField)`
  /* Fixes a bug in Safari where the date picker doesn't show (ENG-4236) */
  .react-datepicker-popper {
    transform: none !important;
    top: unset !important;
    left: unset !important;
  }
`;

type Props = {
  selectedIds: string[];
  excludedIds: string[];
  isAllSelected: boolean;
  selectedCount: number;
  filters: Partial<TransactionFilters>;
  isLoadingTags: boolean;
  initialValues?: Partial<FormValues>;
  onSuccess: () => void;
};

const TransactionBulkUpdateDrawerForm = ({
  selectedIds,
  excludedIds,
  isAllSelected,
  selectedCount,
  filters,
  isLoadingTags = true,
  initialValues,
  onSuccess,
}: Props) => {
  const { openErrorToast, openToast } = useToast();
  const { close: closeDrawer } = useSideDrawerContext();

  const [{ users }] = useHouseholdUsers();

  const householdMembersOptions = [
    {
      value: 'anyone',
      label: 'Anyone',
    },
    ...users.map((user) => ({
      label: user.name,
      value: user.id,
    })),
  ];

  const [bulkUpdateTransactions] = useMutation(BULK_UPDATE_TRANSACTIONS_MUTATION);

  const [bulkDeleteTransactions, { loading: isDeleting }] = useMutation<
    BulkDeleteTransactionsMutation,
    BulkDeleteTransactionsMutationVariables
  >(BULK_DELETE_TRANSACTIONS_MUTATION);

  const handleBulkDelete = useCallback(async () => {
    const result = await bulkDeleteTransactions({
      variables: {
        allSelected: isAllSelected,
        selectedTransactionIds: selectedIds,
        excludedTransactionIds: excludedIds,
        expectedAffectedTransactionCount: selectedCount,
        filters,
      },
    });

    if (result.data?.bulkDeleteTransactions?.success) {
      const count = selectedCount ?? 0;
      onSuccess();
      closeDrawer();
      openToast({
        title: `${pluralize('Transaction', count)} deleted successfully`,
        description: `${count} ${pluralize('transaction', count)} ${pluralize(
          'was',
          count,
        )} affected.`,
      });
    } else {
      closeDrawer();
      openErrorToast({
        title: "We couldn't delete the transactions",
        description: result.data?.bulkDeleteTransactions?.errors?.message ?? '',
      });
    }
  }, [
    closeDrawer,
    selectedIds,
    openToast,
    bulkDeleteTransactions,
    onSuccess,
    excludedIds,
    filters,
    isAllSelected,
    selectedCount,
    openErrorToast,
  ]);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      const valid: TransactionUpdateParams = getValidValues(values);

      const needsReviewByValue =
        values.needsReviewByUserId === 'anyone' ? null : values.needsReviewByUserId;

      const isUpdatingNeedsReview = RA.isNotNilOrEmpty(values.reviewStatus);

      // If the "No change" option is selected, our select field will return
      // an object that looks like: { value: undefined, label: 'No change' }
      // this checks if reviewStatus is one of the valid values and just sends undefined if it's no
      const reviewStatus =
        valid.reviewStatus &&
        Object.values(ReviewStatus).includes(valid.reviewStatus as ReviewStatus)
          ? valid.reviewStatus
          : undefined;

      const result = await bulkUpdateTransactions({
        variables: {
          selectedTransactionIds: selectedIds,
          updates: {
            ...valid,
            reviewStatus,
            needsReviewByUserId: needsReviewByValue,
          },
          excludedTransactionIds: excludedIds,
          allSelected: isAllSelected,
          expectedAffectedTransactionCount: selectedCount,
          // @ts-ignore
          filters,
        },
        refetchQueries: isUpdatingNeedsReview ? [HOUSEHOLD_MEMBERS_QUERY_NAME] : undefined,
      });

      if (result.data?.bulkUpdateTransactions?.success) {
        const { affectedCount } = result.data.bulkUpdateTransactions ?? {};
        const count = affectedCount ?? 0;

        onSuccess();
        closeDrawer();
        openToast({
          title: `${pluralize('Transaction', count)} updated successfully`,
          description: `${count} ${pluralize('transaction', count)} ${pluralize(
            'was',
            count,
          )} affected.`,
        });
      } else {
        closeDrawer();
        openErrorToast({
          description:
            "We couldn't update the transactions. Please try again or report this issue.",
        });
      }
    },
    [
      closeDrawer,
      selectedIds,
      bulkUpdateTransactions,
      openErrorToast,
      openToast,
      onSuccess,
      excludedIds,
      filters,
      isAllSelected,
      selectedCount,
    ],
  );

  const [BulkUpdateConfirmationModal, { open: openBulkUpdateConfirmationModal }] = useModal();
  const [
    BulkDeleteConfirmationModal,
    { open: openBulkDeleteConfirmationModal, close: closeBulkDeleteConfirmationModal },
  ] = useModal();

  return (
    <Form
      initialValues={{
        date: undefined,
        notes: undefined,
        merchantName: undefined,
        categoryId: undefined,
        goalId: undefined,
        tags: undefined,
        reviewStatus: undefined,
        isRecurring: undefined,
        needsReviewByUserId: undefined,
        ...initialValues,
      }}
      isInitialValid={false}
      onSubmit={handleSubmit}
      validate={validateForm}
    >
      <Root>
        <TransactionDrawerFieldRow label="Merchant">
          <SelectField
            name="merchantName"
            InputComponent={MerchantSearchSelect}
            hideLabel
            defaultOptions
          />
        </TransactionDrawerFieldRow>
        <TransactionDrawerFieldRow label="Category">
          <SelectField name="categoryId" InputComponent={CategorySelect} hideLabel isCreatable />
        </TransactionDrawerFieldRow>
        <TransactionDrawerFieldRow label="Goal">
          <SelectField name="goalId" InputComponent={GoalSelect} hideLabel />
          <FormContext.Consumer>
            {({ values }) =>
              values.goalId && (
                <Text weight="medium" size="small" color="textLight">
                  This will only update for the transactions in accounts assigned to this goal.
                </Text>
              )
            }
          </FormContext.Consumer>
        </TransactionDrawerFieldRow>

        <TransactionDrawerFieldRow label="Date">
          <StyledDateField
            name="date"
            dateFormat="MMMM d, yyyy"
            placeholderText="Change date..."
            autoComplete="off"
            hideLabel
          />
        </TransactionDrawerFieldRow>

        <TransactionDrawerFieldRow label="Recurring">
          <SelectField
            name="isRecurring"
            options={[{ value: undefined, label: 'No change' }, ...RECURRING_SELECT_OPTIONS]}
            hideLabel
          />
        </TransactionDrawerFieldRow>

        <TransactionBulkUpdateNotesInput selectedCount={selectedCount} />
        <PremiumFeatureOverlayTrigger feature={ProductFeature.TAGS}>
          {({ hasAccess }) => (
            <TransactionDrawerFieldRow
              label="Tags"
              right={hasAccess ? <ClearFieldButton name="tags" /> : <PremiumBadge />}
            >
              <SelectField
                name="tags"
                InputComponent={TransactionTagSelect}
                isLoading={isLoadingTags}
                hideLabel
                isDisabled={!hasAccess}
              />
            </TransactionDrawerFieldRow>
          )}
        </PremiumFeatureOverlayTrigger>
        <TransactionDrawerFieldRow
          label="Hide transactions"
          tooltip={COPY.TRANSACTIONS.HIDE_TRANSACTION_HELP_TEXT}
        >
          <StyledSelectField
            name="hide"
            options={HIDE_SELECT_OPTIONS}
            placeholder="No change"
            hideLabel
          />
        </TransactionDrawerFieldRow>
        <TransactionDrawerFieldRow label="Review status">
          <SelectField
            name="reviewStatus"
            menuPlacement="auto"
            options={[{ value: undefined, label: 'No change' }, ...REVIEW_STATUS_OPTIONS]}
            hideLabel
          />
        </TransactionDrawerFieldRow>
        <FormContext.Consumer>
          {({ values: { reviewStatus } }) => (
            <>
              {reviewStatus === ReviewStatus.NeedsReview && (
                <TransactionDrawerFieldRow label="Needs review by">
                  <SelectField
                    name="needsReviewByUserId"
                    placeholder="Select"
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    options={householdMembersOptions}
                    hideLabel
                  />
                </TransactionDrawerFieldRow>
              )}
            </>
          )}
        </FormContext.Consumer>

        <FormButtonContainer>
          <DangerButton size="medium" onClick={openBulkDeleteConfirmationModal}>
            Delete {formatThousands(selectedCount ?? 0)}{' '}
            {pluralize('transaction', selectedCount ?? 0)}
          </DangerButton>
          <DefaultButton size="medium" onClick={closeDrawer}>
            Cancel
          </DefaultButton>
          <FormContext.Consumer>
            {({ isValid }) => (
              <PrimaryButton
                size="medium"
                onClick={openBulkUpdateConfirmationModal}
                disabled={!isValid}
              >
                Save
              </PrimaryButton>
            )}
          </FormContext.Consumer>
        </FormButtonContainer>
      </Root>
      <BulkUpdateConfirmationModal>
        <TransactionBulkUpdateConfirmationModalContainer
          selectedCount={selectedCount}
          initialTags={initialValues?.tags}
        />
      </BulkUpdateConfirmationModal>
      <BulkDeleteConfirmationModal>
        <TransactionBulkDeleteConfirmationModal
          selectedCount={selectedCount}
          isDeleting={isDeleting}
          onConfirm={handleBulkDelete}
          onCancel={closeBulkDeleteConfirmationModal}
        />
      </BulkDeleteConfirmationModal>
    </Form>
  );
};

export default TransactionBulkUpdateDrawerForm;
