import styled from 'styled-components';

import Text from 'components/lib/ui/Text';
import { onboardingContentContainerMixin } from 'components/onboarding/style';

import { spacing } from 'common/lib/theme/dynamic';

const OnboardingTitle = styled(Text).attrs({ weight: 'medium' })<{ isSecondary?: boolean }>`
  ${onboardingContentContainerMixin}
  margin: ${({ isSecondary }) => (isSecondary ? spacing.small : 0)} auto 0;
  font-size: 20px;
  text-wrap: balance;
`;

export default OnboardingTitle;
