import type { DateTime } from 'luxon';
import React from 'react';

import MoveMoneyPopover from 'components/budget/MoveMoneyPopover';
import { OverlayTrigger, Popover } from 'components/lib/ui/popover';
import { PlanAmountsTooltip } from 'components/plan/PlanAmountsTooltip';
import { PlanCellAmountPill } from 'components/plan/PlanCellAmountPill';

import type { PlanAmounts } from 'lib/plan';
import { SAVINGS_PLAN_SECTION_TYPE } from 'lib/plan';
import type { PlanSectionType } from 'lib/plan/Adapters';

import { CategoryGroupType } from 'common/generated/graphql';

type Props = {
  date: DateTime;
  amounts: PlanAmounts | undefined;
  sectionType: PlanSectionType;
  itemId?: string;
  itemName?: string;
  itemIcon?: React.ReactNode | string;
  isAggregate?: boolean;
  isCategoryGroup?: boolean;
  showPlanAmountsTooltip: boolean;
  availableDisplayAmount: number;
  isMovable: boolean;
  showRolloverIcon: boolean;
  warningText?: string;
};

const PlanCellMoveMoneyPill = ({
  date,
  amounts,
  sectionType,
  itemId,
  itemName,
  itemIcon,
  showPlanAmountsTooltip,
  isAggregate = false,
  isCategoryGroup = false,
  availableDisplayAmount,
  isMovable,
  showRolloverIcon,
  warningText,
}: Props) => (
  <OverlayTrigger
    placement="bottom-end"
    overlay={({ close }) => (
      <Popover>
        <MoveMoneyPopover
          budgetStartDate={date}
          onDone={close}
          initialCategoryId={itemId}
          isGroup={isCategoryGroup}
          categoryType={
            sectionType === SAVINGS_PLAN_SECTION_TYPE ? CategoryGroupType.INCOME : sectionType
          }
        />
      </Popover>
    )}
  >
    {({ toggleOpen, isOpen }) => (
      <PlanAmountsTooltip
        amounts={amounts}
        showTooltip={showPlanAmountsTooltip}
        itemName={itemName}
        itemIcon={itemIcon}
        warningText={warningText}
      >
        <PlanCellAmountPill
          value={availableDisplayAmount}
          onClick={isMovable ? toggleOpen : undefined}
          showRolloverIcon={showRolloverIcon}
          active={isOpen}
          isAggregate={isAggregate}
          isMovable={isMovable}
          isCategoryGroup={isCategoryGroup}
          sectionType={sectionType}
          hasWarning={!!warningText}
          isBold
        />
      </PlanAmountsTooltip>
    )}
  </OverlayTrigger>
);

export default React.memo(PlanCellMoveMoneyPill);
