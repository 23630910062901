import useScrollToTop from 'hooks/useScrollToTop';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import OnboardingFooterNav from 'components/onboarding/OnboardingFooterNav';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';
import { usePreRegistration } from 'components/onboarding/preRegistration/PreRegistrationContext';
import { AccomplishmentOption } from 'components/onboarding/preRegistration/constants';
import { onboardingContentContainerMixin } from 'components/onboarding/style';

import { spacing, fontSize, fontWeight, radius, color, variables } from 'common/lib/theme/dynamic';

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
`;

interface Topic {
  title: string;
  text: string;
}

interface ContentBlockProps {
  imageUrl: string;
  subject: string;
  title: string;
  subtopics: Topic[];
}

const ContentBlocks = styled.div`
  margin-top: ${spacing.xxlarge};
`;

const ContentBlockContainer = styled.div`
  text-align: left;
`;

const Image = styled.img`
  margin-bottom: ${spacing.xlarge};
  box-shadow: ${variables.color.shadow.large};
  border-radius: ${radius.xxsmall};
  /* Negative offset to visually line up image (which has a shadow) with container */
  width: calc(100% + 12px);
  margin-left: -6px;
  margin-right: -6px;
`;

const Subject = styled.div`
  color: ${variables.color.content.brand};
  font-size: ${fontSize.xsmall};
  font-weight: ${fontWeight.medium};
  line-height: 150%;
  letter-spacing: 0.48px;
  margin-bottom: ${spacing.xxsmall};
`;

const Title = styled.h2`
  font-size: 20px;
  color: ${variables.color.content.primary};
  line-height: 150%;
  font-weight: ${fontWeight.medium};
  margin: 0;
`;

const TopicContainer = styled.div`
  margin-top: ${spacing.default};
`;

const TopicTitle = styled.div`
  font-size: ${fontSize.base};
  font-weight: ${fontWeight.medium};
  color: ${variables.color.content.primary};
`;

const TopicText = styled.div`
  font-size: ${fontSize.small};
  color: ${variables.color.content.secondary};
  margin-bottom: ${spacing.default};
`;

const Container = styled.div`
  ${onboardingContentContainerMixin}
  margin: 0 auto;
`;

const PageTitle = styled(OnboardingTitle)`
  text-align: center;
  display: block;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${color.gray2};
  margin: ${spacing.xxlarge} 0;
`;

const BASE_S3_URL =
  'https://monarch-static-assets.s3.us-east-1.amazonaws.com/onboarding/pre-registration/';

const BASE_CONTENT_BLOCKS: ContentBlockProps[] = [
  {
    imageUrl: `${BASE_S3_URL}tracking.png`,
    subject: 'TRACKING',
    title: 'See exactly where your money goes',
    subtopics: [
      {
        title: 'Get spending insights at your fingertips',
        text: 'View trends by category, group, or merchant.',
      },
      {
        title: 'Grow your net worth',
        text: 'Bring all your accounts and investments together.',
      },
      {
        title: "Know what's around the corner",
        text: 'Track your bills and subscriptions all in one place.',
      },
    ],
  },
  {
    imageUrl: `${BASE_S3_URL}planning.png`,
    subject: 'PLANNING',
    title: 'Save for goals both big and small',
    subtopics: [
      {
        title: 'Make tangible progress',
        text: 'Create specific goals and watch your savings add up.',
      },
      {
        title: 'Save alongside your daily spending',
        text: 'Plan for savings goals alongside your monthly budget.',
      },
      {
        title: 'Plan for near and long-term goals',
        text: 'Save for things like vacation, down payments, retirement, or pay off debt.',
      },
    ],
  },
  {
    imageUrl: `${BASE_S3_URL}budgeting.png`,
    subject: 'BUDGETING',
    title: 'Budgeting made easy',
    subtopics: [
      {
        title: 'Choose your budgeting style',
        text: 'Monarch offers two ways to budget, so you can find what works for you.',
      },
      {
        title: 'Customize to your needs',
        text: 'Modify your categories, rollovers, notifications, and more.',
      },
      {
        title: 'Increase your savings',
        text: 'Monitor your spending and find areas to save.',
      },
    ],
  },
  {
    imageUrl: `${BASE_S3_URL}collaboration2.png`,
    subject: 'COLLABORATION',
    title: 'Make smart money moves together',
    subtopics: [
      {
        title: 'Create a shared view of your money',
        text: 'Get the full picture by bringing separate and joint accounts together.',
      },
      {
        title: 'Stay on the same page',
        text: 'Money talks are quick and easy with monthly summaries and interactive reports.',
      },
      {
        title: 'Go further, together',
        text: 'Align on your shared goals and track your progress.',
      },
    ],
  },
];

const ContentBlock: React.FC<ContentBlockProps> = ({ imageUrl, subject, title, subtopics }) => (
  <ContentBlockContainer>
    <Image src={imageUrl} alt={title} />
    <Subject>{subject}</Subject>
    <Title>{title}</Title>
    {subtopics.map((topic, index) => (
      <TopicContainer key={index}>
        <TopicTitle>{topic.title}</TopicTitle>
        <TopicText>{topic.text}</TopicText>
      </TopicContainer>
    ))}
  </ContentBlockContainer>
);

const PRIORITY_TO_SUBJECT_MAP: Record<AccomplishmentOption, string> = {
  [AccomplishmentOption.COLLABORATE_PARTNER]: 'COLLABORATION',
  [AccomplishmentOption.SAVE_GOALS]: 'PLANNING',
  [AccomplishmentOption.PAY_DEBT]: 'BUDGETING',
  [AccomplishmentOption.UNDERSTAND_SPENDING]: 'TRACKING',
  [AccomplishmentOption.BUDGET_BETTER]: 'BUDGETING',
  [AccomplishmentOption.REDUCE_EXPENSES]: 'PLANNING',
};

type Props = {
  next: () => void;
};

const PreRegistrationWeCanHelpYou: React.FC<Props> = ({ next }) => {
  useScrollToTop();
  const { data } = usePreRegistration();
  const { mostImportant } = data;

  const contentBlocks = useMemo(() => {
    const blocks = [...BASE_CONTENT_BLOCKS];

    if (!mostImportant) {
      return blocks;
    }

    const subject = PRIORITY_TO_SUBJECT_MAP[mostImportant];
    const priorityBlock = blocks.find((block) => block.subject === subject);

    if (priorityBlock) {
      const filteredBlocks = blocks.filter((block) => block !== priorityBlock);
      return [priorityBlock, ...filteredBlocks];
    }

    return blocks;
  }, [mostImportant]);

  return (
    <Container>
      <PageTitle>Here&apos;s how Monarch can help you</PageTitle>
      <ContentBlocks>
        {contentBlocks.map((block, index) => (
          <>
            <ContentBlock key={index} {...block} />
            {index < contentBlocks.length - 1 && <Divider />}
          </>
        ))}
      </ContentBlocks>
      <OnboardingFooterNav>
        <StyledPrimaryButton size="large" onClick={next}>
          Continue
        </StyledPrimaryButton>
      </OnboardingFooterNav>
    </Container>
  );
};

export default PreRegistrationWeCanHelpYou;
