import React from 'react';

import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import type { FormSubmitButtonProps } from 'components/lib/form/FormSubmitButton';
import OnboardingFooterNav from 'components/onboarding/OnboardingFooterNav';

export const OnboardingFormSubmitButton = (props: FormSubmitButtonProps) => (
  <OnboardingFooterNav>
    <FormSubmitButton size="large" {...props} />
  </OnboardingFooterNav>
);

export default OnboardingFormSubmitButton;
