import prettyBytes from 'pretty-bytes';

import type { FileData } from 'components/transactions/drawer/TransactionDrawerBody';

import { DECIMAL_TO_PERCENT } from 'common/constants/math';

import type { GetTransactionDrawerQuery } from 'common/generated/graphql';

type TransactionAttachment = NonNullable<
  GetTransactionDrawerQuery['getTransaction']
>['attachments'][number];

export const transactionAttachmentToFileData = ({
  sizeBytes,
  filename,
  extension,
  id,
  originalAssetUrl,
}: TransactionAttachment): FileData => ({
  attachmentId: id,
  name: [filename, extension].join('.'),
  sizeBytes,
  displaySize: prettyBytes(sizeBytes),
  progressPercent: DECIMAL_TO_PERCENT,
  originalAssetUrl,
  extension,
});
