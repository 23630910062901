import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import AccountLogo from 'components/accounts/AccountLogo';
import Dot from 'components/lib/ui/Dot';
import Icon from 'components/lib/ui/Icon';
import FilterMenu from 'components/reports/filters/FilterMenu';

import { setReportsFilters } from 'actions';
import { color, fontSize, fontWeight, spacing } from 'common/lib/theme/dynamic';
import { formatThousands } from 'common/utils/Number';
import useDispatch from 'lib/hooks/useDispatch';
import useTransactionFilterMenu from 'lib/hooks/useTransactionFilterMenu';
import { selectReportsFilters } from 'state/reports/selectors';

const TAG_DOT_SIZE_PX = 10;
const CREDIT_CARD_ICON_SIZE_PX = 12;
const ACCOUNT_LOGO_SIZE_PX = 14;

const CreditCardIcon = styled(Icon).attrs({
  name: 'credit-card',
  size: CREDIT_CARD_ICON_SIZE_PX,
})``;

const MerchantTransactionCount = styled.span`
  display: flex;
  align-items: center;
  gap: ${spacing.xxsmall};
  font-size: ${fontSize.small};
  color: ${color.textLight};
  font-weight: ${fontWeight.book};
`;

const SmallAccountLogo = styled(AccountLogo).attrs({ size: ACCOUNT_LOGO_SIZE_PX })`
  margin-right: ${spacing.xxsmall};
`;

const TagDot = styled(Dot).attrs({ size: TAG_DOT_SIZE_PX })`
  margin-right: ${spacing.xxsmall};
`;

type Props = {
  toggleOpen: () => void;
};

const ReportsFilterMenuPopover = ({ toggleOpen }: Props) => {
  const dispatch = useDispatch();
  const existingFilters = useSelector(selectReportsFilters);

  const { sections, isLoading, isFetchingMoreData, onChangeSections, handleApply, onSearchChange } =
    useTransactionFilterMenu({
      page: 'reports',
      filters: existingFilters,
      onApplyFilters: (filters) => {
        dispatch(setReportsFilters({ filters, maintainDateFilters: true }));
        toggleOpen();
      },
      renderAccountLogo: (account) => (
        <SmallAccountLogo logoUrl={account.logoUrl} icon={account.icon} />
      ),
      renderMerchantAccessory: (merchant) =>
        merchant.transactionCount && (
          <MerchantTransactionCount>
            <CreditCardIcon />
            {formatThousands(merchant.transactionCount)}
          </MerchantTransactionCount>
        ),
      renderTag: (tag) => <TagDot color={tag.color} />,
    });

  return (
    <FilterMenu
      isLoading={isLoading}
      onClickApply={handleApply}
      onClickCancel={toggleOpen}
      sections={sections}
      onChange={onChangeSections}
      onSearchChange={onSearchChange}
      isSearchLoading={isFetchingMoreData}
    />
  );
};

export default ReportsFilterMenuPopover;
