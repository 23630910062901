import { css } from 'styled-components';

import { color, radius } from 'common/lib/theme/dynamic';

export const onboardingSurveyRadioIndicatorStyles = css`
  border-radius: ${radius.xlarge};
`;

export const onboardingSurveyRadioIconStyles = css`
  color: ${color.orange};
`;

export const onboardingSurveySelectIndicatorDotStyles = css`
  width: 8px;
  height: 8px;
  border-radius: ${radius.xlarge};
  background: ${color.orange};
`;
