import React, { useEffect } from 'react';

import { DatePicker } from 'components/accounts/transferAccountData/DatePicker';
import { TransactionsPreview } from 'components/accounts/transferAccountData/TransactionsPreview';
import RadioGroup from 'components/lib/form/RadioGroup';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import type { StepProps } from 'components/routes/accounts/transferAccountData/TransferAccountData';
import TransferAccountDataStep from 'components/routes/accounts/transferAccountData/TransferAccountDataStep';

import { isoDateToAbbreviatedMonthDayAndYear, parseISODate } from 'common/utils/date';
import { getProgressPercent } from 'lib/accounts/transferAccountData/utils/progress';
import { track } from 'lib/analytics/segment';
import useDataTransferAccounts from 'lib/hooks/accounts/useDataTransferAccounts';
import usePreviewAccountDataTransferTransactions from 'lib/hooks/accounts/usePreviewAccountDataTransferTransactions';
import useRecommendedDataTransferDates from 'lib/hooks/accounts/useRecommendedDataTransferDates';

import { TransferAccountDataEventNames } from 'common/constants/analytics';

const PREVIEW_DAYS = 2;

const Transactions: React.FC<StepProps> = ({ params, updateParams, next }) => {
  const { fromAccountId, toAccountId, transferTransactions, endDateTransactions } = params;

  const { data: recommendedDataTransferDates, loading: loadingRecommendedDataTransferDates } =
    useRecommendedDataTransferDates(fromAccountId, toAccountId);
  const { data: accountsForTransfer, loading: loadingAccountsForTransfer } =
    useDataTransferAccounts(fromAccountId, toAccountId);

  const hasTransactionsToTransfer =
    recommendedDataTransferDates && recommendedDataTransferDates.transactionsEndDate !== null;
  useEffect(() => {
    // Only run this effect once the data has finished loading
    if (loadingRecommendedDataTransferDates) {
      return;
    }

    // If we have a recommended end date, and we don't yet have an end date, set the end date to the
    // recommended end date
    if (hasTransactionsToTransfer && !endDateTransactions) {
      updateParams({
        endDateTransactions: recommendedDataTransferDates.transactionsEndDate,
      });
    } else if (!hasTransactionsToTransfer && transferTransactions) {
      // Otherwise, if we don't have a recommended end date, that means we don't have any
      // transactions to transfer.
      updateParams({
        transferTransactions: false,
        endDateTransactions: null,
        startDateTransactions: null,
      });
    }
  }, [
    hasTransactionsToTransfer,
    recommendedDataTransferDates,
    endDateTransactions,
    transferTransactions,
    updateParams,
    loadingRecommendedDataTransferDates,
  ]);

  const {
    data: previewAccountDataTransferTransactions,
    loading: loadingPreviewAccountDataTransferTransactions,
  } = usePreviewAccountDataTransferTransactions(
    fromAccountId,
    toAccountId,
    recommendedDataTransferDates?.transactionsStartDate,
    endDateTransactions,
    PREVIEW_DAYS,
  );

  const handleNext = () => {
    const isRecommendedDate =
      endDateTransactions === recommendedDataTransferDates?.transactionsEndDate;

    track(TransferAccountDataEventNames.TransferAccountDataTransactionsDateSelected, {
      transferTransactions,
      selectedDate: endDateTransactions,
      recommendedDate: recommendedDataTransferDates?.transactionsEndDate,
      isRecommendedDate,
    });

    next({
      fromAccountExpectedAffectedTransactionCount:
        previewAccountDataTransferTransactions?.fromAccountSummary.numTransactions ?? 0,
      toAccountExpectedAffectedTransactionCount:
        previewAccountDataTransferTransactions?.toAccountSummary.numTransactions ?? 0,
      toAccountNewerTransactionCount:
        previewAccountDataTransferTransactions?.toAccountSummary.numNewerTransactions ?? 0,
      startDateTransactions: recommendedDataTransferDates?.transactionsStartDate,
    });
  };

  return (
    <TransferAccountDataStep
      exitAccountId={fromAccountId}
      progress={getProgressPercent(2)}
      overrideTitle="Transfer transactions"
      isLoading={loadingRecommendedDataTransferDates}
      footer={
        <PrimaryButton size="large" onClick={handleNext}>
          Next up: Balances
        </PrimaryButton>
      }
    >
      <FlexContainer column marginTop="xxlarge" marginBottom="xxlarge" gap="xxlarge">
        <FlexContainer column gap="small">
          <Text size="large" weight="medium">
            Transactions
          </Text>
          <RadioGroup
            value={transferTransactions ? 'transferTransactions' : 'skipTransactions'}
            onChange={(value) =>
              updateParams({
                transferTransactions: value === 'transferTransactions',
              })
            }
            options={[
              {
                value: 'transferTransactions',
                label: 'Move transactions to your new account',
                description:
                  'You can choose which day you want transactions (including updated categories, notes, attachments, and splits) to move from your old account to your new account.',
                disabled: !hasTransactionsToTransfer,
              },
              {
                value: 'skipTransactions',
                label: 'Don’t move transactions',
                description: hasTransactionsToTransfer
                  ? 'If you prefer not to transfer transactions from your old account to your new one, you can move to the next step for transferring balances.'
                  : 'There aren’t any transactions on your old account to move. Continue to the next step for transferring balances.',
              },
            ]}
          />
        </FlexContainer>
        {transferTransactions && (
          <>
            <DatePicker
              title="Transition date"
              subtitle={`We recommend the date of the last transaction on your old account: ${isoDateToAbbreviatedMonthDayAndYear(
                recommendedDataTransferDates?.transactionsEndDate ?? '',
              )}`}
              tooltip="Transactions on and before this date will move from your old account to your new account."
              tooltipMaxWidth={285} // Sized to keep the text at two lines
              recommendedDate={recommendedDataTransferDates?.transactionsEndDate}
              endDate={endDateTransactions}
              setEndDate={(date) => updateParams({ endDateTransactions: date })}
              minDate={
                recommendedDataTransferDates?.transactionsStartDate
                  ? parseISODate(recommendedDataTransferDates?.transactionsStartDate)
                  : null
              }
            />
            <TransactionsPreview
              isLoading={
                loadingPreviewAccountDataTransferTransactions || loadingAccountsForTransfer
              }
              startDate={recommendedDataTransferDates?.transactionsStartDate ?? ''}
              transitionDate={endDateTransactions ?? ''}
              fromAccount={accountsForTransfer?.fromAccount}
              toAccount={accountsForTransfer?.toAccount}
              fromAccountSummary={previewAccountDataTransferTransactions?.fromAccountSummary}
              toAccountSummary={previewAccountDataTransferTransactions?.toAccountSummary}
              previewDays={PREVIEW_DAYS}
              lastTransactionDate={recommendedDataTransferDates?.transactionsEndDate}
            />
          </>
        )}
      </FlexContainer>
    </TransferAccountDataStep>
  );
};

export default Transactions;
