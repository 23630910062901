import React from 'react';
import styled from 'styled-components';

import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import OnboardingFooterNav from 'components/onboarding/OnboardingFooterNav';
import OnboardingSubtitle from 'components/onboarding/OnboardingSubtitle';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';
import Testimonials from 'components/onboarding/Testimonials';

import { spacing } from 'common/lib/theme/dynamic';

import { ONBOARDING } from 'common/constants/copy';

const StyledTestimonials = styled(Testimonials)`
  text-align: left;
  margin-top: ${spacing.xxlarge};
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
`;

type Props = {
  next: () => void;
};

const OnboardingTestimonials = ({ next }: Props) => (
  <>
    <OnboardingTitle>{ONBOARDING.TESTIMONIALS.TITLE}</OnboardingTitle>
    <OnboardingSubtitle>{ONBOARDING.TESTIMONIALS.SUBTITLE}</OnboardingSubtitle>
    <StyledTestimonials />
    <OnboardingFooterNav>
      <StyledPrimaryButton size="large" onClick={next}>
        {ONBOARDING.TESTIMONIALS.BUTTON}
      </StyledPrimaryButton>
    </OnboardingFooterNav>
  </>
);

export default OnboardingTestimonials;
