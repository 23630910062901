import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import Mark from 'components/brand/Mark';
import { ArrowUpExtension } from 'components/lib/ui/ArrowUpExtension';
import Flex from 'components/lib/ui/Flex';
import Text from 'components/lib/ui/Text';

import { fontSize, fontWeight, spacing } from 'common/lib/theme/dynamic';
import { useRetailSyncMessaging } from 'lib/hooks/retailSync/useRetailSyncMessaging';
import useTheme from 'lib/hooks/useTheme';
import isEnvDevelopment from 'lib/isEnvDevelopment';
import { getUser } from 'selectors';

import routes from 'constants/routes';

const MARGIN_TOP = '80px';
const Root = styled(Flex).attrs({ column: true, justifyCenter: true, alignCenter: true })<{
  $bgColor: string;
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: ${({ $bgColor }) => $bgColor};
  padding: ${({ theme }) => theme.spacing.xsmall};
  align-items: center;
  padding-top: ${MARGIN_TOP};
  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    justify-content: center;
    padding-top: ${({ theme }) => theme.spacing.xsmall};
  }
`;

const Layout = styled(Flex)`
  max-width: 100%;
  align-items: center;
`;

const AuthenticatingLabel = styled.div<{ $color: string }>`
  font-size: ${fontSize.xxxlarge};
  font-weight: ${fontWeight.bold};
  color: ${({ $color }) => $color};
`;

const AuthenticatedLabel = styled.div<{ $color: string }>`
  font-size: ${fontSize.xxxlarge};
  font-weight: ${fontWeight.bold};
  color: ${({ $color }) => $color};
`;

const OpenExtensionLabel = styled.div<{ $color: string }>`
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.bold};
  color: ${({ $color }) => $color};

  margin-top: ${spacing.default};
  width: 228px;
  text-align: center;
`;

const OpenExtensionContainer = styled(Flex).attrs({
  column: true,
  alignEnd: true,
})`
  position: absolute;
  top: 0;
  right: 120px;
  margin-top: ${spacing.xxlarge};
`;

const LoadingMonarchIcon = styled.img`
  width: 100px;
  height: 100px;
  margin-top: -50px;
`;

const RedirectText = styled(Text)<{ $color: string }>`
  font-size: ${fontSize.large};
  color: ${({ $color }) => $color};
  opacity: 0.7;
`;

const RetailSyncAuthentication = () => {
  // Force the light theme to avoid changing and mixing with the orange bg
  const theme = useTheme('light');

  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector(getUser);
  const history = useHistory();
  const { sendLoggedInMessage } = useRetailSyncMessaging();

  useEffect(() => {
    const sendMessageAsync = async () => {
      if (!user) {
        return;
      }

      await sendLoggedInMessage();

      setTimeout(() => {
        setIsLoading(false);
      }, 3000);

      setTimeout(() => {
        if (!isEnvDevelopment) {
          history.push(routes.dashboard.path);
        }
      }, 10000);
    };

    sendMessageAsync();
  }, []);

  return (
    <Root $bgColor={isLoading ? theme.color.grayBackground : theme.color.orange}>
      <Layout>
        <Flex column alignCenter gap={theme.spacing.xxxlarge}>
          {isLoading ? (
            <LoadingMonarchIcon src="/butterfly-animation.gif" alt="Loading butterfly animation" />
          ) : (
            <Mark
              color={isLoading ? theme.color.grayBackground : theme.color.pureWhite}
              size={72}
            />
          )}

          {isLoading ? (
            <AuthenticatingLabel $color={theme.color.gray11}>Authenticating...</AuthenticatingLabel>
          ) : (
            <>
              <Flex column gap={theme.spacing.large} alignCenter>
                <AuthenticatedLabel $color={theme.color.white}>
                  You&apos;re all set.
                </AuthenticatedLabel>
                <RedirectText $color={theme.color.white}>
                  Redirecting to your dashboard...
                </RedirectText>
              </Flex>

              <OpenExtensionContainer>
                <ArrowUpExtension color={theme.color.white} />
                <OpenExtensionLabel $color={theme.color.white}>
                  Open the Monarch extension to get started.
                </OpenExtensionLabel>
              </OpenExtensionContainer>
            </>
          )}
        </Flex>
      </Layout>
    </Root>
  );
};

export default RetailSyncAuthentication;
