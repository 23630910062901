import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';

import { color, radius, spacing } from 'common/lib/theme/dynamic';

const ICON_SIZE_PX = 24;

const Root = styled(FlexContainer).attrs({ column: true })`
  background-color: ${color.grayLightBackground};
  border-radius: ${radius.medium};
  padding: ${spacing.default};
`;

// This lets us export so we can override styles for the title
const InformationListTitle = styled(Text)``;

const List = styled(FlexContainer).attrs({ column: true })`
  margin-top: ${spacing.xsmall};
  margin-bottom: ${spacing.xxsmall};
  gap: ${spacing.xsmall};
`;

const Item = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: start;
  grid-template-columns: auto 1fr;
  gap: ${spacing.xsmall};
`;

const InformationItemIcon = styled(Icon)`
  margin: 0;
`;

type ItemProps = {
  iconName: string;
  iconSizePx?: number;
  children: React.ReactNode;
};

type Props = {
  title: string;
  children: React.ReactNode;
  className?: string;
};

const InformationList = ({ title, children, className }: Props) => (
  <Root className={className}>
    <InformationListTitle weight="medium" size="base">
      {title}
    </InformationListTitle>
    <List>{children}</List>
  </Root>
);

const InformationItem = ({ iconName, iconSizePx = ICON_SIZE_PX, children }: ItemProps) => (
  <Item>
    <InformationItemIcon name={iconName} size={iconSizePx} />
    <Text>{children}</Text>
  </Item>
);

export default InformationList;
export { InformationItem, InformationItemIcon, InformationListTitle };
