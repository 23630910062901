import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';



import Form from 'components/lib/form/Form';
import OnboardingAbstractField from 'components/onboarding/OnboardingAbstractField';
import type { AbstractFieldChildrenRenderProps } from 'components/onboarding/OnboardingAbstractField';
import OnboardingFormContainer from 'components/onboarding/OnboardingFormContainer';
import OnboardingFormSubmitButton from 'components/onboarding/OnboardingFormSubmitButton';
import OnboardingSurveyRadio from 'components/onboarding/OnboardingSurveyRadio';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';
import { usePreRegistration } from 'components/onboarding/preRegistration/PreRegistrationContext';
import { PRE_REGISTRATION_MOST_IMPORTANT, AccomplishmentOption } from 'components/onboarding/preRegistration/constants';



import { track } from 'lib/analytics/segment';



import { OnboardingEventNames } from 'common/constants/analytics';
import routes from 'constants/routes';


type Props = {
  next: () => void;
};

const PreRegistrationMostImportant = ({ next }: Props) => {
  const history = useHistory();
  const { data, setMostImportant } = usePreRegistration();
  const selectedAccomplishments = useMemo(() => data.accomplishments ?? [], [data.accomplishments]);

  // this is a workaround because if we do it in the OnboardingFlow, there isa racing condition
  // where the state is not updated yet
  useEffect(() => {
    // If there's only one accomplishment selected, skip to the appropriate next step
    if (selectedAccomplishments.length === 1) {
      setMostImportant(selectedAccomplishments[0]);
      track(OnboardingEventNames.PreSurveyRegistrationAnswered, {
        question_id: PRE_REGISTRATION_MOST_IMPORTANT.id,
        question: PRE_REGISTRATION_MOST_IMPORTANT.question,
        answer: selectedAccomplishments[0],
      });


      const hasSaveGoals = selectedAccomplishments.includes(AccomplishmentOption.SAVE_GOALS);
      const nextRoute = hasSaveGoals
        ? routes.signup.preRegistration.savingGoals()
        : routes.signup.preRegistration.financesComplexity();

      history.replace(nextRoute);
    }
  }, [selectedAccomplishments, history, next, setMostImportant]);
  // Filter options based on what was selected in PreRegistrationAccomish
  const options = PRE_REGISTRATION_MOST_IMPORTANT.options.filter(({ name }) =>
    selectedAccomplishments.includes(name),
  );

  const handleSubmit = async (values: Record<string, boolean>) => {
    const selectedOption = options.find(({ name }) => values[name]);
    if (selectedOption) {
      setMostImportant(selectedOption.name);
    }

    track(OnboardingEventNames.PreSurveyRegistrationAnswered, {
      question_id: PRE_REGISTRATION_MOST_IMPORTANT.id,
      question: PRE_REGISTRATION_MOST_IMPORTANT.question,
      answer: selectedOption?.name,
    });

    next();
  };

  return (
    <>
      <OnboardingTitle>{PRE_REGISTRATION_MOST_IMPORTANT.question}</OnboardingTitle>
      <OnboardingTitle isSecondary>{PRE_REGISTRATION_MOST_IMPORTANT.question2}</OnboardingTitle>
      <OnboardingFormContainer>
        <Form onSubmit={handleSubmit}>
          {options.map(({ name, displayName }) => (
            <OnboardingAbstractField name={name} hideLabel key={name}>
              {({ value, setFieldValue }: AbstractFieldChildrenRenderProps) => (
                <OnboardingSurveyRadio
                  value={value}
                  onChange={(value) => {
                    // unselect all options so only one is selected at a time
                    options.forEach((option) => {
                      setFieldValue(option.name, false);
                    });
                    setFieldValue(name, value);
                  }}
                >
                  {displayName}
                </OnboardingSurveyRadio>
              )}
            </OnboardingAbstractField>
          ))}
          <OnboardingFormSubmitButton>Continue</OnboardingFormSubmitButton>
        </Form>
      </OnboardingFormContainer>
    </>
  );
};

export default PreRegistrationMostImportant;