import React, { Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import EmployeeMenuDebugInfo from 'components/employee/EmployeeMenuDebugInfo';
import EmployeeMenuEmailReport from 'components/employee/EmployeeMenuEmailReport';
import EmployeeMenuForceRefresh from 'components/employee/EmployeeMenuForceRefresh';
import EmployeeMenuOther from 'components/employee/EmployeeMenuOther';
import EmployeeMenuPlan from 'components/employee/EmployeeMenuPlan';
import EmployeeMenuRecurring from 'components/employee/EmployeeMenuRecurring';
import EmployeeMenuTags from 'components/employee/EmployeeMenuTags';
import DropdownMenu from 'components/lib/ui/DropdownMenu';
import NestedDropdownMenu from 'components/lib/ui/NestedDropdownMenu';
import Toggle from 'components/lib/ui/Toggle';
import { toast as customToast } from 'components/lib/ui/toast/Toast';
import Stack from 'components/utils/Stack';

import { setUiThemePreference } from 'actions';
import { forceEmptyState, setDemoMode } from 'common/actions';
import useProfileFlag from 'common/lib/hooks/users/useProfileFlag';
import { getForceEmptyState, getIsDemoMode } from 'common/state/employee/selectors';
import useTheme from 'lib/hooks/useTheme';
import toast from 'lib/ui/toast';

import routes from 'constants/routes';

import type { UiTheme } from 'common/types';

const RenderProfilerToggle = React.lazy(() => import('components/employee/RenderProfilerToggle'));

const StyledDropdownMenu = styled(DropdownMenu)`
  width: 328px;
`;

const MenuHome = () => {
  const { uiTheme } = useTheme();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isDemoMode = useSelector(getIsDemoMode);
  const isForcedEmptyState = useSelector(getForceEmptyState);

  const [, updateDismissedRecurringWalkthroughAt] = useProfileFlag(
    'dismissedRecurringWalkthroughAt',
  );
  const [
    dismissedTransferAccountDataWalkthroughAt,
    updateDismissedTransferAccountDataWalkthroughAt,
  ] = useProfileFlag('dismissedTransferAccountDataWalkthroughAt');

  const [dismissedReportsSavedViewsWalkthroughAt, updateDismissedReportsSavedViewsWalkthroughAt] =
    useProfileFlag('dismissedReportsSavedViewsWalkthroughAt');

  const [, updateHasDismissedWhatsNewAt] = useProfileFlag('hasDismissedWhatsNewAt');

  const reloadPageAfterASecond = () => {
    setTimeout(() => window.location.reload(), 1000);
  };

  return (
    <NestedDropdownMenu
      items={[
        {
          title: 'Demo Mode',
          right: (
            <Toggle
              checked={isDemoMode}
              onChange={({ target: { checked } }) => dispatch(setDemoMode(checked))}
            />
          ),
        },
        {
          title: 'Force Empty State',
          right: (
            <Toggle
              checked={isForcedEmptyState}
              onChange={({ target: { checked } }) => dispatch(forceEmptyState(checked))}
            />
          ),
        },
        {
          title: 'Dark Mode',
          right: (
            <Toggle
              checked={uiTheme === 'dark'}
              onChange={({ target: { checked } }) => {
                const preference: UiTheme = checked ? 'dark' : 'light';
                dispatch(setUiThemePreference(preference));
              }}
            />
          ),
        },
        {
          title: 'Show Renders',
          right: (
            <Suspense fallback={<></>}>
              <RenderProfilerToggle />
            </Suspense>
          ),
        },
        {
          title: 'Debug Info',
          icon: 'info',
          component: EmployeeMenuDebugInfo,
        },
        {
          title: 'Recurring',
          icon: 'calendar',
          component: EmployeeMenuRecurring,
        },
        {
          title: 'Tags',
          icon: 'tag',
          component: EmployeeMenuTags,
        },
        {
          title: 'Force Refresh',
          icon: 'refresh-cw',
          component: EmployeeMenuForceRefresh,
        },
        {
          title: 'Monthly Email Report',
          icon: 'mail',
          component: EmployeeMenuEmailReport,
        },
        {
          title: 'Reset Reports state',
          icon: 'trash',
          description: 'Useful if you are in a weird state with the filters or chart options.',
          show: pathname.includes(routes.reports.path),
          onClick: () => {
            localStorage.removeItem('persist:reports');
            toast({ title: 'Reports state cleared', description: 'Reloading the page...' });
            reloadPageAfterASecond();
          },
        },
        {
          title: 'Reset Recurring Walkthrough',
          show: pathname.includes(routes.recurring.path),
          icon: 'undo',
          onClick: async () => {
            await updateDismissedRecurringWalkthroughAt(null);
            customToast({
              title: 'Recurring walkthrough reset',
              description: 'Reloading the page in a second...',
            });
            reloadPageAfterASecond();
          },
        },
        {
          title: 'Budget',
          icon: 'dollar-sign',
          show: pathname.includes(routes.plan.path) || pathname.includes(routes.budget.path),
          component: EmployeeMenuPlan,
        },
        {
          title: "Reset What's New",
          icon: 'undo',
          onClick: async () => {
            await updateHasDismissedWhatsNewAt(null);
            customToast({
              title: "What's New reset",
              description: 'Reloading the page in a second...',
            });
            reloadPageAfterASecond();
          },
        },
        {
          title: 'Reset Saved Views Walkthrough',
          icon: 'undo',
          show: !!dismissedReportsSavedViewsWalkthroughAt,
          onClick: async () => {
            await updateDismissedReportsSavedViewsWalkthroughAt(null);
            customToast({
              title: 'Saved Views Walkthrough reset',
              description: 'Reloading the page in a second...',
            });
            reloadPageAfterASecond();
          },
        },
        {
          title: 'Reset Transfer Account Data Walkthrough',
          show:
            !!dismissedTransferAccountDataWalkthroughAt && pathname.includes(routes.accounts.path),
          icon: 'undo',
          onClick: async () => {
            await updateDismissedTransferAccountDataWalkthroughAt(null);
            customToast({
              title: 'Transfer Account Data Walkthrough reset',
              description: 'Reloading the page in a second...',
            });
            reloadPageAfterASecond();
          },
        },
        {
          title: 'Other',
          icon: 'more-horizontal',
          component: EmployeeMenuOther,
        },
      ]}
    />
  );
};

const EmployeeMenu = () => (
  <StyledDropdownMenu>
    <Stack initial={{ component: MenuHome, props: {} }} />
  </StyledDropdownMenu>
);

export default EmployeeMenu;
