import React from 'react';
import styled from 'styled-components';

import ManualLink from 'components/lib/ui/link/ManualLink';

import { color } from 'common/lib/theme/dynamic';

import { externalUrls } from 'constants/routes';

const StyledLink = styled(ManualLink)`
  color: ${color.text};
`;

const TermsAndConditions = () => (
  <>
    By clicking the button above, you agree to our{' '}
    <StyledLink target="_blank" href={externalUrls.termsOfUse} unstyled>
      Terms of Use
    </StyledLink>{' '}
    and{' '}
    <StyledLink target="_blank" href={externalUrls.privacyPolicy} unstyled>
      Privacy Policy
    </StyledLink>
    .
  </>
);

export default TermsAndConditions;
