export enum FinancialSystemOption {
  YES = 'yes',
  KIND_OF = 'kind_of',
  NOT_RIGHT_NOW = 'not_right_now',
}

export enum BetterUnderstandOption {
  YES = 'yes',
  NO = 'no',
}

export enum AccomplishmentOption {
  UNDERSTAND_SPENDING = 'understand_spending',
  SAVE_GOALS = 'save_goals',
  BUDGET_BETTER = 'budget_better',
  PAY_DEBT = 'pay_debt',
  COLLABORATE_PARTNER = 'collaborate_partner',
  REDUCE_EXPENSES = 'reduce_expenses',
}

export enum SavingGoalOption {
  VACATION = 'vacation',
  HOUSE = 'house',
  RETIREMENT = 'retirement',
  EMERGENCY_FUND = 'emergency_fund',
  EDUCATION = 'education',
  STARTING_FAMILY = 'starting_family',
  WEDDING = 'wedding',
  FINANCIAL_INDEPENDENCE = 'financial_independence',
  SOMETHING_ELSE = 'something_else',
}

export enum FinancesComplexityOption {
  YES = 'yes',
  NO = 'no',
}

const PRE_REGISTRATION_FINANCIAL_SYSTEM_OPTIONS: Array<{
  name: FinancialSystemOption;
  displayName: string;
}> = [
  { name: FinancialSystemOption.YES, displayName: 'Yes' },
  { name: FinancialSystemOption.KIND_OF, displayName: 'Kind of' },
  { name: FinancialSystemOption.NOT_RIGHT_NOW, displayName: 'Not right now' },
];

export const PRE_REGISTRATION_FINANCIAL_SYSTEM = {
  id: 'financial_system',
  question: 'Tell us a little about you.',
  question2: 'Do you have a system for tracking your personal finances?',
  options: PRE_REGISTRATION_FINANCIAL_SYSTEM_OPTIONS,
};

export const PRE_REGISTRATION_BETTER_UNDERSTAND_OPTIONS: Array<{
  name: BetterUnderstandOption;
  displayName: string;
}> = [
  { name: BetterUnderstandOption.YES, displayName: 'Yes' },
  { name: BetterUnderstandOption.NO, displayName: 'No' },
];

export const PRE_REGISTRATION_ACCOMPLISHMENT_OPTIONS: Array<{
  name: AccomplishmentOption;
  displayName: string;
}> = [
  { name: AccomplishmentOption.UNDERSTAND_SPENDING, displayName: 'Understand my spending' },
  { name: AccomplishmentOption.SAVE_GOALS, displayName: 'Save for goals or emergencies' },
  { name: AccomplishmentOption.BUDGET_BETTER, displayName: 'Budget better' },
  { name: AccomplishmentOption.PAY_DEBT, displayName: 'Pay off debt' },
  { name: AccomplishmentOption.COLLABORATE_PARTNER, displayName: 'Collaborate with my partner' },
  { name: AccomplishmentOption.REDUCE_EXPENSES, displayName: 'Reduce my expenses' },
];

export const PRE_REGISTRATION_SAVING_GOALS_OPTIONS: Array<{
  name: SavingGoalOption;
  displayName: string;
}> = [
  { name: SavingGoalOption.VACATION, displayName: 'Vacation' },
  { name: SavingGoalOption.HOUSE, displayName: 'House' },
  { name: SavingGoalOption.RETIREMENT, displayName: 'Retirement' },
  { name: SavingGoalOption.EMERGENCY_FUND, displayName: 'Emergency fund' },
  { name: SavingGoalOption.EDUCATION, displayName: 'Education' },
  { name: SavingGoalOption.STARTING_FAMILY, displayName: 'Starting a family' },
  { name: SavingGoalOption.WEDDING, displayName: 'Wedding' },
  { name: SavingGoalOption.FINANCIAL_INDEPENDENCE, displayName: 'Financial independence' },
  { name: SavingGoalOption.SOMETHING_ELSE, displayName: 'Something else' },
];

export const PRE_REGISTRATION_FINANCES_COMPLEXITY_OPTIONS: Array<{
  name: FinancesComplexityOption;
  displayName: string;
}> = [
  { name: FinancesComplexityOption.YES, displayName: 'Yes' },
  { name: FinancesComplexityOption.NO, displayName: 'No' },
];

export const PRE_REGISTRATION_BETTER_UNDERSTAND = {
  id: 'better_understand',
  question: 'Do you want to better understand your personal finances?',
  options: PRE_REGISTRATION_BETTER_UNDERSTAND_OPTIONS,
};

export const PRE_REGISTRATION_ACCOMPLISHMENT = {
  id: 'accomplishment',
  question: 'What do you hope to accomplish with Monarch?',
  options: PRE_REGISTRATION_ACCOMPLISHMENT_OPTIONS,
};

export const PRE_REGISTRATION_MOST_IMPORTANT = {
  id: 'most_important',
  question: "Great, now let's talk priorities.",
  question2: "What's most important to you?",
  options: PRE_REGISTRATION_ACCOMPLISHMENT_OPTIONS,
};

export const PRE_REGISTRATION_SAVING_GOALS = {
  id: 'saving_goals',
  question: 'What are you saving for?',
  options: PRE_REGISTRATION_SAVING_GOALS_OPTIONS,
};

export const PRE_REGISTRATION_FINANCES_COMPLEXITY = {
  id: 'finances_complexity',
  question: 'Do you feel your finances have become more complex over time?',
  options: PRE_REGISTRATION_FINANCES_COMPLEXITY_OPTIONS,
};
