import styled from 'styled-components';

import { onboardingContentContainerMixin } from 'components/onboarding/style';

import boxShadow from 'common/lib/styles/boxShadow';
import { color, spacing } from 'common/lib/theme/dynamic';

export const FOOTER_NAV_HEIGHT = 80;

export const OnboardingFooterNav = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  ${boxShadow.mediumTop}
  padding: ${spacing.default};
  height: ${FOOTER_NAV_HEIGHT}px;
  display: flex;
  justify-content: center;
  background: ${color.white};

  button {
    ${onboardingContentContainerMixin}
    margin-top: 0;

    /* To match outline on OnboardingSurveyRadio & OnboardingSurveyCheckbox */
    :focus-visible {
      outline: 2px solid ${color.blue};
      outline-offset: 4px;
    }
  }
`;

export default OnboardingFooterNav;
