import * as R from 'ramda';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import LinkAccountFlow from 'components/accounts/LinkAccountFlow';
import CheckCircle from 'components/lib/ui/CheckCircle';
import CheckProgressBar from 'components/lib/ui/CheckProgressBar';
import DashboardWidget from 'components/lib/ui/DashboardWidget';
import FlexContainer from 'components/lib/ui/FlexContainer';
import TextButton from 'components/lib/ui/TextButton';

import useDashboardConfig from 'common/lib/hooks/dashboard/useDashboardConfig';
import useOnboardingChecklist, {
  ChecklistItemName,
} from 'common/lib/hooks/dashboard/useOnboardingChecklist';
import { color, spacing, variables } from 'common/lib/theme/dynamic';
import { DashboardWidgetName } from 'common/state/dashboard/types';
import useModal from 'lib/hooks/useModal';

import routes from 'constants/routes';

const Row = styled(FlexContainer).attrs({ alignCenter: true })<{ $checked: boolean }>`
  padding: ${({ theme }) => theme.spacing.large} ${({ theme }) => theme.spacing.large};
  transition: ${({ theme }) => theme.transition.default};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  user-select: none;

  ${({ $checked }) =>
    !$checked &&
    css`
      cursor: pointer;

      :hover {
        background: ${({ theme }) => theme.color.grayBackground};
      }
    `}

  :last-child {
    border-radius: 0 0 ${({ theme }) => theme.radius.medium} ${({ theme }) => theme.radius.medium};
    border-bottom: none;
  }
`;

const Emoji = styled.span`
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const RowTitle = styled.span<{ $checked: boolean }>`
  margin: 0 ${({ theme }) => theme.spacing.default};
  flex: 1;

  ${({ $checked }) =>
    $checked &&
    css`
      text-decoration: line-through;
      color: ${({ theme }) => theme.color.textLight};
    `}
`;

const StyledCheckCircle = styled(CheckCircle).attrs({ iconSizePx: 14 })`
  background: ${color.green};
`;

const Circle = styled.div`
  background: ${({ theme }) => theme.color.grayFocus};
  height: 24px;
  width: 24px;
  border-radius: ${({ theme }) => theme.radius.round};
`;

const ProgressBarContainer = styled.div`
  padding: 0 ${spacing.large};
  padding-bottom: ${spacing.default};
  border-bottom: 1px solid ${variables.color.divider.primary};
`;

const StyledProgressBar = styled(CheckProgressBar)``;

const Footer = styled(FlexContainer)`
  padding: ${({ theme }) => theme.spacing.large} 46px;
`;

const OnboardingDashboardWidget = () => {
  const history = useHistory();

  const { items, titleCopy, isLoadingInitialData, refetch, completedCount } =
    useOnboardingChecklist();

  const [dashboardConfig, updateDashboardConfig] = useDashboardConfig('web');

  const [LinkModal, { open: openLink, close: closeLink }] = useModal();

  const onClickRow = useCallback(
    (itemName: ChecklistItemName) => {
      switch (itemName) {
        case ChecklistItemName.AddAccount:
          openLink();
          break;
        case ChecklistItemName.CustomizeCategories:
          history.push(routes.settings.categories());
          break;
        case ChecklistItemName.CreateGoal:
          history.push(routes.goalsV2());
          break;
        case ChecklistItemName.CreatePlan:
          history.push(routes.budget());
          break;
      }
    },
    [history, openLink],
  );

  const disableWidget = useCallback(() => {
    updateDashboardConfig(
      R.mergeDeepLeft(
        {
          widgets: {
            [DashboardWidgetName.GETTING_STARTED]: {
              enabled: false,
            },
          },
        },
        dashboardConfig,
      ),
      true,
    );
  }, [updateDashboardConfig, dashboardConfig]);

  return (
    <>
      <DashboardWidget
        title="Getting Started"
        description={!isLoadingInitialData && titleCopy}
        headerLink="/accounts"
        loading={isLoadingInitialData}
        showHeaderBorder={false}
      >
        {!isLoadingInitialData && (
          <ProgressBarContainer>
            <StyledProgressBar progress={completedCount / items.length} stepCount={items.length} />
          </ProgressBarContainer>
        )}
        {items.map(({ name, icon, title, completed }) => (
          <Row key={title} $checked={completed} onClick={() => onClickRow(name)}>
            <Emoji>{icon}</Emoji>
            <RowTitle $checked={completed}>{title}</RowTitle>
            {completed ? <StyledCheckCircle /> : <Circle />}
          </Row>
        ))}
        <Footer>
          <TextButton onClick={disableWidget}>Hide this widget</TextButton>
        </Footer>
      </DashboardWidget>
      <LinkModal onClose={refetch}>
        <LinkAccountFlow onClose={closeLink} onComplete={closeLink} />
      </LinkModal>
    </>
  );
};

export default OnboardingDashboardWidget;
