import { useOnboardingSurveyCheckbox } from 'common/hooks/onboarding/useOnboardingSurveyCheckbox';
import React from 'react';
import styled from 'styled-components';

import {
  onboardingSurveyCheckboxIndicatorStyles,
  onboardingSurveyCheckboxIconSettings,
  onboardingSurveyCheckboxIconStyles,
} from 'common/components/onboarding/OnboardingSurveyCheckbox';
import { onboardingSurveySelectIndicatorStyles } from 'common/components/onboarding/OnboardingSurveySelectIndicator';
import Icon from 'components/lib/ui/Icon';
import OnboardingSurveyAnswer from 'components/onboarding/OnboardingSurveyAnswer';
import OnboardingSurveyControlMotion from 'components/onboarding/OnboardingSurveyControlMotion';

const Checkbox = styled.div`
  ${onboardingSurveySelectIndicatorStyles}
  ${onboardingSurveyCheckboxIndicatorStyles}
`;

export const CheckIcon = styled(Icon).attrs(onboardingSurveyCheckboxIconSettings)<{
  isSelected: boolean;
}>`
  ${onboardingSurveyCheckboxIconStyles}
`;

export type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
  children?: React.ReactNode;
  className?: string;
};

const OnboardingSurveyCheckbox = ({ value, onChange, children, className }: Props) => {
  const { onInteract, onKeyDown } = useOnboardingSurveyCheckbox({ value, onChange });

  return (
    <OnboardingSurveyAnswer
      value={value}
      onClick={onInteract}
      onKeyDown={onKeyDown}
      role="checkbox"
      className={className}
    >
      <Checkbox isSelected={value}>
        <OnboardingSurveyControlMotion value={value}>
          <CheckIcon isSelected={value} />
        </OnboardingSurveyControlMotion>
      </Checkbox>

      {children}
    </OnboardingSurveyAnswer>
  );
};

export default OnboardingSurveyCheckbox;
