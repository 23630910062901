import { useMutation } from '@apollo/client';
import * as RA from 'ramda-adjunct';
import React, { useEffect, useState } from 'react';
import type { PlaidLinkOnSuccessMetadata } from 'react-plaid-link';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import LinkInstitutionFailedModal from 'components/accounts/LinkInstitutionFailedModal';
import LinkInstitutionFallbackDataProviderModal from 'components/accounts/LinkInstitutionFallbackDataProviderModal';
import LinkZillowFlow from 'components/accounts/LinkZillowFlow';
import FinicityConnect from 'components/lib/external/FinicityConnect';
import MXConnect from 'components/lib/external/MXConnect';
import type { PlaidLinkOnExitInput } from 'components/lib/external/PlaidLink';
import PlaidLink from 'components/lib/external/PlaidLink';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Modal from 'components/lib/ui/Modal';
import ModalCard from 'components/lib/ui/ModalCard';

import { CREATE_FINICITY_CONNECT_URL_MUTATION } from 'common/lib/graphQl/finicity';
import { GET_INSTITUTION_BY_PROVIDER_ID_QUERY } from 'common/lib/graphQl/institutions';
import { CREATE_MX_CONNECT_URL_MUTATION } from 'common/lib/graphQl/mx';
import { CREATE_PLAID_LINK_TOKEN_MUTATION } from 'common/lib/graphQl/plaid';
import useQuery from 'common/lib/hooks/useQuery';
import { getInstitutionDataProviders } from 'common/lib/institutions/fallback';
import { track } from 'lib/analytics/segment';
import { useModalContext } from 'lib/contexts/ModalContext';
import { getPlaidLinkRedirectUri } from 'lib/external/plaid';
import useCoinbaseOAuth from 'lib/hooks/accounts/useCoinbaseOAuth';
import useTheme from 'lib/hooks/useTheme';

import { IS_LAX_PLAID_CONNECTION_ISSUE_DETECTION_ON } from 'common/constants/accounts';
import { ConnectEventNames } from 'common/constants/analytics';
import { DEFAULT_DATA_PROVIDER } from 'common/constants/institutions';

import type {
  CreateFinicityConnectUrlMutation,
  CreateFinicityConnectUrlMutationVariables,
} from 'common/generated/graphQlTypes/CreateFinicityConnectUrlMutation';
import type {
  CreateMXConnectUrlMutation,
  CreateMXConnectUrlMutationVariables,
} from 'common/generated/graphQlTypes/CreateMXConnectUrlMutation';
import type {
  CreatePlaidLinkTokenMutation,
  CreatePlaidLinkTokenMutationVariables,
} from 'common/generated/graphQlTypes/CreatePlaidLinkTokenMutation';
import type {
  GetInstitutionByProviderId,
  GetInstitutionByProviderIdVariables,
} from 'common/generated/graphQlTypes/GetInstitutionByProviderId';
import type { LinkInstitutionFieldsFragment } from 'common/generated/graphql';
import { DataProviderLegacy } from 'common/generated/graphql';

const PLAID_INVALID_LINK_CUSTOMIZATION_ERROR = 'INVALID_FIELD';
const PLAID_LINK_INVALID_CREDENTIALS = 'INVALID_CREDENTIALS';

const CenterContainer = styled(FlexContainer).attrs({ center: true })`
  height: 200px;
`;

type InstitutionFields = Pick<
  LinkInstitutionFieldsFragment,
  'preferredDataProvider' | 'finicityInstitutionId' | 'plaidInstitutionId' | 'mxInstitutionId'
> & {
  name?: string;
  id?: string;
};

export type LinkAccountSuccessMetadata = {
  dataProvider?: DataProviderLegacy;
  institution?: InstitutionFields;
  plaidLinkToken?: string;
  plaidLinkMetadata?: PlaidLinkOnSuccessMetadata;
  coinbaseCode?: string;
  accountIds?: string[];
};

enum DataProviderExitConditions {
  ConnectivityIssue = 'Connectivity Issue',
  NonConnectivityIssue = 'Non-Connectivity Issue',
}

export type Props = {
  institution?: InstitutionFields;
  dataProvider?: DataProviderLegacy;
  /** Set this after plaid oauth redirect */
  cachedPlaidLinkToken?: string;
  /** Set this after plaid oauth redirect */
  plaidReceivedRedirectUri?: string;
  next: (params: { metadata: LinkAccountSuccessMetadata }) => void;
  goBack?: () => void;
};

const LinkAccountDataProviderModalWithFallback = ({
  institution,
  dataProvider = institution?.preferredDataProvider ?? DEFAULT_DATA_PROVIDER,
  cachedPlaidLinkToken,
  plaidReceivedRedirectUri,
  next,
  goBack,
}: Props) => {
  const { plaidInstitutionId, finicityInstitutionId, mxInstitutionId } = institution ?? {};
  const { close } = useModalContext();
  const theme = useTheme();
  const [dataProviderExitCondition, setDataProviderExitCondition] = useState<
    DataProviderExitConditions | undefined
  >();

  const [plaidLinkError, setPlaidLinkError] = useState<PlaidLinkOnExitInput | undefined>();

  const [offerFallbackDataProvider, setOfferFallbackDataProvider] = useState<boolean>(false);

  const { data: fullInstitutionData, isLoadingInitialData: isLoadingFullInstitution } = useQuery<
    GetInstitutionByProviderId,
    GetInstitutionByProviderIdVariables
  >(GET_INSTITUTION_BY_PROVIDER_ID_QUERY, {
    variables: {
      id: institution?.id,
      plaidId: plaidInstitutionId ?? '',
      finicityId: finicityInstitutionId ?? '',
      mxId: mxInstitutionId ?? null,
    },
    skip: !institution?.id && !plaidInstitutionId && !finicityInstitutionId && !mxInstitutionId,
  });

  const fullInstitution = fullInstitutionData?.institution ?? undefined;
  const { dataProviderPriority0, fallbackInstitutionDataProvider } = getInstitutionDataProviders(
    fullInstitution,
    dataProvider,
  );

  const [createLinkToken, { data: plaidData }] = useMutation<
    CreatePlaidLinkTokenMutation,
    CreatePlaidLinkTokenMutationVariables
  >(CREATE_PLAID_LINK_TOKEN_MUTATION);
  const { linkToken: fetchedLinkToken, error: plaidError } = plaidData?.createPlaidLinkToken ?? {};
  const linkToken = cachedPlaidLinkToken ?? fetchedLinkToken;

  const [createFinicityConnectUrl, { data: finicityData }] = useMutation<
    CreateFinicityConnectUrlMutation,
    CreateFinicityConnectUrlMutationVariables
  >(CREATE_FINICITY_CONNECT_URL_MUTATION);
  const { url: finicityConnectUrl, errors: finicityErrors } =
    finicityData?.createFinicityConnectUrl ?? {};

  const [createMxConnectUrl, { data: mxData }] = useMutation<
    CreateMXConnectUrlMutation,
    CreateMXConnectUrlMutationVariables
  >(CREATE_MX_CONNECT_URL_MUTATION);
  const { url: mxConnectUrl, errors: mxErrors } = mxData?.createMxConnectUrl ?? {};

  const fetchDataProviderMutation = () => {
    if (dataProvider === DataProviderLegacy.FINICITY) {
      return createFinicityConnectUrl({
        variables: { finicityInstitutionId: finicityInstitutionId ?? '' },
      });
    } else if (dataProvider === DataProviderLegacy.PLAID) {
      return createLinkToken({
        variables: {
          plaidInstitutionId,
          redirectUri: getPlaidLinkRedirectUri(),
        },
      });
    } else if (dataProvider === DataProviderLegacy.MX) {
      return createMxConnectUrl({
        variables: {
          mxInstitutionId: mxInstitutionId ?? '',
          isDarkMode: theme.uiTheme === 'dark',
        },
      });
    }
  };

  const onCloseCallback = (hasConnectivityIssue?: boolean): void => {
    setDataProviderExitCondition(
      hasConnectivityIssue
        ? DataProviderExitConditions.ConnectivityIssue
        : DataProviderExitConditions.NonConnectivityIssue,
    );
  };

  useEffect(() => {
    if (!plaidReceivedRedirectUri) {
      fetchDataProviderMutation();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { openCoinbaseConnect, hasCode } = useCoinbaseOAuth({
    onSuccess: (code) =>
      next({
        metadata: {
          dataProvider,
          institution,
          coinbaseCode: code,
        },
      }),
  });

  useEffect(() => {
    if (dataProvider === DataProviderLegacy.COINBASE && !hasCode) {
      openCoinbaseConnect();
    }
  }, [dataProvider, hasCode]);

  useEffect(() => {
    fullInstitution?.id &&
      track(ConnectEventNames.ConnectInstitutionSelected, {
        institution_id: fullInstitution.id,
        institution_name: fullInstitution.name,
        data_provider: dataProvider,
        data_provider_priority: dataProviderPriority0,
      });
  }, [dataProvider, fullInstitution?.id]);

  useEffect(() => {
    // This useEffect triggers the fallback flow. It's a noop unless we have a provider
    // exit condition and the fullInstitution is finished loading.
    // If we're exiting and there wasn't a connectivity issue, or we don't have a fallback,
    // we'll goBack() or close().
    if (!dataProviderExitCondition) {
      return;
    }
    if (isLoadingFullInstitution) {
      return;
    }
    const hasFallbackProviderOption = !!fallbackInstitutionDataProvider;
    const encounteredConnectivityIssue =
      dataProviderExitCondition === DataProviderExitConditions.ConnectivityIssue;
    const offerFallback = encounteredConnectivityIssue && hasFallbackProviderOption;
    setOfferFallbackDataProvider(offerFallback);

    if (!offerFallback) {
      goBack ? goBack() : close();
    }
  }, [dataProviderExitCondition, fallbackInstitutionDataProvider, isLoadingFullInstitution]);

  useEffect(() => {
    // If we encounter errors creating the connect url/link token,
    // consider it a connectivity issue right away to potentially offer the fallback.
    // Todo: if fallback isn't available and the provider gives us an error, the modal
    // will just close. This UX could be improved.
    if (!!plaidError || !!finicityErrors || !!mxErrors) {
      onCloseCallback(true);
    }
  }, [plaidError, finicityErrors, mxErrors]);

  return (
    <Switch>
      <Case when={offerFallbackDataProvider}>
        {fallbackInstitutionDataProvider ? (
          <LinkInstitutionFallbackDataProviderModal
            onClose={goBack}
            institution={{
              id: institution?.id,
              name: institution?.name,
            }}
            currentDataProvider={dataProvider}
            dataProviderPriority0={dataProviderPriority0}
            fallbackInstitutionDataProvider={fallbackInstitutionDataProvider}
          />
        ) : null}
      </Case>
      <Case when={!!plaidError || !!finicityErrors || !!mxErrors || !!plaidLinkError}>
        <LinkInstitutionFailedModal
          errorMessage={plaidLinkError?.error?.display_message}
          plaidInstitutionId={
            // Status information only applies for Plaid, so don't show if linking with Finicity or MX
            dataProvider === DataProviderLegacy.PLAID
              ? plaidLinkError?.metadata.institution?.institution_id
              : undefined
          }
          onRetry={
            cachedPlaidLinkToken
              ? undefined
              : () => {
                  fetchDataProviderMutation();
                  setPlaidLinkError(undefined);
                }
          }
          onClose={close}
        />
      </Case>
      <Case when={dataProvider === DataProviderLegacy.ZILLOW}>
        <LinkZillowFlow onBack={goBack} onComplete={(metadata) => next({ metadata })} />
      </Case>
      <Case when={dataProvider === DataProviderLegacy.FINICITY}>
        <Modal onClose={goBack}>
          <FinicityConnect
            institution={{
              id: institution?.id,
              name: institution?.name,
            }}
            connectUrl={finicityConnectUrl ?? undefined}
            onClose={onCloseCallback}
            onSuccess={() => {
              next({
                metadata: { dataProvider, institution },
              });
            }}
          />
        </Modal>
      </Case>
      <Case when={dataProvider === DataProviderLegacy.MX}>
        <Modal onClose={goBack}>
          <MXConnect
            connectUrl={mxConnectUrl ?? undefined}
            onClose={onCloseCallback}
            onSuccess={() => {
              next({
                metadata: { dataProvider, institution },
              });
            }}
          />
        </Modal>
      </Case>
      <Case when={RA.isNotNil(linkToken)}>
        <PlaidLink
          key={linkToken}
          token={linkToken ?? ''}
          receivedRedirectUri={plaidReceivedRedirectUri}
          onClose={onCloseCallback}
          onSuccess={({ token, metadata }) =>
            next({
              metadata: {
                dataProvider,
                institution,
                plaidLinkToken: token,
                plaidLinkMetadata: metadata,
              },
            })
          }
          onError={(error) => {
            if (error.error?.error_code === PLAID_INVALID_LINK_CUSTOMIZATION_ERROR) {
              // The linkCustomizationName we used was invalid, so we generate a new link token with the default customization
              createLinkToken();
            } else {
              setPlaidLinkError(error);
              const hasConnectivityIssue = IS_LAX_PLAID_CONNECTION_ISSUE_DETECTION_ON
                ? true
                : error.error?.error_code !== PLAID_LINK_INVALID_CREDENTIALS;
              if (hasConnectivityIssue) {
                onCloseCallback(hasConnectivityIssue);
              }
            }
          }}
          laxConnectionIssueDetection={IS_LAX_PLAID_CONNECTION_ISSUE_DETECTION_ON}
        />
      </Case>
      <Case default>
        <ModalCard hideCloseButton>
          <CenterContainer>
            <LoadingSpinner />
          </CenterContainer>
        </ModalCard>
      </Case>
    </Switch>
  );
};

export default LinkAccountDataProviderModalWithFallback;
