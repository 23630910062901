import { darken, ellipsis, transparentize } from 'polished';
import styled, { css } from 'styled-components';

import Card from 'components/lib/ui/Card';
import Drawer from 'components/lib/ui/Drawer';
import FlexContainer from 'components/lib/ui/FlexContainer';

import { variables } from 'common/lib/theme/dynamic';
import fakeCornersMixin from 'lib/styles/fakeCornersMixin';

export const LEFT_WIDTH_PX = 328;
export const SECTION_HEADER_HEIGHT_PX = 48;
export const ROW_HEIGHT_PX = 56;
export const COLUMN_WIDTH_PX = 144;
export const SECTION_HEADER_Z_INDEX = 4;
export const GROUP_LEFT_Z_INDEX = 3;
export const GROUP_HEADER_Z_INDEX = 2;
export const PROGRESS_BAR_Z_INDEX = 1;
export const ALL_PLANNED_SECTION_PADDING_PX = 12;
export const INPUT_HEIGHT_PX = 32;

export const PlanGridGroup = styled(Card)`
  margin-bottom: ${({ theme }) => theme.spacing.small};
  background: ${({ theme }) => theme.color.white};
`;

export const PlanGridRow = styled(FlexContainer)<{
  $grayBackground?: boolean;
  $hasBottomBorder?: boolean;
  $height?: number;
}>`
  height: ${({ $height = ROW_HEIGHT_PX }) => $height}px;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacing.xlarge};

  :not(:first-child) {
    border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
  }

  ${({ $height }) =>
    $height &&
    $height !== ROW_HEIGHT_PX &&
    css`
      padding-top: ${({ theme }) => theme.spacing.small};
      align-items: flex-start;
    `}

  ${({ $hasBottomBorder }) =>
    $hasBottomBorder
      ? css`
          :last-child {
            border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
          }
        `
      : ''}

  ${({ $grayBackground }) =>
    $grayBackground &&
    css`
      background: ${({ theme }) => theme.color.grayBackground};
      &&&& {
        border-top-color: ${({ theme }) => theme.color.grayFocus};
      }
      & + & {
        border-top-color: ${({ theme }) => theme.color.grayFocus};
      }
    `}
`;

export const PlanGridColumn = styled(FlexContainer).attrs({
  column: true,
  alignEnd: true,
  justifyCenter: true,
})<{
  width?: number;
}>`
  width: ${({ width }) => width ?? COLUMN_WIDTH_PX}px;
  min-height: ${INPUT_HEIGHT_PX}px;
`;

export const LeftSticky = styled.div`
  position: sticky;
  left: 0;
  width: ${LEFT_WIDTH_PX}px;
  background: ${variables.color.background.page};
`;

export const LeftColumn = styled(LeftSticky)<{
  $hasBottomPadding?: boolean;
  $backgroundColor?: string;
}>`
  ${({ theme }) => fakeCornersMixin({ theme, topLeft: true, bottomLeft: true })};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: ${GROUP_LEFT_Z_INDEX}; /* stylelint-disable-line plugin/no-z-index */
  padding-bottom: ${({ $hasBottomPadding }) =>
    $hasBottomPadding ? ALL_PLANNED_SECTION_PADDING_PX : 0}px;

  ::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 100%;
    top: 0;
    right: -8px;
    ${({ theme, $backgroundColor }) =>
      $backgroundColor
        ? css`
            background: linear-gradient(
              90deg,
              ${transparentize(0.05, darken(0.05, $backgroundColor))} 0%,
              ${transparentize(1, $backgroundColor)} 100%
            );
          `
        : css`
            background: linear-gradient(
              90deg,
              ${transparentize(0.6, theme.color.grayBackground)} 0%,
              ${transparentize(1, theme.color.grayBackground)} 100%
            );
          `}
  }
`;

export const HeaderText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.large};
  ${ellipsis()}
`;

export const GroupHeaderRow = styled(PlanGridRow)`
  position: sticky;
  top: ${SECTION_HEADER_HEIGHT_PX}px;
  overflow: hidden;
  z-index: ${GROUP_HEADER_Z_INDEX}; /* stylelint-disable-line plugin/no-z-index */
`;

export const PlanGroupDrawer = styled(Drawer)`
  ${PlanGridRow}:first-child {
    border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
  }
`;
