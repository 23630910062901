import * as R from 'ramda';

import type { PayloadErrorFieldsFragment } from 'common/generated/graphql';

const convertGraphQlErrorsToRestFormat = (
  errors: PayloadErrorFieldsFragment['fieldErrors'],
): { [field: string]: string[] } => {
  const byField = R.indexBy(({ field }) => field, errors ?? []);
  return R.map(({ messages }) => messages, byField);
};

export default convertGraphQlErrorsToRestFormat;
