import * as R from 'ramda';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import type { Props as FormProps } from 'common/components/form/BaseForm';
import Form from 'components/lib/form/Form';
import Column from 'components/lib/ui/Column';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Row from 'components/lib/ui/Row';
import Text from 'components/lib/ui/Text';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import SplitFormValidationRow from 'components/transactions/SplitFormValidationRow';
import TransactionSplitFormFooter from 'components/transactions/TransactionSplitFormFooter';
import FormSplitContainer from 'components/transactions/splits/FormSplitContainer';

import { throwIfHasMutationErrors } from 'common/lib/form/errors';
import useSplitTransactionForm from 'common/lib/hooks/transactions/useSplitTransactionForm';
import { spacing } from 'common/lib/theme/dynamic';
import type { FormValues } from 'common/lib/transactions/Splits';
import {
  transformSplitPercentagesToAbsoluteAmounts,
  validateFormValues,
  invertSplitAmounts,
} from 'common/lib/transactions/Splits';
import { isIncome } from 'common/utils/formatTransactionAmount';
import { useTransactionRuleToastContext } from 'lib/contexts/TransactionRuleToastContext';

import type {
  Common_SplitTransactionMutation,
  Common_SplitTransactionMutationDocument,
  Common_TransactionSplitQuery,
  TransactionSplitInputData,
} from 'common/generated/graphql';
import { SplitAmountType } from 'common/generated/graphql';
import type { MutationFunctionFromDocument } from 'common/types/graphql';
import type { Split } from 'types/splits';

/** Only show delete button for splits in excess of this amount */
export const NUM_REQUIRED_SPLITS = 2;
export const COLUMN_GUTTER_PX = 40;

const StyledFormSplitContainer = styled(FormSplitContainer)`
  margin: ${spacing.xsmall} ${spacing.xlarge};
`;

const EmptySplitsRow = styled(Row)`
  padding: ${spacing.xsmall} ${spacing.xlarge};
`;

const StyledSplitFormValidationRow = styled(SplitFormValidationRow)`
  padding: ${spacing.xsmall} ${spacing.xlarge};
`;

type InnerFormProps = {
  originalTransaction: Common_TransactionSplitQuery['getTransaction'];
  onCancel: () => void;
  isEditMode: boolean;
  amountType: SplitAmountType;
  isLoading?: boolean;
};

const InnerForm = ({
  originalTransaction,
  onCancel,
  isEditMode,
  amountType,
  isLoading,
}: InnerFormProps) => {
  const { splits, adjustSplit, addSplit, removeSplit, removeAllSplits, amountLeftToSplit } =
    useSplitTransactionForm({
      originalTransaction,
      amountType,
    });

  const defaultSplitsExpanded = isEditMode
    ? Array(splits.length).fill(false) // Start all splits as collapsed in edit mode
    : splits.map((_, i) => i === 0); // Expand the first split by default in create mode

  const [areSplitsExpanded, setAreSplitsExpanded] = useState<boolean[]>(defaultSplitsExpanded);

  const toggleSplitExpanded = useCallback(
    (index: number) => () => {
      setAreSplitsExpanded(R.adjust(index, R.not, areSplitsExpanded));
    },
    [setAreSplitsExpanded, areSplitsExpanded],
  );

  const addFormSplit = useCallback(() => {
    setAreSplitsExpanded([...areSplitsExpanded, false]);
    return addSplit();
  }, [areSplitsExpanded, setAreSplitsExpanded, addSplit]);

  const removeFormSplit = useCallback(
    (index: number) => () => {
      setAreSplitsExpanded(R.remove(index, 1, areSplitsExpanded));
      return removeSplit(index)();
    },
    [areSplitsExpanded, setAreSplitsExpanded, removeSplit],
  );

  const adjustFormSplit = useCallback(
    (index: number) => async (data: Split) => adjustSplit(index)(data as TransactionSplitInputData),
    [adjustSplit],
  );

  const removeAllFormSplits = useCallback(() => {
    setAreSplitsExpanded([]);
    return removeAllSplits();
  }, [setAreSplitsExpanded, removeAllSplits]);

  return (
    <>
      {splits.length === 0 && (
        <EmptySplitsRow>
          <Column xs={12}>
            <FlexContainer justifyBetween alignCenter>
              <Text italic>All splits have been removed</Text>
              <DefaultButton onClick={addFormSplit}>
                <ButtonIcon name="plus-circle" size={12} />
                <Text size="small">Add a split</Text>
              </DefaultButton>
            </FlexContainer>
          </Column>
        </EmptySplitsRow>
      )}
      {splits.map((split, index) => (
        <StyledFormSplitContainer
          key={index}
          absoluteAmount={amountType === SplitAmountType.ABSOLUTE}
          expanded={areSplitsExpanded[index]}
          transactionId={originalTransaction?.id}
          formFieldPrefix={`splitData[${index}]`}
          split={split}
          onAdjustSplit={adjustFormSplit(index)}
          onRemoveSplit={removeFormSplit(index)}
          onToggleSplitExpanded={toggleSplitExpanded(index)}
        />
      ))}
      {splits.length > 0 && (
        <StyledSplitFormValidationRow
          onAddFormSplit={addFormSplit}
          amountLeftToSplit={amountLeftToSplit}
          isPercentage={amountType === SplitAmountType.PERCENTAGE}
        />
      )}
      <TransactionSplitFormFooter
        splitCount={splits.length}
        onRemoveAllSplits={removeAllFormSplits}
        onCancel={onCancel}
        isEditMode={isEditMode}
        isLoading={isLoading}
      />
    </>
  );
};

type Props = {
  /** Amount that splits must add up to */
  originalTransaction: Common_TransactionSplitQuery['getTransaction'];
  isEditMode: boolean;
  amountType: SplitAmountType;
  initialValues: FormValues;
  onCancel: () => void;
  onSubmitSuccess: () => void;
  mutation: MutationFunctionFromDocument<typeof Common_SplitTransactionMutationDocument>;
} & Pick<
  FormProps<FormValues, Common_SplitTransactionMutation, any>,
  'transformValuesBeforeSubmit'
>;

const TransactionSplitForm = ({
  originalTransaction,
  isEditMode,
  amountType,
  initialValues,
  onCancel,
  onSubmitSuccess,
  mutation,
  ...formProps
}: Props) => {
  const { showToast } = useTransactionRuleToastContext();
  const { amount = 0 } = originalTransaction ?? {};

  const [isLoading, setIsLoading] = useState(false);

  return (
    <Form
      {...formProps}
      initialValues={initialValues}
      validate={validateFormValues(Math.abs(originalTransaction?.amount ?? 0), amountType)}
      onSubmit={async (values) => {
        setIsLoading(true);

        let transformedValues = R.clone(values);

        if (amountType === SplitAmountType.PERCENTAGE) {
          transformedValues.splitData = transformSplitPercentagesToAbsoluteAmounts(
            transformedValues.splitData,
            Math.abs(originalTransaction?.amount ?? 0),
          );
        }

        if (!isIncome(amount)) {
          transformedValues = invertSplitAmounts(transformedValues);
        }

        const { data } = await mutation?.({ variables: { input: transformedValues } });

        if (R.isNil(data)) {
          return;
        }

        throwIfHasMutationErrors(data);

        const { updateTransactionSplit } = data;
        const { transaction } = updateTransactionSplit ?? {};
        if (transaction?.hasSplitTransactions) {
          showToast({
            merchantName: originalTransaction?.merchant?.name,
            categoryId: originalTransaction?.category?.id,
            amountEquals: originalTransaction?.amount,
            newSplits: transaction?.splitTransactions.map((split) => ({
              amount: Math.abs(split.amount), // Always pass along unsigned values to rule
              hideFromReports: split.hideFromReports,
              merchantName: split.merchant?.name,
              categoryId: split.category?.id,
              goalId: split.goal?.id,
              reviewStatus: split.reviewStatus,
              needsReviewByUserId: split.needsReviewByUser?.id,
              tags: split.tags.map(({ id }) => id),
            })),
          });
        }
      }}
      onSubmitSuccess={onSubmitSuccess}
      onError={() => setIsLoading(false)}
    >
      <InnerForm
        originalTransaction={originalTransaction}
        onCancel={onCancel}
        isEditMode={isEditMode}
        amountType={amountType}
        isLoading={isLoading}
      />
    </Form>
  );
};

export default TransactionSplitForm;
