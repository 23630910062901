import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { ButtonGroup } from 'components/lib/ui/ButtonGroup';
import DividerLine from 'components/lib/ui/DividerLine';
import DropdownMenu, { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import OverlayTrigger from 'components/lib/ui/popover/OverlayTrigger';

import { ENTITY_TO_LABEL } from 'common/lib/reports';
import type { SankeyGroupMode } from 'lib/cashFlow/sankey';
import { TIMEFRAME_TO_LABEL, VIEW_MODE_TO_LABEL } from 'lib/reports';
import { SANKEY_GROUP_MODE_TO_LABEL } from 'lib/reports/sankey';
import type { TabDisplayProperties } from 'state/reports/types';
import { ReportsChart } from 'state/reports/types';

import type { ReportsGroupByEntity, ReportsGroupByTimeframe } from 'common/generated/graphql';

const Root = styled(FlexContainer).attrs({ gap: 'small', alignStretch: true })`
  height: 36px;
`;

const Rotate = styled.div`
  transform: rotate(90deg);
`;

const ChevronDownIcon = styled(Icon).attrs({ name: 'chevron-down', size: 12 })`
  transform: translateY(0px);
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

const ButtonGroupIcon = styled(Icon).attrs({ size: 16 })``;

const Menu = styled(DropdownMenu)`
  min-width: 222px;
`;

/**
 * Spending / income view mode buttons. i.e. "Total amounts" or "Change over time"
 */
const SpendingIncomeViewModeButtonGroup = ({
  viewMode,
  onChangeViewMode,
}: {
  viewMode?: TabDisplayProperties['viewMode'];
  onChangeViewMode: (viewMode: string) => void;
}) => (
  <ButtonGroup>
    {Object.entries(VIEW_MODE_TO_LABEL).map(([value, label]) => (
      <DefaultButton
        key={value}
        onClick={() => onChangeViewMode?.(value)}
        active={value === viewMode}
      >
        {label}
      </DefaultButton>
    ))}
  </ButtonGroup>
);

/**
 * Spending / income reports chart type buttons (pie chart, bar chart)
 */
const SpendingIncomeChartTypeButtonGroup = ({
  chartType,
  onChangeChartType,
}: {
  chartType: ReportsChart | undefined;
  onChangeChartType: (chartType: ReportsChart) => void;
}) => (
  <ButtonGroup>
    <DefaultButton
      onClick={() => onChangeChartType(ReportsChart.PieChart)}
      active={chartType === ReportsChart.PieChart}
    >
      <ButtonGroupIcon name="pie-chart" />
    </DefaultButton>
    <DefaultButton
      onClick={() => onChangeChartType(ReportsChart.HorizontalBarChart)}
      active={chartType === ReportsChart.HorizontalBarChart}
    >
      <Rotate>
        <ButtonGroupIcon name="bar-chart" />
      </Rotate>
    </DefaultButton>
  </ButtonGroup>
);

/**
 * Spending / income time-based reports ("Change over time") chart type buttons.
 */
const SpendingIncomeTimeBasedChartTypeButtonGroup = ({
  chartType,
  onChangeChartType,
}: {
  chartType: ReportsChart | undefined;
  onChangeChartType: (chartType: ReportsChart) => void;
}) => (
  <ButtonGroup>
    <DefaultButton
      onClick={() => onChangeChartType(ReportsChart.BarChart)}
      active={chartType === ReportsChart.BarChart}
    >
      <ButtonGroupIcon name="bar-chart" />
    </DefaultButton>
    <DefaultButton
      onClick={() => onChangeChartType(ReportsChart.StackedBarChart)}
      active={chartType === ReportsChart.StackedBarChart}
    >
      <ButtonGroupIcon name="bar-chart-2" />
    </DefaultButton>
  </ButtonGroup>
);

/**
 * Timeframes dropdown for time-based reports ("Change over time" or cashflow)
 */
const TimeframesDropdown = ({
  timeframe,
  onChangeTimeframe,
}: {
  timeframe: ReportsGroupByTimeframe;
  onChangeTimeframe: (timeframe: ReportsGroupByTimeframe) => void;
}) => (
  <OverlayTrigger
    placement="bottom-end"
    overlay={
      <Menu>
        {Object.entries(TIMEFRAME_TO_LABEL).map(([value, label]) => (
          <DropdownMenuItem
            key={value}
            onClick={() => onChangeTimeframe(value as ReportsGroupByTimeframe)}
            selected={value === timeframe}
          >
            {label}
          </DropdownMenuItem>
        ))}
      </Menu>
    }
  >
    {({ toggleOpen, isOpen }) => (
      <DefaultButton onClick={toggleOpen} active={isOpen}>
        {timeframe ? TIMEFRAME_TO_LABEL[timeframe] : ''} <ChevronDownIcon />
      </DefaultButton>
    )}
  </OverlayTrigger>
);

/**
 * Cash flow sankey chart group mode dropdown
 */
const CashFlowSankeyGroupModeDropdown = ({
  groupMode = 'both',
  onChangeSankeyGroupMode,
}: {
  groupMode?: Maybe<SankeyGroupMode>;
  onChangeSankeyGroupMode: (mode: SankeyGroupMode) => void;
}) => (
  <OverlayTrigger
    placement="bottom-end"
    overlay={
      <Menu>
        {Object.entries(SANKEY_GROUP_MODE_TO_LABEL).map(([value, label]) => (
          <DropdownMenuItem
            key={value}
            onClick={() => onChangeSankeyGroupMode(value as SankeyGroupMode)}
            selected={value === groupMode}
          >
            {label}
          </DropdownMenuItem>
        ))}
      </Menu>
    }
  >
    {({ toggleOpen, isOpen }) => (
      <DefaultButton onClick={toggleOpen} active={isOpen}>
        {groupMode ? SANKEY_GROUP_MODE_TO_LABEL[groupMode] : ''} <ChevronDownIcon />
      </DefaultButton>
    )}
  </OverlayTrigger>
);

const SpendingIncomeGroupModeDropdown = ({
  groupBy,
  onChangeGroupBy,
}: {
  groupBy: ReportsGroupByEntity | undefined;
  onChangeGroupBy: (groupBy: ReportsGroupByEntity) => void;
}) => (
  <OverlayTrigger
    placement="bottom-end"
    overlay={
      <Menu>
        {Object.entries(ENTITY_TO_LABEL).map(([value, label]) => (
          <DropdownMenuItem
            key={value}
            onClick={() => onChangeGroupBy(value as ReportsGroupByEntity)}
            selected={value === groupBy}
          >
            By {label}
          </DropdownMenuItem>
        ))}
      </Menu>
    }
  >
    {({ toggleOpen, isOpen }) => (
      <DefaultButton onClick={toggleOpen} active={isOpen}>
        {groupBy ? `By ${ENTITY_TO_LABEL[groupBy]}` : 'Group by'} <ChevronDownIcon />
      </DefaultButton>
    )}
  </OverlayTrigger>
);

/**
 * Cash flow reports chart type buttons (bar chart, stacked bar chart, sankey)
 */
const CashFlowChartTypeButtonGroup = ({
  chartType,
  onChangeChartType,
}: {
  chartType: ReportsChart | undefined;
  onChangeChartType: (chartType: ReportsChart) => void;
}) => (
  <ButtonGroup>
    <DefaultButton
      onClick={() => onChangeChartType(ReportsChart.SankeyCashFlowChart)}
      active={chartType === ReportsChart.SankeyCashFlowChart}
    >
      <ButtonGroupIcon name="sankey" />
    </DefaultButton>
    <DefaultButton
      onClick={() => onChangeChartType(ReportsChart.CashFlowChart)}
      active={chartType === ReportsChart.CashFlowChart}
    >
      <ButtonGroupIcon name="bar-chart" />
    </DefaultButton>
    <DefaultButton
      onClick={() => onChangeChartType(ReportsChart.StackedCashFlowChart)}
      active={chartType === ReportsChart.StackedCashFlowChart}
    >
      <ButtonGroupIcon name="bar-chart-2" />
    </DefaultButton>
  </ButtonGroup>
);

type Props = {
  isChartEmpty: boolean;
  chartType: ReportsChart | undefined;
  onChangeChartType: (chartType: ReportsChart) => void;
  viewMode?: TabDisplayProperties['viewMode'];
  onChangeViewMode: (viewMode: string) => void;
  spendingIncomeGroupBy: ReportsGroupByEntity | undefined;
  onChangeSpendingIncomeGroupBy: (groupBy: ReportsGroupByEntity) => void;
  sankeyGroupMode?: TabDisplayProperties['groupMode'];
  onChangeSankeyGroupMode: (mode: SankeyGroupMode) => void;
  groupByTimeframe: ReportsGroupByTimeframe;
  onChangeGroupByTimeframe: (timeframe: ReportsGroupByTimeframe) => void;
  onClickShare: () => void;
};

const ReportsChartCardControls = ({
  isChartEmpty,
  chartType,
  onChangeChartType,
  viewMode,
  onChangeViewMode,
  spendingIncomeGroupBy,
  onChangeSpendingIncomeGroupBy,
  sankeyGroupMode,
  onChangeSankeyGroupMode,
  groupByTimeframe,
  onChangeGroupByTimeframe,
  onClickShare,
}: Props) => {
  const isChartTypeOneOf = useCallback(
    (types: ReportsChart[]) => chartType && types.includes(chartType),
    [chartType],
  );

  const sectionsToRender = useMemo(
    () =>
      [
        // Timeframes (Daily, Weekly, Monthly, Quarterly, Yearly) button group
        isChartTypeOneOf([
          ReportsChart.CashFlowChart,
          ReportsChart.StackedCashFlowChart,
          ReportsChart.BarChart,
          ReportsChart.StackedBarChart,
        ]) && (
          <TimeframesDropdown
            timeframe={groupByTimeframe}
            onChangeTimeframe={onChangeGroupByTimeframe}
          />
        ),
        // Spending / income group by dropdown
        spendingIncomeGroupBy && (
          <SpendingIncomeGroupModeDropdown
            groupBy={spendingIncomeGroupBy}
            onChangeGroupBy={onChangeSpendingIncomeGroupBy}
          />
        ),
        // Spending / income view mode buttons. i.e. "Total amounts" or "Change over time"
        viewMode && (
          <>
            <DividerLine />
            <SpendingIncomeViewModeButtonGroup
              viewMode={viewMode}
              onChangeViewMode={onChangeViewMode}
            />
          </>
        ),
        // Spending / income reports chart type buttons (pie chart, bar chart)
        isChartTypeOneOf([ReportsChart.PieChart, ReportsChart.HorizontalBarChart]) && (
          <SpendingIncomeChartTypeButtonGroup
            chartType={chartType}
            onChangeChartType={onChangeChartType}
          />
        ),
        // Spending / income time-based reports ("Change over time") chart type buttons.
        isChartTypeOneOf([ReportsChart.StackedBarChart, ReportsChart.BarChart]) && (
          <>
            {!viewMode && <DividerLine />}
            <SpendingIncomeTimeBasedChartTypeButtonGroup
              chartType={chartType}
              onChangeChartType={onChangeChartType}
            />
          </>
        ),
        // Cash flow sankey chart group mode dropdown
        isChartTypeOneOf([ReportsChart.SankeyCashFlowChart]) && (
          <CashFlowSankeyGroupModeDropdown
            groupMode={sankeyGroupMode}
            onChangeSankeyGroupMode={onChangeSankeyGroupMode}
          />
        ),
        // Cash flow reports chart type buttons (bar chart, stacked bar chart, sankey)
        isChartTypeOneOf([
          ReportsChart.CashFlowChart,
          ReportsChart.StackedCashFlowChart,
          ReportsChart.SankeyCashFlowChart,
        ]) && (
          <>
            <DividerLine />
            <CashFlowChartTypeButtonGroup
              chartType={chartType}
              onChangeChartType={onChangeChartType}
            />
          </>
        ),
        // Share button
        <>
          <DividerLine />
          <DefaultButton onClick={onClickShare} disabled={isChartEmpty}>
            <Icon name="share" size={12} />
          </DefaultButton>
        </>,
      ].filter(React.isValidElement),
    [
      isChartTypeOneOf,
      groupByTimeframe,
      onChangeGroupByTimeframe,
      spendingIncomeGroupBy,
      onChangeSpendingIncomeGroupBy,
      viewMode,
      onChangeViewMode,
      chartType,
      onChangeChartType,
      sankeyGroupMode,
      onChangeSankeyGroupMode,
      isChartEmpty,
      onClickShare,
    ],
  );

  return (
    <Root>
      {sectionsToRender.map((section, index) => (
        <React.Fragment key={index}>{section}</React.Fragment>
      ))}
    </Root>
  );
};

export default ReportsChartCardControls;
