import { camelCase } from 'change-case';
import { DateTime } from 'luxon';
import { pathOr, descend, sortWith, prop, omit, pick, path, isNil } from 'ramda';
import { isArray, isNotNil, isNotNilOrEmpty } from 'ramda-adjunct';

import type { ReportsEntityTypes, ReportRow, ReportsSummaryKey } from 'common/lib/reports/types';
import type lightPalette from 'common/lib/theme/lightPalette';
import { ensureEnumValue } from 'common/utils/Enum';
import { withPercent } from 'common/utils/Number';
import { clampDate, isSameDay } from 'common/utils/date';

import {
  ReportsGroupByEntity,
  ReportsGroupByTimeframe,
  CategoryGroupType,
} from 'common/generated/graphql';
import type {
  Maybe,
  Common_GetReportsDataQuery,
  ReportsSummaryFieldsFragment,
  Common_GetReportsDataQueryVariables,
  TransactionFilterInput,
} from 'common/generated/graphql';
import type { OmitTypename } from 'common/types/utility';

export const DEFAULT_MAX_VISIBLE_ENTITIES = 12;

export const ENTITY_TO_LABEL: Record<ReportsGroupByEntity, string> = {
  [ReportsGroupByEntity.CATEGORY]: 'category',
  [ReportsGroupByEntity.CATEGORY_GROUP]: 'group',
  [ReportsGroupByEntity.MERCHANT]: 'merchant',
};

export type GroupDetail = {
  categoryGroupType: CategoryGroupType | undefined;
  date: Maybe<string>;
  entities: Maybe<ReportsEntityTypes>;
  original: ReportRow;
  percent?: number;
  summary: OmitTypename<ReportsSummaryFieldsFragment>;
  total: number;
};

type OtherGroupDetails = Pick<GroupDetail, 'total' | 'percent'> & { otherGroups: GroupDetail[] };

export type AdaptedReportData = readonly [GroupDetail[], OtherGroupDetails];

type AdapterOptions = {
  reportsData: Common_GetReportsDataQuery | undefined;
  filters?: Partial<TransactionFilterInput>;
  groupBy: Common_GetReportsDataQueryVariables['groupBy'];
  isSpendingReport?: Maybe<boolean>;
  includeAllGroups?: boolean;
  maxVisibleEntities?: number;
};

export const adaptReportsData = ({
  reportsData,
  filters,
  groupBy,
  isSpendingReport,
  includeAllGroups,
  maxVisibleEntities,
}: AdapterOptions): AdaptedReportData => {
  if (!reportsData) {
    return [[], { total: 0, otherGroups: [] }];
  }

  const summaryKey = determineSummaryKey(isSpendingReport);
  const groupByKeys = determineGroupByKeys(groupBy);

  const { sum: maybeOverallTotal } = ensureSummaryFromData(reportsData);
  const overallTotal = Math.abs(maybeOverallTotal || 0);

  const adaptedGroups = reportsData.reports.map((row) =>
    withPercent({
      item: mapToGroupDetail(row, summaryKey, groupByKeys, isSpendingReport, filters),
      key: 'total',
      total: overallTotal,
    }),
  );

  const filterGroups = (group: GroupDetail) =>
    // If groupBy is null or undefined, we just include all groups
    isNotNilOrEmpty(groupBy) ? isNotNilOrEmpty(group.entities) : true;

  const sortByTotal = sortWith<GroupDetail>([descend(prop('total'))]);
  const sortedGroups = sortByTotal(
    adaptedGroups.filter(filterGroups).filter(({ categoryGroupType }) => {
      if (isNil(isSpendingReport) || isNil(categoryGroupType)) {
        return true;
      }

      return isSpendingReport
        ? categoryGroupType === CategoryGroupType.EXPENSE
        : categoryGroupType === CategoryGroupType.INCOME;
    }),
  );

  return includeAllGroups
    ? [sortedGroups, { total: 0, otherGroups: [] }]
    : partitionGroups(sortedGroups, overallTotal, maxVisibleEntities);
};

const determineSummaryKey = (isExpenseReport: Maybe<boolean>): ReportsSummaryKey => {
  if (!isExpenseReport) {
    return 'sum';
  }

  return isExpenseReport ? 'sumExpense' : 'sumIncome';
};

const determineGroupByKeys = (entities?: AdapterOptions['groupBy']) =>
  (isNotNil(entities) && isArray(entities) ? entities.map((e) => camelCase(e)) : undefined) as
    | (keyof ReportsEntityTypes)[]
    | undefined;

const partitionGroups = (
  groups: GroupDetail[],
  overallTotal: number,
  maxVisibleEntities?: number,
): [GroupDetail[], OtherGroupDetails] => {
  const defaultMaxVisibleEntities =
    maxVisibleEntities && groups.length > maxVisibleEntities
      ? maxVisibleEntities - 1
      : groups.length;

  const visibleGroups = groups.slice(0, defaultMaxVisibleEntities);
  const otherGroups = groups.slice(defaultMaxVisibleEntities);

  const otherGroupsTotal = otherGroups.reduce((total, group) => total + group.total, 0);

  const other = {
    total: otherGroupsTotal,
    otherGroups,
  };

  return [
    visibleGroups,
    withPercent({
      item: other,
      key: 'total',
      total: overallTotal,
    }),
  ];
};

const mapToGroupDetail = (
  row: ReportRow,
  summaryKey: ReportsSummaryKey,
  groupByKeys: Maybe<(keyof ReportsEntityTypes)[]>,
  isSpendingReport?: Maybe<boolean>,
  filters?: Partial<TransactionFilterInput>,
): GroupDetail => {
  const value = pathOr(0, [summaryKey], row.summary);
  const includedEntities = isNotNil(groupByKeys) ? pick(groupByKeys, row.groupBy) : undefined;
  // If it's a spending report, we flip the sign so the chart shows the bars in the right direction.
  // Otherwise (i.e. income report), we keep the sign as is.
  const coefficient = isSpendingReport === true ? -1 : 1;

  return {
    // There are some timeframes where the provided date should not be the actual start date.
    // For example, a weekly timeframe uses ISO weeks, but these may not respect the provided filters,
    // so we need to clamp the date to filter start date, if necessary.
    date:
      row.groupBy.date && clampDate(row.groupBy.date, filters?.startDate, 'earliest').toISODate(),
    entities: includedEntities,
    total: value * coefficient,
    summary: omit(['__typename'], row.summary),
    categoryGroupType: includedEntities
      ? getCategoryGroupTypeFromEntities(includedEntities)
      : undefined,
    original: row,
  };
};

export const getDefaultChartColors = (palette: typeof lightPalette) => [
  palette.blue,
  palette.green,
  palette.yellow,
  palette.orange,
  palette.purple,
  palette.teal,
  palette.pink,
  palette.indigo,
  palette.lime,
];

export const getChartDateForTimeframe = (
  isoDate: string,
  timeframe: ReportsGroupByTimeframe,
  options?: { showToday?: boolean; formatMonth?: string },
) => {
  const { showToday, formatMonth = 'LLL yyyy' } = options || {};
  const dt = DateTime.fromISO(isoDate);

  if (showToday && timeframe === ReportsGroupByTimeframe.DAY && isSameDay(dt, DateTime.local())) {
    return 'Today';
  }

  switch (timeframe) {
    case ReportsGroupByTimeframe.DAY:
      return dt.toFormat('d LLL');
    case ReportsGroupByTimeframe.WEEK:
      return dt.toFormat("'W'WW");
    case ReportsGroupByTimeframe.MONTH:
      return dt.toFormat(formatMonth);
    case ReportsGroupByTimeframe.QUARTER:
      return dt.toFormat("'Q'q yyyy");
    case ReportsGroupByTimeframe.YEAR:
      return dt.toFormat('yyyy');
  }
};

export const ensureGroupBy = (
  groupBy: string | string[] | undefined,
): Common_GetReportsDataQueryVariables['groupBy'] => {
  if (isArray(groupBy)) {
    return groupBy.filter(isNotNil) as ReportsGroupByEntity[];
  }

  const value = ensureEnumValue(ReportsGroupByEntity, groupBy);

  if (value) {
    return [value];
  }

  return undefined;
};

/**
 * Util to ensure that the summary is always present, even if the data is not.
 */
export const ensureSummaryFromData = (
  data: Common_GetReportsDataQuery | undefined,
): ReportsSummaryFieldsFragment => {
  const summary = path<ReportsSummaryFieldsFragment>(['aggregates', 0, 'summary'], data);

  if (!summary || !data) {
    const empty: ReportsSummaryFieldsFragment = {
      __typename: 'TransactionsSummary',
      avg: 0,
      count: 0,
      max: 0,
      sum: 0,
      sumExpense: 0,
      sumIncome: 0,
      savings: 0,
      savingsRate: 0,
      first: undefined,
      last: undefined,
    };
    return empty;
  }

  return summary;
};

export const getCategoryGroupTypeFromEntities = (
  possibleEntities: ReportsEntityTypes,
): CategoryGroupType | undefined => {
  if (possibleEntities.category) {
    return possibleEntities.category.group?.type;
  }

  if (possibleEntities.categoryGroup) {
    return possibleEntities.categoryGroup.type;
  }

  return undefined;
};
