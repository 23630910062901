import { createGlobalStyle } from 'styled-components';

import { color } from 'common/lib/theme/dynamic';

// Override the main body background so that the content beyond the white
// onboarding scroll container is also white. This is noticeable on iOS &
// with momentum scrolling.
const OnboardingGlobalStyle = createGlobalStyle`
  body {
    background: ${color.white}
  }
`;

export default OnboardingGlobalStyle;
