import pluralize from 'pluralize';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import {
  AccountColumn,
  ColumnWrapper as AccountColumnWrapper,
} from 'components/accounts/transferAccountData/AccountColumn';
import { GroupedTransactionTimeline } from 'components/accounts/transferAccountData/GroupedTransactionTimeline';
import FlexContainer from 'components/lib/ui/FlexContainer';
import InformationList, { InformationItem } from 'components/lib/ui/InformationList';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Text from 'components/lib/ui/Text';

import { spacing, variables } from 'common/lib/theme/dynamic';
import { isoDateToAbbreviatedMonthDayAndYear } from 'common/utils/date';
import type { TransactionsPreviewProps } from 'lib/accounts/transferAccountData/types';
import { organizeTransactionsByDate } from 'lib/accounts/transferAccountData/utils/organizeTransactionsByDate';

const TransactionListsContainer = styled.div`
  position: relative;
  display: flex;
  gap: ${spacing.gutter};
  width: 100%;

  ${AccountColumnWrapper} {
    width: calc(50% - ${spacing.gutter} / 2);
    min-width: 0;
    flex: 0 0 calc(50% - ${spacing.gutter} / 2);
  }
`;

const TransitionDateBar = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background-color: ${variables.color.background.brand};
`;

const LoadingOverlay = styled(FlexContainer)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${variables.color.background.primary};
  opacity: 0.5;
  /* Position the overlay above the timeline */
  z-index: 2; /* stylelint-disable-line plugin/no-z-index */
`;

const ContentContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StyledInformationList = styled(InformationList)`
  background-color: ${variables.color.background.secondary};
`;

export const TransactionsPreview: React.FC<TransactionsPreviewProps> = ({
  isLoading,
  startDate,
  transitionDate,
  fromAccount,
  toAccount,
  fromAccountSummary,
  toAccountSummary,
  previewDays,
  lastTransactionDate,
}) => {
  const [barPosition, setBarPosition] = useState<number>();
  const containerRef = useRef<HTMLDivElement>(null);
  const transitionDateElementRef = useRef<HTMLElement>(null);

  // We really do want to run this on every render so the bar is in the correct position.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (transitionDateElementRef.current && containerRef.current) {
      const elementRect = transitionDateElementRef.current.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();
      setBarPosition(elementRect.top - containerRect.top);
    }
  });

  const { fromAccountDatesWithTransactions, toAccountDatesWithTransactions } = useMemo(
    () =>
      organizeTransactionsByDate(
        fromAccountSummary?.transactionsInWindow ?? [],
        toAccountSummary?.transactionsInWindow ?? [],
        transitionDate,
        previewDays,
      ),
    [
      fromAccountSummary?.transactionsInWindow,
      toAccountSummary?.transactionsInWindow,
      transitionDate,
      previewDays,
    ],
  );

  // Show full-page spinner if we don't have the necessary data yet
  if (isLoading && (!fromAccount || !toAccount || !fromAccountSummary || !toAccountSummary)) {
    return (
      <FlexContainer full center>
        <LoadingSpinner />
      </FlexContainer>
    );
  }

  const toAccountTransactions = toAccountSummary?.numTransactions ?? 0;
  const fromAccountTransactions = fromAccountSummary?.numTransactions ?? 0;
  const toAccountNewerTransactions = toAccountSummary?.numNewerTransactions ?? 0;

  return (
    <ContentContainer>
      {isLoading && (
        <LoadingOverlay center>
          <LoadingSpinner />
        </LoadingOverlay>
      )}
      <FlexContainer column gap="gutter">
        <TransactionListsContainer ref={containerRef}>
          <AccountColumn
            title="Old"
            subtitle="(We&rsquo;ll move data from this account)"
            account={fromAccount}
          >
            <GroupedTransactionTimeline
              startDate={startDate}
              includeItemsAfterTransitionDate={false}
              transitionDate={transitionDate}
              lastTransactionDate={lastTransactionDate}
              datesWithTransactions={fromAccountDatesWithTransactions}
              transitionDateElementRef={transitionDateElementRef}
              numNewerTransactions={fromAccountSummary?.numTransactionsNewerThanWindow}
              numOlderTransactions={fromAccountSummary?.numTransactionsOlderThanWindow}
            />
          </AccountColumn>
          <AccountColumn
            title="New"
            subtitle="(We&rsquo;ll move data to this account)"
            account={toAccount}
          >
            <GroupedTransactionTimeline
              startDate={startDate}
              includeItemsAfterTransitionDate
              transitionDate={transitionDate}
              datesWithTransactions={toAccountDatesWithTransactions}
              numNewerTransactions={toAccountSummary?.numTransactionsNewerThanWindow}
              numOlderTransactions={toAccountSummary?.numTransactionsOlderThanWindow}
            />
          </AccountColumn>
          <TransitionDateBar
            data-date={isoDateToAbbreviatedMonthDayAndYear(transitionDate)}
            style={{ top: barPosition }}
          />
        </TransactionListsContainer>
        <StyledInformationList title="What will happen:">
          <InformationItem iconName="trash">
            <Text>
              {toAccountTransactions} {pluralize('transaction', toAccountTransactions)} on and
              before{' '}
              <Text weight="medium">{isoDateToAbbreviatedMonthDayAndYear(transitionDate)}</Text>{' '}
              will be deleted on your new account
            </Text>
          </InformationItem>
          <InformationItem iconName="merge">
            <Text>
              {fromAccountTransactions} {pluralize('transaction', fromAccountTransactions)} on and
              before{' '}
              <Text weight="medium">{isoDateToAbbreviatedMonthDayAndYear(transitionDate)}</Text>{' '}
              will be moved from your old account to your new account
            </Text>
          </InformationItem>
          <InformationItem iconName="check">
            <Text>
              {toAccountNewerTransactions} {pluralize('transaction', toAccountNewerTransactions)}{' '}
              after{' '}
              <Text weight="medium">{isoDateToAbbreviatedMonthDayAndYear(transitionDate)}</Text>{' '}
              will remain on your new account
            </Text>
          </InformationItem>
        </StyledInformationList>
      </FlexContainer>
    </ContentContainer>
  );
};
