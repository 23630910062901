import styled from 'styled-components';

import Text from 'components/lib/ui/Text';
import { onboardingContentContainerMixin } from 'components/onboarding/style';

import { color, spacing } from 'common/lib/theme/dynamic';

const OnboardingSubtitle = styled(Text)`
  ${onboardingContentContainerMixin}
  color: ${color.gray11};
  margin-top: ${spacing.xxsmall};
  text-wrap: balance;
`;

export default OnboardingSubtitle;
