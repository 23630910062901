import { useOnboardingSurveyRadio } from 'common/hooks/onboarding/useOnboardingSurveyRadio';
import React from 'react';
import styled from 'styled-components';

import {
  onboardingSurveyRadioIndicatorStyles,
  onboardingSurveySelectIndicatorDotStyles,
} from 'common/components/onboarding/OnboardingSurveyRadio';
import { onboardingSurveySelectIndicatorStyles } from 'common/components/onboarding/OnboardingSurveySelectIndicator';
import OnboardingSurveyAnswer from 'components/onboarding/OnboardingSurveyAnswer';
import OnboardingSurveyControlMotion from 'components/onboarding/OnboardingSurveyControlMotion';

export const CircleIndicator = styled.div`
  ${onboardingSurveySelectIndicatorStyles}
  ${onboardingSurveyRadioIndicatorStyles}
`;

export const Dot = styled.div`
  ${onboardingSurveySelectIndicatorDotStyles}
`;

export type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
  children?: React.ReactNode;
  className?: string;
};

const OnboardingSurveyRadio = ({ value, onChange, children, className }: Props) => {
  const { onInteract, onKeyDown } = useOnboardingSurveyRadio({
    value,
    onChange,
  });

  return (
    <OnboardingSurveyAnswer
      value={value}
      onClick={onInteract}
      onKeyDown={onKeyDown}
      role="radio"
      className={className}
    >
      <CircleIndicator isSelected={value}>
        <OnboardingSurveyControlMotion value={value}>
          <Dot />
        </OnboardingSurveyControlMotion>
      </CircleIndicator>

      {children}
    </OnboardingSurveyAnswer>
  );
};

export default OnboardingSurveyRadio;
