import { any } from 'ramda';
import { isNotNumber } from 'ramda-adjunct';

import type {
  FilterMenuOption,
  FilterMenuSection,
  WithPath,
} from 'components/reports/filters/types';

import { ANYONE_ID } from 'common/lib/hooks/household/useHouseholdUsers';
import typewriter from 'lib/analytics/typewriter';
import type { SectionAdapterOptions, AmountFilter } from 'lib/filters/types';
import type { radioFilterFactory } from 'lib/filters/utils';
import { getIsRadioOptionSelected, makeAmountOptions } from 'lib/filters/utils';
import { getNeedsReviewByUserId } from 'lib/transactions/review';

import type { TransactionFilters } from 'types/filters';

export const makeAmountFilterSection = (
  filters?: Partial<TransactionFilters>,
): FilterMenuSection => ({
  id: 'amount',
  name: 'Amount',
  validate: (values: {
    [AmountFilter.Between]?: { min: number | undefined; max: number | undefined };
    [AmountFilter.GreaterThan]?: { amount: number | undefined };
    [AmountFilter.LessThan]?: { amount: number | undefined };
    [AmountFilter.EqualTo]?: { amount: number | undefined };
  }) => {
    let errors: string[] = [];

    if (values.between) {
      if (any(isNotNumber, [values.between.min, values.between.max])) {
        errors = errors.concat('Please enter a minimum and maximum amount');
      }

      if (values.between.min && values.between.max && values.between.min > values.between.max) {
        errors = errors.concat('Minimum amount must be less than maximum amount');
      }
    }

    return errors;
  },
  options: [
    {
      id: 'amount',
      name: 'Amount',
      type: 'radio',
      options: makeAmountOptions(filters),
    },
    {
      id: 'type',
      name: 'Type',
      type: 'radio',
      options: [
        {
          id: 'debitsOnly',
          name: 'Debits only',
          isSelected: filters?.debitsOnly,
        },
        {
          id: 'creditsOnly',
          name: 'Credits only',
          isSelected: filters?.creditsOnly,
        },
      ],
    },
  ],
});

export const makeOtherFilterSection = (
  makeRadioFilter: ReturnType<typeof radioFilterFactory>,
  filters?: Partial<TransactionFilters>,
  options?: SectionAdapterOptions,
): FilterMenuSection => ({
  id: 'other',
  name: 'Other',
  options: [
    makeReviewFilterSection(options?.householdUsers, filters),
    makeRadioFilter({
      label: 'Recurring',
      filterPath: ['isRecurring'],
    }),
    makeRadioFilter({
      label: 'Has Attachments',
      negativeOptionLabel: 'No attachments',
      filterPath: ['hasAttachments'],
    }),
    makeRadioFilter({
      label: 'Split',
      negativeOptionLabel: 'Not split',
      filterPath: ['isSplit'],
    }),
    makeRadioFilter({
      label: 'Notes',
      optionLabel: 'Has notes',
      negativeOptionLabel: 'No notes',
      filterPath: ['hasNotes'],
    }),
    makeRadioFilter({
      label: 'Syncing',
      optionLabel: 'Synced from institution',
      filterPath: ['syncedFromInstitution'],
    }),
    makeRadioFilter({
      label: 'Investments',
      optionLabel: 'In an investment account',
      filterPath: ['isInvestmentAccount'],
    }),
    makeRadioFilter({
      label: 'Imported from Mint',
      filterPath: ['importedFromMint'],
      hide: !options?.hasTransactionsImportedFromMint,
      optionLabel: 'Imported from Mint', // Just so the word "Mint" is not lowercased
    }),
    makeRadioFilter({
      label: 'Hidden',
      filterPath: ['hideFromReports'],
    }),
    makeRadioFilter({
      label: 'Pending',
      filterPath: ['isPending'],
    }),
  ].filter(Boolean),
});

export const makeReviewFilterSection = (
  users: SectionAdapterOptions['householdUsers'],
  filters?: Partial<TransactionFilters>,
): FilterMenuOption => ({
  id: 'needsReview',
  name: 'Needs Review by',
  type: 'radio',
  options: [
    {
      id: ANYONE_ID,
      name: 'Anyone',
      isSelected: getNeedsReviewByUserId(ANYONE_ID, filters),
      formatLabel: () => 'Needs review by anyone',
    },
    ...(users || []).map(({ name, id }) => ({
      id,
      name,
      isSelected: getNeedsReviewByUserId(id, filters),
      formatLabel: () => `Needs review by ${name}`,
    })),
  ],
});

/**
 * This gets the selected options and generates a dictionary with the attributes the
 * event "Filters Applied" expects.
 */
export const getPropsForAnalytics = (selectedOptions: WithPath<FilterMenuOption>[]) =>
  selectedOptions.reduce(
    (acc, option) => {
      const [section, filterOrValue] = option.path;

      if (section === 'other') {
        const value = getIsRadioOptionSelected(option);

        if (!acc.other) {
          acc.other = {};
        }

        acc.other[filterOrValue] = value;
      } else if (section === 'amount') {
        if (!acc.amount) {
          acc.amount = {};
        }

        acc.amount[option.id] = option.inputValues;
      } else {
        acc[section] = (acc[section] || 0) + 1;
      }

      return acc;
    },
    {} as Record<string, number> & {
      amount: Record<string, unknown>;
      other: Record<string, boolean>;
    },
  );

export const trackFiltersApplied = (page: string, selectedOptions: WithPath<FilterMenuOption>[]) =>
  typewriter.filtersApplied({
    page,
    sections: getPropsForAnalytics(selectedOptions),
  });
