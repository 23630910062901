import { Duration } from 'luxon';
import * as R from 'ramda';
import { useMemo } from 'react';

import useQueryWithCacheExpiration from 'common/lib/hooks/useQueryWithCacheExpiration';
import { indexConstants } from 'common/lib/premium/adapters';

import { gql } from 'common/generated/gql';

// This is unlikely to change, so we cache for an entire day
const CACHE_EXPIRATION = Duration.fromObject({ days: 1 });

/**
 * Hook used to fetch the required entitlements for all features.
 */
const useFeatureEntitlementParams = () => {
  const { data } = useQueryWithCacheExpiration(QUERY, {
    cacheExpiration: CACHE_EXPIRATION,
  });
  const { subscription } = data ?? {};
  const { entitlementParams } = subscription ?? {};
  const { features: featuresList = [], constants: constantsList = [] } = entitlementParams ?? {};

  const features = useMemo(() => R.indexBy(R.prop('feature'), featuresList), [featuresList]);

  const constants = useMemo(() => indexConstants(constantsList), [constantsList]);

  return {
    features,
    constants,
  };
};

const QUERY = gql(/* GraphQL */ `
  query Common_GetFeatureEntitlementParams {
    subscription {
      id
      entitlementParams {
        features {
          feature
          requiredEntitlements
        }
        constants {
          entitlement
          maxCredentials
          maxTransactionRules
        }
      }
    }
  }
`);

export default useFeatureEntitlementParams;
