import { DateTime } from 'luxon';
import { isNil, pick } from 'ramda';
import { isNotNil } from 'ramda-adjunct';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import DateRangeWithTimeframesPickerButton from 'components/lib/dates/DateRangeWithTimeframesPickerButton';
import DividerLine from 'components/lib/ui/DividerLine';
import FlexContainer from 'components/lib/ui/FlexContainer';
import TextButton from 'components/lib/ui/TextButton';
import ReportsConfigurationsMenuButton from 'components/reports/ReportsConfigurationsMenuButton';
import ReportsFilterMenuButton from 'components/reports/ReportsFilterMenuButton';

import { setGroupByTimeframe, setReportsFilters } from 'actions';
import { resolveDateRangeWithRelativeTimeframe } from 'common/utils/date';
import { useDispatch } from 'lib/hooks';
import { INITIAL_STATE as REPORTS_INITIAL_STATE } from 'state/reports/reducer';
import { selectReportsFilters, selectReportsHasFilters } from 'state/reports/selectors';

import type { Timeframe, ReportConfiguration } from 'common/generated/graphql';
import { ReportsGroupByTimeframe } from 'common/generated/graphql';
import type { DateRangeWithTimeframes } from 'common/types/DateRange';

const REPORTS_DEFAULT_DATE_RANGE = pick(
  ['startDate', 'endDate', 'timeframePeriod'],
  REPORTS_INITIAL_STATE.filters,
);

type Props = {
  reportConfigurations: ReportConfiguration[];
  selectedReportConfigurationId?: string;
  onSelectReportConfiguration?: (reportConfiguration: ReportConfiguration) => void;
  onSaveReportConfiguration?: () => void;
  onEditReportConfiguration?: (reportConfiguration: ReportConfiguration) => void;
};

const ReportsHeaderControls = ({
  reportConfigurations,
  selectedReportConfigurationId,
  onSelectReportConfiguration,
  onSaveReportConfiguration,
  onEditReportConfiguration,
}: Props) => {
  const dispatch = useDispatch();

  const hasActiveFilters = useSelector(selectReportsHasFilters);
  const filters = useSelector(selectReportsFilters);
  const { startDate, endDate, timeframePeriod } = filters;

  const onClearFilters = useCallback(() => {
    dispatch(setReportsFilters({}));
  }, [dispatch]);

  const setRecommendedTimeframe = useCallback(
    (resolvedStartDate: Maybe<ISODate>, resolvedEndDate: Maybe<ISODate>) => {
      const startDateTime = resolvedStartDate ? DateTime.fromISO(resolvedStartDate) : undefined;
      const endDateTime = resolvedEndDate ? DateTime.fromISO(resolvedEndDate) : undefined;

      // We optimistically assume that the timeframe is more than one month for performance reasons,
      // unless there's a clear indicator otherwise. Better to be usually correct and fast. This is
      // a heuristic for when there is no start date.
      let greaterThanOneMonth = true;
      if (isNotNil(endDateTime) && isNotNil(startDateTime)) {
        greaterThanOneMonth = endDateTime.diff(startDateTime, 'months').months > 1;
      } else if (isNotNil(startDateTime)) {
        greaterThanOneMonth = Math.abs(startDateTime.diffNow('months').months) > 1;
      }

      dispatch(
        setGroupByTimeframe(
          greaterThanOneMonth ? ReportsGroupByTimeframe.MONTH : ReportsGroupByTimeframe.DAY,
        ),
      );
    },
    [dispatch],
  );

  const handleDateRangeWithTimeframesChange = useCallback(
    (dateRangeWithTimeframes: DateRangeWithTimeframes) => {
      const { startDate: resolvedStartDate, endDate: resolvedEndDate } =
        resolveDateRangeWithRelativeTimeframe(
          dateRangeWithTimeframes.startDate,
          dateRangeWithTimeframes.endDate,
          dateRangeWithTimeframes.timeframeUnit,
          dateRangeWithTimeframes.timeframeValue,
          dateRangeWithTimeframes.includeCurrentPeriod,
        );
      setRecommendedTimeframe(resolvedStartDate, resolvedEndDate);

      dispatch(
        setReportsFilters({
          filters: getReportDateFilters(dateRangeWithTimeframes),
          maintainNonDateFilters: true,
        }),
      );
    },
    [dispatch],
  );

  return (
    <FlexContainer gap="small" alignCenter justifyEnd>
      {hasActiveFilters && <TextButton onClick={onClearFilters}>Clear</TextButton>}
      <DateRangeWithTimeframesPickerButton
        dateRange={{
          startDate,
          endDate,
          timeframeUnit: timeframePeriod?.unit,
          timeframeValue: timeframePeriod?.value,
          includeCurrentPeriod: timeframePeriod?.includeCurrent,
        }}
        defaultRange={REPORTS_DEFAULT_DATE_RANGE}
        onChangeDateRange={handleDateRangeWithTimeframesChange}
      />
      <ReportsFilterMenuButton />
      <DividerLine />
      <ReportsConfigurationsMenuButton
        hasActiveFilters={hasActiveFilters}
        reportConfigurations={reportConfigurations}
        selectedReportConfigurationId={selectedReportConfigurationId}
        onSelectReportConfiguration={onSelectReportConfiguration}
        onSaveReportConfiguration={onSaveReportConfiguration}
        onEditReportConfiguration={onEditReportConfiguration}
      />
    </FlexContainer>
  );
};

const getReportDateFilters = ({
  startDate,
  endDate,
  timeframeUnit,
  timeframeValue,
  includeCurrentPeriod,
}: DateRangeWithTimeframes) => {
  if (isNil(timeframeUnit) || isNil(timeframeValue) || isNil(includeCurrentPeriod)) {
    return {
      startDate,
      endDate,
      timeframePeriod: undefined,
    };
  }

  return {
    startDate: undefined,
    endDate: undefined,
    timeframePeriod: {
      unit: timeframeUnit as Timeframe,
      value: timeframeValue,
      includeCurrent: includeCurrentPeriod,
    },
  };
};

export default ReportsHeaderControls;
