import * as React from 'react';
import styled, { css } from 'styled-components';

import { useDraggableContext } from 'components/lib/dnd/DraggableContext';
import Card from 'components/lib/ui/Card';
import CardTitle from 'components/lib/ui/CardTitle';
import DragDots from 'components/lib/ui/DragDots';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import NavLink from 'components/lib/ui/link/NavLink';

import { fontSize, spacing, variables } from 'common/lib/theme/dynamic';

type Props = {
  title: string;
  description?: React.ReactNode;
  headerRight?: React.ReactNode;
  children?: React.ReactNode;
  loading?: boolean;
  minHeightPx?: number;
  headerLink: string;
  showHeaderBorder?: boolean;
  hideHeader?: boolean;
  tooltip?: React.ReactNode;
  className?: string;
};

const Root = styled(({ minHeightPx, ...props }) => <Card {...props} />)<{ minHeightPx?: number }>`
  min-height: ${({ minHeightPx }) => minHeightPx ?? 0}px;
  position: relative;
`;

const Loading = styled(FlexContainer)`
  margin: ${({ theme }) => theme.spacing.xlarge};
`;

const Header = styled(FlexContainer)<{ showBorder: boolean }>`
  ${({ showBorder, theme }) =>
    showBorder &&
    css`
      border-bottom: 1px solid ${theme.color.grayBackground};
    `}

  padding: ${spacing.small};
  padding-left: ${spacing.large};
  min-height: 56px;
`;

const HeaderClickable = styled(NavLink)<{ tooltip: React.ReactNode }>`
  ${({ tooltip }) =>
    !tooltip &&
    `
    flex: 1;
  `}

  display: flex;
  gap: 1px;
  flex-direction: column;
  align-items: stretch;

  @media (min-width: ${({ theme }) => theme.breakPoints.xl}px) {
    flex-direction: row;
    align-items: center;
    gap: ${spacing.xsmall};
  }
`;

const HeaderRight = styled(Flex)`
  margin-left: ${spacing.default};
  align-items: center;
`;

const Title = styled(CardTitle).attrs({ $large: true })`
  display: flex;
  align-items: center;

  ${HeaderClickable}:hover & {
    color: ${({ theme }) => theme.color.blue};
  }
`;

export const Description = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 150%;
  margin-top: 1px;
  font-size: ${fontSize.base};
  color: ${variables.color.content.secondary};
`;

const StyledDragDots = styled(DragDots)`
  position: absolute;
  top: unset;
  left: 0;
  cursor: move;
  transition: ${({ theme }) => theme.transition.default};
  opacity: 0;

  ${Root}:hover & {
    opacity: 1;
  }
`;

const DragHandle = () => {
  const { dragHandleProps } = useDraggableContext();
  return <StyledDragDots {...dragHandleProps} />;
};

const DashboardWidget = ({
  title,
  description,
  headerRight,
  children,
  loading = false,
  minHeightPx,
  headerLink,
  showHeaderBorder = true,
  hideHeader = false,
  tooltip,
  className,
}: Props) => (
  <Root className={className} minHeightPx={minHeightPx}>
    {!hideHeader && (
      <Header showBorder={showHeaderBorder}>
        <HeaderClickable to={headerLink} tooltip={tooltip}>
          <DragHandle />
          <Title>{title}</Title>
          {!!description && <Description>{description}</Description>}
        </HeaderClickable>
        {tooltip}
        {!!headerRight && <HeaderRight justifyEnd>{headerRight}</HeaderRight>}
      </Header>
    )}
    {loading ? (
      <Loading center>
        <LoadingSpinner />
      </Loading>
    ) : (
      children
    )}
  </Root>
);

export default DashboardWidget;
