import type { Method, AxiosInstance } from 'axios';
import axios from 'axios';

import { MS_PER_SECOND } from 'common/constants/time';

import type { AuthHeaders } from 'common/types';

// Ensure this is greater than the backend request timeout, otherwise axios can timeout
// when the request actually succeeds
const TIMEOUT_MS = 40 * MS_PER_SECOND;

type SendOptions<DataT> = {
  data?: DataT;
  overrideHeaders?: AuthHeaders;
  queryParams?: { [key: string]: string };
  onUploadProgress?: (e: ProgressEvent) => void;
  timeout?: number;
};

type ConfigOptions = {
  baseUrlProvider: () => string;
  userTokenProvider?: () => string;
  userAgentOverride?: string | undefined;
  clientPlatform?: string | undefined;
  actAsUserProvider?: () => string | null;
  deviceUUIDProvider?: () => Promise<string>;
  onError?: (error: Error) => void;
};

class MonarchRestApi {
  config: ConfigOptions;

  constructor(config: ConfigOptions) {
    this.config = config;
  }

  _send =
    (method: Method) =>
    async <DataT>(
      url: string,
      { data, overrideHeaders, queryParams, onUploadProgress, timeout }: SendOptions<DataT> = {},
    ) => {
      const {
        baseUrlProvider,
        userTokenProvider,
        userAgentOverride,
        onError,
        clientPlatform,
        actAsUserProvider,
        deviceUUIDProvider,
      } = this.config;
      const token = userTokenProvider?.();
      const baseUrl = baseUrlProvider();
      const actAsUser = actAsUserProvider?.();
      const deviceUUID = await deviceUUIDProvider?.();

      const headers: AuthHeaders = overrideHeaders || {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // TODO: Consider moving these to an getExtraHeaders param passed to API
        ...(actAsUser ? { 'Act-As-User': actAsUser } : {}),
        ...(clientPlatform ? { 'Client-Platform': clientPlatform } : {}),
        ...(token ? { Authorization: `Token ${token}` } : {}),
        ...(userAgentOverride ? { 'User-Agent': userAgentOverride } : {}),
        ...(deviceUUID ? { 'Device-UUID': deviceUUID } : {}),
      };

      try {
        const response = await axios({
          method,
          url,
          params: queryParams ?? {},
          baseURL: baseUrl,
          headers,
          data,
          timeout: timeout || TIMEOUT_MS,
          ...(onUploadProgress ? { onUploadProgress } : {}),
        });

        return response.data;
      } catch (error: any) {
        onError?.(error);
      }
    };

  post = this._send('post');
  patch = this._send('patch');
  del = this._send('delete');
  get = this._send('get');
}

export default MonarchRestApi;

export const CloudinaryRestApi: AxiosInstance = axios.create({
  baseURL: 'https://api.cloudinary.com',
  timeout: TIMEOUT_MS,
});
