import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import type { SingleDatePickerProps } from 'components/lib/ui/SingleDatePicker';
import SingleDatePicker from 'components/lib/ui/SingleDatePicker';
import Text from 'components/lib/ui/Text';
import { DefaultTooltipContent, TooltipV2 } from 'components/lib/ui/TooltipV2';

import { spacing, variables } from 'common/lib/theme/dynamic';

const TOOLTIP_MAX_WIDTH_PX = 300;

const SecondaryText = styled(Text)`
  color: ${variables.color.content.secondary};
`;

const InfoIcon = styled(Icon).attrs({ name: 'info', size: 12 })`
  display: inline-block;
  margin-left: ${spacing.xxsmall};
  cursor: pointer;
  color: ${variables.color.content.secondary};
`;

const StyledTooltipContent = styled(DefaultTooltipContent)`
  text-align: left;
`;

interface DatePickerProps extends Omit<SingleDatePickerProps, 'date' | 'onDateChange'> {
  title: string;
  subtitle: string;
  tooltip: string;
  tooltipMaxWidth?: number;
  recommendedDate: string | null | undefined;
  endDate: string | null;
  setEndDate: (value: string | null) => void;
  maxDate?: Date;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  title,
  subtitle,
  tooltip,
  tooltipMaxWidth = TOOLTIP_MAX_WIDTH_PX,
  recommendedDate,
  endDate,
  setEndDate,
  maxDate = new Date(),
  ...restProps
}) => {
  if (!recommendedDate) {
    return null;
  }

  return (
    <FlexContainer column gap="xsmall">
      <span>
        <Text>{title}</Text>
        <TooltipV2
          content={<StyledTooltipContent>{tooltip}</StyledTooltipContent>}
          side="right"
          maxWidth={tooltipMaxWidth}
        >
          <span>
            <InfoIcon />
          </span>
        </TooltipV2>
      </span>
      <SingleDatePicker
        displayFormat="MMMM D, yyyy"
        date={endDate}
        onDateChange={setEndDate}
        {...restProps}
        maxDate={maxDate}
      />
      <SecondaryText size="small">{subtitle}</SecondaryText>
    </FlexContainer>
  );
};
