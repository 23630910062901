import { DateTime } from 'luxon';

import useEventCallback from 'common/lib/hooks/useEventCallback';
import useProfileFlag from 'common/lib/hooks/users/useProfileFlag';

const useReportsSavedViewsWalkthrough = () => {
  const [dismissedWalkthroughAt, setDismissedWalkthroughAt, isLoadingDismissedWalkthroughAt] =
    useProfileFlag('dismissedReportsSavedViewsWalkthroughAt');

  const shouldShowWalkthrough = !isLoadingDismissedWalkthroughAt && !dismissedWalkthroughAt;

  const dismissWalkthrough = useEventCallback(() => {
    setDismissedWalkthroughAt(DateTime.now().toISO());
  });

  return [shouldShowWalkthrough, dismissWalkthrough] as const;
};

export default useReportsSavedViewsWalkthrough;
