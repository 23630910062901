import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import RequestInstitutionModal from 'components/accounts/RequestInstitutionModal';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import ContactSupportHelpCenterText from 'components/support/ContactSupportHelpCenterText';
import ContactSupportInnerForm from 'components/support/ContactSupportInnerForm';

import getSupportApi from 'common/lib/api/support';
import type { FormValues } from 'common/lib/support/form';
import { prepareSupportTicketFormData, SUPPORT_FORM_INITIAL_VALUES } from 'common/lib/support/form';
import api from 'lib/api';
import { useModalContext } from 'lib/contexts/ModalContext';
import useDemoHousehold from 'lib/hooks/useDemoHousehold';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import useTrack from 'lib/hooks/useTrack';
import { errorToast } from 'lib/ui/toast';
import { getUserEmail } from 'state/user/selectors';

import { AnalyticsEventNames } from 'common/constants/analytics';
import * as COPY from 'common/constants/copy';

const Content = styled(FlexContainer).attrs({ column: true })`
  padding: 0 ${({ theme }) => theme.spacing.xlarge} ${({ theme }) => theme.spacing.xxlarge};
  gap: ${({ theme }) => theme.spacing.large};

  > div:not(:last-child) {
    margin-bottom: unset;
  }
`;

const ReplyToContainer = styled(FlexContainer).attrs({ column: true })`
  gap: ${({ theme }) => theme.spacing.xxsmall};
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

export type Props = {
  initialValues?: Partial<FormValues>;
  next: (params?: any) => void;
  goBack?: () => void;
};

const ContactSupportModal = ({ next, initialValues }: Props) => {
  const { close } = useModalContext();
  const { isDemoHousehold } = useDemoHousehold();

  const isChatBeforeFormEnabled = useIsFeatureFlagOn('support-chat-bubble');

  const [showRequestNewInstitution, setShowRequestNewInstitution] = useState(false);
  const [institutionName, setInstitutionName] = useState('');
  const email = useSelector(getUserEmail);

  const onSubmit = useCallback(
    async (values: Partial<FormValues>) => {
      if (isDemoHousehold) {
        errorToast('This feature is disabled when using a demo account');
        return;
      }

      try {
        // Add an extra tag if the user saw a chatbot first
        const valuesWithTags = {
          ...values,
          tags: [...(values.tags || []), isChatBeforeFormEnabled ? 'chatbot_first' : 'form_first'],
        };

        const data = prepareSupportTicketFormData(valuesWithTags, email, window.location.pathname);

        await getSupportApi(api).createTicket(data);
        next();
      } catch (e) {
        errorToast(COPY.SUPPORT.ERROR_TOAST_SUBTEXT);
      }
    },
    [email, isChatBeforeFormEnabled, isDemoHousehold, next],
  );

  const onOpenRequestInstitution = (institutionName: string) => {
    setInstitutionName(institutionName);
    setShowRequestNewInstitution(true);
  };

  useTrack(AnalyticsEventNames.SupportTicketFormViewed);

  if (showRequestNewInstitution) {
    return (
      <RequestInstitutionModal
        allowManualAccount
        initialInstitutionName={institutionName}
        onBack={() => setShowRequestNewInstitution(false)}
      />
    );
  }

  return (
    <ModalCard
      title="Contact Support"
      description={<ContactSupportHelpCenterText />}
      hideBottomBorder
    >
      <Form<Partial<FormValues>>
        initialValues={{ ...SUPPORT_FORM_INITIAL_VALUES, ...initialValues }}
        onSubmit={(values) => onSubmit(values)}
      >
        <Content>
          <ReplyToContainer>
            <Text weight="medium" size="small">
              Your email address
            </Text>
            <Text>{email}</Text>
          </ReplyToContainer>
          <ContactSupportInnerForm onOpenRequestInstitution={onOpenRequestInstitution} />
        </Content>
        <CardFooter>
          <DefaultButton onClick={close}>Cancel</DefaultButton>
          <FormSubmitButton size="small">Submit issue</FormSubmitButton>
        </CardFooter>
      </Form>
    </ModalCard>
  );
};

export default ContactSupportModal;
