import React, { useMemo } from 'react';
import styled from 'styled-components';

import AmountPill from 'components/lib/ui/AmountPill';

import { formatCurrencyNoCents } from 'common/utils/Currency';
import { SAVINGS_PLAN_SECTION_TYPE } from 'lib/plan';
import type { PlanSectionType } from 'lib/plan/Adapters';

import type { Color } from 'types/Styles';

const PILL_X_OFFSET_PX = 12;

const StyledAmountPill = styled(AmountPill)<{ $isHeader: boolean; $isBold: boolean }>`
  font-size: ${({ $isHeader, theme }) => ($isHeader ? theme.fontSize.large : theme.fontSize.base)};
  font-weight: ${({ $isBold, theme }) =>
    $isBold ? theme.fontWeight.medium : theme.fontWeight.book};
  margin-right: -${PILL_X_OFFSET_PX}px;
`;

type Props = {
  sectionType: PlanSectionType;
  isAggregate?: boolean;
  isCategoryGroup?: boolean;
  isMovable: boolean;
  showRolloverIcon: boolean;
  active?: boolean;
  warningText?: string;
  onClick?: () => void;
  value?: number;
  hideBackground?: boolean;
  isBold?: boolean;
  overrideTextColor?: Color;
  hasWarning?: boolean;
};

export const PlanCellAmountPill = React.memo(
  ({
    sectionType,
    isAggregate = false,
    isCategoryGroup = false,
    value,
    isMovable,
    showRolloverIcon,
    active,
    onClick,
    hideBackground = false,
    isBold = false,
    hasWarning = false,
    overrideTextColor,
  }: Props) => {
    const pillStyles = usePillStyles({
      sectionType,
      value,
      isMovable,
      isCategoryGroup,
      isAggregate,
      hideBackground,
      overrideTextColor,
      hasWarning,
    });

    return (
      <StyledAmountPill
        value={value}
        formatter={formatCurrencyNoCents}
        onClick={onClick}
        icon={showRolloverIcon ? 'rotate-cw' : undefined}
        active={active}
        $isHeader={isAggregate}
        $isBold={isBold}
        {...pillStyles}
      />
    );
  },
);

type PillStylesProps = {
  sectionType: PlanSectionType;
  isMovable: boolean;
  isCategoryGroup: boolean;
  isAggregate: boolean;
  value?: number;
  hideBackground: boolean;
  overrideTextColor?: Color;
  hasWarning: boolean;
};

const usePillStyles = ({
  sectionType,
  isMovable,
  isCategoryGroup,
  isAggregate,
  value = 0,
  hideBackground,
  overrideTextColor,
  hasWarning,
}: PillStylesProps) =>
  useMemo(() => {
    let styles: Record<string, string> = {};

    if (sectionType === SAVINGS_PLAN_SECTION_TYPE) {
      styles = {
        ...styles,
        positiveBackgroundColor: 'blueBackground',
        positiveTextColor: isAggregate ? 'blueText' : 'blue',
        negativeBackgroundColor: 'grayFocus',
        negativeTextColor: 'textLight',
      };
    }

    if (overrideTextColor) {
      styles = {
        ...styles,
        positiveTextColor: overrideTextColor,
        negativeTextColor: overrideTextColor,
        neutralTextColor: overrideTextColor,
      };
    }

    if (hasWarning && value < 0) {
      styles = {
        ...styles,
        negativeTextColor: 'yellowText',
      };
    }

    if (hideBackground) {
      styles = {
        ...styles,
        positiveBackgroundColor: 'transparent',
        negativeBackgroundColor: 'transparent',
        neutralBackgroundColor: 'transparent',
      };
    }

    if (!isMovable && (isCategoryGroup || isAggregate)) {
      styles = {
        ...styles,
        positiveBackgroundColor: 'transparent',
        negativeBackgroundColor: 'transparent',
        neutralBackgroundColor: 'transparent',
      };
    }

    return styles;
  }, [
    sectionType,
    value,
    isMovable,
    isCategoryGroup,
    isAggregate,
    hasWarning,
    overrideTextColor,
    hideBackground,
  ]);
