import React from 'react';
import styled from 'styled-components';

import Mark from 'components/brand/Mark';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';
import IconButton from 'components/lib/ui/button/IconButton';
import NavLink from 'components/lib/ui/link/NavLink';
import NotificationCenterButton from 'components/notifications/NotificationCenterButton';

import { toggleSidebarExpanded } from 'actions';
import { color, spacing, variables } from 'common/lib/theme/dynamic';
import { useDispatch } from 'lib/hooks';

import { SIDEBAR_COLLAPSED_CLASS_NAME } from 'constants/classNames';
import routes from 'constants/routes';

export const MARK_SIZE_PX_V2 = 28;

export const HeaderLink = styled(NavLink)`
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: ${spacing.xxxsmall};
  transition: opacity 0.1s;
  transition-delay: 0.1s;

  .${SIDEBAR_COLLAPSED_CLASS_NAME}:hover & {
    opacity: 0;
  }
`;

const SearchTooltipText = styled(Text).attrs({ weight: 'medium' })`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const SearchTooltipContent = styled.div`
  display: flex;
  align-items: center;
  padding: 6px ${({ theme }) => theme.spacing.xsmall};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.xxsmall};
  border-radius: ${({ theme }) => theme.radius.small};
  background: ${color.gray11};
  margin-left: ${({ theme }) => theme.spacing.xxxsmall};
  min-width: 20px;
  height: 20px;
`;

const StyledIconButton = styled(IconButton)`
  color: ${variables.color.content.secondary};
`;

export const CollapseExpandButton = styled(StyledIconButton)`
  .${SIDEBAR_COLLAPSED_CLASS_NAME} & {
    position: absolute;
    right: ${spacing.xxxsmall};
    opacity: 0;
    transition: opacity 0.1s;
    transition-delay: 0.1s;
  }

  .${SIDEBAR_COLLAPSED_CLASS_NAME}:hover & {
    opacity: 1;
  }
`;

type Props = {
  onOpenSearch?: () => Promise<void> | void;
  isMacOs?: boolean;
  isSidebarExpanded?: boolean;
};

const SideBarDefaultHeader = ({
  isMacOs = false,
  onOpenSearch,
  isSidebarExpanded = true,
}: Props) => {
  const dispatch = useDispatch();

  const showButtons = isSidebarExpanded;

  return (
    <>
      <HeaderLink to={routes.dashboard()}>
        <Mark size={MARK_SIZE_PX_V2} />
      </HeaderLink>
      {showButtons && (
        <Tooltip
          portal
          content={
            <SearchTooltipContent>
              <SearchTooltipText>Search</SearchTooltipText>
              <IconWrapper>
                {isMacOs ? <Icon size={12} name="command" /> : <kbd>ctrl</kbd>}
              </IconWrapper>
              <IconWrapper>K</IconWrapper>
            </SearchTooltipContent>
          }
          place="bottom"
        >
          <StyledIconButton icon="search" size="small" darkBg={false} onClick={onOpenSearch} />
        </Tooltip>
      )}
      <NotificationCenterButton />
      {showButtons && (
        <StyledIconButton
          icon="settings"
          size="small"
          linkTo={routes.settings.profile()}
          // @ts-ignore - activeClassName is passed through to NavLink
          activeClassName="jon-was-here"
          darkBg={false}
        />
      )}
      <CollapseExpandButton
        icon="sidebar"
        size="small"
        onClick={() => dispatch(toggleSidebarExpanded())}
      />
    </>
  );
};

export default SideBarDefaultHeader;
