import { useMutation, useQuery } from '@apollo/client';
import type { PaymentMethod } from '@stripe/stripe-js';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useCallback } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import SettingsCard from 'components/lib/layouts/SettingsCard';
import RouteModal from 'components/lib/routing/RouteModal';
import Column from 'components/lib/ui/Column';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import PremiumSubscribeModal from 'components/premium/PremiumSubscribeModal';
import PremiumSubscriptionEndedModal from 'components/premium/PremiumSubscriptionEndedModal';
import PremiumUpgradeFlow from 'components/premium/PremiumUpgradeFlow';
import BillingDetailsCard from 'components/settings/billing/BillingDetailsCard';
import DeleteHouseholdFinalConfirmation from 'components/settings/billing/DeleteHouseholdFinalConfirmation';
import DeleteHouseholdInitialConfirmation from 'components/settings/billing/DeleteHouseholdInitialConfirmation';
import InvoicesCard from 'components/settings/billing/InvoicesCard';
import ManageSubscriptionFlow from 'components/settings/billing/ManageSubscriptionFlow';
import RevokeSponsorAccessConfirmation from 'components/settings/billing/RevokeSponsorAccessConfirmation';
import UpdatePaymentMethodModal from 'components/settings/billing/UpdatePaymentMethodModal';

import { getDaysLeftOfTrial } from 'common/lib/billing/Billing';
import { CANCEL_SUBSCRIPTION_MUTATION } from 'common/lib/graphQl/billing';
import usePremiumStripeOfferings from 'common/lib/hooks/premium/usePremiumStripeOfferings';
import useChangeSubscription from 'lib/hooks/billing/useChangeSubscription';
import useCreateSubscription from 'lib/hooks/billing/useCreateSubscription';
import useReactivateSubscription from 'lib/hooks/billing/useReactivateSubscription';
import useDemoHousehold from 'lib/hooks/useDemoHousehold';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import useModal from 'lib/hooks/useModal';
import { errorToast } from 'lib/ui/toast';
import { getIsHouseholdOwner } from 'selectors';

import { ReferredFeatureAnalyticsName } from 'common/constants/premium';
import routes from 'constants/routes';

import { gql } from 'common/generated/gql';
import type { PayloadErrorFieldsFragment } from 'common/generated/graphql';

const InvoicesContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xlarge};
`;

const BillingSettings = () => {
  const { isDemoHousehold, blockActionForDemoHousehold } = useDemoHousehold();
  const history = useHistory();
  const isHouseholdOwner = useSelector(getIsHouseholdOwner);
  const isReferralsV2Active = useIsFeatureFlagOn('referrals-v2');

  const { plans } = usePremiumStripeOfferings();

  const { data, refetch: refetchSubscription } = useQuery(GET_SUBSCRIPTION);

  const { createSubscription } = useCreateSubscription();
  const [updateOrCreatePaymentMethod] = useMutation(UPDATE_OR_CREATE_STRIPE_PAYMENT_METHOD);
  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION_MUTATION);
  const [cancelSponsorship, { loading: cancelSponsorshipMutationLoading }] = useMutation(
    CANCEL_SPONSORSHIP_MUTATION,
  );

  const { reactivateSubscriptionWithPriceId } = useReactivateSubscription();
  const { changeSubscription } = useChangeSubscription();

  const [
    DeleteHouseholdInitialConfirmationModal,
    {
      open: openDeleteHouseholdInitialConfirmation,
      close: closeDeleteHouseholdInitialConfirmation,
    },
  ] = useModal();

  const [
    DeleteHouseholdFinalConfirmationModal,
    { open: openDeleteHouseholdFinalConfirmation, close: closeDeleteHouseholdFinalConfirmation },
  ] = useModal();

  const [
    CancelSponsorshipConfirmationModal,
    { open: openCancelSponsorshipConfirmation, close: closeCancelSponsorshipConfirmation },
  ] = useModal();

  const onClickDeleteHousehold = () =>
    blockActionForDemoHousehold(
      (daysLeftOfTrial ?? 0) > 0
        ? openDeleteHouseholdInitialConfirmation
        : openDeleteHouseholdFinalConfirmation,
      false,
    );

  const openSubscriptionModal = () =>
    blockActionForDemoHousehold(() => {
      history.push(routes.settings.billing.subscribe());
    }, false);

  const closeSubscriptionModal = () => {
    history.push(routes.settings.billing());
  };

  const [PaymentMethodModal, { open: openPaymentMethodModal, close: closePaymentMethodModal }] =
    useModal();

  const { id: activeSponsorshipId } = data?.subscription?.activeSponsorship ?? {};

  const onCancelSponsorship = useCallback(async () => {
    if (RA.isNotNil(activeSponsorshipId)) {
      const { data: responseData } = await cancelSponsorship({
        variables: {
          input: {
            id: activeSponsorshipId,
          },
        },
      });
      const errors: PayloadErrorFieldsFragment | undefined = R.path(
        ['cancelSubscriptionSponsorship', 'errors'],
        responseData,
      );
      if (errors) {
        errorToast(errors.message);
      }
      refetchSubscription();
      closeCancelSponsorshipConfirmation();
    }
  }, [cancelSponsorship, refetchSubscription, activeSponsorshipId]);

  const onUpdatePaymentMethodSuccess = useCallback(
    async ({ id: paymentMethodId }: PaymentMethod) => {
      await updateOrCreatePaymentMethod({
        variables: { input: { paymentMethodId } },
      });
    },
    [updateOrCreatePaymentMethod],
  );

  const daysLeftOfTrial = getDaysLeftOfTrial(data?.subscription?.trialEndsAt);

  return (
    <Column md={9}>
      <Helmet>
        <title>Billing Settings</title>
      </Helmet>
      <SettingsCard title="Billing" isDemo={isDemoHousehold}>
        {!data ? (
          <FlexContainer full center marginVertical="large">
            <LoadingSpinner />
          </FlexContainer>
        ) : (
          <BillingDetailsCard
            plans={plans}
            hasStripeSubscriptionId={data.subscription?.hasStripeSubscriptionId}
            hasChargedForLifetime={data.subscription?.hasChargedForLifetime}
            billingPeriod={data.subscription?.billingPeriod}
            paymentMethod={data.subscription?.paymentMethod}
            paymentSource={data.subscription?.paymentSource ?? null}
            promoCode={data.subscription.activePromoCode}
            accessExpirationDate={data.subscription.currentPeriodEndsAt}
            nextPaymentAmount={data.subscription.nextPaymentAmount}
            nextPaymentDate={data.subscription.currentPeriodEndsAt}
            hasPremium={data.subscription.hasPremiumEntitlement}
            hasBillingIssue={data.subscription.hasBillingIssue}
            willCancelAtPeriodEnd={data.subscription.willCancelAtPeriodEnd}
            eligibleForTrial={data.subscription.eligibleForTrial}
            monthlyPriceDollars={data.constants.monthlyPriceDollars}
            activeSponsorship={data.subscription?.activeSponsorship}
            onClickRevokeSponsorAccess={openCancelSponsorshipConfirmation}
            onClickManageSubscription={openSubscriptionModal}
            onClickUpdatePaymentMethod={openPaymentMethodModal}
            onClickDeleteHousehold={onClickDeleteHousehold}
            creditBalance={data.creditBalance}
            isHouseholdOwner={isHouseholdOwner}
            isOnFreeTrial={data.subscription?.isOnFreeTrial ?? false}
            isReferralsV2Active={isReferralsV2Active}
          />
        )}
      </SettingsCard>
      {data?.invoices && data.invoices.length > 0 && (
        <InvoicesContainer>
          <InvoicesCard invoices={data.invoices} />
        </InvoicesContainer>
      )}
      <RouteModal path={routes.settings.billing.subscribe.path} exact>
        {data ? (
          <ManageSubscriptionFlow
            billingPeriod={data.subscription?.billingPeriod}
            currentPeriodEndsAt={data.subscription.currentPeriodEndsAt}
            createSubscription={createSubscription}
            reactivateSubscription={reactivateSubscriptionWithPriceId}
            changeSubscription={changeSubscription}
            onDone={() => {
              closeSubscriptionModal();
              history.push(routes.settings.billing());
            }}
            cancelMutation={cancelSubscription}
            onCancelSuccess={closeSubscriptionModal}
            onCancelClick={closeSubscriptionModal}
          />
        ) : null}
      </RouteModal>
      <RouteModal path={routes.settings.billing.upgrade.path} exact>
        {({ close }) => <PremiumSubscribeModal next={close} goBack={history.goBack} />}
      </RouteModal>
      <RouteModal path={routes.settings.billing.subscriptionEnded.path} exact>
        {({ close }) => <PremiumSubscriptionEndedModal next={close} />}
      </RouteModal>
      <DeleteHouseholdInitialConfirmationModal>
        <DeleteHouseholdInitialConfirmation
          onCancel={closeDeleteHouseholdInitialConfirmation}
          onConfirm={openDeleteHouseholdFinalConfirmation}
          daysLeftOfTrial={daysLeftOfTrial}
        />
      </DeleteHouseholdInitialConfirmationModal>
      <DeleteHouseholdFinalConfirmationModal>
        <DeleteHouseholdFinalConfirmation onCancel={closeDeleteHouseholdFinalConfirmation} />
      </DeleteHouseholdFinalConfirmationModal>
      <PaymentMethodModal>
        <UpdatePaymentMethodModal
          onSuccess={onUpdatePaymentMethodSuccess}
          onDone={closePaymentMethodModal}
        />
      </PaymentMethodModal>
      <CancelSponsorshipConfirmationModal>
        <RevokeSponsorAccessConfirmation
          onConfirm={onCancelSponsorship}
          onCancel={closeCancelSponsorshipConfirmation}
          isLoading={cancelSponsorshipMutationLoading}
        />
      </CancelSponsorshipConfirmationModal>
      <RouteModal path={routes.settings.billing.premium.path} exact>
        {({ close }) => (
          <PremiumUpgradeFlow
            analyticsName={ReferredFeatureAnalyticsName.Billing}
            onBack={close}
            onComplete={close}
          />
        )}
      </RouteModal>
    </Column>
  );
};

export const UPDATE_OR_CREATE_STRIPE_PAYMENT_METHOD = gql(`
  mutation Web_UpdateOrCreateStripePaymentMethod(
    $input: UpdateOrCreateStripePaymentMethodMutationInput!
  ) {
    updateOrCreateStripePaymentMethod(input: $input) {
      subscription {
        id
        ...SubscriptionFields
        ...SponsoredSubscriptionFields
      }
      errors {
        message
      }
    }
  }
`);

export const GET_SUBSCRIPTION = gql(/* GraphQL */ `
  query Web_GetSubscription {
    subscription {
      id
      ...SubscriptionFields
      ...SponsoredSubscriptionFields
    }
    invoices {
      id
      date
      amount
      receiptUrl
    }
    creditBalance
    constants {
      monthlyPriceDollars
    }
  }
`);

export const CANCEL_SPONSORSHIP_MUTATION = gql(`
  mutation Web_BillingSettingsCancelSponsorship($input: CancelSponsorshipInput!) {
    cancelSubscriptionSponsorship(input: $input) {
      canceled
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export default BillingSettings;
