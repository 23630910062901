import type { ReactNode } from 'react';
import React, { createContext, useContext } from 'react';
import { useLocalstorageState } from 'rooks';

import type {
  FinancialSystemOption,
  BetterUnderstandOption,
  AccomplishmentOption,
  SavingGoalOption,
  FinancesComplexityOption,
} from 'components/onboarding/preRegistration/constants';

const PRE_REGISTRATION_STORAGE_KEY = 'monarch_pre_registration_data';

interface PreRegistrationData {
  financialSystem: FinancialSystemOption | undefined;
  betterUnderstand: BetterUnderstandOption | undefined;
  accomplishments: AccomplishmentOption[] | undefined;
  mostImportant: AccomplishmentOption | undefined;
  savingGoals: SavingGoalOption[] | undefined;
  financesComplexity: FinancesComplexityOption | undefined;
}

interface PreRegistrationContextType {
  data: PreRegistrationData;
  setFinancialSystem: (value: FinancialSystemOption) => void;
  setBetterUnderstand: (value: BetterUnderstandOption) => void;
  setAccomplishments: (values: AccomplishmentOption[]) => void;
  setMostImportant: (value: AccomplishmentOption) => void;
  setSavingGoals: (values: SavingGoalOption[]) => void;
  setFinancesComplexity: (value: FinancesComplexityOption) => void;
}

const defaultPreRegistrationData: PreRegistrationData = {
  financialSystem: undefined,
  betterUnderstand: undefined,
  accomplishments: undefined,
  mostImportant: undefined,
  savingGoals: undefined,
  financesComplexity: undefined,
};

const PreRegistrationContext = createContext<PreRegistrationContextType | undefined>(undefined);

interface PreRegistrationProviderProps {
  children: ReactNode;
}

export const PreRegistrationProvider = ({ children }: PreRegistrationProviderProps) => {
  const [data, setData] = useLocalstorageState(
    PRE_REGISTRATION_STORAGE_KEY,
    defaultPreRegistrationData,
  );

  const setFinancialSystem = (value: FinancialSystemOption) => {
    setData((prev) => ({ ...prev, financialSystem: value }));
  };

  const setBetterUnderstand = (value: BetterUnderstandOption) => {
    setData((prev) => ({ ...prev, betterUnderstand: value }));
  };

  const setAccomplishments = (values: AccomplishmentOption[]) => {
    setData((prev) => ({ ...prev, accomplishments: values }));
  };

  const setMostImportant = (value: AccomplishmentOption) => {
    setData((prev) => ({ ...prev, mostImportant: value }));
  };

  const setSavingGoals = (values: SavingGoalOption[]) => {
    setData((prev) => ({ ...prev, savingGoals: values }));
  };

  const setFinancesComplexity = (value: FinancesComplexityOption) => {
    setData((prev) => ({ ...prev, financesComplexity: value }));
  };

  return (
    <PreRegistrationContext.Provider
      value={{
        data,
        setFinancialSystem,
        setBetterUnderstand,
        setAccomplishments,
        setMostImportant,
        setSavingGoals,
        setFinancesComplexity,
      }}
    >
      {children}
    </PreRegistrationContext.Provider>
  );
};

export const usePreRegistration = () => {
  const context = useContext(PreRegistrationContext);
  if (context === undefined) {
    throw new Error('usePreRegistration must be used within a PreRegistrationProvider');
  }
  return context;
};

// We still export the specific types for use in the components
export type { PreRegistrationData };
