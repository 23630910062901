import React from 'react';

import Form from 'components/lib/form/Form';
import TextField from 'components/lib/form/TextField';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import ManualLink from 'components/lib/ui/link/ManualLink';
import OnboardingFormContainer from 'components/onboarding/OnboardingFormContainer';
import OnboardingFormSubmitButton from 'components/onboarding/OnboardingFormSubmitButton';
import OnboardingSubtitle from 'components/onboarding/OnboardingSubtitle';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';

import getUserApi from 'common/lib/api/user';
import api from 'lib/api';
import { useQueryParam } from 'lib/hooks';
import useToast from 'lib/hooks/useToast';

import { ONBOARDING } from 'common/constants/copy';

type Props = {
  next: (params: { queryParams: { email: string; code: string } }) => void;
};

const OnboardingVerifyEmail = ({ next }: Props) => {
  const email = useQueryParam('email') || '';
  const { openToast, openErrorToast, dismissToast } = useToast();

  const onSubmit = async ({ code }: { code: string }) => {
    await getUserApi(api).verifyEmail({ code, email });
    next({ queryParams: { email, code } });
  };

  const onResend = async () => {
    const successToastId = openToast({
      title: ONBOARDING.VERIFY_EMAIL.RESENT_EMAIL_TITLE,
      description: ONBOARDING.VERIFY_EMAIL.RESENT_EMAIL,
    });

    try {
      await getUserApi(api).createVerification(email);
    } catch {
      dismissToast(successToastId);
      openErrorToast();
    }
  };

  return (
    <>
      <OnboardingTitle>{ONBOARDING.VERIFY_EMAIL.TITLE}</OnboardingTitle>
      <OnboardingSubtitle>{ONBOARDING.VERIFY_EMAIL.SUBTITLE(email)}</OnboardingSubtitle>
      <OnboardingFormContainer>
        <Form onSubmit={onSubmit}>
          <TextField
            name="code"
            required
            placeholder="123456"
            minLength={6}
            autoFocus
            autoFocusOnMobile={false}
            onlyShowApiErrors
          />
          <FlexContainer center marginTop="large" marginBottom="large">
            <Text size="small" align="center">
              Don&apos;t see a code? <ManualLink onClick={onResend}>Resend to email</ManualLink>
            </Text>
          </FlexContainer>
          <OnboardingFormSubmitButton>{ONBOARDING.VERIFY_EMAIL.BUTTON}</OnboardingFormSubmitButton>
        </Form>
      </OnboardingFormContainer>
    </>
  );
};

export default OnboardingVerifyEmail;
