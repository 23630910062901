import { gql } from '@apollo/client';
import React from 'react';
import type { PlaidLinkOnSuccessMetadata } from 'react-plaid-link';

import LinkAccountDataProviderModalWithFallback from 'components/accounts/LinkAccountDataProviderModalWithFallback';
import LinkAccountLoadingModal from 'components/accounts/LinkAccountLoadingModal';

import { useStackContext } from 'common/lib/contexts/StackContext';

import type { DataProviderLegacy, LinkInstitutionFieldsFragment } from 'common/generated/graphql';

export type InstitutionFields = Pick<
  LinkInstitutionFieldsFragment,
  'preferredDataProvider' | 'finicityInstitutionId' | 'plaidInstitutionId' | 'mxInstitutionId'
> & {
  name?: string;
  id?: string;
};

export type LinkAccountSuccessMetadata = {
  dataProvider?: DataProviderLegacy;
  institution?: InstitutionFields;
  plaidLinkToken?: string;
  plaidLinkMetadata?: PlaidLinkOnSuccessMetadata;
  coinbaseCode?: string;
  accountIds?: string[];
};

export type Props = {
  institution?: InstitutionFields;
  /** If provided, this will be used instead of institution.preferredDataProvider */
  dataProvider?: DataProviderLegacy;
  /** Set this after plaid oauth redirect */
  cachedPlaidLinkToken?: string;
  /** Set this after plaid oauth redirect */
  plaidReceivedRedirectUri?: string;
};

const LinkAccountDataProviderModal = ({
  institution,
  dataProvider,
  cachedPlaidLinkToken,
  plaidReceivedRedirectUri,
}: Props) => {
  const { replace, pop } = useStackContext();

  const next = (params: { metadata: LinkAccountSuccessMetadata }) => {
    replace(LinkAccountLoadingModal, params);
  };

  return (
    <LinkAccountDataProviderModalWithFallback
      institution={institution}
      dataProvider={dataProvider}
      cachedPlaidLinkToken={cachedPlaidLinkToken}
      plaidReceivedRedirectUri={plaidReceivedRedirectUri}
      next={next}
      goBack={pop}
    />
  );
};

export const LINK_INSTITUTION_FIELDS_FRAGMENT = gql`
  fragment LinkInstitutionFields on Institution {
    id
    name
    plaidInstitutionId
    finicityInstitutionId
    mxInstitutionId
    preferredDataProvider
    firstBackupDataProvider
    logo
    status
    newConnectionsDisabled
    hasIssuesReported
    hasIssuesReportedMessage
    linkFlowWarnBeforeConnecting
    linkFlowWarningMessage
    linkFlowWarningTitle
  }
`;

export default LinkAccountDataProviderModal;
