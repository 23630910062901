/** Properties of theme which do not change in response to environment. */
const DEFAULT_EASING = 'ease-out';
const DEFAULT_TRANSITION_DURATION = 0.1;
// Ease quart isn't a standard easing function, but the shape of the curve is that
// of a fourth of a circle (quart). It is similar to easeOutQuart except the start & end
// share more of a similar slope on the easing curve.
const EASE_QUART = 'cubic-bezier(0, 0, 0, 1)';

const staticTheme = {
  fontSize: {
    xxsmall: '10px',
    xsmall: '12px',
    small: '14px',
    base: '16px',
    large: '18px',
    xlarge: '24px',
    xxlarge: '30px',
    xxxlarge: '40px',
  } as const,
  spacing: {
    xxxsmall: '2px',
    xxsmall: '4px',
    xsmall: '8px',
    small: '12px',
    medium: '14px',
    default: '16px',
    large: '20px',
    xlarge: '24px',
    xxlarge: '32px',
    xxxlarge: '48px',
    xxxxlarge: '64px',
  } as const,
  radius: {
    xxsmall: '2px',
    xsmall: '3px',
    small: '4px',
    medium: '8px',
    large: '12px',
    xlarge: '16px',
    round: '100%',
    pill: '500px', // Make pill shaped (https://stackoverflow.com/a/18795153)
  } as const,
  fontFamily: {
    primary: 'Oracle',
    primaryStyle: 'Oracle, sans-serif',
    secondary: 'Copernicus',
    secondaryStyle: 'Copernicus, serif',
  } as const,
  fontWeight: {
    book: 'normal',
    medium: '500',
    bold: '600',
  } as const,
  transition: {
    defaultEasing: DEFAULT_EASING,
    defaultDuration: DEFAULT_TRANSITION_DURATION,
    defaultDurationMs: DEFAULT_TRANSITION_DURATION * 1000,
    default: `all ${DEFAULT_TRANSITION_DURATION}s ${DEFAULT_EASING}`,
    easeQuart: EASE_QUART,
    quart: `all ${DEFAULT_TRANSITION_DURATION}s ${EASE_QUART}`,
    fast: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
    createDefaultTransition: (props: string[]) =>
      props.map((p) => `${p} ${DEFAULT_TRANSITION_DURATION}s ${DEFAULT_EASING}`).join(', '),
    /** Generates a list of CSS transition properties with a fast timing function. */
    createFastTransitions: (props: string[]) =>
      props.map((p) => `${p} 150ms cubic-bezier(0.4, 0, 0.2, 1)`),
  } as const,
  breakPoints: { xxs: 400, xs: 576, sm: 768, md: 992, lg: 1200, xl: 1360 } as const,
};

export default staticTheme;
