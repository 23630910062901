import { useQuery } from '@apollo/client';
import pluralize from 'pluralize';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import type { Props as SelectProps } from 'components/lib/form/Select';
import Select from 'components/lib/form/Select';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Tooltip from 'components/lib/ui/Tooltip';

import { GET_MERCHANTS_SEARCH } from 'common/lib/graphQl/merchants';
import useToggle from 'common/lib/hooks/useToggle';

import type { GetMerchantsSearchQuery } from 'common/generated/graphql';
import type { ElementOf } from 'common/types/utility';
import type { OptionType } from 'types/select';

type GetMerchantsSearchMerchant = ElementOf<GetMerchantsSearchQuery, 'merchants'>;

const MERCHANT_SEARCH_LIMIT = 20;

export type MerchantOption = OptionType & {
  transactionCount?: number;
};

type Props = Omit<SelectProps, 'value'> & {
  className?: string;
  value: MerchantOption;
  formatOptions?: (merchant: GetMerchantsSearchMerchant[]) => MerchantOption[];
  formatValue?: (
    option: MerchantOption,
  ) => (MerchantOption & (string | string[] | MerchantOption)) | null | undefined;
};

export const MerchantSelectCreditCardIcon = styled(Icon).attrs({ name: 'credit-card' })`
  width: ${({ theme }) => theme.spacing.small};
  height: ${({ theme }) => theme.spacing.small};
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

export const MerchantCountContainer = styled(FlexContainer).attrs({ alignCenter: true })`
  color: ${({ theme }) => theme.color.textLight};

  .react-select__option--is-focused & {
    color: ${({ theme }) => theme.color.blue};
  }
  .react-select__option--is-selected & {
    color: ${({ theme }) => theme.color.white};
  }
  .react-select__single-value & {
    display: none;
  }
`;

const CreateOptionLabel = styled.span`
  color: ${({ theme }) => theme.color.blue9};
`;

const OptionRow = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })`
  flex: 1;
`;

const defaultMerchantsFormatter = (merchants: GetMerchantsSearchMerchant[]): MerchantOption[] =>
  merchants.map(({ name, transactionCount }) => ({
    label: name,
    value: name,
    transactionCount,
  }));

/**
 * This component is used for select async merchant options
 * commonly used when we need to search for one merchant.
 * Does not work with isMulti.
 */
const MerchantSearchSelect = ({
  value,
  formatValue,
  formatOptions = defaultMerchantsFormatter,
  ...props
}: Props) => {
  const { refetch: getMerchants } = useQuery(
    GET_MERCHANTS_SEARCH,
    // 'network-only' because if we use the default (cache-and-network), the refetch promise
    // resolves from the cached value (initially empty) while doing the network request
    // in the background. We want to loadOptions from the network response.
    { skip: true, fetchPolicy: 'network-only' },
  );

  const [isMenuOpen, { setOn: setOpen, setOff: setClosed }] = useToggle(false);

  const formattedValue = useMemo(() => {
    if (formatValue) {
      return formatValue(value);
    } else {
      return typeof value === 'string' && value !== '' ? { value, label: value } : value;
    }
  }, [value, formatValue]);

  return (
    <Select<MerchantOption>
      placeholder="Search merchants..."
      isCreatable
      {...props}
      openMenuOnFocus
      value={formattedValue}
      onMenuOpen={setOpen}
      onMenuClose={setClosed}
      controlShouldRenderValue={props.isMulti || !isMenuOpen}
      defaultOptions={
        props.defaultOptions ?? [typeof value === 'string' ? { value, label: value } : value ?? {}]
      }
      formatCreateLabel={(input: string) => (
        <CreateOptionLabel>Create new &quot;{input}&quot; merchant</CreateOptionLabel>
      )}
      cacheOptions
      loadOptions={async (search: string) => {
        const response = await getMerchants({ search, limit: MERCHANT_SEARCH_LIMIT });
        const res = formatOptions(response.data.merchants ?? []);
        return res;
      }}
      renderOption={({ label, transactionCount }) => (
        <OptionRow>
          <span>{label}</span>
          {!!transactionCount && (
            <Tooltip
              content={`${transactionCount} existing ${pluralize(
                'transaction',
                transactionCount,
              )} for this merchant`}
            >
              <MerchantCountContainer>
                <MerchantSelectCreditCardIcon />
                <span>{transactionCount}</span>
              </MerchantCountContainer>
            </Tooltip>
          )}
        </OptionRow>
      )}
    />
  );
};

export default MerchantSearchSelect;
