import React from 'react';
import styled from 'styled-components';

import {
  AccountColumn,
  ColumnWrapper as AccountColumnWrapper,
} from 'components/accounts/transferAccountData/AccountColumn';
import AccountDownloads from 'components/accounts/transferAccountData/AccountDownloads';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import type { StepProps } from 'components/routes/accounts/transferAccountData/TransferAccountData';
import TransferAccountDataStep from 'components/routes/accounts/transferAccountData/TransferAccountDataStep';

import { spacing, variables } from 'common/lib/theme/dynamic';
import { getProgressPercent } from 'lib/accounts/transferAccountData/utils/progress';
import useDataTransferAccounts from 'lib/hooks/accounts/useDataTransferAccounts';

const SecondaryTitleText = styled(Text)`
  color: ${variables.color.content.secondary};
`;

const BackupWrapper = styled(FlexContainer)`
  ${AccountColumnWrapper} {
    width: calc(50% - ${spacing.gutter} / 2);
    min-width: 0;
    flex: 0 0 calc(50% - ${spacing.gutter} / 2);
  }
`;

const Backup: React.FC<StepProps> = ({ params, next }) => {
  const { fromAccountId, toAccountId } = params;

  const { data: accountsForTransfer, loading: loadingAccountsForTransfer } =
    useDataTransferAccounts(fromAccountId, toAccountId);

  return (
    <TransferAccountDataStep
      exitAccountId={fromAccountId}
      overrideTitle="Backup"
      progress={getProgressPercent(4)}
      isLoading={loadingAccountsForTransfer || !fromAccountId || !toAccountId}
      footer={
        <PrimaryButton size="large" onClick={() => next()}>
          Next up: Review
        </PrimaryButton>
      }
    >
      <FlexContainer column marginTop="xxlarge" marginBottom="xxlarge" gap="xxlarge">
        <FlexContainer column gap="default">
          <Flex column>
            <Text size="large" weight="medium">
              Data backup <SecondaryTitleText size="large">(optional)</SecondaryTitleText>
            </Text>
            <Text>
              We recommend downloading backup files before the transfer. While it’s unlikely you’ll
              need it, some data will be permanently deleted from Monarch after the transfer is
              complete.
            </Text>
          </Flex>
          <BackupWrapper gap="gutter" full>
            <AccountColumn
              title="Old"
              subtitle="(We'll move data from this account)"
              account={accountsForTransfer?.fromAccount}
            >
              <AccountDownloads accountId={fromAccountId!} />
            </AccountColumn>
            <AccountColumn
              title="New"
              subtitle="(We'll move data to this account)"
              account={accountsForTransfer?.toAccount}
            >
              <AccountDownloads accountId={toAccountId!} />
            </AccountColumn>
          </BackupWrapper>
        </FlexContainer>
      </FlexContainer>
    </TransferAccountDataStep>
  );
};

export default Backup;
