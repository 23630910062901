import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Text from 'components/lib/ui/Text';
import TextButton from 'components/lib/ui/TextButton';
import TransactionsDownloadInvisibleButton from 'components/transactions/TransactionsDownloadInvisibleButton';

import { spacing, variables } from 'common/lib/theme/dynamic';
import { formatISODate } from 'common/utils/date';
import { track } from 'lib/analytics/segment';
import useDownloadBalances from 'lib/hooks/accounts/useDownloadBalances';
import useDownloadTransactions from 'lib/hooks/statements/useDownloadTransactions';
import { DEFAULT_FILTERS } from 'lib/transactions/Filters';

import { TransferAccountDataEventNames } from 'common/constants/analytics';

const filenameDate = formatISODate(new Date());

enum DownloadType {
  TRANSACTIONS = 'transactions',
  BALANCES = 'balances',
}

enum DownloadStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
}

type DownloadStatusType = DownloadStatus | null;

const DownloadSection = styled(FlexContainer)`
  border-top: 1px solid ${variables.color.divider.secondary};
  padding: ${spacing.small} ${spacing.default};
`;

const StyledIcon = styled(Icon)`
  margin-right: ${spacing.xxsmall};
`;

const DownloadButton = styled(TextButton)`
  padding: 0;
  justify-content: flex-start;
`;

const ThinLoadingSpinner = styled(LoadingSpinner)`
  border-width: 1px;
`;

const StatusIcon = styled(Icon)<{ $success?: boolean }>`
  color: ${({ $success = true }) =>
    $success ? variables.color.content.success : variables.color.content.danger};
`;

type AccountDownloadProps = {
  accountId: string;
};

const AccountDownloads: React.FC<AccountDownloadProps> = ({ accountId }) => {
  const [balancesDownloadStatus, setBalancesDownloadStatus] = useState<DownloadStatusType>(null);
  const [transactionsDownloadStatus, setTransactionsDownloadStatus] =
    useState<DownloadStatusType>(null);

  const { downloadBalances } = useDownloadBalances({
    onSuccess: () => {
      setBalancesDownloadStatus(DownloadStatus.SUCCESS);
      track(TransferAccountDataEventNames.TransferAccountDataBackupDownloaded, {
        accountId,
        downloadType: DownloadType.BALANCES,
        status: DownloadStatus.SUCCESS,
      });
    },
    onError: () => {
      setBalancesDownloadStatus(DownloadStatus.ERROR);
      track(TransferAccountDataEventNames.TransferAccountDataBackupDownloaded, {
        accountId,
        downloadType: DownloadType.BALANCES,
        status: DownloadStatus.ERROR,
      });
    },
  });

  const {
    currentSession: transactionsSession,
    downloadTransactions: originalDownloadTransactions,
    isLoading: isLoadingDownloadTransactions,
  } = useDownloadTransactions({
    filters: { ...DEFAULT_FILTERS, accounts: [accountId] },
    onParseSuccess: () => {
      setTransactionsDownloadStatus(DownloadStatus.SUCCESS);
      track(TransferAccountDataEventNames.TransferAccountDataBackupDownloaded, {
        accountId,
        downloadType: DownloadType.TRANSACTIONS,
        status: DownloadStatus.SUCCESS,
      });
    },
    onParseError: () => {
      setTransactionsDownloadStatus(DownloadStatus.ERROR);
      track(TransferAccountDataEventNames.TransferAccountDataBackupDownloaded, {
        accountId,
        downloadType: DownloadType.TRANSACTIONS,
        status: DownloadStatus.ERROR,
      });
    },
  });

  const downloadTransactions = useCallback(() => {
    setTransactionsDownloadStatus(DownloadStatus.LOADING);
    originalDownloadTransactions();
  }, [originalDownloadTransactions]);

  const handleDownloadBalances = useCallback(async () => {
    setBalancesDownloadStatus(DownloadStatus.LOADING);
    try {
      await downloadBalances([accountId], {
        showToast: false,
        fileName: `balances-${accountId}-${filenameDate}.csv`,
      });
    } catch (error) {
      setBalancesDownloadStatus(DownloadStatus.ERROR);
    }
  }, [downloadBalances, accountId]);

  // Helper function to render the appropriate status indicator
  const renderStatusIndicator = (status: DownloadStatusType) => {
    switch (status) {
      case DownloadStatus.LOADING:
        return <ThinLoadingSpinner $size={16} />;
      case DownloadStatus.SUCCESS:
        return <StatusIcon size={16} $success name="check-circle" />;
      case DownloadStatus.ERROR:
        return <StatusIcon size={16} $success={false} name="x-circle" />;
      default:
        return null;
    }
  };

  return (
    <FlexContainer column>
      <DownloadSection>
        <FlexContainer full column gap="xxxsmall">
          <Text weight="medium">Transactions</Text>
          <DownloadButton
            onClick={downloadTransactions}
            disabled={
              isLoadingDownloadTransactions || transactionsDownloadStatus === DownloadStatus.LOADING
            }
          >
            <Text weight="medium" size="small">
              <StyledIcon name="download" />
              Download
            </Text>
          </DownloadButton>
        </FlexContainer>
        <FlexContainer center>{renderStatusIndicator(transactionsDownloadStatus)}</FlexContainer>
      </DownloadSection>
      <DownloadSection>
        <FlexContainer full column gap="xxxsmall">
          <Text weight="medium">Balances</Text>
          <DownloadButton
            onClick={handleDownloadBalances}
            disabled={balancesDownloadStatus === DownloadStatus.LOADING}
          >
            <Text weight="medium" size="small">
              <StyledIcon name="download" />
              Download
            </Text>
          </DownloadButton>
        </FlexContainer>
        <FlexContainer center>{renderStatusIndicator(balancesDownloadStatus)}</FlexContainer>
      </DownloadSection>
      <TransactionsDownloadInvisibleButton downloadUrl={transactionsSession?.url ?? undefined} />
    </FlexContainer>
  );
};

export default AccountDownloads;
