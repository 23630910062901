import { transparentize } from 'polished';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import OnboardingFooterNav from 'components/onboarding/OnboardingFooterNav';
import OnboardingSankey from 'components/onboarding/OnboardingSankey';
import OnboardingSubtitle from 'components/onboarding/OnboardingSubtitle';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';
import { GET_ACCOUNTS_PAGE } from 'components/routes/Accounts';

import useQuery from 'common/lib/hooks/useQuery';
import useIsFeatureFlagOff from 'lib/hooks/useIsFeatureFlagOff';

import * as COPY from 'common/constants/copy';

const SankeyView = styled(FlexContainer)`
  width: 100%;
  margin: auto;
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
`;

const GradientOverlay = styled.div<{ $isLoading?: boolean }>`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 300px;
  background: linear-gradient(
    0deg,
    ${({ theme }) => theme.color.white} 80%,
    ${({ theme }) => transparentize(1, theme.color.white)} 100%
  );

  justify-content: center;
  align-items: center;
  display: ${({ $isLoading }) => ($isLoading ? 'none' : 'flex')};

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    background: linear-gradient(
      0deg,
      ${({ theme }) => theme.color.white} 80%,
      ${({ theme }) => transparentize(1, theme.color.white)} 100%
    );

    height: 120px;
    padding: ${({ theme }) => theme.spacing.xxxxlarge} 0;
  }
`;

const ContinueButton = styled(PrimaryButton)`
  width: 100%;
`;

const SankeyWrapper = styled.div`
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakPoints.lg}px) {
    zoom: 0.7;
  }
  @media (max-width: ${({ theme }) => theme.breakPoints.md}px) {
    zoom: 0.6;
  }
  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    zoom: 0.5;
  }
  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    zoom: 0.4;
  }
`;

type Props = {
  next: () => void;
};

const OnboardingConnectSingleAccountDone = ({ next }: Props) => {
  const { data, isLoadingInitialData } = useQuery(GET_ACCOUNTS_PAGE);

  const { hasAccounts } = data ?? {};

  const isAbTestOnboardingSankey = useIsFeatureFlagOff('ab-test-onboarding-sankey', {
    trackImpression: true,
  });

  /*
    skips the page when the ab-test-onboarding-sankey feature flag is off or if the user hasn't connected an account.
    This feature flag is used to let users connect accounts before the paywall.
  */
  useEffect(() => {
    if (isAbTestOnboardingSankey || (!isLoadingInitialData && !hasAccounts)) {
      next();
    }
  }, [isAbTestOnboardingSankey, isLoadingInitialData, hasAccounts]);

  return (
    <>
      <OnboardingTitle>Visualize your cash flow like never before</OnboardingTitle>
      <OnboardingSubtitle>
        As soon as you subscribe, you can access interactive diagrams like this. It gives you a
        clear and engaging view of where your money comes from and where it goes each month. Take a
        look at what it looks like with your data.
      </OnboardingSubtitle>
      <SankeyView>
        <SankeyWrapper>
          <OnboardingSankey />
        </SankeyWrapper>
        <GradientOverlay $isLoading={isLoadingInitialData}>
          <OnboardingFooterNav>
            <ContinueButton size="medium" type="button" onClick={next}>
              {COPY.ONBOARDING.SPENDING_INSIGHTS.BUTTON}
            </ContinueButton>
          </OnboardingFooterNav>
        </GradientOverlay>
      </SankeyView>
    </>
  );
};

export default OnboardingConnectSingleAccountDone;
