import _ from 'lodash';
import React, { useRef, useEffect, useCallback, useMemo, useImperativeHandle } from 'react';
import { scroller } from 'react-scroll';
import { useUIDSeed } from 'react-uid';
import styled, { css } from 'styled-components';

import ScrollContext from 'lib/contexts/ScrollContext';
import useEventListener from 'lib/hooks/useEventListener';
import type { ScrollOffset } from 'state/scroll/reducer';

const DEFAULT_SCROLL_DEBOUNCE_MS = 100;
const DEFAULT_SCROLL_TO_DURATION_MS = 500;

type Props = {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  initialOffset?: ScrollOffset;
  onScroll?: (scrollTop: number, scrollLeft: number) => void;
  scrollHandlerDebounceMs?: number;
  hideScrollBar?: boolean;
  marginBottom?: number;
};

export type ScrollHandles = {
  scrollTo: (elementName: string, options?: any) => void;
};

const Root = styled.div<{
  disabled: boolean;
  hideScrollBar: boolean;
  marginBottom: number;
}>`
  overflow: auto;
  flex: 1;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}

  ${({ hideScrollBar }) =>
    hideScrollBar &&
    css`
      &::-webkit-scrollbar {
        display: none; /* Chrome, Opera, Safari */
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    `}
`;

const Scroll: React.ForwardRefRenderFunction<ScrollHandles, Props> = (
  {
    children,
    className,
    disabled = false,
    initialOffset,
    onScroll,
    scrollHandlerDebounceMs = DEFAULT_SCROLL_DEBOUNCE_MS,
    hideScrollBar = false,
    marginBottom = 0,
  },
  ref,
) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  // We need a unique id per Scroll, so that we can use it as containerId for 'react-scroll'.
  const seed = useUIDSeed();
  const scrollContainerId = seed('scroll');

  useEventListener(
    scrollRef.current,
    'scroll',
    _.debounce((e) => {
      onScroll?.(scrollRef.current?.scrollTop ?? 0, scrollRef.current?.scrollLeft ?? 0);
    }, scrollHandlerDebounceMs),
  );

  // Scroll to initial offset
  const { scrollTop: initialTop, scrollLeft: initialLeft } = initialOffset ?? {};
  useEffect(() => {
    window.requestAnimationFrame(() => {
      if (scrollRef.current) {
        if (initialTop) {
          scrollRef.current.scrollTop = initialTop;
        }
        if (initialLeft) {
          scrollRef.current.scrollLeft = initialLeft;
        }
      }
    });
  }, [scrollRef, initialTop, initialLeft]);

  const scrollTo = useCallback(
    (elementName: string, options?: any) =>
      scroller.scrollTo(elementName, {
        smooth: true,
        containerId: scrollContainerId,
        duration: DEFAULT_SCROLL_TO_DURATION_MS,
        ...options,
      }),
    [scrollContainerId],
  );

  useImperativeHandle(ref, () => ({
    scrollTo,
  }));

  const context = useMemo(
    () => ({
      scrollRef,
      scrollContainerId,
      scrollTo,
    }),
    [scrollRef, scrollContainerId, scrollTo],
  );

  return (
    <Root
      ref={scrollRef}
      id={scrollContainerId}
      className={className}
      disabled={disabled}
      hideScrollBar={hideScrollBar}
      marginBottom={marginBottom}
    >
      <ScrollContext.Provider value={context}>{children}</ScrollContext.Provider>
    </Root>
  );
};

export default React.forwardRef(Scroll);
