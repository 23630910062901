import data from '@emoji-mart/data';
import { init } from 'emoji-mart';
import { useIsWalmartUser } from 'hooks/useIsWalmartUser';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import CoinbaseOAuth from 'components/accounts/CoinbaseOAuth';
import EmployeeMenuButton from 'components/employee/EmployeeMenuButton';
import RenderProfilerDynamic from 'components/employee/RenderProfilerDynamic';
import FeatureFlagGate from 'components/lib/higherOrder/FeatureFlagGate';
import FeatureFlagRoute from 'components/lib/higherOrder/FeatureFlagRoute';
import AdvisorRoute from 'components/lib/routing/AdvisorRoute';
import EmployeeRoute from 'components/lib/routing/EmployeeRoute';
import LoggedInRoute from 'components/lib/routing/LoggedInRoute';
import DemoBanner from 'components/lib/ui/DemoBanner';
import Flex from 'components/lib/ui/Flex';
import FooterButtonContainer from 'components/lib/ui/FooterButtonContainer';
import MonarchWatermark from 'components/lib/ui/MonarchWatermark';
import OutageBanner from 'components/lib/ui/OutageBanner';
import { PreRegistrationProvider } from 'components/onboarding/preRegistration/PreRegistrationContext';
import AcceptHouseholdInvite from 'components/routes/AcceptHouseholdInvite';
import AccountDetails from 'components/routes/AccountDetails';
import Accounts from 'components/routes/Accounts';
import ActAsUser from 'components/routes/ActAsUser';
import AdvisorOnboardingFlow from 'components/routes/AdvisorOnboardingFlow';
import Assistant from 'components/routes/Assistant';
import AssistantEvaluation from 'components/routes/AssistantEvaluation';
import AuthConfirmation from 'components/routes/AuthConfirmation';
import CashFlow from 'components/routes/CashFlow';
import CashFlowSankey from 'components/routes/CashFlowSankey';
import CategoryDetails from 'components/routes/CategoryDetails';
import CategoryGroupDetails from 'components/routes/CategoryGroupDetails';
import Dashboard from 'components/routes/Dashboard';
import DemoOnboardingFlow from 'components/routes/DemoOnboardingFlow';
import Hackathon from 'components/routes/Hackathon';
import Health from 'components/routes/Health';
import Links from 'components/routes/Links';
import Login from 'components/routes/Login';
import Logout from 'components/routes/Logout';
import MerchantDetails from 'components/routes/MerchantDetails';
import NotFound from 'components/routes/NotFound';
import OnboardingFlow from 'components/routes/OnboardingFlow';
import Oops from 'components/routes/Oops';
import RetailSyncAuthentication from 'components/routes/RetailSyncAuthentication';
import SideBar from 'components/routes/SideBar';
import SpendingInsights from 'components/routes/SpendingInsights';
import SplitClientTest from 'components/routes/SplitClientTest';
import SponsorshipUnsubscribedSuccess from 'components/routes/SponsorshipUnsubscribedSuccess';
import StartDemo from 'components/routes/StartDemo';
import Transactions from 'components/routes/Transactions';
import Version from 'components/routes/Version';
import WalmartOnboardingFlow from 'components/routes/WalmartOnboardingFlow';
import ImportMintBalanceHistory from 'components/routes/accounts/ImportMintBalanceHistory';
import ImportMintData from 'components/routes/accounts/ImportMintData';
import ImportMintTransactions from 'components/routes/accounts/ImportMintTransactions';
import TransferAccountData from 'components/routes/accounts/transferAccountData/TransferAccountData';
import Advice from 'components/routes/advice/Advice';
import AdviceQuestionnaire from 'components/routes/advice/AdviceQuestionnaire';
import QuestionnaireIntro from 'components/routes/advice/QuestionnaireIntro';
import AdvisorBilling from 'components/routes/advisorPortal/AdvisorBilling';
import AdvisorClients from 'components/routes/advisorPortal/AdvisorClients';
import AdvisorProfile from 'components/routes/advisorPortal/AdvisorProfile';
import AdvisorSecurity from 'components/routes/advisorPortal/AdvisorSecurity';
import BudgetOnboardingFlow from 'components/routes/budget/BudgetOnboardingFlow';
import GoalDetailsV2 from 'components/routes/goalsV2/GoalDetailsV2';
import GoalsMapAccounts from 'components/routes/goalsV2/GoalsMapAccounts';
import GoalsMonthlyBudgets from 'components/routes/goalsV2/GoalsMonthlyBudgets';
import GoalsPreTaxBudgets from 'components/routes/goalsV2/GoalsPreTaxBudgets';
import GoalsTargetAmounts from 'components/routes/goalsV2/GoalsTargetAmounts';
import GoalsV2 from 'components/routes/goalsV2/GoalsV2';
import RankGoals from 'components/routes/goalsV2/RankGoals';
import SelectGoals from 'components/routes/goalsV2/SelectGoals';
import Investments from 'components/routes/investments/Investments';
import ContinueWithExternalAuth from 'components/routes/login/ContinueWithExternalAuth';
import CreatePassword from 'components/routes/password/CreatePassword';
import ForgotPassword from 'components/routes/password/ForgotPassword';
import ResetPassword from 'components/routes/password/ResetPassword';
import Plan from 'components/routes/plan/Plan';
import MapSpinwheelLiabilities from 'components/routes/recurring/MapSpinwheelLiabilities';
import Recurring from 'components/routes/recurring/Recurring';
import Reports from 'components/routes/reports/Reports';
import Settings from 'components/routes/settings/Settings';
import SessionTimeout from 'components/session/SessionTimeout';
import BlockApp from 'components/site/BlockApp';
import DemoHouseholdModal from 'components/site/DemoHouseholdModal';
import DynamicHead from 'components/site/DynamicHead';
import MobileBrowserWarning from 'components/site/MobileBrowserWarning';
import ContactSupportChat from 'components/support/chat/ContactSupportChat';

import { appLoaded } from 'actions';
import { ContactSupportContext } from 'lib/contexts/ContactSupportContext';
import useCustomerioLinkTracking from 'lib/hooks/external/useCustomerioLinkTracking';
import { PlanTimeframe } from 'lib/hooks/plan/usePlanState';
import useAppStatusWhileNavigating from 'lib/hooks/useAppStatusWhileNavigating';
import useBlockAppWithMessage from 'lib/hooks/useBlockAppWithMessage';
import useDebugAnonymousId from 'lib/hooks/useDebugAnonymousId';
import useDemoHousehold from 'lib/hooks/useDemoHousehold';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import useShouldShowMobileBrowserWarning from 'lib/hooks/useShouldShowMobileBrowserWarning';
import { getUserIsLoggedIn } from 'selectors';

import routes from 'constants/routes';

init({ data });

export const SIDE_DRAWER_ROOT_NODE_ID = 'side-drawer-root';

const Root = styled(Flex)<{ $top: number }>`
  position: absolute;
  top: ${({ $top }) => $top}px;
  left: 0;
  right: 0;
  bottom: 0;
`;

const DEFAULT_BANNER_HEIGHT_PX = 40;

const App = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getUserIsLoggedIn);
  const forceHideStatusLabelApps = useIsFeatureFlagOn('hide-status-label-apps');

  const { shouldShowStatus } = useAppStatusWhileNavigating();
  const { shouldShowBanner, isDemoHouseholdModalVisible } = useDemoHousehold();
  useCustomerioLinkTracking();
  useDebugAnonymousId();

  const hasBannerVisible = (!forceHideStatusLabelApps && shouldShowStatus) || shouldShowBanner;

  useEffect(() => {
    dispatch(appLoaded());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const showMobileBrowserWarning = useShouldShowMobileBrowserWarning();
  const [shouldBlockApp, blockAppInfo] = useBlockAppWithMessage();

  const isWalmartUser = useIsWalmartUser();

  if (shouldBlockApp) {
    return <BlockApp message={blockAppInfo.title} description={blockAppInfo.description} />;
  }

  return (
    <>
      <DynamicHead />
      {isLoggedIn && <SessionTimeout />}
      {!forceHideStatusLabelApps && <OutageBanner />}
      <Root $top={hasBannerVisible ? DEFAULT_BANNER_HEIGHT_PX : 0}>
        <Switch>
          <Route path={routes.actAsUser.path} component={ActAsUser} />
          <Route path={routes.authConfirmation.path} component={AuthConfirmation} />
          <Route path={routes.links.path} component={Links} />
          <Route path={routes.logout.path} component={Logout} />
          <Route path={routes.login.path} component={Login} />
          <Route path={routes.authProviderLogin.path} component={ContinueWithExternalAuth} />
          <Route path={routes.signup.path}>
            <PreRegistrationProvider>
              {isWalmartUser ? <WalmartOnboardingFlow /> : <OnboardingFlow />}
            </PreRegistrationProvider>
          </Route>
          <Route path={routes.demo.startDemo.path} component={StartDemo} />
          <Route path={routes.demo.path} component={DemoOnboardingFlow} />
          <FeatureFlagRoute
            name="advisor-onboarding-web"
            path={routes.advisors.signup.path}
            component={AdvisorOnboardingFlow}
          />
          <Route path={routes.household.acceptInvite.path} component={AcceptHouseholdInvite} />
          <Route path={routes.resetPassword.path} component={ResetPassword} />
          <Route path={routes.forgotPassword.path} component={ForgotPassword} />
          <Route path={routes.createPassword.path} component={CreatePassword} />
          <Route path={routes.oops.path} component={Oops} />
          <Route
            path={routes.sponsorshipInviteUnsubscribeSuccess.path}
            component={SponsorshipUnsubscribedSuccess}
          />
          <Route path={routes.health.path} component={Health} />
          <Route path={routes.version.path} component={Version} />
          <LoggedInRoute
            path={routes.retailSyncAuthentication.path}
            component={RetailSyncAuthentication}
          />
          <Route path={routes.advice.questionnaire.path} component={AdviceQuestionnaire} />
          <Route path={routes.advice.questionnaireIntro.path} component={QuestionnaireIntro} />
          <Route path={routes.goalsV2.preTaxBudgets.path} component={GoalsPreTaxBudgets} />
          <Route path={routes.goalsV2.monthlyBudgets.path} component={GoalsMonthlyBudgets} />
          <Route path={routes.goalsV2.mapAccounts.path} component={GoalsMapAccounts} />
          <Route path={routes.goalsV2.targetAmounts.path} component={GoalsTargetAmounts} />
          <Route path={routes.goalsV2.rank.path} component={RankGoals} />
          <Route path={routes.goalsV2.select.path} component={SelectGoals} />
          <Route path={routes.budget.onboarding.path} component={BudgetOnboardingFlow} />

          <LoggedInRoute path={routes.accounts.importMintData.path} component={ImportMintData} />

          <LoggedInRoute
            path={routes.recurring.mapLiabilities.path}
            component={MapSpinwheelLiabilities}
          />

          <LoggedInRoute
            path={routes.accounts.importMintTransactions.path}
            component={ImportMintTransactions}
          />
          <LoggedInRoute
            path={routes.accounts.importMintBalanceHistory.path}
            component={ImportMintBalanceHistory}
          />

          <FeatureFlagRoute
            name="transfer-account-data"
            path={routes.accounts.transferAccountData.path}
            component={TransferAccountData}
          />

          <LoggedInRoute path="/">
            {shouldShowBanner && <DemoBanner />}
            <SideBar />
            <Switch>
              <Route exact path="/">
                <Redirect to={routes.dashboard()} />
              </Route>
              <Route exact path="/request-feature">
                <Redirect to={`${routes.dashboard()}/request-feature`} />
              </Route>
              <Route exact path="/billing">
                {/* Mobile links to /billing */}
                <Redirect to={routes.settings.billing()} />
              </Route>
              <Route path={routes.accounts.accountDetails.path} component={AccountDetails} />
              <Route path={routes.categories.path} component={CategoryDetails} />
              <Route path={routes.categoryGroups.path} component={CategoryGroupDetails} />
              <Route path={routes.merchants.path} component={MerchantDetails} />
              <Route path={routes.accounts.path} component={Accounts} />
              <Route path={routes.cashFlow.path} component={CashFlow} />
              <Route path={routes.sankey.path} component={CashFlowSankey} />
              <Route path={routes.budget.path}>
                {/* Some email links may still use this url */}
                <Redirect
                  to={routes.plan({ queryParams: { timeframe: PlanTimeframe.SingleMonth } })}
                />
              </Route>
              <Route path={routes.goalsV2.goalDetail.path} component={GoalDetailsV2} />
              <Route path={routes.goalsV2.path} component={GoalsV2} />
              <Route path={routes.planning.path}>
                {/* Some email links may still use this url */}
                <Redirect to={routes.plan({ queryParams: { timeframe: PlanTimeframe.Monthly } })} />
              </Route>
              <Route path={routes.plan.path} component={Plan} />
              <Route path={routes.transactions.path} component={Transactions} />
              <Route path={routes.settings.path} component={Settings} />
              <EmployeeRoute path="/split-test" component={SplitClientTest} />
              <EmployeeRoute path={routes.hackathon.path} component={Hackathon} />
              <Route path={routes.recurring.path} component={Recurring} />

              <Route path={routes.reports.path} component={Reports} />
              <Route path={routes.investments.path} component={Investments} />
              <AdvisorRoute path={routes.advisorPortal.clients.path} component={AdvisorClients} />
              <AdvisorRoute path={routes.advisorPortal.billing.path} component={AdvisorBilling} />
              <AdvisorRoute path={routes.advisorPortal.profile.path} component={AdvisorProfile} />
              <AdvisorRoute path={routes.advisorPortal.security.path} component={AdvisorSecurity} />
              <Route path={routes.advice.path} component={Advice} />
              <Route path={routes.assistant.path} component={Assistant} />
              <Route path={routes.spendingInsights.path} component={SpendingInsights} />
              <Route path={routes.oauth.coinbase.path} component={CoinbaseOAuth} />
              <EmployeeRoute
                path={routes.assistantEvaluation.path}
                component={AssistantEvaluation}
              />
              <Route path={routes.dashboard.path} component={Dashboard} />
              <Route path="*" component={NotFound} />
            </Switch>
          </LoggedInRoute>
        </Switch>
      </Root>
      {isDemoHouseholdModalVisible && <DemoHouseholdModal />}
      {showMobileBrowserWarning && <MobileBrowserWarning />}
      {/* This is hidden by default. It needs to "exist" so it's found by `onDownloadChart`. */}
      <MonarchWatermark />
      {/*
       * This component is used by the SideDrawer component as a portal root.
       * It facilitates control over the z-index ordering of the portal elements.
       * This is crucial for ensuring the support chat overlay appears above other elements but beneath modal shields.
       */}
      <div id={SIDE_DRAWER_ROOT_NODE_ID} />
      <FooterButtonContainer>
        <FeatureFlagGate name="support-chat-bubble">
          <ContactSupportContext.Consumer>
            {({ isChatOpen }) => isChatOpen && <ContactSupportChat />}
          </ContactSupportContext.Consumer>
        </FeatureFlagGate>
        <EmployeeMenuButton />
      </FooterButtonContainer>

      <RenderProfilerDynamic />
    </>
  );
};

export default App;
