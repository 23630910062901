import { ifElse, evolve, pipe, isNil, always, trim, omit, filter } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useQueryParam, useArrayQueryParam } from 'lib/hooks';
import useQueryParamFiltersWithFalsyValues from 'lib/hooks/transactions/useQueryParamFiltersWithFalsyValues';
import { DEFAULT_FILTERS } from 'lib/transactions/Filters';

import routes from 'constants/routes';

import type { TransactionFilters } from 'types/filters';

export type SetFiltersFunction = (
  filters: Partial<TransactionFilters> | Record<string, never>,
  override?: boolean,
) => void;

export const isActiveFilter = (value: unknown) => !isNilOrEmpty(value);

export const getQueryParamsFromFilters = (
  filters: Partial<TransactionFilters>,
): Record<string, any> => {
  const queryParams: Partial<TransactionFilters> & {
    timeframeUnit?: string;
    timeframeValue?: number;
    timeframeIncludeCurrent?: boolean;
  } = pipe(
    evolve({ search: ifElse(isNil, always(''), trim) }),
    filter(isActiveFilter),
  )(omit(['timeframePeriod'], filters));

  const timeFramePeriod = filters.timeframePeriod;
  if (timeFramePeriod) {
    queryParams.timeframeUnit = timeFramePeriod.unit;
    queryParams.timeframeValue = timeFramePeriod.value;
    queryParams.timeframeIncludeCurrent = timeFramePeriod.includeCurrent;
  }

  return queryParams;
};

// Use object literal to make use of TransactionFilters type.
export const useQueryParamFilters = (): TransactionFilters => {
  const absAmountGteQueryParam = useQueryParam('absAmountGte');
  const absAmountLteQueryParam = useQueryParam('absAmountLte');

  const search = useQueryParam('search') || undefined;
  const accounts = useArrayQueryParam('accounts') || undefined;
  const categories = useArrayQueryParam('categories') || undefined;
  const merchants = useArrayQueryParam('merchants') || undefined;
  const startDate = useQueryParam('startDate') || undefined;
  const endDate = useQueryParam('endDate') || undefined;
  const absAmountGte = absAmountGteQueryParam ? parseFloat(absAmountGteQueryParam) : undefined;
  const absAmountLte = absAmountLteQueryParam ? parseFloat(absAmountLteQueryParam) : undefined;
  const amountFilter = useQueryParam('amountFilter') || undefined;
  const isSplit = useQueryParam('isSplit') === 'true' || undefined;
  const isRecurring = useQueryParam('isRecurring') === 'true' || undefined;
  const hideFromReports = useQueryParam('hideFromReports') === 'true' || undefined;
  const hasAttachments = useQueryParam('hasAttachments') === 'true' || undefined;
  const hasNotes = useQueryParam('hasNotes') === 'true' || undefined;
  const importedFromMint = useQueryParam('importedFromMint') === 'true' || undefined;
  const syncedFromInstitution = useQueryParam('syncedFromInstitution') === 'true' || undefined;
  const order = useQueryParam('order') || undefined;
  const tags = useArrayQueryParam('tags') || undefined;
  const isUntagged = useQueryParam('isUntagged') === 'true' || undefined;
  const needsReview = useQueryParam('needsReview') === 'true' || undefined;
  const needsReviewByUser = useQueryParam('needsReviewByUser') || undefined;
  const needsReviewUnassigned = useQueryParam('needsReviewUnassigned') === 'true' || undefined;
  const categoryGroups = useArrayQueryParam('categoryGroups') || undefined;
  const isInvestmentAccount = useQueryParam('isInvestmentAccount') === 'true' || undefined;
  const creditsOnly = useQueryParam('creditsOnly') === 'true' || undefined;
  const debitsOnly = useQueryParam('debitsOnly') === 'true' || undefined;
  const timeframeUnit = useQueryParam('timeframeUnit') || undefined;
  const timeframeValue = useQueryParam('timeframeValue') || undefined;
  const timeframeIncludeCurrent = useQueryParam('timeframeIncludeCurrent') === 'true';
  const timeframePeriod =
    timeframeUnit && timeframeValue
      ? {
          unit: timeframeUnit,
          value: parseInt(timeframeValue, 10),
          includeCurrent: timeframeIncludeCurrent,
        }
      : undefined;

  return {
    ...DEFAULT_FILTERS,
    ...filter(isActiveFilter, {
      absAmountGte,
      absAmountLte,
      accounts,
      amountFilter,
      categories,
      endDate,
      hasAttachments,
      hasNotes,
      hideFromReports,
      importedFromMint,
      syncedFromInstitution,
      isSplit,
      isRecurring,
      merchants,
      needsReview,
      order,
      search,
      startDate,
      tags,
      isUntagged,
      needsReviewByUser,
      needsReviewUnassigned,
      categoryGroups,
      isInvestmentAccount,
      debitsOnly,
      creditsOnly,
      timeframePeriod,
    }),
  };
};

type Options = {
  initialFilters?: TransactionFilters;
  /**
   * This is so we can include falsy values (e.g. needsReview=false) from the query params.
   * It's useful so we can enable it for the filter menu without introducing a breaking change
   * to the other places that use this hook.
   */
  includeFalsyValues?: boolean;
  onUpdate?: (params: Partial<TransactionFilters>, override?: boolean) => void;
};

const useFilters = (options: Options | undefined = {}) => {
  const { initialFilters, onUpdate, includeFalsyValues = false } = options;

  const queryParamFilters = useQueryParamFilters();
  const queryParamFiltersWithFalsyValues = useQueryParamFiltersWithFalsyValues();
  const filters = includeFalsyValues ? queryParamFiltersWithFalsyValues : queryParamFilters;

  const history = useHistory();

  const setFilters: SetFiltersFunction = useCallback(
    (filters, override) => {
      // Prevent query params without values e.g. '?search='
      const queryParams = getQueryParamsFromFilters(filters);

      if (onUpdate) {
        onUpdate(filters, override);
      } else {
        history.push(routes.transactions({ queryParams }));
      }
    },
    [history, onUpdate],
  );

  return [filters || initialFilters || {}, setFilters] as const;
};

export default useFilters;
