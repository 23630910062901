import { omit } from 'ramda';
import { createSelector } from 'reselect';

import type { SankeyGroupMode } from 'lib/cashFlow/sankey';
import { isActiveFilter } from 'lib/hooks/transactions/useFilters';
import { encodeReportView } from 'lib/reports';
import type { ReportsTab } from 'state/reports/types';
import type { RootState } from 'state/types';

import type { TransactionFilters } from 'types/filters';

export const selectReportsState = (state: RootState) => state.reports;
export const selectReportsFilters = createSelector(selectReportsState, (state) => state.filters);
export const selectReportsSortBy = createSelector(selectReportsState, (state) => state.sortBy);
export const selectReportsGroupBy = createSelector(selectReportsState, (state) => state.groupBy);
export const selectReportsGroupByTimeframe = createSelector(
  selectReportsState,
  (state) => state.groupByTimeframe,
);

const selectActiveTab = (_: RootState, tab: ReportsTab) => tab;

export const selectDisplayPropertiesForTab = createSelector(
  [selectReportsState, selectActiveTab],
  (state, tab) => state[tab],
);

export const selectReportsHasFilters = createSelector(
  selectReportsFilters,
  (filters: Partial<TransactionFilters>) => Object.values(filters).some(isActiveFilter),
);

export const selectReportsHasNonDateFilters = createSelector(selectReportsFilters, (filters) =>
  Object.values(omit(['startDate', 'endDate', 'timeframePeriod'], filters)).some(isActiveFilter),
);

export const selectReportsSankeyGroupMode = createSelector(
  selectReportsState,
  (state): SankeyGroupMode => state.cashFlow.groupMode ?? 'category',
);

export const selectReportView = createSelector(
  [selectReportsState, selectActiveTab],
  (state, tab: ReportsTab) => encodeReportView(state, tab),
);
