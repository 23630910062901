import React from 'react';
import Tilt from 'react-parallax-tilt';
import styled, { css } from 'styled-components';

import Mark from 'components/brand/Mark';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import boxShadow from 'common/lib/styles/boxShadow';
import { fontSize, radius, spacing } from 'common/lib/theme/dynamic';
import useTheme from 'lib/hooks/useTheme';

const StyledTilt = styled(Tilt)`
  width: 100%;
`;

const TiltInnerRoot = styled.div`
  position: relative;
  aspect-ratio: 1.67/1;
  width: 100%;
  border-radius: ${radius.xlarge};
  background: linear-gradient(124deg, #ed794b -9.43%, #c74d99 90.78%);
  ${boxShadow.medium}
`;

const MarkContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledMark = styled(Mark)`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(-10deg) scale(1.4);
  opacity: 0.07;
`;

const Content = styled(FlexContainer).attrs({ column: true, justifyCenter: true })`
  height: 100%;
  text-align: center;
  padding: ${spacing.default};
  position: relative;
`;

const Title = styled(Text).attrs({
  colorVariable: 'content.white',
  weight: 'medium',
})<{ isSidebarExpanded?: boolean }>`
  text-align: center;
  font-size: clamp(${fontSize.large}, 2vw, ${fontSize.xxlarge});

  ${({ isSidebarExpanded, theme }) =>
    isSidebarExpanded
      ? css`
          @media (max-width: ${theme.breakPoints.xl}px) {
            font-size: clamp(${fontSize.base}, 1.8vw, ${fontSize.xxlarge});
          }
          @media (max-width: ${theme.breakPoints.lg}px) {
            font-size: clamp(${fontSize.xlarge}, 5vw, ${fontSize.xxlarge});
          }
        `
      : css`
          @media (max-width: ${theme.breakPoints.md}px) {
            font-size: clamp(${fontSize.large}, 3vw, ${fontSize.xxlarge});
          }
          @media (max-width: ${theme.breakPoints.sm}px) {
            font-size: clamp(${fontSize.xlarge}, 5vw, ${fontSize.xxlarge});
          }
        `}
`;

const Description = styled(Text).attrs({
  colorVariable: 'content.white',
  size: 'xxsmall',
  weight: 'medium',
})`
  text-align: center;
  opacity: 0.8;
`;

type Props = {
  className?: string;
  isSidebarExpanded?: boolean;
  title: string;
  description: string;
};

export const GiftCard = ({ className, isSidebarExpanded, title, description }: Props) => {
  const theme = useTheme();

  return (
    <StyledTilt className={className} tiltMaxAngleX={8} tiltMaxAngleY={8}>
      <TiltInnerRoot>
        <MarkContainer>
          <StyledMark color={theme.variables.color.content.white} />
        </MarkContainer>

        <Content>
          <Title isSidebarExpanded={isSidebarExpanded}>{title}</Title>
          <Description>{description}</Description>
        </Content>
      </TiltInnerRoot>
    </StyledTilt>
  );
};
