import * as RA from 'ramda-adjunct';
import type { SyntheticEvent } from 'react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import type { Row, UseTableOptions } from 'react-table';
import { useExpanded, useGroupBy, useSortBy, useTable } from 'react-table';
import type { TableVirtuosoProps } from 'react-virtuoso';
import { TableVirtuoso } from 'react-virtuoso';
import styled, { css } from 'styled-components';

import { cardDropShadowStyleMixin } from 'components/lib/ui/Card';
import Icon from 'components/lib/ui/Icon';
import SectionHeader from 'components/lib/ui/SectionHeader';
import AbstractButton from 'components/lib/ui/button/AbstractButton';
import type { EmptyProps } from 'components/lib/ui/table/TableEmptyContainer';
import TableEmptyContainer from 'components/lib/ui/table/TableEmptyContainer';
import TableLoadingContainer from 'components/lib/ui/table/TableLoadingContainer';

import { spacing } from 'common/lib/theme/dynamic';
import ScrollContext from 'lib/contexts/ScrollContext';
import fakeCornersMixin from 'lib/styles/fakeCornersMixin';

const DEFAULT_EMPTY_MESSAGE = 'No data was found.';
const DEFAULT_FOOTER_PADDING = spacing.gutter;

const Root = styled.div`
  display: block;
  max-width: 100%;
  margin-bottom: ${DEFAULT_FOOTER_PADDING};
  position: relative;
  transform: scale(1); /* create stacking context */
`;

const BaseTable = styled.table`
  width: 100%;
  border-spacing: 0;
`;

export const TableHeader = styled.thead`
  background-color: ${({ theme }) => theme.color.white};

  ${cardDropShadowStyleMixin}
`;

const TableBody = styled.tbody`
  ${cardDropShadowStyleMixin}
`;

const VirtualizedBaseTable = styled(BaseTable)`
  thead > tr > th:first-child,
  thead > tr > th:last-child {
    ${({ theme }) =>
      fakeCornersMixin({
        theme,
        backgroundColor: theme.color.white,
        fillColor: theme.color.grayBackground,
      })}
  }
`;

export const TableCell = styled.td<{ $isHeader?: boolean; $defaultAlignment: 'left' | 'right' }>`
  padding: ${({ theme }) => `${theme.spacing.default} ${theme.spacing.large}`};
  text-align: right;
  text-align: ${({ $defaultAlignment }) => $defaultAlignment};

  &:first-child {
    text-align: left;
  }

  &:last-child {
    border-right: none;
  }

  ${({ $isHeader, theme }) =>
    $isHeader
      ? css`
          font-size: ${({ theme }) => theme.fontSize.small};
          font-weight: ${({ theme }) => theme.fontWeight.medium};
          padding: ${theme.spacing.xsmall} ${theme.spacing.xlarge};
          background-color: ${theme.color.grayBackground};
          color: ${theme.color.textLight};
        `
      : ''}
`;

export const TableHeaderCell = styled.th<{
  $defaultAlignment: 'left' | 'right';
  $hasHover?: boolean;
  $isCollapsed?: boolean;
  $showHeaderCellBorders?: boolean;
}>`
  position: relative;
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  padding: ${({ theme }) => `${theme.spacing.default} ${theme.spacing.large}`};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  border-right: ${({ $showHeaderCellBorders, theme }) =>
    $showHeaderCellBorders ? `1px solid ${theme.color.grayBackground}` : 'none'};
  white-space: nowrap;
  text-align: ${({ $defaultAlignment }) => $defaultAlignment};

  &:first-child {
    text-align: left;
    border-top-left-radius: ${({ theme }) => theme.radius.medium};
    border-bottom-left-radius: ${({ theme, $isCollapsed }) =>
      $isCollapsed ? theme.radius.medium : 0};
  }

  &:last-child {
    text-align: right;
    border-right: none;
    border-top-right-radius: ${({ theme }) => theme.radius.medium};
    border-bottom-right-radius: ${({ theme, $isCollapsed }) =>
      $isCollapsed ? theme.radius.medium : 0};
  }

  &:hover {
    color: ${({ theme }) => theme.color.blue};
    cursor: ${({ $hasHover }) => ($hasHover ? 'pointer' : 'default')};
  }
`;

export const TableCellSectionHeader = styled(TableHeaderCell)`
  padding: 0;
`;

export const TableRow = styled.tr`
  background-color: ${({ theme }) => theme.color.white};

  ${TableCell} {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }

  &:last-child {
    ${TableCell} {
      border-bottom: unset;
    }

    ${TableCell}:first-child {
      border-bottom-left-radius: ${({ theme }) => theme.radius.medium};
    }

    ${TableCell}:last-child {
      border-bottom-right-radius: ${({ theme }) => theme.radius.medium};
    }
  }
`;

const TableHeaderRow = styled(TableRow)<{ $useShadow: boolean }>`
  ${({ $useShadow, theme }) => ($useShadow ? cardDropShadowStyleMixin({ theme }) : '')}
`;

export const TableFooter = styled.tfoot`
  background-color: ${({ theme }) => theme.color.white};
  transform: translateY(${DEFAULT_FOOTER_PADDING});

  ${TableRow} {
    ${TableCell}:first-child {
      border-top-left-radius: ${({ theme }) => theme.radius.medium};
    }

    ${TableCell}:last-child {
      border-top-right-radius: ${({ theme }) => theme.radius.medium};
    }
  }

  ${cardDropShadowStyleMixin}
`;

const StyledIcon = styled(Icon)`
  position: relative;
  margin-left: ${({ theme }) => theme.spacing.xsmall};
  bottom: -2px;
`;

const ChevronIconButtonContainer = styled(AbstractButton)`
  display: inline;
  color: inherit;
  padding-left: 0;
`;

const ChevronIcon = styled(Icon)`
  color: inherit;
`;

// eslint-disable-next-line @typescript-eslint/ban-types
type GroupedData<T extends object> = {
  header: string | React.ReactElement;
  key: string;
  rows: Row<T>[];
};

// eslint-disable-next-line @typescript-eslint/ban-types
type SharedProps<T extends object> = {
  isLoading?: boolean;
  empty?: EmptyProps;
  initiallyExpanded?: boolean;
  collapsible?: boolean;
  showFooter?: boolean;
  className?: string;
  defaultAlignment?: 'left' | 'right';
  virtualizeOptions?: Omit<TableVirtuosoProps<T, unknown>, 'components'>;
  showHeaderCellBorders?: boolean;
  groupByFn?: (data: Row<T>[]) => GroupedData<T>[];
};

// react-table needs T extended from object, Record<string, unknown> doesn't work
// eslint-disable-next-line
type Props<T extends object> = UseTableOptions<T> & SharedProps<T>;

// eslint-disable-next-line
const Table = <T extends object>({
  data,
  columns,
  isLoading,
  empty,
  initiallyExpanded = true,
  showFooter = true,
  className,
  defaultAlignment = 'right',
  virtualizeOptions,
  collapsible = false,
  showHeaderCellBorders = false,
  groupByFn, // implemented only when not virtualized
  ...props
}: Props<T>) => {
  const memoizedData = useMemo(() => data, [data]);
  const memoizedColumns = useMemo(() => columns, [columns]);
  const hasData = data.length > 0;

  const isTableVirtualized = RA.isNotNil(virtualizeOptions);
  const { scrollRef } = useContext(ScrollContext);
  const [isScrolledTop, setIsScrolledTop] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(!initiallyExpanded);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    toggleRowExpanded,
    toggleAllRowsExpanded,
    isAllRowsExpanded,
  } = useTable(
    {
      data: memoizedData,
      columns: memoizedColumns,
      disableMultiSort: true,
      ...props,
    },
    useGroupBy,
    useSortBy,
    useExpanded,
  );

  // Weirdly enough, using useEffect causes a flicker when the table re-renders
  // More at https://github.com/tannerlinsley/react-table/issues/2404#issuecomment-644900166
  useMemo(
    () => {
      if (!isAllRowsExpanded) {
        toggleAllRowsExpanded(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, isAllRowsExpanded, toggleAllRowsExpanded],
  );

  const toggleIsCollapsed = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation(); // stop it from triggering sort
      setIsCollapsed(!isCollapsed);
    },
    [isCollapsed],
  );

  const getTableHeaderProps = useCallback(
    (idx: number): React.ComponentProps<typeof TableHeaderCell> | Record<string, never> =>
      idx === 0 && collapsible ? { onClick: toggleIsCollapsed, $hasHover: true } : {},
    [collapsible, toggleIsCollapsed],
  );

  const headerChevronIconName = isCollapsed ? 'chevron-right' : 'chevron-down';

  const groupedData = groupByFn
    ? groupByFn(rows)
    : [
        {
          header: undefined,
          key: undefined,
          rows,
        },
      ];

  if (isTableVirtualized) {
    return (
      <Root className={className}>
        <TableVirtuoso
          {...virtualizeOptions}
          totalCount={data.length}
          customScrollParent={scrollRef?.current ?? undefined}
          style={{ ...(virtualizeOptions?.style ?? {}), height: 'auto' }}
          atTopStateChange={setIsScrolledTop}
          components={{
            Table: ({ style, ...props }) => (
              <VirtualizedBaseTable
                {...getTableProps()}
                {...props}
                style={{
                  ...(style ?? {}),
                  // override width from `style`, if present
                  width: '100%',
                }}
              />
            ),
            // @ts-ignore issue with forwardRef and tableBody props
            TableBody: React.forwardRef(({ style, ...props }, ref) => (
              <TableBody {...getTableBodyProps()} {...props} ref={ref} />
            )),
            TableRow: (props) => {
              const index = props['data-index'];
              const row = rows[index];
              return <TableRow {...props} {...row.getRowProps()} />;
            },
            EmptyPlaceholder: () => (
              <TableBody>
                <TableRow>
                  <TableLoadingContainer columnsCount={columns.length} />
                </TableRow>
              </TableBody>
            ),
          }}
          itemContent={(index) => {
            const row = rows[index];
            prepareRow(row);
            return row.cells.map((cell) => (
              <TableCell
                $defaultAlignment={defaultAlignment}
                {...cell.getCellProps()}
                key={`${cell.column.id}-${cell.value}`}
                $isHeader={cell.isGrouped || cell.isAggregated}
              >
                {cell.isAggregated ? cell.render('Aggregated') : cell.render('Cell')}
              </TableCell>
            ));
          }}
          fixedHeaderContent={() =>
            headerGroups.map((headerGroup) => (
              <TableHeaderRow
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.getHeaderGroupProps().key}
                $useShadow={!isScrolledTop}
              >
                {headerGroup.headers.map((column, idx) => (
                  <TableHeaderCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    $defaultAlignment={defaultAlignment}
                    key={column.id}
                    $isCollapsed={isCollapsed}
                    $showHeaderCellBorders={showHeaderCellBorders}
                    {...getTableHeaderProps(idx)}
                  >
                    {column.render('Header')}
                    {column.isSorted && column.isSortedDesc ? (
                      <StyledIcon name="arrow-down" />
                    ) : null}
                    {column.isSorted && !column.isSortedDesc ? (
                      <StyledIcon name="arrow-up" />
                    ) : null}
                  </TableHeaderCell>
                ))}
              </TableHeaderRow>
            ))
          }
        />
      </Root>
    );
  }

  return (
    <Root className={className}>
      <BaseTable {...getTableProps()}>
        {hasData && (
          <TableHeader>
            {headerGroups.map((group) => (
              <TableRow {...group.getHeaderGroupProps()} key={group.getHeaderGroupProps().key}>
                {group.headers.map((column, idx) => (
                  <TableHeaderCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    $defaultAlignment={defaultAlignment}
                    key={column.id}
                    $isCollapsed={isCollapsed}
                    $showHeaderCellBorders={showHeaderCellBorders}
                    {...getTableHeaderProps(idx)}
                  >
                    {collapsible && idx === 0 && (
                      <ChevronIconButtonContainer onClick={toggleIsCollapsed}>
                        <ChevronIcon name={headerChevronIconName} size={16} />
                      </ChevronIconButtonContainer>
                    )}

                    {column.render('Header')}
                    {column.isSorted && column.isSortedDesc ? (
                      <StyledIcon name="arrow-down" />
                    ) : null}
                    {column.isSorted && !column.isSortedDesc ? (
                      <StyledIcon name="arrow-up" />
                    ) : null}
                  </TableHeaderCell>
                ))}
              </TableRow>
            ))}
          </TableHeader>
        )}
        {isLoading ? (
          <TableBody>
            <TableRow>
              <TableLoadingContainer columnsCount={columns.length} />
            </TableRow>
          </TableBody>
        ) : (
          !isCollapsed && (
            <TableBody {...getTableBodyProps()}>
              {!hasData ? (
                <TableRow>
                  <TableEmptyContainer
                    columnsCount={columns.length}
                    title={DEFAULT_EMPTY_MESSAGE}
                    {...empty}
                  />
                </TableRow>
              ) : (
                groupedData.map(({ header, key, rows }) => (
                  <React.Fragment key={key}>
                    {header && rows.length > 0 && (
                      <TableRow>
                        <TableCellSectionHeader
                          $defaultAlignment={defaultAlignment}
                          colSpan={columns.length}
                        >
                          <SectionHeader>{header}</SectionHeader>
                        </TableCellSectionHeader>
                      </TableRow>
                    )}
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <TableRow
                          {...row.getRowProps()}
                          key={row.id}
                          onClick={() => toggleRowExpanded([row.id])}
                        >
                          {row.cells.map((cell) => (
                            <TableCell
                              $defaultAlignment={defaultAlignment}
                              {...cell.getCellProps()}
                              key={`${cell.column.id}-${cell.value}`}
                              $isHeader={cell.isGrouped || cell.isAggregated}
                            >
                              {cell.isAggregated ? cell.render('Aggregated') : cell.render('Cell')}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </React.Fragment>
                ))
              )}
            </TableBody>
          )
        )}
        {hasData && showFooter && (
          <TableFooter>
            {footerGroups.map((group) => (
              <TableRow {...group.getFooterGroupProps()} key={group.getFooterGroupProps().key}>
                {group.headers.map((column) => (
                  <TableCell
                    $defaultAlignment={defaultAlignment}
                    {...column.getFooterProps()}
                    key={column.id}
                  >
                    {column.render('Footer')}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableFooter>
        )}
      </BaseTable>
    </Root>
  );
};

export default Table;
