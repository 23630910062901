import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

import { onboardingSurveyControlMotionSettings as motionSettings } from 'common/components/onboarding/OnboardingSurveyControlMotion';

import { DEFAULT_TRANSITION } from 'constants/animation';

const MotionDiv = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const inactiveState = {
  opacity: motionSettings.inactiveState.opacity,
  scale: motionSettings.inactiveState.scale,
};

const activeState = {
  opacity: motionSettings.activeState.opacity,
  scale: motionSettings.activeState.scale,
};

const motionData = {
  exit: inactiveState,
  initial: inactiveState,
  animate: activeState,
  transition: {
    ...DEFAULT_TRANSITION,
    duration: motionSettings.duration,
  },
};

export type Props = {
  value: boolean;
  children?: React.ReactNode;
};

const OnboardingSurveyControlMotion = ({ value, children }: Props) => (
  <AnimatePresence initial={false}>
    {value && <MotionDiv {...motionData}>{children}</MotionDiv>}
  </AnimatePresence>
);

export default OnboardingSurveyControlMotion;
