import { darken } from 'polished';
import React, { useMemo, useState } from 'react';
import type { LabelProps } from 'recharts';
import styled, { css } from 'styled-components';

import BarChart from 'components/lib/charts/BarChart';

import { color, spacing, transition } from 'common/lib/theme/dynamic';
import { formatCurrencyNoCents, formatCurrencyThousandsDecimal } from 'common/utils/Currency';
import useTheme from 'lib/hooks/useTheme';

import type { KeyOfThemeProp } from 'common/types/Styles';

const CHART_HEIGHT_PX = 136;
const BAR_WIDTH_PX = 24;

export type BudgetHistoricalAmountBar = {
  sum: number;
  month: string;
};

const Root = styled.div<{ $barColor: string; $isEmpty: boolean }>`
  padding-top: ${spacing.default};
  position: relative;

  ${({ $isEmpty }) =>
    $isEmpty &&
    css`
      cursor: default;
    `}

  .recharts-bar-rectangles .recharts-rectangle {
    &:hover {
      fill: ${({ $barColor }) => darken(0.04, $barColor)};
      cursor: pointer;
    }
  }

  .recharts-label-list text {
    transition: ${transition.default};
    opacity: 0;
    transform: translateY(2px);
    fill: ${color.text};

    &[data-show='true'] {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const EmptyContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

type Props = {
  width: number;
  data: BudgetHistoricalAmountBar[];
  color: KeyOfThemeProp<'color'>;
  isLoading?: boolean;
  emptyMessage?: React.ReactNode;
  onClickBar?: (amount: number) => void;
};

const BudgetHistoricalAmountsBarChart = ({
  width,
  data,
  color = 'gray',
  isLoading,
  emptyMessage,
  onClickBar,
}: Props) => {
  const theme = useTheme();
  const tickStyle = useMemo(
    () => ({
      fontSize: 11,
      fontWeight: theme.fontWeight.bold,
      letterSpacing: '0.08em',
    }),
    [theme],
  );

  const barDataKeys = useMemo(
    () =>
      data.map(() => ({
        name: 'sum',
        color: theme.color[color],
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.length],
  );

  const [hoveredBarIndex, setHoveredBarIndex] = useState<number | null>(null);

  const isChartEmpty = !isLoading && data.every((datum) => Math.abs(datum.sum) === 0);

  return (
    <Root $barColor={theme.color[color]} $isEmpty={isChartEmpty}>
      <BarChart
        data={data}
        xDataKey="month"
        yDataKey="sum"
        width={width}
        height={CHART_HEIGHT_PX}
        barWidth={BAR_WIDTH_PX}
        barDataKeys={barDataKeys}
        yAxisFormatter={formatCurrencyThousandsDecimal}
        onClickBar={onClickBar ? (value) => onClickBar(value.sum) : undefined}
        // All styling-related props are defined below
        dashed={false}
        margin={{ right: 12, bottom: 0, left: -12, top: 18 }}
        yAxisProps={{
          tickLine: false,
          axisLine: false,
        }}
        xAxisProps={{
          tickLine: false,
          axisLine: true,
          stroke: theme.color.grayFocus,
        }}
        cartesianGridProps={{
          stroke: theme.color.grayFocus,
        }}
        barProps={{
          label: <CustomLabel hoveredBarIndex={hoveredBarIndex} />,
          onMouseEnter: (_, index) => setHoveredBarIndex(index),
          onMouseLeave: () => setHoveredBarIndex(null),
        }}
        customTickStyle={tickStyle}
        tickMargin={4}
        disableAnimation
        roundBarCorners
      />
      {isChartEmpty && <EmptyContainer>{emptyMessage}</EmptyContainer>}
    </Root>
  );
};

const CustomLabel: React.FC<LabelProps & { index?: number; hoveredBarIndex: number | null }> = ({
  x,
  y,
  width,
  value,
  index,
  hoveredBarIndex,
}) =>
  value ? (
    <text
      x={x ?? 0 + (width ?? 0) / 2}
      y={y}
      dy={-8}
      dx={(width ?? 0) / 2 - 2}
      textAnchor="middle"
      data-show={index === hoveredBarIndex}
    >
      {formatCurrencyNoCents(Number(value))}
    </text>
  ) : null;

export default BudgetHistoricalAmountsBarChart;
