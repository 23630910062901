import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';

import isEnvDevelopment from 'lib/isEnvDevelopment';
import { getIsEmployee } from 'selectors';

const RenderProfiler = React.lazy(() => import('components/employee/RenderProfiler'));

const RenderProfilerDynamic = () => {
  const isEmployee = useSelector(getIsEmployee);
  const preventProfiler = !isEmployee && !isEnvDevelopment();

  if (preventProfiler) {
    return null;
  }

  return (
    <Suspense fallback={<></>}>
      <RenderProfiler />
    </Suspense>
  );
};

export default RenderProfilerDynamic;
