import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';

import getStringEnvVar from 'lib/getStringEnvVar';
import useTheme from 'lib/hooks/useTheme';

const STRIPE_PUBLIC_KEY = getStringEnvVar('REACT_APP_STRIPE_PUBLIC_KEY');
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

type Props = {
  children: React.ReactNode;
};

/**
 * Configure Stripe elements. This must be placed in the tree above any stripe hooks / elements.
 */
const StripeProvider = ({ children }: Props) => {
  const theme = useTheme();

  const options = {
    fonts: [
      {
        family: theme.fontFamily.primary,
        weight: theme.fontWeight.book,
        src: 'url(https://monarch-static-assets.s3.us-east-1.amazonaws.com/fonts/ABCOracle-Book.woff2)',
      },
    ],
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};

export default StripeProvider;
