import { useQuery } from '@apollo/client';
import pluralize from 'pluralize';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import type { Props as SelectProps } from 'components/lib/form/Select';
import Select from 'components/lib/form/Select';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Tooltip from 'components/lib/ui/Tooltip';
import {
  MerchantCountContainer,
  MerchantSelectCreditCardIcon,
} from 'components/transactions/MerchantSearchSelect';

import { GET_MERCHANTS_SEARCH } from 'common/lib/graphQl/merchants';
import useToggle from 'common/lib/hooks/useToggle';

import type { GetMerchantsSearchQuery } from 'common/generated/graphql';
import type { ElementOf } from 'common/types/utility';
import type { OptionType } from 'types/select';

type GetMerchantsSearchMerchant = ElementOf<GetMerchantsSearchQuery, 'merchants'>;

const MERCHANT_SEARCH_LIMIT = 20;

export type MerchantOption = OptionType & {
  transactionCount?: number;
};

type Props = Omit<SelectProps, 'value'> & {
  className?: string;
  value?: string[];
  formatOptions?: (merchant: GetMerchantsSearchMerchant[]) => MerchantOption[];
};

const OptionRow = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })`
  flex: 1;
`;

const defaultMerchantsFormatter = (merchants: GetMerchantsSearchMerchant[]): MerchantOption[] =>
  merchants.map(({ id, name, transactionCount }) => ({
    value: id,
    label: name,
    transactionCount,
  }));

/**
 * This component is used for select async merchant options
 * commonly used when we need to search for multiple merchants
 * like in a filter card.
 */
const MultiMerchantSearchSelect = ({
  value,
  formatOptions = defaultMerchantsFormatter,
  ...props
}: Props) => {
  const {
    data: initialMerchants,
    refetch: getMerchants,
    loading,
  } = useQuery(
    GET_MERCHANTS_SEARCH,
    // 'network-only' because if we use the default (cache-and-network), the refetch promise
    // resolves from the cached value (initially empty) while doing the network request
    // in the background. We want to loadOptions from the network response.
    {
      fetchPolicy: 'network-only',
      variables: {
        includeIds: value,
        limit: Math.max(MERCHANT_SEARCH_LIMIT, value?.length ?? 0),
        search: '',
      },
    },
  );

  const [isMenuOpen, { setOn: setOpen, setOff: setClosed }] = useToggle(false);

  const defaultOptions = useMemo(() => {
    if (!initialMerchants) {
      return undefined;
    }

    return formatOptions(initialMerchants.merchants);
  }, [initialMerchants, formatOptions]);

  return (
    <Select<MerchantOption>
      placeholder="Search merchants..."
      {...props}
      isLoading={loading}
      isMulti
      openMenuOnFocus
      // @ts-ignore
      value={value}
      options={defaultOptions}
      onMenuOpen={setOpen}
      onMenuClose={setClosed}
      controlShouldRenderValue={props.isMulti || !isMenuOpen}
      defaultOptions={defaultOptions}
      cacheOptions
      loadOptions={async (search: string) => {
        const response = await getMerchants({
          search,
          limit: MERCHANT_SEARCH_LIMIT,
          includeIds: value,
        });
        return formatOptions(response.data.merchants ?? []);
      }}
      renderOption={({ label, transactionCount }: MerchantOption) => (
        <OptionRow>
          <span>{label}</span>
          {!!transactionCount && (
            <Tooltip
              content={`${transactionCount} existing ${pluralize(
                'transaction',
                transactionCount,
              )} for this merchant`}
            >
              <MerchantCountContainer>
                <MerchantSelectCreditCardIcon />
                <span>{transactionCount}</span>
              </MerchantCountContainer>
            </Tooltip>
          )}
        </OptionRow>
      )}
    />
  );
};

export default MultiMerchantSearchSelect;
