import React from 'react';
import styled from 'styled-components';

import Flex from 'components/lib/ui/Flex';
import Icon from 'components/lib/ui/Icon';

import { spacing, variables } from 'common/lib/theme/dynamic';
import staticTheme from 'common/lib/theme/staticTheme';
import { TransactionStatus } from 'lib/accounts/transferAccountData/types';

const DescriptionListTerm = styled.dt<{ $type?: TransactionStatus }>`
  font-weight: bold;
  font-size: ${staticTheme.fontSize.xsmall};
  padding: ${spacing.xxsmall} ${spacing.default};
  background-color: ${({ $type = TransactionStatus.Default, theme }) => {
    switch ($type) {
      case TransactionStatus.Included:
        return variables.color.background.success;
      case TransactionStatus.Excluded:
        return variables.color.background.danger;
      default:
        return variables.color.background.secondary;
    }
  }};
  color: ${({ $type = TransactionStatus.Default }) =>
    $type === TransactionStatus.Default
      ? variables.color.content.secondary
      : variables.color.content.primary};
`;

const DescriptionListSubtitle = styled.span`
  font-weight: normal;
  color: ${variables.color.content.secondary};
`;

const DescriptionListDescription = styled.dd`
  margin: 0;
`;

const StyledIcon = styled(Icon)<{ isSuccess?: boolean }>`
  color: ${({ isSuccess = true }) =>
    isSuccess ? variables.color.content.success : variables.color.content.danger};
`;

type Props = {
  title: string;
  subtitle?: string;
  type: TransactionStatus;
  children: React.ReactNode;
};

export const DateSection = React.forwardRef<HTMLElement, Props>(
  ({ title, subtitle, type, children }, ref) => (
    <>
      <DescriptionListTerm key={title} $type={type} ref={ref}>
        <Flex justifyBetween alignCenter>
          <span>
            {title}
            {subtitle && <DescriptionListSubtitle> {subtitle}</DescriptionListSubtitle>}
          </span>
          {type !== TransactionStatus.Default && (
            <StyledIcon
              name={type === TransactionStatus.Included ? 'check-circle' : 'x-circle'}
              size={16}
              isSuccess={type === TransactionStatus.Included}
            />
          )}
        </Flex>
      </DescriptionListTerm>
      <DescriptionListDescription>{children}</DescriptionListDescription>
    </>
  ),
);
