import * as R from 'ramda';

import type Api from 'common/lib/api';
import type { UpdateUserParams } from 'common/state/user/reducer';
import { convertKeysToSnakeCase } from 'common/utils/Object';

import type { AuthProvider } from 'common/types/auth';

export type UserProfileREST = {
  birthday: string;
  collaborates_on_finances: boolean;
};

export type UserAttributionData = {
  fbp?: string;
  fbc?: string;
  signup_apps_flyer_id?: string;
  anonymous_id?: string;
  google_analytics_client_id?: string;
  signup_apps_flyer_af_user_id?: string;
};

const getUserApi = (api: Api) => ({
  login: (data: {
    username: string;
    password: string;
    totp?: string;
    recovery_code?: string;
    trusted_device?: boolean;
    supports_mfa: boolean;
    supports_email_otp?: boolean;
    supports_recaptcha?: boolean;
  }) => api.post('/auth/login/', { data }),
  logout: () => api.del('/auth/logout/', {}),
  updateUser: (data: UpdateUserParams) => api.patch('/users/me/', { data }),
  changePassword: (data: { current_password: string; new_password: string }) =>
    api.post('/auth/change-password/', { data }),
  sendForgotPasswordEmail: (data: { email: string }) =>
    api.post('/auth/forgot-password/', { data }),
  sendCreatePasswordEmail: () => api.post('/auth/create-password/'),
  resetPassword: (data: { token: string; new_password: string }) =>
    api.post('/auth/reset-password/', { data }),
  verifyEmail: (data: { email: string; code: string }) =>
    api.patch('/verifications/verify/', { data }),
  verifyNewEmail: (data: { newEmail: string; code: string }) =>
    api.patch('/verifications/verify-new-email/', {
      data: { ...convertKeysToSnakeCase(data) },
    }),
  getUser: () => api.get('/users/me/', {}),
  createUnverifiedUser: ({
    email,
    referralCode,
    userAttributionData,
    fingerprint,
    isSponsor,
  }: {
    email: string;
    referralCode?: string | null;
    userAttributionData?: UserAttributionData;
    fingerprint?: string;
    isSponsor?: boolean;
  }) =>
    api.post('/users/create-unverified/', {
      data: {
        email,
        referral_code: referralCode,
        user_attribution_data: userAttributionData,
        fingerprint,
        is_sponsor: isSponsor,
      },
    }),
  createVerification: (email: string) => api.post('/verifications/new/', { data: { email } }),
  createEmailUpdateVerification: (newEmail: string, currentPassword: string) =>
    api.post('/verifications/new-email-update/', {
      data: { ...convertKeysToSnakeCase({ newEmail, currentPassword }) },
    }),
  resendEmailUpdateVerification: () => api.post('/verifications/resend-email-update/'),
  verifyUser: (
    email: string,
    code: string,
    name: string,
    password: string,
    profile?: UserProfileREST,
    userAttributionData?: UserAttributionData,
  ) =>
    api.patch('/auth/verify-user/', {
      data: { email, code, name, password, profile, user_attribution_data: userAttributionData },
    }),
  signupUserWithToken: (data: { email: string; code: string; name: string; password: string }) =>
    api.post('/auth/signup-user-with-token/', { data }),
  checkHouseholdInvite: (data: { token: string; email: string }) =>
    api.get('/auth/check-household-invite', { queryParams: data }),
  signupUserWithHouseholdInvite: (data: {
    email: string;
    token: string;
    name: string;
    password: string;
  }) => api.post('/auth/signup-with-household-invite/', { data }),
  signupUserWithDemoPassword: (data: { demo_password: string }) =>
    api.post('/auth/signup-with-demo-password/', { data }),
  createTemporaryDemoAccount: () => api.post('/auth/create-temporary-demo-account/'),
  extendToken: () => api.post('/auth/extend-token/'),
  createSupportTicket: (data: FormData) => api.post('/users/support-ticket/', { data }),
  logAppLoadedOnBackend: () => api.post('/users/track-app-loaded/'),
  getGuestPassData: (code: string) => api.get('/users/get-guest-pass/', { queryParams: { code } }),
  getReferralCodeIsValid: (code: string) => api.get('/referrals/code', { queryParams: { code } }),
  authenticateUserWithProvider: <TData extends Record<string, unknown>>(
    provider: AuthProvider,
    data: TData,
    userAttributionData?: UserAttributionData,
    fingerprint?: string,
    isSponsor?: boolean,
  ) =>
    api.post(`/auth/${provider}/`, {
      data: convertKeysToSnakeCase(
        R.mergeRight(data, { isSponsor, userAttributionData, fingerprint }),
      ),
    }),
  revokeProviderSSO: <TData extends Record<string, unknown>>(provider: AuthProvider, data: TData) =>
    api.post(`/auth/revoke-sso/${provider}/`, {
      data: {
        ...convertKeysToSnakeCase(data),
      },
    }),
  getScopedAuthToken: (scope: string) => api.post('auth/scoped-auth-token/', { data: { scope } }),
});

export default getUserApi;
