import { css } from 'styled-components';

import { breakPoints } from 'common/lib/theme/dynamic';

export const onboardingContentContainerMixin = css`
  max-width: 368px;

  @media (max-width: ${breakPoints.xs}px) {
    max-width: 100%;
  }
`;
