import React from 'react';
import styled from 'styled-components';

import Text from 'components/lib/ui/Text';

import { PREMIUM } from 'common/constants/copy';

const Root = styled(Text)`
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.book};
  text-transform: lowercase;
`;

type Props = {
  className?: string;
  text?: string;
};

const TaxLabel = ({ className, text }: Props) => (
  <Root className={className}>{text ?? PREMIUM.DEFAULT_TAX_DISCLAIMER}</Root>
);

export default TaxLabel;
