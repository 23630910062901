import * as RA from 'ramda-adjunct';
import React from 'react';

import RouteLink from 'components/lib/ui/link/RouteLink';
import BillingRow from 'components/settings/billing/BillingRow';
import BillingStatusRowV2 from 'components/settings/billing/BillingStatusRowV2';
import DeleteHouseholdRow from 'components/settings/billing/DeleteHouseholdRow';
import NextPaymentRow from 'components/settings/billing/NextPaymentRow';
import PaymentMethodRow from 'components/settings/billing/PaymentMethodRow';
import PromoCodeRow from 'components/settings/billing/PromoCodeRow';

import { formatCurrency } from 'common/utils/Currency';

import routes from 'constants/routes';

import type {
  Common_GetPremiumUpgradePlansQuery,
  Web_GetSubscriptionQuery,
  PaymentMethod,
  SubscriptionDetailsPaymentSource,
} from 'common/generated/graphql';
import { PaymentPeriod } from 'common/generated/graphql';

type Subscription = Web_GetSubscriptionQuery['subscription'];

type Props = {
  plans: Common_GetPremiumUpgradePlansQuery['subscriptionOffering']['plans'];
  hasChargedForLifetime: boolean | null | undefined;
  billingPeriod: PaymentPeriod | null | undefined;
  accessExpirationDate: string | null | undefined;
  paymentSource: SubscriptionDetailsPaymentSource | null | undefined;
  paymentMethod: PaymentMethod | null | undefined;
  promoCode: Subscription['activePromoCode'];
  nextPaymentAmount: number | null | undefined;
  nextPaymentDate: string | null | undefined;
  activeSponsorship: Subscription['activeSponsorship'];
  creditBalance: number | null | undefined;
  hasPremium: boolean;
  hasBillingIssue: boolean;
  willCancelAtPeriodEnd: boolean;
  isHouseholdOwner: boolean;
  isOnFreeTrial: boolean;
  isReferralsV2Active: boolean;
  hasStripeSubscriptionId: boolean;
  monthlyPriceDollars: number;
  eligibleForTrial: boolean;
  onClickRevokeSponsorAccess: () => void;
  onClickManageSubscription: () => void;
  onClickUpdatePaymentMethod: () => void;
  onClickDeleteHousehold: () => void;
};

const BillingDetailsCard = ({
  plans,
  billingPeriod,
  hasChargedForLifetime,
  accessExpirationDate,
  paymentSource,
  paymentMethod,
  promoCode,
  nextPaymentDate,
  nextPaymentAmount,
  activeSponsorship,
  creditBalance,
  hasPremium,
  hasBillingIssue,
  willCancelAtPeriodEnd,
  isHouseholdOwner,
  isOnFreeTrial,
  isReferralsV2Active,
  monthlyPriceDollars,
  hasStripeSubscriptionId,
  eligibleForTrial,
  onClickRevokeSponsorAccess,
  onClickUpdatePaymentMethod,
  onClickDeleteHousehold,
  onClickManageSubscription,
}: Props) => {
  const hasLifetimeSubscription = billingPeriod === PaymentPeriod.LIFETIME;
  const lifetimePlan = plans?.find((el) => el.period === PaymentPeriod.LIFETIME);

  const nextPaymentAmountOrLifetimeAmount =
    hasLifetimeSubscription && !hasChargedForLifetime
      ? lifetimePlan?.pricePerPeriodDollars
      : nextPaymentAmount;

  return (
    <>
      <BillingStatusRowV2
        hasPremium={hasPremium}
        billingPeriod={billingPeriod}
        willCancelAtPeriodEnd={willCancelAtPeriodEnd}
        paymentSource={paymentSource}
        accessExpirationDate={accessExpirationDate}
        onClickManageSubscription={onClickManageSubscription}
        activeSponsorship={activeSponsorship}
        isOnFreeTrial={isOnFreeTrial}
        eligibleForTrial={eligibleForTrial}
      />

      {hasPremium && RA.isNotNil(creditBalance) && creditBalance > 0 && (
        <BillingRow
          title="Credits"
          controls={
            <RouteLink to={routes.settings.referrals()} small>
              Learn more
            </RouteLink>
          }
        >
          {formatCurrency(creditBalance)}
        </BillingRow>
      )}

      {hasStripeSubscriptionId && (
        <>
          <PaymentMethodRow
            paymentSource={paymentSource}
            paymentMethod={paymentMethod}
            hasBillingIssue={hasBillingIssue}
            onClickUpdatePaymentMethod={onClickUpdatePaymentMethod}
          />
          <PromoCodeRow promoCode={promoCode} paymentSource={paymentSource} />
        </>
      )}
      {!!nextPaymentDate && RA.isNotNil(nextPaymentAmountOrLifetimeAmount) && (
        <NextPaymentRow
          date={nextPaymentDate}
          amount={nextPaymentAmountOrLifetimeAmount}
          monthlyPriceDollars={monthlyPriceDollars}
          activeSponsorship={activeSponsorship}
          hasLifetimeSubscription={hasLifetimeSubscription}
          onRevokeSponsorAccess={onClickRevokeSponsorAccess}
          isReferralsV2Active={isReferralsV2Active}
        />
      )}
      {(!hasPremium || willCancelAtPeriodEnd) && isHouseholdOwner && (
        <DeleteHouseholdRow onClickDeleteHousehold={onClickDeleteHousehold} />
      )}
    </>
  );
};

export default BillingDetailsCard;
