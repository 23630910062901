import * as React from 'react';
import styled from 'styled-components';

import AsyncButton from 'components/lib/ui/button/AsyncButton';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import { defaultButtonMixin } from 'components/lib/ui/button/DefaultButton';

import type { ButtonSize } from 'lib/styles/buttonSizeMixin';

import type { AuthProvider } from 'common/types/auth';

const Root = styled(AsyncButton)<{ id: string; size: ButtonSize }>`
  ${defaultButtonMixin};
  width: 100%;
`;

const iconSettingsByProvider = {
  // The icon strategy is different per provider as a result of the Google icon not
  // being apart of the icon font, which is intentional since it is not monochrome.
  // For this reason, the icons start from different sizes & settings & we customize
  // them here to make them look visually similar. Apple is shifted up slightly since
  // the logo doesn't quite look visually centered without it as a result of its shape.
  apple: { name: 'apple', size: 18, style: { transform: 'translateY(-2px)' } },
  google: { name: 'monarch-google-logo', size: 16, style: undefined },
};

export type Props = {
  text: string;
  className?: string;
  provider?: AuthProvider;
  pending?: boolean;
  disabled?: boolean;
  onClick: ((e?: React.SyntheticEvent) => void) | ((e: React.SyntheticEvent) => Promise<void>);
  right?: React.ReactNode;
};

const AuthButton = ({ text, provider, className, pending, disabled, onClick, right }: Props) => (
  <Root
    disabled={disabled}
    onClick={onClick}
    pending={pending}
    className={className}
    size="medium"
    id={`continue-with-${provider}-btn`}
  >
    {provider ? (
      <ButtonIcon
        style={iconSettingsByProvider[provider].style}
        size={iconSettingsByProvider[provider].size}
        name={iconSettingsByProvider[provider].name}
      />
    ) : null}
    <span>{text}</span>
    {right}
  </Root>
);

export default AuthButton;
