import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Mark from 'components/brand/Mark';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import {
  CollapseExpandButton,
  HeaderLink,
  MARK_SIZE_PX_V2,
} from 'components/routes/SideBarDefaultHeader';

import { toggleSidebarExpanded } from 'actions';
import { fontSize, fontWeight, spacing, variables } from 'common/lib/theme/dynamic';

import { SIDEBAR_COLLAPSED_CLASS_NAME } from 'constants/classNames';
import routes from 'constants/routes';

const LogoContainer = styled(FlexContainer)`
  align-items: center;
  gap: ${spacing.xsmall};
`;

const Separator = styled.div`
  width: 1px;
  height: 16px;
  background-color: ${variables.color.divider.secondary};

  .${SIDEBAR_COLLAPSED_CLASS_NAME} & {
    display: none;
  }
`;

const Title = styled(Text)`
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.small};
  color: ${variables.color.content.secondary};

  .${SIDEBAR_COLLAPSED_CLASS_NAME} & {
    display: none;
  }
`;

const SideBarAdvisorPortalHeader = () => {
  const dispatch = useDispatch();

  return (
    <>
      <HeaderLink to={routes.advisorPortal.clients()}>
        <LogoContainer>
          <Mark size={MARK_SIZE_PX_V2} />
          <Separator />
          <Title>Professional</Title>
        </LogoContainer>
      </HeaderLink>
      <CollapseExpandButton
        icon="sidebar"
        size="small"
        onClick={() => dispatch(toggleSidebarExpanded())}
      />
    </>
  );
};

export default SideBarAdvisorPortalHeader;
