import React from 'react';
import styled from 'styled-components';

import type { BlockRenderer, ImageBlock } from 'common/lib/posts/types';
import { radius, spacing, variables } from 'common/lib/theme/dynamic';
import useTheme from 'lib/hooks/useTheme';

import type { ThemeType } from 'common/types/Styles';

export const resolveUrl = (image: { url: string; darkModeUrl?: string }, theme: ThemeType) => {
  if (theme.uiTheme === 'dark') {
    return image.darkModeUrl ?? image.url;
  }
  return image.url;
};

const Root = styled.div`
  margin: ${spacing.default} 0;
`;

const StyledImage = styled.img`
  border-radius: ${radius.medium};
  background-color: ${variables.color.background.primary};
  width: 100%;
  height: auto;
`;

const ImageBlockRenderer: BlockRenderer<ImageBlock> = ({ data }) => {
  const theme = useTheme();

  return (
    <Root>
      <StyledImage src={resolveUrl(data, theme)} />
    </Root>
  );
};

export default ImageBlockRenderer;
