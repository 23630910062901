import type { DateTime } from 'luxon';
import { isNil } from 'ramda';
import * as RA from 'ramda-adjunct';
import { isNumber } from 'ramda-adjunct';
import React, { useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import BudgetAmountPopover from 'components/budget/BudgetAmountPopover';
import CurrencyInput from 'components/lib/form/CurrencyInput';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import { OverlayTrigger } from 'components/lib/ui/popover';
import PlanCellAmount from 'components/plan/PlanCellAmount';
import PlanCellMoveMoneyPill from 'components/plan/PlanCellMoveMoneyPill';
import { INPUT_HEIGHT_PX } from 'components/plan/PlanGrid';
import { PlanUnallocatedErrorBanner } from 'components/plan/PlanUnallocatedBudgetRow';
import { CellLoadingSpinner } from 'components/planning/PlanningTableCell';

import { getAvailableDisplayAmount } from 'common/lib/budget/Amounts';
import { isBudgetVariabilityOfType, isGoalsSection } from 'common/lib/budget/Budget';
import useHouseholdPreferences from 'common/lib/hooks/household/useHouseholdPreferences';
import useUpdateEffect from 'common/lib/hooks/useUpdateEffect';
import { usePlanContext } from 'lib/contexts/PlanContext';
import useKey from 'lib/hooks/useKey';
import type { PlanAmounts, PlanAmountType } from 'lib/plan';
import { getAmountForType } from 'lib/plan';
import type { PlanSectionType } from 'lib/plan/Adapters';

import { BudgetVariability, CategoryGroupType } from 'common/generated/graphql';

/**
 * These are errors that can be passed to the PlanCell component to display an error message.
 * We've created this enum to avoid using magic strings to identify errors.
 */
export enum PlanCellError {
  FlexibleBudgetExceeded = 'FLEXIBLE_BUDGET_EXCEEDED',
}

/**
 * This is the interface that the PlanCell component exposes to the parent by using `useImperativeHandle`.
 * This is useful for the parent to interact with the PlanCell component without the need to pass down callbacks or state.
 *
 * It should be used sparingly and only when necessary.
 */
export type PlanCellMethods = {
  setValue: (value: number, applyToFuture: boolean) => void;
};

const Root = styled.div`
  position: relative;
`;

const AmountInput = styled(CurrencyInput)<{
  $isHeader: boolean;
  $hasError?: boolean;
  $hideBorder?: boolean;
}>`
  width: 100px;
  height: ${INPUT_HEIGHT_PX}px;
  text-align: right;
  margin-right: -13px;
  background: none;
  font-size: ${({ $isHeader, theme }) => ($isHeader ? theme.fontSize.large : theme.fontSize.base)};
  border-color: ${({ theme, $hideBorder }) =>
    $hideBorder ? 'transparent' : theme.color.grayFocus};
  transition: border-color 150ms ease-out;

  &:not(:focus):not(:active):not(:focus-within) {
    color: ${({ $isHeader, $hideBorder, theme }) =>
      !$hideBorder || $isHeader ? theme.color.text : 'transparent'};
  }

  ${({ $hasError, theme }) =>
    $hasError &&
    css`
      border: 1px solid ${theme.color.red};

      &&:hover:not(:focus) {
        border-color: ${theme.color.red};
      }
    `}
`;

type Props = {
  date: DateTime;
  canEdit: boolean;
  amounts: PlanAmounts | undefined;
  amountType: PlanAmountType;
  sectionType: PlanSectionType;
  itemId?: string;
  itemName?: string;
  itemIcon?: React.ReactNode | string;
  itemHasRolloverEnabled?: boolean;
  isCategoryGroup?: boolean;
  isAggregate?: boolean;
  isUnplannedAggregate?: boolean;
  isLoading?: boolean;
  showPlanAmountsTooltip: boolean;
  groupLevelBudgetingEnabled?: boolean;
  allowMoveMoney: boolean;
  error?: PlanCellError;
  budgetVariability?: Maybe<BudgetVariability>;
  isOverspentButCoveredByFlexibleAmount?: boolean;
  onChangeValue?: (value: number, applyToFuture: boolean) => void;
  openEditGroupModal?: () => void;
  updateFlexBudgetValue?: () => void;
  isPlanSummary?: boolean;
};

const PlanCell: React.ForwardRefRenderFunction<PlanCellMethods, Props> = (
  {
    date,
    canEdit,
    amounts,
    amountType,
    sectionType,
    itemId,
    itemName,
    itemIcon,
    itemHasRolloverEnabled,
    isCategoryGroup = false,
    isAggregate = false,
    isUnplannedAggregate = false,
    isLoading,
    showPlanAmountsTooltip = false,
    groupLevelBudgetingEnabled = false,
    allowMoveMoney,
    error,
    budgetVariability,
    isOverspentButCoveredByFlexibleAmount,
    onChangeValue,
    openEditGroupModal,
    updateFlexBudgetValue,
    isPlanSummary,
  },
  planCellRef,
) => {
  const { budgetApplyToFutureMonthsDefault } = usePlanContext();
  const { isFlexBudgeting } = useHouseholdPreferences();

  const isRemainingAmountType = amountType === 'remaining';
  const isBudgetType = amountType === 'budgeted';
  const value = getAmountForType(amounts, amountType);
  const availableDisplayAmount = getAvailableDisplayAmount(value, sectionType);
  const [localValue, setLocalValue] = useState(value ?? 0);

  const [localAppliedToFuture, setLocalAppliedToFuture] = useState(
    budgetApplyToFutureMonthsDefault,
  );

  // Ensure that we always default to outside state when it changes
  useUpdateEffect(() => {
    setLocalValue(value ?? 0);
  }, [value]);
  useUpdateEffect(() => {
    setLocalAppliedToFuture(budgetApplyToFutureMonthsDefault);
  }, [budgetApplyToFutureMonthsDefault]);

  const inputRef = useRef<HTMLElement>(null);

  const onBlur = useCallback(() => {
    if (localValue !== value || !localAppliedToFuture) {
      onChangeValue?.(localValue, localAppliedToFuture);
    }
  }, [value, onChangeValue, localValue, localAppliedToFuture]);

  const onSubmit = useCallback(() => {
    inputRef.current?.blur();
  }, [inputRef]);

  const onClickChartAmount = useCallback(
    (amount: number) => {
      setLocalValue(amount);
      setTimeout(() => inputRef.current?.blur(), 0); // Need setTimeout so localValue updates before blur
    },
    [setLocalValue, inputRef],
  );

  useImperativeHandle(
    planCellRef,
    (): PlanCellMethods => ({
      setValue: (amount, applyToFuture) => {
        setLocalValue(amount);
        onChangeValue?.(amount, applyToFuture);
      },
    }),
  );

  useKey('Escape', inputRef.current, { onKeyDown: () => inputRef.current?.blur() });
  useKey('Enter', inputRef.current, { onKeyDown: onSubmit });
  useKey('Tab', inputRef.current, { onKeyDown: onSubmit });

  const showRolloverIcon =
    (itemHasRolloverEnabled || RA.isNotNil(amounts?.rolloverType)) &&
    amountType === 'remaining' &&
    sectionType !== 'savings';

  const isFlexSection =
    isFlexBudgeting &&
    isBudgetVariabilityOfType(budgetVariability, BudgetVariability.FLEXIBLE) &&
    sectionType === CategoryGroupType.EXPENSE;

  const canHideBorder = useMemo(
    () => (isNumber(localValue) ? localValue === 0 : isNil(localValue)) && isFlexSection,
    [localValue, isFlexSection],
  );

  const showBudgetAmountPopover = sectionType !== 'savings' && !!itemId;
  const isFlexBudgetMoneyMovementRestricted =
    isRemainingAmountType && isFlexBudgeting && itemId === BudgetVariability.FLEXIBLE;

  const shouldShowMoveMoneyPill =
    allowMoveMoney || (isGoalsSection(sectionType) && isRemainingAmountType && !isAggregate);

  const updateFlexBudgetValueWithBlur = useCallback(() => {
    updateFlexBudgetValue?.();
    setTimeout(() => inputRef.current?.blur(), 0);
  }, [updateFlexBudgetValue]);

  return (
    <Root>
      <Switch>
        <Case when={!!isLoading}>
          <CellLoadingSpinner />
        </Case>
        <Case when={canEdit}>
          <OverlayTrigger
            overlay={
              showBudgetAmountPopover && (
                <BudgetAmountPopover
                  header={
                    !!updateFlexBudgetValue &&
                    !!error && (
                      <PlanUnallocatedErrorBanner
                        onClickUpdateFlexBudget={updateFlexBudgetValueWithBlur}
                      />
                    )
                  }
                  itemId={itemId}
                  appliedToFuture={localAppliedToFuture}
                  onCheckApplyToFuture={setLocalAppliedToFuture}
                  isCategoryGroup={isCategoryGroup}
                  isIncome={sectionType === CategoryGroupType.INCOME}
                  onClickBar={onClickChartAmount}
                  value={localValue}
                />
              )
            }
            placement="bottom-end"
          >
            {({ open, close }) => (
              <AmountInput
                ref={inputRef}
                tabIndex={0}
                name="budgeted"
                selectOnFocus
                value={localValue}
                onChange={setLocalValue}
                onBlur={() => {
                  close();
                  onBlur();
                }}
                onFocus={open}
                autoComplete="off"
                maskOptions={{ allowNegative: true }}
                {...sensitiveClassProps}
                bold={isAggregate}
                $isHeader={isAggregate}
                $hasError={!!error}
                $hideBorder={canHideBorder}
              />
            )}
          </OverlayTrigger>
        </Case>
        {/* Combined case for flex budget restrictions and general money movement */}
        <Case when={shouldShowMoveMoneyPill}>
          <PlanCellMoveMoneyPill
            date={date}
            amounts={amounts}
            availableDisplayAmount={availableDisplayAmount}
            sectionType={sectionType}
            itemId={itemId}
            itemName={itemName}
            showPlanAmountsTooltip={showPlanAmountsTooltip}
            isAggregate={isAggregate}
            showRolloverIcon={showRolloverIcon}
            // Conditional props
            itemIcon={isFlexBudgetMoneyMovementRestricted ? itemIcon : undefined}
            isCategoryGroup={!isFlexBudgetMoneyMovementRestricted ? isCategoryGroup : undefined}
            // Movability logic
            isMovable={allowMoveMoney}
          />
        </Case>
        <Case default>
          <PlanCellAmount
            amounts={amounts}
            showPlanAmountsTooltip={showPlanAmountsTooltip}
            itemId={itemId}
            itemName={itemName}
            itemIcon={isFlexBudgetMoneyMovementRestricted ? itemIcon : undefined}
            isAggregate={isAggregate}
            isUnplannedAggregate={isUnplannedAggregate}
            groupLevelBudgetingEnabled={groupLevelBudgetingEnabled}
            isRemainingAmountType={isRemainingAmountType}
            value={value}
            availableDisplayAmount={availableDisplayAmount}
            isBudgetType={isBudgetType}
            sectionType={sectionType}
            openEditGroupModal={openEditGroupModal}
            isCategoryGroup={isCategoryGroup}
            showRolloverIcon={showRolloverIcon}
            isFlexSection={isFlexSection}
            // Warning text for flex budget overspending
            warningText={
              isOverspentButCoveredByFlexibleAmount
                ? 'This category is overspent, but the Flexible budget amount covers it.'
                : undefined
            }
            isPlanSummary={isPlanSummary}
          />
        </Case>
      </Switch>
    </Root>
  );
};

export default React.memo(React.forwardRef(PlanCell));
