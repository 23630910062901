import { useQuery, useMutation } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Tabs from 'common/components/tabs/Tabs';
import Switch, { Case } from 'common/components/utils/Switch';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import PillTab from 'components/lib/ui/tabs/PillTab';
import TransactionSplitForm from 'components/transactions/TransactionSplitForm';
import TransactionSplitOriginalTransactionContainer from 'components/transactions/TransactionSplitOriginalTransactionContainer';

import {
  SPLIT_TRANSACTION_MUTATION,
  TRANSACTION_SPLIT_QUERY,
} from 'common/lib/graphQl/transactions';
import useEventCallback from 'common/lib/hooks/useEventCallback';
import { breakPoints, spacing } from 'common/lib/theme/dynamic';
import type { FormValues } from 'common/lib/transactions/Splits';
import { getDefaultSplitDataValues, getInitialFormValues } from 'common/lib/transactions/Splits';

import { SplitAmountType } from 'common/generated/graphql';

const Root = styled(ModalCard)`
  margin: 0 auto;
  width: 100%;

  @media (min-width: ${breakPoints.md}px) {
    width: ${breakPoints.md}px;
  }
`;

const LoadingContainer = styled(FlexContainer).attrs({ center: true })`
  flex: 1;
  padding: ${spacing.xlarge};
`;

const SectionHeader = styled(FlexContainer).attrs({
  justifyBetween: true,
  alignCenter: true,
})`
  padding: ${spacing.small} ${spacing.xlarge};
`;

const SectionHeading = styled(Text).attrs({
  size: 'xsmall',
  weight: 'bold',
  color: 'textLight',
})``;

const TabsContainer = styled(FlexContainer).attrs({
  justifyCenter: true,
  alignCenter: true,
  gap: 'xsmall',
})``;

type Props = {
  id: string;
  onDone: () => void;
  onCancel: () => void;
};

const TransactionSplitModal = ({ id, onDone, onCancel }: Props) => {
  const { data, loading } = useQuery(TRANSACTION_SPLIT_QUERY, {
    variables: { id: id ?? '' },
  });
  const { splitTransactions } = data?.getTransaction ?? {};

  // useState so that once the mutation is complete the ui doesn't update (while the refetching is happening)
  const [isEditing, setIsEditing] = useState<boolean | undefined>(undefined);

  const [amountType, setAmountType] = useState<SplitAmountType>(SplitAmountType.ABSOLUTE);
  const amountTypeOrder = [SplitAmountType.ABSOLUTE, SplitAmountType.PERCENTAGE];

  const [initialFormValues, setInitialFormValues] = useState<FormValues | undefined>(undefined);

  useEffect(() => {
    if (splitTransactions && isEditing === undefined) {
      setIsEditing(splitTransactions.length > 0);
    }
  }, [splitTransactions, isEditing, setIsEditing]);

  useEffect(() => {
    if (data && data.getTransaction && initialFormValues === undefined) {
      const defaultSplit = getDefaultSplitDataValues(data.getTransaction);
      setInitialFormValues(getInitialFormValues(data.getTransaction, [defaultSplit, defaultSplit]));
    }
  }, [data, setInitialFormValues, initialFormValues]);

  const [splitTransaction] = useMutation(SPLIT_TRANSACTION_MUTATION, {
    refetchQueries: ['AccountDetails_getAccount', 'Common_TransactionSplitQuery'],
  });

  const onChangeAmountType = useEventCallback((index: number) => {
    setAmountType(amountTypeOrder[index]);
  });

  return (
    <Root
      title="Split Transaction"
      description="Splitting a transaction will create individual transactions that you can categorize and manage separately."
    >
      <Switch>
        <Case when={loading && !data}>
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        </Case>
        <Case default>
          <SectionHeader>
            <SectionHeading>ORIGINAL</SectionHeading>
          </SectionHeader>
          <TransactionSplitOriginalTransactionContainer
            originalTransaction={data?.getTransaction}
          />
          <SectionHeader>
            <SectionHeading>SPLITS</SectionHeading>
            <Tabs
              index={amountTypeOrder.findIndex((type) => type === amountType)}
              onChangeIndex={onChangeAmountType}
            >
              <TabsContainer>
                <PillTab index={0} $background="card">
                  By Amount
                </PillTab>
                <PillTab index={1} $background="card">
                  By Percent
                </PillTab>
              </TabsContainer>
            </Tabs>
          </SectionHeader>
          {initialFormValues && (
            <TransactionSplitForm
              onCancel={onCancel}
              onSubmitSuccess={onDone}
              mutation={splitTransaction}
              initialValues={initialFormValues}
              originalTransaction={data?.getTransaction}
              isEditMode={isEditing ?? false}
              amountType={amountType}
            />
          )}
        </Case>
      </Switch>
    </Root>
  );
};

export default TransactionSplitModal;
