import queryString from 'query-string';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { isNotNil } from 'ramda-adjunct';

import { objToKey } from 'common/utils/Object';

import type { TransactionFilterInput } from 'common/generated/graphql';
import type { TransactionFilters } from 'types/filters';

export const allAmounts = 'allAmounts';
export const greaterThan = 'greaterThan';
export const lessThan = 'lessThan';
export const equalTo = 'equalTo';
export const between = 'between';
export const minAmount = 'absAmountGte';
export const maxAmount = 'absAmountLte';

export const DEFAULT_FILTERS: TransactionFilters = {
  search: undefined,
  categories: undefined,
  accounts: undefined,
  absAmountGte: undefined,
  absAmountLte: undefined,
  amountFilter: undefined,
  tags: undefined,
  isUntagged: undefined,
  startDate: undefined,
  endDate: undefined,
  timeframePeriod: undefined,
  categoryGroups: undefined,
  order: undefined,
  goalId: undefined,
  hasAttachments: undefined,
  hasNotes: undefined,
  hideFromReports: undefined,
  householdMerchants: undefined,
  ids: undefined,
  importedFromMint: undefined,
  isRecurring: undefined,
  isSplit: undefined,
  isUncategorized: undefined,
  merchants: undefined,
  needsReview: undefined,
  uploadedStatement: undefined,
  needsReviewByUser: undefined,
  needsReviewUnassigned: undefined,
  isFlexSpending: undefined,
  syncedFromInstitution: undefined,
  categoryType: undefined,
  isInvestmentAccount: undefined,
  creditsOnly: undefined,
  debitsOnly: undefined,
  isPending: undefined,
  excludeIds: undefined,
};

export const resetAmountFilters = (
  values: TransactionFilters,
  amountFilter = '',
): TransactionFilters => {
  switch (amountFilter) {
    case equalTo:
      return { ...values, absAmountGte: values.absAmountLte };
    case allAmounts:
      return R.omit([minAmount, maxAmount], values) as TransactionFilters;
    case lessThan:
      return R.omit([minAmount], values) as TransactionFilters;
    case greaterThan:
      return R.omit([maxAmount], values) as TransactionFilters;
    default:
      return values;
  }
};

export const amountFilterOptions = [
  { label: 'All amounts', value: allAmounts },
  { label: 'Greater than', value: greaterThan },
  { label: 'Less than', value: lessThan },
  { label: 'Equal to', value: equalTo },
  { label: 'Between', value: between },
];

/**
 * 1. Omit `amountFilter` because the BE only needs the gte/lte amounts
 * (the amountFilter type can be inferred from the values of absAmountGte/Lte)
 * 2. Omit `order` because the BE doesn't need it on filters
 * 3. Omit `timeframePeriod` (or its __typename) in the right situations.
 * */
export const convertFiltersToInput = (filters: Partial<TransactionFilters>) => {
  const merged = R.mergeLeft(filters, DEFAULT_FILTERS);

  const intermediateFilters = R.omit(['amountFilter', 'order'])(
    merged,
  ) as unknown as TransactionFilterInput;

  // While the date resolution implementation on both the frontend and backend handles the situation
  // where we have an anchor start / end date that we can use a relative timeframe against (e.g.,
  // next 2 quarters after 2/6/2023, or last 1 year before 3/1/2022), we explicitly prioritize the
  // start / end dates and unset the relative timeframe period when they exist simultaneously. This
  // is because (as of ENG-11409, i.e. Saved Reports launch) this is currently an unintended state
  // that can't be reached by users through the existing UI. It's possible that users could finagle
  // the url to reach this state, but we don't want to support this since the UI would only
  // partially reflect the state of the filters within the date pickers.
  if (isNotNil(intermediateFilters.startDate) || isNotNil(intermediateFilters.endDate)) {
    intermediateFilters.timeframePeriod = undefined;
  }

  if (isNotNil(intermediateFilters.timeframePeriod)) {
    intermediateFilters.timeframePeriod = R.omit(['__typename'])(
      intermediateFilters.timeframePeriod,
    );
  }

  return intermediateFilters;
};

/**
 * This function corrects the date options in the provided filters because the API does not allow
 * passing both a date range and also a timeframe period. This scenario can happen when we're
 * looking at a relative timeframe, but drill down into a date range. In these cases, we'd like to
 * just default to the date range selected.
 */
export const correctDatesForFilters = (filters: Partial<TransactionFilters>) => {
  if (
    (RA.isNotNil(filters.startDate) || RA.isNotNil(filters.endDate)) &&
    RA.isNotNil(filters.timeframePeriod)
  ) {
    const { timeframePeriod, ...remainingFilters } = filters;
    return remainingFilters;
  }

  return filters;
};

/**
 * Generate query parameters from the filters, overriding or keeping the current ones
 * if necessary (e.g. if the user is already on the page with a filter)
 */
export const getUpdatedQueryParams = (params: Record<string, unknown>, override?: boolean) => {
  const currentParams = override ? {} : queryString.parse(window.location.search);
  return { ...currentParams, ...params };
};

const stringifyFilters = <T extends Partial<TransactionFilters>>(filters: T) =>
  R.pipe(R.reject(RA.isNilOrEmpty), objToKey)(filters);

export const isAnyFilterApplied = <T extends Partial<TransactionFilters>>(filters: T) =>
  !R.equals(stringifyFilters(filters), stringifyFilters(DEFAULT_FILTERS));
