import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import type {
  Props as FullScreenSelectProps,
  SelectGroupType,
} from 'components/lib/form/FullScreenSelect';
import { FullScreenSelect, useSelectContext } from 'components/lib/form/FullScreenSelect';
import type { Props as SelectProps } from 'components/lib/form/Select';
import Badge from 'components/lib/ui/Badge';
import ContentLoader from 'components/lib/ui/ContentLoader';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import MerchantLogo from 'components/merchants/MerchantLogo';

import useDebounce from 'common/lib/hooks/useDebounce';
import useQuery from 'common/lib/hooks/useQuery';
import { track } from 'lib/analytics/segment';
import { getIsEmployee } from 'selectors';

import { AnalyticsEventNames } from 'common/constants/analytics';
import { TYPEAHEAD_DEBOUNCE_TIME_MS } from 'common/constants/form';

import { gql } from 'common/generated/gql';
import { MerchantOrdering } from 'common/generated/graphql';

type Merchant = {
  id: string;
  name: string;
  source?: string | null | undefined;
  logoUrl?: string | null | undefined;
  transactionCount?: number | null;
};

type RecommendedMerchant = {
  name: string;
  source: string;
  transactionCount?: number | null;
};

type Props = Omit<SelectProps, 'onChange'> & {
  className?: string;
  placeholder?: string;
  transactionId?: string;
  transactionProviderDescription?: string | null;
  transactionMerchantName?: string | null;
  alwaysShowBorder?: boolean;
  fullWidthTrigger?: boolean;
  onChange?: (merchantIdOrName: string) => Promise<void>;
  onBlur?: () => void;
} & Pick<FullScreenSelectProps, 'italicizeValueLabel' | 'disabled' | 'renderSelectedOption'>;

const FullHeightMerchantSelect = ({
  filters,
  onChange,
  placeholder,
  transactionId,
  value: selectedValue,
  transactionMerchantName,
  transactionProviderDescription,
  fullWidthTrigger,
  alwaysShowBorder = true,
  ...props
}: Props) => {
  const isEmployee = useSelector(getIsEmployee);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearch = useDebounce(searchQuery, TYPEAHEAD_DEBOUNCE_TIME_MS);

  const { refetch: refetchHouseholdMerchants, fetchMore: fetchMoreHouseholdMerchants } = useQuery(
    GET_MERCHANTS,
    {
      skip: true,
      variables: {
        offset: 0,
        limit: 50,
        orderBy: MerchantOrdering.TRANSACTION_COUNT,
        search: debouncedSearch,
      },
    },
  );
  const { refetch: refetchRecommendedMerchants } = useQuery(GET_RECOMMENDED_MERCHANTS, {
    skip: true,
    variables: {
      transactionId: transactionId ?? '', // Note: Query would fail at run-time if transactionId is empty
    },
  });

  // NOTE: ideally we should use isNetworkRequestInFlight from the useQuery hook, but for some
  // reason it doesn't work in this case, probably due to the skip + refetch combination.
  const [isLoadingHouseholdMerchants, setIsLoadingHouseholdMerchants] = useState<boolean>(false);
  const [isLoadingRecommendedMerchants, setIsLoadingRecommendedMerchants] = useState<boolean>(true);

  const [householdMerchants, setHouseholdMerchants] = useState<Merchant[]>([]);
  const [recommendedMerchants, setRecommendedMerchants] = useState<RecommendedMerchant[]>([]);

  useEffect(() => {
    setIsLoadingRecommendedMerchants(true);
  }, []);

  const handleFullScreenSelectOpened = () => {
    // Load household merchants
    setIsLoadingHouseholdMerchants(true);
    refetchHouseholdMerchants().then((resp) => {
      setHouseholdMerchants(resp.data.merchants);
      setIsLoadingHouseholdMerchants(false);
    });
    // Load recommended merchants, if possible
    if (transactionId) {
      setIsLoadingRecommendedMerchants(true);
      refetchRecommendedMerchants().then((resp) => {
        setRecommendedMerchants(resp.data?.recommendedMerchants ?? []);
        setIsLoadingRecommendedMerchants(false);
      });
    }
  };
  const allGroups = useMemo<SelectGroupType[]>(() => {
    const shouldHideRecommendedMerchantsSection =
      !transactionProviderDescription ||
      !RA.isEmptyString(searchQuery) ||
      (!isLoadingRecommendedMerchants && R.isEmpty(recommendedMerchants));

    const shouldShowLoadingRecommendedMerchants =
      isLoadingRecommendedMerchants || !transactionProviderDescription;

    return [
      {
        id: 'description',
        label: 'Original statement',
        options: [
          {
            value: transactionProviderDescription || '--',
            label: transactionProviderDescription || '--',
            disabled: !transactionProviderDescription,
            dontFocusOnSearch: true,
          },
        ],
        loadingComponent: <TransactionDescriptionSkeleton />,
        fixed: true,
      },
      ...(shouldHideRecommendedMerchantsSection
        ? []
        : [
            {
              id: 'recommended',
              label: 'Recommended merchants',
              options: recommendedMerchants
                .map((el) => ({
                  id: el.name,
                  name: el.name,
                  value: el.name,
                  label: el.name,
                  source: isEmployee ? el.source : undefined,
                  transactionCount: el.transactionCount,
                }))
                .map(optionFromMerchant),
              loading: shouldShowLoadingRecommendedMerchants,
              loadingComponent: <MerchantsListSkeleton />,
              fixed: true,
            },
          ]),
      ...(!RA.isEmptyString(searchQuery) && householdMerchants.length > 0
        ? [
            {
              id: 'household_merchants',
              label: 'Your merchants',
              options: householdMerchants.map(optionFromMerchant) || [],
              loading: isLoadingHouseholdMerchants,
              loadingComponent: <MerchantsListSkeleton />,
              fixed: false,
            },
          ]
        : []),
    ];
  }, [
    filters,
    searchQuery,
    householdMerchants,
    recommendedMerchants,
    transactionMerchantName,
    transactionProviderDescription,
    isLoadingHouseholdMerchants,
    isLoadingRecommendedMerchants,
  ]);

  const handleValueChange = useCallback(
    (merchantIdOrName: string) => {
      const existingHouseholdMerchant = householdMerchants.find(
        (merchant) => merchant.id === merchantIdOrName,
      );
      if (existingHouseholdMerchant) {
        track(AnalyticsEventNames.ExistingMerchantSelected, {
          transactionId,
          transactionProviderDescription,
          merchantName: existingHouseholdMerchant.name,
        });
      } else {
        track(AnalyticsEventNames.RecommendedMerchantEnrichmentSelected, {
          transactionId,
          transactionProviderDescription,
          merchantName: merchantIdOrName,
          source:
            recommendedMerchants.find((merchant) => merchant.name === merchantIdOrName)?.source ??
            'statement',
        });
      }
      onChange?.(existingHouseholdMerchant ? existingHouseholdMerchant.name : merchantIdOrName);
    },
    [householdMerchants, onChange],
  );

  const handleCreateMerchantButtonClicked = () => {
    track(AnalyticsEventNames.CustomMerchantEnrichmentCreated, {
      transactionId,
      transactionProviderDescription,
      merchantName: searchQuery,
    });
    onChange?.(searchQuery);
  };

  const handleSearchQueryChanged = (query: string) => {
    setSearchQuery(query);
    refetchHouseholdMerchants({
      offset: 0,
      search: query,
    }).then((resp) => {
      setHouseholdMerchants(resp.data.merchants);
    });
  };

  const handleLoadMoreHouseholdMerchants = () => {
    fetchMoreHouseholdMerchants({
      variables: { offset: householdMerchants.length },
    }).then((resp) => {
      setHouseholdMerchants([...householdMerchants, ...resp.data.merchants]);
    });
  };

  return (
    <>
      <FullScreenSelect
        value={selectedValue}
        options={allGroups}
        onChange={handleValueChange}
        footerSection={
          searchQuery && (
            <CreateMerchantSection
              merchantName={searchQuery}
              onCreateMerchant={handleCreateMerchantButtonClicked}
            />
          )
        }
        alwaysShowBorder={alwaysShowBorder}
        fullWidthTrigger={fullWidthTrigger}
        searchPlaceholder={placeholder ?? 'Search merchants...'}
        onLoadMore={handleLoadMoreHouseholdMerchants}
        onOpen={handleFullScreenSelectOpened}
        onSearch={handleSearchQueryChanged}
        fixedGroupsAlwaysVisible
        {...props}
      />
    </>
  );
};

const CreateMerchantFooter = styled(FlexContainer).attrs({ alignCenter: true })`
  border-top: 1px solid ${({ theme }) => theme.color.grayFocus};
  color: ${({ theme }) => theme.color.blue9};
  cursor: pointer;
`;

const CreateMerchantText = styled.span`
  margin: ${({ theme }) => theme.spacing.xsmall};
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.default};
  width: 100%;
  &:hover {
    color: ${({ theme }) => theme.color.blue10};
    background-color: ${({ theme }) => theme.color.blue3};
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CreateMerchantSection = ({
  merchantName,
  onCreateMerchant,
}: {
  merchantName: string | undefined;
  onCreateMerchant: () => void;
}) => {
  // This component must be rendered inside the SelectRoot hierarchy
  const context = useSelectContext();
  return (
    <CreateMerchantFooter
      onClick={() => {
        context.onOpenChange(false);
        onCreateMerchant();
      }}
    >
      <CreateMerchantText>{`Create new "${merchantName}" merchant`}</CreateMerchantText>
    </CreateMerchantFooter>
  );
};

const TransactionsCountContainer = styled.div`
  color: ${({ theme }) => theme.color.textLight};
`;

const MerchantNameContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 24px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  > .name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: ${({ theme }) => theme.spacing.xsmall};
  }

  > :first-child {
    margin-right: ${({ theme }) => theme.spacing.xsmall};
  }
`;

const StyledBadge = styled(Badge)`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const nameFromSource = (source: string | null | undefined) => {
  if (source === 'llm') {
    return 'LLM';
  } else if (source === 'vector_index') {
    return 'Index';
  }
  return undefined;
};

const optionFromMerchant = ({ id, name, source, logoUrl, transactionCount }: Merchant) => ({
  value: id,
  label: (
    <Flex full row justifyBetween alignCenter>
      <MerchantNameContainer>
        <MerchantLogo url={logoUrl} size={20} />
        <span className="name">{name}</span>
      </MerchantNameContainer>
      {source && <StyledBadge color="grayLight">{nameFromSource(source)}</StyledBadge>}
      {RA.isPositive(transactionCount) && (
        <TransactionsCountContainer data-select-hover>
          <Icon name="credit-card" /> {transactionCount}
        </TransactionsCountContainer>
      )}
    </Flex>
  ),
  name,
});

const TransactionDescriptionSkeleton = ({ className }: { className?: any }) => (
  <ContentLoader className={className} height={32}>
    <rect x="24" y="6" rx="5" ry="5" width="72" height="14" />
  </ContentLoader>
);

const MerchantsListSkeleton = ({ className }: { className?: any }) => (
  <ContentLoader className={className} height={96}>
    <circle cx="32" cy="12" r="10" />
    <rect x="48" y="6" rx="5" ry="5" width="72" height="14" />

    <circle cx="32" cy="48" r="10" />
    <rect x="48" y="42" rx="5" ry="5" width="96" height="14" />

    <circle cx="32" cy="84" r="10" />
    <rect x="48" y="78" rx="5" ry="5" width="72" height="14" />
  </ContentLoader>
);

export const GET_TRANSACTION = gql(/* GraphQL */ `
  query Web_GetMerchantSelectTransactionDetails($transactionId: UUID!) {
    getTransaction(id: $transactionId) {
      id
      dataProviderDescription
      merchant {
        id
        name
      }
    }
  }
`);

export const GET_MERCHANTS = gql(/* GraphQL */ `
  query Web_GetMerchantSelectHouseholdMerchants(
    $offset: Int!
    $limit: Int!
    $orderBy: MerchantOrdering
    $search: String
  ) {
    merchants(
      offset: $offset
      limit: $limit
      orderBy: $orderBy
      search: $search
      includeMerchantsWithoutTransactions: false
    ) {
      id
      name
      logoUrl
      transactionCount
    }
  }
`);

export const GET_RECOMMENDED_MERCHANTS = gql(/* GraphQL */ `
  query Web_GetMerchantSelectRecommendedMerchants($transactionId: ID!) {
    recommendedMerchants(id: $transactionId) {
      name
      source
      transactionCount
    }
  }
`);

export default FullHeightMerchantSelect;
