import { useEffect, useContext } from 'react';

import ScrollContext from 'lib/contexts/ScrollContext';

const useScrollToTop = () => {
  const { scrollRef } = useContext(ScrollContext);

  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, [scrollRef]);
};

export default useScrollToTop;
