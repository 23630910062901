import { ellipsis } from 'polished';
import React from 'react';
import type { DefaultTheme } from 'styled-components';
import styled from 'styled-components';

import { getColorVariable } from 'common/lib/theme/getColorVariable';

import type { ColorVariablePaths, ThemeType } from 'common/types/Styles';

type Color = keyof DefaultTheme['color'];

const getColor = ({
  color,
  colorVariable,
  theme,
}: {
  color?: Color;
  colorVariable?: ColorVariablePaths;
  theme: ThemeType;
}) => {
  if (color) {
    const value = theme.color[color];
    if (value) {
      return value;
    }
  }

  if (colorVariable) {
    const value = getColorVariable(colorVariable, theme.variables.color);
    if (value) {
      return value;
    }
  }

  return 'inherit';
};

const Text = styled.span<{
  weight?: keyof DefaultTheme['fontWeight'];
  italic?: boolean;
  size?: keyof DefaultTheme['fontSize'];
  color?: keyof DefaultTheme['color'];
  colorVariable?: ColorVariablePaths;
  align?: 'left' | 'right' | 'center' | 'justify';
  transform?: 'uppercase' | 'lowercase';
  clampLines?: number;
}>`
  font-weight: ${({ weight, theme }) => (weight ? theme.fontWeight[weight] : 'inherit')};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  font-size: ${({ theme, size }) => (size ? theme.fontSize[size] : 'inherit')};
  color: ${({ theme, color, colorVariable }) => getColor({ theme, color, colorVariable })};
  text-align: ${({ align }) => align ?? 'inherit'};
  line-height: 150%;
  text-transform: ${({ theme, transform }) => transform ?? 'none'};

  ${({ clampLines }) => clampLines && ellipsis(undefined, clampLines)};
`;
/**
 * Component that renders text with line breaks preserved.
 * It splits the input text by newline characters and renders each line
 * separately, inserting <br /> elements between lines.
 */
export const TextWithLineBreaks = React.memo(({ text }: { text: string }) => (
  <>
    {text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ))}
  </>
));

export default Text;
