import useEventCallback from 'common/lib/hooks/useEventCallback';

type Args = {
  value: boolean;
  onChange: (value: boolean) => void;
};

export const useOnboardingSurveyRadio = ({ value, onChange }: Args) => {
  const onInteract = useEventCallback(() => {
    if (value) {
      return;
    }

    onChange(!value);
  });

  const onKeyDown = useEventCallback((event: React.KeyboardEvent) => {
    if (!value && event.key === ' ') {
      event.preventDefault();
      onChange(!value);
    }
  });

  return { onInteract, onKeyDown };
};
