import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import ProgressBar from 'components/lib/ui/ProgressBar';
import Text from 'components/lib/ui/Text';
import TextButton from 'components/lib/ui/TextButton';
import { Box } from 'components/settings/referrals/Box';
import { Boxes } from 'components/settings/referrals/Boxes';

import { spacing, variables } from 'common/lib/theme/dynamic';
import { formatCurrencyNoCents } from 'common/utils/Currency';
import { formatThousands } from 'common/utils/Number';
import { isoDateToAbbreviatedMonthDayAndYear } from 'common/utils/date';

const Root = styled(FlexContainer).attrs({ column: true })`
  gap: ${spacing.xlarge};
`;

const Progress = styled(FlexContainer).attrs({ column: true })`
  gap: ${spacing.xsmall};
`;

const Earnings = styled(FlexContainer).attrs({ column: true })`
  gap: ${spacing.default};
`;

const EarningsItem = styled(FlexContainer).attrs({ justifyBetween: true })`
  padding: ${spacing.small} 0;
  gap: ${spacing.xlarge};
  border-top: 1px solid ${variables.color.divider.primary};
`;

const EarningsList = styled(FlexContainer).attrs({ column: true })`
  border-bottom: 1px solid ${variables.color.divider.primary};
`;

const dummyEarnings = [
  { id: '1', date: '2025-07-06T12:00:00Z', value: 50 },
  { id: '2', date: '2025-02-13T12:00:00Z', value: 50 },
  { id: '3', date: '2025-01-11T12:00:00Z', value: 50 },
];

type Props = {
  className?: string;
};

export const ReferralEarnings = ({ className }: Props) => (
  <Root className={className}>
    <Progress>
      <FlexContainer justifyBetween alignCenter>
        <Text colorVariable="content.primary" weight="medium">
          2025
        </Text>

        <Text colorVariable="content.secondary" size="small">
          $150 / $500
        </Text>
      </FlexContainer>

      <ProgressBar
        value={0.5}
        colorVariable="progress.success"
        roundAppearance
        roundInnerBar={false}
      />
    </Progress>

    <Boxes>
      <Box
        value={formatCurrencyNoCents(150)}
        label="Earned in 2024"
        colorVariable="content.success"
      />
      <Box value={formatThousands(1)} label="Pending" />
      <Box value={formatThousands(3)} label="Completed" />
    </Boxes>

    {dummyEarnings.length > 0 && (
      <Earnings>
        <Text colorVariable="content.primary" weight="medium">
          Gift cards earned
        </Text>

        <EarningsList>
          {dummyEarnings.map(({ id, date, value }) => (
            <EarningsItem key={id}>
              <Text colorVariable="content.primary">
                {isoDateToAbbreviatedMonthDayAndYear(date)} - {formatCurrencyNoCents(value)} earned
              </Text>

              <TextButton>Resend email</TextButton>
            </EarningsItem>
          ))}
        </EarningsList>
      </Earnings>
    )}
  </Root>
);
