import useScrollToTop from 'hooks/useScrollToTop';
import React from 'react';
import styled from 'styled-components';

import Icon from 'components/lib/ui/Icon';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import OnboardingFooterNav from 'components/onboarding/OnboardingFooterNav';
import OnboardingSubtitle from 'components/onboarding/OnboardingSubtitle';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';

import boxShadow from 'common/lib/styles/boxShadow';
import { breakPoints, spacing, variables } from 'common/lib/theme/dynamic';

const QUOTES = [
  {
    author: 'craigbarrettesquire',
    text: 'Monarch reminded me of my joy of tracking my finances.',
  },
  {
    author: 'Laugh529',
    text: "I'm making better choices and have been able to see where I can save money too! It's been a good tool for my husband and I to keep us accountable.",
  },
  {
    author: 'Tyler R',
    text: 'The app is so awesome and has changed our lives. We budget everything and can see our savings grow before our eyes. Monarch has helped us lower our time preference and save more than ever.',
  },
  {
    author: 'mflamespin',
    text: 'It has helped me understand my savings rate and cash flow instantaneously. Before it took me hours of manually compiling snapshots to find an approximation. Now it is automatic!',
  },
  {
    author: 'Brooklyn HR guy',
    text: 'I enjoy looking at our finances now and it helps me not get stressed about it. Everything is happening automatically like it should and I just watch the accounts grow.',
  },
];

const QuotesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.default};
  margin-top: ${({ theme }) => theme.spacing.xxxlarge};
  width: 100%;
  text-align: left;
  max-width: 1000px;
`;

const Quote = styled.div`
  padding: ${({ theme }) => `${theme.spacing.xlarge} ${theme.spacing.default}`};
  background-color: ${variables.color.background.primary};
  border: 1px solid ${variables.color.border.primary};
  border-radius: ${({ theme }) => theme.radius.medium};
  ${boxShadow.large};
  width: calc((100% - (2 * ${spacing.default})) / 3); /* 3 per row, take gap into account */

  @media (max-width: ${breakPoints.sm}px) {
    width: 100%;
  }
`;

const StarContainer = styled.div`
  display: flex;
  gap: 3px;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const StarIcon = styled(Icon).attrs({
  name: 'star-filled',
  size: 20,
})`
  color: ${({ theme }) => theme.color.yellow9};
`;

const QuoteAuthor = styled.div`
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: 150%;
  color: ${({ theme }) => theme.color.textLight};
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const QuoteText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.base};
  line-height: 150%;
  color: ${({ theme }) => theme.color.text};

  &::before,
  &::after {
    content: '"';
  }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
`;

type Props = {
  next: () => void;
};

const PreRegistrationQuotes = ({ next }: Props) => {
  useScrollToTop();

  return (
    <>
      <OnboardingTitle>How Monarch has helped others</OnboardingTitle>
      <OnboardingSubtitle>
        Join thousands of people who have transformed their relationship with money using Monarch
      </OnboardingSubtitle>
      <QuotesContainer>
        {QUOTES.map(({ author, text }) => (
          <Quote key={author}>
            <StarContainer>
              <StarIcon />
              <StarIcon />
              <StarIcon />
              <StarIcon />
              <StarIcon />
            </StarContainer>
            <QuoteAuthor>{author}</QuoteAuthor>
            <QuoteText>{text}</QuoteText>
          </Quote>
        ))}
      </QuotesContainer>
      <OnboardingFooterNav>
        <StyledPrimaryButton size="large" onClick={next}>
          Continue
        </StyledPrimaryButton>
      </OnboardingFooterNav>
    </>
  );
};

export default PreRegistrationQuotes;
