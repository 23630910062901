import { adjust, append, evolve, remove } from 'ramda';
import { isNotNil } from 'ramda-adjunct';
import { useCallback, useContext, useMemo } from 'react';

import FormContext from 'common/components/form/FormContext';

import useUpdateEffect from 'common/lib/hooks/useUpdateEffect';
import type { FormValues, Split } from 'common/lib/transactions/Splits';
import {
  getAmountLeftToSplit,
  getAmountToSplit,
  getDefaultSplitDataValues,
  transformSplitAbsoluteAmountsToPercentages,
  transformSplitPercentagesToAbsoluteAmounts,
} from 'common/lib/transactions/Splits';

import type { Common_TransactionSplitQuery, Maybe } from 'common/generated/graphql';
import { SplitAmountType } from 'common/generated/graphql';

type Options = {
  originalTransaction?: Common_TransactionSplitQuery['getTransaction'];
  amountType?: SplitAmountType;
};

const useSplitTransactionForm = ({ originalTransaction, amountType }: Options) => {
  const { values, setValues } = useContext(FormContext);
  const { splitData: splits = [] } = (values as FormValues) ?? {};

  const addSplit = useCallback(
    (data?: Split) => {
      const newValues = evolve(
        {
          splitData: append({
            ...getDefaultSplitDataValues(originalTransaction),
            ...data,
          }),
        },
        values,
      );

      return setValues(newValues);
    },
    [setValues, values, originalTransaction],
  );

  const adjustSplit = useCallback(
    (index: number) => (data: Split) =>
      setValues(
        evolve(
          {
            splitData: adjust(index, (split: Split) => ({ ...split, ...data })),
          },
          values,
        ),
      ),
    [setValues, values],
  );

  const removeSplit = useCallback(
    (index: number) => () =>
      setValues(
        evolve(
          {
            splitData: remove(index, 1),
          },
          values,
        ),
      ),
    [setValues, values],
  );

  const removeAllSplits = useCallback(
    () => setValues({ ...values, splitData: [] }),
    [setValues, values],
  );

  const amountLeftToSplit = useMemo(
    () =>
      getAmountLeftToSplit(
        splits,
        getAmountToSplit(
          amountType ?? SplitAmountType.ABSOLUTE,
          Math.abs(originalTransaction?.amount ?? 0),
        ),
      ),
    [splits, amountType, originalTransaction],
  );

  useUpdateEffect(() => {
    let newSplits: Maybe<Split[]>;

    const totalAmount = Math.abs(originalTransaction?.amount ?? 0);
    if (amountType === SplitAmountType.PERCENTAGE) {
      newSplits = transformSplitAbsoluteAmountsToPercentages(splits, totalAmount);
    } else if (amountType === SplitAmountType.ABSOLUTE) {
      newSplits = transformSplitPercentagesToAbsoluteAmounts(splits, totalAmount);
    }

    if (isNotNil(newSplits)) {
      setValues(
        evolve(
          {
            splitData: (splits: Split[]) =>
              splits.map((split, index) =>
                evolve({ amount: () => newSplits![index].amount }, split),
              ),
          },
          values,
        ),
      );
    }
  }, [amountType]);

  return {
    splits,
    addSplit,
    adjustSplit,
    removeSplit,
    removeAllSplits,
    amountLeftToSplit,
  };
};

export default useSplitTransactionForm;
