import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useInitialValue from 'common/lib/hooks/useInitialValue';
import { track } from 'lib/analytics/segment';
import { useQueryParam } from 'lib/hooks/useQueryParams';
import { getSegmentAnonymousUserId } from 'selectors';

import { AnalyticsEventNames } from 'common/constants/analytics';

/**
 * Temporary hook for debugging discrepencies between
 * anonymous IDs in the marketing site and web app.
 *
 * See https://linear.app/monarch/issue/GRO-354/add-anonymous-id-as-aid-url-parameter-on-clicks-from-marketing-website
 */
const useDebugAnonymousId = () => {
  const aid = useQueryParam('ajs_aid');
  const marketingSiteAnonymousId = useInitialValue(aid);
  const webAppAnonymousId = useSelector(getSegmentAnonymousUserId);

  useEffect(() => {
    if (marketingSiteAnonymousId && webAppAnonymousId) {
      track(AnalyticsEventNames.DebugAnonymousId, {
        marketingSiteAnonymousId,
        webAppAnonymousId,
        anonymousIdsMatch: marketingSiteAnonymousId === webAppAnonymousId,
      });
    }
  }, [marketingSiteAnonymousId, webAppAnonymousId]);
};

export default useDebugAnonymousId;
