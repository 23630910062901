import React from 'react';
import styled from 'styled-components';

import {
  onboardingSurveyAnswerContainerStyles,
  onboardingSurveyAnswerPaddingStyles,
  onboardingSurveyTextColor,
} from 'common/components/onboarding/OnboardingSurveyAnswer';
import Flex from 'components/lib/ui/Flex';

import { color, fontWeight, transition } from 'common/lib/theme/dynamic';

const Root = styled(Flex).attrs({ alignCenter: true })<{
  isSelected: boolean;
}>`
  ${onboardingSurveyAnswerContainerStyles}
  ${onboardingSurveyAnswerPaddingStyles}
  cursor: pointer;
  white-space: normal;
  font-weight: ${fontWeight.medium};
  transition: ${({ theme }) => theme.transition.createDefaultTransition(['color'])};
  position: relative;
  color: ${({ isSelected, theme }) =>
    isSelected
      ? onboardingSurveyTextColor.active({ theme })
      : onboardingSurveyTextColor.inactive({ theme })};

  :focus-visible {
    outline: 2px solid ${color.blue};
    outline-offset: 4px;
  }

  :hover {
    background: ${color.gray4};
  }
`;

const ToggleBackground = styled.div<{
  isSelected: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${color.orange};
  transition: ${transition.quart};
  will-change: transform;
  transform: ${({ isSelected }) => (isSelected ? 'translateX(0)' : 'translateX(-100%)')};
`;

const ChildrenContainer = styled(Flex).attrs({ alignCenter: true })`
  position: relative;
`;

export type Props = {
  value: boolean;
  onClick: () => void;
  onKeyDown: (event: React.KeyboardEvent) => void;
  children?: React.ReactNode;
  className?: string;
  role: 'checkbox' | 'radio';
};

const OnboardingSurveyAnswer = ({ value, role, onClick, children, onKeyDown }: Props) => (
  <Root
    isSelected={value}
    onClick={onClick}
    role={role}
    tabIndex={0}
    aria-checked={value}
    onKeyDown={onKeyDown}
  >
    <ToggleBackground isSelected={value} />
    <ChildrenContainer>{children}</ChildrenContainer>
  </Root>
);

export default OnboardingSurveyAnswer;
