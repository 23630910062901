import { useMutation } from '@apollo/client';
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import CheckAnimation from 'components/lib/ui/CheckAnimation';
import ConfirmationSuccess from 'components/lib/ui/ConfirmationSuccess';
import FlexContainer from 'components/lib/ui/FlexContainer';
import type { DeleteOrDisableFormValues } from 'components/settings/institutions/DeleteOrDisableCredentialConfirmationInnerForm';
import DeleteOrDisableCredentialConfirmationInnerForm from 'components/settings/institutions/DeleteOrDisableCredentialConfirmationInnerForm';

import { DELETE_CREDENTIAL_MUTATION } from 'common/lib/graphQl/accounts';
import { DISABLE_CREDENTIAL_AND_DELETE_FROM_DATA_PROVIDER_MUTATION } from 'common/lib/graphQl/credentials';
import { track } from 'lib/analytics/segment';
import useToast from 'lib/hooks/useToast';

import { CredentialDeleteOrDisableEventNames } from 'common/constants/analytics';

import type {
  Common_DeleteCredentialMutation,
  Web_GetInstitutionSettingsQuery,
} from 'common/generated/graphql';

const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.xxlarge} 0px;
  border-radius: ${({ theme }) => theme.radius.medium};
`;

const Container = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  padding: ${({ theme }) => theme.spacing.xxlarge} 0px;
  flex: 1;
`;

const HeaderText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin: ${({ theme }) => theme.spacing.small} 0px;
  text-align: center;
`;

const ERROR_MESSAGE_DELETE_CREDENTIAL =
  'An error occurred while deleting this credential. Please try again.';
const ERROR_MESSAGE_DISABLE_CREDENTIAL =
  'An error occurred while disabling this credential. Please try again.';

type Props = {
  credential: Web_GetInstitutionSettingsQuery['credentials'][0];
  onCompleted: () => void;
  closeModal: () => void;
};

const DeleteOrDisableCredentialConfirmation = ({ credential, onCompleted, closeModal }: Props) => {
  const [successCompleted, setSuccessCompleted] = useState(false);

  const [deleteCredential, { loading: deleteCredentialLoading }] =
    useMutation<Common_DeleteCredentialMutation>(DELETE_CREDENTIAL_MUTATION);

  const [
    disableCredentialAndDeleteFromDataProvider,
    { loading: disableCredentialAndDeleteFromDataProviderLoading },
  ] = useMutation(DISABLE_CREDENTIAL_AND_DELETE_FROM_DATA_PROVIDER_MUTATION);

  const { openErrorToast } = useToast();

  const handleSubmit = useCallback(
    async (values: DeleteOrDisableFormValues) => {
      const { deleteOrDisableChoice } = values;

      if (deleteOrDisableChoice === 'delete') {
        deleteCredential({
          variables: { input: { credentialId: credential.id } },
          onError: () => {
            openErrorToast({
              description: ERROR_MESSAGE_DELETE_CREDENTIAL,
            });
          },
          onCompleted: (data) => {
            if (!data.deleteCredential?.deleted) {
              openErrorToast({
                description: ERROR_MESSAGE_DELETE_CREDENTIAL,
              });
              return;
            }

            setSuccessCompleted(true);
            onCompleted();
            track(
              CredentialDeleteOrDisableEventNames.DeleteOrDisableCredentialConfirmationSubmitted,
              {
                credentialId: credential.id,
                action: deleteOrDisableChoice,
              },
            );
          },
        });
      } else if (deleteOrDisableChoice === 'disable') {
        disableCredentialAndDeleteFromDataProvider({
          variables: { input: { credentialId: credential.id } },
          onError: () => {
            openErrorToast({
              description: ERROR_MESSAGE_DISABLE_CREDENTIAL,
            });
          },
          onCompleted: (data) => {
            if (data.disableCredentialAndDeleteFromDataProvider?.errors) {
              openErrorToast({
                description: ERROR_MESSAGE_DISABLE_CREDENTIAL,
              });
              return;
            }

            setSuccessCompleted(true);
            onCompleted();
            track(
              CredentialDeleteOrDisableEventNames.DeleteOrDisableCredentialConfirmationSubmitted,
              {
                credentialId: credential.id,
                action: deleteOrDisableChoice,
              },
            );
          },
        });
      } else {
        openErrorToast({
          description: ERROR_MESSAGE_DELETE_CREDENTIAL,
        });
      }
    },
    [credential.id],
  );

  if (successCompleted) {
    return (
      <ConfirmationSuccess title="" dismissText="Done" onDismiss={closeModal}>
        <Content>
          <Container>
            <CheckAnimation size={80} />
            <HeaderText>Your connection was successfully deleted</HeaderText>
          </Container>
        </Content>
      </ConfirmationSuccess>
    );
  }

  return (
    <Form
      initialValues={
        {
          deleteOrDisableChoice: null,
          institutionNameConfirmDeletion: '',
        } as Partial<DeleteOrDisableFormValues>
      }
      onSubmit={handleSubmit}
      isInitialValid={false}
    >
      <DeleteOrDisableCredentialConfirmationInnerForm
        credential={credential}
        onCancel={closeModal}
        disabled={deleteCredentialLoading || disableCredentialAndDeleteFromDataProviderLoading}
      />
    </Form>
  );
};

export default DeleteOrDisableCredentialConfirmation;
