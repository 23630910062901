import React from 'react';

import Tooltip from 'components/lib/ui/Tooltip';
import type { Props as TooltipProps } from 'components/lib/ui/Tooltip';

const HIDE_TOOLTIP_DELAY_MS = 1000;

export const ClickTriggeredTooltip = ({ children, ...tooltipProps }: TooltipProps) => (
  <Tooltip delayHide={HIDE_TOOLTIP_DELAY_MS} event="click" eventOff="mouseleave" {...tooltipProps}>
    {children}
  </Tooltip>
);
