import { DateTime } from 'luxon';
import * as R from 'ramda';
import { createSelector } from 'reselect';

import type { UserState } from 'common/state/user/reducer';

import { UserHouseholdRole } from 'common/generated/graphql';

export type RootStateUser = {
  user: UserState;
};

// Even though this data is fetched from GQL, we still keep it in redux since it's needed in
// middleware in a lot of places.
export const getUser = (state: RootStateUser) => state.user || {};
export const getUserName = createSelector(getUser, ({ name }) => name || '');
export const getUserFirstName = createSelector(getUserName, (fullUserName) =>
  R.head(fullUserName.split(' ') ?? []),
);
export const getUserEmail = createSelector(getUser, ({ email }) => email || '');
export const getUserTimezone = createSelector(getUser, ({ timezone }) => timezone);
export const getUserToken = (state: RootStateUser) => state.user.token || '';
export const getUserIsLoggedIn = (state: RootStateUser) => !R.isNil(getUser(state).id);
export const getUserBirthday = createSelector(getUser, ({ birthday }) => birthday);
export const getIsDemo = createSelector(getUser, ({ is_demo }) => is_demo);
export const getIsTemporaryDemo = createSelector(
  getUser,
  ({ is_temporary_demo }) => is_temporary_demo,
);
export const getIsSponsor = createSelector(getUser, ({ is_sponsor }) => is_sponsor);
export const getIsSuperuser = createSelector(
  getUser,
  ({ other_info: { is_superuser } }) => is_superuser,
);
export const getIsEmployee = createSelector(
  getUser,
  ({ other_info: { is_employee } }) => is_employee,
);
export const getIsHouseholdOwner = createSelector(
  getUser,
  ({ household_role }) => household_role === UserHouseholdRole.OWNER,
);
export const getSplitUserAttributes = createSelector(
  getUser,
  ({ split_attributes }) => split_attributes,
);
export const getUserId = createSelector(getUser, ({ id }) => id);

export const getTokenExpirationDate = createSelector(getUser, ({ tokenExpiration }) =>
  tokenExpiration ? DateTime.fromISO(tokenExpiration).toJSDate() : null,
);

export const getShouldForceRedirectSponsorUser = createSelector(
  getUser,
  ({ should_force_redirect_sponsor }) => should_force_redirect_sponsor,
);

export const getUserExternalId = createSelector(getUser, ({ external_id }) => external_id);
