import * as Yup from 'yup';

const REQUIRED_MESSAGE = 'This field is required';

// Onboarding Invite Partner
const email = Yup.string().required('');

export const invitePartnerValidationSchema = Yup.object().shape({
  email,
});

// Onboarding Demographics
const name = Yup.string().required(REQUIRED_MESSAGE);

export const demographicsValidationSchema = Yup.object().shape({
  name,
  country: Yup.string().required(REQUIRED_MESSAGE),
  manageWithPartner: Yup.object()
    .shape({
      yes: Yup.boolean(),
      no: Yup.boolean(),
    })
    .test(
      'one-option-selected',
      REQUIRED_MESSAGE,
      (value) => value?.yes === true || value?.no === true,
    ),
  manageWithProfessional: Yup.object()
    .shape({
      yes: Yup.boolean(),
      no: Yup.boolean(),
    })
    .test(
      'one-option-selected',
      REQUIRED_MESSAGE,
      (value) => value?.yes === true || value?.no === true,
    ),
});

/** Mobile has slightly different fields */
export const getDemographicsValidationSchemaMobile = (skipName?: boolean) =>
  Yup.object().shape({
    name: skipName ? Yup.string() : name,
    manageWithPartner: Yup.boolean().required(REQUIRED_MESSAGE),
    manageWithProfessional: Yup.boolean().required(REQUIRED_MESSAGE),
  });
