import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import * as R from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';

import Badge from 'components/lib/ui/Badge';
import Banner from 'components/lib/ui/Banner';
import FlexContainer from 'components/lib/ui/FlexContainer';
import TextButton from 'components/lib/ui/TextButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import BillingRow from 'components/settings/billing/BillingRow';

import * as COPY from 'common/constants/copy';
import { PLAN_LABEL_FOR_PERIOD } from 'common/constants/premium';

import type { Web_GetSubscriptionQuery } from 'common/generated/graphql';
import { PaymentPeriod, SubscriptionDetailsPaymentSource } from 'common/generated/graphql';

const Container = styled(FlexContainer).attrs({ column: true })`
  flex: 1;
`;

const Row = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })`
  flex: 1;
`;

const SubscriptionStatusBadgeStyle = css`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  letter-spacing: 0;
  text-transform: none;

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    margin: 0;
  }
`;

const CanceledBadge = styled(Badge).attrs({ color: 'red' })`
  ${SubscriptionStatusBadgeStyle}
`;

const ActiveBadge = styled(Badge).attrs({ color: 'green' })`
  ${SubscriptionStatusBadgeStyle}
`;

const PlanName = styled.span`
  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    display: block;
  }
`;

const StyledTextButton = styled(TextButton)`
  margin-left: 10px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const BodyText = styled.div`
  margin-top: ${({ theme }) => theme.spacing.default};
`;

const InfoBanner = styled(Banner).attrs({ type: 'info' })`
  margin-top: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  hasPremium: boolean;
  billingPeriod: PaymentPeriod | null | undefined;
  willCancelAtPeriodEnd: boolean;
  activeSponsorship: Web_GetSubscriptionQuery['subscription']['activeSponsorship'] | null;
  paymentSource: SubscriptionDetailsPaymentSource | null | undefined;
  accessExpirationDate?: string | null;
  isOnFreeTrial: boolean;
  eligibleForTrial: boolean;
  onClickManageSubscription: () => void;
};

const BillingStatusRowV2 = ({
  activeSponsorship,
  hasPremium,
  billingPeriod,
  willCancelAtPeriodEnd,
  paymentSource,
  accessExpirationDate,
  isOnFreeTrial,
  eligibleForTrial,
  onClickManageSubscription,
}: Props) => {
  const planName =
    hasPremium && billingPeriod !== null ? PLAN_LABEL_FOR_PERIOD[billingPeriod!] : '';

  const expirationDate = accessExpirationDate && DateTime.fromISO(accessExpirationDate);
  const hasCanceledMobileStoreSubscription =
    paymentSource !== SubscriptionDetailsPaymentSource.STRIPE && willCancelAtPeriodEnd;

  const showManageSubscriptionButton =
    hasPremium &&
    paymentSource === SubscriptionDetailsPaymentSource.STRIPE &&
    R.isNil(activeSponsorship) &&
    billingPeriod !== PaymentPeriod.LIFETIME;

  const shouldShowBannerRedirectingToMobileStore =
    hasPremium &&
    paymentSource &&
    !isOnFreeTrial &&
    [
      SubscriptionDetailsPaymentSource.APP_STORE,
      SubscriptionDetailsPaymentSource.PLAY_STORE,
    ].includes(paymentSource);

  return (
    <BillingRow
      title="Current plan"
      controls={
        showManageSubscriptionButton &&
        (willCancelAtPeriodEnd ? (
          <PrimaryButton onClick={onClickManageSubscription}>
            {COPY.PREMIUM.MANAGE_SUBSCRIPTION.REACTIVATE_SUBSCRIPTION_CTA}
          </PrimaryButton>
        ) : (
          <StyledTextButton onClick={onClickManageSubscription}>
            {COPY.PREMIUM.MANAGE_SUBSCRIPTION.MANAGE_SUBSCRIPTION_CTA}
          </StyledTextButton>
        ))
      }
    >
      <Container>
        <Row>
          <span>
            <PlanName>
              {hasPremium && <span>{planName}</span>}
              {isOnFreeTrial && <span> - Trial</span>}
            </PlanName>
            {!hasPremium || willCancelAtPeriodEnd ? (
              <CanceledBadge>Canceled</CanceledBadge>
            ) : (
              <ActiveBadge>Active</ActiveBadge>
            )}
          </span>
          {(!hasPremium || (hasCanceledMobileStoreSubscription && isOnFreeTrial)) && (
            <PrimaryButton onClick={onClickManageSubscription}>
              {eligibleForTrial
                ? COPY.PREMIUM.UPGRADE_CTA.TRIAL_AVAILABLE
                : COPY.PREMIUM.MANAGE_SUBSCRIPTION.REACTIVATE_SUBSCRIPTION_CTA}
            </PrimaryButton>
          )}
        </Row>
        {hasPremium && isOnFreeTrial && !willCancelAtPeriodEnd && (
          <BodyText>
            You&apos;ll be charged for an annual subscription after your trial ends.
          </BodyText>
        )}
        {hasPremium && willCancelAtPeriodEnd && (
          <BodyText>
            Your subscription has been canceled.{' '}
            {!!expirationDate &&
              `You will have access to Premium until ${expirationDate.toLocaleString(
                DateTime.DATE_FULL,
              )}.`}
          </BodyText>
        )}
        {shouldShowBannerRedirectingToMobileStore && (
          <InfoBanner>
            To manage your subscription, please visit the {capitalCase(paymentSource)}.
          </InfoBanner>
        )}
      </Container>
    </BillingRow>
  );
};

export default BillingStatusRowV2;
