import queryString from 'query-string';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import getUserApi from 'common/lib/api/user';
import { setSegmentAnonymousUserId } from 'common/state/analytics/actions';
import clientPlatform from 'lib/analytics/clientPlatform';
import clientPlatformMiddleware from 'lib/analytics/clientPlatformMiddleware';
import dedupIdMiddleware from 'lib/analytics/dedupIdMiddleware';
import getImpersonatorId from 'lib/analytics/getImpersonatorId';
import typewriter from 'lib/analytics/typewriter';
import api from 'lib/api';
import { store } from 'state/store';
import { getIsDemo } from 'state/user/selectors';

import type { AnalyticsEvent } from 'common/constants/analytics';

import type { UserHouseholdRole } from 'common/generated/graphQlTypes/globalTypes';

const ANONYMOUS_ID_COOKIE_NAME = 'ajs_anonymous_id';

export const shouldLogToSegment = () => !!process.env.REACT_APP_SEGMENT_WRITE_KEY;

export const identifyUserToSegment = (
  id: string,
  email: string,
  householdRole?: UserHouseholdRole,
) => {
  if (!shouldLogToSegment()) {
    return;
  }

  const payload = R.reject(RA.isNilOrEmpty)({
    household_role: householdRole,
    email,
  });

  // Prevent empty attributes from being sent to Segment and issues like this one from
  // happening again: https://linear.app/monarch/issue/ENG-2814/empty-users-in-customerio
  if (RA.isNilOrEmpty(payload)) {
    return;
  }

  window.analytics.identify(id, payload);
};

// Log that the web app has loaded through the backend.
export const logLoadedWebApp = () => {
  typewriter.webAppLoaded();
  getUserApi(api).logAppLoadedOnBackend();
};

export const track = (event: AnalyticsEvent, data = {}, options = {}) => {
  const state = store.getState();
  const isDemo = getIsDemo(state);

  if (isDemo) {
    return;
  }

  const augmentedData = {
    ...data,
    client_platform: clientPlatform,
    impersonator_id: getImpersonatorId(),
  };
  window.analytics.track(event, augmentedData, options);
};

export const trackDedupedFacebookPixelEvent = (
  event: AnalyticsEvent,
  data = {},
  dedupId?: string,
) => {
  // Send certain standard events to Facebook only, with an id to deduplicate the same event on the BE.
  track(
    event,
    { ...data, eventId: dedupId },
    { integrations: { All: false, 'Facebook Pixel': true } },
  );
};

const SEARCH_QUERY_KEYS_TO_REDACT = ['email', 'token'];

const redactPIIFromSearchParams = (search: string) => {
  // Filter out PII keys from query search params
  const searchParams = queryString.parse(search);
  const redactedSearchParams = R.mapObjIndexed(
    (value, key) => (SEARCH_QUERY_KEYS_TO_REDACT.includes(key) ? '_REDACTED_' : value),
    searchParams,
  );

  const containsPII =
    R.intersection(Object.keys(searchParams), SEARCH_QUERY_KEYS_TO_REDACT).length > 0;

  return [containsPII, queryString.stringify(redactedSearchParams)] as const;
};

export const trackCurrentPage = (
  path: string,
  search: string,
  pageTitle: string,
  pageName: string,
) => {
  // We need to strip PII (such as email) from search params so that when we send them to
  // destinations like FB, we're not leaking PII to them (FB will warn us if we do).
  const [containsPII, redactedSearch] = redactPIIFromSearchParams(search);
  if (shouldLogToSegment()) {
    // This automatically sends the path/url/params, but we can add more stuff later.
    window.analytics.page(
      pageName,
      {
        path,
        search: redactedSearch,
        title: pageTitle,
        client_platform: clientPlatform,
        impersonator_id: getImpersonatorId(),
      },
      // If the URL contains PII, don't send it at all to Facebook Pixel.
      // (It will get redacted for other platforms).
      containsPII ? { integrations: { 'Facebook Pixel': false } } : {},
    );
  }
};

export const loadSegment = () => {
  window.analytics?.ready(() => {
    const anonymousUserId = window.analytics?.user?.()?.anonymousId?.();
    store.dispatch(setSegmentAnonymousUserId(anonymousUserId));
  });

  if (shouldLogToSegment() && process.env.REACT_APP_SEGMENT_WRITE_KEY) {
    // @ts-ignore: Analytics.JS type should be updated with addSourceMiddleware
    window.analytics?.addSourceMiddleware?.(clientPlatformMiddleware);
    // @ts-ignore: Analytics.JS type should be updated with addSourceMiddleware
    window.analytics?.addSourceMiddleware?.(dedupIdMiddleware);
    window.analytics?.load?.(process.env.REACT_APP_SEGMENT_WRITE_KEY);
  } else {
    // Log to console in local development
    window.analytics = {
      ...window.analytics,
      track: (...args: any[]) => {
        // eslint-disable-next-line no-console
        console.log('TRACK: ', ...args);
      },
    };
  }
};

export const getSegmentAnonymousIdFromQueryParamOrCookie = () => {
  // Try to parse ajs_aid from query params first
  // Since it could be set as part of:
  // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/querystring/
  const queryParams = queryString.parse(window.location.search);
  if (queryParams.ajs_aid) {
    return queryParams.ajs_aid as string;
  }
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${ANONYMOUS_ID_COOKIE_NAME}=`)) {
      const cookieValue = cookie.substring(ANONYMOUS_ID_COOKIE_NAME.length + 1);
      return decodeURIComponent(cookieValue);
    }
  }

  return null;
};

export const setSegmentAnonymousId = (anonymousId: string) => {
  // set ANONYMOUS_ID_COOKIE_NAME cookie with anonymousId
  document.cookie = `${ANONYMOUS_ID_COOKIE_NAME}=${encodeURIComponent(anonymousId)}`;

  // sets Segment Anonymous ID
  window.analytics?.setAnonymousId?.(anonymousId);
};
