import * as RA from 'ramda-adjunct';
import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import AccountLogo from 'components/accounts/AccountLogo';
import GoalImage from 'components/goalsV2/GoalImage';
import Dot from 'components/lib/ui/Dot';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import MerchantLogo from 'components/merchants/MerchantLogo';

import { formatDateRangeWithRelativeTimeframeOrShortcut } from 'common/lib/dateRange/dateRangeWithTimeframes';
import { color, fontWeight, radius, spacing } from 'common/lib/theme/dynamic';
import { makeAmountOptions } from 'lib/filters/utils';
import useTransactionFiltersMetadata from 'lib/hooks/transactions/useTransactionFiltersMetadata';
import { convertFiltersToInput } from 'lib/transactions/Filters';

import type { TransactionFilters } from 'types/filters';

const FilterSectionContainer = styled(FlexContainer).attrs({
  gap: 'xsmall',
  column: true,
})``;

const FilterSectionTitle = styled(Text).attrs({
  size: 'small',
})`
  font-weight: ${fontWeight.bold};
`;

const FilterSectionItemsContainer = styled(FlexContainer).attrs({
  justifyStart: true,
  gap: 'xsmall',
  wrap: true,
})``;

const FilterSectionItemContainer = styled(FlexContainer).attrs({
  justifyBetween: true,
  alignCenter: true,
  gap: 'xsmall',
})`
  background-color: ${color.grayBackground};
  padding: ${spacing.xxsmall} ${spacing.xsmall};
  border-radius: ${spacing.xxsmall};
`;

const FilterSectionItemText = styled(Text).attrs({
  size: 'small',
})``;

const SmallGoalImage = styled(GoalImage).attrs({
  size: 'small',
})`
  width: 16px;
  height: 16px;
  border-radius: ${radius.xxsmall};
`;

const FilterSection = ({ title, children }: { title: string; children: React.ReactNode }) => (
  <FilterSectionContainer>
    <FilterSectionTitle>{title}</FilterSectionTitle>
    <FilterSectionItemsContainer>{children}</FilterSectionItemsContainer>
  </FilterSectionContainer>
);

const TransactionFiltersOverview = ({
  className,
  filters,
}: {
  className?: string;
  filters: Partial<TransactionFilters>;
}) => {
  const filterInput = convertFiltersToInput(filters);

  const { transactionFiltersMetadata } = useTransactionFiltersMetadata(filterInput);
  const { categories, categoryGroups, merchants, accounts, tags, goal, needsReviewByUser } =
    transactionFiltersMetadata ?? {};

  const { amountFilter } = filters;
  const {
    search,
    startDate,
    endDate,
    timeframePeriod,
    creditsOnly,
    debitsOnly,
    needsReview,
    isRecurring,
    isSplit,
    isPending,
    isUncategorized,
    isFlexSpending,
    isInvestmentAccount,
    hasAttachments,
    hasNotes,
    importedFromMint,
    hideFromReports,
    syncedFromInstitution,
    isUntagged,
  } = filterInput;

  const sections = useMemo(() => {
    const datesSection = (
      <FilterSection title="Dates" key="dates">
        <FilterSectionItemContainer>
          <FilterSectionItemText>
            {formatDateRangeWithRelativeTimeframeOrShortcut({
              startDate,
              endDate,
              timeframeUnit: timeframePeriod?.unit,
              timeframeValue: timeframePeriod?.value,
              includeCurrentPeriod: timeframePeriod?.includeCurrent,
            })}
          </FilterSectionItemText>
        </FilterSectionItemContainer>
      </FilterSection>
    );

    const categoriesSection = RA.isNotNilOrEmpty(categories) ? (
      <FilterSection title="Categories" key="categories">
        {categories?.map(({ name, icon }) => (
          <FilterSectionItemContainer key={name}>
            <FilterSectionItemText>{icon}</FilterSectionItemText>
            <FilterSectionItemText>{name}</FilterSectionItemText>
          </FilterSectionItemContainer>
        ))}
      </FilterSection>
    ) : null;

    const categoryGroupsSection = RA.isNotNilOrEmpty(categoryGroups) ? (
      <FilterSection title="Category groups" key="categoryGroups">
        {categoryGroups?.map(({ name }) => (
          <FilterSectionItemContainer key={name}>
            <FilterSectionItemText>{name}</FilterSectionItemText>
          </FilterSectionItemContainer>
        ))}
      </FilterSection>
    ) : null;

    const merchantsSection = RA.isNotNilOrEmpty(merchants) ? (
      <FilterSection title="Merchants" key="merchants">
        {merchants?.map(({ name, logoUrl }) => (
          <FilterSectionItemContainer key={name}>
            <MerchantLogo url={logoUrl} size={16} />
            <FilterSectionItemText>{name}</FilterSectionItemText>
          </FilterSectionItemContainer>
        ))}
      </FilterSection>
    ) : null;

    const accountsSection = RA.isNotNilOrEmpty(accounts) ? (
      <FilterSection title="Accounts" key="accounts">
        {accounts?.map(({ displayName, logoUrl, icon }) => (
          <FilterSectionItemContainer key={displayName}>
            <AccountLogo icon={icon} logoUrl={logoUrl} size={16} />
            <FilterSectionItemText>{displayName}</FilterSectionItemText>
          </FilterSectionItemContainer>
        ))}
      </FilterSection>
    ) : null;

    const tagsSection =
      RA.isNotNilOrEmpty(tags) || isUntagged ? (
        <FilterSection title="Tags" key="tags">
          {isUntagged && (
            <FilterSectionItemContainer>
              <FilterSectionItemText>Is untagged</FilterSectionItemText>
            </FilterSectionItemContainer>
          )}
          {tags?.map(({ name, color }) => (
            <FilterSectionItemContainer key={name}>
              <Dot color={color} size={12} />
              <FilterSectionItemText>{name}</FilterSectionItemText>
            </FilterSectionItemContainer>
          ))}
        </FilterSection>
      ) : null;

    const goalSection = RA.isNotNil(goal) ? (
      <FilterSection title="Goal" key="goal">
        <FilterSectionItemContainer>
          <SmallGoalImage
            imageStorageProvider={goal.imageStorageProvider}
            imageStorageProviderId={goal.imageStorageProviderId}
          />
          <FilterSectionItemText>{goal.name}</FilterSectionItemText>
        </FilterSectionItemContainer>
      </FilterSection>
    ) : null;

    let amountFilterSection = null;
    const hasAmountFilter = RA.isNotNilOrEmpty(amountFilter);
    const hasAmountType = RA.isNotNil(creditsOnly) || RA.isNotNil(debitsOnly);
    if (hasAmountFilter || hasAmountType) {
      const amountOptions = makeAmountOptions(filters);
      const amountOption = amountOptions.find((option) => option.isSelected);
      amountFilterSection = (
        <FilterSection title="Amount" key="amount">
          {hasAmountFilter && (
            <FilterSectionItemContainer>
              <FilterSectionItemText>{amountOption?.formatLabel({})}</FilterSectionItemText>
            </FilterSectionItemContainer>
          )}
          {filters.creditsOnly && (
            <FilterSectionItemContainer>
              <FilterSectionItemText>Credits only</FilterSectionItemText>
            </FilterSectionItemContainer>
          )}
          {filters.debitsOnly && (
            <FilterSectionItemContainer>
              <FilterSectionItemText>Debits only</FilterSectionItemText>
            </FilterSectionItemContainer>
          )}
        </FilterSection>
      );
    }

    const otherItems = [
      needsReview && {
        key: 'needsReview',
        child: (
          <FilterSectionItemText>
            Needs review by {needsReviewByUser ? needsReviewByUser.name : 'anyone'}
          </FilterSectionItemText>
        ),
      },
      RA.isNotNilOrEmpty(search) && {
        key: 'search',
        child: <FilterSectionItemText>Searching for &apos;{search}&apos;</FilterSectionItemText>,
      },
      RA.isNotNil(isRecurring) && {
        key: 'isRecurring',
        child: (
          <FilterSectionItemText>
            {isRecurring ? 'Recurring' : 'Not recurring'}
          </FilterSectionItemText>
        ),
      },
      RA.isNotNil(isSplit) && {
        key: 'isSplit',
        child: <FilterSectionItemText>{isSplit ? 'Split' : 'Not split'}</FilterSectionItemText>,
      },
      RA.isNotNil(isPending) && {
        key: 'isPending',
        child: (
          <FilterSectionItemText>{isPending ? 'Pending' : 'Not pending'}</FilterSectionItemText>
        ),
      },
      RA.isNotNil(isUncategorized) && {
        key: 'isUncategorized',
        child: (
          <FilterSectionItemText>
            {isUncategorized ? 'Uncategorized' : 'Categorized'}
          </FilterSectionItemText>
        ),
      },
      RA.isNotNil(isFlexSpending) && {
        key: 'isFlexSpending',
        child: (
          <FilterSectionItemText>
            {isFlexSpending ? 'Flex spending' : 'Not flex spending'}
          </FilterSectionItemText>
        ),
      },
      RA.isNotNil(isInvestmentAccount) && {
        key: 'isInvestmentAccount',
        child: (
          <FilterSectionItemText>
            {isInvestmentAccount ? 'In an investment account' : 'Not in an investment account'}
          </FilterSectionItemText>
        ),
      },
      RA.isNotNil(hasAttachments) && {
        key: 'hasAttachments',
        child: (
          <FilterSectionItemText>
            {hasAttachments ? 'Has attachments' : 'No attachments'}
          </FilterSectionItemText>
        ),
      },
      RA.isNotNil(hasNotes) && {
        key: 'hasNotes',
        child: <FilterSectionItemText>{hasNotes ? 'Has notes' : 'No notes'}</FilterSectionItemText>,
      },
      RA.isNotNil(importedFromMint) && {
        key: 'importedFromMint',
        child: (
          <FilterSectionItemText>
            {importedFromMint ? 'Imported from Mint' : 'Not imported from Mint'}
          </FilterSectionItemText>
        ),
      },
      RA.isNotNil(hideFromReports) && {
        key: 'hideFromReports',
        child: (
          <FilterSectionItemText>
            {hideFromReports ? 'Hidden from cash flow / reports' : 'Visible in cash flow / reports'}
          </FilterSectionItemText>
        ),
      },
      RA.isNotNil(syncedFromInstitution) && {
        key: 'syncedFromInstitution',
        child: (
          <FilterSectionItemText>
            {syncedFromInstitution ? 'Synced from institution' : 'Created manually'}
          </FilterSectionItemText>
        ),
      },
    ].filter(Boolean) as { key: string; child: ReactNode }[];

    const otherSection =
      otherItems.length > 0 ? (
        <FilterSection title="Other" key="other">
          {otherItems.map(({ key, child }) => (
            <FilterSectionItemContainer key={key}>{child}</FilterSectionItemContainer>
          ))}
        </FilterSection>
      ) : null;

    return [
      datesSection,
      categoriesSection,
      categoryGroupsSection,
      merchantsSection,
      accountsSection,
      tagsSection,
      goalSection,
      amountFilterSection,
      otherSection,
    ].filter(RA.isNotNil);
  }, [
    startDate,
    endDate,
    timeframePeriod,
    categories,
    categoryGroups,
    merchants,
    accounts,
    tags,
    goal,
    amountFilter,
    creditsOnly,
    debitsOnly,
    needsReview,
    needsReviewByUser,
    search,
    isRecurring,
    isSplit,
    isPending,
    isUncategorized,
    isFlexSpending,
    isInvestmentAccount,
    hasAttachments,
    hasNotes,
    importedFromMint,
    hideFromReports,
    syncedFromInstitution,
    filters,
  ]);

  return (
    <FlexContainer className={className} column gap="xlarge">
      {sections}
    </FlexContainer>
  );
};

export default TransactionFiltersOverview;
