import type { Interval } from 'luxon';
import { DateTime } from 'luxon';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import GoalImage from 'components/goalsV2/GoalImage';

import { isBudgetVariabilityOfType } from 'common/lib/budget/Budget';
import { isDateWithinRolloverPeriod } from 'common/lib/budget/Rollovers';
import {
  BUDGET_VARIABILITY_TO_TITLE_MAPPING,
  DEFAULT_BUDGET_VARIABILITY_IF_UNSET,
} from 'common/lib/budget/constants';
import { sortCategoryGroups, sortByOrder } from 'common/lib/categories/Adapters';
import { CATEGORY_GROUP_TYPE_TO_TITLE } from 'common/lib/categories/constants';
import { filterOutArchivedGoals, sortGoalsByPriority } from 'common/lib/goalsV2/adapters';
import type { PlanningAmountsByIdDate } from 'common/lib/planning';
import { PlanTimeframe } from 'lib/hooks/plan/usePlanState';
import type { PlanAmounts, PlanSectionType as PlanSectionTypeImport } from 'lib/plan';

import routes from 'constants/routes';

import type { Common_GetJointPlanningDataQuery } from 'common/generated/graphql';
import { BudgetVariability, CategoryGroupType } from 'common/generated/graphql';
import type { ElementOf, MarkOptional } from 'common/types/utility';

// Convenience types
type CategoryGroup = ElementOf<Common_GetJointPlanningDataQuery, 'categoryGroups'>;
type Category = ElementOf<CategoryGroup, 'categories'>;
type GoalV2 = ElementOf<Common_GetJointPlanningDataQuery, 'goalsV2'>;

const SmallGoalImage = styled(GoalImage)`
  width: 24px;
  height: 24px;
  border-radius: ${({ theme }) => theme.radius.xxsmall};
  position: relative;
`;

const GOALS_SECTION_ID = 'goals';
const GOALS_GROUP_ID = 'goal-contributions';
const GOALS_SECTION_TITLE = 'Contributions';
const GOALS_GROUP_TITLE = 'Goals';

/** Decides whether a row shows up in the "unplanned" section of a group. */
const isRowUnplanned =
  (
    rowAmountsByMonth: PlanningAmountsByIdDate,
    columnDates: DateTime[],
    isFixFlexEnabled: boolean,
  ) =>
  (
    id: string,
    categoryRolloverPeriod?: Category['rolloverPeriod'] | CategoryGroup['rolloverPeriod'],
    isFlex = false,
  ) =>
    !R.any((date) => {
      const amounts = rowAmountsByMonth[id]?.[date.toISODate()];
      const hasNoBudget = R.isNil(amounts?.budgeted) || amounts?.budgeted === 0;
      const hasRollover = RA.isNotNil(amounts?.rolloverType) || !!amounts?.rollover;
      const dateIsWithinRolloverPeriod = categoryRolloverPeriod
        ? isDateWithinRolloverPeriod(date, categoryRolloverPeriod)
        : false;

      // Negative actuals (e.g., refunds) should also be considered
      const hasActualAmount = (amounts?.actual ?? 0) !== 0;

      return (
        !(hasNoBudget && !hasRollover) ||
        dateIsWithinRolloverPeriod ||
        (hasActualAmount && isFlex && isFixFlexEnabled)
      );
    }, columnDates);

/** Sort categories by order and add derived data. */
const generateSortedRowDataListForCategories = ({
  categories,
  getIsUnplanned,
  columnDisplayInterval,
  groupLevelBudgetingEnabled,
  parentGroupId,
  groupUnplannedOverride,
  isFlexBudgeting,
  isExpenseGroup,
}: {
  categories: Category[];
  getIsUnplanned: ReturnType<typeof isRowUnplanned>;
  columnDisplayInterval: Interval;
  groupLevelBudgetingEnabled?: boolean | null;
  parentGroupId?: string | null;
  groupUnplannedOverride?: boolean | null;
  isFlexBudgeting?: boolean;
  isExpenseGroup?: boolean;
}): PlanRowData[] =>
  sortByOrder(categories).map((category) => {
    let canMoveBudget = true;

    const budgetVariability =
      (groupLevelBudgetingEnabled
        ? category.group.budgetVariability
        : category.budgetVariability) ?? DEFAULT_BUDGET_VARIABILITY_IF_UNSET;

    if (isFlexBudgeting) {
      // If in flex budgeting mode, flexible expense categories can't be moved
      canMoveBudget = budgetVariability !== BudgetVariability.FLEXIBLE || !isExpenseGroup;
    }

    return {
      id: category.id,
      name: category.name,
      icon: category.icon,
      excludeFromBudget: category.excludeFromBudget ?? false,
      isUnplanned: groupLevelBudgetingEnabled
        ? groupUnplannedOverride ?? false
        : getIsUnplanned(
            category.id,
            category.rolloverPeriod,
            isBudgetVariabilityOfType(budgetVariability, BudgetVariability.FLEXIBLE),
          ),
      href: routes.categories({
        id: category.id,
        queryParams: {
          date: columnDisplayInterval.start.toISODate(),
        },
      }),
      settingsButtonAction: SettingsButtonAction.OPEN_CATEGORY_EDIT_MODAL,
      canBeBudgeted: !groupLevelBudgetingEnabled,
      budgetVariability,
      parentGroupId,
      canMoveBudget,
      rowType: RowType.Category,
      groupLevelBudgetingEnabled,
      rolloverPeriod: category.rolloverPeriod,
      isSystemCategory: category.isSystemCategory,
    };
  });

/** Sort groups in correct order and add derived data */
const sortAndMapGoalsV2 = (goals: GoalV2[], getIsUnplanned: (id: string) => boolean) => {
  let displayGoals = filterOutArchivedGoals(goals);
  displayGoals = sortGoalsByPriority(displayGoals);

  return displayGoals.map((goal) => ({
    ...goal,
    icon: (
      <SmallGoalImage
        imageStorageProvider={goal.imageStorageProvider}
        imageStorageProviderId={goal.imageStorageProviderId}
        size="small"
        showGradient={false}
      />
    ),
    isUnplanned: getIsUnplanned(goal.id),
    href: routes.goalsV2.goalDetail({ id: goal.id }),
    canBeBudgeted: true,
  }));
};

/* Map group with derived data */
const mapGroupToDisplay = <
  T extends {
    id: string;
    name: string;
    groupLevelBudgetingEnabled: boolean | null | undefined;
    type?: CategoryGroupType;
  },
  R extends PlanRowData,
>(
  group: T,
  rows: R[],
  columnDisplayInterval?: Interval,
  isFixFlexEnabled?: boolean,
): PlanGroupData => {
  const [unplanned, planned] = R.partition(R.prop('isUnplanned'), rows);
  const isExpenseGroup = group.type === CategoryGroupType.EXPENSE;

  const canMoveBudget =
    (!isExpenseGroup || !isFixFlexEnabled) && !!group.groupLevelBudgetingEnabled;

  return {
    ...group,
    href:
      group.id === GOALS_GROUP_ID
        ? null
        : routes.categoryGroups({
            id: group.id,
            queryParams: {
              date: columnDisplayInterval?.start.toISODate(),
            },
          }),
    unplannedCount: unplanned.length,
    rows: [...planned, ...unplanned],
    showSettingsButton: group.id !== GOALS_GROUP_ID,
    canBeBudget: !!group.groupLevelBudgetingEnabled,
    canMoveBudget,
  };
};

/** Aggregate rows to show at bottom of section */
const aggregateRowsForSection = (section: string, timeframe: PlanTimeframe): PlanAggregateRow[] => {
  const rows: PlanAggregateRow[] = [
    {
      title: `Total ${
        section === GOALS_SECTION_ID ? GOALS_SECTION_TITLE : CATEGORY_GROUP_TYPE_TO_TITLE[section]
      }`,
      key: 'aggregate',
    },
  ];

  if (section === GOALS_SECTION_ID) {
    // eslint-disable-next-line fp/no-mutating-methods
    rows.push({
      title: timeframe === PlanTimeframe.SingleMonth ? 'Left to Budget' : 'Total Left',
      key: 'aggregateLeftover',
      sectionType: 'savings',
      isLeftoverRow: true,
    });
  }

  return rows;
};

export enum SettingsButtonAction {
  OPEN_CATEGORY_EDIT_MODAL = 'OPEN_CATEGORY_EDIT_MODAL',
  OPEN_CATEGORY_GROUP_EDIT_MODAL = 'OPEN_CATEGORY_GROUP_EDIT_MODAL',
}

export enum RowType {
  Category = 'CATEGORY',
  CategoryGroup = 'CATEGORY_GROUP',
}

export type PlanRowData = {
  id: string;
  name: string;
  icon?: React.ReactNode | string;
  isUnplanned: boolean;
  href?: string;
  height?: number;
  settingsButtonAction: SettingsButtonAction;
  rolloverPeriod?: Category['rolloverPeriod'] | CategoryGroup['rolloverPeriod'] | null;
  canBeBudgeted: boolean;
  budgetVariability?: BudgetVariability | null;
  parentGroupId?: string | null;
  canMoveBudget: boolean;
  rowType: RowType;
  groupLevelBudgetingEnabled: boolean | null | undefined;
  excludeFromBudget?: boolean;
  isSystemCategory?: boolean;
};

export type PlanGroupData = {
  id: string;
  name: string;
  unplannedCount: number;
  rows: PlanRowData[];
  groupLevelBudgetingEnabled: boolean | null | undefined;
  href: string | null;
  rolloverPeriod?: CategoryGroup['rolloverPeriod'] | null;
  showSettingsButton: boolean;
  canBeBudget: boolean;
  canMoveBudget: boolean;
};

// Convenience export
export type PlanSectionType = PlanSectionTypeImport;

type PlanAggregateRow = {
  title: string;
  key: keyof AggregateAmountsForSection;
  sectionType?: PlanSectionType;
  isLeftoverRow?: boolean;
};

export type PlanSectionData = {
  id: string;
  name: string;
  type: PlanSectionType;
  groups: PlanGroupData[];
  aggregateRows?: PlanAggregateRow[];
};

/**
 * Transform query data into display data to use with PlanGrid.
 * This only generates row data, not column data.
 *
 * - section: Income, Expenses, Goals
 *   - groups: CategoryGroup or "Contributions"
 *     - rows: Category or Goal
 */
export const getGridDisplayData = (
  categoryGroups: CategoryGroup[],
  goalsV2: GoalV2[] | undefined,
  rowAmountsByMonth: PlanningAmountsByIdDate,
  columnDates: DateTime[],
  timeframe: PlanTimeframe,
  columnDisplayInterval: Interval,
  isFlexBudgeting: boolean,
): PlanSectionData[] => {
  const sortedCategoryGroups = sortCategoryGroups(categoryGroups);
  const categoryGroupsGroupedByType = R.omit(
    [CategoryGroupType.TRANSFER], // Don't show transfers
    R.groupBy(R.prop('type'), sortedCategoryGroups),
  );
  const getIsUnplanned = isRowUnplanned(rowAmountsByMonth, columnDates, isFlexBudgeting);

  const getRowDataForFixFlexPlanGroups = (groups: CategoryGroup[]) => {
    // This turns category groups into row objects in the RowData format.
    // These are to be used as internal rows (not header) on the grid.
    const rows: PlanRowData[] = [];

    const categoryGroupsSortedByOrder = sortByOrder(groups);

    categoryGroupsSortedByOrder.forEach((group) => {
      const isExpenseGroup = group.type === CategoryGroupType.EXPENSE;

      const groupBudgetVariability = group.budgetVariability ?? BudgetVariability.FLEXIBLE;

      const groupIsUnplanned = getIsUnplanned(
        group.id,
        group.rolloverPeriod,
        groupBudgetVariability === BudgetVariability.FLEXIBLE,
      );

      if (group.groupLevelBudgetingEnabled) {
        /*
         * If the group has group budgeting enabled, we add it to the list as a row because
         * it'll be displayed in the grid as a single item
         */

        // If in flex budgeting mode, flexible expense categories can't be moved
        const canGroupMoveBudget =
          groupBudgetVariability !== BudgetVariability.FLEXIBLE || !isExpenseGroup;

        // eslint-disable-next-line fp/no-mutating-methods
        rows.push({
          ...group,
          settingsButtonAction: SettingsButtonAction.OPEN_CATEGORY_GROUP_EDIT_MODAL,
          canBeBudgeted: !!group.groupLevelBudgetingEnabled,
          icon: 'chevron',
          budgetVariability: group.budgetVariability,
          isUnplanned: groupIsUnplanned,
          canMoveBudget: canGroupMoveBudget,
          rowType: RowType.CategoryGroup,
        });
      }

      // If the group has group budgeting disabled, we add all of its categories to the list as rows
      const categoryGroupRowData = generateSortedRowDataListForCategories({
        categories: group.categories,
        getIsUnplanned,
        columnDisplayInterval,
        groupLevelBudgetingEnabled: group.groupLevelBudgetingEnabled,
        parentGroupId: group.groupLevelBudgetingEnabled ? group.id : null,
        groupUnplannedOverride: groupIsUnplanned,
        isFlexBudgeting,
        isExpenseGroup,
      });

      // eslint-disable-next-line fp/no-mutating-methods
      rows.push(...categoryGroupRowData);
    });

    return rows;
  };

  const getGroups = (groups: CategoryGroup[], type: string): PlanGroupData[] => {
    /*
     * For fixed flex, the groups for expense
     * should be fixed/flex and inside these groups
     * we should have rows for category groups and categories
     */

    // For fixed flex, the groups for expense
    // should be fixed/flex and inside these groups
    // we should have rows for category groups and categories
    if (isFlexBudgeting && type === CategoryGroupType.EXPENSE) {
      // Create rowData list from all category groups
      const allRows = getRowDataForFixFlexPlanGroups(groups);

      // group rows by budget variability
      const groupedByFixFlex = R.groupBy(
        R.propOr(DEFAULT_BUDGET_VARIABILITY_IF_UNSET, 'budgetVariability'),
        allRows,
      );

      // partition each variability's rows into planned and unplanned
      const [unplannedFixed, plannedFixed] = R.partition(
        R.prop('isUnplanned'),
        groupedByFixFlex[BudgetVariability.FIXED] ?? [],
      );
      const [unplannedNonMonthly, plannedNonMonthly] = R.partition(
        R.prop('isUnplanned'),
        groupedByFixFlex[BudgetVariability.NON_MONTHLY] ?? [],
      );
      const [unplannedFlex, plannedFlex] = R.partition(
        R.prop('isUnplanned'),
        groupedByFixFlex[BudgetVariability.FLEXIBLE] ?? [],
      );

      // If it has a parent then it should not count as unplanned
      // as the parent is a group with group budgeting
      const filterUnplannedCount = (rows: PlanRowData[]) =>
        R.filter(({ parentGroupId }) => R.isNil(parentGroupId), rows);

      return [
        {
          id: BudgetVariability.FIXED,
          name: BUDGET_VARIABILITY_TO_TITLE_MAPPING[BudgetVariability.FIXED],
          rows: [...plannedFixed, ...unplannedFixed],
          unplannedCount: filterUnplannedCount(unplannedFixed).length,
          groupLevelBudgetingEnabled: false,
          href: null,
          showSettingsButton: false,
          canBeBudget: false,
          canMoveBudget: false,
        },
        {
          id: BudgetVariability.FLEXIBLE,
          name: BUDGET_VARIABILITY_TO_TITLE_MAPPING[BudgetVariability.FLEXIBLE],
          rows: [...plannedFlex, ...unplannedFlex],
          unplannedCount: filterUnplannedCount(unplannedFlex).length,
          groupLevelBudgetingEnabled: false,
          href: null,
          showSettingsButton: false,
          canBeBudget: true, // top level Flex can be budgeted
          canMoveBudget: true, // top level Flex can be moved
        },
        {
          id: BudgetVariability.NON_MONTHLY,
          name: BUDGET_VARIABILITY_TO_TITLE_MAPPING[BudgetVariability.NON_MONTHLY],
          rows: [...plannedNonMonthly, ...unplannedNonMonthly],
          unplannedCount: filterUnplannedCount(unplannedNonMonthly).length,
          groupLevelBudgetingEnabled: false,
          href: null,
          showSettingsButton: false,
          canBeBudget: false,
          canMoveBudget: false,
        },
      ];
    }

    return groups.map(({ categories, ...group }) =>
      mapGroupToDisplay(
        group,
        generateSortedRowDataListForCategories({
          categories,
          getIsUnplanned,
          columnDisplayInterval,
          groupLevelBudgetingEnabled: group.groupLevelBudgetingEnabled,
          isFlexBudgeting,
        }),
        columnDisplayInterval,
        isFlexBudgeting,
      ),
    );
  };

  const categoryTypeSections = Object.values(
    R.mapObjIndexed(
      (groups, type) => ({
        id: type,
        name: CATEGORY_GROUP_TYPE_TO_TITLE[type],
        type: type as CategoryGroupType,
        groups: getGroups(groups, type),
        aggregateRows: aggregateRowsForSection(type, timeframe),
      }),
      categoryGroupsGroupedByType,
    ),
  );

  const goalsSection = {
    id: GOALS_SECTION_ID,
    name: GOALS_SECTION_TITLE,
    type: 'savings' as const,
    groups: [
      mapGroupToDisplay(
        {
          name: GOALS_GROUP_TITLE,
          id: GOALS_GROUP_ID,
          groupLevelBudgetingEnabled: false,
        },
        // @ts-ignore this is correct, TS just doesn't like the generic with 2 different types. Will be fixed when we remove goals-v1
        sortAndMapGoalsV2(goalsV2, getIsUnplanned),
        isFlexBudgeting,
      ),
    ],
    aggregateRows: aggregateRowsForSection(GOALS_SECTION_ID, timeframe),
  };

  const sections = [...categoryTypeSections, goalsSection];

  return sections;
};

/** Merge amount infos with a merge function. */
const mergeAmountInfosWith =
  (fn: (a: number, b: number) => number) =>
  (a: PlanAmounts, b: PlanAmounts): PlanAmounts => ({
    budgeted: fn(a.budgeted ?? 0, b.budgeted ?? 0),
    actual: fn(a.actual ?? 0, b.actual ?? 0),
    remaining:
      RA.isNotNil(a.remaining) || RA.isNotNil(b.remaining)
        ? // Only calculate this if rollovers is enabled
          fn(a.remaining ?? 0, b.remaining ?? 0)
        : undefined,
    rollover: fn(a.rollover ?? 0, b.rollover ?? 0),
  });

/**
 * Sums two amount objects together.
 *
 * budgeted: sum of budgeted in both
 * actual: sum of actual in both
 */
export const sumAmountInfos = mergeAmountInfosWith(R.add);

const subtractAmountInfos = mergeAmountInfosWith(R.subtract);

const aggregateAmounts = (amounts: { [date: string]: PlanAmounts }[]) =>
  amounts.reduce(R.mergeWith(sumAmountInfos), {});

/**
 * Merges amounts by year for each row. Converts all date keys to start of that year.
 *
 * Example:
 * {
 *   rowId: {
 *     '2020-01-01': {
 *       budgeted: 123
 *     },
 *     '2020-02-01': {
 *       budgeted: 234
 *     },
 *     '2019-05-01': {
 *       budgeted: 100
 *     }
 *   }
 * }
 *
 * Returns:
 * {
 *   rowId: {
 *     '2020-01-01': {
 *       budgeted: 357 // 123 + 234
 *     },
 *     '2019-01-01': {
 *       budgeted: 100
 *     }
 *   }
 * }
 */
export const getYearlyAggregateAmounts = (rowAmountsByDate: PlanningAmountsByIdDate) =>
  R.mapObjIndexed(
    (amountsByDate) =>
      R.toPairs(amountsByDate).reduce((acc, [date, amounts]) => {
        const yearKey = DateTime.fromISO(date).startOf('year').toISODate();
        return R.mergeWith(sumAmountInfos, acc, { [yearKey]: amounts });
      }, {}),
    rowAmountsByDate,
  );

export type PlanAmountsByDate = {
  [date: string]: PlanAmounts;
};

export type GridAmountsForGroup = {
  aggregate: PlanAmountsByDate;
  aggregateUnplanned: PlanAmountsByDate;
  rowsAggregate: PlanAmountsByDate;
} & {
  [rowId: string]: PlanAmountsByDate;
};

type AggregateAmountsForSection = {
  aggregate: PlanAmountsByDate;
  aggregateLeftover?: PlanAmountsByDate;
};

export type GridAmountsForSection = AggregateAmountsForSection & {
  [groupId: string]: GridAmountsForGroup;
};

export type GridAmounts = {
  [sectionId: string]: GridAmountsForSection;
};

/**
 * Returns amounts in the same structured hierarchy as grid data (section -> group -> row).
 * Section and group levels have `aggregate` key which is the sum of all of their children.
 *
 * The benefit of this structure is at the component level we can pass only the amounts
 * that each component cares about so they don't all have to rerender when one amount changes.
 */
export const getGridAmounts = (
  sections: PlanSectionData[],
  rowAmountsByDate: PlanningAmountsByIdDate,
): GridAmounts => {
  const getRowAmountsById = (rows: PlanRowData[]) =>
    R.fromPairs(rows.map(({ id: rowId }) => [rowId, rowAmountsByDate[rowId] ?? {}]));

  const getGroupAmountsById = (groups: PlanGroupData[]) =>
    R.fromPairs(
      groups.map(({ id: groupId, rows, groupLevelBudgetingEnabled, ...rest }) => {
        const rowAmountsById = getRowAmountsById(rows);
        const rowsToBeAggregated = getRowAmountsById(rows.filter((x) => !x.parentGroupId));

        const rowsAggregate = aggregateAmounts(Object.values(rowsToBeAggregated));
        let aggregate = rowsAggregate;

        if (groupLevelBudgetingEnabled || groupId === BudgetVariability.FLEXIBLE) {
          // should override aggregate to use values from category group
          if (rowAmountsByDate[groupId]) {
            aggregate = rowAmountsByDate[groupId] ?? {};
          } else {
            R.forEachObjIndexed((amounts) => {
              amounts.loadingGroupBudget = true;
            }, aggregate);
          }
        }

        const unplannedRows = rows.filter(
          ({ isUnplanned, parentGroupId }) => isUnplanned && !parentGroupId,
        );
        const aggregateUnplanned = aggregateAmounts(
          unplannedRows.map(({ id }) => rowAmountsById[id]),
        );
        return [
          groupId,
          {
            ...rowAmountsById,
            aggregate,
            aggregateUnplanned,
            rowsAggregate,
          } as GridAmountsForGroup,
        ];
      }),
    );

  const sectionAmountsById = R.fromPairs(
    sections.map(({ id: sectionId, groups }) => {
      const groupAmountsById = getGroupAmountsById(groups);
      const aggregate = aggregateAmounts(
        Object.values(groupAmountsById).map(({ aggregate }) => aggregate),
      );

      return [sectionId, { ...groupAmountsById, aggregate } as GridAmountsForSection];
    }),
  );

  if (sectionAmountsById[GOALS_SECTION_ID]) {
    const aggregateSavings = R.mergeWith(
      subtractAmountInfos,
      sectionAmountsById[CategoryGroupType.INCOME]?.aggregate ?? {},
      sectionAmountsById[CategoryGroupType.EXPENSE]?.aggregate ?? {},
    );
    const aggregateLeftover = R.mergeWith(
      subtractAmountInfos,
      aggregateSavings,
      sectionAmountsById[GOALS_SECTION_ID].aggregate,
    );

    sectionAmountsById[GOALS_SECTION_ID].aggregateLeftover = aggregateLeftover;
  }

  return sectionAmountsById;
};

/** Merges two lists of monthlyAmountsByCategory, ensuring that category stays unique */
export const mergeMonthlyAmountsByCategory =
  (a: Common_GetJointPlanningDataQuery['budgetData']['monthlyAmountsByCategory']) =>
  (b: typeof a): typeof a => {
    const indexByCategoryId = R.indexBy<
      Common_GetJointPlanningDataQuery['budgetData']['monthlyAmountsByCategory'][number]
    >(({ category: { id } }) => id);

    const merged = R.mergeWith(
      (
        a: Common_GetJointPlanningDataQuery['budgetData']['monthlyAmountsByCategory'][number],
        b: Common_GetJointPlanningDataQuery['budgetData']['monthlyAmountsByCategory'][number],
      ) =>
        R.evolve(
          {
            monthlyAmounts: R.concat(a.monthlyAmounts),
          },
          b,
        ),
      indexByCategoryId(a),
      indexByCategoryId(b),
    );

    return R.flatten(R.values(merged));
  };

/** Merges two lists of monthlyAmountsByCategory, ensuring that category stays unique */
const mergeMonthlyAmountsByCategoryGroup =
  (a: Common_GetJointPlanningDataQuery['budgetData']['monthlyAmountsByCategoryGroup']) =>
  (b: typeof a): typeof a => {
    const indexByCategoryId = R.indexBy<
      Common_GetJointPlanningDataQuery['budgetData']['monthlyAmountsByCategoryGroup'][number]
    >(({ categoryGroup: { id } }) => id);

    const merged = R.mergeWith(
      (
        a: Common_GetJointPlanningDataQuery['budgetData']['monthlyAmountsByCategoryGroup'][number],
        b: Common_GetJointPlanningDataQuery['budgetData']['monthlyAmountsByCategoryGroup'][number],
      ) =>
        R.evolve(
          {
            monthlyAmounts: R.concat(a.monthlyAmounts),
          },
          b,
        ),
      indexByCategoryId(a),
      indexByCategoryId(b),
    );

    return R.flatten(R.values(merged));
  };

type MonthlyAmountsForFlexExpense =
  Common_GetJointPlanningDataQuery['budgetData']['monthlyAmountsForFlexExpense'];

const mergeMonthlyAmountsForFlexExpense =
  (a: MonthlyAmountsForFlexExpense) =>
  (b: MonthlyAmountsForFlexExpense): MonthlyAmountsForFlexExpense => {
    const indexByMonth = R.indexBy<MonthlyAmountsForFlexExpense['monthlyAmounts'][number]>(
      ({ month }) => month,
    );

    const mergedMonthlyAmounts = R.mergeWith(
      (a: MonthlyAmountsForFlexExpense['monthlyAmounts'][number]) => a, // If value exists in both, use the value from a
      indexByMonth(a.monthlyAmounts),
      indexByMonth(b.monthlyAmounts),
    );

    return {
      ...b,
      monthlyAmounts: R.values(mergedMonthlyAmounts),
    };
  };

const mergeGoalsV2 =
  (a: GoalV2[]) =>
  (b: GoalV2[]): GoalV2[] => {
    const aById = R.indexBy(R.prop('id'), a);
    const bById = R.indexBy(R.prop('id'), b);

    const mergedGoalsById = R.mergeWith(
      (a: GoalV2, b: GoalV2): GoalV2 => ({
        ...a,
        plannedContributions: R.concat(a.plannedContributions, b.plannedContributions),
        monthlyContributionSummaries: R.concat(
          a.monthlyContributionSummaries,
          b.monthlyContributionSummaries,
        ),
      }),
      aById,
      bById,
    );

    return Object.values(mergedGoalsById);
  };

/** Merge data from fetchMore query with existing data. */
export const mergeFetchMorePlanningData = <
  T extends MarkOptional<Common_GetJointPlanningDataQuery, 'budgetData'>,
>(
  prev: T,
  fetchMoreResult: T,
) => {
  const prevWithAllFields = R.mergeLeft(prev, {
    // these fields are either included or excluded depending on goals v2 feature flag.
    // since R.evolve below only works for keys that are present in the evolving object,
    // we ensure that these keys exist.
    goals: [],
    goalsV2: [],
  });

  return R.evolve(
    {
      budgetData: {
        monthlyAmountsByCategory: mergeMonthlyAmountsByCategory(
          fetchMoreResult?.budgetData?.monthlyAmountsByCategory ?? [],
        ),
        monthlyAmountsByCategoryGroup: mergeMonthlyAmountsByCategoryGroup(
          fetchMoreResult?.budgetData?.monthlyAmountsByCategoryGroup ?? [],
        ),
        monthlyAmountsForFlexExpense: mergeMonthlyAmountsForFlexExpense(
          fetchMoreResult?.budgetData?.monthlyAmountsForFlexExpense ?? {
            __typename: 'BudgetFlexMonthlyAmounts',
            budgetVariability: BudgetVariability.FLEXIBLE,
            monthlyAmounts: [],
          },
        ),
        totalsByMonth: (current) => {
          const results = fetchMoreResult?.budgetData?.totalsByMonth ?? [];
          return results.concat(current);
        },
      },
      goalsV2: mergeGoalsV2(fetchMoreResult?.goalsV2 ?? []),
    },
    prevWithAllFields,
  );
};
