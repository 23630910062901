import type { Transition, Variants } from 'framer-motion';
import camelCase from 'lodash/camelCase';

import staticTheme from 'common/lib/theme/staticTheme';

export const POPOVER_ANIMATION_VARIANTS: Variants = {
  enter: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.96 },
};

/* Defaults to line up with theme object */
export const DEFAULT_TRANSITION: Transition = {
  duration: staticTheme.transition.defaultDuration,
  ease: camelCase(staticTheme.transition.defaultEasing),
};

/* Deprecated. Try to use variables from the theme instead of defining new settings here. */
export const DEFAULT_MOTION_TRANSITION: Transition = { duration: 0.2, ease: 'easeInOut' };

export const DEFAULT_MOTION_TRANSITION_FAST: Transition = { type: 'tween', duration: 0.06 };
