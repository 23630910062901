import type {
  AccountsListFieldsFragment,
  Web_RecommendedAccountsQuery,
} from 'common/generated/graphql';
import type { ElementOf } from 'common/types/utility';

type AccountFields = {
  createdAt: string;
  dataProvider: string | null | undefined;
} & AccountsListFieldsFragment;

type RecommendedAccount = ElementOf<
  Web_RecommendedAccountsQuery['recommendedAccountDataTransferAccounts'],
  'recommendedAccounts'
> &
  AccountFields;

type OtherAccount = ElementOf<
  Web_RecommendedAccountsQuery['recommendedAccountDataTransferAccounts'],
  'otherAccounts'
> &
  AccountFields;

type SameSubtypeAccount = ElementOf<
  Web_RecommendedAccountsQuery['recommendedAccountDataTransferAccounts'],
  'sameSubtypeAccounts'
> &
  AccountFields;

type UnsupportedAccount = ElementOf<
  Web_RecommendedAccountsQuery['recommendedAccountDataTransferAccounts'],
  'unsupportedAccounts'
> & {
  account: AccountFields;
  reason: string;
};

type AccountRecommendation =
  | RecommendedAccount
  | OtherAccount
  | SameSubtypeAccount
  | UnsupportedAccount;

export type RecommendedAccountGroup = {
  label: string;
  options: AccountOption[];
  type: 'recommended' | 'other' | 'sameSubtype' | 'unsupported';
};

function mapAccountsRecommendationsToOptions(accounts?: AccountRecommendation[]): AccountOption[] {
  if (!accounts) {
    return [];
  }
  return accounts.map((account) => ({
    value: isUnsupportedAccount(account) ? account.account.id : account.id,
    accountData: account,
  }));
}

export type AccountOption = {
  value: string;
  accountData: AccountRecommendation;
};

export const isUnsupportedAccount = (option: AccountRecommendation): option is UnsupportedAccount =>
  option.__typename === 'UnsupportedAccount';

export const getRecommendedAccountGroups = (
  recommendedAccountData?: Web_RecommendedAccountsQuery['recommendedAccountDataTransferAccounts'],
): RecommendedAccountGroup[] => {
  const { recommendedAccounts, otherAccounts, sameSubtypeAccounts, unsupportedAccounts } =
    recommendedAccountData ?? {};
  const displaySubtype = sameSubtypeAccounts?.[0]?.subtype?.display;
  return [
    {
      label: 'Recommended',
      options: mapAccountsRecommendationsToOptions(recommendedAccounts),
      type: 'recommended',
    },
    {
      label: `Other ${displaySubtype?.toLowerCase()} accounts`,
      options: mapAccountsRecommendationsToOptions(sameSubtypeAccounts),
      type: 'sameSubtype',
    },
    {
      label: 'All other accounts',
      options: mapAccountsRecommendationsToOptions(otherAccounts),
      type: 'other',
    },
    {
      label: 'Unavailable to transfer data to',
      options: mapAccountsRecommendationsToOptions(unsupportedAccounts),
      type: 'unsupported',
    },
  ];
};
