import { DataProviderLegacy } from 'common/generated/graphql';

export const getDisplayName = (dataProvider: DataProviderLegacy | string): string => {
  // Return 'MX' for both enum value and case-insensitive string match
  if (
    dataProvider === DataProviderLegacy.MX ||
    (typeof dataProvider === 'string' && dataProvider.toLowerCase() === 'mx')
  ) {
    return 'MX';
  }

  // Otherwise, title case it and replace underscores
  return dataProvider
    .toLowerCase()
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
};
