import { css } from 'styled-components';

import { color, spacing } from 'common/lib/theme/dynamic';

export const onboardingSurveySelectIndicatorStyles = css<{ isSelected: boolean }>`
  background: ${color.white};
  width: ${spacing.large};
  height: ${spacing.large};
  margin-right: ${spacing.small};
  border: 1px solid ${({ isSelected }) => (isSelected ? color.orange : color.gray)};
`;
