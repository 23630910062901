import { assocPath, groupBy, map, path, pathOr, pipe } from 'ramda';

import type {
  FilterMenuOption,
  FilterMenuSection,
  WithPath,
} from 'components/reports/filters/types';

import { makeAmountFilterSection, makeOtherFilterSection } from 'lib/filters/sections';
import { UNTAGGED_FILTER_ID } from 'lib/filters/types';
import type { SectionAdapterOptions } from 'lib/filters/types';
import {
  generateAmountFilterFromOption,
  makeFilterFromRadioOption,
  makeIsSelected,
  makeTagFilterSelectionFromOption,
  radioFilterFactory,
  sortByOrder,
  transformCategory,
} from 'lib/filters/utils';

import type { Web_TransactionsFilterQuery } from 'common/generated/graphql';
import type { TransactionFilters } from 'types/filters';

/**
 * Adapt the filter sections from the query to the format expected by the FilterMenu component.
 */
export const getFilterSectionsFromQuery = (
  data: Maybe<Web_TransactionsFilterQuery>,
  filters?: Partial<TransactionFilters>,
  options?: SectionAdapterOptions,
): FilterMenuSection[] => {
  if (!data) {
    return [];
  }

  const { renderAccountLogo, renderMerchantAccessory, renderTag } = options || {};
  const {
    categoryGroups = [],
    merchants = [],
    accounts = [],
    householdTransactionTags = [],
    householdPreferences,
  } = data;

  const accountsByType = groupBy(pathOr('', ['type', 'display']), accounts);
  const accountGroupTypes = Object.keys(accountsByType);

  const accountGroupOrder = householdPreferences?.accountGroupOrder;
  const hasAccountGroupOrder = accountGroupOrder && accountGroupOrder.length > 0;

  const sortedAccountGroupTypes = hasAccountGroupOrder
    ? [
        // First include account types that exist in accountsByType
        ...accountGroupOrder.filter((accountType) => accountsByType[accountType]),
        // Then include any remaining account types not in accountGroupOrder
        ...accountGroupTypes.filter((accountType) => !accountGroupOrder.includes(accountType)),
      ]
    : accountGroupTypes;

  const makeRadioFilter = radioFilterFactory(filters);

  return [
    {
      id: 'categories',
      name: 'Categories',
      options: sortByOrder(categoryGroups).map((group) => ({
        id: group.id,
        name: group.name,
        options: pipe(
          sortByOrder,
          map((category) => transformCategory(category, makeIsSelected(filters?.categories))),
        )(group.categories),
      })),
    },
    {
      id: 'merchants',
      name: 'Merchants',
      options: merchants.map((merchant) => ({
        id: merchant.id,
        name: merchant.name,
        isSelected: makeIsSelected(filters?.merchants)(merchant.id),
        accessory: renderMerchantAccessory?.(merchant),
      })),
    },
    {
      id: 'accounts',
      name: 'Accounts',
      options: sortedAccountGroupTypes.map((type) => ({
        id: type,
        name: type,
        options: accountsByType[type].map((account) => ({
          id: account.id,
          name: pathOr('', ['displayName'], account),
          isSelected: makeIsSelected(filters?.accounts)(account.id),
          icon: renderAccountLogo?.(account),
        })),
      })),
    },
    {
      id: 'tags',
      name: 'Tags',
      options: [
        {
          id: UNTAGGED_FILTER_ID,
          name: 'Untagged',
          isSelected: filters?.isUntagged,
        },
        ...sortByOrder(householdTransactionTags).map((tag) => ({
          id: tag.id,
          name: tag.name,
          color: tag.color,
          isSelected: makeIsSelected(filters?.tags)(tag.id),
          icon: renderTag?.(tag),
        })),
      ],
    },
  ]
    .concat(makeAmountFilterSection(filters))
    .concat(makeOtherFilterSection(makeRadioFilter, filters, options));
};

/**
 * Transforms a list of selected filter options into a format suitable for transactions filtering.
 * Each selected option is an object with a `path` and `id`. The first element of `path` determines
 * the type of the filter (e.g., 'categories', 'merchants', 'tags', 'accounts').
 *
 * The function reduces the array of selected options into an object where each key is a type of filter
 * and the value is an array of selected option IDs for that filter. If a type cannot be determined
 * from an option, a warning is logged and the option is skipped.
 *
 * @example
 * const selected = [
 *   { path: ['categories'], id: '1' },
 *   { path: ['categories'], id: '2' },
 *   { path: ['merchants'], id: '3' },
 *   { path: ['tags'], id: '4' },
 *   { path: ['tags'], id: '5' },
 * ];
 *
 * adaptSelectedOptionsToTransactionsFilters(selected);
 * >> { categories: ['1', '2'], merchants: ['3'], tags: ['4', '5'] }
 */
export const adaptSelectedOptionsToTransactionsFilters = <TFilters extends Record<string, unknown>>(
  selected: WithPath<FilterMenuOption>[],
): Partial<TFilters> => {
  const result = selected.reduce((acc, option) => {
    // 'categories', 'merchants', 'tags', 'accounts'...
    // The ID for each of these sections is defined above in `getFilterSectionsFromQuery`
    const section = path<string>(['path', 0], option);

    if (!section) {
      // eslint-disable-next-line no-console
      console.warn('No type found for filter option', option);
      return acc;
    }

    // Amount filter options are handled separately as they have a different structure
    if (section === 'amount') {
      return {
        ...acc,
        ...generateAmountFilterFromOption(option),
      };
    } else if (section === 'tags') {
      const ids = pathOr([], [section], acc);
      return { ...acc, ...makeTagFilterSelectionFromOption(option, ids) };
    } else if (section === 'other') {
      return { ...acc, ...makeFilterFromRadioOption(option) };
    }

    const ids = pathOr([], [section], acc);
    return assocPath([section], [...ids, option.id], acc);
  }, {});

  return result;
};
