import { DateTime } from 'luxon';
import * as R from 'ramda';

import type { DateWithTransactions, Transaction } from 'lib/accounts/transferAccountData/types';

const transactionsByDate = (transactions: Transaction[]) => {
  const map = new Map<string, Transaction[]>();
  transactions.forEach((transaction) => {
    const { date } = transaction;
    const existing = map.get(date) || [];
    const result = R.sort(
      (a, b) => {
        const nameA = (R.path(['merchant', 'name'], a) || '') as string;
        const nameB = (R.path(['merchant', 'name'], b) || '') as string;
        return nameA.localeCompare(nameB);
      },
      [...existing, transaction],
    );
    map.set(date, result);
  });
  return map;
};

const generateDateRange = (transitionDate: string, previewDays: number): string[] => {
  const baseDate = DateTime.fromISO(transitionDate);

  // Generate days before transition date
  const beforeDates = R.map(
    (i) => baseDate.minus({ days: i }).toISODate() as string,
    R.range(1, previewDays + 1),
  );

  // Generate days after transition date
  const afterDates = R.map(
    (i) => baseDate.plus({ days: i }).toISODate() as string,
    R.range(1, previewDays + 1),
  );

  // Combine them all
  return R.concat(R.concat(beforeDates, [transitionDate]), afterDates);
};

export const organizeTransactionsByDate = (
  fromTransactions: Transaction[],
  toTransactions: Transaction[],
  transitionDate: string,
  previewDays: number,
): {
  fromAccountDatesWithTransactions: DateWithTransactions[];
  toAccountDatesWithTransactions: DateWithTransactions[];
} => {
  const fromTransactionsByDate = transactionsByDate(fromTransactions);
  const toTransactionsByDate = transactionsByDate(toTransactions);

  // Generate the complete date range including transition date and preview days
  const dateRange = generateDateRange(transitionDate, previewDays);

  // Include both transaction dates and the generated date range
  const dates = new Set([
    ...fromTransactionsByDate.keys(),
    ...toTransactionsByDate.keys(),
    ...dateRange,
  ]);

  const sortedDates = R.sort(R.descend(R.identity), Array.from(dates));

  const fromAccountDatesWithTransactions = sortedDates.map((date) => {
    const numTransactionsForDate = Math.max(
      fromTransactionsByDate.get(date)?.length ?? 0,
      toTransactionsByDate.get(date)?.length ?? 0,
    );
    return {
      date,
      transactions: fromTransactionsByDate.get(date) || [],
      numTransactionsForDate,
    };
  });

  const toAccountDatesWithTransactions = sortedDates.map((date) => {
    const numTransactionsForDate = Math.max(
      fromTransactionsByDate.get(date)?.length ?? 0,
      toTransactionsByDate.get(date)?.length ?? 0,
    );
    return {
      date,
      transactions: toTransactionsByDate.get(date) || [],
      numTransactionsForDate,
    };
  });

  return {
    fromAccountDatesWithTransactions,
    toAccountDatesWithTransactions,
  };
};
