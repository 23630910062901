import * as R from 'ramda';
import { isNil } from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import Tooltip from 'components/lib/ui/Tooltip';
import WithIndicatorContainer from 'components/lib/ui/WithIndicatorContainer';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import IconButton from 'components/lib/ui/button/IconButton';
import { OverlayTrigger, Popover } from 'components/lib/ui/popover';

import { spacing, transition, variables } from 'common/lib/theme/dynamic';
import { truncate } from 'common/utils/String';

import type { ReportConfiguration } from 'common/generated/graphql';

const StyledPopover = styled(Popover)`
  min-width: 260px;
  padding: ${spacing.xsmall} ${spacing.xsmall};
`;

const DropdownMenuList = styled.div`
  max-height: 500px;
  overflow-y: auto;
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${variables.color.divider.secondary};
  margin: ${spacing.xxsmall} 0;
`;

const StyledIconButton = styled(IconButton)`
  opacity: 0;
  transition: opacity ${transition.default};
  margin-left: ${spacing.xsmall};
`;

const StyledDropdownMenuItem = styled(DropdownMenuItem)`
  margin: 0 0 4px 0;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ReportConfigurationDropdownMenuItem = styled(StyledDropdownMenuItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: ${spacing.xsmall};

  &:hover {
    ${StyledIconButton} {
      opacity: 1;
    }
  }
`;

const ActionDropdownMenuItem = styled(StyledDropdownMenuItem)<{ disabled?: boolean }>`
  color: ${variables.color.content.info};

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${variables.color.content.secondary};
    `}
`;

const TRUNCATED_DISPLAY_NAME_LENGTH = 30;

const truncateDisplayName = (displayName: string | undefined) =>
  displayName ? truncate(displayName, TRUNCATED_DISPLAY_NAME_LENGTH) : undefined;

type Props = {
  hasActiveFilters: boolean;
  reportConfigurations: ReportConfiguration[];
  selectedReportConfigurationId?: string;
  onSelectReportConfiguration?: (reportConfiguration: ReportConfiguration) => void;
  onSaveReportConfiguration?: () => void;
  onEditReportConfiguration?: (reportConfiguration: ReportConfiguration) => void;
};

const ReportsConfigurationsMenuButton = ({
  hasActiveFilters,
  reportConfigurations,
  selectedReportConfigurationId,
  onSelectReportConfiguration,
  onSaveReportConfiguration,
  onEditReportConfiguration,
}: Props) => {
  const sortedReportConfigurations = useMemo(
    () =>
      R.sort(
        (a: ReportConfiguration, b: ReportConfiguration) =>
          a.displayName.localeCompare(b.displayName),
        reportConfigurations,
      ),
    [reportConfigurations],
  );

  const selectedReportConfiguration = useMemo(
    () =>
      RA.isNotNil(selectedReportConfigurationId)
        ? sortedReportConfigurations.find((config) => config.id === selectedReportConfigurationId)
        : undefined,
    [sortedReportConfigurations, selectedReportConfigurationId],
  );

  return (
    <OverlayTrigger
      placement="bottom-end"
      overlay={() => (
        <StyledPopover>
          <DropdownMenuList>
            {sortedReportConfigurations.map((reportConfiguration) => (
              <ReportConfigurationDropdownMenuItem
                key={reportConfiguration.id}
                onClick={() => onSelectReportConfiguration?.(reportConfiguration)}
                selected={reportConfiguration.id === selectedReportConfigurationId}
              >
                <span
                  title={
                    reportConfiguration.displayName.length > TRUNCATED_DISPLAY_NAME_LENGTH
                      ? reportConfiguration.displayName
                      : undefined
                  }
                >
                  {truncateDisplayName(reportConfiguration.displayName)}
                </span>
                <StyledIconButton
                  icon="edit"
                  size="xxsmall"
                  onClick={(event) => {
                    onEditReportConfiguration?.(reportConfiguration);
                    event.stopPropagation();
                  }}
                />
              </ReportConfigurationDropdownMenuItem>
            ))}
          </DropdownMenuList>
          {reportConfigurations.length > 0 && <Divider />}
          <Tooltip
            portal
            place="left"
            maxWidth={180}
            content={
              hasActiveFilters ? undefined : 'Select filters and/or a date range to save a report.'
            }
          >
            <ActionDropdownMenuItem
              onClick={onSaveReportConfiguration}
              disabled={!hasActiveFilters}
            >
              Save current report
            </ActionDropdownMenuItem>
          </Tooltip>
        </StyledPopover>
      )}
    >
      {({ toggleOpen, isOpen }) => (
        <WithIndicatorContainer show={!!selectedReportConfiguration}>
          <div title={selectedReportConfiguration?.displayName}>
            <DefaultButton onClick={toggleOpen} active={isOpen}>
              <ButtonIcon
                name={isNil(selectedReportConfiguration) ? 'bookmark' : 'bookmark-filled'}
              />
              <span>
                {truncateDisplayName(selectedReportConfiguration?.displayName) ?? 'Saved'}
              </span>
            </DefaultButton>
          </div>
        </WithIndicatorContainer>
      )}
    </OverlayTrigger>
  );
};

export default ReportsConfigurationsMenuButton;
