import * as RA from 'ramda-adjunct';
import { useDispatch, useSelector } from 'react-redux';

import { setHasAddedAccounts } from 'common/actions';
import { GET_HAS_ACCOUNTS } from 'common/lib/graphQl/accounts';
import useQuery from 'common/lib/hooks/useQuery';
import { getHasAddedAccounts } from 'common/state/accounts/selectors';

/**
 * Hook that checks if a user has accounts. Reads from localStorage first and only runs
 * a query if no data is available.
 */
const useHasAccounts = () => {
  const dispatch = useDispatch();

  const hasAccountsPersisted = useSelector(getHasAddedAccounts);

  const { data, refetch, error, isLoadingInitialData } = useQuery(GET_HAS_ACCOUNTS, {
    skip: hasAccountsPersisted,
    onCompleted({ hasAccounts }) {
      if (hasAccounts) {
        dispatch(setHasAddedAccounts(hasAccounts));
      }
    },
  });

  const hasAccounts =
    (RA.isNotNilOrEmpty(hasAccountsPersisted) ? hasAccountsPersisted : data?.hasAccounts) ??
    undefined;

  return { hasAccounts, refetch, error, isLoadingInitialData };
};

export default useHasAccounts;
