import _ from 'lodash';
import React from 'react';
import { createPortal } from 'react-dom';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import uuid from 'uuid/v4';

import { variables } from 'common/lib/theme/dynamic';

const DEFAULT_MAX_WIDTH_PX = 248;
const DEFAULT_OPACITY = 0.9;
const CLICKABLE_DELAY_HIDE_MS = 200;
const MIN_TOOLTIP_LEFT = 20;
const OFFSET_OVERRIDE_PX = -4;

const TooltipPlaceholder = styled(ReactTooltip).attrs({
  className: 'custom-tooltip',
})<{ $maxWidth: number; $opacity: number; $fitContent: boolean }>`
  transition: ${({ theme }) => theme.transition.createFastTransitions(['opacity'])};

  &.custom-tooltip {
    --tooltip-background-color: ${variables.color.background.tooltip};

    padding: 0;
    background-color: var(--tooltip-background-color);
    max-width: ${({ $maxWidth, $fitContent }) => ($fitContent ? 'fit-content' : `${$maxWidth}px`)};
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize.xsmall};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    line-height: 1.5;
    border-radius: ${({ theme }) => theme.radius.small};
    transform-origin: center;

    &.place-right:after {
      border-right-color: var(--tooltip-background-color);
    }

    &.place-left:after {
      border-left-color: var(--tooltip-background-color);
    }

    &.place-bottom:after {
      border-bottom-color: var(--tooltip-background-color);
    }

    &.place-top:after {
      border-top-color: var(--tooltip-background-color);
    }
  }

  &.show {
    opacity: ${({ $opacity }) => $opacity};
  }
`;

export const TooltipText = styled.div<{ $hyphens?: boolean; $clickable?: boolean }>`
  padding: ${({ theme }) => `${theme.spacing.xsmall} calc(${theme.spacing.xsmall} + 3px)`};
  word-break: break-word;
  white-space: normal;
  hyphens: ${({ $hyphens }) => ($hyphens ? 'auto' : 'none')};

  a {
    transition: unset;
  }
`;

export type Props = Omit<React.ComponentProps<typeof ReactTooltip>, 'className'> & {
  children: React.ReactElement;
  content?: React.ReactNode;
  maxWidth?: number;
  fitContent?: boolean;
  opacity?: number;
  id?: string;
  hyphens?: boolean;
  /** Portal to document.body */
  portal?: boolean;
  /** Minimum space to ensure tooltip isn't rendered offscreen */
  minTooltipLeft?: number;
};

const Tooltip = ({
  content,
  children,
  maxWidth = DEFAULT_MAX_WIDTH_PX,
  fitContent = false,
  opacity = DEFAULT_OPACITY,
  id = uuid(),
  effect = 'solid',
  hyphens = true,
  clickable,
  delayHide,
  portal,
  minTooltipLeft = MIN_TOOLTIP_LEFT,
  place,
  ...tooltipProps
}: Props) => {
  if (!content) {
    // No tooltip content so don't render tooltip at all
    return children;
  }

  // When the default offset is used, there's a gap between the tooltip and the trigger element.
  // This reduced the gap so that the tooltip touches the trigger element.
  const offset = { [place || 'top']: OFFSET_OVERRIDE_PX };
  const placeholder = (
    <TooltipPlaceholder
      $maxWidth={maxWidth}
      $fitContent={fitContent}
      $opacity={opacity}
      effect={effect}
      offset={offset}
      {...tooltipProps}
      id={id}
      clickable={clickable}
      delayHide={delayHide ?? (clickable ? CLICKABLE_DELAY_HIDE_MS : undefined)}
      place={place}
      overridePosition={({ left, top }, event, triggerElement, tooltipElement) => ({
        top,
        // Ensure tooltip isn't rendered offscreen
        left: Math.max(
          minTooltipLeft,
          Math.min(left, window.innerWidth - tooltipElement.offsetWidth),
        ),
      })}
    >
      {_.isString(content) ? <TooltipText $hyphens={hyphens}>{content}</TooltipText> : content}
    </TooltipPlaceholder>
  );

  return (
    <>
      {React.cloneElement(children, { 'data-tip': '', 'data-for': id })}
      {portal ? createPortal(placeholder, document.body) : placeholder}
    </>
  );
};

export default Tooltip;
