import React from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import MonarchLogo from 'components/lib/ui/MonarchLogo';
import ProgressBar from 'components/lib/ui/ProgressBar';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import IconButton from 'components/lib/ui/button/IconButton';
import Header from 'components/routes/Header';

import boxShadow from 'common/lib/styles/boxShadow';
import { spacing, variables } from 'common/lib/theme/dynamic';

import routes from 'constants/routes';

const PROGRESS_BAR_HEIGHT = 2;

const StyledProgressBar = styled(ProgressBar)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -${PROGRESS_BAR_HEIGHT}px;
  height: ${PROGRESS_BAR_HEIGHT}px;
`;

const Container = styled(FlexContainer).attrs({ gap: 'small' })`
  flex: 1;
`;

// When the back button is the first item in the left container, reduce the padding
// so that the back button (which has no border but padding on all sides of the icon)
// looks more visually aligned with the side.
const StyledHeader = styled(Header)<{ showBackButton?: boolean }>`
  background-color: ${variables.color.background.fullscreenHeader};
  ${boxShadow.large}
  padding: ${({ showBackButton, theme }) =>
    showBackButton
      ? `0 ${theme.spacing.gutter} 0 ${theme.spacing.xsmall}`
      : `0 ${theme.spacing.gutter}`};
`;

// Adjusting the padding on the left when the back button is shown causes the logo
// to be off center. Shift it the half the amount that we reduced the spacing on the header
// to center it again.
const Logo = styled(MonarchLogo)<{ showBackButton?: boolean }>`
  ${({ showBackButton }) =>
    showBackButton &&
    css`
      transform: translateX(${spacing.xxsmall});
    `}
`;

type Props = {
  progress: number;
  showBackButton?: boolean;
  showCancelButton?: boolean;
  showSaveButton?: boolean;
  showSkipButton?: boolean;
  cancelButtonOnRight?: boolean;
  onClickBackButton?: () => void;
  onClickSkipButton?: () => void;
  onClickCancelButton?: () => void;
  overrideLogo?: React.ReactNode;
  rightButtons?: React.ReactNode;
};

const QuestionnaireHeader = ({
  progress,
  showBackButton,
  showCancelButton,
  showSaveButton,
  showSkipButton,
  onClickBackButton,
  onClickSkipButton,
  onClickCancelButton,
  cancelButtonOnRight,
  overrideLogo,
  rightButtons,
}: Props) => {
  const history = useHistory();

  const cancelBtn = showCancelButton ? (
    <DefaultButton
      linkTo={onClickCancelButton ? undefined : routes.advice({})}
      onClick={onClickCancelButton}
    >
      Cancel
    </DefaultButton>
  ) : null;

  return (
    <StyledHeader showSidebarToggle={false} showBackButton={showBackButton}>
      <Container alignCenter justifyBetween>
        <Container alignCenter>
          {showBackButton && (
            <IconButton
              size="medium"
              onClick={onClickBackButton ?? history.goBack}
              icon="arrow-left"
            />
          )}
          {showCancelButton && !cancelButtonOnRight && cancelBtn}
        </Container>
        {overrideLogo ?? <Logo showBackButton={showBackButton} dark />}
        <Container alignCenter justifyEnd>
          {rightButtons}
          {showSaveButton && <DefaultButton linkTo={routes.advice({})}>Save & Exit</DefaultButton>}
          {showSkipButton && onClickSkipButton && (
            <DefaultButton onClick={onClickSkipButton}>Skip</DefaultButton>
          )}
          {showCancelButton && cancelButtonOnRight && cancelBtn}
        </Container>
      </Container>
      <StyledProgressBar
        value={progress}
        color="orange"
        rightBorderColor="orange"
        backgroundColor="transparent"
        animated
      />
    </StyledHeader>
  );
};

export default QuestionnaireHeader;
