import { color, ifDark, withOpacity } from 'common/lib/theme/dynamic/types';

const SHADOW_COLOR = color('gray12', 'trueBlack');

const background = {
  primary: color('pureWhite', 'gray3'),
  secondary: color('gray2', 'gray4'),
  tertiary: color('gray4', 'gray6'),
  success: color('green3', 'green4'),
  info: color('blue3', 'blue4'),
  warning: color('yellow3', 'yellow4'),
  danger: color('red3', 'red4'),
  brand: color('orange9'),
  page: color('gray2'),
  primaryHover: color('gray1', 'gray3'),
  secondaryHover: color('gray3', 'gray5'),
  tertiaryHover: color('gray5', 'gray7'),
  successHover: color('green4', 'green5'),
  infoHover: color('blue4', 'blue5'),
  warningHover: color('yellow4', 'yellow5'),
  dangerHover: color('red4', 'red5'),
  brandHover: color('orange10'),
  brandDisabled: color('orange7'),

  // not in Figma
  fullscreenHeader: color('white'),
  tooltip: color('gray12', 'gray1'),
  modalOverlay: withOpacity(SHADOW_COLOR, ifDark(0.5, 0.4)),
};

const content = {
  primary: color('gray12', 'pureWhite'),
  secondary: color('gray11'),
  link: color('blue10'),
  success: color('green11'),
  info: color('blue11'),
  warning: color('yellow11'),
  danger: color('red11'),
  white: color('pureWhite'),
  brand: color('orange9'),
  linkHover: color('blue11'),
  successHover: color('green12'),
  infoHover: color('blue12'),
  warningHover: color('yellow12'),
  dangerHover: color('red12'),
  brandHover: color('orange10'),
};

const border = {
  primary: color('gray5', 'gray6'),
  secondary: color('gray8', 'gray9'),
  success: color('green9'),
  info: color('blue9'),
  warning: color('yellow9'),
  danger: color('red9'),
  brand: color('orange9'),
  white: color('pureWhite'),
  primaryHover: color('gray6', 'gray7'),
  successHover: color('green10'),
  infoHover: color('blue10'),
  warningHover: color('yellow10'),
  dangerHover: color('red10'),
  brandHover: color('orange10'),

  // not in Figma
  tooltip: color('gray11', 'gray4'),
};

const chart = {
  axis: color('gray3', 'gray6'),
  label: color('gray10'),
  line: color('gray12'),
  income: color('green6'),
  incomeActive: color('green9'),
  expenses: color('red6'),
  expensesActive: color('red9'),
};

const divider = {
  primary: color('gray2', 'gray4'),
  secondary: color('gray5', 'gray7'),
};

const input = {
  placeholder: color('gray9'),
  default: {
    background: color('pureWhite', 'gray3'),
    border: color('gray6', 'gray7'),
    text: content.primary,
    icon: color('gray8', 'gray7'),
  },
  hover: {
    background: color('pureWhite', 'gray3'),
    border: color('gray7', 'gray8'),
    text: content.primary,
    icon: color('gray8', 'gray7'),
  },
  focus: {
    background: color('pureWhite', 'gray3'),
    border: color('blue9', 'blue8'),
    text: content.primary,
    icon: color('gray8', 'gray7'),
  },
  error: {
    background: color('pureWhite', 'gray3'),
    border: border.danger,
    text: content.primary,
    icon: color('gray8', 'gray7'),
  },
  disabled: {
    background: color('gray2', 'gray4'),
    border: color('gray6', 'gray7'),
    text: content.secondary,
    icon: color('gray8', 'gray7'),
  },
};

const progress = {
  background: color('gray4', 'gray6'),
  success: color('green9'),
  info: color('blue9'),
  warning: color('yellow9'),
  danger: color('red9'),
  brand: color('orange9'),
};

const toggle = {
  off: {
    background: color('gray8'),
    backgroundHover: color('gray10'),
  },
  on: {
    background: background.brand,
    backgroundHover: background.brandHover,
  },
};

const shadow = {
  small: withOpacity(SHADOW_COLOR, ifDark(0.1, 0.05)),
  medium: withOpacity(SHADOW_COLOR, ifDark(0.2, 0.1)),
  inset: withOpacity(SHADOW_COLOR, ifDark(0.2, 0.1)),
  large: withOpacity(SHADOW_COLOR, ifDark(0.2, 0.1)),
  card: withOpacity(SHADOW_COLOR, ifDark(0.2, 0.1)),
};

/** Mobile nav bar. */
const navBar = {
  primary: {
    background: color('gray2'),
    tint: color('gray12'),
  },
};

const _color = {
  background,
  content,
  border,
  chart,
  divider,
  input,
  progress,
  toggle,

  // not in Figma
  shadow,
  navBar,
};

export default _color;
