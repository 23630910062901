import { useMutation } from '@apollo/client';
import prettyBytes from 'pretty-bytes';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useCallback, useEffect, useState } from 'react';
import type { FileError, FileRejection } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import SideDrawer, { SideDrawerNavigationHeader } from 'components/lib/ui/SideDrawer';
import DeleteTransactionConfirmation from 'components/transactions/drawer/DeleteTransactionConfirmationModal';
import type { FileData } from 'components/transactions/drawer/TransactionDrawerBody';
import TransactionDrawerBody from 'components/transactions/drawer/TransactionDrawerBody';
import TransactionDrawerDeleteAttachmentConfirmationModal from 'components/transactions/drawer/TransactionDrawerDeleteAttachmentConfirmationModal';
import TransactionDrawerHeaderStatusButtons from 'components/transactions/drawer/TransactionDrawerHeaderStatusButtons';
import TransactionDrawerKeyboardControls from 'components/transactions/drawer/TransactionDrawerKeyboardControls';
import TransactionDrawerMenu from 'components/transactions/drawer/TransactionDrawerMenu';

import useHouseholdPreferences from 'common/lib/hooks/household/useHouseholdPreferences';
import useUploadTransactionAttachment from 'common/lib/hooks/transactions/useUploadTransactionAttachment';
import useCurrentReference from 'common/lib/hooks/useCurrentReference';
import useItemArray from 'common/lib/hooks/useItemArray';
import useQuery from 'common/lib/hooks/useQuery';
import { spacing } from 'common/lib/theme/dynamic';
import { getProgressPercent } from 'common/lib/upload';
import { getFileExtension } from 'common/utils/File';
import useSplitTransactionModal from 'lib/hooks/transactions/useSplitTransactionModal';
import useModal from 'lib/hooks/useModal';
import { transactionAttachmentToFileData } from 'lib/transactions/Attachments';

import { MAX_FILE_SIZE_BYTES } from 'common/constants/transaction';
import { UPDATE_TRANSACTION_REFETCH_QUERIES } from 'constants/graphql';
import routes from 'constants/routes';

import { gql } from 'common/generated/gql';

const MAX_ATTACHMENT_COUNT = 3;
const MAX_FILE_SIZE_STRING = prettyBytes(MAX_FILE_SIZE_BYTES);

type Props = {
  id: GraphQlUUID;
  previousTransactionId: GraphQlUUID | null;
  nextTransactionId: GraphQlUUID | null;
  setId: (id: GraphQlUUID) => void;
  onClose: () => void;
  isReviewModeActive?: boolean;
};

const StyledSideDrawer = styled(SideDrawer)`
  ${SideDrawerNavigationHeader} {
    padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.default};
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  pointer-events: none;
  padding: ${spacing.xlarge};
`;

const getErrorMessageForCode = (code: FileError['code'], file: File) => {
  let message;
  switch (code) {
    case 'file-too-large':
      message = `file ${file.name} is larger than ${MAX_FILE_SIZE_STRING}`;
      break;
    case 'too-many-files':
      message = `transaction can not have more than ${MAX_ATTACHMENT_COUNT} attachments`;
      break;
    case 'file-invalid-type':
      message = `file ${file.name} is an invalid type. Please upload an image or PDF.`;
      break;
    default:
      message = 'unknown error';
  }

  return `Unable to attach files to transaction: ${message}`;
};

const TransactionDrawerContainer = ({
  id,
  previousTransactionId,
  nextTransactionId,
  setId,
  onClose,
  isReviewModeActive,
}: Props) => {
  const history = useHistory();
  const {
    data,
    isLoadingInitialData,
    refetch: refetchTransaction,
  } = useQuery(QUERY, {
    variables: { id, redirectPosted: true },
  });
  const isLoading = isLoadingInitialData || data?.getTransaction?.id !== id;

  const { householdPreferences } = useHouseholdPreferences();

  const [updateTransaction] = useMutation(UPDATE_MUTATION, {
    refetchQueries: ['AccountDetails_getAccount', ...UPDATE_TRANSACTION_REFETCH_QUERIES],
  });

  const [deleteTransactionAttachment] = useMutation(DELETE_ATTACHMENT_MUTATION);
  const [setTransactionTags] = useMutation(SET_TRANSACTION_TAGS_MUTATION);

  const uploadTransactionAttachment = useUploadTransactionAttachment(id);
  const [SplitModal, { open: openSplitModal, isOpen: isSplitModalOpen }] =
    useSplitTransactionModal(onClose);
  const [errorText, setErrorText] = useState<string | null>(null);
  const [
    DeleteAttachmentModal,
    {
      open: openDeleteAttachmentModal,
      close: closeDeleteAttachmentModal,
      context: deleteModalContext,
    },
  ] = useModal<{ attachmentId: GraphQlUUID | undefined; remove: () => void }>();

  const transaction = data?.getTransaction;
  const { attachments, merchant, pending } = transaction ?? {};

  const [files, addFile, _getItem] = useItemArray<FileData>(
    attachments?.map(transactionAttachmentToFileData) || null,
  );
  const getItem = useCurrentReference(_getItem);

  const maxAttachmentsReached = files.length >= MAX_ATTACHMENT_COUNT;

  const onDropAccepted = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length + files.length > MAX_ATTACHMENT_COUNT) {
        setErrorText(getErrorMessageForCode('too-many-files', acceptedFiles[0]));
        return;
      }
      setErrorText(null);

      acceptedFiles.forEach(async (file: File) => {
        const key = addFile({
          name: file.name,
          sizeBytes: file.size,
          displaySize: prettyBytes(file.size),
          progressPercent: 0,
          extension: getFileExtension(file.name),
        });

        try {
          const attachment = await uploadTransactionAttachment(file, (e: ProgressEvent) => {
            const progress = getProgressPercent(e);
            const item = getItem.current(key);
            if (RA.isNotNil(item)) {
              item.modify(R.assoc('progressPercent', progress));
            }
          });
          const { id: attachmentId, originalAssetUrl } = attachment;

          const fileItem = getItem.current(key);
          if (R.isNil(fileItem)) {
            throw new Error("Item doesn't exist");
          }

          fileItem.modify(R.mergeLeft({ attachmentId, originalAssetUrl }));
        } catch (error) {
          const fileItem = getItem.current(key);
          if (RA.isNotNil(fileItem)) {
            fileItem.remove();
          }
          setErrorText((error as Error).message);
        }
      });
    },
    [addFile, getItem, uploadTransactionAttachment, files, setErrorText],
  );

  const onDropRejected = useCallback(
    (rejections: FileRejection[]) => {
      const [firstRejection] = rejections;
      const [firstError] = firstRejection.errors;
      const { file: firstFile } = firstRejection;
      setErrorText(getErrorMessageForCode(firstError.code, firstFile));
    },
    [setErrorText],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openUploadDialog,
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    maxSize: MAX_FILE_SIZE_BYTES,
    maxFiles: 3,
    // Removed 'image/*' as we don't want to include .gif extensions
    accept: [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/heic',
      'image/heif',
      'image/avif',
      'image/webp',
      'image/tiff',
      'image/bmp',
      'application/pdf',
    ],
    preventDropOnDocument: true,
    noClick: true,
  });

  useEffect(() => {
    if (!isLoadingInitialData) {
      const transactionId = data?.getTransaction?.id || '';
      if (transactionId && transactionId !== id) {
        history.replace(routes.transactions.details({ id: transactionId }));
      }
    }
  }, [isLoadingInitialData]);

  const [
    DeleteTransactionConfirmationModal,
    { open: openDeleteTransactionModal, close: closeDeleteTransactionModal },
  ] = useModal();

  const onClickDeleteAttachment = useCallback(
    async (attachmentId: GraphQlUUID | undefined, remove: () => void) => {
      if (attachmentId) {
        await deleteTransactionAttachment({
          variables: { id: attachmentId },
          update: (cache) => {
            cache.evict({
              id: cache.identify({
                __typename: 'TransactionAttachment',
                id: attachmentId,
              }),
            });
          },
        });
      }
      remove();
      setErrorText(null);
    },
    [deleteTransactionAttachment],
  );

  const isEditingEnabled =
    !pending || (pending && householdPreferences?.pendingTransactionsCanBeEdited);
  const isEditingDisabled = !isEditingEnabled;
  const isKeyboardControlsDisabled = isSplitModalOpen;

  return (
    <>
      <StyledSideDrawer
        title={
          transaction && !isLoading ? (
            <TransactionDrawerHeaderStatusButtons
              transaction={transaction}
              updateTransaction={updateTransaction}
              key={transaction.id}
            />
          ) : null
        }
        extraNode={
          !!merchant &&
          !isEditingDisabled && (
            <TransactionDrawerMenu
              transaction={transaction}
              updateTransaction={updateTransaction}
              refetchTransaction={refetchTransaction}
              onClickDelete={openDeleteTransactionModal}
              onClickSplit={openSplitModal}
            />
          )
        }
        left={
          !isKeyboardControlsDisabled && (
            <LeftContainer>
              <TransactionDrawerKeyboardControls
                setTransactionId={setId}
                previousTransactionId={previousTransactionId}
                nextTransactionId={nextTransactionId}
              />
            </LeftContainer>
          )
        }
        onClose={onClose}
        showBorder
      >
        <TransactionDrawerBody
          {...getRootProps()}
          transaction={transaction}
          household={data?.myHousehold}
          disableUploads={maxAttachmentsReached}
          errorText={errorText}
          files={files}
          getInputProps={getInputProps}
          isDragActive={isDragActive}
          isLoadingInitialData={isLoading}
          onRemoveAttachmentClick={openDeleteAttachmentModal}
          onUploadClick={openUploadDialog}
          openSplitModal={openSplitModal}
          setTransactionId={setId}
          transactionId={id}
          updateTransaction={updateTransaction}
          setTransactionTags={setTransactionTags}
          isEditingDisabled={isEditingDisabled}
          onClickDelete={openDeleteTransactionModal}
          isReviewModeActive={isReviewModeActive}
        />
        <SplitModal />
        <DeleteAttachmentModal>
          {deleteModalContext && (
            <TransactionDrawerDeleteAttachmentConfirmationModal
              {...deleteModalContext}
              onCancelClick={closeDeleteAttachmentModal}
              onDeleteClick={onClickDeleteAttachment}
            />
          )}
        </DeleteAttachmentModal>
      </StyledSideDrawer>

      {transaction?.id && (
        <DeleteTransactionConfirmationModal>
          <DeleteTransactionConfirmation
            transactionId={transaction.id}
            afterDelete={onClose}
            closeModal={closeDeleteTransactionModal}
          />
        </DeleteTransactionConfirmationModal>
      )}
    </>
  );
};

export const TRANSACTION_DRAWER_FIELDS_FRAGMENT = gql(/* GraphQL */ `
  fragment TransactionDrawerFields on Transaction {
    id
    amount
    pending
    isRecurring
    date
    originalDate
    hideFromReports
    needsReview
    reviewedAt
    reviewedByUser {
      id
      name
    }
    plaidName
    notes
    hasSplitTransactions
    isSplitTransaction
    isManual
    splitTransactions {
      id
      ...TransactionDrawerSplitMessageFields
    }
    originalTransaction {
      id
      ...OriginalTransactionFields
    }
    attachments {
      id
      extension
      sizeBytes
      filename
      originalAssetUrl
    }
    account {
      id
      hideTransactionsFromReports
      ...AccountLinkFields
    }
    category {
      id
    }
    goal {
      id
    }
    merchant {
      id
      name
      transactionCount
      logoUrl
      recurringTransactionStream {
        id
        frequency
      }
    }
    tags {
      id
      name
      color
      order
    }
    needsReviewByUser {
      id
    }
    ...TransactionOverviewFields
  }
`);

const QUERY = gql(/* GraphQL */ `
  query GetTransactionDrawer($id: UUID!, $redirectPosted: Boolean) {
    getTransaction(id: $id, redirectPosted: $redirectPosted) {
      id
      ...TransactionDrawerFields
    }
    myHousehold {
      id
      users {
        id
        name
      }
    }
  }
`);

const UPDATE_MUTATION = gql(/* GraphQL */ `
  mutation Web_TransactionDrawerUpdateTransaction($input: UpdateTransactionMutationInput!) {
    updateTransaction(input: $input) {
      transaction {
        id
        ...TransactionDrawerFields
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

const DELETE_ATTACHMENT_MUTATION = gql(`
  mutation Web_TransactionDrawerDeleteAttachment($id: UUID!) {
    deleteTransactionAttachment(id: $id) {
      deleted
    }
  }
`);

export const SET_TRANSACTION_TAGS_MUTATION = gql(`
  mutation Web_SetTransactionTags($input: SetTransactionTagsInput!) {
    setTransactionTags(input: $input) {
      errors {
        ...PayloadErrorFields
      }
      transaction {
        id
        tags {
          id
        }
      }
    }
  }
`);

export default TransactionDrawerContainer;
