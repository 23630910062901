import React from 'react';
import styled from 'styled-components';

import Icon from 'components/lib/ui/Icon';

import { color, spacing } from 'common/lib/theme/dynamic';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';

import * as COPY from 'common/constants/copy';

const Root = styled.div`
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: row;
  max-width: 361px;
  width: 100%;
  align-items: stretch;
`;

const TitleTimelineIcon = styled(Icon).attrs(({ name }) => ({
  size: 16,
  name,
}))`
  color: ${({ theme }) => theme.color.white};
  position: absolute;
  left: 8px;
  transform: translateY(8px) translateX(0.5px); /* translateX to visually center icon in circle */
`;

const TimelineDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const TimelineDescriptionItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: ${spacing.xlarge};

  &:last-of-type {
    margin-bottom: ${spacing.xxlarge};
  }
`;

const TimelineTitle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.color.text};

  &::before {
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    left: 0;
    background: ${color.orange};
    outline: 4px solid ${color.white};
  }
`;

const TimelineText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.book};
  color: ${color.gray11};
  text-wrap: balance;
  margin-top: ${spacing.xxsmall};
`;

const Timeline = styled.div`
  width: 32px;
  min-width: 32px;
  border-radius: ${({ theme }) => theme.radius.pill};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: ${spacing.gutter};
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 14px;
    bottom: -8px;
    right: 15px;
    width: 2px;
    background: linear-gradient(
      180deg,
      ${color.red9} 5.57%,
      ${color.orangeDark} 34.36%,
      ${color.red8} 71.91%,
      rgba(255, 104, 95, 0.61) 80.33%,
      rgba(255, 104, 95, 0.18) 90.17%,
      rgba(255, 104, 95, 0) 99.89%
    );
  }
`;

type Props = {
  className?: string;
  trialDays: number;
  trialEndDate: string;
};

const PremiumPlanTimeline = ({ className, trialDays, trialEndDate }: Props) => {
  const isGoalOrientedSignUpTestOn = useIsFeatureFlagOn('ab-test-goal-oriented-signup', {
    trackImpression: true,
  });

  return isGoalOrientedSignUpTestOn ? (
    <Root className={className}>
      <Timeline />
      <TimelineDescription>
        <TimelineDescriptionItem>
          <TimelineTitle>
            <TitleTimelineIcon name="unlock" />
            Today
          </TimelineTitle>
          <TimelineText>Start your free trial and see all your finances in one place.</TimelineText>
        </TimelineDescriptionItem>
        <TimelineDescriptionItem>
          <TimelineTitle>
            <TitleTimelineIcon name="sun" />
            Within a week
          </TimelineTitle>
          <TimelineText>
            You’ll begin experiencing clarity, confidence, and peace of mind in managing your money.
          </TimelineText>
        </TimelineDescriptionItem>
        <TimelineDescriptionItem>
          <TimelineTitle>
            <TitleTimelineIcon name="bell" />
            Every Day
          </TimelineTitle>
          <TimelineText>
            You can keep track of your remaining trial days right on your dashboard. You can cancel
            anytime.
          </TimelineText>
        </TimelineDescriptionItem>
        <TimelineDescriptionItem>
          <TimelineTitle>
            <TitleTimelineIcon name="award" />
            {COPY.PREMIUM.TIMELINE.PROGRESS.IN_X_DAYS.TITLE(trialDays)}
          </TimelineTitle>
          <TimelineText>
            {COPY.PREMIUM.TIMELINE.PROGRESS.IN_X_DAYS.DESCRIPTION(trialEndDate)}
          </TimelineText>
        </TimelineDescriptionItem>
      </TimelineDescription>
    </Root>
  ) : (
    <Root className={className}>
      <Timeline />
      <TimelineDescription>
        <TimelineDescriptionItem>
          <TimelineTitle>
            <TitleTimelineIcon name="unlock" />
            {COPY.PREMIUM.TIMELINE.PROGRESS.TODAY.TITLE}
          </TimelineTitle>
          <TimelineText>{COPY.PREMIUM.TIMELINE.PROGRESS.TODAY.DESCRIPTION}</TimelineText>
        </TimelineDescriptionItem>
        <TimelineDescriptionItem>
          <TimelineTitle>
            <TitleTimelineIcon name="bell" />
            {COPY.PREMIUM.TIMELINE.PROGRESS.IN_X_DAYS.TITLE(trialDays - 1)}
          </TimelineTitle>
          <TimelineText>{COPY.PREMIUM.TIMELINE.PROGRESS.BEFORE_LAST_DAY.DESCRIPTION}</TimelineText>
        </TimelineDescriptionItem>
        <TimelineDescriptionItem>
          <TimelineTitle>
            <TitleTimelineIcon name="award" />
            {COPY.PREMIUM.TIMELINE.PROGRESS.IN_X_DAYS.TITLE(trialDays)}
          </TimelineTitle>
          <TimelineText>
            {COPY.PREMIUM.TIMELINE.PROGRESS.IN_X_DAYS.DESCRIPTION(trialEndDate)}
          </TimelineText>
        </TimelineDescriptionItem>
      </TimelineDescription>
    </Root>
  );
};

export default PremiumPlanTimeline;
