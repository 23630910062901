import * as R from 'ramda';
import { useCallback } from 'react';

import type Api from 'common/lib/api';
import getUserApi from 'common/lib/api/user';
import type { UserAttributionData } from 'common/lib/api/user';
import useToggle from 'common/lib/hooks/useToggle';
import type { UserState } from 'common/state/user/reducer';

import type { AuthProvider, AuthEndpointPayload } from 'common/types/auth';

type ExtraData = Partial<{ client_platform: string; trusted_device: boolean }>;

type Options<TPayload, TResponse> = {
  provider: AuthProvider;
  apiWrapper: Api;
  getUserAttributionData?: () => UserAttributionData;
  fingerprint?: string;
  getExtraData?: () => ExtraData;
  onShouldConfirmAccountCreation: (response: TResponse, data: TPayload) => void;
  onConfirmedSignup: (response: TResponse, data: TPayload) => void;
  onUserSignedIn: (response: TResponse, data: TPayload) => void;
  onError?: (error?: { detail: string; code: string }) => void;
};

/**
 * Base hook to call a 3rd party authentication endpoint on our backend. It should be extended
 * to perform the required actions based on platform (web, mobile).
 */
const useAuthProviderBase = <
  TPayload extends AuthEndpointPayload,
  TResponse extends UserState & { exists?: boolean; is_new: boolean } = UserState & {
    exists?: boolean;
    is_new: boolean;
  },
>({
  provider,
  apiWrapper,
  getUserAttributionData,
  fingerprint,
  getExtraData,
  onConfirmedSignup,
  onShouldConfirmAccountCreation,
  onUserSignedIn,
  onError,
}: Options<TPayload, TResponse>) => {
  const [isLoading, { setOn: setIsSubmitting, setOff: disableIsSubmitting }] = useToggle(false);

  const authenticate = useCallback(
    async (payload: TPayload) => {
      setIsSubmitting();

      try {
        const extraData = getExtraData?.() ?? {};
        const { skipConfirmation = false } = payload;
        const response: TResponse = await getUserApi(apiWrapper).authenticateUserWithProvider(
          provider,
          {
            ...payload,
            ...extraData,
            skipConfirmation,
          },
          getUserAttributionData?.(),
          fingerprint,
        );

        const shouldConfirm = response.exists === false && !skipConfirmation;
        const isSignupConfirmed = response.is_new === true && skipConfirmation;

        // User doesn't have account and confirmed sign-up, redirect them
        if (isSignupConfirmed) {
          onConfirmedSignup(R.omit(['is_new'], response) as TResponse, payload);
          return;
        }

        // Doesn't have an account yet, redirect to confirmation screen
        if (shouldConfirm) {
          onShouldConfirmAccountCreation(response, payload);
          return;
        }

        // Regular user signing in
        onUserSignedIn(response, payload);
      } catch (e: any) {
        onError?.(e?.data ?? { detail: e });
      } finally {
        disableIsSubmitting();
      }
    },
    [
      provider,
      fingerprint,
      getUserAttributionData,
      apiWrapper,
      onError,
      disableIsSubmitting,
      setIsSubmitting,
      onShouldConfirmAccountCreation,
      onUserSignedIn,
      onConfirmedSignup,
      getExtraData,
    ],
  );

  return { authenticate, isLoading };
};

export default useAuthProviderBase;
