import * as RA from 'ramda-adjunct';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import OnboardingAccoladesBanner from 'components/onboarding/OnboardingAccoladesBanner';
import OnboardingFooterNav from 'components/onboarding/OnboardingFooterNav';
import OnboardingSubtitle from 'components/onboarding/OnboardingSubtitle';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';
import { onboardingContentContainerMixin } from 'components/onboarding/style';
import PremiumPlanComparisonTable from 'components/premium/PremiumPlanComparisonTable';
import PremiumPlanTimeline from 'components/premium/PremiumPlanTimeline';

import { getDaysLeftOfTrial } from 'common/lib/billing/Billing';
import usePremiumStripeOfferings from 'common/lib/hooks/premium/usePremiumStripeOfferings';
import useTrialStatusQuery from 'common/lib/hooks/premium/useTrialStatusQuery';
import { spacing } from 'common/lib/theme/dynamic';
import { isoDateToMonthAndDay } from 'common/utils/date';
import { trackPremiumScreenViewed } from 'lib/analytics/premium';
import typewriter from 'lib/analytics/typewriter';
import { useFeatureFlag } from 'lib/hooks';
import { getUser } from 'selectors';
import { getTemporaryPromoCode } from 'state/onboarding/selectors';

import * as COPY from 'common/constants/copy';
import { ReferredFeatureAnalyticsName } from 'common/constants/premium';

const StyledOnboardingTitle = styled(OnboardingTitle)`
  margin-top: ${spacing.xxlarge};
`;

const StyledOnboardingAccoladesBanner = styled(OnboardingAccoladesBanner)`
  margin-top: -${({ theme }) => theme.spacing.xxlarge};
  margin-bottom: 0;
  width: calc(100% + ${({ theme }) => parseInt(theme.spacing.default) * 2}px);
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
`;

const StyledPremiumPlanComparisonTable = styled(PremiumPlanComparisonTable)`
  width: 100%;
  max-width: 437px;
  margin-top: ${spacing.xxlarge};
`;

const StyledPremiumPlanTimeline = styled(PremiumPlanTimeline)`
  ${onboardingContentContainerMixin}
  margin-top: ${spacing.xxlarge};
`;

type Props = {
  next: () => void;
  skipToComplete?: () => void;
};

const OnboardingPremiumUpsell = ({ next, skipToComplete }: Props) => {
  // this is a fake experiment - we're doing this to ensure an anonymous experiment works end-to-end
  useFeatureFlag('ab-test-fake-experiment', {
    trackImpression: true,
    isAnonymous: true,
  });

  const temporaryPromoCode = useSelector(getTemporaryPromoCode);
  const { plans } = usePremiumStripeOfferings({
    prefetch: true,
    promoCode: temporaryPromoCode,
  }); // Prefetch offerings since they're shown on the next page
  const { trialDurationDays, hasPremiumTrialAvailable } = useTrialStatusQuery();

  useEffect(() => {
    if (temporaryPromoCode) {
      typewriter.webAppPromoCodeApplied({
        promoCode: temporaryPromoCode,
        auto: true,
      });
    }
  }, [temporaryPromoCode]);

  const trialDays = useMemo(() => {
    // Check if any of the plans have a new trial date (changed by the promo code) and use the first one
    const planWithNewTrial = plans.find(({ newTrialEndsAt }) => newTrialEndsAt);

    return RA.isNotNil(planWithNewTrial)
      ? getDaysLeftOfTrial(planWithNewTrial.newTrialEndsAt) ?? trialDurationDays
      : trialDurationDays;
  }, [plans, trialDurationDays]);

  useEffect(() => {
    trackPremiumScreenViewed(ReferredFeatureAnalyticsName.Signup);
  }, []);

  const trialEndDate = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() + trialDays);
    return isoDateToMonthAndDay(date.toISOString());
  }, [trialDays]);

  const user = useSelector(getUser);

  const regularSignupPremiumUpsellScreen = (
    <>
      <StyledOnboardingAccoladesBanner />
      <StyledOnboardingTitle>
        {COPY.PREMIUM.PLAN_COMPARISION.TITLE.TRIAL_UNAVAILABLE}
      </StyledOnboardingTitle>
      <StyledPremiumPlanComparisonTable promoCode={temporaryPromoCode} />
      <OnboardingFooterNav>
        <StyledPrimaryButton size="large" onClick={next}>
          <ButtonIcon name="diamond" />
          <span>{COPY.PREMIUM.UPGRADE_CTA.TRIAL_AVAILABLE}</span>
        </StyledPrimaryButton>
      </OnboardingFooterNav>
    </>
  );

  const timelineSignupPremiumUpsellScreen = (
    <>
      <StyledOnboardingAccoladesBanner />
      <StyledOnboardingTitle>{COPY.PREMIUM.TIMELINE.TITLE}</StyledOnboardingTitle>
      <OnboardingSubtitle>{user.email}</OnboardingSubtitle>
      <StyledPremiumPlanTimeline trialDays={trialDays} trialEndDate={trialEndDate} />
      <OnboardingFooterNav>
        <StyledPrimaryButton size="large" onClick={next}>
          {COPY.PREMIUM.TIMELINE.CONTINUE_BUTTON}
        </StyledPrimaryButton>
      </OnboardingFooterNav>
    </>
  );

  return hasPremiumTrialAvailable
    ? timelineSignupPremiumUpsellScreen
    : regularSignupPremiumUpsellScreen;
};

export default OnboardingPremiumUpsell;
