import { css } from 'styled-components';

import { color, radius } from 'common/lib/theme/dynamic';

export const onboardingSurveyCheckboxIndicatorStyles = css`
  border-radius: ${radius.small};
`;

export const onboardingSurveyCheckboxIconSettings = { name: 'check', size: 16 };

export const onboardingSurveyCheckboxIconStyles = css`
  color: ${color.orange};
`;
