import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import type { DefaultTheme } from 'styled-components';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import FlexItem from 'components/lib/ui/FlexItem';
import FreeTrialDurationPanel from 'components/sidebar/FreeTrialDurationPanel';
import NavBarLink from 'components/sidebar/NavBarLink';
import ReferralLinkButton from 'components/sidebar/ReferralLinkButton';
import SideBarLink from 'components/sidebar/SideBarLink';
import SidebarYellowLinkButton from 'components/sidebar/SidebarYellowLinkButton';
import WhatsNewPopup from 'components/sidebar/WhatsNewPopup';

import useHouseholdPreferences from 'common/lib/hooks/household/useHouseholdPreferences';
import useTrialStatusQuery from 'common/lib/hooks/premium/useTrialStatusQuery';
import { spacing, variables } from 'common/lib/theme/dynamic';
import { getIsSponsor } from 'common/state/user/selectors';
import { track } from 'lib/analytics/segment';
import useReportsSavedViewsWalkthrough from 'lib/hooks/reports/useReportsSavedViewsWalkthrough';
import useDemoHousehold from 'lib/hooks/useDemoHousehold';
import useTheme from 'lib/hooks/useTheme';
import { useWhatsNew } from 'lib/hooks/useWhatsNew';

import { WhatsNewEventNames } from 'common/constants/analytics';
import * as COPY from 'common/constants/copy';
import routes from 'constants/routes';

import type { Web_GetSidebarDataQuery } from 'common/generated/graphql';
import { PaymentPeriod, SubscriptionDetailsPaymentSource } from 'common/generated/graphql';

const SecondaryNavBarLink = styled(NavBarLink)`
  color: ${variables.color.content.secondary};
`;

const PopupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.small};
  padding-bottom: ${spacing.small};
`;

const getRecurringBadge = (
  pendingStreamsCount: number,
  shouldShowWhatsNew: boolean,
  theme: DefaultTheme,
) => {
  if (shouldShowWhatsNew) {
    return {
      color: theme.color.orange,
      textColor: theme.color.textWhite,
      content: 'New',
    };
  }

  return {
    color: theme.color.orange,
    textColor: theme.color.textWhite,
    content: pendingStreamsCount > 0 ? pendingStreamsCount.toString() : undefined,
  };
};

const getBudgetingBadge = (shouldShowWhatsNew: boolean, theme: DefaultTheme) => {
  if (shouldShowWhatsNew) {
    return {
      color: theme.color.orange,
      textColor: theme.color.textWhite,
      content: 'New',
    };
  }
};

const getReportsBadge = (shouldShowBadge: boolean, theme: DefaultTheme) => {
  if (shouldShowBadge) {
    return {
      color: theme.color.orange,
      textColor: theme.color.textWhite,
      content: 'New',
    };
  }
};

type Props = {
  subscription?: Web_GetSidebarDataQuery['subscription'] | null;
  subscriptionOffering?: Web_GetSidebarDataQuery['subscriptionOffering'] | null;
  onSupportButtonClick?: () => Promise<void> | void;
  onUpgradeButtonClick?: () => Promise<void> | void;
  pendingStreamsCount: number;
  isSidebarExpanded?: boolean;
};

const SideBarDefaultContent = ({
  subscription,
  subscriptionOffering,
  onSupportButtonClick,
  onUpgradeButtonClick,
  pendingStreamsCount,
  isSidebarExpanded,
}: Props) => {
  const history = useHistory();
  const theme = useTheme();
  const { isDemoHousehold } = useDemoHousehold();
  const { whatsNew, dismissWhatsNew } = useWhatsNew();
  const [shouldShowReportsBadge] = useReportsSavedViewsWalkthrough();

  const {
    paymentSource,
    billingPeriod,
    hasPremiumEntitlement: isSubscriptionActive,
    isOnFreeTrial: isTrialing,
    nextPaymentAmount,
    activeSponsorship,
  } = subscription ?? {};

  const isSponsored = !!activeSponsorship;

  const { plans } = subscriptionOffering ?? {};

  const { hasPremiumTrialAvailable } = useTrialStatusQuery();
  const isSponsor = useSelector(getIsSponsor);

  const { householdPreferences } = useHouseholdPreferences();
  const { aiAssistantEnabled } = householdPreferences ?? {};

  // we're experimenting the annual upsell only with users with monthly active subscription via Stripe.
  const isAnnualUpsellAvailable =
    !!subscription &&
    !!isSubscriptionActive &&
    billingPeriod === PaymentPeriod.MONTHLY &&
    paymentSource === SubscriptionDetailsPaymentSource.STRIPE &&
    !!nextPaymentAmount &&
    nextPaymentAmount === 14.99;

  const budgetingBadge = getBudgetingBadge(whatsNew?.feature === 'flex-budgeting', theme);
  const reportsBadge = getReportsBadge(shouldShowReportsBadge, theme);
  const recurringBadge = getRecurringBadge(
    pendingStreamsCount,
    whatsNew?.feature === 'bill-tracking',
    theme,
  );

  return (
    <>
      <FlexItem>
        <SideBarLink name="Dashboard" exact />
        <SideBarLink name="Accounts" />
        <SideBarLink name="Transactions" />
        <SideBarLink name="Cash Flow" />
        <SideBarLink
          name="Reports"
          badge={reportsBadge?.content}
          badgeColor={reportsBadge?.color}
          badgeTextColor={reportsBadge?.textColor}
        />
        <SideBarLink
          name="Budget"
          isActive={(match, location) => !!match || location.pathname === routes.planning()}
          badge={budgetingBadge?.content}
          badgeColor={budgetingBadge?.color}
          badgeTextColor={budgetingBadge?.textColor}
        />
        <SideBarLink
          name="Recurring"
          badge={recurringBadge.content}
          badgeColor={recurringBadge.color}
          badgeTextColor={recurringBadge.textColor}
        />
        <SideBarLink name="Goals" />
        <SideBarLink name="Investments" />
        {!isSponsored && !isSponsor && <SideBarLink name="Advice" />}
        {aiAssistantEnabled && <SideBarLink name="Assistant" />}
      </FlexItem>
      <FlexItem grow={1} />
      <PopupsContainer>
        <AnimatePresence>
          {!!whatsNew && (
            <WhatsNewPopup
              description={whatsNew.description}
              callToAction={whatsNew.callToAction}
              image={whatsNew.image}
              onDismiss={() => {
                track(WhatsNewEventNames.WhatsNewDismissed, whatsNew);
                dismissWhatsNew();
              }}
              onCheckItOut={() => {
                track(WhatsNewEventNames.WhatsNewClicked, whatsNew);

                if (whatsNew.route.startsWith('http')) {
                  window.open(whatsNew.route, '_blank');
                  dismissWhatsNew();
                } else {
                  history.push(whatsNew.route);
                }
              }}
            />
          )}
        </AnimatePresence>
        {isTrialing && subscription && !isDemoHousehold && isSidebarExpanded && (
          <FreeTrialDurationPanel data={subscription} />
        )}
      </PopupsContainer>
      <FlexItem>
        <Switch>
          <Case when={isSponsor}>
            <SecondaryNavBarLink to={routes.advisorPortal.clients()} iconName="users">
              Professional Portal
            </SecondaryNavBarLink>
          </Case>
          {/* Show the experiment with the annual upsell */}
          <Case when={isAnnualUpsellAvailable}>
            <SidebarYellowLinkButton plans={plans} />
          </Case>
          {/* Show the Referral button without going through the split - we don't want annual/lifetime users on this experiment*/}
          <Case when={!isAnnualUpsellAvailable}>
            <ReferralLinkButton />
          </Case>
          <Case when={!isTrialing}>
            <NavBarLink onClick={onUpgradeButtonClick} iconName="diamond">
              {hasPremiumTrialAvailable
                ? COPY.PREMIUM.UPGRADE_CTA.TRIAL_AVAILABLE
                : COPY.PREMIUM.UPGRADE_CTA.TRIAL_UNAVAILABLE}
            </NavBarLink>
          </Case>
          <Case default>{null}</Case>
        </Switch>
        <SecondaryNavBarLink iconName="message-circle" onClick={onSupportButtonClick} color="blue">
          Help & Support
        </SecondaryNavBarLink>
      </FlexItem>
    </>
  );
};

export default SideBarDefaultContent;
