import React from 'react';

import Form from 'components/lib/form/Form';
import OnboardingAbstractField from 'components/onboarding/OnboardingAbstractField';
import type { AbstractFieldChildrenRenderProps } from 'components/onboarding/OnboardingAbstractField';
import OnboardingFormContainer from 'components/onboarding/OnboardingFormContainer';
import OnboardingFormSubmitButton from 'components/onboarding/OnboardingFormSubmitButton';
import OnboardingSurveyRadio from 'components/onboarding/OnboardingSurveyRadio';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';
import { usePreRegistration } from 'components/onboarding/preRegistration/PreRegistrationContext';
import { PRE_REGISTRATION_FINANCES_COMPLEXITY } from 'components/onboarding/preRegistration/constants';

import { track } from 'lib/analytics/segment';

import { OnboardingEventNames } from 'common/constants/analytics';

type Props = {
  next: () => void;
};

const PreRegistrationFinancesComplexity = ({ next }: Props) => {
  const { setFinancesComplexity } = usePreRegistration();

  return (
    <>
      <OnboardingTitle>{PRE_REGISTRATION_FINANCES_COMPLEXITY.question}</OnboardingTitle>
      <OnboardingFormContainer>
        <Form
          onSubmit={async (values: Record<string, boolean>) => {
            const selectedOption = PRE_REGISTRATION_FINANCES_COMPLEXITY.options.find(
              ({ name }) => values[name],
            );
            if (selectedOption) {
              setFinancesComplexity(selectedOption.name);
            }

            track(OnboardingEventNames.PreSurveyRegistrationAnswered, {
              question_id: PRE_REGISTRATION_FINANCES_COMPLEXITY.id,
              question: PRE_REGISTRATION_FINANCES_COMPLEXITY.question,
              answer: selectedOption?.name,
            });

            track(OnboardingEventNames.PreSurveyRegistrationCompleted);

            next();
          }}
        >
          {PRE_REGISTRATION_FINANCES_COMPLEXITY.options.map(({ name, displayName }) => (
            <OnboardingAbstractField name={name} hideLabel key={name}>
              {({ value, setFieldValue }: AbstractFieldChildrenRenderProps) => (
                <OnboardingSurveyRadio
                  value={value}
                  onChange={(value) => {
                    // unselect all options so only one is selected at a time
                    PRE_REGISTRATION_FINANCES_COMPLEXITY.options.forEach((option) => {
                      setFieldValue(option.name, false);
                    });
                    setFieldValue(name, value);
                  }}
                >
                  {displayName}
                </OnboardingSurveyRadio>
              )}
            </OnboardingAbstractField>
          ))}
          <OnboardingFormSubmitButton>Continue</OnboardingFormSubmitButton>
        </Form>
      </OnboardingFormContainer>
    </>
  );
};

export default PreRegistrationFinancesComplexity;
