import type { DetailedAPIError } from 'common/errors';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';

import Form from 'components/lib/form/Form';
import TextField from 'components/lib/form/TextField';
import Banner from 'components/lib/ui/Banner';
import OnboardingFormContainer from 'components/onboarding/OnboardingFormContainer';
import OnboardingFormSubmitButton from 'components/onboarding/OnboardingFormSubmitButton';
import OnboardingSubtitle from 'components/onboarding/OnboardingSubtitle';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';
import PasswordValidationGroup, {
  MergedPasswordSchema,
} from 'components/onboarding/PasswordValidationGroup';

import { login } from 'actions';
import getUserApi from 'common/lib/api/user';
import api from 'lib/api';
import { useDispatch, useQueryParam } from 'lib/hooks';
import useUserAttributionData from 'lib/hooks/useUserAttributionData';

import { ONBOARDING } from 'common/constants/copy';
import routes from 'constants/routes';

import { ErrorCode } from 'common/generated/graphql';

const INITIAL_VALUES = {
  name: '',
  password: '',
};

const VALIDATION_SCHEMA = Yup.object().shape({
  password: MergedPasswordSchema,
});

const CustomErrorBanner = styled(Banner)`
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const LogInLink = styled(Link)`
  text-decoration: underline;
  color: inherit;

  :hover {
    color: inherit;
  }
`;

type Props = {
  next: () => void;
};

const OnboardingCreatePassword = ({ next }: Props) => {
  const dispatch = useDispatch();

  const code = useQueryParam('code') || '';
  const email = useQueryParam('email') || '';
  const { getUserAttributionData } = useUserAttributionData();

  const [showExistingAccountError, setShowExistingAccountError] = useState(false);

  const handleSubmit = async (values: { password: string; name: string }) => {
    const response = await getUserApi(api).verifyUser(
      email,
      code,
      values.name,
      values.password,
      undefined,
      getUserAttributionData(),
    );
    dispatch(login({ signedUpAndVerified: true, ...response }));
    next();
  };

  const handleError = (apiError: DetailedAPIError) => {
    setShowExistingAccountError(apiError.data?.error_code === ErrorCode.USER_ALREADY_SIGNED_UP);
  };

  return (
    <>
      <OnboardingTitle>{ONBOARDING.CREATE_PASSWORD.TITLE}</OnboardingTitle>
      <OnboardingSubtitle>{ONBOARDING.CREATE_PASSWORD.SUBTITLE}</OnboardingSubtitle>
      <OnboardingFormContainer>
        {showExistingAccountError && (
          <CustomErrorBanner type="error">
            You have already signed up. Please <LogInLink to={routes.login()}>log in</LogInLink>
          </CustomErrorBanner>
        )}
        <Form
          initialValues={INITIAL_VALUES}
          overrideValidationSchema={VALIDATION_SCHEMA}
          onSubmit={handleSubmit}
          onError={handleError}
        >
          <TextField
            name="password"
            placeholder="New password"
            password
            required
            autoComplete="new-password"
            onlyShowApiErrors
            autoFocus
            autoFocusOnMobile={false}
          />
          <PasswordValidationGroup />
          <OnboardingFormSubmitButton>
            {ONBOARDING.CREATE_PASSWORD.BUTTON}
          </OnboardingFormSubmitButton>
        </Form>
      </OnboardingFormContainer>
    </>
  );
};

export default OnboardingCreatePassword;
