import prettyBytes from 'pretty-bytes';
import * as R from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { DropzoneOptions, FileError, FileRejection } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import type { AbstractFieldProps } from 'components/lib/form/AbstractField';
import AbstractField from 'components/lib/form/AbstractField';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import AttachmentItem from 'components/transactions/drawer/AttachmentItem';

import type { ValidationOptions } from 'common/lib/form/validation';
import { getAttachmentErrorMessageFromCode } from 'lib/form/validation';

const Root = styled(FlexContainer).attrs({ alignCenter: true, column: true })`
  width: 100%;
  margin-bottom: -${({ theme }) => theme.spacing.xsmall};
`;

const InputContainer = styled(FlexContainer).attrs({ alignCenter: true })<{
  $isDragActive: boolean;
}>`
  width: 100%;
  border-radius: ${({ theme }) => theme.radius.small};
  gap: ${({ theme }) => theme.spacing.small};
`;

const UploadButton = styled(DefaultButton)`
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;

const UploadButtonIcon = styled(Icon).attrs({ name: 'arrow-up', size: 14 })`
  transform: translateY(1px);
`;

const AttachedFilesContainer = styled(FlexContainer).attrs({
  alignStart: true,
  column: true,
})`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

const PreviewAttachmentItem = styled(AttachmentItem).attrs({
  publicId: 'placeholder',
  useThumbnailPlaceholder: true,
})`
  margin: 0;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.xsmall};
  border-color: ${({ theme }) => theme.color.grayLight};
`;

const getFileKey = (file: File, index: number) => `${file.name}-${index}`;

type Props = Pick<ValidationOptions, 'name'> & {
  className?: string;
  dropzoneOptions?: DropzoneOptions;
  disabled?: boolean;
};

const AttachmentField = ({
  className,
  dropzoneOptions,
  name,
  disabled,
  ...props
}: AbstractFieldProps<Props>) => {
  const { values, setFieldValue, setFieldError, setFieldTouched } = useFormContext();
  const currentValue = useMemo<File[]>(
    () => R.pathOr([], [name], values),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values[name], name],
  );

  const [localUris, setLocalUris] = useState<Record<string, string>>({});
  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      // Revoke all local URIs on unmount to avoid memory leaks
      Object.values(localUris).forEach((uri) => URL.revokeObjectURL(uri));
    };
  }, []);

  const onDropAccepted = useCallback(
    (files: File[]) => {
      setFieldValue(name, [...currentValue, ...files]);
      setFieldError(name, '');
      setLocalUris(
        R.mergeLeft(
          files.reduce((acc, file, index) => {
            const key = getFileKey(file, index);
            return { ...acc, [key]: URL.createObjectURL(file) };
          }, {}),
        ),
      );
    },
    [currentValue, name],
  );

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      const errors = R.pipe(
        R.map(R.path<FileError['code']>(['errors', 0, 'code'])),
        R.uniq,
        R.map((code) => getAttachmentErrorMessageFromCode(code, dropzoneOptions?.maxFiles)),
      )(fileRejections);
      setFieldError(name, errors.join('. '));
      setFieldTouched(name, true);
    },
    [name, dropzoneOptions?.maxFiles],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...dropzoneOptions,
    onDropAccepted,
    onDropRejected,
    preventDropOnDocument: true,
    disabled,
  });

  return (
    <AbstractField className={className} name={name} {...props} hideLabel>
      {({ id }) => (
        <Root id={id}>
          <InputContainer {...getRootProps()} $isDragActive={isDragActive}>
            <input {...getInputProps()} />
            <UploadButton disabled={disabled}>
              <UploadButtonIcon />
              <Text>Upload a file</Text>
            </UploadButton>
            <Text size="small" color={isDragActive && !disabled ? 'blue' : 'textLight'}>
              Or drag one here
            </Text>
          </InputContainer>
          <AttachedFilesContainer>
            {currentValue.map((file, index) => {
              const key = getFileKey(file, index);
              return (
                <PreviewAttachmentItem
                  key={key}
                  name={file.name}
                  disabled={disabled}
                  originalAssetUrl={localUris[key]}
                  displaySize={prettyBytes(file.size)}
                  onRemoveClick={() => setFieldValue(name, R.remove(index, 1, currentValue))}
                  progressPercent={100}
                  publicId="placeholder"
                  useThumbnailPlaceholder
                />
              );
            })}
          </AttachedFilesContainer>
        </Root>
      )}
    </AbstractField>
  );
};

export default AttachmentField;
