import React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
  type: 'info' | 'success' | 'warning' | 'error' | 'plain';
  className?: string;
};

const Root = styled.div<Pick<Props, 'type'>>`
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.default};
  border-radius: ${({ theme }) => theme.radius.small};
  background-color: ${({ theme, type }) =>
    ({
      info: theme.color.blueBackground,
      success: theme.color.greenBackground,
      warning: theme.color.yellowBackground,
      error: theme.color.redBackground,
      plain: theme.color.white,
    })[type]};
  color: ${({ theme, type }) =>
    ({
      info: theme.color.blueDark,
      success: theme.color.greenDark,
      warning: '#ae7b14',
      error: theme.color.redDark,
      plain: theme.color.text,
    })[type]};
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.book};
  overflow: auto;
`;

const Banner = ({ children, type, className }: Props) => (
  <Root type={type} className={className}>
    {children}
  </Root>
);

export default Banner;
