import React from 'react';
import styled from 'styled-components';

import InformationList, {
  InformationItem,
  InformationItemIcon,
  InformationListTitle,
} from 'components/lib/ui/InformationList';
import Text from 'components/lib/ui/Text';

import { fontSize, spacing, variables } from 'common/lib/theme/dynamic';

const StyledInformationList = styled(InformationList)`
  background-color: ${variables.color.background.primary};
  padding-bottom: ${spacing.small};

  ${InformationListTitle} {
    font-size: ${fontSize.large};
  }

  ${InformationItemIcon} {
    padding-top: ${spacing.xxsmall};
  }
`;

const DescriptionText = styled.div`
  color: ${variables.color.content.secondary};
  font-size: ${fontSize.small};
`;

export type ReviewSectionProps = {
  title: string;
  items: Array<{
    iconName: string;
    title: string;
    description: string;
  }>;
};

const ReviewSection: React.FC<ReviewSectionProps> = ({ title, items }) => (
  <StyledInformationList title={title}>
    {items.map((item, index) => (
      <InformationItem key={index} iconName={item.iconName} iconSizePx={16}>
        <Text weight="medium">{item.title}</Text>
        <DescriptionText>{item.description}</DescriptionText>
      </InformationItem>
    ))}
  </StyledInformationList>
);

export default ReviewSection;
