import { useMutation } from '@apollo/client';
import type { GraphQlMutationError } from 'common/errors';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import TextField from 'components/lib/form/TextField';
import Banner from 'components/lib/ui/Banner';
import OnboardingFormContainer from 'components/onboarding/OnboardingFormContainer';
import OnboardingFormSubmitButton from 'components/onboarding/OnboardingFormSubmitButton';
import OnboardingSubtitle from 'components/onboarding/OnboardingSubtitle';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';
import { INVITE_PARTNER_MUTATION } from 'components/settings/members/InviteMemberModal';

import { throwIfHasMutationErrors } from 'common/lib/form/errors';
import { invitePartnerValidationSchema } from 'common/lib/signup/SignupValidation';
import useIsFeatureFlagOff from 'lib/hooks/useIsFeatureFlagOff';

import { ONBOARDING } from 'common/constants/copy';

import type { InviteEmail, InviteEmailVariables } from 'common/generated/graphQlTypes/InviteEmail';
import { ErrorCode } from 'common/generated/graphql';

const InvitePartnerContainer = styled.div`
  margin: auto;
  width: 100%;
  max-width: 500px;
`;

const StyledOnboardingTitle = styled(OnboardingTitle)`
  display: block;
`;

const StyledOnboardingSubtitle = styled(OnboardingSubtitle)`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const FormContainer = styled(OnboardingFormContainer)`
  margin-left: auto;
  margin-right: auto;
`;

const ErrorContainer = styled(Banner).attrs({ type: 'error' })`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.spacing.xlarge} 0;
`;

type Props = {
  next: () => void;
};

const OnboardingInvitePartner = ({ next }: Props) => {
  const isAbTestInvitePartnerOnboardingFeatureFlagOff = useIsFeatureFlagOff(
    'ab-test-invite-partner-onboarding',
    {
      trackImpression: true,
    },
  );

  /*
    skips the page when the feature flag is off.
    We're running this here because we want to skip users that don't have partners from this A/B test.
  */
  useEffect(() => {
    if (isAbTestInvitePartnerOnboardingFeatureFlagOff) {
      next();
    }
  }, [isAbTestInvitePartnerOnboardingFeatureFlagOff]);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [inviteEmail] = useMutation<InviteEmail, InviteEmailVariables>(INVITE_PARTNER_MUTATION);

  return (
    <InvitePartnerContainer>
      {errorMessage && <ErrorContainer>{errorMessage}</ErrorContainer>}
      <StyledOnboardingTitle>{ONBOARDING.INVITE_PARTNER.TITLE}</StyledOnboardingTitle>
      <StyledOnboardingSubtitle>{ONBOARDING.INVITE_PARTNER.SUBTITLE}</StyledOnboardingSubtitle>
      <FormContainer>
        <Form
          onSubmit={async ({ email }: { email: string }) => {
            setErrorMessage(null);

            const { data } = await inviteEmail({
              variables: {
                input: { email },
              },
            });

            throwIfHasMutationErrors(data);
            next();
          }}
          overrideValidationSchema={invitePartnerValidationSchema}
          onError={({ errors: { code, fieldErrors } }: GraphQlMutationError) => {
            if (code === ErrorCode.HOUSEHOLD_INVITE_USER_ALREADY_EXISTS) {
              setErrorMessage(ONBOARDING.INVITE_PARTNER.ERRORS.DUPLICATE);
            } else if (fieldErrors) {
              fieldErrors.forEach(({ field }) => {
                if (field === 'invited_email') {
                  setErrorMessage(ONBOARDING.INVITE_PARTNER.FIELDS.EMAIL.INVALID_EMAIL_ERROR);
                }
              });
            }
          }}
        >
          <TextField
            name="email"
            label={ONBOARDING.INVITE_PARTNER.FIELDS.EMAIL.LABEL}
            placeholder={ONBOARDING.INVITE_PARTNER.FIELDS.EMAIL.PLACEHOLDER}
            autoFocus
            required
          />

          <OnboardingFormSubmitButton>
            {ONBOARDING.INVITE_PARTNER.BUTTON}
          </OnboardingFormSubmitButton>
        </Form>
      </FormContainer>
    </InvitePartnerContainer>
  );
};

export default OnboardingInvitePartner;
