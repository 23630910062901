import * as RA from 'ramda-adjunct';
import React from 'react';

import useTheme from 'lib/hooks/useTheme';
import type { Identifiable, Link, LinkWithExtraProps } from 'lib/ui/sankey';
import { sankeyLinkPathHorizontal } from 'lib/ui/sankey';

type Props<N extends Identifiable, L extends Link> = {
  link: LinkWithExtraProps<N, L>;
  onMouseEnter: (link: LinkWithExtraProps<N, L>) => void;
  onMouseLeave: () => void;
  onClick?: (link: LinkWithExtraProps<N, L>) => void;
};

const SankeyDiagramLink = <N extends Identifiable, L extends Link>({
  link,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: Props<N, L>) => {
  const theme = useTheme();
  const d = sankeyLinkPathHorizontal(link);

  if (d === null) {
    throw new Error();
  }

  const { source, target, index } = link;

  const isClickable = (!!source.isClickable || !!target.isClickable) && RA.isNotNil(onClick);

  return (
    <>
      <defs>
        <linearGradient
          id={`gradient-${index}`}
          gradientUnits="userSpaceOnUse"
          x1={source.x1}
          x2={target.x0}
        >
          <stop offset="0" stopColor={source.color ?? theme.color.grayFocus} />
          <stop offset="100%" stopColor={target.color ?? theme.color.grayFocus} />
        </linearGradient>
      </defs>
      <path
        d={d}
        fill={`url(#gradient-${index})`}
        fillOpacity={0.25}
        className={['link', isClickable && 'is-clickable'].filter(Boolean).join(' ')}
        data-target-id={target.id}
        data-source-id={source.id}
        onMouseEnter={() => onMouseEnter(link)}
        onMouseLeave={onMouseLeave}
        onClick={() => onClick?.(link)}
      />
    </>
  );
};

export default SankeyDiagramLink;
