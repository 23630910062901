import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useRetailSyncMessaging } from 'lib/hooks/retailSync/useRetailSyncMessaging';
import { logoutAction } from 'state/user/thunks';

import routes from 'constants/routes';

const Logout = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { sendLoggedOutMessage } = useRetailSyncMessaging();

  useEffect(() => {
    const logout = () => {
      dispatch(logoutAction());
      sendLoggedOutMessage();
      history.replace(routes.login.path);
    };
    logout();
  });

  return null;
};

export default Logout;
