import React from 'react';
import styled from 'styled-components';

import AccountLogo from 'components/accounts/AccountLogo';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import RelativeDate from 'components/lib/ui/RelativeDate';
import Text from 'components/lib/ui/Text';

import {
  evaluateInstitutionStatus,
  getInstitutionPlaidStatus,
  getStatusForDataProvider,
} from 'common/lib/accounts/Institution';
import { getDisplayName } from 'common/lib/dataProvider';
import { spacing } from 'common/lib/theme/dynamic';
import { formatCurrency } from 'common/utils/Currency';

import type { Credential, DataProviderLegacy, Institution } from 'common/generated/graphql';
import { InstitutionStatus } from 'common/types/institutions';

const StatusText = styled(Text)<{ $isHealthy: boolean }>`
  color: ${({ theme, $isHealthy }) => ($isHealthy ? theme.color.greenText : theme.color.redText)};
`;
const StyledAccountLogo = styled(AccountLogo)`
  margin-right: ${spacing.small};
  margin-top: 6px;
`;
const StyledIcon = styled(Icon)`
  margin-right: ${spacing.xxsmall};
`;

const TruncatedText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const BalanceText = styled(Text)`
  flex-shrink: 0;
  text-align: right;
  margin-left: ${spacing.small};
`;

const MinWidthFlexContainer = styled(FlexContainer)`
  min-width: 0;
`;

export type AccountSummaryProps = {
  icon?: string | null;
  logoUrl?: string | null;
  label: React.ReactNode;
  balance?: number | null;
  createdAt?: string;
  lastUpdatedAt?: string;
  dataProvider?: string | null;
  institution?: Partial<Institution> | null;
  credential?: Partial<Credential> | null;
  syncDisabled?: boolean;
  className?: string;
};

const AccountSummary: React.FC<AccountSummaryProps> = ({
  icon,
  logoUrl,
  label,
  balance,
  createdAt,
  lastUpdatedAt,
  dataProvider,
  institution,
  credential,
  syncDisabled,
  className,
}) => {
  const hasBalance = balance !== undefined && balance !== null;
  const hasCreatedAt = createdAt !== undefined;
  const hasLastUpdatedAt = lastUpdatedAt !== undefined;
  const isHealthy = getConnectionIsHealthy(
    institution ?? {},
    credential ?? {},
    dataProvider ?? '',
    syncDisabled ?? false,
  );
  const providerName = getDisplayName(dataProvider as DataProviderLegacy);
  return (
    <FlexContainer className={className}>
      <StyledAccountLogo size={32} icon={icon} logoUrl={logoUrl} />
      <MinWidthFlexContainer column full>
        <FlexContainer justifyBetween>
          <TruncatedText title={typeof label === 'string' ? label : undefined} weight="medium">
            {label}
          </TruncatedText>
          {hasBalance && <BalanceText weight="medium">{formatCurrency(balance)}</BalanceText>}
        </FlexContainer>
        <FlexContainer justifyBetween>
          {hasCreatedAt && (
            <Text size="small">
              Created: <RelativeDate date={createdAt ?? ''} />
            </Text>
          )}
          {hasLastUpdatedAt && (
            <Text size="small">
              Last update: <RelativeDate date={lastUpdatedAt} />
            </Text>
          )}
        </FlexContainer>
        <FlexContainer>
          {dataProvider ? (
            <StatusText size="small" $isHealthy={isHealthy}>
              <StyledIcon size={12} name={isHealthy ? 'check-circle' : 'alert-triangle'} />
              {isHealthy ? 'Connected through ' : 'Disconnected from '}
              {providerName}
            </StatusText>
          ) : (
            /* We need to maintain the vertical space here to keep the layout consistent */
            <Text size="small">&nbsp;</Text>
          )}
        </FlexContainer>
      </MinWidthFlexContainer>
    </FlexContainer>
  );
};

export default AccountSummary;

const getConnectionIsHealthy = (
  institution: Partial<Institution>,
  credential: Partial<Credential>,
  dataProvider: string,
  syncDisabled: boolean,
) => {
  const updateRequired = credential?.updateRequired ?? false;
  const newConnectionsDisabled = institution?.newConnectionsDisabled ?? false;
  const balanceStatus = getStatusForDataProvider(
    getInstitutionPlaidStatus(institution, 'balanceStatus'),
    dataProvider,
    syncDisabled,
    newConnectionsDisabled,
  );
  const transactionsStatus = getStatusForDataProvider(
    getInstitutionPlaidStatus(institution, 'transactionsStatus'),
    dataProvider,
    syncDisabled,
    newConnectionsDisabled,
  );
  const showTransactionSyncingStatus = !['zillow'].includes(dataProvider ?? '');
  const status = evaluateInstitutionStatus({
    transactionsStatus,
    balanceStatus,
    showTransactionSyncingStatus,
  });
  return status === InstitutionStatus.Healthy && !updateRequired;
};
