import React from 'react';
import styled from 'styled-components';

import Link from 'components/lib/ui/Link';
import Text from 'components/lib/ui/Text';
import { TooltipV2 } from 'components/lib/ui/TooltipV2';

const TOOLTIP_DELAY_SHOW_MS = 300;
const TOOLTIP_MAX_WIDTH_PX = 320;

const ToolTipContentWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.small};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 1.5;
`;

type GroupLevelBudgetingTooltipProps = {
  itemName?: string;
  openEditGroupModal: () => void;
  children: React.ReactNode;
};

/**
 * This tooltip is used to inform about group level budgeting.
 * Its meant to be used with PlanCellAmount.
 */
export const GroupLevelBudgetingTooltip = ({
  itemName,
  openEditGroupModal,
  children,
}: GroupLevelBudgetingTooltipProps) => {
  const [shouldHideTooltip, setShouldHideTooltip] = React.useState(false);

  React.useEffect(() => {
    if (shouldHideTooltip) {
      setShouldHideTooltip(false);
    }
    // this workaround is necessary to prevent the tooltip from staying visible
    // for a split second after the modal is open
  }, [shouldHideTooltip]);

  const onClickOpenGroupModal = () => {
    setShouldHideTooltip(true);
    openEditGroupModal?.();
  };

  const tooltipContent = shouldHideTooltip ? null : (
    <ToolTipContentWrapper>
      <Text>
        To set a budget for the {itemName} group as a whole,{' '}
        <Link onClick={onClickOpenGroupModal} color="blue">
          click here
        </Link>{' '}
        to enable budgeting &quot;By Group&quot;.
      </Text>
    </ToolTipContentWrapper>
  );

  return (
    <TooltipV2
      delayShow={TOOLTIP_DELAY_SHOW_MS}
      maxWidth={TOOLTIP_MAX_WIDTH_PX}
      content={tooltipContent}
      side="top"
      clickable
      opacity={1}
    >
      <div>{children}</div>
    </TooltipV2>
  );
};
