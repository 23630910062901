import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';

import { breakPoints, spacing } from 'common/lib/theme/dynamic';

export const Boxes = styled(FlexContainer).attrs({ justifyBetween: true, alignCenter: true })`
  gap: ${spacing.default};

  @media (max-width: ${breakPoints.sm}px) {
    flex-flow: column nowrap;
  }
`;
