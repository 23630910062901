import { gql, useMutation } from '@apollo/client';
import React from 'react';

import NestedDropdownMenu from 'components/lib/ui/NestedDropdownMenu';
import NestedDropdownSubMenu from 'components/lib/ui/NestedDropdownSubMenu';

import toast from 'lib/ui/toast';

import type {
  ResetHouseholdRecurringMerchantSearch,
  ResetHouseholdRecurringMerchantSearchVariables,
} from 'common/generated/graphQlTypes/ResetHouseholdRecurringMerchantSearch';

const EmployeeMenuRecurring = () => {
  const [resetRecurringMerchantSearch] = useMutation<
    ResetHouseholdRecurringMerchantSearch,
    ResetHouseholdRecurringMerchantSearchVariables
  >(RESET_RECURRING_MERCHANT_SEARCH, {
    refetchQueries: [
      'Common_GetAggregatedRecurringItems',
      'Web_GetAllRecurringTransactionItems',
      'RecurringMerchantSearch',
    ],
  });

  return (
    <NestedDropdownSubMenu title="Recurring">
      <NestedDropdownMenu
        items={[
          {
            title: 'Reset recurring merchant search',
            type: 'danger',
            onClick: () => {
              resetRecurringMerchantSearch({ variables: { deleteStreams: false } });
              toast({
                title: '✅ Recurring merchant search reset',
                description: 'Now you can trigger a new search from the "Add recurring" flow.',
              });
            },
          },
          {
            title: 'Delete recurring streams',
            type: 'danger',
            onClick: async () => {
              await resetRecurringMerchantSearch({ variables: { deleteStreams: true } });
              toast({
                title: '✅ Recurring merchant search reset',
                description: 'All of your recurring transaction streams have been deleted.',
              });
            },
          },
        ]}
      />
    </NestedDropdownSubMenu>
  );
};

const RESET_RECURRING_MERCHANT_SEARCH = gql`
  mutation Web_ResetHouseholdRecurringMerchantSearch($deleteStreams: Boolean) {
    resetRecurringMerchantSearch(deleteStreams: $deleteStreams) {
      success
    }
  }
`;

export default EmployeeMenuRecurring;
