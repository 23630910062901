import { useIsWalmartUser } from 'hooks/useIsWalmartUser';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

import { useFeatureFlag } from 'lib/hooks';

import routes from 'constants/routes';

const SignupRedirect = () => {
  const history = useHistory();
  const location = useLocation();
  const isWalmartUser = useIsWalmartUser();

  const { treatment: preRegistrationSurveyTreatment } = useFeatureFlag(
    'ab-test-pre-registration-survey',
    {
      isAnonymous: true,
    },
  );

  useEffect(() => {
    const hasPreRegistrationSurveyLoaded = preRegistrationSurveyTreatment !== 'control';
    const isPreRegistrationSurveyOn = preRegistrationSurveyTreatment === 'on';

    if (hasPreRegistrationSurveyLoaded) {
      const initialRoute =
        isPreRegistrationSurveyOn && !isWalmartUser
          ? routes.signup.preRegistration.trackingFinances
          : routes.signup.onboardingWelcome;

      // Preserve the search parameters when redirecting
      history.push({
        pathname: initialRoute.path,
        search: location.search,
      });
    }
  }, [history, preRegistrationSurveyTreatment, isWalmartUser, location.search]);

  return null;
};

export default SignupRedirect;
