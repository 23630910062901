import * as React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import { Box } from 'components/settings/referrals/Box';
import { Boxes } from 'components/settings/referrals/Boxes';

import { spacing } from 'common/lib/theme/dynamic';
import { formatCurrency } from 'common/utils/Currency';
import { formatThousands } from 'common/utils/Number';

import { REFERRALS } from 'common/constants/copy';

import type { Web_GetReferralSettingsQuery } from 'common/generated/graphql';

const Root = styled(FlexContainer).attrs({ column: true })`
  gap: ${spacing.xlarge};
  margin: ${spacing.default} ${spacing.xlarge};
`;

const LegacyReferralsText = styled(Text).attrs({
  colorVariable: 'content.secondary',
  size: 'small',
})`
  max-width: 552px;
`;

type Props = {
  isReferralsV2Active: boolean;
  isSidebarExpanded?: boolean;
  statistics: Web_GetReferralSettingsQuery['referralStatistics'];
};

const ReferralTracker = ({ isReferralsV2Active, statistics }: Props) => (
  <Root>
    {isReferralsV2Active && (
      <LegacyReferralsText>{REFERRALS.LEGACY_REFERRALS_HONORED('Jan 1, 1969')}</LegacyReferralsText>
    )}

    <Boxes>
      <Box value={formatThousands(statistics.signedUp)} label="Signed up" />
      <Box value={formatThousands(statistics.subscribed)} label="Subscribed" />
      <Box
        value={formatCurrency(statistics.creditsEarned)}
        label="Credits earned"
        colorVariable="content.success"
      />
    </Boxes>
  </Root>
);

export default ReferralTracker;
