import React from 'react';

import ReconnectCredentialButton from 'components/accounts/ReconnectCredentialButton';
import DropdownMenu, { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import IconButton from 'components/lib/ui/button/IconButton';
import OverlayTrigger from 'components/lib/ui/popover/OverlayTrigger';
import DeleteOrDisableCredentialConfirmation from 'components/settings/institutions/DeleteOrDisableCredentialConfirmation';

import { isSyncDisabledWithReason } from 'common/lib/credentials/adapters';
import { useContactSupportContext } from 'lib/contexts/ContactSupportContext';
import useModal from 'lib/hooks/useModal';

import { SyncDisabledReason } from 'common/constants/credential';

import type { Web_GetInstitutionSettingsQuery } from 'common/generated/graphql';
import { ContactSupportTopic } from 'common/generated/graphql';

type Props = {
  credential: Web_GetInstitutionSettingsQuery['credentials'][0];
  refetch: () => void;
};

const InstitutionMenu = ({ credential, refetch }: Props) => {
  const [ConfirmDeleteModal, { open: openConfirmDelete, close: closeConfirmDelete }] = useModal();

  const { openContactSupportModal } = useContactSupportContext();

  const onCompletedCredentialDelete = () => {
    refetch();
  };

  return (
    <>
      <OverlayTrigger
        overlay={
          <DropdownMenu>
            {!isSyncDisabledWithReason(credential, SyncDisabledReason.InactiveUser) &&
              !isSyncDisabledWithReason(credential, SyncDisabledReason.UserDisabled) && (
                <ReconnectCredentialButton
                  institution={{
                    name: credential?.institution.name,
                    id: credential?.institution.id,
                  }}
                  credential={credential}
                  renderContent={(onClick) => (
                    <DropdownMenuItem keepMenuOpenOnClick onClick={onClick}>
                      Update login settings
                    </DropdownMenuItem>
                  )}
                />
              )}
            <DropdownMenuItem
              onClick={() =>
                openContactSupportModal({
                  credentialId: credential?.id,
                  topic: ContactSupportTopic.CONNECTION_ISSUE,
                })
              }
            >
              Contact support
            </DropdownMenuItem>

            <DropdownMenuItem type="danger" onClick={openConfirmDelete}>
              Delete this connection
            </DropdownMenuItem>
          </DropdownMenu>
        }
        placement="bottom-end"
      >
        {({ toggleOpen, isOpen }) => (
          <IconButton icon="more-horizontal" size="small" onClick={toggleOpen} active={isOpen} />
        )}
      </OverlayTrigger>

      <ConfirmDeleteModal>
        <DeleteOrDisableCredentialConfirmation
          credential={credential}
          onCompleted={onCompletedCredentialDelete}
          closeModal={closeConfirmDelete}
        />
      </ConfirmDeleteModal>
    </>
  );
};

export default InstitutionMenu;
