import staticTheme from 'common/lib/theme/staticTheme';

export const onboardingSurveyControlMotionSettings = {
  inactiveState: {
    opacity: 0,
    scale: 0.25,
  },
  activeState: {
    opacity: 1,
    scale: 1,
  },
  duration: staticTheme.transition.defaultDuration,
  durationMs: staticTheme.transition.defaultDurationMs,
};
