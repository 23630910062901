import React from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import CurrencyField from 'components/lib/form/CurrencyField';
import { Input as CurrencyInput } from 'components/lib/form/CurrencyInput';
import FieldCellWithToggle from 'components/lib/form/FieldCellWithToggle';
import SelectField from 'components/lib/form/SelectField';
import ToggleField from 'components/lib/form/ToggleField';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Link from 'components/lib/ui/Link';
import Text from 'components/lib/ui/Text';
import PremiumBadge from 'components/premium/PremiumBadge';
import PremiumFeatureOverlayTrigger from 'components/premium/PremiumFeatureOverlayTrigger';

import type { CreateEditCategoryFormValues } from 'common/lib/categories/form';
import useRolloverFormFields from 'common/lib/categories/useRolloverFormFields';
import { color, spacing } from 'common/lib/theme/dynamic';
import { formatCurrencyNoCents } from 'common/utils/Currency';

import {
  ROLLOVER_APPLY_TO_FUTURE_MONTHS_FIELD_NAME,
  ROLLOVER_ENABLED_FIELD_NAME,
} from 'common/constants/budget';
import { BUDGET } from 'common/constants/copy';
import { HELP_CENTER_ROLLOVER_ARTICLE_URL } from 'common/constants/externalUrls';
import { ProductFeature } from 'common/constants/premium';

import { BudgetVariability } from 'common/generated/graphql';

const RolloverFieldCell = styled(FieldCellWithToggle)`
  border: 0;
  background-color: ${color.grayBackground};
  margin-top: ${spacing.small};
`;

const RolloverFieldCellTitle = styled(Text).attrs({ weight: 'medium', size: 'base' })``;

const CurrencyFieldWithBackground = styled(CurrencyField)`
  ${CurrencyInput} {
    background-color: ${({ theme }) => theme.color.white};
  }
`;

const SpacelessToggleField = styled(ToggleField)`
  transform: translateY(1px);

  && {
    margin-bottom: 0;
  }
`;

const ApplyToFutureToggleContainer = styled(FlexContainer).attrs({
  justifyStart: true,
  alignCenter: true,
  gap: 'xsmall',
  marginTop: 'xlarge',
})`
  &:hover {
    ${SpacelessToggleField} {
      /* Make the hover on the container dim the toggle's background */
      .react-toggle:not(.react-toggle--disabled):not(.react-toggle--checked) .react-toggle-track {
        background-color: ${({ theme }) => theme.color.grayDark};
      }
    }
  }
`;

const FixedFlexCategoryRolloverFields = () => {
  const { values, setFieldValue } = useFormContext<CreateEditCategoryFormValues<any>>();

  const {
    rolloverStartMonthOptions,
    rolloverFrequencyOptions,
    isLoadingBudgetRolloverFrequencies,
    shouldPromptApplyToFuture,
    calculatedMonthlyRolloverBudget,
  } = useRolloverFormFields();

  const shouldShowNonMonthlyFields = values.budgetVariability === BudgetVariability.NON_MONTHLY;

  // We don't just use the ToggleField's internals because we want the click on the whole container to toggle the field
  const toggleApplyToFutureMonths = () =>
    setFieldValue(
      ROLLOVER_APPLY_TO_FUTURE_MONTHS_FIELD_NAME,
      !values[ROLLOVER_APPLY_TO_FUTURE_MONTHS_FIELD_NAME],
    );

  return (
    <PremiumFeatureOverlayTrigger feature={ProductFeature.ROLLOVERS} placement="bottom-end">
      {({ hasAccess }) => (
        <RolloverFieldCell
          fieldCellProps={{
            title: (
              <RolloverFieldCellTitle>Make this category a rollover fund</RolloverFieldCellTitle>
            ),
            subtitle: (
              <>
                Carry over remaining balances or set due dates to better plan for future expenses.{' '}
                <Link href={HELP_CENTER_ROLLOVER_ARTICLE_URL} target="_blank">
                  Learn more
                </Link>
              </>
            ),
            rightAccessory: !hasAccess ? <PremiumBadge /> : undefined,
          }}
          toggleFieldProps={{
            name: ROLLOVER_ENABLED_FIELD_NAME,
          }}
        >
          <SelectField
            name="rolloverStartMonth"
            label="Starting Month"
            options={rolloverStartMonthOptions}
            description={BUDGET.ROLLOVER_SETTINGS.STARTING_MONTH_DESCRIPTION}
            required
          />

          <CurrencyFieldWithBackground
            name="rolloverStartingBalance"
            label="Starting Balance"
            placeholder="$0.00"
            maskOptions={{
              prefix: '$',
              allowDecimal: true,
              decimalLimit: 2,
              allowNegative: false,
            }}
            description={BUDGET.ROLLOVER_SETTINGS.STARTING_BALANCE_DESCRIPTION}
          />

          {shouldShowNonMonthlyFields && (
            <>
              <SelectField
                name="rolloverFrequency"
                label="Expense Frequency"
                options={rolloverFrequencyOptions}
                description={BUDGET.ROLLOVER_SETTINGS.FREQUENCY_DESCRIPTION}
                isLoading={isLoadingBudgetRolloverFrequencies}
                isDisabled={isLoadingBudgetRolloverFrequencies}
                required
              />

              <CurrencyFieldWithBackground
                name="rolloverTargetAmount"
                label="Target Amount (optional)"
                description={BUDGET.ROLLOVER_SETTINGS.TARGET_AMOUNT_DESCRIPTION}
                placeholder="$0.00"
                maskOptions={{
                  prefix: '$',
                  allowDecimal: true,
                  decimalLimit: 2,
                  allowNegative: false,
                }}
              />
              {shouldPromptApplyToFuture && (
                <ApplyToFutureMonthsToggle
                  suggestedBudget={calculatedMonthlyRolloverBudget}
                  toggleApplyToFutureMonths={toggleApplyToFutureMonths}
                />
              )}
            </>
          )}
        </RolloverFieldCell>
      )}
    </PremiumFeatureOverlayTrigger>
  );
};

const ApplyToFutureMonthsToggle = ({
  suggestedBudget,
  toggleApplyToFutureMonths,
}: {
  suggestedBudget: number;
  toggleApplyToFutureMonths: () => void;
}) => (
  <ApplyToFutureToggleContainer onClick={toggleApplyToFutureMonths}>
    <SpacelessToggleField name={ROLLOVER_APPLY_TO_FUTURE_MONTHS_FIELD_NAME} hideLabel />
    <Text size="small">
      Apply <Text weight="medium">{formatCurrencyNoCents(suggestedBudget)} budget</Text> to all
      future months. This will override any budgets you have set for future months.
    </Text>
  </ApplyToFutureToggleContainer>
);

export default FixedFlexCategoryRolloverFields;
