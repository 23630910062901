import useQuery from 'common/lib/hooks/useQuery';

import { gql } from 'common/generated/gql';
import type { QueryHookOptionsFromDocument } from 'common/types/graphql';

/**
 * This hook allows us to know if user has an active subscription on both web and mobile
 * and keep a single source of truth. It can be extended to add more statuses.
 */
const useSubscriptionDetails = (
  options?: QueryHookOptionsFromDocument<typeof GET_SUBSCRIPTION_DETAILS_QUERY>,
) => {
  const { data, isLoadingInitialData } = useQuery(GET_SUBSCRIPTION_DETAILS_QUERY, options);
  const { hasPremiumEntitlement, isOnFreeTrial, activeSponsorship, ...rest } =
    data?.subscription ?? {};

  return {
    isLoading: isLoadingInitialData,
    isSubscriptionActive: hasPremiumEntitlement,
    isTrialing: isOnFreeTrial,
    isSponsored: !!activeSponsorship,
    ...rest,
  };
};

const GET_SUBSCRIPTION_DETAILS_QUERY = gql(/* GraphQL */ `
  query Common_GetSubscriptionDetails {
    subscription {
      id
      paymentSource
      referralCode
      isOnFreeTrial
      hasPremiumEntitlement

      activeSponsorship {
        id
      }
    }
  }
`);

export default useSubscriptionDetails;
