import React, { useEffect } from 'react';

import { useDispatch } from 'lib/hooks';
import { useRetailSyncMessaging } from 'lib/hooks/retailSync/useRetailSyncMessaging';
import useModal from 'lib/hooks/useModal';
import useRedirectToLogin from 'lib/hooks/useRedirectToLogin';
import { logoutAction } from 'state/user/thunks';

import { SECONDS_PER_MINUTE } from 'common/constants/time';

import SessionTimeoutWarningModal from './SessionTimeoutWarningModal';
import useTokenExtender from './useTokenExtender';

const WARNING_PERIOD_SECONDS = 10 * SECONDS_PER_MINUTE;

// SessionTimeout logic for logged in users.
// We use the tokenExpiry date as the time by which a user needs to be logged out.
// If a user is active, up to once every EXTEND_TOKEN_THROTTLE_SECONDS, we extend a user's token.
// We then maintain two "setTimeouts", one for showing the warning and one for actually logging
// out the user. These are derived from the tokenExpiry.
const SessionTimeout = () => {
  const [
    WarningModal,
    { open: openWarningModal, close: closeWarningModal, isOpen: isWarningModalOpen },
  ] = useModal();

  const dispatch = useDispatch();

  const [tokenExpiration, extendToken] = useTokenExtender(!isWarningModalOpen);
  const { sendLoggedOutMessage } = useRetailSyncMessaging();

  const redirectToLogin = useRedirectToLogin();

  // Timeout for showing the warning Modal.
  useEffect(() => {
    if (!tokenExpiration) {
      return;
    }
    const timeInMsUntilTokenExpiry = tokenExpiration.getTime() - new Date().getTime();
    if (!timeInMsUntilTokenExpiry) {
      return;
    }
    const warningTimeout = timeInMsUntilTokenExpiry - WARNING_PERIOD_SECONDS * 1000;
    const timeout = setTimeout(() => {
      openWarningModal();
    }, warningTimeout);
    return () => clearTimeout(timeout);
  }, [tokenExpiration, openWarningModal]);

  // Timeout to actually log the user out.
  useEffect(() => {
    if (!tokenExpiration) {
      return;
    }
    const timeInMsUntilTokenExpiry = tokenExpiration.getTime() - new Date().getTime();
    const timeout = setTimeout(() => {
      dispatch(logoutAction()).then(() => {
        // After logout, show the timeout message. Technically, the logoutAction
        // will already cause a redirect to /login, but we do want to show the message.
        sendLoggedOutMessage();
        return redirectToLogin({ message: 'timeout' });
      });
    }, timeInMsUntilTokenExpiry);
    return () => clearTimeout(timeout);
  }, [tokenExpiration, dispatch, redirectToLogin]);

  return tokenExpiration ? (
    <WarningModal onClose={() => extendToken(true)}>
      <SessionTimeoutWarningModal
        onClickContinueSession={() => {
          closeWarningModal();
          extendToken(true);
        }}
        tokenExpiration={tokenExpiration}
        onClickLogout={() => {
          dispatch(logoutAction()).then(() => {
            sendLoggedOutMessage();
          });
        }}
      />
    </WarningModal>
  ) : null;
};
export default SessionTimeout;
