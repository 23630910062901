import React from 'react';
import styled from 'styled-components';

import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';
import TransactionAttachmentsIcon from 'components/transactions/TransactionAttachmentsIcon';
import TransactionNotesIcon from 'components/transactions/TransactionNotesIcon';
import TransactionPendingBadge from 'components/transactions/list/TransactionPendingBadge';
import TransactionSplitIcon from 'components/transactions/list/TransactionSplitIcon';
import TransactionTagsIcon from 'components/transactions/tags/TransactionTagsIcon';

import { getRecurringTooltipText } from 'common/lib/recurring';
import { spacing, variables } from 'common/lib/theme/dynamic';
import staticTheme from 'common/lib/theme/staticTheme';
import { getTransactionDisplayAmount } from 'common/utils/Currency';
import { TRANSACTION_ROW_HEIGHT_PX } from 'lib/accounts/transferAccountData/constants';
import type { Transaction } from 'lib/accounts/transferAccountData/types';
import { TransactionStatus } from 'lib/accounts/transferAccountData/types';

import type { Frequency } from 'common/constants/recurringTransactions';
import { HIDDEN_TOOLTIP_TEXT } from 'constants/copy';

const ICON_WRAPPER_SIZE_PX = 24;
const ICON_SIZE_PX = 12;

const ListItem = styled.li<{ $type: TransactionStatus }>`
  list-style: none;
  padding: ${spacing.xsmall} ${spacing.default};
  font-size: ${staticTheme.fontSize.small};
  border-width: 0 0 1px;
  border-style: solid;
  border-color: ${({ $type }) => {
    switch ($type) {
      case TransactionStatus.Included:
        return variables.color.background.success;
      case TransactionStatus.Excluded:
        return variables.color.background.danger;
      default:
        return variables.color.divider.primary;
    }
  }};
  height: ${TRANSACTION_ROW_HEIGHT_PX}px;
  background-color: ${({ $type, theme }) => {
    switch ($type) {
      case TransactionStatus.Included:
        return theme.color.green1;
      case TransactionStatus.Excluded:
        return theme.color.red1;
      default:
        return 'transparent';
    }
  }};
  opacity: ${({ $type }) => ($type === TransactionStatus.Default ? 0.5 : 1)};

  &:last-child {
    border-bottom: none;
  }
`;

const CategoryIcon = styled.span`
  margin-right: ${spacing.xsmall};
`;

const AmountText = styled(Text)`
  margin-left: ${spacing.small};
`;

const MerchantNameContainer = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  min-width: 0;
`;

const TransactionInfoContainer = styled.span`
  display: flex;
  align-items: center;
  min-width: 0;
  flex: 1;
`;

const IconSection = styled(FlexContainer).attrs({
  inline: true,
  alignCenter: true,
  gap: 'xxsmall',
})`
  margin-left: ${spacing.xsmall};
  color: ${variables.color.content.secondary};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: ${ICON_WRAPPER_SIZE_PX}px;
  height: ${ICON_WRAPPER_SIZE_PX}px;
  border-radius: ${({ theme }) => theme.radius.round};
  transition: ${({ theme }) => theme.transition.default};
  user-select: none;

  :hover {
    background: ${({ theme }) => theme.color.grayBackground};
    color: ${({ theme }) => theme.color.black};
  }
`;

const RecurringIcon = styled(Icon).attrs({ name: 'calendar', size: ICON_SIZE_PX })`
  display: block;
  cursor: default;
`;

type Props = {
  transaction: Transaction;
  type: TransactionStatus;
};

export const TransactionRow: React.FC<Props> = ({ transaction, type }) => {
  const {
    pending,
    category: { icon: categoryIcon },
    merchant: { name: merchantName, ...merchant },
    amount,
    attachments,
    hideFromReports,
    notes,
    isSplitTransaction,
    tags,
    isRecurring,
  } = transaction;

  const recurringTooltip = getRecurringTooltipText(
    merchant.recurringTransactionStream?.frequency as Frequency,
  );

  return (
    <ListItem $type={type}>
      <Flex justifyBetween alignCenter>
        <TransactionInfoContainer>
          <CategoryIcon>{categoryIcon}</CategoryIcon>
          <MerchantNameContainer>
            <Text>{merchantName}</Text>
          </MerchantNameContainer>
        </TransactionInfoContainer>
        <IconSection>
          {tags.length > 0 && <TransactionTagsIcon tags={tags} />}
          {hideFromReports && (
            <Tooltip content={HIDDEN_TOOLTIP_TEXT}>
              <IconWrapper>
                <Icon name="eye-off" size={ICON_SIZE_PX} />
              </IconWrapper>
            </Tooltip>
          )}
          {!!notes && <TransactionNotesIcon notes={notes} />}
          {!!isRecurring && (
            <Tooltip content={recurringTooltip}>
              <IconWrapper>
                <RecurringIcon />
              </IconWrapper>
            </Tooltip>
          )}
          {isSplitTransaction && <TransactionSplitIcon />}
          {attachments.length > 0 && <TransactionAttachmentsIcon count={attachments.length} />}
          {pending && <TransactionPendingBadge isEditingDisabled={false} />}
        </IconSection>
        <AmountText>{getTransactionDisplayAmount(amount)}</AmountText>
      </Flex>
    </ListItem>
  );
};

type EmptyTransactionRowProps = {
  type?: TransactionStatus;
};

export const EmptyTransactionRow: React.FC<EmptyTransactionRowProps> = ({
  type = TransactionStatus.Default,
}) => (
  <ListItem $type={type}>
    <Flex justifyBetween>
      <TransactionInfoContainer>
        <MerchantNameContainer>&nbsp;</MerchantNameContainer>
      </TransactionInfoContainer>
    </Flex>
  </ListItem>
);
