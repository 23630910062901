import { Duration } from 'luxon';

import useQueryWithCacheExpiration from 'common/lib/hooks/useQueryWithCacheExpiration';

import { SubscriptionEntitlement } from 'common/constants/premium';

import { gql } from 'common/generated/gql';

// We default to Premium while the query is loading to ensure we don't accidentally
// restrict the user when they do in fact have premium.
const DEFAULT_ENTITLEMENTS = [SubscriptionEntitlement.PREMIUM];
const CACHE_EXPIRATION = Duration.fromObject({ hours: 1 });

/** Hook used to get which entitlements are active for the user's household. */
const useActiveEntitlements = () => {
  const { data } = useQueryWithCacheExpiration(QUERY, {
    cacheExpiration: CACHE_EXPIRATION,
  });
  const { subscription } = data ?? {};
  const { entitlements } = subscription ?? {};

  return entitlements ?? DEFAULT_ENTITLEMENTS;
};

const QUERY = gql(/* GraphQL */ `
  query Common_GetEntitlements {
    subscription {
      id
      entitlements
    }
  }
`);

export default useActiveEntitlements;
