import * as R from 'ramda';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Element } from 'react-scroll';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import PageIndicatorDots from 'components/lib/ui/PageIndicatorDots';
import type { ScrollHandles } from 'components/lib/ui/Scroll';
import Scroll from 'components/lib/ui/Scroll';

import useMeasure from 'lib/hooks/useMeasure';

const Container = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  width: 100%;
  position: relative;
`;

const ScrollContainer = styled(Scroll)`
  display: flex;
  width: 100%;
  align-items: center;
  scroll-snap-type: x mandatory;

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    align-items: flex-start;
  }
`;

export const Item = styled(Element)`
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  scroll-snap-align: center;
  justify-content: flex-start;
  height: 100%;
`;

const ArrowButton = styled(FlexContainer).attrs({ center: true })`
  width: 32px;
  height: 32px;
  border-radius: ${({ theme }) => theme.radius.round};
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
  position: absolute;
  top: 35%;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition.default};

  :hover {
    background: ${({ theme }) => theme.color.grayBackground};
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    display: none;
  }
`;

const LeftArrow = styled(ArrowButton)`
  left: 15%;
`;

const RightArrow = styled(ArrowButton)`
  right: 15%;
`;

interface DotsProps {
  position: 'top' | 'bottom';
}

export const Dots = styled.div<DotsProps>`
  padding: ${({ theme }) => theme.spacing.default};
  position: ${({ position }) => (position === 'top' ? 'absolute' : 'static')};

  /* by default, when mobile it will have the three dots over the content */
  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    padding: 0;
    position: static;
    margin-top: -${({ theme }) => theme.spacing.default};
    margin-bottom: ${({ theme }) => theme.spacing.default};
  }
`;

type Props = {
  className?: string;
  name: string;
  children: React.ReactNode;
  onChangeIndex?: (index: number) => void;
  showArrows?: boolean;
  automated?: boolean;
  automationInterval?: number;
  dotsPosition?: 'top' | 'bottom';
};

const PagedCarousel = ({
  className,
  name,
  children,
  onChangeIndex,
  showArrows,
  automated,
  automationInterval = 5000,
  dotsPosition = 'bottom',
}: Props) => {
  const scrollRef = useRef<ScrollHandles>(null);
  const [ref, { width }] = useMeasure();

  const [index, setIndex] = useState(0);
  const stepCount = React.Children.count(children);

  const clampIndex = (newIndex: number) => R.clamp(0, stepCount - 1, Math.floor(newIndex));

  const scrollToIndex = useCallback(
    (index: number) =>
      scrollRef.current?.scrollTo(`${name}-${index}`, { horizontal: true, duration: 0 }),
    [scrollRef],
  );

  const [timeoutId, setTimeoutId] = useState<number | undefined>(undefined);

  const incrementIndex = (increment: number) => {
    scrollToIndex(clampIndex(index + increment));
  };

  useEffect(() => {
    onChangeIndex?.(index);

    if (automated) {
      clearTimeout(timeoutId);

      const timeout = setTimeout(() => {
        if (index === stepCount - 1) {
          scrollToIndex(0);
        } else {
          incrementIndex(1);
        }
      }, automationInterval);

      setTimeoutId(timeout);
    }
  }, [index, automated, automationInterval]);

  return (
    <Container className={className} ref={ref}>
      <ScrollContainer
        ref={scrollRef}
        hideScrollBar
        onScroll={(_, left) => {
          setIndex(clampIndex(left / (Math.floor(width ?? 0) ?? Math.floor(left))));
        }}
      >
        {React.Children.map(children, (child, index) => (
          <Item name={`${name}-${index}`}>{child}</Item>
        ))}
      </ScrollContainer>
      <Dots position={dotsPosition}>
        <PageIndicatorDots count={stepCount} currentIndex={index} onChangeIndex={scrollToIndex} />
      </Dots>

      {showArrows && (
        <>
          <LeftArrow onClick={() => incrementIndex(-1)}>
            <Icon name="arrow-left" />
          </LeftArrow>
          <RightArrow onClick={() => incrementIndex(1)}>
            <Icon name="arrow-right" />
          </RightArrow>
        </>
      )}
    </Container>
  );
};

export default PagedCarousel;
