import { yupToFormErrors } from 'formik';
import { DateTime } from 'luxon';
import { isNil } from 'ramda';
import { isNotNil } from 'ramda-adjunct';
import React, { useState } from 'react';
import * as Yup from 'yup';

import DateRangeWithTimeframesFormContent from 'components/lib/dates/DateRangeWithTimeframesFormContent';
import Form from 'components/lib/form/Form';

import type { ShortcutId } from 'common/lib/dateRange/dateRangeWithTimeframes';
import { getShortcutByDateRange } from 'common/lib/dateRange/dateRangeWithTimeframes';

import type { DateRangeWithTimeframes } from 'common/types/DateRange';

export type Props = {
  dateRange: DateRangeWithTimeframes;
  onChange: (dateRange: DateRangeWithTimeframes) => void;
  onClear: () => void;
  toggleOpen?: () => void;
};

export enum DateRangeWithTimeframesFormContentType {
  LAST = 'last',
  RANGE = 'range',
}

const validationSchema = Yup.object()
  .shape({
    startDate: Yup.string()
      .nullable()
      .test(
        'startBeforeEnd',
        'Start date must be before end date',
        function validateStartDate(startDate) {
          const { endDate } = this.parent;
          if (isNil(startDate) || isNil(endDate)) {
            return true;
          }

          return DateTime.fromISO(startDate) <= DateTime.fromISO(endDate);
        },
      ),
    endDate: Yup.string().nullable(),
    timeframeUnit: Yup.string().nullable().notOneOf([''], 'Timeframe unit is required.'),
    timeframeValue: Yup.number().nullable().min(1, 'Timeframe value must be greater than 0.'),
    includeCurrentPeriod: Yup.boolean().nullable(),
  })
  .test(
    'timeframe-period',
    'Timeframe unit and value are required.',
    // Because of https://github.com/jaredpalmer/formik/issues/2146, top level tests need to create
    // an error with a field path that exists within the form, rather than simply returning false.
    function validateTimeframePeriod(this: Yup.TestContext, values: DateRangeWithTimeframes) {
      const { startDate, endDate, timeframeUnit, timeframeValue, includeCurrentPeriod } = values;
      const { isLast } = (this.options.context as { isLast: boolean }) ?? {};

      const containsRange = isNotNil(startDate) || isNotNil(endDate);
      const containsTimeframe =
        isNotNil(timeframeUnit) || isNotNil(timeframeValue) || isNotNil(includeCurrentPeriod);

      // All time situation
      if (!containsRange && !containsTimeframe) {
        if (isLast) {
          return this.createError({
            path: 'timeframeUnit',
            message: 'Timeframe unit and value must both be provided.',
          });
        }

        return true;
      }

      if (containsRange && containsTimeframe) {
        return this.createError({ message: 'Cannot select both range and timeframe.' });
      }

      if (
        isLast &&
        containsTimeframe &&
        (isNil(timeframeUnit) || isNil(timeframeValue) || isNil(includeCurrentPeriod))
      ) {
        return this.createError({
          path: 'timeframeUnit',
          message: 'Timeframe unit and value must both be provided.',
        });
      }

      return true;
    },
  );

const validateFormValues = (isLast: boolean) => async (values: DateRangeWithTimeframes) => {
  try {
    await validationSchema.validate(values, {
      context: { isLast },
      abortEarly: false,
    });
    return {}; // No errors
  } catch (error) {
    const fieldErrors = yupToFormErrors(error);
    return fieldErrors;
  }
};

const getContentId = (dateRange: DateRangeWithTimeframes) => {
  const shortcut = getShortcutByDateRange(dateRange);

  if (shortcut) {
    return shortcut.id as ShortcutId;
  }

  if (isNil(dateRange.startDate) && isNil(dateRange.endDate)) {
    return DateRangeWithTimeframesFormContentType.LAST;
  }

  return DateRangeWithTimeframesFormContentType.RANGE;
};

const DateRangeWithTimeframesForm = ({ dateRange, onChange, onClear, toggleOpen }: Props) => {
  const [contentId, setContentId] = useState(() => getContentId(dateRange));

  return (
    <Form
      initialValues={dateRange}
      validate={validateFormValues(contentId === DateRangeWithTimeframesFormContentType.LAST)}
      enableReinitialize
      onSubmit={(values) => {
        onChange(values);
        toggleOpen?.();
      }}
    >
      <DateRangeWithTimeframesFormContent
        contentId={contentId}
        onSetContentId={setContentId}
        onClear={onClear}
        toggleOpen={toggleOpen}
      />
    </Form>
  );
};

export default React.memo(DateRangeWithTimeframesForm);
