import { variables } from 'common/lib/theme/dynamic';

import type { StyleMixin } from 'common/types/Styles';

const small: StyleMixin = (context) => `
  box-shadow: 0px 1px 2px ${variables.color.shadow.small(context)};
`;

const medium: StyleMixin = (context) => `
  box-shadow: 0px 2px 4px ${variables.color.shadow.medium(context)};
`;

const mediumTop: StyleMixin = (context) => `
  box-shadow: 0px -2px 4px ${variables.color.shadow.medium(context)};
`;

const inset: StyleMixin = (context) => `
  box-shadow: inset 0px 2px 4px ${variables.color.shadow.inset(context)};
`;

const large: StyleMixin = (context) => `
  box-shadow: 0px 3px 6px ${variables.color.shadow.large(context)};
`;

const card: StyleMixin = (context) => medium(context);

const boxShadow = {
  small,
  medium,
  mediumTop,
  inset,
  large,
  card,
};

export default boxShadow;
