import React from 'react';

import { BudgetHistoricalAmountsTooltip } from 'components/plan/BudgetHistoricalAmountsTooltip';
import { GroupLevelBudgetingTooltip } from 'components/plan/GroupLevelBudgetingTooltip';
import { PlanAmountsTooltip } from 'components/plan/PlanAmountsTooltip';
import { PlanCellAmountPill } from 'components/plan/PlanCellAmountPill';

import type { PlanAmounts } from 'lib/plan';
import type { PlanSectionType } from 'lib/plan/Adapters';

import type { Color } from 'types/Styles';

type Props = {
  itemId?: string;
  itemName?: string;
  itemIcon?: React.ReactNode | string;
  isLoading?: boolean;
  groupLevelBudgetingEnabled?: boolean;
  isBudgetType: boolean;
  sectionType: PlanSectionType;
  isCategoryGroup?: boolean;
  isFlexSection?: boolean;
  openEditGroupModal?: () => void;
  warningText?: string;
  amounts: PlanAmounts | undefined;
  showPlanAmountsTooltip: boolean;
} & AmountProps;

/**
 * This component is used to display the amount of a plan cell when the cell is a regular amount, as opposed to a move money pill or a budget value input
 * It also handles showing tooltips if the cell requires it
 */
const PlanCellAmount = ({
  itemId,
  itemName,
  itemIcon,
  isAggregate,
  isUnplannedAggregate,
  groupLevelBudgetingEnabled,
  isRemainingAmountType,
  isBudgetType,
  availableDisplayAmount,
  sectionType,
  value,
  isCategoryGroup,
  isFlexSection,
  openEditGroupModal,
  warningText,
  showRolloverIcon,
  isPlanSummary,
  amounts,
  showPlanAmountsTooltip,
}: Props) => {
  const shouldShowGroupLevelBudgetingTooltip =
    isBudgetType &&
    sectionType === 'expense' &&
    !groupLevelBudgetingEnabled &&
    !!itemId &&
    !!isCategoryGroup &&
    !!openEditGroupModal;

  const shouldShowAmountsTooltip =
    isFlexSection && !isRemainingAmountType && !isAggregate && !!itemId;

  const amountComponent = (
    <Amount
      sectionType={sectionType}
      isRemainingAmountType={isRemainingAmountType}
      availableDisplayAmount={availableDisplayAmount}
      value={value}
      isUnplannedAggregate={isUnplannedAggregate}
      isAggregate={isAggregate}
      hasWarning={!!warningText}
      showRolloverIcon={!!showRolloverIcon}
      isPlanSummary={isPlanSummary}
    />
  );

  if (shouldShowGroupLevelBudgetingTooltip) {
    return (
      <GroupLevelBudgetingTooltip itemName={itemName} openEditGroupModal={openEditGroupModal}>
        {amountComponent}
      </GroupLevelBudgetingTooltip>
    );
  } else if (shouldShowAmountsTooltip) {
    return (
      <BudgetHistoricalAmountsTooltip itemId={itemId}>
        {amountComponent}
      </BudgetHistoricalAmountsTooltip>
    );
  } else if (showPlanAmountsTooltip) {
    return (
      <PlanAmountsTooltip
        amounts={amounts}
        showTooltip={showPlanAmountsTooltip}
        itemName={itemName}
        itemIcon={itemIcon}
        warningText={warningText}
      >
        {amountComponent}
      </PlanAmountsTooltip>
    );
  }

  // No tooltips
  return amountComponent;
};

type AmountProps = {
  sectionType: PlanSectionType;
  isRemainingAmountType: boolean;
  availableDisplayAmount: number;
  value?: number;
  isUnplannedAggregate?: boolean;
  isAggregate: boolean;
  hasWarning?: boolean;
  showRolloverIcon?: boolean;
  isPlanSummary?: boolean;
};

const Amount = ({
  sectionType,
  isRemainingAmountType,
  availableDisplayAmount,
  value,
  isUnplannedAggregate,
  isAggregate,
  hasWarning,
  showRolloverIcon,
  isPlanSummary,
}: AmountProps) => {
  let overrideTextColor: Color | undefined;

  if (isPlanSummary) {
    overrideTextColor = 'textWhite';
  } else if (!isRemainingAmountType) {
    overrideTextColor = 'text';
  } else if (isUnplannedAggregate && availableDisplayAmount > 0) {
    overrideTextColor = 'textLight';
  }

  return (
    <PlanCellAmountPill
      sectionType={sectionType}
      value={isRemainingAmountType ? availableDisplayAmount : value}
      isAggregate={isAggregate}
      isBold={isRemainingAmountType || isAggregate}
      overrideTextColor={overrideTextColor}
      hideBackground
      hasWarning={hasWarning}
      showRolloverIcon={!!showRolloverIcon}
      isMovable={false}
    />
  );
};

export default React.memo(PlanCellAmount);
