import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import useQuery from 'common/lib/hooks/useQuery';
import {
  adaptReportsData,
  DEFAULT_MAX_VISIBLE_ENTITIES,
  ensureSummaryFromData,
} from 'common/lib/reports';
import type {
  ConstructQueryVariablesOptions,
  UseAdaptedReportsDataOptions,
  UseAdaptedReportsDataResult,
} from 'lib/hooks/reports/types';
import { isTimeBasedReport } from 'lib/reports';
import { convertFiltersToInput } from 'lib/transactions/Filters';
import {
  selectDisplayPropertiesForTab,
  selectReportsSankeyGroupMode,
} from 'state/reports/selectors';
import { ReportsChart } from 'state/reports/types';
import type { RootState } from 'state/types';

import { gql } from 'common/generated/gql';
import type {
  Common_GetReportsDataQueryVariables,
  TransactionFilterInput,
} from 'common/generated/graphql';
import { CategoryType, ReportsGroupByEntity, ReportsSortBy } from 'common/generated/graphql';
import type { PickByIncludePrefix } from 'common/types/utility';

/**
 * This hook fetches and adapts data from the reports endpoint based on the current tab and groupBy options.
 * It uses a variety of selectors to get the necessary state from the Redux store.
 *
 * The hook first determines the grouping criteria based on the current tab and the selected sankey group mode.
 * It then checks if the report is time-based by comparing the current tab and display properties.
 *
 * The hook also decides the sorting order based on the current tab and whether the report is time-based.
 * It then defines if the report is a spending report based on the current tab.
 *
 * These calculated values are used to construct the query variables for fetching data from the reports endpoint.
 * The fetched data is then adapted to the required format.
 *
 * The hook returns an object containing the adapted data, a summary of the data, a loading state, the result of
 * the adapted data, and the timeframe of the reports.
 */
const useAdaptedReportsData = ({
  currentTab,
  groupBy,
  groupByTimeframe,
  filters,
  showAllEntities,
  skipQuery = false,
  onCompletedQuerySuccessfully,
}: Partial<UseAdaptedReportsDataOptions>): UseAdaptedReportsDataResult => {
  const displayProperties = useSelector(
    (state: RootState) => currentTab && selectDisplayPropertiesForTab(state, currentTab),
  );
  const isSankey = displayProperties?.chartType === ReportsChart.SankeyCashFlowChart;
  const sankeyGroupMode = useSelector(selectReportsSankeyGroupMode);

  const groupingCriteriaParsed = useMemo(() => {
    if (!isSankey) {
      return groupBy;
    }

    switch (sankeyGroupMode) {
      case 'category':
        return [ReportsGroupByEntity.CATEGORY];
      case 'group':
        return [ReportsGroupByEntity.CATEGORY_GROUP];
      case 'both':
        return [ReportsGroupByEntity.CATEGORY, ReportsGroupByEntity.CATEGORY_GROUP];
      default:
        return undefined;
    }
  }, [groupBy, sankeyGroupMode, isSankey]);

  const isTimeBased = useMemo(
    () => isTimeBasedReport(currentTab, displayProperties?.chartType, displayProperties?.viewMode),
    [currentTab, displayProperties],
  );

  const sortBy = useMemo(() => {
    if (isTimeBased) {
      return undefined;
    }

    if (currentTab === 'spending') {
      return ReportsSortBy.SUM_EXPENSE;
    } else if (currentTab === 'income') {
      return ReportsSortBy.SUM_INCOME;
    }
  }, [currentTab, isTimeBased]);

  // Note that this should return undefined if the tab is not supported (e.g. cashFlow)
  const isSpendingReport = useMemo(() => {
    if (currentTab === 'spending') {
      return true;
    } else if (currentTab === 'income') {
      return false;
    }

    return undefined;
  }, [currentTab]);

  const variables = constructQueryVariables({
    filters: filters ? convertFiltersToInput(filters) : {},
    groupBy: groupingCriteriaParsed,
    groupByTimeframe,
    isTimeBasedReport: isTimeBased,
    isSpendingReport,
    sortBy,
  });

  const { data, isDataAvailable, isNetworkRequestInFlight } = useQuery(REPORTS_QUERY, {
    variables,
    skip: skipQuery,
    onCompleted: () => onCompletedQuerySuccessfully?.(),
  });

  const adaptedData = adaptReportsData({
    reportsData: data,
    filters,
    groupBy: groupingCriteriaParsed,
    isSpendingReport,
    includeAllGroups: isSankey || isTimeBased,
    maxVisibleEntities:
      showAllEntities || displayProperties?.chartType === ReportsChart.HorizontalBarChart
        ? undefined
        : DEFAULT_MAX_VISIBLE_ENTITIES,
  });

  return {
    summary: ensureSummaryFromData(data),
    isLoading: !isDataAvailable && isNetworkRequestInFlight,
    result: adaptedData,
    queryVariables: variables,
  };
};

const constructQueryVariables = (
  options: ConstructQueryVariablesOptions,
): Common_GetReportsDataQueryVariables => {
  const { filters, isTimeBasedReport, groupBy, groupByTimeframe, sortBy, isSpendingReport } =
    options;
  const includes = constructGraphQlIncludes(groupBy);
  const { endDate, ...otherFilters } = filters;

  return {
    ...includes,
    filters: {
      ...otherFilters,
      categoryType: (() => {
        if (isSpendingReport !== undefined) {
          return isSpendingReport ? CategoryType.EXPENSE : CategoryType.INCOME;
        }
        return undefined;
      })(),
      // We need to pass the end date as a separate variable because we
      // want to limit it to today's date for time-based reports, so we
      // don't generate dates in the future.
      endDate: endDate
        ? DateTime.min(DateTime.fromISO(endDate), DateTime.local()).toISODate()
        : undefined,
    } as TransactionFilterInput,
    groupBy,
    groupByTimeframe: isTimeBasedReport ? groupByTimeframe : undefined,
    fillEmptyValues: !groupBy?.includes(ReportsGroupByEntity.MERCHANT),
    sortBy,
  };
};

/**
 * Generates the include flags for the reports query.
 *
 * Not ideal, but we need to pass these to the query to avoid BE from
 * doing unnecessary work. We do this by leveraging GraphQL's `@include` API.
 */
const constructGraphQlIncludes = (
  groupBy?: UseAdaptedReportsDataOptions['groupBy'],
): PickByIncludePrefix<Common_GetReportsDataQueryVariables> => ({
  includeCategory: !!groupBy?.includes(ReportsGroupByEntity.CATEGORY),
  includeCategoryGroup: !!groupBy?.includes(ReportsGroupByEntity.CATEGORY_GROUP),
  includeMerchant: !!groupBy?.includes(ReportsGroupByEntity.MERCHANT),
});

// Do not change the name of this query, it is used in UPDATE_TRANSACTION_REFETCH_QUERIES
const REPORTS_QUERY = gql(/* GraphQL */ `
  query Common_GetReportsData(
    $filters: TransactionFilterInput!
    $groupBy: [ReportsGroupByEntity!]
    $groupByTimeframe: ReportsGroupByTimeframe
    $sortBy: ReportsSortBy
    $includeCategory: Boolean = false
    $includeCategoryGroup: Boolean = false
    $includeMerchant: Boolean = false
    $fillEmptyValues: Boolean = true
  ) {
    reports(
      groupBy: $groupBy
      groupByTimeframe: $groupByTimeframe
      filters: $filters
      sortBy: $sortBy
      fillEmptyValues: $fillEmptyValues
    ) {
      groupBy {
        date
        ...ReportsCategoryFields @include(if: $includeCategory)
        ...ReportsCategoryGroupFields @include(if: $includeCategoryGroup)
        ...ReportsMerchantFields @include(if: $includeMerchant)
      }
      summary {
        ...ReportsSummaryFields
      }
    }

    aggregates(filters: $filters, fillEmptyValues: $fillEmptyValues) {
      summary {
        ...ReportsSummaryFields
      }
    }
  }

  fragment ReportsSummaryFields on TransactionsSummary {
    sum
    avg
    count
    max
    sumIncome
    sumExpense
    savings
    savingsRate
    first
    last
  }

  fragment ReportsCategoryFields on ReportsGroupByData {
    category {
      id
      name
      icon
      group {
        id
        name
        type
      }
    }
  }

  fragment ReportsCategoryGroupFields on ReportsGroupByData {
    categoryGroup {
      id
      name
      type
    }
  }

  fragment ReportsMerchantFields on ReportsGroupByData {
    merchant {
      id
      name
    }
  }
`);

export default useAdaptedReportsData;
