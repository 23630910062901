import React from 'react';

import BudgetAmountPopover from 'components/budget/BudgetAmountPopover';
import { TooltipV2 } from 'components/lib/ui/TooltipV2';

const TOOLTIP_DELAY_SHOW_MS = 300;

type BudgetAmountsTooltipProps = {
  itemId: string;
  children: React.ReactNode;
};

/**
 * This tooltip is used to show the historical actual amounts for a budget.
 * Its meant to be used with PlanCellAmount.
 */
export const BudgetHistoricalAmountsTooltip = ({ itemId, children }: BudgetAmountsTooltipProps) => {
  const tooltipContent = <BudgetAmountPopover itemId={itemId} />;

  return (
    <TooltipV2
      delayShow={TOOLTIP_DELAY_SHOW_MS}
      content={tooltipContent}
      side="bottom"
      align="end"
      unstyled
      clickable
      opacity={1}
    >
      <div>{children}</div>
    </TooltipV2>
  );
};
