import { darken } from 'polished';
import * as React from 'react';
import { FiArrowRight } from 'react-icons/fi';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import { color, radius, spacing, variables } from 'common/lib/theme/dynamic';
import typewriter from 'lib/analytics/typewriter';

const LEGACY_ICON_BOX_SIZE_PX = 32;
const V2_ICON_BOX_SIZE_PX = 24;

const IconBox = styled(FlexContainer).attrs({ center: true })<{ color: string; size: number }>`
  background-color: ${({ color }) => color};
  flex-shrink: 0;
`;

const LegacyIconBox = styled(IconBox)`
  width: 32px;
  height: 32px;
  border-radius: ${radius.medium};
`;

const V2IconBox = styled(IconBox)`
  width: 24px;
  height: 24px;
  border-radius: ${radius.small};
`;

const ArrowContainer = styled(FlexContainer).attrs({ justifyEnd: true })`
  transition: all 0.1s ease-in-out;
  color: ${color.gray};
  flex-grow: 1;
`;

const ShareLinkRoot = styled(FlexContainer).attrs({ alignCenter: true, justifyStart: true })`
  color: ${color.text};
  cursor: pointer;
  transition: ${({ theme }) => theme.transition.createDefaultTransition(['background-color'])};

  &:hover {
    background-color: ${({ theme }) => darken(0.008, theme.color.white)};

    ${ArrowContainer} {
      transform: translateX(3px);
      color: ${({ theme }) => theme.color.textLight};
    }
  }
`;

const LegacyShareLinkRoot = styled(ShareLinkRoot)`
  background-color: ${color.white};
  padding: ${spacing.large} ${spacing.xlarge};
  gap: ${spacing.default};

  &:hover {
    background-color: ${({ theme }) => darken(0.008, theme.color.white)};
  }
`;

const TitleText = styled(Text)`
  transition: ${({ theme }) => theme.transition.createDefaultTransition(['color'])};
`;

const V2ShareLinkRoot = styled(ShareLinkRoot)<{ withTitle: boolean }>`
  background-color: ${variables.color.background.secondary};
  padding: ${spacing.large};
  gap: ${spacing.xlarge};
  border-radius: ${radius.medium};
  justify-content: ${({ withTitle }) => (withTitle ? 'flex-start' : 'center')};

  &:hover {
    background-color: ${variables.color.background.secondaryHover};
  }

  &:active {
    background-color: ${variables.color.background.tertiaryHover};
  }
`;

const ICON_SIZE = 24;

type Props = {
  color?: string;
  href: string;
  title?: string;
  icon: React.ReactNode;
  platform: string;
  isReferralsV2Active: boolean;
};

const ShareLinkItem = ({ color, href, title, icon, platform, isReferralsV2Active }: Props) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    onClick={() => typewriter.referralCodeShared({ method: platform })}
    style={isReferralsV2Active ? { width: '100%' } : {}}
  >
    {isReferralsV2Active ? (
      <V2ShareLinkRoot withTitle={!!title}>
        <FlexContainer gap="small">
          {color ? (
            <V2IconBox color={color} size={V2_ICON_BOX_SIZE_PX}>
              {icon}
            </V2IconBox>
          ) : (
            icon
          )}
          {title && <TitleText>{title}</TitleText>}
        </FlexContainer>
        {title && (
          <ArrowContainer>
            <FiArrowRight size={ICON_SIZE} />
          </ArrowContainer>
        )}
      </V2ShareLinkRoot>
    ) : (
      <LegacyShareLinkRoot>
        {color && (
          <LegacyIconBox color={color} size={LEGACY_ICON_BOX_SIZE_PX}>
            {icon}
          </LegacyIconBox>
        )}
        <Text>{title}</Text>
        <ArrowContainer>
          <FiArrowRight size={ICON_SIZE} />
        </ArrowContainer>
      </LegacyShareLinkRoot>
    )}
  </a>
);

export default ShareLinkItem;
