import { useMutation } from '@apollo/client';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import styled from 'styled-components';

import {
  AccountColumn,
  ColumnWrapper as AccountColumnWrapper,
} from 'components/accounts/transferAccountData/AccountColumn';
import ReviewCard, {
  Divider,
  ReviewCardHeader,
} from 'components/accounts/transferAccountData/ReviewCard';
import ReviewSection from 'components/accounts/transferAccountData/ReviewSection';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import Banner from 'components/lib/ui/Banner';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import { IconCircle } from 'components/lib/ui/IconCircle';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import ManualLink from 'components/lib/ui/link/ManualLink';
import type { StepProps } from 'components/routes/accounts/transferAccountData/TransferAccountData';
import TransferAccountDataStep, {
  TransferAccountDataContentContainer,
  TransferAccountDataFooter,
} from 'components/routes/accounts/transferAccountData/TransferAccountDataStep';

import { spacing, variables } from 'common/lib/theme/dynamic';
import { isoDateToAbbreviatedMonthDayAndYear } from 'common/utils/date';
import { getProgressPercent } from 'lib/accounts/transferAccountData/utils/progress';
import { track } from 'lib/analytics/segment';
import { useContactSupportContext } from 'lib/contexts/ContactSupportContext';
import useDataTransferAccounts from 'lib/hooks/accounts/useDataTransferAccounts';

import { TransferAccountDataEventNames } from 'common/constants/analytics';

import { gql } from 'common/generated/gql';
import type {
  Web_TransferAccountDataMutation,
  Web_TransferAccountDataMutationVariables,
} from 'common/generated/graphql';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-items: center;
` as typeof Form;

const AccountContainer = styled(FlexContainer).attrs({ gap: 'xsmall' })`
  ${AccountColumnWrapper} {
    width: calc(50% - ${spacing.xsmall} / 2);
    min-width: 0;
    flex: 0 0 calc(50% - ${spacing.xsmall} / 2);
  }
`;

const SubmitButton = styled(FormSubmitButton)`
  margin: 0;
`;

const TitleText = styled(Text)`
  margin-bottom: ${spacing.xxsmall};
`;

const AlertCircle = styled(IconCircle).attrs({
  iconName: 'alert-triangle',
  iconSizePx: 30,
})`
  background-color: ${variables.color.content.danger};
  width: 60px;
  height: 60px;
  margin-bottom: ${spacing.small};
`;

const Review: React.FC<StepProps> = ({ params, next }) => {
  const {
    fromAccountId,
    toAccountId,
    transferTransactions,
    startDateTransactions,
    endDateTransactions,
    fromAccountExpectedAffectedTransactionCount,
    toAccountExpectedAffectedTransactionCount,
    toAccountNewerTransactionCount,
    transferBalanceSnapshots,
    startDateBalanceSnapshots,
    endDateBalanceSnapshots,
    fromAccountExpectedAffectedBalanceSnapshotCount,
    toAccountExpectedAffectedBalanceSnapshotCount,
    toAccountNewerBalanceSnapshotCount,
  } = params;

  const [hasError, setHasError] = useState(false);

  const [transferAccountData] = useMutation<
    Web_TransferAccountDataMutation,
    Web_TransferAccountDataMutationVariables
  >(TRANSFER_ACCOUNT_DATA_MUTATION);

  const { data: accountsForTransfer, loading: loadingAccountsForTransfer } =
    useDataTransferAccounts(fromAccountId, toAccountId);

  const transactionItems = getTransactionItems(
    transferTransactions,
    endDateTransactions ?? '',
    fromAccountExpectedAffectedTransactionCount,
    toAccountExpectedAffectedTransactionCount,
    toAccountNewerTransactionCount,
  );

  const balanceItems = getBalanceItems(
    transferBalanceSnapshots,
    endDateBalanceSnapshots ?? '',
    fromAccountExpectedAffectedBalanceSnapshotCount,
    toAccountExpectedAffectedBalanceSnapshotCount,
    toAccountNewerBalanceSnapshotCount,
  );

  return (
    <TransferAccountDataStep
      exitAccountId={fromAccountId}
      overrideTitle="Review"
      progress={getProgressPercent(5)}
      isLoading={loadingAccountsForTransfer || !fromAccountId || !toAccountId}
      useContainer={false}
    >
      {hasError ? (
        <ErrorState setHasError={setHasError} />
      ) : (
        <StyledForm
          initialValues={{
            cleanupFromAccount: true,
            fromAccountId: fromAccountId ?? '',
            toAccountId: toAccountId ?? '',
            transferTransactions,
            startDateTransactions,
            endDateTransactions,
            expectedAffectedBalanceSnapshotCount: fromAccountExpectedAffectedBalanceSnapshotCount,
            fromAccountExpectedAffectedTransactionCount,
            toAccountExpectedAffectedTransactionCount,
            transferBalanceSnapshots,
            startDateBalanceSnapshots,
            endDateBalanceSnapshots,
          }}
          isInitialValid
          mutation={transferAccountData}
          onSubmitSuccess={(response) => {
            track(TransferAccountDataEventNames.TransferAccountDataSucceeded, {
              fromAccountId,
              toAccountId,
              transferTransactions,
              transferBalanceSnapshots,
            });

            next();
          }}
          onError={(error) => {
            setHasError(true);
            track(TransferAccountDataEventNames.TransferAccountDataFailed, {
              fromAccountId,
              toAccountId,
              transferTransactions,
              transferBalanceSnapshots,
              error: error.message,
            });
          }}
        >
          <TransferAccountDataContentContainer>
            <FlexContainer center column marginTop="xxlarge" marginBottom="xxlarge" gap="large">
              <FlexContainer full column gap="small">
                <Text size="large" weight="medium">
                  Review
                </Text>
                <ReviewCard>
                  <ReviewCardHeader title="Accounts">
                    <AccountContainer>
                      <AccountColumn
                        title="Old"
                        subtitle="(We'll move data from this account)"
                        account={accountsForTransfer?.fromAccount}
                        titleSpacing="xsmall"
                        shadow="small"
                      />
                      <AccountColumn
                        title="New"
                        subtitle="(We'll move data to this account)"
                        account={accountsForTransfer?.toAccount}
                        titleSpacing="xsmall"
                        shadow="small"
                      />
                    </AccountContainer>
                  </ReviewCardHeader>
                  <Divider />
                  <ReviewSection title="Transactions" items={transactionItems} />
                  <Divider />
                  <ReviewSection title="Balances" items={balanceItems} />
                </ReviewCard>
                {!transferTransactions && !transferBalanceSnapshots && (
                  <Banner type="error">
                    <FlexContainer alignCenter gap="small">
                      <Icon size={16} name="alert-triangle" />
                      <Text>
                        You’ve chosen to not transfer transactions or balances, so there’s no data
                        to transfer. If this is a mistake, choose what you’d like to transfer.
                      </Text>
                    </FlexContainer>
                  </Banner>
                )}
              </FlexContainer>
            </FlexContainer>
          </TransferAccountDataContentContainer>
          <TransferAccountDataFooter>
            <SubmitButton
              size="large"
              disableWhenValuesUnchanged={false}
              disabled={!transferTransactions && !transferBalanceSnapshots}
            >
              Permanently transfer data
            </SubmitButton>
          </TransferAccountDataFooter>
        </StyledForm>
      )}
    </TransferAccountDataStep>
  );
};

export default Review;

const ErrorState = ({ setHasError }: { setHasError: (hasError: boolean) => void }) => {
  const { openContactSupportModal } = useContactSupportContext();
  return (
    <>
      <TransferAccountDataContentContainer>
        <FlexContainer center column marginTop="xxlarge" marginBottom="xxlarge">
          <AlertCircle />
          <TitleText size="xlarge" weight="bold">
            Data transfer unsuccessful
          </TitleText>
          <FlexContainer column gap="small">
            <Text align="center">
              There was an issue while attempting to transfer your data and we recommend trying
              again. If your next attempt is unsuccessful, please{' '}
              <ManualLink onClick={() => openContactSupportModal()}>contact support</ManualLink> for
              help troubleshooting.
            </Text>
          </FlexContainer>
        </FlexContainer>
      </TransferAccountDataContentContainer>
      <TransferAccountDataFooter>
        <PrimaryButton size="large" onClick={() => setHasError(false)}>
          Try again
        </PrimaryButton>
      </TransferAccountDataFooter>
    </>
  );
};

const getTransactionItems = (
  transferTransactions: boolean,
  endDateTransactions: string,
  fromAccountExpectedAffectedTransactionCount: number,
  toAccountExpectedAffectedTransactionCount: number,
  toAccountNewerTransactionCount: number,
) => {
  if (transferTransactions) {
    return [
      {
        iconName: 'trash',
        title: `Delete ${toAccountExpectedAffectedTransactionCount} overlapping ${pluralize('transaction', toAccountExpectedAffectedTransactionCount)}`,
        description: `Transactions on and before ${isoDateToAbbreviatedMonthDayAndYear(
          endDateTransactions,
        )} will be deleted on your new account`,
      },
      {
        iconName: 'merge',
        title: `Move ${fromAccountExpectedAffectedTransactionCount} ${pluralize('transaction', fromAccountExpectedAffectedTransactionCount)}`,
        description: `Transactions (including updated categories, notes, attachments, and splits) on and before ${isoDateToAbbreviatedMonthDayAndYear(
          endDateTransactions,
        )} will be moved from your old account to your new account`,
      },
      {
        iconName: 'check',
        title: `Keep ${toAccountNewerTransactionCount} recent ${pluralize('transaction', toAccountNewerTransactionCount)}`,
        description: `Transactions after ${isoDateToAbbreviatedMonthDayAndYear(
          endDateTransactions,
        )} will remain on your new account`,
      },
    ];
  }
  return [
    {
      iconName: 'x-circle',
      title: 'Don’t move transactions',
      description: 'Your transactions won’t be moved from your old account to your new account',
    },
  ];
};

const getBalanceItems = (
  transferBalanceSnapshots: boolean,
  endDateBalanceSnapshots: string,
  fromAccountExpectedAffectedBalanceSnapshotCount: number,
  toAccountExpectedAffectedBalanceSnapshotCount: number,
  toAccountNewerBalanceSnapshotCount: number,
) => {
  if (transferBalanceSnapshots) {
    return [
      {
        iconName: 'trash',
        title: `Delete ${toAccountExpectedAffectedBalanceSnapshotCount} overlapping ${pluralize('day', toAccountExpectedAffectedBalanceSnapshotCount)} of balances`,
        description: `Balances on and before ${isoDateToAbbreviatedMonthDayAndYear(
          endDateBalanceSnapshots,
        )} will be deleted on your new account`,
      },
      {
        iconName: 'merge',
        title: `Move ${fromAccountExpectedAffectedBalanceSnapshotCount} ${pluralize('day', fromAccountExpectedAffectedBalanceSnapshotCount)} of balances`,
        description: `Balances on and before ${isoDateToAbbreviatedMonthDayAndYear(
          endDateBalanceSnapshots,
        )} will be copied from your old account to your new account`,
      },
      {
        iconName: 'check',
        title: `Keep ${toAccountNewerBalanceSnapshotCount} recent ${pluralize('day', toAccountNewerBalanceSnapshotCount)} of balances`,
        description: `Balances after ${isoDateToAbbreviatedMonthDayAndYear(
          endDateBalanceSnapshots,
        )} will remain on your new account`,
      },
    ];
  }
  return [
    {
      iconName: 'x-circle',
      title: 'Don’t move balances',
      description: 'Your balance history won’t be moved from your old account to your new account',
    },
  ];
};

const TRANSFER_ACCOUNT_DATA_MUTATION = gql(/* GraphQL */ `
  mutation Web_TransferAccountData($input: TransferAccountDataMutationInput!) {
    transferAccountData(input: $input) {
      fromAccount {
        id
      }
      toAccount {
        id
      }
      numTransactionsAffected
      numBalanceSnapshotsAffected
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);
