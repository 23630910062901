import { useMutation } from '@apollo/client';
import * as RA from 'ramda-adjunct';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import Switch, { Case } from 'common/components/utils/Switch';
import AccountTypeSubtypeSelect from 'components/accounts/AccountTypeSubtypeSelect';
import ZillowPropertySearchModal from 'components/accounts/ZillowPropertySearchModal';
import CurrencyField from 'components/lib/form/CurrencyField';
import FieldCell from 'components/lib/form/FieldCell';
import Form from 'components/lib/form/Form';
import FormItemContainer from 'components/lib/form/FormItemContainer';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import SelectField from 'components/lib/form/SelectField';
import TextField from 'components/lib/form/TextField';
import ToggleFieldWithText from 'components/lib/form/ToggleFieldWithText';
import Banner from 'components/lib/ui/Banner';
import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import ToggleWithText, { ToggleFieldWithTextRoot } from 'components/lib/ui/ToggleWithText';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import DefaultButton, { defaultButtonMixin } from 'components/lib/ui/button/DefaultButton';
import PremiumBadge from 'components/premium/PremiumBadge';
import PremiumFeatureOverlayTrigger from 'components/premium/PremiumFeatureOverlayTrigger';
import AccountLogoSettings from 'components/settings/AccountLogoSettings';
import AccountRecurringFields from 'components/settings/accounts/AccountRecurringFields';

import { isManualHoldingsAccount } from 'common/lib/accounts/accountTypes';
import { editAccountValidationSchema, isAccountInactive } from 'common/lib/accounts/editAccount';
import { UPDATE_ACCOUNT } from 'common/lib/graphQl/accounts';
import {
  getCreditReportLiabilityAccountFormFields,
  getLastStatementDueDateDay,
  getRecurrenceFields,
} from 'common/lib/recurring';
import { formatCurrency } from 'common/utils/Currency';
import { formatFullDate, parseISODate } from 'common/utils/date';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import { getIsDemo } from 'selectors';

import { AccountTypeName } from 'common/constants/accounts';
import * as COPY from 'common/constants/copy';
import { ProductFeature } from 'common/constants/premium';
import { TRANSACTIONS_LIST_QUERY_NAME } from 'constants/graphql';

import { gql as newGql } from 'common/generated/gql';
import type {
  EditAccountFormFieldsFragment,
  UpdateAccountMutationInput,
} from 'common/generated/graphql';

const FormContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.xlarge};
`;

const StyledSubmitButton = styled(FormSubmitButton)`
  &&& {
    width: auto;
    margin-top: 0;
  }
`;

const Title = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.base};
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const RecurringSectionTitle = styled(Title)`
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const FieldSubtext = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  color: ${({ theme }) => theme.color.textLight};
  margin-left: ${({ theme }) => theme.spacing.xxsmall};
  margin-top: -${({ theme }) => theme.spacing.xsmall};
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const StyledToggleFieldWithText = styled(ToggleFieldWithText)`
  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  }

  ${ToggleFieldWithTextRoot} {
    margin-bottom: 0;
  }
`;

const ZillowToggleField = styled(ToggleWithText)`
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const StyledFieldCell = styled(FieldCell)`
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const DefaultAsyncButton = styled(AsyncButton)`
  ${defaultButtonMixin};
`;

const TextContainer = styled(FlexContainer).attrs({ column: true })`
  gap: ${({ theme }) => theme.spacing.small};
`;

type Props = {
  displayBalancePreview?: number;
  refreshDisplayBalancePreview: (
    invertSyncedBalance?: boolean,
    useAvailableBalance?: boolean,
  ) => void;
  account: EditAccountFormFieldsFragment;
  onDone: () => void;
  onClickDelete: () => void;
  onClickCloseAccount: () => void;
  onClose: () => void;
  isSearchingZillow: boolean;
  setIsSearchingZillow: (val: boolean) => void;
};

const EditAccountForm = ({
  onDone,
  onClickDelete,
  onClickCloseAccount,
  onClose,
  isSearchingZillow,
  setIsSearchingZillow,
  displayBalancePreview,
  refreshDisplayBalancePreview,
  account: {
    id,
    displayName,
    isAsset,
    isManual,
    deactivatedAt,
    type,
    manualInvestmentsTrackingMethod,
    includeInNetWorth,
    subtype,
    displayBalance,
    dataProvider,
    dataProviderAccountId,
    hideFromList,
    hideTransactionsFromReports,
    invertSyncedBalance,
    canInvertBalance,
    useAvailableBalance,
    canUseAvailableBalance,
    logoUrl,
    hasCustomizedLogo,
    creditReportLiabilityAccount: creditReportLiability,
  },
}: Props) => {
  const isDemoHousehold = useSelector(getIsDemo);

  const isSynced = !isManual;
  const isRealEstate = type.name === AccountTypeName.REAL_ESTATE;
  const isInvestments = type.name === AccountTypeName.BROKERAGE;
  const isTrackingHoldings = isManualHoldingsAccount(
    type.name,
    manualInvestmentsTrackingMethod as any,
  );
  const isSyncedRealEstate = isSynced && isRealEstate;
  const creditReportLiabilityAccountFields =
    getCreditReportLiabilityAccountFormFields(creditReportLiability);

  const isManualOrRealEstate = isManual || isSyncedRealEstate;
  const isManualTrackingHoldings = isManual && isInvestments && isTrackingHoldings;
  const showBalanceField = (isManualOrRealEstate && !isManualTrackingHoldings) || isDemoHousehold;

  const [updateAccount] = useMutation(UPDATE_ACCOUNT, {
    onCompleted: onDone,
    refetchQueries: [TRANSACTIONS_LIST_QUERY_NAME],
  });

  const [reopenAccount, { loading: isReopeningAccount }] = useMutation(UPDATE_ACCOUNT, {
    variables: {
      input: {
        id,
        deactivatedAt: null,
      } as UpdateAccountMutationInput,
    },
  });

  return (
    <Form
      initialValues={{
        id,
        dataProvider,
        dataProviderAccountId,
        deactivatedAt,
        // @ts-ignore mutation doesn't have this field
        accountStatus: RA.isNotNil(deactivatedAt),
        name: displayName,
        type: type.name,
        subtype: subtype.name,
        displayBalance: showBalanceField ? displayBalance : undefined,
        invertSyncedBalance: invertSyncedBalance ?? false,
        useAvailableBalance: useAvailableBalance ?? false,
        // @ts-ignore mutation doesn't have this field
        hideInNetWorth: !includeInNetWorth,
        hideFromList,
        hideTransactionsFromReports,
        synced: isSynced,
        accountGroup: isAsset,
        creditReportLiabilityAccount: creditReportLiabilityAccountFields,
      }}
      mutation={updateAccount}
      overrideValidationSchema={editAccountValidationSchema}
      transformValuesBeforeSubmit={(values) => {
        const {
          // @ts-ignore mutation doesn't have `hideInNetWorth`
          hideInNetWorth,
          // @ts-ignore mutation doesn't have `accountStatus`
          accountStatus,
          deactivatedAt,
          // @ts-ignore mutation doesn't have `accountGroup`
          accountGroup,
          // @ts-ignore mutation doesn't have `creditReportLiabilityAccount`
          creditReportLiabilityAccount,
          ...currentValues
        } = values;
        return {
          ...currentValues,
          deactivatedAt: isAccountInactive(accountStatus) ? deactivatedAt : null,
          includeInNetWorth: !hideInNetWorth,
          recurrence: getRecurrenceFields(creditReportLiabilityAccount?.recurringTransactionStream),
        };
      }}
    >
      <FormInner
        id={id}
        refreshDisplayBalancePreview={refreshDisplayBalancePreview}
        displayBalancePreview={displayBalancePreview}
        canUseAvailableBalance={canUseAvailableBalance}
        canInvertBalance={canInvertBalance}
        isInvestments={isInvestments}
        isTrackingHoldings={isTrackingHoldings}
        isRealEstate={isRealEstate}
        isSearchingZillow={isSearchingZillow}
        isSynced={isSynced}
        isSyncedRealEstate={isSyncedRealEstate}
        isReopeningAccount={isReopeningAccount}
        showBalanceField={showBalanceField}
        setIsSearchingZillow={setIsSearchingZillow}
        onClickCloseAccount={onClickCloseAccount}
        onClickReopenAccount={reopenAccount}
        onClickDelete={onClickDelete}
        onClose={onClose}
        onDone={onDone}
        logoUrl={logoUrl}
        hasCustomizedLogo={hasCustomizedLogo}
        lastStatementDueDateDay={getLastStatementDueDateDay(creditReportLiability?.lastStatement)}
      />
    </Form>
  );
};

type FormInnerProps = {
  id: string;
  displayBalancePreview?: number;
  refreshDisplayBalancePreview: (
    invertSyncedBalance?: boolean,
    useAvailableBalance?: boolean,
  ) => void;
  canUseAvailableBalance: boolean;
  canInvertBalance: boolean;
  isInvestments: boolean;
  isTrackingHoldings: boolean;
  isSearchingZillow: boolean;
  isSynced: boolean;
  isSyncedRealEstate: boolean;
  isRealEstate: boolean;
  isReopeningAccount: boolean;
  showBalanceField: boolean;
  setIsSearchingZillow: (val: boolean) => void;
  onClickCloseAccount: () => void;
  onClickReopenAccount: () => void;
  onClickDelete: () => void;
  onClose: () => void;
  onDone: () => void;
  logoUrl?: string | null;
  hasCustomizedLogo?: boolean | null;
  lastStatementDueDateDay: number;
};

const FormInner = ({
  id,
  displayBalancePreview,
  refreshDisplayBalancePreview,
  canUseAvailableBalance,
  canInvertBalance,
  isInvestments,
  isTrackingHoldings,
  isSearchingZillow,
  isSynced,
  isSyncedRealEstate,
  isRealEstate,
  isReopeningAccount,
  setIsSearchingZillow,
  showBalanceField,
  onClickCloseAccount,
  onClickReopenAccount,
  onClickDelete,
  onClose,
  onDone,
  logoUrl,
  hasCustomizedLogo,
  lastStatementDueDateDay,
}: FormInnerProps) => {
  const { getFieldMeta, setFieldValue, values } = useContext(FormContext);
  const { type, subtype, invertSyncedBalance, useAvailableBalance } = values;
  const currentIsSyncedValue = getFieldMeta('synced').value as boolean;
  const currentIsRealEstate = type === 'real_estate';
  const currentIsSyncedRealEstate = currentIsSyncedValue && isRealEstate;
  const isAccountCustomLogosActive = useIsFeatureFlagOn('account-custom-logos');

  const ACCOUNT_GROUP_SELECT_OPTIONS = [
    { value: true, label: 'Asset' },
    { value: false, label: 'Liability' },
  ];

  useEffect(() => {
    refreshDisplayBalancePreview(invertSyncedBalance, useAvailableBalance);
  }, [refreshDisplayBalancePreview, invertSyncedBalance, useAvailableBalance]);

  return (
    <Switch>
      <Case when={isSearchingZillow}>
        <ZillowPropertySearchModal
          onItemSelect={({ zestimate, zpid }) => {
            setFieldValue('displayBalance', zestimate);
            setFieldValue('dataProviderAccountId', zpid);
            setFieldValue('dataProvider', 'zillow');
            setFieldValue('synced', true);
            setIsSearchingZillow(false);
          }}
        />
      </Case>
      <Case default>
        <>
          <FormContainer>
            {!!id && isAccountCustomLogosActive && (
              <AccountLogoSettings
                accountId={id}
                accountLogo={logoUrl}
                hasCustomizedLogo={hasCustomizedLogo}
              />
            )}
            <TextField name="name" type="text" label="Name" required isSensitive />
            {showBalanceField && (
              <>
                <CurrencyField
                  name="displayBalance"
                  label="Balance"
                  maskOptions={{ allowDecimal: true }}
                  disabled={currentIsSyncedRealEstate}
                />
                {currentIsSyncedRealEstate && (
                  <FieldSubtext>{COPY.ACCOUNTS.ZILLOW_UP_TO_DATE}</FieldSubtext>
                )}
              </>
            )}

            <SelectField
              fieldId="accountGroup"
              id="accountGroup"
              name="accountGroup"
              label="Type"
              options={ACCOUNT_GROUP_SELECT_OPTIONS}
              onChange={() => {
                setFieldValue('type', undefined);
                setFieldValue('subtype', undefined);
              }}
            />

            <FormItemContainer name="type" fieldId="type" label="Subtype">
              <AccountTypeSubtypeSelect
                filters={{
                  asset: getFieldMeta<boolean>('accountGroup').value,
                  synced: isSynced && !isSyncedRealEstate,
                  allowedTypes: isSyncedRealEstate ? [type] : undefined,
                }}
                value={{
                  type,
                  subtype,
                }}
                onChange={({ type, subtype }) => {
                  setFieldValue('type', type);
                  setFieldValue('subtype', subtype);
                }}
              />
            </FormItemContainer>
            {currentIsRealEstate && (
              <>
                <PremiumFeatureOverlayTrigger
                  feature={ProductFeature.ZILLOW}
                  placement="bottom-end"
                >
                  {({ hasAccess }) => (
                    <ZillowToggleField
                      // Don't actually use a field here, so that if a user goes into the zillow modal
                      // then clicks "back", we don't set the field value to True. Only if they pick  a
                      // result item
                      checked={currentIsSyncedValue}
                      label="Sync value from Zillow"
                      subtext="Use Zillow's Zestimate to automatically track the value of this property."
                      replaceToggle={!hasAccess ? <PremiumBadge /> : undefined}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.checked;
                        if (!isSynced && value && !isSearchingZillow) {
                          setIsSearchingZillow(true);
                        }
                        if (!value) {
                          setFieldValue('synced', false);
                        }
                      }}
                      disabled={isSyncedRealEstate}
                    />
                  )}
                </PremiumFeatureOverlayTrigger>
                {!isSyncedRealEstate && currentIsSyncedValue && (
                  <Banner type="warning">
                    Note: Once a manual account is set to sync with Zillow, it cannot be changed
                    back.
                  </Banner>
                )}
              </>
            )}

            {canInvertBalance ? (
              <StyledToggleFieldWithText
                name="invertSyncedBalance"
                label={COPY.ACCOUNTS.INVERT_SYNCED_BALANCE_TITLE}
                subtext={
                  <TextContainer>
                    <Text>{COPY.ACCOUNTS.INVERT_SYNCED_BALANCE_SUBTITLE}</Text>
                    <Text>Balance preview: {formatCurrency(displayBalancePreview)}</Text>
                  </TextContainer>
                }
              />
            ) : null}

            {canUseAvailableBalance ? (
              <StyledToggleFieldWithText
                name="useAvailableBalance"
                label={COPY.ACCOUNTS.USE_AVAILABLE_BALANCE_TITLE}
                subtext={
                  <TextContainer>
                    <Text>{COPY.ACCOUNTS.USE_AVAILABLE_BALANCE_SUBTITLE}</Text>
                    <Text>Balance preview: {formatCurrency(displayBalancePreview)}</Text>
                  </TextContainer>
                }
              />
            ) : null}

            <Title>Visibility</Title>

            <StyledToggleFieldWithText
              name="hideFromList"
              label="Hide this account in list"
              subtext="This will hide this account in the account list"
            />

            <StyledToggleFieldWithText
              name="hideInNetWorth"
              label="Hide balance from net worth"
              subtext="This will hide this account’s balance from net worth and account group totals"
            />

            <StyledToggleFieldWithText
              name="hideTransactionsFromReports"
              label="Hide transactions from cash flow & budgets"
              subtext="This will hide this account’s transactions from cash flow & budgets"
            />

            {values?.creditReportLiabilityAccount ? (
              <RecurringSectionTitle>Recurring bill</RecurringSectionTitle>
            ) : null}

            <AccountRecurringFields
              onDone={onDone}
              lastStatementDueDateDay={lastStatementDueDateDay}
            />

            <Title>Actions</Title>

            <StyledFieldCell
              rightAccessory={
                values.deactivatedAt ? (
                  <DefaultAsyncButton onClick={onClickReopenAccount} pending={isReopeningAccount}>
                    Reopen
                  </DefaultAsyncButton>
                ) : (
                  <DefaultButton onClick={onClickCloseAccount}>Close</DefaultButton>
                )
              }
              subtitle={
                !values.deactivatedAt
                  ? 'Set balance to $0 but keep historical information'
                  : `This account was closed on ${formatFullDate(
                      parseISODate(values.deactivatedAt),
                    )}`
              }
              title={!values.deactivatedAt ? 'Close account' : 'Account is closed'}
            />

            <StyledFieldCell
              rightAccessory={<DefaultButton onClick={onClickDelete}>Delete</DefaultButton>}
              subtitle="Remove all data about this account from Monarch"
              title="Delete account"
            />
          </FormContainer>

          <CardFooter>
            <DefaultButton size="small" onClick={onClose}>
              Cancel
            </DefaultButton>
            <StyledSubmitButton size="small" disableWhenValuesUnchanged={false}>
              Save
            </StyledSubmitButton>
          </CardFooter>
        </>
      </Case>
    </Switch>
  );
};

EditAccountForm.fragments = {
  EditAccountFormFields: newGql(/* GraphQL */ `
    fragment EditAccountFormFields on Account {
      id
      displayName
      deactivatedAt
      displayBalance
      logoUrl
      hasCustomizedLogo
      includeInNetWorth
      hideFromList
      hideTransactionsFromReports
      dataProvider
      dataProviderAccountId
      isManual
      manualInvestmentsTrackingMethod
      isAsset
      invertSyncedBalance
      canInvertBalance
      useAvailableBalance
      canUseAvailableBalance

      type {
        name
        display
      }

      subtype {
        name
        display
      }

      creditReportLiabilityAccount {
        id
        lastStatement {
          id
          dueDate
        }
        recurringTransactionStream {
          id
          isActive
          dayOfTheMonth
          frequency
          defaultPaymentAccount {
            id
            name
          }
          defaultPaymentCategory {
            id
            name
          }
        }
      }
    }
  `),
};

export default EditAccountForm;
